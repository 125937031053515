import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { NoticeModel } from '@models/notification';
import { CKEditorIgogoImageUploader } from '@utils/ckeditorImageUpload';
import { Checkbox, Input } from 'antd';

interface ContentDefaultProps {
  data?: NoticeModel;
  onChange?: (value: unknown) => void;
}

export const Title: React.FC<ContentDefaultProps> = (props) => {
  const { data, onChange } = props;

  return (
    <Input
      defaultValue={data && data.title}
      style={{ maxWidth: '428px' }}
      onBlur={(e) => {
        onChange?.(e.target.value);
      }}
    />
  );
};

export const Content: React.FC<ContentDefaultProps> = (props) => {
  const { data, onChange } = props;

  return (
    <CKEditor
      config={{ extraPlugins: [CKEditorIgogoImageUploader], link: { addTargetToExternalLinks: true } }}
      editor={ClassicEditor}
      data={data && data.content}
      onBlur={(_, editor) => {
        const data = editor.getData();
        onChange?.(data);
      }}
    />
  );
};

export const ExposeDomain: React.FC<ContentDefaultProps> = (props) => {
  const { data, onChange } = props;

  return (
    <Checkbox.Group
      key={JSON.stringify(data)}
      defaultValue={data?.targets}
      options={[
        { label: '앱', value: 'app' },
        { label: '파트너 어드민', value: 'partner' },
        { label: '판매자 어드민', value: 'seller' },
      ]}
      onChange={(e) => onChange?.(e)}
    />
  );
};

export const Option: React.FC<ContentDefaultProps> = (props) => {
  const { data, onChange } = props;
  const defaultOptions = [];

  if (data?.popup) defaultOptions.push('popup');

  return (
    <div>
      <Checkbox.Group
        defaultValue={defaultOptions}
        options={[{ label: '팝업 여부', value: 'popup' }]}
        onChange={(e) => onChange?.(e)}
      />
    </div>
  );
};
