import CalendarIcon from '@assets/images/app/calendar.png';
import ClockIcon from '@assets/images/app/clock.png';
// import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { DatePicker, TimePicker } from 'antd';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import moment from 'moment';
import 'dayjs/locale/ko';
import locale from 'antd/es/date-picker/locale/ko_KR';

dayjs.extend(weekday);
dayjs.extend(localeData);

export interface DateTimeButtonPickerProps {
  value?: Date;
  minDate?: string; // YYYY-MM-DD
  maxDate?: string; // YYYY-MM-DD
  minTime?: string; // HH:mm
  maxTime?: string; // HH:mm
  onChange?: (value: Date) => void;
}

export const DateTimeButtonPicker: React.FC<DateTimeButtonPickerProps> = (props) => {
  // const dateRef = useRef<any>();
  const timeRef = useRef<any>();
  // const timeButtonRef = useRef<any>();

  const [dateString, setDateString] = useState<string>();
  const [timeString, setTimeString] = useState<string>();
  // const [focusDate, setFocusDate] = useState<boolean>(false);
  // const [focusTime, setFocusTime] = useState<boolean>(false);
  const [date, setDate] = useState<Date>();

  useEffect(() => {
    const dateStr = dateString || moment(date).format('YYYY-MM-DD');
    const newDate = new Date(Date.parse(`${dateStr} ${timeString || props.minTime || '00:00:00'}`));
    setDate(newDate);

    if (dateString || timeString) {
      props.onChange && props.onChange(newDate);
    }
  }, [dateString, timeString]);

  useEffect(() => {
    setDate(props.value);
  }, [props.value]);

  return (
    <div style={{ display: 'flex', marginTop: '8px', gap: '8px' }}>
      <DatePicker
        locale={locale}
        style={{ flex: 1, height: '52px' }}
        format={'YY. MM. DD(dddd)'}
        placeholder={'날짜'}
        suffixIcon={<img src={CalendarIcon}></img>}
        value={dayjs(date)}
        onChange={(e) => {
          setDate(e?.toDate());
          setDateString(e?.format('YYYY-MM-DD'));
          // setFocusDate(false);
          if (timeRef.current) {
            timeRef.current.showPicker();
            // setFocusTime(true);
          }
        }}
      />
      <TimePicker
        format={'HH:mm'}
        minuteStep={10}
        value={dayjs(date)}
        style={{ flex: 1, height: '52px' }}
        placeholder={'시간'}
        suffixIcon={<img src={ClockIcon}></img>}
        onChange={(e) => {
          setTimeString(e?.format('HH:mm:00'));
        }}
        onBlur={(e) => {
          const value = e.target.value;
          setTimeString(value + ':00');
          // setTimeString(e?.format('HH:mm:00'));
        }}
      />

      {/* <button
        className="link-button"
        style={{
          flex: 1,
          display: 'flex',
          justifyContent: 'space-between',
          border: focusDate ? '1px solid #242424' : '1px solid #E8EAED',
          padding: '16px',
          borderRadius: '8px',
          position: 'relative',
        }}
        onClick={() => {
          if (dateRef.current && !focusDate) {
            dateRef.current.showPicker();
            timeButtonRef.current.focus();
            setFocusDate(true);
          } else {
            setFocusDate(false);
          }
        }}
        onBlur={() => {
          setFocusDate(false);
        }}>
        {date ? (
          <div
            style={{
              color: 'var(--Neutral-90, #424242)',
              fontFamily: 'Pretendard',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '22px',
            }}>
            {moment(date).format('YY. MM. DD(dddd)').replace('요일', '')}
          </div>
        ) : (
          <div
            style={{
              color: 'var(--Neutral-90, #B9BBBE)',
              fontFamily: 'Pretendard',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '22px',
            }}>
            날짜
          </div>
        )}
        <img src={CalendarIcon}></img>
        <input
          ref={dateRef}
          type={'date'}
          style={{
            border: 'none',
            visibility: 'hidden',
            background: 'transparent',
            color: 'transparent',
            cursor: 'pointer',
            position: 'absolute',
            width: 0,
            height: 0,
            bottom: 0,
            left: 0,
          }}
          min={props.minDate}
          max={props.maxDate}
          onChange={(e) => {
            setDateString(e.target.value);
            setFocusDate(false);
            if (timeRef.current) {
              timeRef.current.showPicker();
              setFocusTime(true);
            }
          }}></input>
      </button>
      <button
        ref={timeButtonRef}
        className="link-button"
        style={{
          marginLeft: '8px',
          flex: 1,
          display: 'flex',
          justifyContent: 'space-between',
          border: focusTime ? '1px solid #242424' : '1px solid #E8EAED',
          padding: '16px',
          borderRadius: '8px',
          position: 'relative',
        }}
        onClick={() => {
          if (timeRef.current && !focusTime) {
            timeRef.current.showPicker();
            setFocusTime(true);
          } else {
            setFocusTime(false);
          }
        }}
        onBlur={() => {
          setFocusTime(false);
        }}>
        {date ? (
          <div
            style={{
              color: 'var(--Neutral-90, #424242)',
              fontFamily: 'Pretendard',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '22px',
            }}>
            {moment(date).format('a h:mm')}
          </div>
        ) : (
          <div
            style={{
              color: 'var(--Neutral-90, #B9BBBE)',
              fontFamily: 'Pretendard',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '22px',
            }}>
            시간
          </div>
        )}
        <img src={ClockIcon}></img>
        <input
          ref={timeRef}
          type={'time'}
          style={{
            border: 'none',
            visibility: 'hidden',
            background: 'transparent',
            color: 'transparent',
            cursor: 'pointer',
            position: 'absolute',
            width: 0,
            height: 0,
            bottom: 0,
            left: 0,
          }}
          onChange={(e) => {
            setTimeString(e.target.value);
          }}
          step={'600'}
          min={props.minTime}
          max={props.maxTime}
          defaultValue={props.minTime || '09:00'}></input>
      </button> */}
    </div>
  );
};

export default DateTimeButtonPicker;
