import * as api from '@apis/join-promotions';
import FileUploadButton from '@components/common/FileUploadButton';
import { useAuthentication } from '@hooks/authentication';
import { JoinPromotion } from '@models/join-promotion';
import { useJoinPromotionsStore } from '@stores/joinPromotionsStore';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { useMutation, useQuery } from '@tanstack/react-query';
import { removeTempProperties } from '@utils/dataTransform';
import { cdnImageUrl, resizeImage } from '@utils/image';
import { createRandomCode, extractFileName } from '@utils/string';
import { WEB_URL } from '@variables';
import { Button, Checkbox, DatePicker, Input, Modal, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { RcFile } from 'antd/es/upload';
import dayjs from 'dayjs';
import moment from 'moment';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

interface JoinPromotionEditModalProps {
  open?: boolean;
  data?: JoinPromotion;
  onClose?: () => void;
  needRefetch?: () => void;
}

const JoinPromotionEditModal: React.FC<JoinPromotionEditModalProps> = (props) => {
  const [data, setData] = useState<JoinPromotion>();
  const [imageFile, setImageFile] = useState<File>();

  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
  }, [props.data]);
  const dialog = useOkCancelDialog();
  const updateJoinPromotionMutation = useMutation({
    mutationFn: api.updateJoinPromotion,
    onSuccess: async () => {
      setData(undefined);
      props.onClose && props.onClose();
      dialog.open({
        content: '수정했습니다.',
        type: 'ok',
      });

      props.needRefetch && props.needRefetch();
    },
    onError: async (e: any) => {
      setData(undefined);
      props.onClose && props.onClose();
      dialog.open({
        title: '오류',
        content: e?.response?.data?.message,
        type: 'ok',
      });
    },
  });

  const createJoinPromotionMutation = useMutation({
    mutationFn: api.createJoinPromotion,
    onSuccess: async () => {
      setData(undefined);
      props.onClose && props.onClose();
      dialog.open({
        content: '추가했습니다.',
        type: 'ok',
      });

      props.needRefetch && props.needRefetch();
    },
    onError: async (e: any) => {
      setData(undefined);
      props.onClose && props.onClose();
      dialog.open({
        title: '오류',
        content: e?.response?.data?.message,
        type: 'ok',
      });
    },
  });

  const onChangeImage = async (files: FileList) => {
    const { fileName } = extractFileName(files[0].name);
    const newFiles = await Promise.all(
      Array.from(files)
        .slice(0, 10)
        .map(async (file) => {
          const result = await resizeImage(file, { maxWidth: 856 });

          return result;
        }),
    );
    const contentTypes = newFiles[0].type.split('/');
    const uploadFileName = `${fileName}.${contentTypes[1]}`;
    const newImageFile = new File([newFiles[0].image], `${fileName}.${contentTypes[1]}`) as RcFile;
    const tempUrl = URL.createObjectURL(newFiles[0].image);
    setData((prev) => {
      const newValue = { ...prev, imageUrl: uploadFileName, imageUrlTemp: tempUrl } as JoinPromotion;
      return newValue;
    });

    setImageFile(newImageFile);
  };

  return (
    <Modal
      styles={{ footer: { height: '44px' } }}
      open={props.open}
      footer={
        <div style={{ width: '100%' }}>
          <Button
            style={{
              width: 120,
            }}
            onClick={props.onClose}>
            취소
          </Button>
          <Button
            style={{
              width: 120,
              marginLeft: '8px',
            }}
            onClick={() => {
              const formData: FormData = new FormData();
              const json = removeTempProperties(data);
              formData.append('json', JSON.stringify(json));
              if (imageFile) {
                formData.append('files', imageFile);
              }

              if (props.data?._id) {
                formData.append('joinPromotionId', props.data?._id);
                updateJoinPromotionMutation.mutate(formData);
              } else {
                createJoinPromotionMutation.mutate(formData);
              }
            }}>
            {props.data?._id ? '수정하기' : '생성하기'}
          </Button>
        </div>
      }
      onCancel={props.onClose}>
      <div style={{ height: '48px', fontSize: '18px', fontWeight: 'bold' }}>
        {props.data?._id ? '회원가입 이벤트 수정' : '신규 이벤트 생성'}
      </div>
      <OverlayScrollbarsComponent
        defer
        options={{ scrollbars: { autoHide: 'scroll' } }}
        style={{
          backgroundColor: '#ffffff',
          marginTop: '8px',
          position: 'relative',
          height: 'calc(100dvh - 340px)',
          overflow: 'auto',
        }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', marginRight: '8px' }}>
            <Select
              style={{ width: '200px' }}
              value={data?.status}
              placeholder={'상태'}
              options={[
                { value: 'active', label: '사용' },
                { value: 'inactive', label: '중단' },
              ]}
              onChange={(value) => {
                setData((prev) => {
                  return { ...data, ...prev, status: value } as JoinPromotion;
                });
              }}></Select>
            <div style={{ display: 'flex', gap: '8px' }}>
              <div style={{ width: '80px', flexShrink: 0 }}>이벤트 이름</div>
              <div style={{ width: '100%' }}>
                <Input
                  value={data?.name}
                  onChange={(e) => {
                    setData((prev) => {
                      return { ...prev, name: e.target.value } as JoinPromotion;
                    });
                  }}></Input>
              </div>
            </div>
            <div style={{ display: 'flex', gap: '8px' }}>
              <div style={{ width: '80px', flexShrink: 0 }}>이벤트 코드</div>
              <div style={{ width: '100%', display: 'flex', gap: '8px' }}>
                <Input
                  value={data?.eventCode}
                  onChange={(e) => {
                    setData((prev) => {
                      return { ...prev, eventCode: e.target.value } as JoinPromotion;
                    });
                  }}></Input>
                <Button
                  onClick={() => {
                    setData((prev) => {
                      return { ...prev, eventCode: createRandomCode(6) } as JoinPromotion;
                    });
                  }}>
                  랜덤 코드 생성
                </Button>
              </div>
            </div>
            <div style={{ display: 'flex', gap: '8px' }}>
              <div style={{ width: '80px', flexShrink: 0 }}>지급 포인트</div>
              <div style={{ width: '100%', display: 'flex', gap: '8px' }}>
                <Input
                  value={data?.creditPoint}
                  onChange={(e) => {
                    setData((prev) => {
                      return { ...prev, creditPoint: Number(e.target.value) || 0 } as JoinPromotion;
                    });
                  }}></Input>
              </div>
            </div>
            <div style={{ display: 'flex', gap: '8px' }}>
              <div style={{ width: '80px', flexShrink: 0 }}>제목</div>
              <div style={{ width: '100%' }}>
                <Input.TextArea
                  value={data?.title}
                  onChange={(e) => {
                    setData((prev) => {
                      return { ...prev, title: e.target.value } as JoinPromotion;
                    });
                  }}></Input.TextArea>
              </div>
            </div>
            <div style={{ display: 'flex', gap: '8px' }}>
              <div style={{ width: '80px', flexShrink: 0 }}>설명</div>
              <div style={{ width: '100%' }}>
                <Input.TextArea
                  value={data?.description}
                  onChange={(e) => {
                    setData((prev) => {
                      return { ...prev, description: e.target.value } as JoinPromotion;
                    });
                  }}></Input.TextArea>
              </div>
            </div>
            <div style={{ display: 'flex', gap: '8px' }}>
              <div style={{ width: '80px', flexShrink: 0 }}>접근 제한</div>
              <div style={{ width: '100%' }}>
                <Checkbox
                  checked={data?.accessControl}
                  onChange={(e) => {
                    setData((prev) => {
                      return { ...prev, accessControl: e.target.checked } as JoinPromotion;
                    });
                  }}>
                  사용
                </Checkbox>
                <Input
                  value={data?.referrer}
                  onChange={(e) => {
                    setData((prev) => {
                      return { ...prev, referrer: e.target.value } as JoinPromotion;
                    });
                  }}></Input>
              </div>
            </div>
            <div style={{ display: 'flex', gap: '8px' }}>
              <div style={{ width: '80px', flexShrink: 0 }}>이벤트 시작</div>
              <div style={{ width: '100%' }}>
                <DatePicker
                  value={dayjs(data?.startDate)}
                  showTime={{ showSecond: false }}
                  onChange={(value, dateString) => {
                    setData((prev) => {
                      return {
                        ...prev,
                        startDate: moment(dateString, 'YYYY-MM-DD HH:mm:00').toDate(),
                      } as JoinPromotion;
                    });
                  }}
                />
              </div>
            </div>
            <div style={{ display: 'flex', gap: '8px' }}>
              <div style={{ width: '80px', flexShrink: 0 }}>이벤트 종료</div>
              <div style={{ width: '100%' }}>
                <DatePicker
                  value={dayjs(data?.endDate)}
                  showTime={{ showSecond: false }}
                  onChange={(value, dateString) => {
                    setData((prev) => {
                      return {
                        ...prev,
                        endDate: moment(dateString, 'YYYY-MM-DD HH:mm:00').toDate(),
                      } as JoinPromotion;
                    });
                  }}
                />
              </div>
            </div>
            <FileUploadButton
              accept={['jpg', 'jpeg', 'png', 'tif', 'tiff', 'webp', 'svg']
                .map((ext) => {
                  return '.' + ext;
                })
                .join(',')}
              className="link-button"
              style={{
                fontSize: '14px',
                height: '32px',
                padding: '4px 12px',
                borderRadius: '6px',
                backgroundColor: '#ffffff',
                border: '1px solid #d9d9d9',
                color: 'rgba(0, 0, 0, 0.88)',
                boxShadow: '0 2px 0 rgba(0, 0, 0, 0.02)',
                cursor: 'pointer',
              }}
              onChange={(e) => {
                if (e.target.files) {
                  const files = e.target.files;
                  onChangeImage(files);
                }
              }}>
              이미지 변경
            </FileUploadButton>
            <img
              src={cdnImageUrl(data?.imageUrlTemp || data?.imageUrl)}
              style={{ width: '100%', height: '240px', borderRadius: 0, objectFit: 'cover' }}
            />
          </div>
        </div>
      </OverlayScrollbarsComponent>
    </Modal>
  );
};

export const JoinPromotionsPage: React.FC = () => {
  const [editItem, setEditItem] = useState<JoinPromotion>();
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [searchKeyword, setSearchKeyword] = useState<string>();
  const searchInputRef = useRef<any>();

  const store = useJoinPromotionsStore();

  useAuthentication();

  const joinPromotionsQuery = useQuery({
    queryKey: [`join-promotions`, JSON.stringify(store.searchQuery)],
    queryFn: () => api.searchJoinPromotions(store.searchQuery),
  });

  const joinPromotions = joinPromotionsQuery.data?.data;

  useEffect(() => {
    if (joinPromotions) {
      store.setJoinPromotionsContainer(joinPromotions);
    }
  }, [joinPromotions]);

  const TABLE_COLUMNS: ColumnsType<JoinPromotion> = [
    {
      title: 'ID',
      dataIndex: '_id',
      render: (_id) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(_id);
                toast.dark('ID를 클립보드에 복사했습니다', {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  pauseOnFocusLoss: false,
                  draggable: true,
                  progress: undefined,
                  bodyStyle: {
                    color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                    textAlign: 'center',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                  },
                  theme: 'dark',
                });
              }}>
              ID
            </Button>
          </div>
        );
      },
    },
    {
      title: '타이틀',
      render: (record: JoinPromotion) => {
        return record.title;
      },
    },
    {
      title: '설명',
      render: (record: JoinPromotion) => {
        return record.description;
      },
    },
    {
      title: '포인트 금액',
      render: (record: JoinPromotion) => {
        return record.creditPoint;
      },
    },
    {
      title: '상태',
      width: 100,
      render: (record: JoinPromotion) => {
        if (record.status === 'active') {
          return (
            <div className="badge" style={{ backgroundColor: '#3fb547' }}>
              사용중
            </div>
          );
        }
        return (
          <div className="badge" style={{ backgroundColor: '#bd334a' }}>
            중단
          </div>
        );
      },
    },
    {
      title: 'URL',
      width: 100,
      render: (record: JoinPromotion) => {
        return (
          <Button
            onClick={() => {
              navigator.clipboard.writeText(`${WEB_URL}/join/referral?ec=${record.eventCode}`);
              toast.dark('이벤트 URL을 클립보드에 복사했습니다', {
                position: 'top-center',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                pauseOnFocusLoss: false,
                draggable: true,
                progress: undefined,
                bodyStyle: {
                  color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                  textAlign: 'center',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '20px',
                },
                theme: 'dark',
              });
            }}>
            URL 복사
          </Button>
        );
      },
    },
    {
      title: '수정하기',
      width: 100,
      render: (record: JoinPromotion) => {
        return (
          <Button
            onClick={() => {
              setEditItem(record);
              setShowCreateModal(true);
            }}>
            수정
          </Button>
        );
      },
    },
    {
      title: '이벤트 기간',
      width: 180,
      render: (record: JoinPromotion) => {
        return (
          <div>
            {record.startDate && <div>{moment(record.startDate).format('YYYY.MM.DD HH:mm')}</div>}
            <div>~</div>
            {record.endDate && <div>{moment(record.endDate).format('YYYY.MM.DD HH:mm')}</div>}
          </div>
        );
      },
    },
    {
      title: '생성',
      dataIndex: 'createdAt',
      render: (createdAt) => {
        if (!createdAt) {
          return '-';
        }
        return moment(createdAt).format('YYYY.MM.DD HH:mm');
      },
    },
    {
      title: '수정',
      dataIndex: 'updatedAt',
      render: (updatedAt) => {
        if (!updatedAt) {
          return '-';
        }
        return moment(updatedAt).format('YYYY.MM.DD HH:mm');
      },
    },
  ];
  return (
    <div className="dashboard" style={{ position: 'relative' }}>
      <JoinPromotionEditModal
        data={editItem}
        open={showCreateModal}
        onClose={() => {
          setShowCreateModal(false);
          setEditItem(undefined);
        }}
        needRefetch={() => {
          joinPromotionsQuery.refetch();
        }}></JoinPromotionEditModal>

      <div className="page-header">
        <div>회원가입 프로모션 관리</div>
      </div>
      <div style={{ display: 'flex' }}>
        <div className="page-content" style={{ width: '100%', height: 'calc(100vh - 80px)', overflow: 'auto' }}>
          <div style={{ display: 'flex', gap: '12px', width: '100%', height: '52px', alignItems: 'center' }}>
            <Input
              ref={searchInputRef}
              style={{ width: '240px' }}
              value={searchKeyword}
              placeholder={'검색어 입력 후 엔터'}
              onChange={(e) => {
                setSearchKeyword(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.code === 'Enter' && searchKeyword) {
                  store.updateSearchQuery({ keyword: searchKeyword });
                }
              }}></Input>
            <Button
              onClick={() => {
                setSearchKeyword(undefined);
                store.resetSearchQuery();
              }}>
              검색 초기화
            </Button>
            <div style={{ display: 'flex', marginLeft: 'auto', gap: '12px', alignItems: 'center' }}>
              <Button
                onClick={() => {
                  setShowCreateModal(true);
                }}>
                신규 이벤트 생성
              </Button>
            </div>
          </div>
          <Table
            columns={TABLE_COLUMNS}
            scroll={{ x: 'max-content', y: 'calc(100dvh - 280px)' }}
            style={{ whiteSpace: 'pre-wrap' }}
            rowKey={(record) => record?._id}
            dataSource={store.searchResultContainer.contents.filter((item) => item?._id)}
            pagination={{
              current: (store.searchResultContainer.page || 0) + 1,
              pageSize: store.searchResultContainer.size || 20,
              total: store.searchResultContainer.total,
            }}
            loading={joinPromotionsQuery.isLoading}
            onChange={(e) => {
              store.updateSearchQuery({ page: (e.current || 2) - 1, size: e.pageSize || 20 });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default JoinPromotionsPage;
