import * as api from '@apis/admin-user';
import FileUploadButton from '@components/common/FileUploadButton';
import { Roles, ROLE_LABELS } from '@consts/role';
import { closestCenter, DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { UpdateUserBody } from '@models/auth';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { useMutation, useQuery } from '@tanstack/react-query';
import { removeTempProperties } from '@utils/dataTransform';
import { cdnImageUrl, resizeImage } from '@utils/image';
import { createRandomNumber, extractFileName } from '@utils/string';
import { Button, Checkbox, Input, Modal, Select } from 'antd';
import { RcFile } from 'antd/es/upload';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

export interface UserEditModalProps {
  open?: boolean;
  userId?: string;
  onClose?: () => void;
  needRefetch?: () => void;
}

interface SortableItemProps {
  onDeleteItem?: (event: any) => void;
  id: any;
  item: { key: string; label: string };
  canDelete?: boolean;
}

export const SortableItem: React.FC<SortableItemProps> = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const { onDeleteItem, item } = props;
  return (
    <div ref={setNodeRef} style={{ ...style }} {...attributes} {...listeners}>
      <div
        style={{
          display: 'flex',
          width: '80px',
          borderRadius: '8px',
          border: '1px solid #aaaaaa',
          justifyContent: 'space-between',
          alignItems: 'center',
          fontSize: '12px',
          padding: '4px',
        }}>
        <div style={{ flex: 1, textAlign: 'center' }}>{item.label}</div>
        <div style={{ flexShrink: 0 }}>
          {props.canDelete && (
            <button
              style={{ width: '20px' }}
              className="link-button"
              onClick={(e) => {
                onDeleteItem && onDeleteItem(e);
              }}>
              X
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export const UserEditModal: React.FC<UserEditModalProps> = (props) => {
  const [selectedRole, setSelectedRole] = useState<Roles>();
  const [updateUserData, setUpdateUserData] = useState<UpdateUserBody>({});
  const [modifiedFiles, setModifiedFiles] = useState<{ [key: string]: File }>({});
  const dialog = useOkCancelDialog();
  const userQuery = useQuery({
    queryKey: [`user-item-${props.userId}`],
    queryFn: () => api.getUser(props.userId!),
    enabled: !!props.userId,
  });

  useEffect(() => {
    setUpdateUserData({});
  }, [props.userId]);

  const data = userQuery.data?.data;

  const sortableSensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 10 },
    }),
  );

  const onSortEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    const roles = updateUserData.roles || data?.roles;

    if (active.id !== over?.id) {
      if (roles) {
        const oldIndex = roles.findIndex((item) => item === active.id);
        const newIndex = roles.findIndex((item) => item === over?.id);
        const newData = arrayMove(roles, oldIndex, newIndex);
        setUpdateUserData((prev) => {
          return { ...prev, roles: newData };
        });
      }
    }
  };

  const updateUserMutation = useMutation({
    mutationFn: api.updateUser,
    onSuccess: async () => {
      props.onClose && props.onClose();
      props.needRefetch && props.needRefetch();
      dialog.open({
        content: '수정되었습니다.',
        type: 'ok',
      });
    },
    onError: async (e: any) => {
      props.onClose && props.onClose();
      props.needRefetch && props.needRefetch();
      dialog.open({
        title: '오류',
        content: e?.response?.data?.message,
        type: 'ok',
      });
    },
  });

  const onChangeImage = async (files: FileList, key: string) => {
    const { fileName } = extractFileName(files[0].name);
    const newFiles = await Promise.all(
      Array.from(files)
        .slice(0, 10)
        .map(async (file) => {
          const result = await resizeImage(file, { maxWidth: 1024 });

          return result;
        }),
    );
    const contentTypes = newFiles[0].type.split('/');
    const uploadFileName = `${fileName}.${contentTypes[1]}`;
    const newImageFile = new File([newFiles[0].image], `${fileName}.${contentTypes[1]}`) as RcFile;
    const tempUrl = URL.createObjectURL(newFiles[0].image);
    setUpdateUserData((prev) => {
      return { ...prev, [key]: uploadFileName, [`${key}Temp`]: tempUrl };
    });

    setModifiedFiles((prev) => {
      return { ...prev, [key]: newImageFile };
    });
  };

  return (
    <Modal
      className="app-modal"
      open={props.open}
      styles={{ footer: { height: '44px' } }}
      footer={
        <div style={{ width: '100%' }}>
          <Button
            style={{
              width: 120,
            }}
            onClick={props.onClose}>
            취소
          </Button>
          <Button
            style={{
              width: 120,
              marginLeft: '8px',
            }}
            onClick={() => {
              const formData: FormData = new FormData();
              const json = removeTempProperties({ ...updateUserData });
              formData.append('userId', props.userId!);
              formData.append('json', JSON.stringify(json));
              Object.values(modifiedFiles).forEach((file) => {
                formData.append('files', file);
              });

              updateUserMutation.mutate(formData);
            }}>
            저장
          </Button>
        </div>
      }
      onCancel={props.onClose}>
      <OverlayScrollbarsComponent
        defer
        options={{ scrollbars: { autoHide: 'scroll' } }}
        style={{
          backgroundColor: '#ffffff',
          marginTop: 0,
          position: 'relative',
          height: 'calc(100dvh - 240px)',
          overflow: 'auto',
        }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              fontSize: 20,
              fontWeight: 'bold',
              textAlign: 'center',
              marginBottom: 10,
            }}>
            사용자 정보 수정
          </div>
          <div style={{ textAlign: 'left', padding: 20 }}>
            <Button
              onClick={() => {
                setUpdateUserData((prev) => {
                  return { ...prev, password: createRandomNumber() };
                });
              }}>
              랜덤 비밀번호로 변경
            </Button>
            {updateUserData?.password && (
              <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                계정 비밀번호:
                <Button
                  onClick={() => {
                    navigator.clipboard.writeText(`${updateUserData.password}`);
                    toast.dark('비밀번호를 클립보드에 복사했습니다', {
                      position: 'top-center',
                      autoClose: 3000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      pauseOnFocusLoss: false,
                      draggable: true,
                      progress: undefined,
                      bodyStyle: {
                        color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                        textAlign: 'center',
                        fontFamily: 'Pretendard',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '20px',
                      },
                      theme: 'dark',
                    });
                  }}>
                  {updateUserData.password}
                </Button>
              </div>
            )}
            <div style={{ marginTop: 10 }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  marginBottom: '8px',
                  justifyContent: 'space-between',
                }}>
                <div>역할</div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                  <Select
                    value={selectedRole}
                    style={{ width: '120px', marginLeft: '12px' }}
                    options={Object.keys(ROLE_LABELS).map((key) => {
                      return { value: key, label: ROLE_LABELS[key] };
                    })}
                    onChange={(value) => {
                      setSelectedRole(value);
                    }}></Select>
                  <Button
                    onClick={() => {
                      setUpdateUserData((prev) => {
                        const roles = updateUserData.roles || data?.roles || [];
                        if (selectedRole && !roles.includes(selectedRole)) {
                          roles?.push(selectedRole);
                        }
                        return { ...prev, roles: roles };
                      });
                    }}>
                    추가
                  </Button>
                </div>
              </div>
              <div>
                <DndContext sensors={sortableSensors} collisionDetection={closestCenter} onDragEnd={onSortEnd}>
                  <SortableContext
                    items={(updateUserData.roles || data?.roles || []).map((role) => {
                      return { id: role, key: role, label: ROLE_LABELS[role] };
                    })}
                    strategy={verticalListSortingStrategy}>
                    <div style={{ width: '100%', display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                      {(updateUserData.roles || data?.roles || []).map((key, index) => {
                        const item = { id: key, key: key, label: ROLE_LABELS[key] };
                        return (
                          <SortableItem
                            item={item}
                            id={item.id}
                            canDelete={(updateUserData.roles || data?.roles || []).length > 1}
                            onDeleteItem={() => {
                              setUpdateUserData((prev) => {
                                const roles = updateUserData.roles || data?.roles;
                                roles?.splice(index, 1);
                                return { ...prev, roles: roles };
                              });
                            }}></SortableItem>
                        );
                      })}
                    </div>
                  </SortableContext>
                </DndContext>
              </div>
            </div>
            <div style={{ marginTop: 10 }}>
              이름
              <Input
                placeholder="이름"
                value={updateUserData.name || data?.profile?.name}
                onChange={(e) => {
                  setUpdateUserData((prev) => {
                    return { ...prev, name: e.target.value };
                  });
                }}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              닉네임(활동명)
              <Input
                placeholder="닉네임"
                value={updateUserData.nickname || data?.profile?.nickname}
                onChange={(e) => {
                  setUpdateUserData((prev) => {
                    return { ...prev, nickname: e.target.value };
                  });
                }}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              {'메시지(라운지 > 닉네임 하단)'}
              <Input
                placeholder="메시지"
                value={updateUserData.message || data?.profile?.message}
                onChange={(e) => {
                  setUpdateUserData((prev) => {
                    return { ...prev, message: e.target.value };
                  });
                }}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              (구)실명(튜터)
              <Input
                placeholder="이름"
                value={updateUserData.realName || data?.profile?.realName}
                onChange={(e) => {
                  setUpdateUserData((prev) => {
                    return { ...prev, realName: e.target.value };
                  });
                }}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              연락처
              <Input
                placeholder="연락처를 입력하세요"
                value={updateUserData.phoneNumber || data?.profile?.phone}
                onChange={(e) => {
                  setUpdateUserData((prev) => {
                    return { ...prev, phoneNumber: e.target.value };
                  });
                }}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              이메일
              <Input
                placeholder="이메일를 입력하세요"
                value={updateUserData.email || data?.emails?.[0]?.address}
                onChange={(e) => {
                  setUpdateUserData((prev) => {
                    return { ...prev, email: e.target.value };
                  });
                }}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              고객센터(연락처)
              <Input
                placeholder="고객센터 연락처를 입력하세요"
                value={updateUserData.customerServicePhone || data?.profile?.customerServicePhone}
                onChange={(e) => {
                  setUpdateUserData((prev) => {
                    return {
                      ...prev,
                      customerServicePhone: e.target.value,
                    };
                  });
                }}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              고객센터(URL)
              <Input
                placeholder="URL 주소를 입력하세요"
                value={updateUserData.customerServiceUrl || data?.profile?.customerServiceUrl}
                onChange={(e) => {
                  setUpdateUserData((prev) => {
                    return {
                      ...prev,
                      customerServiceUrl: e.target.value,
                    };
                  });
                }}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              대표고객센터
              <br />
              <Select
                placeholder={'대표고객센터 선택'}
                value={updateUserData.mainCustomerService || data?.profile?.mainCustomerService}
                options={[
                  { value: 'phone', label: '전화번호' },
                  { value: 'url', label: 'URL' },
                ]}
                onSelect={(value) => {
                  setUpdateUserData((prev) => {
                    return {
                      ...prev,
                      mainCustomerService: value,
                    };
                  });
                }}></Select>
            </div>

            <div style={{ marginTop: 10 }}>
              수수료율(숫자만 입력, 단위: %)
              <Input
                type="number"
                placeholder="수수료율을 입력하세요"
                value={updateUserData.commissionRate || data?.profile?.commissionRate}
                onChange={(e) => {
                  setUpdateUserData((prev) => {
                    return { ...prev, commissionRate: e.target.value };
                  });
                }}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              계약 현황
              <br />
              <Select
                style={{ width: 200 }}
                placeholder="계약 상태 선택"
                value={updateUserData.contractStatus || data?.profile?.contractStatus}
                onChange={(value) => {
                  setUpdateUserData((prev) => {
                    return { ...prev, contractStatus: value };
                  });
                }}>
                <Select.Option key={'sales'} value={'sales'}>
                  판매중
                </Select.Option>
                <Select.Option key={'hold'} value={'hold'}>
                  홀드
                </Select.Option>
                <Select.Option key={'end'} value={'end'}>
                  계약 종료
                </Select.Option>
              </Select>
            </div>
            <div style={{ marginTop: 10 }}>
              외부 판매 채널 입점
              <br />
              <Checkbox
                checked={(updateUserData?.salesChannels || data?.profile?.salesChannels)?.includes('naver')}
                onChange={(e) => {
                  setUpdateUserData((prev) => {
                    return { ...prev, salesChannels: e.target.checked ? ['naver'] : [] };
                  });
                }}>
                네이버 스토어
              </Checkbox>
            </div>
            <div style={{ marginTop: 10, display: 'flex', flexDirection: 'column', gap: '4px', width: '120px' }}>
              <div>계약서</div>
              <FileUploadButton
                accept={['jpg', 'jpeg', 'png', 'tif', 'tiff', 'webp', 'svg']
                  .map((ext) => {
                    return '.' + ext;
                  })
                  .join(',')}
                className="link-button"
                style={{
                  fontSize: '14px',
                  height: '32px',
                  padding: '4px 12px',
                  borderRadius: '6px',
                  backgroundColor: '#ffffff',
                  border: '1px solid #d9d9d9',
                  color: 'rgba(0, 0, 0, 0.88)',
                  boxShadow: '0 2px 0 rgba(0, 0, 0, 0.02)',
                  cursor: 'pointer',
                }}
                onChange={(e) => {
                  if (e.target.files) {
                    const files = e.target.files;
                    onChangeImage(files, 'contractPhoto');
                  }
                }}>
                이미지 업로드
              </FileUploadButton>
              <img
                src={cdnImageUrl(updateUserData?.contractPhotoTemp || data?.profile?.contract?.contractPhoto)}
                style={{ width: '120px', height: '160px', objectFit: 'cover' }}
              />
            </div>

            <div style={{ marginTop: 10, display: 'flex', flexDirection: 'column', gap: '4px', width: '120px' }}>
              <div>사업자 OR 신분증</div>
              <FileUploadButton
                accept={['jpg', 'jpeg', 'png', 'tif', 'tiff', 'webp', 'svg']
                  .map((ext) => {
                    return '.' + ext;
                  })
                  .join(',')}
                className="link-button"
                style={{
                  fontSize: '14px',
                  height: '32px',
                  padding: '4px 12px',
                  borderRadius: '6px',
                  backgroundColor: '#ffffff',
                  border: '1px solid #d9d9d9',
                  color: 'rgba(0, 0, 0, 0.88)',
                  boxShadow: '0 2px 0 rgba(0, 0, 0, 0.02)',
                  cursor: 'pointer',
                }}
                onChange={(e) => {
                  if (e.target.files) {
                    const files = e.target.files;
                    onChangeImage(files, 'licensePhoto');
                  }
                }}>
                이미지 업로드
              </FileUploadButton>
              <img
                src={cdnImageUrl(updateUserData?.licensePhotoTemp || (data?.profile?.contract?.licensePhoto as string))}
                style={{ width: '120px', height: '160px', objectFit: 'cover' }}
              />
            </div>
            <div style={{ marginTop: 10, display: 'flex', flexDirection: 'column', gap: '4px', width: '120px' }}>
              <div>통장 사본</div>
              <FileUploadButton
                accept={['jpg', 'jpeg', 'png', 'tif', 'tiff', 'webp', 'svg']
                  .map((ext) => {
                    return '.' + ext;
                  })
                  .join(',')}
                className="link-button"
                style={{
                  fontSize: '14px',
                  height: '32px',
                  padding: '4px 12px',
                  borderRadius: '6px',
                  backgroundColor: '#ffffff',
                  border: '1px solid #d9d9d9',
                  color: 'rgba(0, 0, 0, 0.88)',
                  boxShadow: '0 2px 0 rgba(0, 0, 0, 0.02)',
                  cursor: 'pointer',
                }}
                onChange={(e) => {
                  if (e.target.files) {
                    const files = e.target.files;
                    onChangeImage(files, 'bankAccountPhoto');
                  }
                }}>
                이미지 업로드
              </FileUploadButton>
              <img
                src={cdnImageUrl(
                  updateUserData?.bankAccountPhotoTemp || (data?.profile?.contract?.bankAccountPhoto as string),
                )}
                style={{ width: '120px', height: '160px', objectFit: 'cover' }}
              />
            </div>
          </div>
        </div>
      </OverlayScrollbarsComponent>
    </Modal>
  );
};
