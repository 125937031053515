import { PagenatedResponse } from '@models/common';
import {
  CreateReviewBody,
  ProductInventoryClosingLog,
  ProductModel,
  ProductOption,
  ProductSearchQuery,
  UpdateReviewBody,
} from '@models/product';
import { PaymentStatementReview } from '@models/review';
import { NaverCommerceProduct } from '@models/sales-channels';
import { apiClient } from '.';

export const searchProducts = async (query?: ProductSearchQuery) => {
  return apiClient.get<PagenatedResponse<ProductModel>>(`/admin/v1/products`, {
    params: query,
  });
};

export const getProduct = async (productId: string) => {
  return apiClient.get<ProductModel>(`/admin/v1/products/${productId}`);
};

export const getDraftProduct = async (productId: string) => {
  return apiClient.get<ProductModel>(`/admin/v1/products/${productId}/draft`);
};

export const createProduct = async (body: Partial<ProductModel>) => {
  return apiClient.post(`/admin/v1/products`, body);
};

export const deleteProduct = async (productId: string) => {
  return apiClient.delete(`/admin/v1/products/${productId}`);
};

export const deleteDraftProduct = async (productId: string) => {
  return apiClient.delete(`/admin/v1/products/${productId}/draft`);
};

export const getRecommendProducts = async (productId: string) => {
  return apiClient.get<ProductModel[]>(`/app/v1/products/${productId}/recommends`);
};

export const createMyFavorites = async (data: { productId: string; saleType: string }) => {
  const { productId, saleType } = data;
  return apiClient.post(`/app/v1/products/${productId}/favorite`, { saleType });
};

export const deleteMyFavorites = async (productId: string) => {
  return apiClient.delete(`/app/v1/products/${productId}/favorite`);
};

export const getReview = async (reviewId: string) => {
  return apiClient.get<PaymentStatementReview>(`/app/v1/reviews/${reviewId}`);
};

export const getProductRievews = async (productId: string) => {
  return apiClient.get(`/app/v1/products/${productId}/reviews`);
};

export const createReview = async (body: CreateReviewBody) => {
  return apiClient.post<PaymentStatementReview>(`/app/v1/payment-statement/${body.paymentStatementId}/reviews`, body);
};

export const updateReview = async (body: UpdateReviewBody) => {
  return apiClient.put<PaymentStatementReview>(
    `/app/v1/payment-statement/${body.paymentStatementId}/reviews/${body.reviewId}`,
    body,
  );
};

export const getProductsExcelFile = async (query?: ProductSearchQuery) => {
  return apiClient.get(`/admin/v1/products/export`, {
    params: query,
    responseType: 'blob',
    headers: { 'Cache-Control': 'no-cache' },
  });
};

export const updateProduct = async (formData: FormData) => {
  const productId = formData.get('productId');
  return apiClient.put<ProductModel>(`/admin/v1/products/${productId}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const getProductOptions = async (productId: string) => {
  return apiClient.get<ProductOption[]>(`/admin/v1/products/${productId}/options`);
};

export const getProductOptionsExcelFile = async (productId: string) => {
  return apiClient.get(`/admin/v1/products/${productId}/options/export`, {
    responseType: 'blob',
    headers: { 'Cache-Control': 'no-cache' },
  });
};

export const getTempProductOptions = async (productId: string) => {
  return apiClient.get<{ productOptions: ProductOption; optionLabels: string[] }>(
    `/admin/v1/products/${productId}/options-temp`,
  );
};

export const getCloseCandidateProductAndOptions = async () => {
  return apiClient.get<
    {
      _id: string;
      count: number;
      options: ProductOption[];
      remainOptions?: ProductOption[];
      product: ProductModel;
      naverProduct?: NaverCommerceProduct;
    }[]
  >(`/admin/v1/products/inventory-closures/candidates`);
};

export const closeProductOptions = async () => {
  return apiClient.post(`/admin/v1/products/inventory-closures`);
};

export const searchProductInventoryClosingHistory = async (query?: ProductSearchQuery) => {
  return apiClient.get<PagenatedResponse<ProductInventoryClosingLog>>(`/admin/v1/products/inventory-closures`, {
    params: query,
  });
};

export const updateProductInventoryClosingHistoryToOrigin = async (params: {
  inventoryClosingLogId: string;
  action: 'apply' | 'restore';
}) => {
  return apiClient.put<PaymentStatementReview>(
    `/admin/v1/products/inventory-closures/${params.inventoryClosingLogId}/${params.action}`,
  );
};
