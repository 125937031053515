import PlusIcon from '@assets/images/app/icon-plus.png';
import Number1CircleIcon from '@assets/images/app/img-1.png';
import Number2CircleIcon from '@assets/images/app/img-2.png';
import Number3CircleIcon from '@assets/images/app/img-3.png';
import SelectIcon from '@assets/images/app/select-check.png';
import SelectCheckedIcon from '@assets/images/app/select-checked.png';
import { EditChildModal } from '@components/class-invoice/EditChildModal';
import AppPreviewModal from '@components/class-invoice/AppPreviewModal';
import { SelectChildren } from '@components/class-invoice/SelectChildren';
import SelectWeekDays from '@components/class-invoice/SelectWeekDays';
import { ChildInfo } from '@models/chat';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

interface ClassInquiryModalProps {
  childList?: ChildInfo[];
  open?: boolean;
  onCancel?: () => void;
  onConfirm?: (data: ClassInquiryInfo) => void;
}

type ScheduleType = 'oneday' | 'regular' | 'chat';
type ClassInquiryInfoKey = 'children' | 'days' | 'scheduleType' | 'scheduleWithChat';

export interface ClassInquiryInfo {
  address?: any;
  classTitle?: string;
  children?: ChildInfo[];
  days?: string[];
  scheduleType?: ScheduleType;
  scheduleWithChat?: boolean;
}

export const ClassInquiryModal: React.FC<ClassInquiryModalProps> = (props) => {
  const childList = props.childList || [];
  const [chatRequestInfo, setClassInquiryInfo] = useState<ClassInquiryInfo>({});
  const [showAddChild, setShowAddChild] = useState<boolean>(false);

  const onChangeProperty = (key: ClassInquiryInfoKey, value: any) => {
    setClassInquiryInfo((prev) => {
      const newProperties = { ...prev };
      newProperties[key] = value;
      return newProperties;
    });
  };

  return (
    <AppPreviewModal
      hideRightNaviButton
      onLeftNaviButtonClick={props.onCancel}
      onRightNaviButtonClick={props.onCancel}
      open={props.open}
      title={'채팅 상담하기'}
      footer={
        <button
          className={'link-button'}
          style={{
            display: 'flex',
            width: '100%',
            backgroundColor: '#0c8eff',
            height: '60px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => {
            if ((chatRequestInfo.children || []).length === 0) {
              toast.error('수강할 아이를 선택해주세요', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                pauseOnFocusLoss: false,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
              return;
            }
            if (!chatRequestInfo.scheduleType) {
              toast.error('희망 스케줄을 선택해 주세요', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                pauseOnFocusLoss: false,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });

              return;
            }
            if ((chatRequestInfo.days || []).length === 0 && !chatRequestInfo.scheduleWithChat) {
              toast.error('요일을 선택해 주세요', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                pauseOnFocusLoss: false,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
              return;
            }
            props.onConfirm && props.onConfirm(chatRequestInfo);
          }}>
          <div style={{ color: '#ffffff', fontSize: '18px', textAlign: 'center' }}>시작하기</div>
        </button>
      }>
      <EditChildModal
        open={showAddChild}
        onCancel={() => {
          setShowAddChild(false);
        }}
        onConfirm={() => {
          setShowAddChild(false);
        }}></EditChildModal>
      <OverlayScrollbarsComponent
        defer
        options={{
          scrollbars: { autoHide: 'scroll' },
          overflow: {
            x: 'hidden',
          },
        }}
        style={{
          backgroundColor: '#ffffff',
          marginTop: 0,
          position: 'relative',
          overflow: 'auto',
          width: '100%',
          height: '100%',
        }}>
        <div style={{ backgroundColor: 'white' }}>
          <div
            style={{
              marginTop: '24px',
              flexDirection: 'column',
              marginLeft: '16px',
              marginRight: '16px',
            }}>
            <div style={{ marginBottom: '6px' }}>
              <div
                style={{
                  fontSize: '17px',
                  fontWeight: 'bold',
                  color: '#242424',
                }}>
                아이선택 (필수)
              </div>
            </div>
            <SelectChildren
              selectable
              editable
              childList={childList}
              selectedItems={chatRequestInfo.children || []}
              onChange={(selectedChildren) => {
                onChangeProperty('children', selectedChildren);
              }}></SelectChildren>
            {/* <ChildrenLayout
                margin={false}
                fromClassInquiryModal={true}
                onModify={this.onClickChildModify}
                onDelete={(index) =>
                  this.setState({
                    childremoveConfirmModal: true,
                    childremoveIndex: index,
                  })
                }
                onSelect={this.onClickChildSelect}
                selectedChildren={chatRequestInfo.selectedChildren}
                selectable={true}
                editable={true}
                childrens={chatRequestInfo.childrens}
              /> */}
            <div>
              <button
                className={'link-button'}
                onClick={() => {
                  setShowAddChild(true);
                }}
                style={{ width: '100%' }}>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    height: '52px',
                    borderRadius: '12px',
                    backgroundColor: '#eff1f4',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'row',
                    marginTop: '12px',
                    marginBottom: 0,
                  }}>
                  <img style={{ width: '12px', height: '12px', marginRight: '8px' }} src={PlusIcon} />
                  <div style={{ color: '#242424', fontSize: '14px' }}>아이 등록하기</div>
                </div>
              </button>
            </div>
          </div>
          <div
            style={{
              width: '100%',
              height: '1px',
              backgroundColor: '#eff1f4',
              marginTop: '24px',
              marginBottom: '24px',
            }}
          />
          <div
            style={{
              flexDirection: 'column',
              marginLeft: '16px',
              marginRight: '16px',
            }}>
            <div style={{ marginBottom: '16px' }}>
              <div
                style={{
                  fontSize: '17px',
                  fontWeight: 'bold',
                  color: '#242424',
                }}>
                희망 스케줄 선택 (필수)
              </div>
            </div>
            <div>
              <button
                id={'select-oneday'}
                className="link-button"
                style={{
                  width: '100%',
                  display: 'flex',
                  border: chatRequestInfo.scheduleType === 'oneday' ? '1px solid #0c8eff' : '1px solid #e8eaed',
                  borderRadius: '12px',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  marginBottom: '12px',
                  paddingTop: '20px',
                  paddingBottom: '16px',
                }}
                onClick={() => {
                  onChangeProperty('scheduleType', 'oneday');
                }}>
                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '16px' }}>
                  <div
                    style={{
                      display: 'flex',
                      border: chatRequestInfo.scheduleType === 'oneday' ? '1px solid #0c8eff' : '1px solid #dadcdf',
                      borderRadius: '50%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '20px',
                      height: '20px',
                      marginRight: '8px',
                    }}>
                    <div
                      style={{
                        backgroundColor: chatRequestInfo.scheduleType === 'oneday' ? '#0c8eff' : '#dadcdf',
                        borderRadius: '50%',
                        width: '10px',
                        height: '10px',
                      }}
                    />
                  </div>
                  <div>
                    <div
                      style={{
                        fontSize: '15px',
                        lineHeight: '19px',
                        fontWeight: 'bold',
                        color: '#242424',
                      }}>
                      원데이 클래스만 수강할래요
                    </div>
                  </div>
                </div>
                {chatRequestInfo.scheduleType === 'oneday' && (
                  <>
                    <div
                      style={{
                        width: '100%',
                        height: '1px',
                        backgroundColor: '#eff1f4',
                        marginTop: '16px',
                        marginBottom: '19px',
                      }}
                    />
                    <SelectWeekDays
                      required
                      selectedItems={chatRequestInfo.days}
                      onChange={(days) => {
                        onChangeProperty('days', days);
                      }}></SelectWeekDays>
                  </>
                )}
              </button>
              <button
                id={'select-regular'}
                className="link-button"
                style={{
                  width: '100%',
                  display: 'flex',
                  border: chatRequestInfo.scheduleType === 'regular' ? '1px solid #0c8eff' : '1px solid #e8eaed',
                  borderRadius: '12px',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  marginBottom: '12px',
                  paddingTop: '20px',
                  paddingBottom: '16px',
                }}
                onClick={() => {
                  onChangeProperty('scheduleType', 'regular');
                }}>
                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '16px' }}>
                  <div
                    style={{
                      display: 'flex',
                      border: chatRequestInfo.scheduleType === 'regular' ? '1px solid #0c8eff' : '1px solid #dadcdf',
                      borderRadius: '50%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '20px',
                      height: '20px',
                      marginRight: '8px',
                    }}>
                    <div
                      style={{
                        backgroundColor: chatRequestInfo.scheduleType === 'regular' ? '#0c8eff' : '#dadcdf',
                        borderRadius: '50%',
                        width: '10px',
                        height: '10px',
                      }}
                    />
                  </div>
                  <div>
                    <div
                      style={{
                        fontSize: '15px',
                        lineHeight: '19px',
                        fontWeight: 'bold',
                        color: '#242424',
                      }}>
                      정기적으로 클래스를 수강할래요
                    </div>
                  </div>
                </div>
                {chatRequestInfo.scheduleType === 'regular' && (
                  <>
                    <div
                      style={{
                        width: '100%',
                        height: '1px',
                        backgroundColor: '#eff1f4',
                        marginTop: '16px',
                        marginBottom: '19px',
                      }}
                    />
                    <SelectWeekDays
                      required
                      selectedItems={chatRequestInfo.days}
                      onChange={(days) => {
                        onChangeProperty('days', days);
                      }}></SelectWeekDays>
                  </>
                )}
              </button>
              <button
                id={'select-chat'}
                className="link-button"
                style={{
                  width: '100%',
                  display: 'flex',
                  border: chatRequestInfo.scheduleType === 'chat' ? '1px solid #0c8eff' : '1px solid #e8eaed',
                  borderRadius: '12px',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  marginBottom: '12px',
                  paddingTop: '20px',
                  paddingBottom: '16px',
                }}
                onClick={() => {
                  onChangeProperty('scheduleType', 'chat');
                }}>
                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '16px' }}>
                  <div
                    style={{
                      display: 'flex',
                      border: chatRequestInfo.scheduleType === 'chat' ? '1px solid #0c8eff' : '1px solid #dadcdf',
                      borderRadius: '50%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '20px',
                      height: '20px',
                      marginRight: '8px',
                    }}>
                    <div
                      style={{
                        backgroundColor: chatRequestInfo.scheduleType === 'chat' ? '#0c8eff' : '#dadcdf',
                        borderRadius: '50%',
                        width: '10px',
                        height: '10px',
                      }}
                    />
                  </div>
                  <div>
                    <div
                      style={{
                        fontSize: '15px',
                        lineHeight: '19px',
                        fontWeight: 'bold',
                        color: '#242424',
                      }}>
                      상담 후 협의할게요
                    </div>
                  </div>
                </div>
                {chatRequestInfo.scheduleType === 'chat' && (
                  <>
                    <div
                      style={{
                        width: '100%',
                        height: '1px',
                        backgroundColor: '#eff1f4',
                        marginTop: '16px',
                        marginBottom: '19px',
                      }}
                    />
                    <SelectWeekDays
                      required
                      selectedItems={chatRequestInfo.days}
                      onChange={(days) => {
                        onChangeProperty('days', days);
                      }}></SelectWeekDays>
                    <div
                      style={{
                        display: 'flex',
                        marginLeft: '16px',
                        marginTop: '20px',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                      onClick={() => {
                        onChangeProperty('scheduleWithChat', !chatRequestInfo.scheduleWithChat);
                        // this.setState({
                        //   scheduleToChat: !scheduleToChat,
                        //   selectedDays: [],
                        // });
                      }}>
                      <img
                        style={{ width: '20px', height: '20px', marginRight: '8px' }}
                        src={chatRequestInfo.scheduleWithChat ? SelectCheckedIcon : SelectIcon}
                      />
                      <div>
                        <div
                          style={{
                            color: '#424242',
                            fontSize: '14px',
                            lineHeight: '17px',
                            textAlign: 'center',
                          }}>
                          스케줄도 상담 후 결정할게요
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </button>
            </div>
          </div>
          <div
            style={{
              width: '100%',
              height: '1px',
              backgroundColor: '#eff1f4',
              marginTop: '24px',
              marginBottom: '24px',
            }}
          />
          <div
            style={{
              flexDirection: 'column',
              marginLeft: '16px',
              marginRight: '16px',
            }}>
            <div style={{ marginBottom: '16px' }}>
              <div
                style={{
                  fontSize: '17px',
                  fontWeight: 'bold',
                  color: '#242424',
                }}>
                상담 신청 전 확인
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                backgroundColor: '#f8f9fc',
                borderRadius: '12px',
                paddingTop: '16px',
                paddingBottom: '16px',
                paddingLeft: '16px',
                paddingRight: '16px',
                flexDirection: 'column',
              }}>
              <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '16px' }}>
                <img style={{ width: '18px', height: '18px', marginRight: '8px' }} src={Number1CircleIcon} />
                <span
                  style={{
                    fontSize: '14px',
                    lineHeight: '18px',
                    fontWeight: 'normal',
                    color: '#242424',
                  }}>
                  튜터님과 수업 일정, 수강 회차 등을
                </span>
                <span
                  style={{
                    fontSize: '14px',
                    lineHeight: '18px',
                    fontWeight: 'normal',
                    color: '#0180ef',
                  }}>
                  &nbsp;협의
                </span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '16px' }}>
                <img style={{ width: '18px', height: '18px', marginRight: '8px' }} src={Number2CircleIcon} />
                <span
                  style={{
                    fontSize: '14px',
                    lineHeight: '18px',
                    fontWeight: 'normal',
                    color: '#242424',
                  }}>
                  협의한 내용대로 수강증 발행을
                </span>
                <span
                  style={{
                    fontSize: '14px',
                    lineHeight: '18px',
                    fontWeight: 'normal',
                    color: '#0180ef',
                  }}>
                  &nbsp;요청
                </span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <img style={{ width: '18px', height: '18px', marginRight: '8px' }} src={Number3CircleIcon} />
                <span
                  style={{
                    fontSize: '14px',
                    lineHeight: '18px',
                    fontWeight: 'normal',
                    color: '#242424',
                  }}>
                  발행된 수강증을 결제하면 예약이
                </span>
                <span
                  style={{
                    fontSize: '14px',
                    lineHeight: '18px',
                    fontWeight: 'normal',
                    color: '#0180ef',
                  }}>
                  &nbsp;확정
                </span>
              </div>
              <div
                style={{
                  width: '100%',
                  height: '1px',
                  backgroundColor: '#eff1f4',
                  marginTop: '16px',
                  marginBottom: '11px',
                }}
              />
              <div style={{ fontSize: '13px', lineHeight: '19px', color: '#808387' }}>
                - 스케줄 진행으로 튜터님 회신이 늦을 수 있어요.
              </div>
              <div style={{ fontSize: '13px', lineHeight: '19px', color: '#808387' }}>
                - 전화번호 및 개인정보 공유는 금지되어 있어요.
              </div>
            </div>
            <div style={{ marginTop: '24px', marginBottom: '40px' }}>
              <div
                style={{
                  color: '#808387',
                  fontSize: '14px',
                  textAlign: 'center',
                }}>
                위 내용을 확인하였으며, 이용규칙에 동의합니다.
              </div>
            </div>
          </div>
        </div>
      </OverlayScrollbarsComponent>
    </AppPreviewModal>
  );
};
