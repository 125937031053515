import * as api from '@apis/statistics';
import { useAuthentication } from '@hooks/authentication';
import { SalesReport } from '@models/statistics';
import { useQuery } from '@tanstack/react-query';
import { WEB_URL } from '@variables';
import { Button, DatePicker, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export const SaleReportPage: React.FC = () => {
  const [searchDate, setSearchDate] = useState<{ startDate: Date; endDate: Date }>({
    startDate: moment().subtract(7, 'days').toDate(),
    endDate: moment().endOf('days').toDate(),
  });

  useAuthentication();

  const salesReportQuery = useQuery({
    queryKey: [`sales-report`, searchDate],
    queryFn: () => api.getSalesReportByPartner(searchDate.startDate, searchDate.endDate),
  });

  const salesReports = salesReportQuery.data?.data || [];

  const TABLE_COLUMNS: ColumnsType<SalesReport> = [
    {
      fixed: 'left',
      title: '순번',
      render: (value, record, index: number) => {
        return index + 1;
      },
    },
    {
      fixed: 'left',
      width: 100,
      title: '판매자 ID',
      render: (record: SalesReport) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(record?._id.sellerId);
                toast.dark('ID를 클립보드에 복사했습니다', {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  pauseOnFocusLoss: false,
                  draggable: true,
                  progress: undefined,
                  bodyStyle: {
                    color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                    textAlign: 'center',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                  },
                  theme: 'dark',
                });
              }}>
              ID
            </Button>
          </div>
        );
      },
    },
    {
      title: '판매자(상품수)',
      width: 300,
      render: (record: SalesReport) => {
        return (
          <div>
            {!record?._id.productName && <div>{`${record?._id.sellerName}(${(record.children || []).length})`}</div>}
            {record?._id.productName && (
              <div>
                <span
                  style={{
                    borderRadius: '4px',
                    background:
                      record._id.productType === 'naver-store'
                        ? 'rgb(3, 199, 90)'
                        : record._id.productType === 'class'
                          ? 'rgb(24, 144, 255)'
                          : '#FF3D8F',
                    color: 'white',
                    fontFamily: 'Pretendard',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    padding: '2px 6px',
                    flexShrink: 0,
                    lineHeight: 'normal',
                    marginRight: '4px',
                  }}>
                  {record._id.productType === 'naver-store' && '네이버'}
                  {record._id.productType === 'commerce' && '커머스'}
                  {record._id.productType === 'class' && '클래스'}
                </span>
                {record?._id.productName}
              </div>
            )}
            {record?.productId && (
              <Button
                onClick={() => {
                  if (record._id.productType === 'commerce' || record._id.productType === 'class') {
                    window.open(`${WEB_URL}/product/${record.productId}`);
                  } else if (record._id.productType === 'naver-store') {
                    window.open(`https://smartstore.naver.com/igogo/products/${record.productId}`, '_blank');
                  }
                }}>
                상품 바로가기
              </Button>
            )}
          </div>
        );
      },
    },
    {
      title: '앱 판매(수)',
      width: 114,
      render: (record: SalesReport) => {
        return record?.appCount.toLocaleString();
      },
      sorter: (a, b) => a.appCount - b.appCount,
    },
    {
      width: 124,
      title: '앱 판매(금액)',
      render: (record: SalesReport) => {
        return record?.appAmount.toLocaleString();
      },
      sorter: (a, b) => a.appAmount - b.appAmount,
    },
    {
      title: '앱 환불(금액)',
      width: 124,
      render: (record: SalesReport) => {
        return record?.appRefund.toLocaleString();
      },
      sorter: (a, b) => a.appRefund - b.appRefund,
    },
    {
      title: '네이버 판매(수)',
      width: 136,
      render: (record: SalesReport) => {
        return record?.naverCount.toLocaleString();
      },
      sorter: (a, b) => a.naverCount - b.naverCount,
    },
    {
      title: '네이버 판매(금액)',
      width: 152,
      render: (record: SalesReport) => {
        return record?.naverAmount.toLocaleString();
      },
      sorter: (a, b) => a.naverAmount - b.naverAmount,
    },
    {
      title: '네이버 환불(금액)',
      width: 152,
      render: (record: SalesReport) => {
        return record?.naverRefund.toLocaleString();
      },
      sorter: (a, b) => a.naverRefund - b.naverRefund,
    },
    {
      title: '총 환불(수)',
      width: 112,
      render: (record: SalesReport) => {
        return record?.totalRefundCount.toLocaleString();
      },
      sorter: (a, b) => a.totalRefundCount - b.totalRefundCount,
    },
    {
      title: '총 환불(금액)',
      width: 130,
      render: (record: SalesReport) => {
        return record?.totalRefund.toLocaleString();
      },
      sorter: (a, b) => a.totalRefund - b.totalRefund,
    },
    {
      title: '총 결제 포인트(금액)',
      width: 172,
      render: (record: SalesReport) => {
        return record?.totalPoint.toLocaleString();
      },
      sorter: (a, b) => a.totalPoint - b.totalPoint,
    },
    {
      title: '총 판매(수)',
      width: 114,
      fixed: 'right',
      render: (record: SalesReport) => {
        return record?.totalCount.toLocaleString();
      },
      sorter: (a, b) => a.totalCount - b.totalCount,
    },
    {
      title: '총 판매(금액)',
      width: 132,
      fixed: 'right',
      render: (record: SalesReport) => {
        return record?.totalAmount.toLocaleString();
      },
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.totalAmount - b.totalAmount,
    },
  ];

  const sum = salesReports.reduce(
    (prev, current) => {
      return {
        count: prev.count + current.totalCount,
        amount: prev.amount + current.totalAmount,
      };
    },
    { count: 0, amount: 0 },
  );

  return (
    <div className="dashboard" style={{ position: 'relative' }}>
      <div className="page-header">
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <div>파트너별 판매 보고서</div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <div>
            <DatePicker
              onChange={(value) => {
                setSearchDate((prev) => {
                  return { ...prev, startDate: moment(value?.toDate()).startOf('day') } as any;
                });
              }}
              value={dayjs(searchDate.startDate)}
            />
          </div>
          <div style={{ marginLeft: '8px' }}>
            <DatePicker
              onChange={(value) => {
                setSearchDate((prev) => {
                  return { ...prev, endDate: moment(value?.toDate()).startOf('day') } as any;
                });
              }}
              value={dayjs(searchDate.endDate)}
            />
          </div>
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <div className="page-content" style={{ width: '100%', height: 'calc(100vh - 80px)', overflow: 'auto' }}>
          <div
            style={{
              display: 'flex',
              gap: '12px',
              width: '100%',
              height: '52px',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}>
            <div>판매 합계(수)</div>
            <div style={{ fontWeight: 'bold' }}>{sum.count.toLocaleString()}개</div>
            <div>판매 합계(금액)</div>
            <div style={{ fontWeight: 'bold' }}>{sum.amount.toLocaleString()}원</div>
            <Button
              onClick={() => {
                const data: any[] = [];
                salesReports.forEach((parent: SalesReport) => {
                  const children: SalesReport[] = (parent.children || []).sort(
                    (lv: SalesReport, rv: SalesReport) => lv.totalAmount - rv.totalAmount,
                  );
                  data.push({
                    판매자: `${parent?._id.sellerName}(${(parent.children || []).length})`,
                    상품명: '-',
                    구분: '-',
                    '앱 판매(수)': parent.appCount,
                    '앱 판매(금액)': parent.appAmount,
                    '앱 환불(금액)': parent.appRefund,
                    '네이버 판매(수)': parent.naverCount,
                    '네이버 판매(금액)': parent.naverAmount,
                    '네이버 환불(금액)': parent.naverRefund,
                    '총 환불(수)': parent.totalRefundCount,
                    '총 환불(금액)': parent.totalRefund,
                    '총 결제 포인트(금액)': parent.totalPoint,
                    '총 판매(수)': parent.totalCount,
                    '총 판매(금액)': parent.totalAmount,
                    '실제 판매(금액)': parent.totalAmount - parent.totalRefund,
                  });
                  children.forEach((child: SalesReport) => {
                    data.push({
                      판매자: child._id.sellerName,
                      상품명: child._id.productName,
                      구분: child._id.productType,
                      '앱 판매(수)': child.appCount,
                      '앱 판매(금액)': child.appAmount,
                      '앱 환불(금액)': child.appRefund,
                      '네이버 판매(수)': child.naverCount,
                      '네이버 판매(금액)': child.naverAmount,
                      '네이버 환불(금액)': child.naverRefund,
                      '총 환불(수)': child.totalRefundCount,
                      '총 환불(금액)': child.totalRefund,
                      '총 결제 포인트(금액)': child.totalPoint,
                      '총 판매(수)': child.totalCount,
                      '총 판매(금액)': child.totalAmount,
                      '실제 판매(금액)': child.totalAmount - child.totalRefund,
                    });
                  });
                });
                try {
                  const fileName = `igogo-sales-report-${moment(searchDate.startDate).format('YYYYMMDD')}-${moment(
                    searchDate.endDate,
                  ).format('YYYYMMDD')}.xlsx`;
                  const workbook = XLSX.utils.book_new();
                  const worksheet = XLSX.utils.json_to_sheet(data);
                  worksheet['!autofilter'] = { ref: 'A1:N1' };
                  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
                  const file = XLSX.writeFileXLSX(workbook, fileName, { bookType: 'xlsx', type: 'file' });
                  saveAs(file, fileName);
                } catch (e) {
                  console.error('excel download error', e);
                }
              }}>
              엑셀 다운로드
            </Button>
          </div>
          <Table
            columns={TABLE_COLUMNS}
            pagination={false}
            scroll={{ x: 'max-content', y: 'calc(100dvh - 320px)' }}
            style={{ whiteSpace: 'pre-wrap' }}
            rowKey={(record) => `${record?._id.sellerId}_${record?._id.productName}`}
            dataSource={salesReports}
            loading={salesReportQuery.isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default SaleReportPage;
