import { Feed } from '@models/feed';
import { Input } from 'antd';
import React, { useEffect, useState } from 'react';

export const SearchRecommendKeywordsEdit: React.FC<{
  clear?: boolean;
  data?: Partial<Feed>;
  onChange?: (data: Partial<Feed>) => void;
}> = (props) => {
  const [changedData, setChangedData] = useState<Feed>();
  const { data } = props;
  const contents = changedData?.data?.[0].data || data?.data?.[0].data || [];
  const title = changedData?.data?.[0].title || data?.data?.[0].title;

  useEffect(() => {
    if (props.clear) {
      setChangedData(undefined);
    }
  }, [props.clear]);

  useEffect(() => {
    if (changedData) {
      props.onChange && props.onChange({ ...data, ...changedData });
    }
  }, [changedData]);

  return (
    <div>
      <div style={{ display: 'flex', gap: '8px', alignItems: 'flex-end' }}>
        <div style={{ fontWeight: 'bole' }}>추천 검색어</div>
      </div>

      <div style={{ marginBottom: '8px', marginTop: '12px' }}>
        <div style={{ fontWeight: 'bold' }}>타이틀</div>
        <Input
          style={{ width: '428px' }}
          value={title}
          onChange={(e) => {
            setChangedData((prev) => {
              return {
                ...prev,
                type: 'recommend-keywords',
                data: [
                  {
                    title: e.target.value,
                    data: contents,
                  },
                ],
              } as Feed;
            });
          }}></Input>
        <div style={{ fontWeight: 'bold', marginTop: '12px' }}>검색어(,로 구분)</div>
        <Input.TextArea
          style={{ width: '428px', height: '140px' }}
          value={contents.map((item) => item.keyword).join(',') || ''}
          onChange={(e) => {
            setChangedData((prev) => {
              return {
                ...prev,
                type: 'recommend-keywords',
                data: [
                  {
                    title: title,
                    data: e.target.value.split(',').map((item) => {
                      return { keyword: item };
                    }),
                  },
                ],
              } as Feed;
            });
          }}></Input.TextArea>
      </div>
    </div>
  );
};

export default SearchRecommendKeywordsEdit;
