import * as api from '@apis/statistics';
import { useAuthentication } from '@hooks/authentication';
import { ProductCount } from '@models/statistics';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { useProductReportStore } from '@stores/productReportStore';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Select, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

export const ProductReportPage: React.FC = () => {
  const store = useProductReportStore();

  const currentYear = parseInt(moment().format('YYYY'));
  const currentMonth = parseInt(moment().format('MM'));

  const years = [...Array(5)].map((value, index) => {
    return currentYear + 1 - (5 - index);
  });
  const months = [...Array(12)].map((value, index) => {
    return 1 + index;
  });
  const endDayInMonth = new Date(
    store.searchQuery?.year || currentYear,
    store.searchQuery?.month || currentMonth,
    0,
  ).getDate();
  const days = [...Array(endDayInMonth || 30)].map((value, index) => {
    return 1 + index;
  });
  const weeks = [...Array(52)].map((value, index) => {
    const start = moment(`${store.searchQuery?.year || currentYear}-01-01`)
      .add(index, 'week')
      .format('YYYY-MM-DD');
    const end = moment(`${store.searchQuery?.year || currentYear}-01-01`)
      .add(index + 1, 'week')
      .format('YYYY-MM-DD');
    return `${start} ~ ${end}`;
  });

  const dialog = useOkCancelDialog();

  useAuthentication();

  const productReportQuery = useQuery({
    queryKey: [`product-counts`, JSON.stringify(store.searchQuery)],
    queryFn: () => api.getProductReport(store.searchQuery!),
  });

  const generateMutation = useMutation({
    mutationFn: api.generateProductReport,
    onSuccess: async () => {
      productReportQuery.refetch();
      const content = `생성했습니다`;
      dialog.open({
        content: content,
        type: 'ok',
      });
    },
    onError: async (e: any) => {
      dialog.open({
        title: '오류',
        content: JSON.stringify(e?.response?.data),
        type: 'ok',
      });
    },
  });

  const productReports = productReportQuery.data?.data;

  useEffect(() => {
    if (productReports) {
      store.setProductReportContainer(productReports);
    }
  }, [productReports]);

  const TABLE_COLUMNS: ColumnsType<ProductCount> = [
    {
      fixed: 'left',
      title: '순번',
      render: (value, record, index: number) => {
        return store.searchResultContainer.page * store.searchResultContainer.size + index + 1;
      },
    },
    {
      fixed: 'left',
      title: '상품 ID',
      render: (record: ProductCount) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(record?._id);
                toast.dark('ID를 클립보드에 복사했습니다', {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  pauseOnFocusLoss: false,
                  draggable: true,
                  progress: undefined,
                  bodyStyle: {
                    color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                    textAlign: 'center',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                  },
                  theme: 'dark',
                });
              }}>
              ID
            </Button>
          </div>
        );
      },
    },
    {
      title: '판매타입',
      render: (record: ProductCount) => {
        return record?.product?.saleType;
      },
    },
    {
      title: '상품명',
      width: 320,
      render: (record: ProductCount) => {
        return record?.product?.title;
      },
    },
    {
      title: '조회수',
      align: 'center',
      render: (record: ProductCount) => {
        const from = record?.from || {};
        return <Tooltip title={JSON.stringify(from, undefined, 2)}>{record?.view}</Tooltip>;
      },
    },
    {
      title: '구매하기\n(옵션)',
      width: 90,
      align: 'center',
      render: (record: ProductCount) => {
        return `${record?.purchaseOption}\n(${record?.conversions?.purchaseOption.toFixed(2)}%)`;
      },
    },
    {
      title: '구매하기\n(결제)',
      width: 90,
      align: 'center',
      render: (record: ProductCount) => {
        return `${record?.purchase}\n(${record?.conversions?.purchase.toFixed(2)}%)`;
      },
    },
    {
      title: '결제',
      width: 90,
      align: 'center',
      render: (record: ProductCount) => {
        return `${record?.payment}\n(${record?.conversions?.payment.toFixed(2)}%)`;
      },
    },
    {
      title: '조회 > 결제\n전환',
      width: 100,
      align: 'center',
      render: (record: ProductCount) => {
        return `${record?.conversions?.total.toFixed(2)}%`;
      },
    },
    {
      title: '결제 금액',
      render: (record: ProductCount) => {
        return `${record?.paymentAmount?.toLocaleString()}원`;
      },
    },
    {
      title: '결제 포인트',
      render: (record: ProductCount) => {
        return `${record?.paymentPointAmount?.toLocaleString()}원`;
      },
    },
    {
      title: '환불',
      render: (record: ProductCount) => {
        return record?.refund;
      },
    },
    {
      title: '환불 금액',
      render: (record: ProductCount) => {
        return `${record?.refundAmount?.toLocaleString()}원`;
      },
    },
    {
      title: '채팅 시작\n(재시작)',
      width: 120,
      render: (record: ProductCount) => {
        return `${record?.chatStart}(${record?.chatReopen})`;
      },
    },
    {
      title: '찜하기',
      width: 80,
      render: (record: ProductCount) => {
        return record?.favorite;
      },
    },
    {
      title: '공유',
      render: (record: ProductCount) => {
        return record?.share;
      },
    },
  ];

  return (
    <div className="dashboard" style={{ position: 'relative' }}>
      <div className="page-header">
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <div>상품 보고서</div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}></div>
      </div>
      <div style={{ display: 'flex' }}>
        <div className="page-content" style={{ width: '100%', height: 'calc(100vh - 80px)', overflow: 'auto' }}>
          <div style={{ display: 'flex', gap: '12px', width: '100%', height: '52px', alignItems: 'center' }}>
            <Select
              placeholder={'정렬'}
              style={{ width: '200px' }}
              value={store.searchQuery?.sort || 'view'}
              options={[
                { value: 'view', label: '조회수' },
                { value: 'purchaseOption', label: '구매하기 클릭(옵션)' },
                { value: 'purchase', label: '구매하기 클릭(결제)' },
                { value: 'payment', label: '결제수' },
                { value: 'paymentAmount', label: '결제금액' },
                { value: 'refund', label: '환불' },
                { value: 'refundAmount', label: '환불금액' },
                { value: 'favorite', label: '찜하기' },
                { value: 'share', label: '공유' },
                { value: 'chatStart', label: '채팅시작' },
                { value: 'chatReopen', label: '채팅재시작' },
                { value: 'conversions.purchaseOption', label: '구매하기 클릭(옵션) 전환' },
                { value: 'conversions.purchase', label: '구매하기 클릭(결제) 전환' },
                { value: 'conversions.payment', label: '결제 전환' },
                { value: 'conversions.total', label: '조회>결제 전환' },
              ]}
              onChange={(value) => {
                store.updateSearchQuery({ sort: value });
              }}></Select>

            <div style={{ display: 'flex', gap: '16px' }}>
              <div>
                <Select
                  placeholder="년"
                  value={store.searchQuery?.year}
                  onChange={(value) => {
                    store.updateSearchQuery({ year: value });
                  }}
                  options={years.map((year) => {
                    return { value: year, label: year };
                  })}></Select>
                년
              </div>
              <div>
                <Select
                  placeholder="월"
                  value={store.searchQuery?.month}
                  onChange={(value) => {
                    store.updateSearchQuery({ month: value, day: undefined });
                  }}
                  options={months.map((month) => {
                    return { value: month, label: month };
                  })}></Select>
                월
              </div>
              <div>
                <Select
                  placeholder="일"
                  value={store.searchQuery?.day}
                  onChange={(value) => {
                    store.updateSearchQuery({ day: value });
                  }}
                  options={days.map((day) => {
                    return { value: day, label: day };
                  })}></Select>
                일
              </div>
              <div>
                <Select
                  style={{ width: '240px' }}
                  placeholder="주"
                  value={store.searchQuery?.week}
                  onChange={(value) => {
                    store.updateSearchQuery({ week: value });
                  }}
                  options={weeks.map((week, index) => {
                    return { value: index + 1, label: week };
                  })}></Select>
              </div>
            </div>
            <Button
              disabled={generateMutation.isPending}
              onClick={() => {
                store.resetSearchQuery();
              }}>
              검색 초기화
            </Button>
            <div style={{ marginLeft: 'auto' }}>
              <Button
                disabled={generateMutation.isPending}
                onClick={() => {
                  if (!store.searchQuery.year || !store.searchQuery.month || !store.searchQuery.day) {
                    dialog.open({
                      content: '서버 부하 이슈로 1일치 데이터만 업데이트할 수 있습니다. 년/월/일 모두 선택해주세요',
                      type: 'ok',
                    });

                    return;
                  }
                  dialog.open({
                    title: '동기화 확인',
                    content: `${store.searchQuery.year}년 ${store.searchQuery.month}월  ${store.searchQuery.day}일 데이터를 업데이트 합니다.`,
                    type: 'ok_cancel',
                    onConfirm: () => {
                      generateMutation.mutate({
                        year: store.searchQuery.year,
                        month: store.searchQuery.month,
                        day: store.searchQuery.day,
                      });
                    },
                  });
                }}>
                데이터 업데이트
              </Button>
              <Button
                onClick={async () => {
                  const fileName =
                    `igogo-product-report-${store.searchQuery?.year}-${store.searchQuery?.month}-${store.searchQuery?.day}|${store.searchQuery?.week}.xlsx`
                      .replace('-undefined', '')
                      .replace('|undefined', '');
                  const blob = await api.getProductReportExcelFile(store.searchQuery!);
                  if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
                    // IE variant
                    (window.navigator as any).msSaveOrOpenBlob(new Blob(blob.data), fileName);
                  } else {
                    const url = window.URL.createObjectURL(blob.data);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode && link.parentNode.removeChild(link);
                  }
                }}>
                결과 엑셀 파일 다운로드
              </Button>
            </div>
          </div>
          <Table
            columns={TABLE_COLUMNS}
            scroll={{ x: 'max-content', y: 'calc(100dvh - 320px)' }}
            style={{ whiteSpace: 'pre-wrap' }}
            rowKey={(record) => record?._id}
            dataSource={store.searchResultContainer.contents.filter((product) => product?._id)}
            pagination={{
              current: (store.searchResultContainer.page || 0) + 1,
              pageSize: store.searchResultContainer.size || 20,
              total: store.searchResultContainer.total,
            }}
            loading={productReportQuery.isLoading}
            onChange={(e) => {
              store.updateSearchQuery({ page: (e.current || 2) - 1, size: e.pageSize || 20 });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductReportPage;
