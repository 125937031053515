import { AdminUser, PartnerProfile, UserProfile } from '@models/auth';
import { PagenatedResponse } from '@models/common';
import { AdminCreditPointLog } from '@models/credit-points';
import { ProductSearchQuery } from '@models/product';
import { apiClient } from '.';

export const searchUsers = async (query?: ProductSearchQuery) => {
  return apiClient.get<PagenatedResponse<AdminUser>>(`/admin/v1/users`, {
    params: query,
  });
};

export const getUser = async (userId: string) => {
  return apiClient.get<AdminUser>(`/admin/v1/users/${userId}`);
};

export const updateUserProfile = async (data: { userId: string; body: Partial<UserProfile> }) => {
  const userId = data.userId;
  return apiClient.put<AdminUser>(`/admin/v1/users/${userId}/profile`, data.body);
};

export const updatePartnerProfile = async (props: { userId: string; body: PartnerProfile }) => {
  return apiClient.patch<AdminUser>(`/admin/v1/users/${props.userId}/partner-profile`, props.body);
};

export const getUserCreditPointLogs = async (userId: string) => {
  return apiClient.get<AdminCreditPointLog[]>(`/admin/v1/users/${userId}/credit-point-logs`);
};

export const updateUserPoint = async (body: {
  userId: string;
  amount: number;
  content: string;
  type: 'add' | 'subtract';
}) => {
  const userId = body.userId;
  return apiClient.put<AdminUser>(`/admin/v1/users/${userId}/point`, body);
};

export const createUser = async (formData: FormData) => {
  return apiClient.post<AdminUser>(`/admin/v1/users`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const updateUser = async (formData: FormData) => {
  const userId = formData.get('userId');
  return apiClient.put<AdminUser>(`/admin/v1/users/${userId}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const getSearchUserssExcelFile = async (query?: ProductSearchQuery) => {
  return apiClient.get(`/admin/v1/users/export`, {
    params: query,
    responseType: 'blob',
    headers: { 'Cache-Control': 'no-cache' },
  });
};
