import { useRef, useState } from 'react';
import { Button, DatePicker, Input, Select, Table, Checkbox, Modal, InputNumber } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as api from '@apis/sales-channels';
import '@components/payment-statement/RefundModeModal.scss';
import {
  PaymentStatementMemo,
  PaymentStatementPayment,
  PaymentStatementSettlementStatus,
  RefundStatus,
} from '@models/paymentStatement';
import { Product, Purchase, SalesChannelOrder } from '@models/sales-channels';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { formatPhoneNumber } from '@utils/dataTransform';
import { localTimeToUtcISOString, utcTimcToLocalISOString } from '@utils/date';
import { REFUND_STATUSES } from './SalesChannelNaverOrdersPage';

enum CancelAttributionType {
  PARTNER = 'PARTNER',
  PURCHASER = 'PURCHASER',
  ETC = 'ETC',
}
enum CancelReasonType {
  PURCHASER_REQUEST = 'PURCHASER_REQUEST',
  RE_BOOKING = 'RE_BOOKING',
  HEALTH_REASON = 'HEALTH_REASON',
  THIRD_PARTY_LOWEST_PRICE = 'THIRD_PARTY_LOWEST_PRICE',
  SIMPLE_REMORSE = 'SIMPLE_REMORSE',
  STORE_SUSPENSION = 'STORE_SUSPENSION',
  BOOKING_CLOSED = 'BOOKING_CLOSED',
  SHORTAGE_OF_PURCHASERS = 'SHORTAGE_OF_PURCHASERS',
  NATURAL_DISASTER = 'NATURAL_DISASTER',
  INFECTIOUS_DISEASE_SAFETY_RULES = 'INFECTIOUS_DISEASE_SAFETY_RULES',
  AUTO_CANCEL = 'AUTO_CANCEL',
}
enum CancellationFeeCalculateType {
  POLICY = 'POLICY',
  AMOUNT = 'AMOUNT',
  RATE = 'RATE',
}

export enum KakaoReservationStatus {
  REQUESTED = 'REQUESTED', // 예약확정대기
  CONFIRMED = 'CONFIRMED', // 예약확정
  COMPLETED = 'COMPLETED', // 이용완료
  CANCELLED = 'CANCELLED', // 예약취소
}

interface CancelReservationInfo {
  type: CancelAttributionType;
  reasonType: CancelReasonType;
  reason?: string;
}
export interface RequestCancelReservation {
  cancellationFeeCalculateType: CancellationFeeCalculateType;
  cancellationFeeRate?: number;
  cancellationFee?: number;
  personInCharge: CancelReservationInfo;
  cancelRequestTargetUnits: {
    bookingPriceUnitId: string;
    status: KakaoReservationStatus;
  }[];
  totalRefundAmount: number;
  isCancelledByAuth: boolean;
}

export interface ChangeReservationStatus {
  bookingItemPriceUnitIds: string[];
}

const ManageKakaoReservationStatusModal: React.FC<{ data?: SalesChannelOrder; onClose: () => void }> = (props) => {
  const [refundTargets, setRefundTargets] = useState<Purchase[]>([]);
  const [refundReason, setRefundReason] = useState<CancelReasonType>();
  const memoRef = useRef<HTMLTextAreaElement>(null);
  const dialog = useOkCancelDialog();
  const queryClient = useQueryClient();
  const refundAmount = refundTargets.reduce<number>((prev, cur) => prev + cur.fee, 0);
  const RESERVATION_STATUS_LABEL = {
    [KakaoReservationStatus.REQUESTED]: '예약확정대기',
    [KakaoReservationStatus.CONFIRMED]: '예약확정',
    [KakaoReservationStatus.COMPLETED]: '이용완료',
    [KakaoReservationStatus.CANCELLED]: '예약취소',
  };
  const REFUND_REASON_FAULTER = {
    [CancelReasonType.PURCHASER_REQUEST]: CancelAttributionType.PURCHASER,
    [CancelReasonType.RE_BOOKING]: CancelAttributionType.PURCHASER,
    [CancelReasonType.HEALTH_REASON]: CancelAttributionType.PURCHASER,
    [CancelReasonType.THIRD_PARTY_LOWEST_PRICE]: CancelAttributionType.PURCHASER,
    [CancelReasonType.SIMPLE_REMORSE]: CancelAttributionType.PURCHASER,
    [CancelReasonType.STORE_SUSPENSION]: CancelAttributionType.PARTNER,
    [CancelReasonType.BOOKING_CLOSED]: CancelAttributionType.PARTNER,
    [CancelReasonType.SHORTAGE_OF_PURCHASERS]: CancelAttributionType.PARTNER,
    [CancelReasonType.NATURAL_DISASTER]: CancelAttributionType.ETC,
    [CancelReasonType.INFECTIOUS_DISEASE_SAFETY_RULES]: CancelAttributionType.ETC,
    [CancelReasonType.AUTO_CANCEL]: CancelAttributionType.ETC,
  };

  const changeReservationStatusMutation = useMutation({
    mutationFn: api.changeKakaoReservationStatus,
    onSuccess: async () => {
      toast.dark('상태 변경에 성공했습니다.', {
        pauseOnFocusLoss: false,
        bodyStyle: {
          textAlign: 'center',
        },
      });

      await queryClient.invalidateQueries({ queryKey: ['kakao-reservation'] });
      props.onClose();
    },
  });

  const getItemByUid = (searchItems: Purchase[], uid: string) => {
    const index = searchItems.findIndex((searchItem) => JSON.stringify(searchItem.uid) === JSON.stringify(uid));
    return searchItems[index];
  };
  const handleChangeStatus = (status: KakaoReservationStatus) => {
    const convertStatus = {
      [KakaoReservationStatus.COMPLETED]: KakaoReservationStatus.CONFIRMED,
      [KakaoReservationStatus.CONFIRMED]: KakaoReservationStatus.COMPLETED,
      [KakaoReservationStatus.CANCELLED]: undefined,
      [KakaoReservationStatus.REQUESTED]: undefined,
    };

    dialog.open({
      type: 'ok_cancel',
      title: '상태 변경',
      content:
        status === KakaoReservationStatus.COMPLETED
          ? '상태값을 이용완료로 변경하시겠습니까?'
          : '상태값 이용 완료를 철회하시겠습니까?',
      onConfirm: async () => {
        const changeStatusTarget =
          props.data?.purchases.filter((refundTarget) => refundTarget.status === convertStatus[status]) || [];

        if (changeStatusTarget.length === 0 || !props.data) {
          toast.dark('상태값 변경이 가능한 항목이 없습니다.', {
            pauseOnFocusLoss: false,
            bodyStyle: {
              textAlign: 'center',
            },
          });
          return;
        }

        changeReservationStatusMutation.mutate({
          type: status === KakaoReservationStatus.COMPLETED ? 'complete' : 'retract-complete',
          bookingId: props.data.uid,
          body: {
            bookingItemPriceUnitIds: changeStatusTarget.map((target) => target.uid as string),
          },
        });
      },
    });
  };
  const submitRefund = () => {
    dialog.open({
      type: 'ok_cancel',
      title: '환불',
      content: `${(
        (props.data?.payment.amount || 0) - (props.data?.refund?.amount || 0)
      ).toLocaleString()}원 중 ${refundAmount.toLocaleString()}원을 환불하시겠습니까?`,
      onConfirm: async () => {
        if (!refundReason || !props.data) {
          toast.dark(refundReason ? '환불할 수 있는 항목이 없습니다.' : '환불 사유를 고르지 않았습니다.', {
            pauseOnFocusLoss: false,
            bodyStyle: {
              textAlign: 'center',
            },
          });
          return;
        }

        const data: RequestCancelReservation = {
          cancellationFeeCalculateType: CancellationFeeCalculateType.AMOUNT,
          cancellationFee: 0,
          personInCharge: {
            type: REFUND_REASON_FAULTER[refundReason],
            reasonType: refundReason,
            reason: memoRef.current?.value || '',
          },
          cancelRequestTargetUnits: refundTargets.map((refundTarget) => ({
            bookingPriceUnitId: refundTarget.uid!,
            status: refundTarget.status as KakaoReservationStatus,
          })),
          totalRefundAmount: refundAmount,
          isCancelledByAuth: true,
        };

        changeReservationStatusMutation.mutate({ type: 'cancel', bookingId: props.data.uid, body: data });
      },
    });
  };

  const CHANGE_STATUS_COLUMNS: ColumnsType<Purchase> = [
    {
      width: 20,
      title: () => (
        <Checkbox
          checked={refundTargets.length === props.data?.purchases.length}
          onChange={(e) => {
            if (e.target.checked) {
              setRefundTargets(props.data?.purchases.map((item) => ({ ...item, count: 0, fee: 0 })) || []);
            } else {
              setRefundTargets([]);
            }
          }}
        />
      ),
      align: 'center',
      render: (_, record) => {
        const refundItemIndex = refundTargets.findIndex((refundTarget) => refundTarget.uid === record.uid);

        return (
          <Checkbox
            checked={refundItemIndex !== -1}
            disabled={(getItemByUid(props.data?.refunds || [], record.uid as string)?.count || 0) === record.count}
            onChange={() => {
              setRefundTargets((prev) => {
                const newValue = [...prev];

                if (refundItemIndex !== -1) {
                  newValue.splice(refundItemIndex, 1);
                } else {
                  newValue.push({ ...record, count: 0, fee: 0 });
                }

                return newValue;
              });
            }}
          />
        );
      },
    },
    {
      width: 200,
      title: '상품명',
      dataIndex: 'name',
      align: 'center',
      render: (name: string) => name,
    },
    {
      width: 70,
      title: '상태',
      dataIndex: 'status',
      align: 'center',
      render: (status: KakaoReservationStatus) => RESERVATION_STATUS_LABEL[status],
    },
    {
      width: 50,
      title: '개당 가격',
      dataIndex: 'fee',
      align: 'center',
      render: (fee: number) => (fee || 0).toLocaleString(),
    },
    {
      width: 30,
      title: '구매 수량',
      dataIndex: 'count',
      align: 'center',
      render: (count: number) => count || 0,
    },
    {
      width: 30,
      title: '남은 수량',
      align: 'center',
      render: (_, record) => record.count - (getItemByUid(props.data?.refunds || [], record.uid as string)?.count || 0),
    },
    {
      width: 20,
      title: '환불 수량',
      dataIndex: 'count',
      align: 'center',
      render: (count: number, record) => {
        const refundItemIndex = refundTargets.findIndex((refundTarget) => refundTarget.uid === record.uid);
        const refundTarget = refundTargets[refundItemIndex];

        return (
          <InputNumber
            value={refundTarget?.count || 0}
            min={0}
            max={count}
            onChange={(value) => {
              setRefundTargets((prev) => {
                const newValue = [...prev];
                newValue[refundItemIndex].count = value || 0;
                newValue[refundItemIndex].fee = (value || 0) * record.fee;

                return newValue;
              });
            }}
            disabled={
              !refundTarget || count === (getItemByUid(props.data?.refunds || [], record.uid as string)?.count || 0)
            }
          />
        );
      },
    },
    {
      width: 140,
      title: '환불 금액',
      dataIndex: 'fee',
      align: 'center',
      render: (fee: number, record) => {
        const refundItemIndex = refundTargets.findIndex((refundTarget) => refundTarget.uid === record.uid);
        const refundTarget = refundTargets[refundItemIndex];

        return (
          <InputNumber
            value={refundTarget?.fee || 0}
            min={0}
            max={fee * (refundTarget?.count || 0)}
            onChange={(value) => {
              setRefundTargets((prev) => {
                const newValue = [...prev];
                newValue[refundItemIndex].fee = value || 0;

                return newValue;
              });
            }}
            disabled={
              !refundTarget ||
              record.count === (getItemByUid(props.data?.refunds || [], record.uid as string)?.count || 0)
            }
            addonAfter={
              <Select
                disabled={!getItemByUid(refundTargets, record.uid as string)}
                value={Math.round(((refundTarget?.fee || 0) / (refundTarget?.count * record.fee)) * 100 || 100)}
                popupMatchSelectWidth={false}
                suffixIcon={null}
                options={Array.from({ length: 20 }, (_, idx) => ({
                  value: 5 * (idx + 1),
                  label: `${5 * (idx + 1)}%`,
                }))}
                onChange={(e) => {
                  setRefundTargets((prev) => {
                    const newValue = [...prev];
                    const refundItem = getItemByUid(newValue, record.uid as string);
                    refundItem.fee = Math.round((record.fee || 0) * (refundTarget?.count || 0) * (e / 100));
                    return newValue;
                  });
                }}
              />
            }
          />
        );
      },
    },
  ];
  return (
    <Modal
      centered
      open={!!props.data}
      footer={null}
      width={1000}
      onCancel={() => props.onClose()}
      destroyOnClose={true}>
      <div>
        <div>
          <h2 className="refund-mode__title">결제 내역(환불 대상)</h2>
          <div style={{ display: 'flex', gap: '8px', marginBottom: '8px' }}>
            <Button onClick={() => handleChangeStatus(KakaoReservationStatus.COMPLETED)}>이용완료</Button>
            <Button onClick={() => handleChangeStatus(KakaoReservationStatus.CONFIRMED)}>이용완료 철회</Button>
          </div>
          {props.data?.payment.amount !== props.data?.payment.billingAmount && (
            <p style={{ fontSize: '12px', color: 'red' }}>
              (쿠폰을 통해 할인 받은 건에 대해서는, 한 항목이라도 "이용완료"가 된 경우 환불이 불가합니다.)
            </p>
          )}
        </div>
        <Table columns={CHANGE_STATUS_COLUMNS} dataSource={props?.data?.purchases || []} pagination={false} />
      </div>
      <div>
        <div>
          <h2 className="refund-mode__title">환불 사유</h2>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.75rem' }}>
          <Select
            value={refundReason}
            placeholder="(필수) 환불 사유 선택"
            popupMatchSelectWidth={false}
            options={[
              {
                value: CancelReasonType.PURCHASER_REQUEST,
                label: '구매자 요청',
              },
              {
                value: CancelReasonType.RE_BOOKING,
                label: '재예약 진행',
              },
              {
                value: CancelReasonType.HEALTH_REASON,
                label: '건강상의 이유',
              },
              {
                value: CancelReasonType.THIRD_PARTY_LOWEST_PRICE,
                label: '타사 최저가 이용',
              },
              {
                value: CancelReasonType.SIMPLE_REMORSE,
                label: '단순 변심',
              },
              {
                value: CancelReasonType.STORE_SUSPENSION,
                label: '장소 운휴',
              },
              {
                value: CancelReasonType.BOOKING_CLOSED,
                label: '예약 마감',
              },
              {
                value: CancelReasonType.SHORTAGE_OF_PURCHASERS,
                label: '모객 부족',
              },
              {
                value: CancelReasonType.NATURAL_DISASTER,
                label: '천재지변',
              },
              {
                value: CancelReasonType.INFECTIOUS_DISEASE_SAFETY_RULES,
                label: '감염병 안전수칙 준수',
              }, // 기타
            ]}
            onChange={(value) => setRefundReason(value)}
            style={{ width: '170px' }}
          />
          <textarea
            className="refund-mode__textarea"
            ref={memoRef}
            rows={2}
            placeholder="(선택) 환불 메모를 입력해 주세요."
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '16px' }}>
          <div>
            <h4 style={{ margin: '8px 0' }}>총 환불 금액</h4>
            <InputNumber size="large" value={refundAmount} disabled={true} />
          </div>
          <div style={{ alignSelf: 'flex-end' }}>
            <button
              className="refund-mode__btn refund-mode__btn--big"
              disabled={
                refundAmount === 0 ||
                (props.data?.payment.amount !== props.data?.payment.billingAmount &&
                  props.data?.purchases.some((purchase) => purchase.status === KakaoReservationStatus.COMPLETED))
              }
              onClick={() => submitRefund()}>
              환불처리
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export interface KakaoReservationsQuery {
  page: number;
  size: number;
  dateType: '' | 'createdAt' | 'updatedAt' | 'plannedDate';
  startDate: string;
  endDate: string;
  refundStatus: '' | RefundStatus;
  settlementStatus: '' | PaymentStatementSettlementStatus;
  keyword: string;
}

const KakaoOrdersPage: React.FC = () => {
  const [activeData, setActiveData] = useState<SalesChannelOrder>();
  const [query, setQuery] = useState<KakaoReservationsQuery>({
    page: 0,
    size: 20,
    dateType: '',
    startDate: '',
    endDate: '',
    refundStatus: '',
    settlementStatus: '',
    keyword: '',
  });

  const kakaoReservationsQuery = useQuery({
    queryKey: [`kakao-reservation`, JSON.stringify(query)],
    queryFn: () => api.searchKakaoReservations(query),
  });

  const TABLE_COLUMNS: ColumnsType<SalesChannelOrder> = [
    {
      width: 120,
      fixed: 'left',
      align: 'center',
      title: '환불',
      render: (_, record) => REFUND_STATUSES[record.statuses.refund] || '-',
    },
    {
      fixed: 'left',
      title: '구매자(수취인)',
      render: (_, record) => {
        if (record.buyer.name !== record.receiver.name) {
          return `${record.buyer.name}(${record.receiver.name})\n${formatPhoneNumber(
            record.buyer.phone,
          )}(${formatPhoneNumber(record.receiver.phone)})`;
        }

        return `${record.buyer.name}\n${formatPhoneNumber(record.buyer.phone)}`;
      },
    },
    {
      fixed: 'left',
      title: '정산일',
      render: (_, record) => {
        if (record.statuses.settlement === PaymentStatementSettlementStatus.DONE) {
          return `${moment(record.settlement?.transferAt).format('YYYY.MM.DD HH:mm')}`;
        }

        return '-';
      },
    },
    {
      title: '판매자',
      render: (_, record) => `${record.seller.name} ${record.seller.id ? '✅' : ''} (${record.sellerUser.role})`,
    },
    {
      width: 400,
      title: '상품명',
      dataIndex: 'product',
      render: (product: Pick<Product, 'name'>) => product.name,
    },
    {
      width: 112,
      title: '수업일',
      dataIndex: 'plannedDate',
      render: (plannedDate: Date) => (plannedDate ? moment(plannedDate).format('YYYY.MM.DD HH:mm') : '-'),
    },
    {
      title: '환불하기',
      dataIndex: 'uid',
      render: (_, record) => <Button onClick={() => setActiveData(record)}>수정</Button>,
    },
    {
      title: '옵션',
      dataIndex: 'purchases',
      render: (purchases: Purchase[]) => <pre>{purchases.map((item) => `${item.name}(${item.count})`).join('\n')}</pre>,
    },
    {
      width: 240,
      title: '메모',
      dataIndex: 'memo',
      render: (memo: PaymentStatementMemo) => {
        const memoList = [
          { label: '결제메모', value: memo.payment },
          { label: '환불메모', value: memo.refundUser },
          { label: '배송메모', value: memo.shipping },
          { label: '부가정보', value: memo.qna },
        ];

        return (
          <div>
            {memoList.map(
              (memoInfo) =>
                memoInfo.value && (
                  <div key={memoInfo.label}>
                    <span style={{ fontWeight: 'bold' }}>{memoInfo.label}: </span>
                    <pre>{memoInfo.value}</pre>
                  </div>
                ),
            )}
          </div>
        );
      },
    },
    {
      width: 100,
      title: '실결제금액',
      dataIndex: 'payment',
      render: (payment: PaymentStatementPayment) => `${payment.billingAmount.toLocaleString()}원`,
    },
    {
      width: 100,
      title: '정산대상금액',
      render: (_, record) => (
        <div>
          <div>{`${record.payment?.amount.toLocaleString()}원`}</div>
          {record?.refund?.amount && record?.refund.amount > 0 && (
            <div style={{ color: 'red' }}>{`-${record?.refund?.amount.toLocaleString()}원`}</div>
          )}
        </div>
      ),
    },
    {
      title: '결제일',
      render: (payment: PaymentStatementPayment) => moment(payment.paidAt).format('YYYY.MM.DD HH:mm'),
    },
    {
      title: '생성',
      dataIndex: 'createdAt',
      render: (createdAt) => moment(createdAt).format('YYYY.MM.DD HH:mm'),
    },
    {
      title: '업데이트',
      dataIndex: 'updatedAt',
      render: (updatedAt) => moment(updatedAt).format('YYYY.MM.DD HH:mm'),
    },
  ];

  return (
    <div>
      <ManageKakaoReservationStatusModal data={activeData} onClose={() => setActiveData(undefined)} />
      <div className="page-header">
        <h2>카카오 예약하기 판매 관리</h2>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <div>
            <Button
              style={{ borderColor: !query.dateType || query.dateType === 'createdAt' ? 'blue' : '#d9d9d9' }}
              onClick={() => {
                setQuery((prev) => ({ ...prev, dateType: 'createdAt' }));
              }}>
              생성일
            </Button>
          </div>
          <div>
            <Button
              style={{ borderColor: query.dateType === 'updatedAt' ? 'blue' : '#d9d9d9' }}
              onClick={() => {
                setQuery((prev) => ({ ...prev, dateType: 'updatedAt' }));
              }}>
              수정일
            </Button>
          </div>
          <div>
            <Button
              style={{ borderColor: query.dateType === 'plannedDate' ? 'blue' : '#d9d9d9' }}
              onClick={() => {
                setQuery((prev) => ({ ...prev, dateType: 'plannedDate' }));
              }}>
              수업일
            </Button>
          </div>
          <div style={{ marginLeft: '10px' }}>
            <DatePicker
              value={dayjs(utcTimcToLocalISOString(query.startDate))}
              onChange={(date) => {
                setQuery((prev) => ({
                  ...prev,
                  startDate: localTimeToUtcISOString(date?.toISOString() || new Date(), { timeToZero: true }) as string,
                }));
              }}
            />
          </div>
          <div style={{ marginLeft: '8px' }}>
            <DatePicker
              value={dayjs(utcTimcToLocalISOString(query.startDate))}
              onChange={(date) => {
                setQuery((prev) => ({
                  ...prev,
                  startDate: localTimeToUtcISOString(date?.toISOString() || new Date(), { timeToZero: true }) as string,
                }));
              }}
            />
          </div>
        </div>
      </div>
      <div className="page-content">
        <div style={{ display: 'flex' }}>
          <Select
            value={query.refundStatus}
            options={[
              { value: '', label: '모든 상태' },
              { value: RefundStatus.ALL, label: '전체 환불' },
              { value: RefundStatus.PARTIAL, label: '부분 환불' },
            ]}
            onChange={(value) => {
              setQuery((prev) => ({ ...prev, refundStatus: value }));
            }}
          />
          <Select
            value={query.settlementStatus}
            options={[
              { value: '', label: '전체' },
              { value: PaymentStatementSettlementStatus.DONE, label: '정산완료' },
            ]}
            onChange={(value) => {
              setQuery((prev) => ({ ...prev, settlementStatus: value }));
            }}
          />
          <Input
            placeholder="검색어 입력 후 엔터"
            onKeyUp={(e) => {
              if (e.code === 'Enter' && e.currentTarget.value) {
                setQuery((prev) => ({ ...prev, keyword: e.currentTarget.value }));
              }
            }}
          />
          <Button
            onClick={() => {
              setQuery((prev) => ({
                page: 0,
                size: prev.size,
                dateType: '',
                startDate: '',
                endDate: '',
                refundStatus: '',
                settlementStatus: '',
                keyword: '',
              }));
            }}>
            검색 초기화
          </Button>
        </div>
        <div>
          <Table
            columns={TABLE_COLUMNS}
            scroll={{ x: 'max-content', y: 'calc(100dvh - 380px)' }}
            style={{ whiteSpace: 'pre-wrap' }}
            rowKey={(record) => record?._id}
            dataSource={kakaoReservationsQuery.data?.data.contents}
            pagination={{
              current: (kakaoReservationsQuery.data?.data?.page || 0) + 1,
              pageSize: kakaoReservationsQuery.data?.data?.size || 20,
              total: kakaoReservationsQuery.data?.data?.total,
            }}
            loading={kakaoReservationsQuery.isLoading}
            onChange={(e) => {
              setQuery((prev) => ({ ...prev, page: (e.current || 2) - 1, size: e.pageSize || 20 }));
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default KakaoOrdersPage;
