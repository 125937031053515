import { FeedData } from '@models/feed';
import { Input } from 'antd';
import React from 'react';
import CategoryEdit from './CategoryEdit';
import CollectionFeedEdit from './CollectionFeedEdit';
import HorizontalBannerEdit from './HorizontalBannerEdit';
import MdPickItemEdit from './MdPickItemEdit';
import PopularKeywordsEdit from './PopularKeywordsEdit';
import ShortcutCellEdit from './ShortcutCellEdit';
import ThemeCellEdit from './ThemeCellEdit';
import VerticalBannerEdit from './VerticalBannerEdit';

export const FEED_TYPE_LABELS: { [key: string]: { label: string; color: string } } = {
  'curation-shortcut': { label: '숏컷', color: '#f1b269' },
  'curation-commerce': { label: '가로형 - 배너', color: '#ec2c83' },
  'curation-vertical': { label: '세로형 - 배너', color: '#a452fe' },
  'curation-class-list': { label: '리스트형 - 기획전', color: '#38a03a' },
  'curation-class-grid': { label: '그리드형 - 기획전', color: '#c3c3a4' },
  'curation-class-general': { label: '일반형 - 기획전', color: '#6c002b' },
  'curation-category': { label: '카테고리', color: '#9f31c8' },
  'curation-tutor-on': { label: '튜터 온', color: '#fd4aee' },
  'curation-search': { label: '검색창', color: '#d2fd1d' },
  'curation-keywords': { label: '인기 검색 키워드', color: '#23f13c' },
  'curation-class-theme': { label: '테마 기획전', color: '#6d95ab' },
  'curation-purchased-list': { label: '결제된 상품 목록', color: '#19d1eb' },
  brand: { label: 'MD PICK', color: '#19d1eb' },
};

export const EditFeedDataView: React.FC<{
  mode: string;
  data: Partial<FeedData>;
  onChange?: (data: Partial<FeedData>) => void;
  onChangeImageFile?: (files: { [key: string]: File }) => void;
}> = (props) => {
  const { mode, data, onChange } = props;

  const type = data.type;
  const title = `${FEED_TYPE_LABELS[data.type!].label} ${mode === 'new' ? '추가' : '수정'}`;

  return (
    <div>
      <div
        style={{
          height: '32px',
          fontWeight: 'bold',
          fontSize: '16px',
          borderBottom: `2px solid ${FEED_TYPE_LABELS[data.type!].color}`,
          marginBottom: '8px',
        }}>
        {title}
      </div>
      <div style={{ height: 'calc(100dvh - 320px)', overflow: 'auto' }}>
        {type === 'curation-vertical' && (
          <VerticalBannerEdit
            clear={!mode}
            data={data}
            onChange={onChange}
            onChangeImageFile={props.onChangeImageFile}></VerticalBannerEdit>
        )}
        {type === 'curation-purchased-list' && (
          <div>
            <Input
              placeholder="타이틀"
              value={data.title}
              onChange={(e) => {
                onChange && onChange({ ...data, title: e.target.value });
              }}></Input>
            <Input.TextArea
              style={{ marginTop: '8px' }}
              placeholder="설명"
              value={data.description}
              onChange={(e) => {
                onChange && onChange({ ...data, description: e.target.value });
              }}></Input.TextArea>
          </div>
        )}
        {type === 'curation-search' && (
          <div>
            <Input
              placeholder="어떤 수업을 찾으세요?"
              value={data.title}
              onChange={(e) => {
                onChange && onChange({ ...data, title: e.target.value });
              }}></Input>
          </div>
        )}
        {type === 'curation-shortcut' && (
          <ShortcutCellEdit
            clear={!mode}
            data={data}
            onChange={onChange}
            onChangeImageFile={props.onChangeImageFile}></ShortcutCellEdit>
        )}
        {type === 'curation-commerce' && (
          <HorizontalBannerEdit
            clear={!mode}
            data={data}
            onChange={onChange}
            onChangeImageFile={props.onChangeImageFile}></HorizontalBannerEdit>
        )}
        {type === 'curation-category' && (
          <CategoryEdit
            clear={!mode}
            data={data}
            onChange={onChange}
            onChangeImageFile={props.onChangeImageFile}></CategoryEdit>
        )}
        {['curation-class-general', 'curation-class-list', 'curation-class-grid'].includes(type || '') && (
          <CollectionFeedEdit clear={!mode} data={data} onChange={onChange}></CollectionFeedEdit>
        )}
        {type === 'curation-keywords' && (
          <PopularKeywordsEdit clear={!mode} data={data} onChange={onChange}></PopularKeywordsEdit>
        )}
        {type === 'curation-class-theme' && (
          <ThemeCellEdit clear={!mode} data={data} onChange={onChange}></ThemeCellEdit>
        )}
        {type === 'curation-tutor-on' && '튜터 온은 수정할 수 없습니다'}
        {type === 'brand' && (
          <MdPickItemEdit
            clear={!mode}
            data={data}
            onChange={onChange}
            onChangeImageFile={props.onChangeImageFile}></MdPickItemEdit>
        )}
      </div>
    </div>
  );
};

export default EditFeedDataView;
