import { usePayment } from '@hooks/payment';
import {
  AdminRefundBody,
  PaymentStatementPurchase,
  RefundData,
  RefundStatus,
  ReserveStatus,
  UpdatePaymentStatementEventType,
} from '@models/paymentStatement';
import { RefundLogStatus } from '@models/refundLog';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { usePaymentStatementModalStore } from '@stores/paymentStatementModalStore';
import { getPaymentMethodText } from '@utils/payment';
import { Modal, Table, Checkbox, InputNumber, Button } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

export const PaymentStatementModal: React.FC = () => {
  const { show, close, data } = usePaymentStatementModalStore();
  const [refundTargets, setRefundTargets] = useState<PaymentStatementPurchase[]>([]);
  const [refundMemo, setRefundMemo] = useState<string>(data?.memo?.refund || '');
  const [refundAmount, setRefundAmount] = useState<number>(0);
  const [noMoreRefundsAvailable, setNoMoreRefundsAvailable] = useState(false);
  const payment = usePayment();
  const dialog = useOkCancelDialog();
  const userMemo = data?.memo?.shipping || data?.memo?.payment || '';
  const paidPoint = data?.payment?.point || 0;
  const paidAmount = `${data?.payment.amount.toLocaleString()}원${
    paidPoint > 0 ? `(${paidPoint.toLocaleString()}P)` : ''
  }`;
  const getRefundStatus = () => {
    const refundStatus = data?.statuses.refund;
    let status = '';
    if (refundStatus === RefundStatus.ALL) {
      status = '전체환불';
    } else if (refundStatus === RefundStatus.PARTIAL) {
      status = '부분환불';
    }
    return status;
  };
  const payType = data?.payment && getPaymentMethodText(data.payment);
  const saleType = data?.product.saleType;
  const refundStatus = getRefundStatus();

  const remainAmount = (data?.payment.amount || 0) - (data?.refund?.amount || 0);
  const getRemainAmount = (remainAmount: number) => {
    const remainPoint = (data?.payment.point || 0) - (data?.refund?.point || 0);
    return `${remainAmount.toLocaleString()}원${remainPoint > 0 ? `(${remainPoint.toLocaleString()}P)` : ''}`;
  };
  const getRefundHistory = () => {
    let refundHistory: RefundData[] = [];
    if (data?.refund?.legacyHistory) {
      refundHistory = [...refundHistory, ...data.refund.legacyHistory];
    }

    if (data?.refund?.data && data.refund.data.length > 0) {
      data.refund.data.forEach((item) => {
        if (item.status === ReserveStatus.DONE && item.refundItems && item.refundItems.length > 0) {
          refundHistory.push(item);
        }
      });
    }
    return refundHistory;
  };
  const getReservedItems = () => {
    let reservedItems: PaymentStatementPurchase[] = [];
    if (data && data?.refund && data?.refund.data && data.refund.data.length > 0) {
      if (data.refund.data[0].status === ReserveStatus.RESERVE && data.refund.data[0].refundItems) {
        reservedItems = reservedItems.concat(data.refund.data[0].refundItems);
      } else if (data.refund.data[0].status === RefundLogStatus.RESERVED && data.refund.data[0].refundItems) {
        reservedItems = reservedItems.concat(data.refund.data[0].refundItems);
      }
    }
    return reservedItems;
  };
  const refundedHitory = getRefundHistory();
  const reservedItems = getReservedItems();
  const remainAmountText = getRemainAmount(remainAmount);

  useEffect(() => {
    setRefundTargets([]);
    if (data && data.memo && data.memo.refund) {
      setRefundMemo(data.memo.refund);
    }
  }, [show]);

  useEffect(() => {
    let reserveRefundAmount = 0;
    if (data?.refund?.data && data.refund.data.length > 0) {
      if (data.refund.data[0].status === ReserveStatus.RESERVE && data.refund.data[0].refundItems) {
        data.refund.data[0].refundItems.forEach((item) => {
          reserveRefundAmount += (item?.count || 0) * (item.fee || 0);
        });
      }
    }
    let refundAmount = 0;
    refundTargets.forEach((item) => {
      refundAmount += (item?.count || 0) * (item.fee || 0);
    });
    setRefundAmount(refundAmount + reserveRefundAmount);
  }, [refundTargets]);

  const getIsIncludeOption = (target: PaymentStatementPurchase) => {
    let isInclude = false;
    let index = 0;
    refundTargets.some((item, idx) => {
      if (JSON.stringify(item.name) === JSON.stringify(target.name)) {
        isInclude = true;
        index = idx;
        return true;
      }
    });
    return {
      isInclude,
      index,
    };
  };

  const getOptionName = (item: any) => {
    const option = item.option && item.option.length > 0 ? `[${item.option.join(' ')}]` : '';
    const name = typeof item.name === 'object' ? item.name.join('|') : item.name;
    return `${option}${name}(${item.count})`;
  };

  const toggleRefundTargets = (target: PaymentStatementPurchase) => {
    const result = getIsIncludeOption(target);
    setRefundTargets((prev) => {
      const newValue = [...prev];
      if (result.isInclude) {
        newValue.splice(result.index, 1);
      } else {
        newValue.push(target);
      }
      if (newValue.length === data?.purchases.length) setNoMoreRefundsAvailable(true);
      return newValue;
    });
  };

  const reserveRefundPayment = () => {
    const canRefund = checkCanRefund();
    if (data && refundTargets.length > 0 && canRefund) {
      payment.updateRefundStatus(data._id, UpdatePaymentStatementEventType.RESERVE, refundTargets);
      close();
      setRefundTargets([]);
      setRefundMemo('');
    } else {
      dialog.open({
        type: 'ok',
        confirmColor: '#FF3D8F',
        title: '실패했습니다.',
        content: !canRefund ? '환불 금액을 확인해주세요!' : '상품을 선택해주세요!',
      });
    }
  };

  const cancelReserveRefundPayment = () => {
    if (data) {
      const newData = { ...data };
      if (newData.refund && newData.refund.data) {
        payment.updateRefundStatus(data._id, UpdatePaymentStatementEventType.REMOVE_RESERVE);
        setRefundMemo('');
        setRefundTargets([]);
        close();
      }
    }
  };

  const refundColumns: ColumnsType<PaymentStatementPurchase> = [
    {
      title: '선택',
      width: 20,
      render: (item) => {
        const isReserved = reservedItems.length > 0;
        const result = getIsIncludeOption(item);
        const newItem = { ...item };
        return (
          <Checkbox
            disabled={isReserved}
            checked={result.isInclude}
            onChange={() => {
              toggleRefundTargets(newItem);
            }}
          />
        );
      },
      align: 'center',
    },
    {
      title: '상품명',
      width: 200,
      render: (item) => {
        let option = '';
        if (item.option && item.option.length > 0) {
          option = `[${item.option.join(' ')}]`;
        }
        return option + item.name;
      },
      align: 'center',
    },
    {
      title: '개당 가격',
      width: 50,
      render: (item) => `${(item.fee || 0).toLocaleString()}`,
      align: 'center',
    },
    {
      title: '구매 수량',
      width: 30,
      render: (item) => item.count || 0,
      align: 'center',
    },
    {
      title: '환불 수량',
      width: 30,
      render: (text, item, index) => renderRefundOptionCount(item, index),
      align: 'center',
    },
    {
      title: '환불 가격',
      width: 30,
      render: (text, item, index) => renderRefundOptionFee(item, index),
      align: 'center',
    },
  ];

  const refundReserveColumns: ColumnsType<PaymentStatementPurchase> = [
    {
      title: '선택',
      width: 20,
      render: () => {
        return '-';
      },
      align: 'center',
    },
    {
      title: '상품명',
      width: 200,
      render: (item) => {
        let option = '';
        if (item.option && item.option.length > 0) {
          option = `[${item.option.join(' ')}]`;
        }
        return option + item.name;
      },
      align: 'center',
    },
    {
      title: '개당 가격',
      width: 50,
      render: (item) => `${(item.fee || 0).toLocaleString()}`,
      align: 'center',
    },
    {
      title: '구매 수량',
      width: 30,
      render: (item) => item.count || 0,
      align: 'center',
    },
    {
      title: '환불 수량',
      width: 30,
      render: (text, item, index) => renderRefundOptionCount(item, index, true),
      align: 'center',
    },
    {
      title: '환불 가격',
      width: 30,
      render: (text, item, index) => renderRefundOptionFee(item, index, true),
      align: 'center',
    },
  ];

  const renderRefundOptionCount = (item: PaymentStatementPurchase, index: number, disable?: boolean) => {
    const result = getIsIncludeOption(item);
    let value = (result.isInclude && refundTargets[result.index]?.count) || 0;
    if (disable) {
      value = item.count || 0;
    }
    return (
      <div>
        <InputNumber
          defaultValue={item.count}
          value={value}
          onChange={(value) => {
            setRefundTargets((prev) => {
              const newValue = [...prev];
              newValue[result.index].count = value || 0;
              return newValue;
            });
          }}
          step={1}
          min={0}
          max={item.count}
          disabled={!result.isInclude || disable}
          formatter={(value) => `${value?.toLocaleString()}`}
        />
      </div>
    );
  };

  const renderRefundOptionFee = (item: PaymentStatementPurchase, index: number, disable?: boolean) => {
    const result = getIsIncludeOption(item);
    const maxAmount = (item?.count || 0) * (item?.fee || 0);

    let value =
      (result.isInclude && (refundTargets[result.index]?.count || 0) * (refundTargets[result.index]?.fee || 0)) || 0;
    if (disable) {
      value = (item.count || 0) * (item.fee || 0);
    }
    return (
      <div>
        <InputNumber
          name="optionFee"
          defaultValue={maxAmount}
          value={value}
          onChange={(value) => {
            if (value !== null) {
              setRefundTargets((prev) => {
                const newValue = [...prev];
                newValue[result.index].fee = value || 0;
                return newValue;
              });
            }
          }}
          step={100}
          min={0}
          max={maxAmount}
          disabled={!result.isInclude || disable}
          formatter={(value) => `${value?.toLocaleString()}`}
        />
      </div>
    );
  };

  const checkCanRefund = () => {
    const paidAmount = data?.payment.amount || 0;
    const beforeRefundedAmount = (data?.refund && data.refund.amount) || 0;
    const canRefundAmount = paidAmount - beforeRefundedAmount;
    const refundItems = refundTargets;
    let requestRefundAmount = 0;
    refundItems.forEach((item) => {
      requestRefundAmount += (item.count || 0) * (item.fee || 0);
    });
    const isCanRefund = canRefundAmount - requestRefundAmount >= 0 && refundAmount > 0;
    return isCanRefund;
  };

  const onRefundCommerceType = () => {
    const refundItems = reservedItems.length > 0 ? reservedItems : refundTargets;
    const fee = refundItems.reduce((prev, cur) => prev + cur.count * (cur.fee || 0), 0);

    const canRefund = checkCanRefund();
    if (data && refundItems.length > 0 && canRefund) {
      const newData: AdminRefundBody = {
        fee,
        memo: refundMemo,
        refundItems,
        noMoreRefundsAvailable: noMoreRefundsAvailable,
      };
      payment.requestRefundWithBody(data._id, newData);
      close();
      setRefundTargets([]);
      setRefundMemo('');
    } else {
      dialog.open({
        type: 'ok',
        confirmColor: '#FF3D8F',
        title: '실패했습니다.',
        content: !canRefund ? '환불 금액을 확인해주세요!' : '상품을 선택해주세요!',
      });
    }
  };

  const onRefundClassType = () => {
    if (refundAmount > remainAmount || refundAmount <= 0) {
      dialog.open({
        type: 'ok',
        confirmColor: '#FF3D8F',
        title: '실패했습니다.',
        content: '환불 금액을 확인해주세요!',
      });
      return;
    } else {
      if (data) {
        const newData: AdminRefundBody = {
          fee: refundAmount,
          memo: refundMemo,
          refundItems: [{ fee: refundAmount, count: 1 }],
          noMoreRefundsAvailable: noMoreRefundsAvailable,
        };
        payment.requestRefundWithBody(data._id, newData);
        close();
        setRefundMemo('');
      }
    }
  };

  return (
    <Modal
      centered
      open={show}
      closable={false}
      footer={null}
      width={1000}
      onCancel={() => {
        close();
        setRefundMemo('');
        setRefundTargets([]);
      }}>
      <div>
        <div style={{ fontSize: '20px', fontWeight: 'bold' }}>결제 정보</div>
        <div style={{ padding: '20px', backgroundColor: '#E0F0FF' }}>
          <div>[결제내역]</div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              margin: '10px 0',
            }}>
            <div
              style={{
                backgroundColor: '#c5e0ff',
                borderRadius: '8px',
                padding: '10px',
                width: userMemo ? '60%' : '100%',
              }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <div style={{ fontWeight: 'bold' }}>구매자: &nbsp;</div>
                <div>{data?.buyer.name}</div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <div style={{ fontWeight: 'bold' }}>연락처: &nbsp;</div>
                <div>{data?.buyer.phone}</div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <div style={{ fontWeight: 'bold' }}>결제 금액: &nbsp;</div>
                <div>{paidAmount}</div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <div style={{ fontWeight: 'bold' }}>결제 수단: &nbsp;</div>
                <div>{payType}</div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <div style={{ fontWeight: 'bold' }}>결제 시각: &nbsp;</div>
                <div>{moment(data?.payment.paidAt).format('YYYY-MM-DD HH:mm')}</div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                }}>
                <div style={{ fontWeight: 'bold' }}>입력 옵션: &nbsp;</div>
                <div style={{ whiteSpace: 'pre-wrap' }}>{data?.memo?.qna || '-'}</div>
              </div>
              <div style={{ fontWeight: 'bold' }}>구매상품 정보</div>
              <div>{data?.product.name}</div>
              {saleType === 'commerce' &&
                (data?.purchases || []).map((item) => {
                  return <div>{getOptionName(item)}</div>;
                })}
            </div>
            {data?.shipping?.address ? (
              <div
                style={{
                  width: '40%',
                  marginLeft: '10px',
                  padding: '10px',
                  backgroundColor: '#c5e0ff',
                  borderRadius: '8px',
                }}>
                <div>[배송 정보]</div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <div style={{ marginTop: '10px', fontWeight: 'bold' }}>받는사람: &nbsp;</div>
                  <div style={{ marginTop: '10px' }}>{data?.shipping.name}</div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <div style={{ fontWeight: 'bold' }}>연락처: &nbsp;</div>
                  <div style={{ marginTop: 0 }}>{data?.shipping.phone}</div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <div style={{ fontWeight: 'bold' }}>배송지: &nbsp;</div>
                  <div>{data?.shipping.address}</div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <div style={{ fontWeight: 'bold' }}>요청사항: &nbsp;</div>
                  <div style={{ flexWrap: 'wrap' }}>{userMemo}</div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <div style={{ fontWeight: 'bold' }}>배송상태: &nbsp;</div>
                  <div style={{ flexWrap: 'wrap' }}>{data.statuses.shipping}</div>
                </div>
              </div>
            ) : null}
            {userMemo ? (
              <div
                style={{
                  width: '40%',
                  marginLeft: '10px',
                  padding: '10px',
                  backgroundColor: '#c5e0ff',
                  borderRadius: '8px',
                }}>
                <div style={{ fontWeight: 'bold' }}>요청 사항</div>
                <div style={{ flexWrap: 'wrap' }}>{userMemo}</div>
              </div>
            ) : null}
          </div>
          {refundedHitory.length > 0 && (
            <div style={{ margin: '10px 0' }}>
              <div>[환불내역] - {refundStatus}</div>
              {refundedHitory.map((item: RefundData) => {
                const refundedAmount = `${item.amount?.toLocaleString()}원${
                  item.point ? `(${item.point.toLocaleString()}P)` : ''
                }`;
                return (
                  <div
                    style={{
                      backgroundColor: '#c5e0ff',
                      borderRadius: '8px',
                      padding: '10px',
                      marginTop: '10px',
                    }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}>
                      <div style={{ fontWeight: 'bold' }}>환불 금액: &nbsp;</div>
                      <div>{refundedAmount}</div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}>
                      <div style={{ fontWeight: 'bold' }}>환불 시각: &nbsp;</div>
                      <div>{`${moment(item.createdAt).format('YY-MM-DD HH:mm')}`}</div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-end',
                        justifyContent: 'space-between',
                      }}>
                      {saleType === 'commerce' && (
                        <div>
                          {item.refundItems &&
                            item.refundItems.length > 0 &&
                            item.refundItems.map((cur: any) => {
                              let selectOptionName = '';
                              if (cur.option && cur.option.length > 0) {
                                selectOptionName = cur.option.join(' ') + ' - ';
                              }

                              let optionName = cur.name;
                              if (typeof optionName == 'object') {
                                optionName = optionName.join(' / ');
                              }
                              return (
                                <div>
                                  &nbsp;&nbsp;- {selectOptionName}
                                  {optionName}({cur.count})
                                </div>
                              );
                            })}
                        </div>
                      )}
                      {item.receiptUrl && item.receiptUrl != '' ? (
                        <div>
                          <div
                            style={{ cursor: 'pointer', color: '#0c8eff' }}
                            onClick={() => {
                              window.open(`${item.receiptUrl}`, '_blank', 'width=600, height=400, top= 100, left=-50');
                            }}>
                            [환불영수증]
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {remainAmount > 0 && (
            <div
              style={{
                backgroundColor: '#c5e0ff',
                borderRadius: '8px',
                padding: '10px',
                marginTop: '10px',
                fontWeight: 'bold',
                fontSize: '16px',
                textAlign: 'right',
              }}>
              잔여금액: {remainAmountText}
            </div>
          )}
        </div>
        {saleType === 'commerce' && (
          <div>
            {reservedItems.length > 0 && (
              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: '10px',
                  }}>
                  <div style={{ fontSize: '20px', fontWeight: 'bold' }}>환불 예약 내역</div>
                  <Button style={{ backgroundColor: '#0C8EFF', color: '#ffffff' }} onClick={cancelReserveRefundPayment}>
                    환불예약 취소
                  </Button>
                </div>
                <Table columns={refundReserveColumns} dataSource={reservedItems} pagination={false} />
              </div>
            )}
          </div>
        )}
        {remainAmount > 0 && (
          <div>
            {saleType === 'commerce' && (
              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: '10px',
                    marginTop: '20px',
                  }}>
                  <div
                    style={{
                      fontSize: '20px',
                      fontWeight: 'bold',
                    }}>
                    결제 내역(환불 대상)
                  </div>
                  {reservedItems.length < 1 && (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                      <Checkbox
                        checked={noMoreRefundsAvailable}
                        onChange={() => setNoMoreRefundsAvailable(!noMoreRefundsAvailable)}>
                        추가 환불 불가
                      </Checkbox>
                      <Button style={{ backgroundColor: '#0C8EFF', color: '#ffffff' }} onClick={reserveRefundPayment}>
                        환불예약
                      </Button>
                    </div>
                  )}
                </div>
                <Table columns={refundColumns} dataSource={data?.purchases || []} pagination={false} />
              </div>
            )}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              <div
                style={{
                  marginTop: '10px',
                  display: 'flex',
                  flex: 1,
                  marginRight: '50px',
                  flexDirection: 'column',
                }}>
                <div
                  style={{
                    fontWeight: 'bold',
                    fontSize: '14px',
                    marginBottom: '5px',
                  }}>
                  환불메모
                </div>
                <TextArea
                  placeholder="(선택) 환불 메모를 입력해 주세요!"
                  value={refundMemo}
                  minLength={2}
                  onChange={(e) => {
                    setRefundMemo(e.target.value);
                  }}></TextArea>
              </div>
              <div
                style={{
                  width: 300,
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                <div
                  style={{
                    marginTop: '10px',
                    marginBottom: '5px',
                    fontWeight: 'bold',
                    fontSize: '14px',
                  }}>
                  총 환불 금액
                </div>
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    height: 40,
                    marginTop: 5,
                  }}>
                  <InputNumber
                    placeholder="총 환불 금액"
                    value={refundAmount || 0}
                    size="large"
                    style={{ height: '40px' }}
                    onChange={(value) => {
                      if (value !== null && value <= remainAmount) {
                        setRefundAmount(value);
                      }
                    }}
                    max={remainAmount}
                    disabled={(saleType === 'commerce' && refundTargets.length < 1) || remainAmount <= 0}
                  />
                  <div
                    style={{
                      backgroundColor: '#0C8EFF',
                      color: '#ffffff',
                      marginLeft: '20px',
                      borderRadius: '8px',
                      cursor: 'pointer',
                      width: '150px',
                      textAlign: 'center',
                      verticalAlign: 'center',
                      fontSize: '15px',
                      height: '45px',
                      alignSelf: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingLeft: '20px',
                      paddingRight: '20px',
                    }}
                    onClick={() => {
                      if (data?.product.saleType === 'commerce') {
                        if (reservedItems.length > 0) {
                          if (confirm('예약내역을 환불합니다.')) {
                            onRefundCommerceType();
                          }
                        } else {
                          onRefundCommerceType();
                        }
                      } else {
                        onRefundClassType();
                      }
                    }}>
                    환불처리
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
