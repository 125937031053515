import * as api from '@apis/class-invoices';
import { ROLE_LABELS } from '@consts/role';
import { useAppSocket } from '@hooks/appSocket';
import { useAuthentication } from '@hooks/authentication';
import { ClassInvoice } from '@models/invoice';
import { PaymentStatement } from '@models/paymentStatement';
import { useClassInvoiceModalStore } from '@stores/classInvoiceModalStore';
import { useClassInvoicesStore } from '@stores/classInvoicesStore';
import { useContentLoaderStore } from '@stores/contentLoaderStore';
import { useQuery } from '@tanstack/react-query';
import { getAllChildrenTextFromChildren } from '@utils/dataTransform';
import { Button, Input, Popover, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

const CLASS_INVOICE_STATUS_STYLES: { [key: string]: { label: string; backgroundColor: string; color: string } } = {
  BEFORE_CREATE_STATUS: { label: '수강증 생성', backgroundColor: '#bdd6fc', color: '#242424' },
  BEFORE_APPROVED_STATUS: { label: '결제 요청', backgroundColor: '#659ffc', color: 'white' },
  ONGOING_STATUS: { label: '결제 완료', backgroundColor: '#1855b8', color: 'white' },
  CANCELED_STATUS: { label: '결제 취소', backgroundColor: '#f2559e', color: 'white' },
  COMPLETE_STATUS: { label: '수업 종료', backgroundColor: '#23b823', color: 'white' },
  waiting_for_payment: { label: '결제 요청', backgroundColor: '#659ffc', color: 'white' },
  payment_completed: { label: '결제 완료', backgroundColor: '#1855b8', color: 'white' },
  payment_canceled: { label: '결제 취소', backgroundColor: '#f2559e', color: 'white' },
  payment_refund: { label: '환불됨', backgroundColor: '#444744', color: 'white' },
  class_completed: { label: '수업 종료', backgroundColor: '#23b823', color: 'white' },
};

export const ClassInvoicesPage: React.FC = () => {
  const [searchKeyword, setSearchKeyword] = useState<string>();
  const searchInputRef = useRef<any>();
  const appSocket = useAppSocket();
  const store = useClassInvoicesStore();
  const loader = useContentLoaderStore();
  const classInvoiceModalStore = useClassInvoiceModalStore();
  useAuthentication();

  const classInvoiceSerchQuery = useQuery({
    queryKey: [`admin-search-class-invoices`, JSON.stringify(store.searchQuery)],
    queryFn: () => api.searchClassInvoices(store.searchQuery),
  });

  useEffect(() => {
    const searchResult = classInvoiceSerchQuery.data?.data;
    if (searchResult) {
      store.setClassInvoicesContainer(searchResult);
    }
  }, [classInvoiceSerchQuery.data?.data]);

  useEffect(() => {
    appSocket.socket.addSocketEventListener('upload_excel_status', 'upload_excel_status', (data) => {
      const status = (data.status || '').toLowerCase();
      if (status === 'done') {
        classInvoiceSerchQuery.refetch();
      }
      if (status === 'done' || status === 'error') {
        loader.off();
      }

      if (data.status) {
        toast.error(data.message, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    });
  }, []);

  window.onbeforeunload = () => {
    appSocket.socket.removeSocketEventListener('upload_excel_status', 'upload_excel_status');
  };

  const TABLE_COLUMNS: ColumnsType<ClassInvoice> = [
    {
      title: 'ID',
      dataIndex: '_id',
      render: (_id) => {
        return (
          <Button
            onClick={() => {
              navigator.clipboard.writeText(_id);
              toast.dark('수강증 ID를 클립보드에 복사했습니다', {
                position: 'top-center',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                pauseOnFocusLoss: false,
                draggable: true,
                progress: undefined,
                bodyStyle: {
                  color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                  textAlign: 'center',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '20px',
                },
                theme: 'dark',
              });
            }}>
            ID 복사
          </Button>
        );
      },
    },
    {
      title: '판매자',
      dataIndex: 'tutorName',
      render: (tutorName) => {
        return <div>{tutorName}</div>;
      },
    },
    {
      title: '클래스명',
      dataIndex: 'classTitle',
      render: (title) => {
        return (
          <Popover content={title}>
            <div style={{ width: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {title}
            </div>
          </Popover>
        );
      },
    },
    {
      title: '구매자',
      dataIndex: 'buyer',
      render: (buyer) => {
        return (
          <div>
            <div>{buyer?.profile?.name}</div>
            <div>{buyer?.profile?.phone}</div>
          </div>
        );
      },
    },
    {
      title: '수강 상태',
      dataIndex: 'status',
      render: (status) => {
        if (!CLASS_INVOICE_STATUS_STYLES[status]) {
          return status;
        }
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: '13px',
              width: '80px',
              backgroundColor: CLASS_INVOICE_STATUS_STYLES[status].backgroundColor,
              color: CLASS_INVOICE_STATUS_STYLES[status].color,
              padding: '4px 0 ',
              borderRadius: '4px',
            }}>
            {CLASS_INVOICE_STATUS_STYLES[status].label}
          </div>
        );
      },
    },
    {
      title: '결제 상태',
      dataIndex: 'paymentStatement',
      render: (paymentStatement: PaymentStatement) => {
        if (!paymentStatement) {
          return '-';
        }
        return (
          <div style={{ width: '80px' }}>
            <div>결제: {paymentStatement.statuses.payment}</div>
            <div>환불: {paymentStatement.statuses.refund}</div>
            <div>정산: {paymentStatement.statuses.settlement}</div>
            <div>배송: {paymentStatement.statuses.shipping}</div>
          </div>
        );
      },
    },
    {
      title: '결제요청 금액',
      width: 120,
      dataIndex: 'classFeePerTime',
      render: (classFeePerTime, record) => {
        const requestedFee = record.requestedFee || 0;
        if (classFeePerTime) {
          return <div>{requestedFee.toLocaleString()}원</div>;
        }
        return (
          <div>
            {requestedFee.toLocaleString()}
            원(구)
          </div>
        );
      },
    },
    {
      title: '원가',
      dataIndex: 'classFeePerTime',
      render: (classFeePerTime, record) => {
        if (classFeePerTime) {
          return <div>{(record.classOriginFee || 0).toLocaleString()}원</div>;
        }

        const classFeePerTimes = (record.classOriginFee || 1) / 4;
        const studentCount = (record.children?.length || 0) + (record.otherChildCount || 0);

        const classOriginFee = classFeePerTimes * record.times * studentCount;
        return (
          <div>
            {classOriginFee.toLocaleString()}
            원(구)
          </div>
        );
      },
    },
    {
      title: '그룹할인',
      dataIndex: 'applyGroupDiscount',
      render: (applyGroupDiscount, record) => {
        return <div>{applyGroupDiscount || record.isGroupSales ? '⭕' : '✖️'}</div>;
      },
      width: 84,
    },
    {
      title: '회차당 금액',
      dataIndex: 'classFeePerTime',
      render: (classFeePerTime, record) => {
        if (classFeePerTime) {
          return <div>{(classFeePerTime || 0).toLocaleString()}원</div>;
        }
        return <div>{((record.classOriginFee || 1) / 4).toLocaleString()}원(구)</div>;
      },
    },
    {
      title: '수강생',
      dataIndex: 'children',
      render: (children, record) => {
        return (
          <div style={{ whiteSpace: 'pre-wrap', width: '80px' }}>
            {getAllChildrenTextFromChildren(children || [], record.additionalParticipantCount || 0)}
          </div>
        );
      },
    },
    {
      title: '총회차',
      dataIndex: 'times',
      render: (times) => {
        return <div>{times}회</div>;
      },
      width: 72,
    },
    {
      title: '수업시간',
      dataIndex: 'minutes',
      render: (minutes) => {
        return <div>{minutes}분</div>;
      },
      width: 84,
    },
    {
      title: '부모 수강증',
      dataIndex: '_id',
      render: (_id, record) => {
        return (
          <div>
            <div>
              <Button
                onClick={() => {
                  classInvoiceModalStore.open({
                    classId: record.classId || record.programId,
                    classInvoiceId: _id,
                    mode: 'view',
                    isTutor: false,
                  });
                }}>
                보기 모드
              </Button>
            </div>
            <div>
              <Button
                onClick={() => {
                  classInvoiceModalStore.open({
                    classId: record.classId || record.programId,
                    classInvoiceId: _id,
                    mode: 'payment',
                    isTutor: false,
                  });
                }}>
                결제 모드
              </Button>
            </div>
          </div>
        );
      },
    },
    {
      title: '튜터 수강증',
      dataIndex: '_id',
      render: (_id, record) => {
        return (
          <div>
            <div>
              <Button
                onClick={() => {
                  classInvoiceModalStore.open({
                    classId: record.classId || record.programId,
                    classInvoiceId: _id,
                    mode: 'view',
                    isTutor: true,
                  });
                }}>
                보기 모드
              </Button>
            </div>
            <div>
              <Button
                onClick={() => {
                  classInvoiceModalStore.open({
                    classId: record.classId || record.programId,
                    classInvoiceId: _id,
                    mode: 'class_schedule_edit',
                    isTutor: true,
                  });
                }}>
                수정 모드
              </Button>
            </div>
            <div>
              <Button
                onClick={() => {
                  classInvoiceModalStore.open({
                    classId: record.classId || record.programId,
                    parentId: record.parentId || record.userId,
                    mode: 'create',
                    isTutor: true,
                  });
                }}>
                생성 모드
              </Button>
            </div>
          </div>
        );
      },
    },
    {
      title: '수업 시작일',
      dataIndex: 'firstStartDate',
      render: (firstStartDate) => {
        return <div>{moment(firstStartDate).format('YYYY.MM.DD HH:mm')}</div>;
      },
    },
    {
      title: '수업 종료일',
      dataIndex: 'lastStartDate',
      render: (firstStartDate) => {
        return <div>{moment(firstStartDate).format('YYYY.MM.DD HH:mm')}</div>;
      },
    },
    {
      title: '생성',
      dataIndex: 'createdAt',
      render: (createdAt) => {
        return <div>{moment(createdAt).format('YYYY.MM.DD HH:mm')}</div>;
      },
    },
  ];

  return (
    <div className="dashboard" style={{ position: 'relative' }}>
      <div className="page-header">
        <div>클래스 수강증 관리</div>
      </div>
      <div style={{ display: 'flex' }}>
        <div className="page-content" style={{ width: '100%', height: 'calc(100vh - 80px)', overflow: 'auto' }}>
          <div style={{ display: 'flex', gap: '12px', width: '100%', height: '52px', alignItems: 'center' }}>
            <Select
              defaultValue={store.searchQuery.type || 'all'}
              style={{ width: 120 }}
              onChange={(value) => {
                store.updateSearchQuery({ type: value });
              }}
              options={[
                { value: 'all', label: '전체' },
                { value: 'parent', label: ROLE_LABELS.parent },
                { value: 'teacher', label: ROLE_LABELS.teacher },
                { value: 'seller', label: ROLE_LABELS.seller },
                { value: 'subadmin', label: ROLE_LABELS.subadmin },
                { value: 'saleadmin', label: ROLE_LABELS.saleadmin },
                { value: 'admin', label: ROLE_LABELS.admin },
              ]}
            />
            <Input
              ref={searchInputRef}
              style={{ width: '240px' }}
              value={searchKeyword}
              placeholder={'검색어 입력 후 엔터'}
              onChange={(e) => {
                setSearchKeyword(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.code === 'Enter' && searchKeyword) {
                  store.updateSearchQuery({ keyword: searchKeyword });
                }
              }}></Input>

            <div style={{ display: 'flex', marginLeft: 'auto', gap: '12px', alignItems: 'center' }}>
              <Button
                onClick={() => {
                  setSearchKeyword(undefined);
                  store.resetSearchQuery();
                }}>
                검색 초기화
              </Button>
            </div>
          </div>
          <Table
            columns={TABLE_COLUMNS}
            scroll={{ x: 'max-content', y: 'calc(100dvh - 280px)' }}
            style={{ whiteSpace: 'pre-wrap' }}
            rowKey={'_id'}
            expandable={{ showExpandColumn: false }}
            dataSource={store.searchResultContainer.contents}
            pagination={{
              current: (store.searchResultContainer.page || 0) + 1,
              pageSize: store.searchResultContainer.size || 20,
              total: store.searchResultContainer.total,
            }}
            loading={classInvoiceSerchQuery.isLoading}
            onChange={(e) => {
              store.updateSearchQuery({ page: (e.current || 2) - 1, size: e.pageSize || 20 });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ClassInvoicesPage;
