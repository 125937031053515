import * as api from '@apis/sales-channels';
import { useAppSocket } from '@hooks/appSocket';
import { useAuthentication } from '@hooks/authentication';
import { NaverCommerceProduct } from '@models/sales-channels';
import { useNaverCommerceProductsStore } from '@stores/naverCommerceProductsStore';
import { useQuery } from '@tanstack/react-query';
import { localTimeToUtcISOString, utcTimcToLocalISOString } from '@utils/date';
import { Button, Checkbox, DatePicker, DatePickerProps, Input, Modal, Progress, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

export const NaverCommerceProductsPage: React.FC = () => {
  const appSocket = useAppSocket();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [searchKeyword, setSearchKeyword] = useState<string>();
  const [showSyncModal, setShowSyncModal] = useState<boolean>(false);
  const [syncState, setSyncState] = useState<{
    status: string;
    message: string;
    current?: number;
    total?: number;
  }>({ status: 'ready', message: '' });
  const searchInputRef = useRef<any>();
  const store = useNaverCommerceProductsStore();

  useAuthentication();

  useEffect(() => {
    if (!searchKeyword && store.searchQuery.keyword) {
      setSearchKeyword(store.searchQuery.keyword);
    }
  }, [store.searchQuery.keyword]);

  const naverCommerceProductsQuery = useQuery({
    queryKey: [`naver-commerce-products`, JSON.stringify(store.searchQuery)],
    queryFn: () => api.searchNaverCommerceProducts(store.searchQuery),
  });

  const naverCommerceProducts = naverCommerceProductsQuery.data?.data;

  useEffect(() => {
    if (naverCommerceProducts) {
      store.setNaverCommerceProductsContainer(naverCommerceProducts);
    }
  }, [naverCommerceProducts]);

  const onNaverCommerceProductsStatus = (data: {
    status: string;
    message: string;
    current?: number;
    total?: number;
  }) => {
    setSyncState((prev) => {
      if (data.status === 'done') {
        return { ...prev, message: '완료!', status: data.status };
      } else if (data.status === 'cancelled') {
        return { ...prev, message: '취소됨', status: data.status };
      }
      return { ...data };
    });
  };

  useEffect(() => {
    appSocket.socket.addSocketEventListener(
      'sync_naver_commerce_products_status',
      'sync_naver_commerce_products_status',
      onNaverCommerceProductsStatus,
    );
    return () => {
      appSocket.socket.removeSocketEventListener(
        'sync_naver_commerce_products_status',
        'sync_naver_commerce_products_status',
      );
    };
  }, []);

  const TABLE_COLUMNS: ColumnsType<NaverCommerceProduct> = [
    {
      fixed: 'left',
      title: () => {
        return (
          <Checkbox
            checked={
              JSON.stringify(selectedIds.sort()) ===
              JSON.stringify(
                (store.searchResultContainer.contents || [])
                  .filter((product) => !!product)
                  .map((product) => product._id)
                  .sort(),
              )
            }
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedIds(
                  (store.searchResultContainer.contents || [])
                    .filter((product) => !!product)
                    .map((product) => product._id),
                );
              } else {
                setSelectedIds([]);
              }
            }}></Checkbox>
        );
      },
      dataIndex: '_id',
      width: 60,
      render: (_id) => {
        return (
          <Checkbox
            onChange={(e) => {
              setSelectedIds((values) => {
                const checked = e.target.checked;
                const newValues = [...values];
                if (checked) {
                  if (!newValues.includes(_id)) {
                    newValues.push(_id);
                  }
                  return newValues;
                } else {
                  return newValues.filter((value) => value !== _id);
                }
              });
            }}
            checked={selectedIds.includes(_id)}></Checkbox>
        );
      },
    },
    {
      fixed: 'left',
      title: 'ID',
      render: (record: NaverCommerceProduct) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(record?._id);
                toast.dark('ID를 클립보드에 복사했습니다', {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  pauseOnFocusLoss: false,
                  draggable: true,
                  progress: undefined,
                  bodyStyle: {
                    color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                    textAlign: 'center',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                  },
                  theme: 'dark',
                });
              }}>
              ID
            </Button>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(record?.channelProductNo);
                toast.dark('상품번호를 클립보드에 복사했습니다', {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  pauseOnFocusLoss: false,
                  draggable: true,
                  progress: undefined,
                  bodyStyle: {
                    color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                    textAlign: 'center',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                  },
                  theme: 'dark',
                });
              }}>
              상품번호
            </Button>
          </div>
        );
      },
    },
    {
      title: '상품명',
      width: 320,
      render: (record: NaverCommerceProduct) => {
        return record?.originProduct?.name;
      },
    },
    {
      title: '옵션',
      width: 320,
      render: (record: NaverCommerceProduct) => {
        const options = record?.originProduct?.detailAttribute?.optionInfo?.optionCombinations || [];
        const found = options.find((item) => {
          if (searchKeyword) {
            const splitedkeywords = searchKeyword.split('|');
            const keyword1 = splitedkeywords[0].trim();
            if (
              item.optionName1?.includes(keyword1) ||
              item.optionName2?.includes(keyword1) ||
              item.optionName3?.includes(keyword1)
            ) {
              return true;
            }
          }
          return false;
        });

        if (options.length === 0) {
          return '-';
        }

        if (store.searchQuery?.startDate && store.searchQuery?.endDate) {
          return options
            .filter((item) => {
              if (store.searchQuery?.startDate && store.searchQuery?.endDate) {
                if (
                  item.date &&
                  new Date(item.date) >= new Date(store.searchQuery?.startDate) &&
                  new Date(item.date) <= new Date(store.searchQuery?.endDate)
                ) {
                  return true;
                } else {
                  return false;
                }
              }
              return true;
            })
            .map((item) => {
              return (
                <div>
                  {`${item.optionName1} / ${item.optionName2} / ${item.optionName3} / 재고: ${item.stockQuantity}`.replace(
                    /\/ undefined/g,
                    '',
                  )}
                </div>
              );
            });
        }

        return (
          <Select
            style={{ width: '320px' }}
            value={
              found
                ? `${found?.optionName1} / ${found?.optionName2} / ${found?.optionName3}`.replace(/\/ undefined/g, '')
                : undefined
            }
            options={options
              .filter((item) => {
                if (store.searchQuery?.startDate && store.searchQuery?.endDate) {
                  if (
                    item.date &&
                    new Date(item.date) >= new Date(store.searchQuery?.startDate) &&
                    new Date(item.date) <= new Date(store.searchQuery?.endDate)
                  ) {
                    return true;
                  } else {
                    return false;
                  }
                }
                return true;
              })
              .map((item) => {
                return {
                  value: `${item.optionName1} / ${item.optionName2} / ${item.optionName3}`.replace(/\/ undefined/g, ''),
                  label:
                    `${item.optionName1} / ${item.optionName2}  / ${item.optionName3} / 재고: ${item.stockQuantity}`.replace(
                      /\/ undefined/g,
                      '',
                    ),
                };
              })}></Select>
        );
      },
    },
    {
      title: '전체 재고',
      render: (record: NaverCommerceProduct) => {
        return record?.originProduct?.stockQuantity;
      },
    },
    {
      title: '업데이트',
      dataIndex: 'updatedAt',
      render: (updatedAt) => {
        return updatedAt ? moment(updatedAt).format('YYYY.MM.DD HH:mm') : '-';
      },
    },
  ];

  const onChangeStartDate: DatePickerProps['onChange'] = (date) => {
    store.setStartDate(localTimeToUtcISOString(date ? new Date(date.toISOString()) : new Date(), { timeToZero: true }));
  };

  const onChangeEndDate: DatePickerProps['onChange'] = (date) => {
    store.setEndDate(localTimeToUtcISOString(date ? new Date(date.toISOString()) : new Date(), { timeToZero: true }));
  };

  const syncCurrent = syncState?.current || 0;
  const syncTotal = syncState?.total || 1;

  return (
    <div className="dashboard" style={{ position: 'relative' }}>
      <Modal
        width={'480px'}
        open={showSyncModal}
        footer={
          <div>
            <Button
              disabled={['prepare', 'in_progress'].includes(syncState.status)}
              onClick={() => {
                appSocket.syncNaverCommerceProducts();
              }}>
              동기화
            </Button>
            <Button
              disabled={['none', 'done', 'error', 'cancelled'].includes(syncState.status)}
              onClick={() => {
                appSocket.cancelSyncNaverCommerceProducts();
              }}>
              동기화 작업 취소
            </Button>
            <Button
              onClick={() => {
                setShowSyncModal(false);
                naverCommerceProductsQuery.refetch();
                setSyncState({ status: 'ready', message: '' });
              }}>
              닫기
            </Button>
          </div>
        }
        onCancel={() => {
          setShowSyncModal(false);
          naverCommerceProductsQuery.refetch();
        }}
        onOk={() => {
          setShowSyncModal(false);
          naverCommerceProductsQuery.refetch();
        }}>
        <div>
          <div style={{ fontWeight: 'bold', fontSize: '16px' }}>네이버에서 상품 정보 가져오기</div>
          <div
            style={{
              marginTop: '24px',
              padding: '12px 0',
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              alignItems: 'flex-end',
            }}>
            <div>
              <Progress percent={Math.floor((syncCurrent / syncTotal) * 100)} size={[400, 20]} />
            </div>
            {syncCurrent > 0 && syncTotal > 1 && <div>{`${syncCurrent} / ${syncTotal}`}</div>}
            <div style={{ width: '100%', height: '36px' }}>{syncState.message}</div>
          </div>
        </div>
      </Modal>
      <div className="page-header">
        <div>네이버 커머스 상품 관리</div>
        <div style={{ display: 'flex', gap: '8px' }}>
          <DatePicker
            onChange={onChangeStartDate}
            value={dayjs(utcTimcToLocalISOString(store.searchQuery.startDate as any))}
          />
          <DatePicker
            onChange={onChangeEndDate}
            value={dayjs(utcTimcToLocalISOString(store.searchQuery.endDate as any))}
          />
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <div className="page-content" style={{ width: '100%', height: 'calc(100vh - 80px)', overflow: 'auto' }}>
          <div style={{ display: 'flex', gap: '12px', width: '100%', height: '52px', alignItems: 'center' }}>
            <Select
              placeholder={'검색 타입'}
              style={{ width: '140px' }}
              value={store.searchQuery.keywordType || 'all'}
              options={[
                { value: 'all', label: '전체' },
                { value: 'productOption', label: '재고 검색' },
              ]}
              onChange={(value) => {
                store.updateSearchQuery({ keywordType: value });
              }}></Select>
            <Input
              ref={searchInputRef}
              style={{ width: '240px' }}
              value={searchKeyword}
              placeholder={'예) 11/25 | 올림픽'}
              onChange={(e) => {
                setSearchKeyword(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.code === 'Enter' && searchKeyword) {
                  store.updateSearchQuery({ keyword: searchKeyword });
                }
              }}></Input>
            <Button
              onClick={() => {
                setSelectedIds([]);
                setSearchKeyword(undefined);
                store.resetSearchQuery();
              }}>
              검색 초기화
            </Button>
            <div>{`결과: ${store.searchResultContainer?.total || 0}개`}</div>

            <Button
              onClick={() => {
                setShowSyncModal(true);
              }}>
              네이버에서 상품 정보 가져오기
            </Button>
          </div>
          <Table
            columns={TABLE_COLUMNS}
            scroll={{ x: 'max-content', y: 'calc(100dvh - 320px)' }}
            style={{ whiteSpace: 'pre-wrap' }}
            rowKey={(record) => record?._id}
            dataSource={store.searchResultContainer.contents.filter((product) => product?._id)}
            pagination={{
              current: (store.searchResultContainer.page || 0) + 1,
              pageSize: store.searchResultContainer.size || 20,
              total: store.searchResultContainer.total,
            }}
            loading={naverCommerceProductsQuery.isLoading}
            onChange={(e) => {
              store.updateSearchQuery({ page: (e.current || 2) - 1, size: e.pageSize || 20 });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default NaverCommerceProductsPage;
