import CreditCardOff from '@assets/images/app/credit-card-inactive.png';
import CreditCardOn from '@assets/images/app/credit-card-active.png';
import KakaoPayOff from '@assets/images/app/kakaopay-inactive.png';
import KakaoPayOn from '@assets/images/app/kakaopay-active.png';
import NaverPayOff from '@assets/images/app/naverpay-inactive.png';
import NaverPayOn from '@assets/images/app/naverpay-active.png';
import SelectedBorderImage from '@assets/images/app/check-rectangle.png';
import { PAYMENT_METHODS_DICT, PaymentMethod } from '@consts/payment';
import React, { useEffect, useState } from 'react';

export const SelectPaymentMethod: React.FC<{ onChange?: (value: PaymentMethod) => void }> = ({ onChange }) => {
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(PAYMENT_METHODS_DICT.card);

  useEffect(() => {
    onChange && onChange(paymentMethod);
  }, [paymentMethod]);

  return (
    <div
      style={{
        marginTop: '24px',
        paddingLeft: '24px',
        paddingRight: '24px',
        marginBottom: '24px',
      }}>
      <div
        style={{
          fontSize: '18px',
          lineHeight: '21px',
          color: '#242424',
          fontWeight: 'bold',
        }}>
        결제 수단
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: '20px',
          justifyContent: 'space-between',
          marginBottom: '8px',
          flexWrap: 'wrap',
          width: '100%',
          color: 'var(--Neutral-100, #242424)',
          fontFamily: 'Pretendard',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: '20px',
        }}>
        <div
          style={{ width: 'calc(50% - 8px)', height: '60px', marginBottom: '8px' }}
          onClick={() => {
            setPaymentMethod(PAYMENT_METHODS_DICT.card);
          }}>
          <div
            style={{
              height: '60px',
              border: paymentMethod.type === 'card' ? '2px solid var(--Primary-95, #FF3D8F)' : '1px solid #E8EAED',
              borderRadius: '8px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
            }}>
            {paymentMethod.type === 'card' && (
              <img
                style={{
                  width: '16px',
                  height: '16px',
                  position: 'absolute',
                  top: -1,
                  left: -1,
                }}
                src={SelectedBorderImage}
              />
            )}
            <img
              style={{
                width: '20px',
                height: '20px',
                marginRight: '8px',
              }}
              src={paymentMethod.type === 'card' ? CreditCardOn : CreditCardOff}
            />
            <div
              style={{
                fontSize: '14px',
                lineHeight: '17px',
                fontWeight: 'bold',
                color: paymentMethod.type === 'card' ? '#242424' : '#808387',
              }}>
              신용/체크카드
            </div>
          </div>
        </div>
        <div
          style={{ width: 'calc(50% - 8px)', height: '60px', marginBottom: '8px' }}
          onClick={() => {
            setPaymentMethod(PAYMENT_METHODS_DICT.kakaopay);
          }}>
          <div
            style={{
              height: '60px',
              borderRadius: '8px',
              border: paymentMethod.type === 'kakaopay' ? '2px solid var(--Primary-95, #FF3D8F)' : '1px solid #E8EAED',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
            }}>
            {paymentMethod.type === 'kakaopay' && (
              <img
                style={{
                  width: '16px',
                  height: '16px',
                  position: 'absolute',
                  top: -1,
                  left: -1,
                }}
                src={SelectedBorderImage}
              />
            )}
            <img
              style={{
                width: '48px',
                height: '20px',
                marginRight: '8px',
              }}
              src={paymentMethod.type === 'kakaopay' ? KakaoPayOn : KakaoPayOff}
            />
            <div
              style={{
                fontSize: '14px',
                lineHeight: '17px',
                fontWeight: 'bold',
                color: paymentMethod.type === 'kakaopay' ? '#242424' : '#808387',
              }}>
              카카오페이
            </div>
          </div>
        </div>
        <div
          style={{ width: 'calc(50% - 8px)', height: '60px', marginBottom: '8px' }}
          onClick={() => {
            setPaymentMethod(PAYMENT_METHODS_DICT.naverpay);
          }}>
          <div
            style={{
              height: '60px',
              borderRadius: '8px',
              border: paymentMethod.type === 'naverpay' ? '2px solid var(--Primary-95, #FF3D8F)' : '1px solid #E8EAED',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
            }}>
            {paymentMethod.type === 'naverpay' && (
              <img
                style={{
                  width: '16px',
                  height: '16px',
                  position: 'absolute',
                  top: -1,
                  left: -1,
                }}
                src={SelectedBorderImage}
              />
            )}
            <img
              style={{
                width: 52,
                height: '20px',
                marginRight: '8px',
              }}
              src={paymentMethod.type === 'naverpay' ? NaverPayOn : NaverPayOff}
            />
            <div
              style={{
                fontSize: '14px',
                lineHeight: '17px',
                fontWeight: 'bold',
                color: paymentMethod.type === 'naverpay' ? '#242424' : '#808387',
              }}>
              네이버페이
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          height: '44px',
          marginBottom: '32px',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#f5f7fb',
          borderRadius: '8px',
        }}>
        <div
          style={{
            color: 'var(--Neutral-70, #808387)',
            textAlign: 'center',
            fontFamily: 'Pretendard',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '20px',
          }}>
          계좌이체 : 카카오톡 '아이고고' 고객센터 문의
        </div>
      </div>
    </div>
  );
};

export default SelectPaymentMethod;
