import ClassInvoicesPage from '@pages/ClassInvoicesPage';
import LoginPage from '@pages/LoginPage';
import MainFeedPage from '@pages/MainFeedPage';
import PaymentStatementsPage from '@pages/PaymentStatementsPage';
import ProductCollectionsPage from '@pages/ProductCollectionsPage';
import ProductsPage from '@pages/ProductsPage';
import PushNotificationPage from '@pages/PushNotificationPage';
import BannersPage from '@pages/BannersPage';
import UsersPage from '@pages/UsersPage';
import { Dashboard } from './pages';
import FeedElementsPage from '@pages/FeedElementsPage';
import ChatMessagesPage from '@pages/ChatMessagesPage';
import ViolationDetectedMessagesPage from '@pages/ViolationDetectedMessagesPage';
import CouponLogsPage from '@pages/CouponLogsPage';
import CouponsPage from '@pages/CouponsPage';
import PointTemplatesPage from '@pages/PointTemplatesPage';
import CustomerRequestsPage from '@pages/CustomerRequestsPage';
import UserContentsPage from '@pages/UserContentsPage';
import ChatRoomsPage from '@pages/ChatRoomsPage';
import CreditPointLogsPage from '@pages/CreditPointLogsPage';
import NaverOrdersPage from '@pages/SalesChannelNaverOrdersPage';
import NoticesPage from '@pages/NoticesPage';
import RedirectPage from './RedirectPage';
import KakaoNotificationsPage from '@pages/KakaoNotificationsPage';
// import SalesChannelProductsPage from '@pages/SalesChannelProductsPage';
import RemoteConfigPage from '@pages/RemoteConfigPage';
import NaverCommerceOrdersPage from '@pages/NaverCommerceOrdersPage';
import JoinPromotionsPage from '@pages/JoinPromotionsPage';
import JoinPromotionLogsPage from '@pages/JoinPromotionLogsPage';
import KakaoReservedNotificationsPage from '@pages/KakaoReservedNotificationsPage';
import SystemPaymentsPage from '@pages/SystemPaymentsPage';
import EventsPage from '@pages/EventsPage';
import NaverCommerceProductsPage from '@pages/NaverCommerceProductsPage';
import ProductReportPage from '@pages/ProductsReportPage';
import ProductsInventoryPage from '@pages/ProductsInventoryPage';
import MembershipsPage from '@pages/MembershipsPage';
import ProductInventoryClosingHistoryPage from '@pages/ProductInventoryClosingHistoryPage';
import ReviewsPage from '@pages/ReviewsPage';
import InboundOrdersPage from '@pages/SalesChannelInboundOrdersPage';
import KakaoOrdersPage from '@pages/SalesChannelKakaoOrdersPage';
import SalesReportPage from '@pages/SalesReportPage';
import PurchasesReportPage from '@pages/PurchasesReportPage';
import SettlementsPage from '@pages/SettlementsPage';

const routes = [
  { path: '/dashboard', element: <Dashboard /> },
  { path: '/notices', element: <NoticesPage /> },
  { path: '/remote-config', element: <RemoteConfigPage /> },
  { path: '/login', element: <LoginPage /> },
  { path: '/payment-statements', element: <PaymentStatementsPage /> },
  { path: '/settlements', element: <SettlementsPage /> },
  { path: '/payments', element: <SystemPaymentsPage /> },
  { path: '/products', element: <ProductsPage /> },
  { path: '/products-inventory', element: <ProductsInventoryPage /> },
  { path: 'product-inventory-closing-history', element: <ProductInventoryClosingHistoryPage /> },
  { path: '/users', element: <UsersPage /> },
  { path: '/class-invoices', element: <ClassInvoicesPage /> },
  { path: '/push-notifications', element: <PushNotificationPage /> },
  { path: '/kakao-notifications', element: <KakaoNotificationsPage /> },
  { path: '/kakao-reserved-notifications', element: <KakaoReservedNotificationsPage /> },
  { path: '/product-collections', element: <ProductCollectionsPage /> },
  { path: '/main-feed', element: <MainFeedPage /> },
  { path: '/banners', element: <BannersPage /> },
  { path: '/feed-elements', element: <FeedElementsPage /> },
  { path: '/chat-messages', element: <ChatMessagesPage /> },
  { path: '/chat-rooms', element: <ChatRoomsPage /> },
  { path: '/violation-detected-messages', element: <ViolationDetectedMessagesPage /> },
  { path: '/user-contents', element: <UserContentsPage /> },
  { path: '/review', element: <ReviewsPage /> },
  { path: '/customer-request', element: <CustomerRequestsPage /> },
  { path: '/point-template', element: <PointTemplatesPage /> },
  { path: '/coupon', element: <CouponsPage /> },
  { path: '/coupon-log', element: <CouponLogsPage /> },
  { path: '/point-log', element: <CreditPointLogsPage /> },
  { path: '/join-promotion', element: <JoinPromotionsPage /> },
  { path: '/join-promotion-log', element: <JoinPromotionLogsPage /> },
  { path: '/naver-orders', element: <NaverOrdersPage /> },
  { path: '/inbound-orders', element: <InboundOrdersPage /> },
  { path: '/kakao-orders', element: <KakaoOrdersPage /> },
  { path: '/naver-commerce-orders', element: <NaverCommerceOrdersPage /> },
  { path: '/naver-commerce-products', element: <NaverCommerceProductsPage /> },
  { path: '/report/products', element: <ProductReportPage /> },
  { path: '/report/sales', element: <SalesReportPage /> },
  { path: '/report/purchases', element: <PurchasesReportPage /> },
  { path: '/events', element: <EventsPage /> },
  { path: '/memberships', element: <MembershipsPage /> },

  { path: '*', element: <RedirectPage /> },
];

export default routes;
