import GrayRightIcon from '@assets/images/app/icon-arr-gray-right-12.png';
import ClassItem from '@components/product-collection/ClassItem';
import CommerceItem from '@components/product-collection/CommerceItem';
import React from 'react';
import { MainFeedCellProps } from '.';

export const ClassGridCell: React.FC<MainFeedCellProps> = (props) => {
  const data = props.data;
  return (
    <div id="class-grid-cell">
      <div
        style={{
          width: '100%',
          backgroundColor: 'white',
          padding: '0 16px',
        }}>
        <div
          style={{
            display: 'flex',
            marginBottom: '20px',
            backgroundColor: 'white',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <div
            id="class-grid-cell-title"
            style={{
              fontSize: '19px',
              fontWeight: 'bold',
              color: '#242424',
            }}>
            {props.title}
          </div>
          <button
            className="link-button"
            style={{
              display: 'flex',
              alignItems: 'center',
            }}>
            <div
              style={{
                fontSize: '14px',
                color: '#808387',
                lineHeight: '17px',
              }}>
              전체보기
            </div>
            <img src={GrayRightIcon} style={{ width: '12px', height: '12px' }} />
          </button>
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            gap: '12px',
          }}>
          {data.map((item: any, index: number) => {
            if (item.saleType === 'commerce') {
              return (
                <CommerceItem
                  key={`product-item-${index}`}
                  item={item}
                  style={{ flex: '0 0 auto', width: 'calc(50% - 6px)', cursor: 'pointer' }}
                  onClick={() => {}}></CommerceItem>
              );
            } else {
              return (
                <ClassItem
                  key={`product-item-${index}`}
                  item={item}
                  style={{
                    flex: '0 0 auto',
                    width: 'calc(50% - 6px)',
                    cursor: 'pointer',
                  }}
                  onClick={() => {}}></ClassItem>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default ClassGridCell;
