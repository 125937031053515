import FileUploadButton from '@components/common/FileUploadButton';
import { closestCenter, DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FeedData, FeedDataItem } from '@models/feed';
import { cdnImageUrl, resizeImage } from '@utils/image';
import { extractFileName } from '@utils/string';
import { Button, Input, Switch } from 'antd';
import { RcFile } from 'antd/es/upload';
import React, { useEffect, useState } from 'react';
import DeeplinkActionEdit from './DeepLinkActionEdit';

interface SortableHomePopupBannerItemProps {
  onDeleteItem?: (event: any) => void;
  onChange?: (item: FeedDataItem) => void;
  onChangeImage?: (files: FileList) => void;
  id: any;
  item: FeedDataItem;
}

export const SortableHomePopupBannerItem: React.FC<SortableHomePopupBannerItemProps> = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const { onDeleteItem, onChange, item } = props;
  return (
    <div ref={setNodeRef} style={{ ...style }} {...attributes} {...listeners}>
      <div style={{ display: 'flex', marginBottom: '8px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '428px', marginRight: '8px' }}>
          <div>
            <img
              style={{ width: '428px', aspectRatio: 1.5, objectFit: 'cover' }}
              src={cdnImageUrl(item.imageUrlTemp || item.imageUrl)}
            />
          </div>
          <FileUploadButton
            accept={['jpg', 'jpeg', 'png', 'tif', 'tiff', 'webp', 'svg']
              .map((ext) => {
                return '.' + ext;
              })
              .join(',')}
            className="link-button"
            style={{
              fontSize: '14px',
              height: '32px',
              padding: '4px 15px',
              borderRadius: '6px',
              backgroundColor: '#ffffff',
              border: '1px solid #d9d9d9',
              color: 'rgba(0, 0, 0, 0.88)',
              boxShadow: '0 2px 0 rgba(0, 0, 0, 0.02)',
              cursor: 'pointer',
            }}
            onChange={(e) => {
              if (e.target.files) {
                const files = e.target.files;
                props.onChangeImage && props.onChangeImage(files);
              }
            }}>
            이미지 변경
          </FileUploadButton>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', marginRight: '8px' }}>
          <div style={{ border: '1px solid #eaeaea', borderRadius: '8px', padding: '8px' }}>
            <div>클릭시 이동 설정</div>
            <DeeplinkActionEdit
              data={item}
              onChange={(value) => {
                onChange && onChange({ ...item, ...value });
              }}></DeeplinkActionEdit>
          </div>
        </div>
        <div>
          <Button
            onClick={(e) => {
              onDeleteItem && onDeleteItem(e);
            }}>
            삭제
          </Button>
        </div>
      </div>
    </div>
  );
};

export const HomePopupBannerEdit: React.FC<{
  clear?: boolean;
  data?: Partial<FeedData>;
  onChange?: (data: Partial<FeedData>) => void;
  onChangeImageFile?: (files: { [key: string]: File }) => void;
}> = (props) => {
  const [changedContents, setChangedContents] = useState<FeedDataItem[]>();
  const [changedSize, setChangedSize] = useState<string>();
  const [imageFiles, setImageFiles] = useState<{ [key: string]: File }>({});
  const [active, setActive] = useState<boolean>();
  const [shuffle, setShuffle] = useState<boolean>();
  const [autoplay, setAutoplay] = useState<boolean>();
  const [delay, setDelay] = useState<number>();

  const { data } = props;
  const contents = changedContents || data?.data || [];

  const sortableSensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 10 },
    }),
  );

  useEffect(() => {
    if (props.clear) {
      setChangedSize(undefined);
      setChangedContents(undefined);
      setImageFiles({});
    }
  }, [props.clear]);

  useEffect(() => {
    props.onChangeImageFile && props.onChangeImageFile(imageFiles);
  }, [imageFiles]);

  useEffect(() => {
    props.onChange &&
      props.onChange({
        ...data,
        data: changedContents || data?.data,
        size: changedSize || data?.size,
        active: active !== undefined ? active : data?.active,
        shuffle: shuffle !== undefined ? shuffle : data?.shuffle,
        autoplay: autoplay !== undefined ? autoplay : data?.autoplay,
        delay: delay !== undefined ? delay : data?.delay,
      });
  }, [changedContents, changedSize, shuffle, active, autoplay, delay]);

  const onChangeImage = async (files: FileList, index: number) => {
    const { fileName } = extractFileName(files[0].name);
    const newFiles = await Promise.all(
      Array.from(files)
        .slice(0, 10)
        .map(async (file) => {
          const result = await resizeImage(file, { maxWidth: 856 });

          return result;
        }),
    );
    const contentTypes = newFiles[0].type.split('/');
    const uploadFileName = `${fileName}.${contentTypes[1]}`;
    const newImageFile = new File([newFiles[0].image], `${fileName}.${contentTypes[1]}`) as RcFile;
    const tempUrl = URL.createObjectURL(newFiles[0].image);
    setChangedContents((prev) => {
      const newValues = [...(prev || contents)];
      newValues[index] = { ...newValues[index], imageUrl: uploadFileName, imageUrlTemp: tempUrl };
      return newValues;
    });

    setImageFiles((value) => {
      const newValue = { ...value };
      newValue[`${data?.type}.${index}`] = newImageFile;
      return newValue;
    });
  };

  const onSortEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      if (contents) {
        const oldIndex = contents.findIndex((item) => item.imageUrl === active.id);
        const newIndex = contents.findIndex((item) => item.imageUrl === over?.id);
        const newData = arrayMove(contents, oldIndex, newIndex);
        setChangedContents(newData);
      }
    }
  };

  return (
    <div>
      <div style={{ display: 'flex', height: '44px', gap: '8px', alignItems: 'center' }}>
        <Button
          onClick={() => {
            setChangedContents((prev) => {
              const newValues = [{}, ...(prev || contents)];
              return newValues;
            });
          }}>
          빈 항목 추가
        </Button>
      </div>
      <div style={{ display: 'flex', gap: '8px', alignItems: 'center', marginBottom: '12px' }}>
        <span>활성 상태: </span>
        <Switch
          checked={active !== undefined ? active : data?.active}
          onChange={(checked) => {
            setActive(checked);
          }}></Switch>

        <span>무작위 노출: </span>
        <Switch
          checked={shuffle !== undefined ? shuffle : data?.shuffle}
          onChange={(checked) => {
            setShuffle(checked);
          }}></Switch>
        <span>자동 재생: </span>
        <Switch
          checked={autoplay !== undefined ? autoplay : data?.autoplay}
          onChange={(checked) => {
            setAutoplay(checked);
          }}></Switch>
        <span>자동 재생 지연 시간(초): </span>
        <Input
          style={{ width: '80px' }}
          value={delay !== undefined ? delay : data?.delay}
          onChange={(e) => {
            setDelay(Number(e.target.value) || 0);
          }}></Input>
      </div>
      <DndContext sensors={sortableSensors} collisionDetection={closestCenter} onDragEnd={onSortEnd}>
        <SortableContext
          items={contents.map((item) => {
            return { ...item, id: item.imageUrl || '' };
          })}
          strategy={verticalListSortingStrategy}>
          <div style={{ maxWidth: '600px' }}>
            {contents.map((item, index) => {
              return (
                <SortableHomePopupBannerItem
                  item={item}
                  id={item.imageUrl}
                  onDeleteItem={() => {
                    setChangedContents((prev) => {
                      const newValues = [...(prev || contents)];
                      newValues.splice(index, 1);
                      return newValues;
                    });
                  }}
                  onChangeImage={(files: FileList) => {
                    onChangeImage(files, index);
                  }}
                  onChange={(item) => {
                    setChangedContents((prev) => {
                      const newValues = [...(prev || contents)];
                      newValues[index] = item;
                      return newValues;
                    });
                  }}></SortableHomePopupBannerItem>
              );
            })}
          </div>
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default HomePopupBannerEdit;
