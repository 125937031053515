import { create } from 'zustand';

interface ContentLoaderStore {
  show?: boolean;
  on: () => void;
  off: () => void;
}

export const useContentLoaderStore = create<ContentLoaderStore>((set) => ({
  show: false,
  on: () => {
    set({ show: true });
  },
  off: () => {
    set({ show: false });
  },
}));
