import { PagenatedResponse, SearchPagenateQuery } from '@models/common';
import { ProductInventoryClosingLog } from '@models/product';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const PAGE_SIZE = 20;

interface ProductInventoryClosingHistoryContainer {
  contents: ProductInventoryClosingLog[];
  page: number;
  size: number;
  total: number;
  hasNext: boolean;
}

export interface ProductInventoryClosingHistoryQuery extends SearchPagenateQuery {
  dateType?: string;
  refundStatus?: string;
  keywordType?: string;
  status?: string;
}

interface ProductInventoryClosingHistoryStoreProps {
  searchResultContainer: ProductInventoryClosingHistoryContainer;
  searchQuery: ProductInventoryClosingHistoryQuery;
  searchKeyword?: string;
  setStartDate: (date: string | null) => void;
  setEndDate: (date: string | null) => void;
  setSearchKeyword: (keyword: string) => void;
  updateSearchQuery: (query: { [key: string]: any }) => void;
  resetSearchQuery: () => void;
  setProductInventoryClosingHistoryContainer: (result: PagenatedResponse<ProductInventoryClosingLog>) => void;
}

export const useProductInventoryClosingHistoryStore = create(
  persist<ProductInventoryClosingHistoryStoreProps>(
    (set) => ({
      searchResultContainer: {
        contents: [] as ProductInventoryClosingLog[],
        total: 0,
        page: 0,
        size: 0,
        hasNext: false,
      },
      searchQuery: {
        page: 0,
        size: PAGE_SIZE,
        startDate: undefined,
        endDate: undefined,
      },
      searchKeyword: undefined,
      setStartDate: (date: string | null) => {
        set((state) => {
          return { ...state, searchQuery: { ...state.searchQuery, startDate: date } };
        });
      },
      setEndDate: (date: string | null) => {
        set((state) => {
          return { ...state, searchQuery: { ...state.searchQuery, endDate: date } };
        });
      },
      setProductInventoryClosingHistoryContainer: (result) => {
        set((state) => {
          const contents = new Array(result.total || PAGE_SIZE);
          contents.splice(result.page * result.size, result.size, ...(result?.contents || []));
          const newValue = { ...state.searchResultContainer, ...result, contents: contents };
          return { ...state, searchResultContainer: newValue };
        });
      },
      setSearchKeyword: (keyword: string) => {
        set((state) => {
          return {
            ...state,
            searchKeyword: keyword,
            searchResultContainer: { ...state.searchResultContainer, size: 0 },
          };
        });
      },
      updateSearchQuery: (query: { [key: string]: any }) => {
        set((state) => {
          return {
            ...state,
            searchQuery: { ...state.searchQuery, ...query },
            searchResultContainer: { ...state.searchResultContainer, size: 0 },
          };
        });
      },
      resetSearchQuery: () => {
        set({
          searchQuery: {
            page: 0,
            size: PAGE_SIZE,
            startDate: undefined,
            endDate: undefined,
          },
        });
      },
    }),
    {
      name: 'igogo-inventory-closing-logs',
    },
  ),
);
