import { useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Checkbox, InputNumber, Modal, Select } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { toast } from 'react-toastify';
import * as api from '@apis/payment-statement';
import { PaymentStatementPurchase, RefundStatus, UpdatePaymentStatementEventType } from '@models/paymentStatement';
import refundModeModalStore from '@stores/refundModeModalStore';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import './RefundModeModal.scss';
import moment from 'moment';
import { RefundLog, RefundLogStatus } from '@models/refundLog';
import { usePayment } from '@hooks/payment';
import SlideMenu from '@components/common/SlideMenu/SlideMenu';
import { getPaymentMethodText } from '@utils/payment';

const SHIPPING_LABELS: { [key: string]: string } = {
  name: '이름',
  phone: '전화번호',
  fee: '배송비',
  address: '기본주소',
  addressDetail: '상세주소',
};

const RefundModeModal: React.FC = () => {
  const { show, close, data } = refundModeModalStore();
  const dialog = useOkCancelDialog();
  const payment = usePayment();
  const queryClient = useQueryClient();
  const [noMoreRefundsAvailable, setNoMoreRefundsAvailable] = useState(false);
  const [refundTargets, setRefundTargets] = useState<PaymentStatementPurchase[]>([]);
  const [refundAmount, setRefundAmount] = useState(0);
  const memoRef = useRef<HTMLTextAreaElement>(null);

  const remainAmount = (data?.payment.amount || 0) - (data?.refund?.amount || 0);
  const remainPoint = (data?.payment.point || 0) - (data?.refund?.point || 0);
  const remainAmountText = `${remainAmount.toLocaleString()}원${
    remainPoint > 0 ? `(${remainPoint.toLocaleString()}P)` : ''
  }`;
  const refundStatusesByUser: string[] = [RefundStatus.ACCEPTED, RefundStatus.DENIED, RefundStatus.REQUESTED];
  const paidPoint = data?.payment?.point || 0;
  const paidAmount = `${data?.payment.amount.toLocaleString()}원${
    paidPoint > 0 ? `(${paidPoint.toLocaleString()}P)` : ''
  }`;
  const reservedItems =
    data?.refund?.data?.[0]?.status === RefundLogStatus.RESERVED ? data.refund.data[0]?.refundItems || [] : [];

  const allowRefundMutation = useMutation({
    mutationFn: api.requestRefundWithBody,
    onSuccess: async (_, body) => {
      let content = '';

      if ('refundItems' in body.data) {
        content = '환불 처리를 성공적으로 완료했습니다.';
      } else {
        content = '"추가 환불 불가" 상태를 변경했습니다.';
      }

      toast.dark(content, {
        pauseOnFocusLoss: false,
        bodyStyle: {
          textAlign: 'center',
        },
      });
      await queryClient.invalidateQueries({ queryKey: ['admin-search-payment-statement'] });
    },
    onError: () => {
      toast('환불에 실패했습니다.', {
        pauseOnFocusLoss: false,
        bodyStyle: {
          textAlign: 'center',
        },
      });
    },
  });

  const firstRefundIndex = data?.refund?.data?.findIndex((item) => item.status === RefundLogStatus.DONE);
  const refundOneFlowData = useMemo(
    () =>
      firstRefundIndex === -1 || firstRefundIndex === undefined
        ? data?.refund?.data
        : data?.refund?.data.slice(0, firstRefundIndex),
    [data, show],
  );
  const refundDoneHistory = useMemo(
    () =>
      data?.refund?.data?.filter((item) => {
        if (item?.refundItems && item?.refundItems?.length > 0) {
          if (item?.status === RefundLogStatus.DONE) {
            return item;
          } else if (data?.statuses?.refund && ['all', 'partial'].includes(data.statuses.refund)) {
            let amount: number = 0;
            item.refundItems.map((refundItem) => {
              amount += refundItem?.fee || 0 * refundItem?.count || 0;
            });
            item.amount = amount;
            return item;
          }
        }
      }) || [],
    [data, show],
  );
  const userRefundLog = useMemo(
    () =>
      refundOneFlowData?.filter(
        (item) => item.status === RefundLogStatus.REQUESTED || item.status === RefundLogStatus.ACCEPTED,
      ),
    [refundOneFlowData],
  );
  const buyerRefundLog = useMemo(
    () => refundOneFlowData?.filter((item) => item.status === RefundLogStatus.REQUESTED),
    [refundOneFlowData],
  );
  const partnerRefundLog = useMemo(
    () => refundOneFlowData?.filter((item) => item.status === RefundLogStatus.ACCEPTED),
    [refundOneFlowData],
  );
  const USER_REFUND_COST = useMemo(
    () => userRefundLog?.[0]?.refundItems?.reduce((prev, cur) => prev + cur.count * (cur.fee || 0), 0) || 0,
    [userRefundLog],
  );
  const BUYER_REFUND_COST = useMemo(
    () => buyerRefundLog?.[0]?.refundItems?.reduce((prev, cur) => prev + cur.count * (cur.fee || 0), 0) || 0,
    [buyerRefundLog],
  );

  const PARTNER_REPLY_STATUS: { [key: string]: string } = {
    denied: '환불 반려',
    accepted: '환불 승인',
    requested: '미응답',
    default: '미응답',
  };
  const REFUND_MEMO = [
    {
      title: '구매자 메모',
      childrens: [
        { key: '요청 사항', value: data?.memo?.payment || '-' },
        { key: '환불 사유', value: data?.memo?.refundUser?.split('/')[0] || '-' },
        { key: '환불 내용', value: data?.memo?.refundUser?.split('/')[1] || '-' },
      ],
    },
    {
      title: '판매자 메모',
      childrens: [
        {
          key: '응답 상태',
          value: PARTNER_REPLY_STATUS[data?.statuses.refund || 'default'] || PARTNER_REPLY_STATUS['default'],
        },
        { key: '메모 내용', value: data?.memo?.refundPartner || '-' },
        {
          key: '승인 상품',
          value:
            data?.statuses.refund === RefundStatus.ACCEPTED
              ? partnerRefundLog?.[0]?.refundItems
                  ?.map((item) => `- ${item.name}(${((item.fee || 0) * item.count).toLocaleString()}원)`)
                  .join('\n')
              : '-',
        },
      ],
    },
  ];
  const PURCHASE_INFO = {
    title: '결제 내역',
    childrens: [
      { key: '구매자', value: data?.buyer.name },
      { key: '연락처', value: data?.buyer.phone },
      { key: '결제금액', value: paidAmount },
      { key: '멤버십 할인', value: `${(data?.payment.membershipDiscountAmount || 0).toLocaleString()}원` },
      { key: '결제수단', value: data?.payment ? getPaymentMethodText(data.payment) : '-' },
      { key: '결제시각', value: moment(data?.payment.paidAt).format('YYYY-MM-DD HH:mm') },
      { key: '입력옵션', value: data?.memo?.qna || '-' },
    ],
  };
  const REFUND_COLUMNS: ColumnsType<PaymentStatementPurchase> = [
    {
      title: '선택',
      width: 20,
      render: (item) => {
        const isReserved = reservedItems.length > 0;
        const refundItemIndex = getRefundItemIndex(item);
        const isPossibleAmount = (item.count * item.fee || 0) - (getItemByName(data?.refunds, item.name)?.fee || 0) > 0;

        return (
          <Checkbox
            disabled={item.fee === 0 ? true : isReserved || !isPossibleAmount}
            checked={item.fee === 0 ? canRefundZeroCount : refundItemIndex !== -1}
            onChange={() => toggleRefundTargets(item)}
          />
        );
      },
      align: 'center',
    },
    {
      title: '상품명',
      width: 200,
      render: (item) => {
        let option = '';
        if (item.option && item.option.length > 0) {
          option = `[${item.option.join(' ')}]`;
        }
        return option + item.name;
      },
      align: 'center',
    },
    {
      title: '개당 가격',
      width: 50,
      render: (item) => `${(item.fee || 0).toLocaleString()}`,
      align: 'center',
    },
    {
      title: '구매 수량',
      width: 30,
      render: (item) => item.count || 0,
      align: 'center',
    },
    {
      title: '남은 수량',
      width: 30,
      render: (item) => item.count - (getItemByName(data?.refunds, item.name)?.count || 0),
    },
    {
      title: '요청 수량',
      width: 30,
      render: (item) => {
        if (data?.statuses.refund && refundStatusesByUser.includes(data.statuses.refund)) {
          return getItemByName(userRefundLog?.[0]?.refundItems, item.name)?.count || 0;
        } else {
          return 0;
        }
      },
      className: 'red',
    },
    {
      title: '환불 수량',
      width: 30,
      render: (item) => {
        const isReserved = reservedItems.length > 0;
        const refundTarget = getItemByName(refundTargets, item.name);

        return (
          <InputNumber
            value={refundTarget?.count || 0}
            min={0}
            max={item.count - (getItemByName(data?.refunds, item.name)?.count || 0)}
            onChange={(value) => {
              setRefundTargets((prev) => {
                const newValue = [...prev];
                const refundItemIndex = getRefundItemIndex(item);
                newValue[refundItemIndex].count = value || 0;
                newValue[refundItemIndex].fee = (value || 0) * item.fee;
                return newValue;
              });
            }}
            disabled={item.fee === 0 ? true : !refundTarget || isReserved}
          />
        );
      },
      align: 'center',
      className: 'red',
    },
    {
      title: '환불 금액',
      width: 50,
      render: (item) => {
        const isReserved = reservedItems.length > 0;
        const refundTarget = getItemByName(refundTargets, item.name);

        let refundItemTotalAmout = 0;
        refundDoneHistory.forEach((log) => {
          log?.refundItems?.forEach((refundItem) => {
            if (JSON.stringify(refundItem.name) === JSON.stringify(item.name)) {
              refundItemTotalAmout += refundItem.fee || 0;
            }
          });
        });

        return (
          <InputNumber
            value={refundTarget?.fee || 0}
            min={0}
            max={
              refundTarget?.count !== 0
                ? Math.min((refundTarget?.count || 0) * (item.fee || 0), item.count * item.fee - refundItemTotalAmout)
                : item.count * item.fee - refundItemTotalAmout
            }
            onChange={(value) => {
              setRefundTargets((prev) => {
                const newValue = [...prev];
                const refundItemIndex = getRefundItemIndex(item);
                newValue[refundItemIndex].fee = value || 0;

                return newValue;
              });
            }}
            disabled={item.fee === 0 ? true : !refundTarget || isReserved}
            addonAfter={
              !(!refundTarget || isReserved) && (
                <Select
                  value={Math.round(((refundTarget.fee || 0) / (refundTarget.count * item.fee)) * 100 || 100)}
                  popupMatchSelectWidth={false}
                  suffixIcon={null}
                  options={Array.from({ length: 20 }, (_, idx) => ({
                    value: 5 * (idx + 1),
                    label: `${5 * (idx + 1)}%`,
                  }))}
                  onChange={(e) => {
                    setRefundTargets((prev) => {
                      const newValue = [...prev];
                      const refundItemIndex = getRefundItemIndex(item);
                      newValue[refundItemIndex].fee = Math.round((item.fee || 0) * refundTarget.count * (e / 100));
                      return newValue;
                    });
                  }}
                />
              )
            }
          />
        );
      },
      align: 'center',
    },
  ];
  const REFUND_RESERVE_COLUMNS: ColumnsType<PaymentStatementPurchase> = [
    {
      title: '상품명',
      width: 200,
      render: (item) => {
        let option = '';
        if (item.option && item.option.length > 0) {
          option = `[${item.option.join(' ')}]`;
        }
        return option + item.name;
      },
      align: 'center',
    },
    {
      title: '개당 가격',
      width: 50,
      render: (item) => getItemByName(data?.purchases, item.name)?.fee?.toLocaleString(),
      align: 'center',
    },
    {
      title: '구매 수량',
      width: 30,
      render: (item) => getItemByName(data?.purchases, item.name)?.count,
      align: 'center',
    },
    {
      title: '남은 수량',
      width: 30,
      render: (item) =>
        (getItemByName(data?.purchases, item.name)?.count || 0) - (getItemByName(data?.refunds, item.name)?.count || 0),
      align: 'center',
    },
    {
      title: '환불 수량',
      width: 30,
      render: (item) => item.count || 0,
      align: 'center',
    },
    {
      title: '환불 가격',
      width: 30,
      render: (item) =>
        item.count !== 0 ? (item.fee * item.count).toLocaleString() || 0 : item.fee?.toLocaleString() || 0,
      align: 'center',
    },
  ];

  const getItemByName = (searchItems?: PaymentStatementPurchase[], itemName?: string | string[]) => {
    if (searchItems && itemName) {
      const index = searchItems.findIndex((searchItem) => JSON.stringify(searchItem.name) === JSON.stringify(itemName));
      return searchItems[index];
    }
  };
  const getRefundItemIndex = (target: PaymentStatementPurchase) => {
    return refundTargets.findIndex((refundTarget) => JSON.stringify(refundTarget.name) === JSON.stringify(target.name));
  };
  const toggleRefundTargets = (target: PaymentStatementPurchase) => {
    const refundItemIndex = getRefundItemIndex(target);

    setRefundTargets((prev) => {
      const newValue = [...prev];
      if (refundItemIndex !== -1) {
        newValue.splice(refundItemIndex, 1);
      } else {
        newValue.push({ ...target, count: 0, fee: 0 });
      }

      return newValue;
    });
  };
  const checkCanRefund = () => {
    const isCanRefund = remainAmount - refundAmount >= 0 && refundAmount > 0;
    return isCanRefund;
  };
  const canRefundZeroCount = useMemo(() => {
    const newRefunds = data?.refunds?.map((item) => ({ ...item })) || [];

    refundTargets.forEach((refundTarget) => {
      const existingItem = newRefunds.find((item) => JSON.stringify(item.name) === JSON.stringify(refundTarget.name));

      if (existingItem) {
        existingItem.count += refundTarget.count;
      } else {
        newRefunds.push({ ...refundTarget });
      }
    });

    const resultFilter =
      data?.purchases
        .filter((item) => (item.fee === 0 ? false : true))
        .map((item) => {
          const existingItem = newRefunds.find(
            (newRefund) => JSON.stringify(newRefund.name) === JSON.stringify(item.name),
          );

          if (existingItem) {
            return { ...item, count: item.count - existingItem.count };
          } else {
            return { ...item };
          }
        })
        .filter((item) => item.count !== 0) || [];

    return resultFilter.length > 0 ? false : true;
  }, [refundTargets]);

  const returnRefundItems = (classType?: string) => {
    if (classType === 'commerce' || classType === 'class') {
      const refundItems =
        reservedItems.length > 0
          ? reservedItems
          : refundTargets.map((item) => {
              return {
                ...item,
                fee: item.count !== 0 ? Math.round((item.fee || 0) / item.count) : item.fee,
              };
            });
      return refundItems;
    } else {
      throw new Error('상품 클래스 타입이 명확하지 않습니다.');
    }
  };
  const refundSubmitText = (classType?: string) => {
    if (classType === 'class') {
      return `남은 금액 ${(
        (data?.payment.amount || 0) - (data?.refund?.amount || 0)
      ).toLocaleString()}원 중 ${refundAmount?.toLocaleString()}원을 환불하시겠습니까?`;
    } else if (classType === 'commerce') {
      if (refundAmount !== 0) {
        return `유저가 요청한 금액 ${USER_REFUND_COST?.toLocaleString()}원 중 ${refundAmount?.toLocaleString()}원을\n 환불처리 하시겠습니까?`;
      } else {
        return '추가 환불 불가 상태를 변경하시겠습니까?';
      }
    } else {
      throw new Error('상품 클래스 타입이 명확하지 않습니다.');
    }
  };
  const reserveRefundPayment = async () => {
    const refundItems: PaymentStatementPurchase[] = refundTargets.map((item) => {
      return {
        ...item,
        fee: item.count !== 0 ? Math.round((item.fee || 0) / item.count) : item.fee,
      };
    });
    const canRefund = checkCanRefund();

    if (data && refundTargets.length > 0 && canRefund && memoRef.current) {
      const recentRefund = data?.refund?.data?.[0];
      const referenceId =
        recentRefund && recentRefund.status !== RefundLogStatus.DONE
          ? '_id' in recentRefund
            ? recentRefund._id
            : undefined
          : undefined;

      await payment.updateRefundStatus(
        data._id,
        UpdatePaymentStatementEventType.RESERVE,
        refundItems,
        referenceId,
        memoRef.current.value,
      );
      close();
      setRefundTargets([]);

      if (memoRef.current) {
        memoRef.current.value = '';
      }
    } else {
      dialog.open({
        type: 'ok',
        confirmColor: '#FF3D8F',
        title: '실패했습니다.',
        content: !canRefund ? '환불 금액을 확인해주세요!' : '상품을 선택해주세요!',
      });
    }
  };
  const cancelReserveRefundPayment = () => {
    if (data) {
      if (data?.refund?.data) {
        payment.updateRefundStatus(
          data._id,
          UpdatePaymentStatementEventType.REMOVE_RESERVE,
          undefined,
          '_id' in data.refund.data[0] ? data.refund.data[0]._id : undefined,
        );
        close();
        setRefundTargets([]);

        if (memoRef.current) {
          memoRef.current.value = '';
        }
      }
    }
  };
  const submitRefund = () => {
    const refundItems = returnRefundItems(data?.product.saleType);
    const submitContent = refundSubmitText(data?.product.saleType);
    const canRefund = checkCanRefund();

    dialog.open({
      type: 'ok_cancel',
      title: '환불',
      content: submitContent,
      onConfirm: async () => {
        if (data && memoRef.current && refundItems && canRefund) {
          const recentRefund = data.refund?.data?.[0];
          const referenceId =
            recentRefund && recentRefund.status && recentRefund.status !== RefundLogStatus.DONE
              ? '_id' in recentRefund
                ? recentRefund._id
                : undefined
              : undefined;

          const body = {
            paymentStatementId: data._id,
            data: {
              referenceId,
              fee: refundAmount,
              refundItems,
              memo: memoRef.current.value,
              noMoreRefundsAvailable,
            },
          };
          await allowRefundMutation.mutateAsync(body);
        } else if (data && refundAmount === 0) {
          await allowRefundMutation.mutateAsync({ paymentStatementId: data._id, data: { noMoreRefundsAvailable } });
        } else {
          toast('환불에 실패했습니다.', {
            pauseOnFocusLoss: false,
            bodyStyle: {
              textAlign: 'center',
            },
          });
        }
        close();
      },
    });
  };

  useEffect(() => {
    setRefundTargets([]);
    if (memoRef?.current) {
      memoRef.current.value = '';
    }
  }, [show]);
  useEffect(() => {
    setNoMoreRefundsAvailable((data?.refund?.data?.[0] as RefundLog)?.noMoreRefundsAvailable || false);
  }, [data, show]);
  useEffect(() => {
    if (data?.statuses.refund && refundStatusesByUser.includes(data.statuses.refund)) {
      userRefundLog?.[0]?.refundItems?.forEach((item) =>
        setRefundTargets((prev) => {
          const newValue = [...prev];
          newValue.push({ ...item, fee: item.count * (item.fee || 0) });

          return newValue;
        }),
      );
    }
  }, [data, show]);
  useEffect(() => {
    const refundAmount = refundTargets.reduce((prev, cur) => prev + (cur.fee || 0), 0);
    let reserveRefundAmount = 0;

    if (reservedItems.length > 0) {
      reserveRefundAmount = reservedItems.reduce((prev, cur) => prev + cur.count * (cur.fee || 0), 0);
    }

    setRefundAmount(refundAmount + reserveRefundAmount);
  }, [refundTargets]);
  useEffect(() => {
    if (canRefundZeroCount) {
      setRefundTargets((target) => {
        const newTarget = [...target];

        data?.purchases.forEach((item) => {
          if (item.fee === 0) newTarget.push(item);
        });

        return newTarget;
      });
    } else {
      data?.purchases.forEach((item) => {
        if (item.fee === 0) {
          const refundItemIndex = getRefundItemIndex(item);
          if (refundItemIndex !== -1) {
            setRefundTargets((prev) => {
              const newValue = [...prev];
              newValue.splice(refundItemIndex, 1);

              return newValue;
            });
          }
        }
      });
    }
  }, [canRefundZeroCount, show]);

  return (
    <Modal
      centered
      open={show}
      closable={false}
      footer={null}
      width={1000}
      zIndex={998}
      onCancel={() => {
        close();
      }}>
      <section className="refund-mode">
        <SlideMenu
          className="refund-mode__container"
          label={<h2 className="refund-mode__title">결제 정보</h2>}
          defaultOn>
          <section className="refund-mode__memo">
            <section className="refund-mode__memo-item" key={PURCHASE_INFO.title}>
              <h3 className="refund-mode__sub-title">{PURCHASE_INFO.title}</h3>
              <article>
                {PURCHASE_INFO.childrens.map((children) => (
                  <div className="refund-mode__text-container" key={children.key} style={{ whiteSpace: 'pre-wrap' }}>
                    <p className="refund-mode__label">{children.key}</p>
                    <p>{children.value}</p>
                  </div>
                ))}
                <div className="refund-mode__text-container">
                  <p className="refund-mode__label">결제상품</p>
                  <p>{data?.product.name || '-'}</p>
                </div>
                <div className="refund-mode__text-container--column">
                  <p className="refund-mode__label">결제 옵션</p>
                  {data?.purchases?.map((info) => (
                    <p key={JSON.stringify(info.name)}>
                      &nbsp;&nbsp;- {info.name} ({info.fee?.toLocaleString()}원*{info.count}개)
                    </p>
                  ))}
                </div>
              </article>
            </section>
            {remainAmount > 0 && (
              <section
                style={{
                  backgroundColor: '#c5e0ff',
                  borderRadius: '8px',
                  padding: '10px',
                  marginTop: '10px',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  textAlign: 'right',
                }}>
                잔여금액: {remainAmountText}
              </section>
            )}
          </section>
        </SlideMenu>
        {data?.shipping && (
          <SlideMenu
            className="refund-mode__container"
            label={<h2 className="refund-mode__title">배송정보</h2>}
            defaultOn>
            <section className="refund-mode__memo">
              <section className="refund-mode__memo-item-container">
                <article>
                  {Object.keys(data?.shipping).map((key) => {
                    const label = SHIPPING_LABELS[key];
                    return (
                      <div className="refund-mode__text-container" key={key}>
                        <p className="refund-mode__label">{label}</p>
                        <p className="refund-mode__text">{(data?.shipping as { [key: string]: string })[key]}</p>
                      </div>
                    );
                  })}
                  <div className="refund-mode__text-container">
                    <p className="refund-mode__label">배송메모</p>
                    <p className="refund-mode__text">{data?.memo?.shipping}</p>
                  </div>
                </article>
              </section>
            </section>
          </SlideMenu>
        )}
        {data?.product.saleType === 'commerce' && (
          <SlideMenu className="refund-mode__container" label={<h2 className="refund-mode__title">메모</h2>} defaultOn>
            <section className="refund-mode__memo">
              <section className="refund-mode__memo-item-container">
                {REFUND_MEMO.map((item) => (
                  <section className="refund-mode__memo-item" key={item.title}>
                    <h3 className="refund-mode__sub-title">{item.title}</h3>
                    <article>
                      {item.childrens.map((children) => (
                        <div className="refund-mode__text-container" key={children.key}>
                          <p className="refund-mode__label">{children.key}</p>
                          <p className="refund-mode__text">{children.value}</p>
                        </div>
                      ))}
                    </article>
                  </section>
                ))}
              </section>
              <section className="refund-mode__memo-item refund-mode__cost">
                <p className="refund-mode__cost-item">요청 환불 금액: {BUYER_REFUND_COST.toLocaleString() || 0} 원</p>
                <p className="refund-mode__cost-item">
                  승인 환불 금액:{' '}
                  {data?.statuses.refund === RefundStatus.ACCEPTED ? partnerRefundLog?.[0].amount?.toLocaleString() : 0}
                  원
                </p>
              </section>
            </section>
          </SlideMenu>
        )}
        {refundDoneHistory.length ? (
          <SlideMenu className="refund-mode__container" label={<h2 className="refund-mode__title">이전 환불 내역</h2>}>
            <section className="refund-mode__memo">
              {refundDoneHistory.map((item, idx) => (
                <section className="refund-mode__memo-item" key={idx}>
                  <h3 className="refund-mode__sub-title">환불내역-{idx + 1}</h3>
                  <article>
                    <div className="refund-mode__text-container">
                      <p className="refund-mode__label">환불 금액</p>
                      <p>
                        {item.amount?.toLocaleString()}원({item.point?.toLocaleString()}P)
                      </p>
                    </div>
                    <div className="refund-mode__text-container">
                      <p className="refund-mode__label">환불 시각</p>
                      <p>{moment(item.createdAt).format('YYYY-MM-DD HH:mm')}</p>
                    </div>
                    <div className="refund-mode__text-container">
                      <p className="refund-mode__label">환불 메모</p>
                      <p>{'memo' in item ? item.memo : '-'}</p>
                    </div>
                    <div className="refund-mode__text-container--column">
                      <p className="refund-mode__label">환불 상품</p>
                      {(item?.refundItems || []).map((info) => (
                        <p key={JSON.stringify(info.name)}>
                          &nbsp;&nbsp;- {info.name} ({info.fee?.toLocaleString()}원*{info.count}개)
                        </p>
                      ))}
                    </div>
                    {item.receiptUrl && (
                      <div className="refund-mode__text-container">
                        <p
                          className="refund-mode__receipt"
                          onClick={() => {
                            window.open(`${item.receiptUrl}`, '_blank', 'width=600, height=400, top= 100, left=-50');
                          }}>
                          [환불 영수증]
                        </p>
                      </div>
                    )}
                  </article>
                </section>
              ))}
            </section>
          </SlideMenu>
        ) : null}
        {data?.product.saleType === 'commerce' && reservedItems.length > 0 && (
          <section>
            <section className="refund-mode__container--row">
              <h2 className="refund-mode__title">환불 예약 내역</h2>
              <button className="refund-mode__btn refund-mode__btn--small" onClick={cancelReserveRefundPayment}>
                환불예약 취소
              </button>
            </section>
            <Table columns={REFUND_RESERVE_COLUMNS} dataSource={reservedItems} pagination={false} />
          </section>
        )}
        {remainAmount > 0 && (
          <section className="refund-mode__container">
            <div className="refund-mode__container--row">
              <h2 className="refund-mode__title">결제 내역(환불 대상)</h2>
              {reservedItems.length < 1 && data?.product.saleType === 'commerce' && (
                <section className="refund-mode__option">
                  <Checkbox disabled checked={data?.refund?.noMoreRefundsAvailable || false}>
                    현재 "추가 환불 불가" 상태
                  </Checkbox>
                  <Checkbox
                    checked={noMoreRefundsAvailable}
                    onChange={() => setNoMoreRefundsAvailable(!noMoreRefundsAvailable)}>
                    추가 환불 불가
                  </Checkbox>
                  <button
                    className="refund-mode__btn refund-mode__btn--small"
                    onClick={reserveRefundPayment}
                    disabled={refundTargets.length === 0}>
                    환불예약
                  </button>
                </section>
              )}
            </div>
            <Table
              columns={REFUND_COLUMNS}
              dataSource={(data?.purchases || []).filter((purchase) => purchase.name !== 'discount')}
              pagination={false}
            />
          </section>
        )}
        {remainAmount > 0 && (
          <section className="refund-mode__container--row">
            <section>
              <h4 className="refund-mode__label">환불메모</h4>
              <textarea
                className="refund-mode__textarea"
                ref={memoRef}
                rows={2}
                placeholder="(선택) 환불 메모를 입력해 주세요!"
              />
            </section>
            <section className="refund-mode__total-cost">
              <h4 className="refund-mode__label">총 환불 금액</h4>
              <InputNumber
                size="large"
                value={refundAmount || 0}
                min={0}
                max={remainAmount}
                onChange={(value) => {
                  if (value && value <= remainAmount) {
                    setRefundAmount(value);
                  }
                }}
                disabled={data?.product.saleType === 'commerce'}
              />
            </section>
            <button
              className="refund-mode__btn refund-mode__btn--big"
              disabled={refundAmount > remainAmount}
              onClick={() => submitRefund()}>
              환불처리
            </button>
          </section>
        )}
      </section>
    </Modal>
  );
};

export default RefundModeModal;
