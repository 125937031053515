import * as statisticsApi from '@apis/statistics';
import { Feed } from '@models/feed';
import { Button, Input, Switch } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

export const SearchPopularKeywordsEdit: React.FC<{
  data?: Feed;
  onChange?: (data: Feed) => void;
}> = (props) => {
  const [changedData, setChangedData] = useState<Feed>();
  const [period, setPeriod] = useState<number>(7);

  const { data } = props;
  const contents = changedData?.data?.[0].data || data?.data?.[0].data || [];
  const sync = changedData?.data?.[0].sync === undefined ? data?.data?.[0].sync : changedData?.data?.[0].sync;
  const syncPeriod =
    changedData?.data?.[0].period === undefined ? data?.data?.[0].period : changedData?.data?.[0].period;
  const title = changedData?.data?.[0].title || data?.data?.[0].title;

  useEffect(() => {
    if (changedData) {
      props.onChange &&
        props.onChange({
          ...data,
          ...changedData,
        } as Feed);
    }
  }, [changedData]);

  const getPopularKeywords = (startDate: Date, endDate: Date) => {
    statisticsApi.getSearchTextRank(startDate.toISOString(), endDate.toISOString()).then((response) => {
      const keywords = response.data;
      if (keywords && keywords.length > 0) {
        setChangedData((prev) => {
          return {
            ...data,
            ...prev,
            type: 'popular-keywords',
            data: [{ title: title, data: keywords.slice(0, 6), sync, period: syncPeriod }],
          } as Feed;
        });
      }
    });
  };

  return (
    <div>
      <div style={{ marginBottom: '12px', marginTop: '12px' }}>
        <div style={{ fontWeight: 'bold' }}>타이틀</div>
        <Input
          style={{ width: '428px' }}
          value={title}
          placeholder={'많이 찾는 인기 검색어'}
          onChange={(e) => {
            setChangedData((prev) => {
              return {
                ...data,
                ...prev,
                type: 'popular-keywords',
                data: [{ title: e.target.value, data: contents, sync, period: syncPeriod }],
              } as Feed;
            });
          }}></Input>
        <div style={{ display: 'flex', gap: '4px', marginTop: '8px', alignItems: 'center' }}>
          <div style={{ fontWeight: 'bold' }}>실시간 검색어 순위 사용</div>
          <Switch
            checked={sync}
            onChange={(checked) => {
              setChangedData((prev) => {
                return {
                  ...data,
                  ...prev,
                  type: 'popular-keywords',
                  data: [{ title: title, data: contents, sync: checked, period: syncPeriod }],
                } as Feed;
              });
            }}></Switch>
          {sync && (
            <div style={{ marginLeft: '12px' }}>
              최근
              <Input
                style={{ width: '120px' }}
                value={syncPeriod}
                onChange={(e) => {
                  setChangedData((prev) => {
                    return {
                      ...data,
                      ...prev,
                      type: 'popular-keywords',
                      data: [{ title: title, data: contents, sync, period: Number(e.target.value) || 0 }],
                    } as Feed;
                  });
                }}></Input>
              일
            </div>
          )}
        </div>

        {!sync && (
          <div style={{ display: 'flex', marginTop: '12px', gap: '4px' }}>
            최근
            <Input
              style={{ width: '120px' }}
              value={period}
              onChange={(e) => {
                setPeriod(Number(e.target.value) || 0);
              }}></Input>
            일
            <Button
              onClick={() => {
                getPopularKeywords(moment().subtract(period, 'days').toDate(), new Date());
              }}>
              검색어 불러오기
            </Button>
          </div>
        )}
      </div>
      {!sync && (
        <div style={{ height: '240px' }}>
          {contents.map((item, index) => {
            return (
              <div style={{ display: 'flex', marginBottom: '8px' }}>
                <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                  <Input
                    style={{ width: '428px' }}
                    value={item._id}
                    onChange={(e) => {
                      setChangedData((prev) => {
                        const newContents = [...contents];
                        newContents[index] = { _id: e.target.value };
                        return {
                          ...data,
                          ...prev,
                          type: 'popular-keywords',
                          data: [{ title: title, data: newContents, sync, period: syncPeriod }],
                        } as Feed;
                      });
                    }}></Input>
                  {`(${item.count})`}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SearchPopularKeywordsEdit;
