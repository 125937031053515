import * as api from '@apis/events';
import FileUploadButton from '@components/common/FileUploadButton';
import { closestCenter, DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, horizontalListSortingStrategy, SortableContext, useSortable } from '@dnd-kit/sortable';
import { useAuthentication } from '@hooks/authentication';
import { Event } from '@models/event';
import { useEventsStore } from '@stores/eventsStore';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { useMutation, useQuery } from '@tanstack/react-query';
import { removeTempProperties } from '@utils/dataTransform';
import { cdnImageUrl, resizeImage } from '@utils/image';
import { createRandomCode, extractFileName } from '@utils/string';
import { WEB_URL } from '@variables';
import { Button, DatePicker, Input, Modal, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { RcFile } from 'antd/es/upload';
import dayjs from 'dayjs';
import moment from 'moment';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { DeleteOutlined } from '@ant-design/icons';
import { CSS } from '@dnd-kit/utilities';
import * as statisticsApi from '@apis/statistics';
import SvgInline from '@components/common/SvgInline';

interface SortableSampleImageProps {
  onDeleteSampleImage?: (event: any) => void;
  value?: any;
  key: any;
  id: any;
  imageUrl: string;
}

const SortableSampleImage: React.FC<SortableSampleImageProps> = (props) => {
  const [hover, setHover] = useState<boolean>();
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const { onDeleteSampleImage, imageUrl } = props;
  const onClickRemove = (event: any) => {
    event.preventDefault();
    onDeleteSampleImage && onDeleteSampleImage(event);
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          width: '184px',
        }}>
        <div style={{ width: '184px', height: '184px', position: 'relative', borderRadius: '8px', overflow: 'hidden' }}>
          <img src={cdnImageUrl(imageUrl)} style={{ width: '184px', height: '184px', objectFit: 'cover' }}></img>
          {hover && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
                backgroundColor: 'rgba(0,0,0,0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <button className="link-button" style={{ width: '48px', height: '48px' }} onClick={onClickRemove}>
                <div style={{ color: 'white' }}>
                  <DeleteOutlined style={{ color: 'white' }} size={48} />
                  <div>삭제하기</div>
                </div>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

interface EventsEditModalProps {
  open?: boolean;
  data?: Event;
  onClose?: () => void;
  needRefetch?: () => void;
}

const EventsEditModal: React.FC<EventsEditModalProps> = (props) => {
  const [data, setData] = useState<Event>();
  const [imageFiles, setImageFiles] = useState<{ [key: string]: File }>({});

  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
  }, [props.data]);
  const dialog = useOkCancelDialog();
  const updateEventsMutation = useMutation({
    mutationFn: api.updateEvent,
    onSuccess: async () => {
      setData(undefined);
      props.onClose && props.onClose();
      dialog.open({
        content: '수정했습니다.',
        type: 'ok',
      });

      props.needRefetch && props.needRefetch();
    },
    onError: async (e: any) => {
      setData(undefined);
      props.onClose && props.onClose();
      dialog.open({
        title: '오류',
        content: e?.response?.data?.message,
        type: 'ok',
      });
    },
  });

  const createEventsMutation = useMutation({
    mutationFn: api.createEvent,
    onSuccess: async () => {
      setData(undefined);
      props.onClose && props.onClose();
      dialog.open({
        content: '추가했습니다.',
        type: 'ok',
      });

      props.needRefetch && props.needRefetch();
    },
    onError: async (e: any) => {
      setData(undefined);
      props.onClose && props.onClose();
      dialog.open({
        title: '오류',
        content: e?.response?.data?.message,
        type: 'ok',
      });
    },
  });

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 10 },
    }),
  );

  const onChangeImage = async (files: FileList, key: string) => {
    const { fileName } = extractFileName(files[0].name);
    const newFiles = await Promise.all(
      Array.from(files)
        .slice(0, 10)
        .map(async (file) => {
          const { fileExt } = extractFileName(file.name);

          if (fileExt === 'svg') {
            return { image: file, type: file.type };
          }

          const result = await resizeImage(file, { maxWidth: 856 });

          return result;
        }),
    );
    const contentTypes = newFiles[0].type.split('/');
    const uploadFileName = `${fileName}.${contentTypes[1]}`.replace('+xml', '');
    const newImageFile = new File([newFiles[0].image], `${fileName}.${contentTypes[1]}`.replace('+xml', '')) as RcFile;
    const tempUrl = URL.createObjectURL(newFiles[0].image);
    setData((prev) => {
      const newValue = { ...prev, [key]: uploadFileName, [`${key}Temp`]: tempUrl } as Event;
      return newValue;
    });

    setImageFiles((value) => {
      const newValue = { ...value };
      newValue[key] = newImageFile;
      return newValue;
    });
  };

  const onDeleteSampleImage = (index: number) => {
    const newSampleImages = [...(data?.sampleImages || [])];
    const newSampleImagesTemp = [...(data?.sampleImagesTemp || data?.sampleImages || [])];

    newSampleImages.splice(index, 1);
    newSampleImagesTemp.splice(index, 1);
    setData((prev) => {
      const newValue = { ...prev, sampleImagesTemp: newSampleImagesTemp, sampleImages: newSampleImages } as Event;
      return newValue;
    });
  };

  const onChangeSampleImageFiles = async (files: FileList) => {
    const newImageFiles: any = {};
    const newFiles = await Promise.all(
      Array.from(files).map(async (file) => {
        const result = await resizeImage(file, { maxWidth: 856 });
        const { fileName } = extractFileName(file.name);
        const contentTypes = result.type.split('/');
        const imageFile = new File([result.image], `${fileName}.${contentTypes[1]}`) as RcFile;
        const tempUrl = URL.createObjectURL(result.image);
        imageFiles[file.name] = imageFile;

        return { result, fileName: `${fileName}.${contentTypes[1]}`, imageFile, tempUrl };
      }),
    );

    setImageFiles((value) => {
      const newValue = { ...value, ...newImageFiles };
      return newValue;
    });

    const tempUrls: string[] = [];
    const fileNames: string[] = [];

    newFiles.forEach((newFile) => {
      tempUrls.push(newFile.tempUrl);
      fileNames.push(newFile.fileName);
    });

    const newSampleImagesTemp = [...(data?.sampleImagesTemp || data?.sampleImages || []), ...tempUrls];
    const newSampleImages = [...(data?.sampleImages || []), ...fileNames];

    setData((prev) => {
      const newValue = { ...prev, sampleImagesTemp: newSampleImagesTemp, sampleImages: newSampleImages } as Event;
      return newValue;
    });
  };

  const onSampleImagesSortEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const oldIndex = (data?.sampleImagesTemp || data?.sampleImages || []).findIndex((item) => item === active.id);
      const newIndex = (data?.sampleImagesTemp || data?.sampleImages || []).findIndex((item) => item === over?.id);
      const newSampleImages = arrayMove(data?.sampleImages || [], oldIndex, newIndex);
      const newSampleImagesTemp = arrayMove(data?.sampleImagesTemp || data?.sampleImages || [], oldIndex, newIndex);

      setData((prev) => {
        const newValue = { ...prev, sampleImagesTemp: newSampleImagesTemp, sampleImages: newSampleImages } as Event;
        return newValue;
      });
    }
  };

  return (
    <Modal
      styles={{ footer: { height: '44px' } }}
      open={props.open}
      footer={
        <div style={{ width: '100%' }}>
          <Button
            style={{
              width: 120,
            }}
            onClick={props.onClose}>
            취소
          </Button>
          <Button
            style={{
              width: 120,
              marginLeft: '8px',
            }}
            onClick={() => {
              const formData: FormData = new FormData();
              const json = removeTempProperties(data);
              formData.append('json', JSON.stringify(json));

              Object.values(imageFiles).forEach((file) => {
                formData.append('files', file);
              });

              if (props.data?._id) {
                formData.append('eventId', props.data?._id);
                updateEventsMutation.mutate(formData);
              } else {
                createEventsMutation.mutate(formData);
              }
            }}>
            {props.data?._id ? '수정하기' : '생성하기'}
          </Button>
        </div>
      }
      onCancel={props.onClose}>
      <div style={{ height: '48px', fontSize: '18px', fontWeight: 'bold' }}>
        {props.data?._id ? '이벤트 수정' : '신규 이벤트 생성'}
      </div>
      <OverlayScrollbarsComponent
        defer
        options={{ scrollbars: { autoHide: 'scroll' } }}
        style={{
          backgroundColor: '#ffffff',
          marginTop: '8px',
          position: 'relative',
          height: 'calc(100dvh - 340px)',
          overflow: 'auto',
        }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', marginRight: '8px' }}>
            <div style={{ display: 'flex', gap: '4px' }}>
              <Select
                style={{ width: '200px' }}
                value={data?.status}
                placeholder={'상태'}
                options={[
                  { value: 'active', label: '사용' },
                  { value: 'inactive', label: '중단' },
                ]}
                onChange={(value) => {
                  setData((prev) => {
                    return { ...data, ...prev, status: value } as Event;
                  });
                }}></Select>
              <Select
                style={{ width: '200px' }}
                value={data?.theme || 'default'}
                placeholder={'페이지 테마'}
                options={[
                  { value: 'default', label: '기본' },
                  { value: 'white', label: '화이트' },
                ]}
                onChange={(value) => {
                  setData((prev) => {
                    return { ...data, ...prev, theme: value } as Event;
                  });
                }}></Select>
            </div>

            <div style={{ display: 'flex', gap: '8px' }}>
              <div style={{ width: '80px', flexShrink: 0 }}>이벤트 이름</div>
              <div style={{ width: '100%' }}>
                <Input
                  value={data?.name}
                  onChange={(e) => {
                    setData((prev) => {
                      return { ...prev, name: e.target.value } as Event;
                    });
                  }}></Input>
              </div>
            </div>
            <div style={{ display: 'flex', gap: '8px' }}>
              <div style={{ width: '80px', flexShrink: 0 }}>이벤트 별명</div>
              <div style={{ width: '100%', display: 'flex', gap: '8px' }}>
                <Input
                  value={data?.alias}
                  onChange={(e) => {
                    setData((prev) => {
                      return { ...prev, alias: e.target.value } as Event;
                    });
                  }}></Input>
                <Button
                  onClick={() => {
                    setData((prev) => {
                      return { ...prev, alias: createRandomCode(6) } as Event;
                    });
                  }}>
                  랜덤 코드 생성
                </Button>
              </div>
            </div>
            <div style={{ display: 'flex', gap: '8px' }}>
              <div style={{ width: '80px', flexShrink: 0 }}>제목</div>
              <div style={{ width: '100%' }}>
                <Input.TextArea
                  value={data?.title}
                  onChange={(e) => {
                    setData((prev) => {
                      return { ...prev, title: e.target.value } as Event;
                    });
                  }}></Input.TextArea>
              </div>
            </div>
            <div style={{ display: 'flex', gap: '8px' }}>
              <div style={{ width: '80px', flexShrink: 0 }}>설명</div>
              <div style={{ width: '100%' }}>
                <Input.TextArea
                  value={data?.description}
                  onChange={(e) => {
                    setData((prev) => {
                      return { ...prev, description: e.target.value } as Event;
                    });
                  }}></Input.TextArea>
              </div>
            </div>
            <div style={{ display: 'flex', gap: '8px' }}>
              <div style={{ width: '80px', flexShrink: 0 }}>이벤트 시작</div>
              <div style={{ width: '100%' }}>
                <DatePicker
                  value={dayjs(data?.startDate)}
                  showTime={{ showSecond: false }}
                  onChange={(value, dateString) => {
                    setData((prev) => {
                      return {
                        ...prev,
                        startDate: moment(dateString, 'YYYY-MM-DD HH:mm:00').toDate(),
                      } as Event;
                    });
                  }}
                />
              </div>
            </div>
            <div style={{ display: 'flex', gap: '8px' }}>
              <div style={{ width: '80px', flexShrink: 0 }}>이벤트 종료</div>
              <div style={{ width: '100%' }}>
                <DatePicker
                  value={dayjs(data?.endDate)}
                  showTime={{ showSecond: false }}
                  onChange={(value, dateString) => {
                    setData((prev) => {
                      return {
                        ...prev,
                        endDate: moment(dateString, 'YYYY-MM-DD HH:mm:00').toDate(),
                      } as Event;
                    });
                  }}
                />
              </div>
            </div>
            <div style={{ display: 'flex', gap: '8px' }}>
              <div style={{ width: '80px', flexShrink: 0 }}>버튼 이름</div>
              <Input
                value={data?.buttonLabel}
                onChange={(e) => {
                  setData((prev) => {
                    return { ...prev, buttonLabel: e.target.value } as Event;
                  });
                }}></Input>
            </div>
            <div style={{ display: 'flex', gap: '8px' }}>
              <div style={{ width: '80px', flexShrink: 0 }}>버튼 링크</div>
              <Input
                value={data?.buttonUrl}
                onChange={(e) => {
                  setData((prev) => {
                    return { ...prev, buttonUrl: e.target.value } as Event;
                  });
                }}></Input>
            </div>
            <div style={{ display: 'flex', gap: '8px' }}>
              <div style={{ width: '80px', flexShrink: 0 }}>다음 버튼</div>
              <Input
                value={data?.nextButtonLabel}
                onChange={(e) => {
                  setData((prev) => {
                    return { ...prev, nextButtonLabel: e.target.value } as Event;
                  });
                }}></Input>
            </div>
            <div style={{ display: 'flex', gap: '8px' }}>
              <div style={{ width: '80px', flexShrink: 0 }}>메인 이미지</div>
              <img
                src={cdnImageUrl(data?.mainImageUrlTemp || data?.mainImageUrl)}
                style={{ height: '40px', borderRadius: 0, objectFit: 'cover' }}
              />
              <div style={{ marginLeft: 'auto', display: 'flex', flexShrink: 0, gap: '4px' }}>
                <FileUploadButton
                  accept={['jpg', 'jpeg', 'png', 'tif', 'tiff', 'webp', 'svg']
                    .map((ext) => {
                      return '.' + ext;
                    })
                    .join(',')}
                  className="link-button"
                  style={{
                    fontSize: '14px',
                    height: '32px',
                    padding: '4px 12px',
                    borderRadius: '6px',
                    backgroundColor: '#ffffff',
                    border: '1px solid #d9d9d9',
                    color: 'rgba(0, 0, 0, 0.88)',
                    boxShadow: '0 2px 0 rgba(0, 0, 0, 0.02)',
                    cursor: 'pointer',
                    marginLeft: 'auto',
                  }}
                  onChange={(e) => {
                    if (e.target.files) {
                      const files = e.target.files;
                      onChangeImage(files, 'mainImageUrl');
                    }
                  }}>
                  이미지 변경
                </FileUploadButton>
                <Button
                  onClick={() => {
                    setData((prev) => {
                      const newValue = { ...prev, logoUrl: null, logoUrlTemp: null } as Event;
                      return newValue;
                    });
                  }}>
                  <div>
                    <DeleteOutlined style={{ color: 'red' }} size={48} />
                  </div>
                </Button>
              </div>
            </div>
            <div style={{ display: 'flex', gap: '8px' }}>
              <div style={{ width: '80px', flexShrink: 0 }}>로고 이미지</div>
              <img
                src={cdnImageUrl(data?.logoUrlTemp || data?.logoUrl)}
                style={{ height: '40px', borderRadius: 0, objectFit: 'cover' }}
              />
              <div style={{ marginLeft: 'auto', display: 'flex', flexShrink: 0, gap: '4px' }}>
                <FileUploadButton
                  accept={['jpg', 'jpeg', 'png', 'tif', 'tiff', 'webp', 'svg']
                    .map((ext) => {
                      return '.' + ext;
                    })
                    .join(',')}
                  className="link-button"
                  style={{
                    fontSize: '14px',
                    height: '32px',
                    padding: '4px 12px',
                    borderRadius: '6px',
                    backgroundColor: '#ffffff',
                    border: '1px solid #d9d9d9',
                    color: 'rgba(0, 0, 0, 0.88)',
                    boxShadow: '0 2px 0 rgba(0, 0, 0, 0.02)',
                    cursor: 'pointer',
                    marginLeft: 'auto',
                  }}
                  onChange={(e) => {
                    if (e.target.files) {
                      const files = e.target.files;
                      onChangeImage(files, 'logoUrl');
                    }
                  }}>
                  이미지 변경
                </FileUploadButton>
                <Button
                  onClick={() => {
                    setData((prev) => {
                      const newValue = { ...prev, logoUrl: null, logoUrlTemp: null } as Event;
                      return newValue;
                    });
                  }}>
                  <div>
                    <DeleteOutlined style={{ color: 'red' }} size={48} />
                  </div>
                </Button>
              </div>
            </div>

            <div style={{ display: 'flex', gap: '8px' }}>
              <div style={{ width: '80px', flexShrink: 0 }}>배지 이미지</div>
              {(data?.badgeUrlTemp || data?.badgeUrl || '').endsWith('svg') ? (
                <SvgInline url={cdnImageUrl(data?.badgeUrlTemp || data?.badgeUrl)!} />
              ) : (
                <img
                  src={cdnImageUrl(data?.badgeUrlTemp || data?.badgeUrl)}
                  style={{ width: '220px', height: '80px', borderRadius: 0, objectFit: 'cover' }}
                />
              )}
              <div style={{ marginLeft: 'auto', display: 'flex', flexShrink: 0, gap: '4px' }}>
                <FileUploadButton
                  accept={['jpg', 'jpeg', 'png', 'tif', 'tiff', 'webp', 'svg']
                    .map((ext) => {
                      return '.' + ext;
                    })
                    .join(',')}
                  className="link-button"
                  style={{
                    fontSize: '14px',
                    height: '32px',
                    padding: '4px 12px',
                    borderRadius: '6px',
                    backgroundColor: '#ffffff',
                    border: '1px solid #d9d9d9',
                    color: 'rgba(0, 0, 0, 0.88)',
                    boxShadow: '0 2px 0 rgba(0, 0, 0, 0.02)',
                    cursor: 'pointer',
                    marginLeft: 'auto',
                  }}
                  onChange={(e) => {
                    if (e.target.files) {
                      const files = e.target.files;
                      onChangeImage(files, 'badgeUrl');
                    }
                  }}>
                  이미지 변경
                </FileUploadButton>
                <Button
                  onClick={() => {
                    setData((prev) => {
                      const newValue = { ...prev, badgeUrl: null, badgeUrlTemp: null } as Event;
                      return newValue;
                    });
                  }}>
                  <div>
                    <DeleteOutlined style={{ color: 'red' }} size={48} />
                  </div>
                </Button>
              </div>
            </div>
            <div style={{ display: 'flex', gap: '8px' }}>
              <div style={{ width: '80px', flexShrink: 0 }}>배지 텍스트</div>
              <Input
                value={data?.badgeText}
                onChange={(e) => {
                  setData((prev) => {
                    return { ...prev, badgeText: e.target.value } as Event;
                  });
                }}></Input>
            </div>

            <FileUploadButton
              accept={['jpg', 'jpeg', 'png', 'tif', 'tiff', 'webp', 'svg']
                .map((ext) => {
                  return '.' + ext;
                })
                .join(',')}
              className="link-button"
              style={{
                fontSize: '14px',
                height: '32px',
                padding: '4px 12px',
                borderRadius: '6px',
                backgroundColor: '#ffffff',
                border: '1px solid #d9d9d9',
                color: 'rgba(0, 0, 0, 0.88)',
                boxShadow: '0 2px 0 rgba(0, 0, 0, 0.02)',
                cursor: 'pointer',
              }}
              onChange={(e) => {
                if (e.target.files) {
                  const files = e.target.files;
                  onChangeImage(files, 'imageUrl');
                }
              }}>
              이미지 변경
            </FileUploadButton>
            <img
              src={cdnImageUrl(data?.imageUrlTemp || data?.imageUrl)}
              style={{ width: '100%', height: '240px', borderRadius: 0, objectFit: 'cover' }}
            />

            <div style={{ marginTop: '12px' }}>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <div>클래스 샘플 이미지</div>
                <FileUploadButton
                  multiple
                  accept={['jpg', 'jpeg', 'png', 'tif', 'tiff', 'webp', 'svg']
                    .map((ext) => {
                      return '.' + ext;
                    })
                    .join(',')}
                  className="link-button"
                  style={{
                    fontSize: '14px',
                    height: '32px',
                    padding: '4px 15px',
                    borderRadius: '6px',
                    backgroundColor: '#ffffff',
                    border: '1px solid #d9d9d9',
                    color: 'rgba(0, 0, 0, 0.88)',
                    boxShadow: '0 2px 0 rgba(0, 0, 0, 0.02)',
                    cursor: 'pointer',
                    marginLeft: '12px',
                  }}
                  onChange={(e) => {
                    if (e.target.files) {
                      const files = e.target.files;
                      onChangeSampleImageFiles(files);
                    }
                  }}>
                  샘플 이미지 추가
                </FileUploadButton>
              </div>
              <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={onSampleImagesSortEnd}>
                <SortableContext
                  items={(data?.sampleImagesTemp || data?.sampleImages || [] || []).map((item) => item)}
                  strategy={horizontalListSortingStrategy}>
                  <div style={{ display: 'flex', gap: '4px', overflow: 'auto', maxWidth: '600px' }}>
                    {(data?.sampleImagesTemp || data?.sampleImages || [] || []).map(
                      (imageUrl: string, index: number) => {
                        return (
                          <SortableSampleImage
                            key={`image-${imageUrl}-${index}`}
                            id={imageUrl}
                            onDeleteSampleImage={() => {
                              onDeleteSampleImage(index);
                            }}
                            imageUrl={imageUrl}
                          />
                        );
                      },
                    )}
                  </div>
                </SortableContext>
              </DndContext>
            </div>
          </div>
        </div>
      </OverlayScrollbarsComponent>
    </Modal>
  );
};

export const EventsPage: React.FC = () => {
  const [editItem, setEditItem] = useState<Event>();
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [searchKeyword, setSearchKeyword] = useState<string>();
  const [statistics, setStatistics] = useState<{ [key: string]: any }>({});
  const searchInputRef = useRef<any>();

  const store = useEventsStore();

  useAuthentication();

  const eventsQuery = useQuery({
    queryKey: [`events`, JSON.stringify(store.searchQuery)],
    queryFn: () => api.searchEvents(store.searchQuery),
  });

  const events = eventsQuery.data?.data;

  useEffect(() => {
    if (events) {
      store.setEventsContainer(events);
      events.contents.forEach((event) => {
        statisticsApi.getPageViewCount({ path: `/events/${event.alias}`, by: 'from' }).then((response) => {
          setStatistics((value) => {
            return { ...value, [`/events/${event.alias}`]: response.data };
          });
        });
      });
    }
  }, [events]);

  const TABLE_COLUMNS: ColumnsType<Event> = [
    {
      title: 'ID',
      dataIndex: '_id',
      render: (_id) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(_id);
                toast.dark('ID를 클립보드에 복사했습니다', {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  pauseOnFocusLoss: false,
                  draggable: true,
                  progress: undefined,
                  bodyStyle: {
                    color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                    textAlign: 'center',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                  },
                  theme: 'dark',
                });
              }}>
              ID
            </Button>
          </div>
        );
      },
    },
    {
      title: '타이틀',
      render: (record: Event) => {
        return record.title;
      },
    },
    {
      title: '설명',
      render: (record: Event) => {
        return record.description;
      },
    },
    {
      title: '상태',
      width: 100,
      render: (record: Event) => {
        if (record.status === 'active') {
          return (
            <div className="badge" style={{ backgroundColor: '#3fb547' }}>
              사용중
            </div>
          );
        }
        return (
          <div className="badge" style={{ backgroundColor: '#bd334a' }}>
            중단
          </div>
        );
      },
    },
    {
      title: 'URL',
      width: 100,
      render: (record: Event) => {
        return (
          <Button
            onClick={() => {
              navigator.clipboard.writeText(`${WEB_URL}/events/${record.alias}`);
              toast.dark('이벤트 URL을 클립보드에 복사했습니다', {
                position: 'top-center',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                pauseOnFocusLoss: false,
                draggable: true,
                progress: undefined,
                bodyStyle: {
                  color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                  textAlign: 'center',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '20px',
                },
                theme: 'dark',
              });
            }}>
            URL 복사
          </Button>
        );
      },
    },
    {
      title: '수정하기',
      width: 100,
      render: (record: Event) => {
        return (
          <Button
            onClick={() => {
              setEditItem(record);
              setShowCreateModal(true);
            }}>
            수정
          </Button>
        );
      },
    },
    {
      title: '이벤트 기간',
      width: 180,
      render: (record: Event) => {
        return (
          <div>
            {record.startDate && <div>{moment(record.startDate).format('YYYY.MM.DD HH:mm')}</div>}
            <div>~</div>
            {record.endDate && <div>{moment(record.endDate).format('YYYY.MM.DD HH:mm')}</div>}
          </div>
        );
      },
    },
    {
      title: '통계',
      width: 180,
      render: (record: Event) => {
        const data = statistics[`/events/${record.alias}`];

        if (data) {
          return data.map((item: any) => {
            return <div>{`${item._id || 'direct'}(${item.count})`}</div>;
          });
        }
        return <div></div>;
      },
    },
    {
      title: '생성',
      dataIndex: 'createdAt',
      render: (createdAt) => {
        if (!createdAt) {
          return '-';
        }
        return moment(createdAt).format('YYYY.MM.DD HH:mm');
      },
    },
    {
      title: '수정',
      dataIndex: 'updatedAt',
      render: (updatedAt) => {
        if (!updatedAt) {
          return '-';
        }
        return moment(updatedAt).format('YYYY.MM.DD HH:mm');
      },
    },
  ];
  return (
    <div className="dashboard" style={{ position: 'relative' }}>
      <EventsEditModal
        data={editItem}
        open={showCreateModal}
        onClose={() => {
          setShowCreateModal(false);
          setEditItem(undefined);
        }}
        needRefetch={() => {
          eventsQuery.refetch();
        }}></EventsEditModal>

      <div className="page-header">
        <div>이벤트 페이지 관리</div>
      </div>
      <div style={{ display: 'flex' }}>
        <div className="page-content" style={{ width: '100%', height: 'calc(100vh - 80px)', overflow: 'auto' }}>
          <div style={{ display: 'flex', gap: '12px', width: '100%', height: '52px', alignItems: 'center' }}>
            <Input
              ref={searchInputRef}
              style={{ width: '240px' }}
              value={searchKeyword}
              placeholder={'검색어 입력 후 엔터'}
              onChange={(e) => {
                setSearchKeyword(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.code === 'Enter' && searchKeyword) {
                  store.updateSearchQuery({ keyword: searchKeyword });
                }
              }}></Input>
            <Button
              onClick={() => {
                setSearchKeyword(undefined);
                store.resetSearchQuery();
              }}>
              검색 초기화
            </Button>
            <div style={{ display: 'flex', marginLeft: 'auto', gap: '12px', alignItems: 'center' }}>
              <Button
                onClick={() => {
                  setShowCreateModal(true);
                }}>
                신규 이벤트 생성
              </Button>
            </div>
          </div>
          <Table
            columns={TABLE_COLUMNS}
            scroll={{ x: 'max-content', y: 'calc(100dvh - 280px)' }}
            style={{ whiteSpace: 'pre-wrap' }}
            rowKey={(record) => record?._id}
            dataSource={store.searchResultContainer.contents.filter((item) => item?._id)}
            pagination={{
              current: (store.searchResultContainer.page || 0) + 1,
              pageSize: store.searchResultContainer.size || 20,
              total: store.searchResultContainer.total,
            }}
            loading={eventsQuery.isLoading}
            onChange={(e) => {
              store.updateSearchQuery({ page: (e.current || 2) - 1, size: e.pageSize || 20 });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default EventsPage;
