import { PagenatedResponse, SearchPagenateQuery } from '@models/common';
import {
  AdminCreditPointCoupon,
  AdminCreditPointCouponLog,
  AdminCreditPointLog,
  AdminCreditPointOnetimeCoupon,
  AdminCreditPointTemplate,
  CreatePointCouponBody,
  CreatePointTemplateBody,
} from '@models/credit-points';
import { apiClient } from '.';

export const searchCreditPointLogs = async (query?: SearchPagenateQuery) => {
  return apiClient.get<PagenatedResponse<AdminCreditPointLog>>(`/admin/v1/credit-points/logs`, {
    params: query,
  });
};

export const searchCouponLogs = async (query?: SearchPagenateQuery) => {
  return apiClient.get<PagenatedResponse<AdminCreditPointCouponLog>>(`/admin/v1/credit-points/coupon-logs`, {
    params: query,
  });
};

export const searchCreditPointCoupons = async (query?: SearchPagenateQuery) => {
  return apiClient.get<PagenatedResponse<AdminCreditPointCoupon>>(`/admin/v1/credit-points/coupons`, {
    params: query,
  });
};

export const searchOnetimeCoupons = async (couponId: string, query?: SearchPagenateQuery) => {
  return apiClient.get<PagenatedResponse<AdminCreditPointOnetimeCoupon>>(
    `/admin/v1/credit-points/coupons/${couponId}/onetime`,
    {
      params: query,
    },
  );
};

export const getOneTimeCouponCodesExcelFile = async (couponId: string) => {
  return apiClient.get(`/admin/v1/credit-points/coupons/${couponId}/onetime/export`, {
    responseType: 'blob',
    headers: { 'Cache-Control': 'no-cache' },
  });
};

export const createCreditPointCoupon = async (body: CreatePointCouponBody) => {
  return apiClient.post<AdminCreditPointCoupon>(`/admin/v1/credit-points/coupons`, body);
};

export const createCreditPointTemplate = async (body: CreatePointTemplateBody) => {
  return apiClient.post<AdminCreditPointTemplate>(`/admin/v1/credit-points/templates`, body);
};

export const searchCreditPointTemplates = async (query?: SearchPagenateQuery) => {
  return apiClient.get<PagenatedResponse<AdminCreditPointTemplate>>(`/admin/v1/credit-points/templates`, {
    params: query,
  });
};

export const getCreditPointTemplates = async () => {
  return apiClient.get<AdminCreditPointTemplate[]>(`/admin/v1/credit-points/templates/all`);
};

export const deleteCreditPointTemplate = async (templateId: string) => {
  return apiClient.delete(`/admin/v1/credit-points/templates/${templateId}`);
};

export const deleteCreditPointCoupon = async (couponId: string) => {
  return apiClient.delete(`/admin/v1/credit-points/coupons/${couponId}`);
};

export const updateCreditPointTemplate = async (data: {
  templateId: string;
  body: Partial<CreatePointTemplateBody>;
}) => {
  const templateId = data.templateId;
  return apiClient.put<AdminCreditPointTemplate>(`/admin/v1/credit-points/templates/${templateId}`, data.body);
};

export const updateCreditPointCoupon = async (data: { couponId: string; body: Partial<CreatePointCouponBody> }) => {
  const couponId = data.couponId;
  return apiClient.put<AdminCreditPointCoupon>(`/admin/v1/credit-points/coupons/${couponId}`, data.body);
};
