export enum PushNotificationTopic {
  NOTICE = 'notice',
  NOTICE_TUTOR = 'notice_tutor',
  NOTICE_PARENT = 'notice_parents',
  ANDROID = 'android',
  ANDROID_TUTOR = 'android_tutor',
  ANDROID_PARENT = 'android_parents',
  ANDROID_SELLER = 'android_seller',
  IOS = 'ios',
  IOS_TUTOR = 'ios_tutor',
  IOS_PARENT = 'ios_parents',
  IOS_SELLER = 'ios_seller',
  TUTOR = 'tutor',
  PARENT = 'parents',
  SELLER = 'seller',
  MANUAL = 'manual',
  ETC = 'etc',
}

export const PUSH_NOTIFICATION_TARGETS: { [key: string]: string } = {
  notice: '공지사항',
  parents: '부모',
  ios_parents: '부모(IOS)',
  android_parents: '부모(AOS)',
  tutor: '튜터',
  ios_tutor: '튜터(IOS)',
  android_tutor: '튜터(AOS)',
  seller: '셀러',
  ios_seller: '셀러(IOS)',
  android_seller: '셀러(AOS)',
  manual: '유저 아이디 직접 입력',
};

export enum LegacyPushNotificationContentType {
  NORMAL = 'normal',
  PRODUCT = 'product',
  COLLECTION = 'collection',
  LINK = 'link',
  PICK = 'pick',
  NOTICE = 'notice',
  REWARD = 'reward',
}
