import { Roles } from '@consts/role';
import { RcFile } from 'antd/es/upload';
import { ChildInfo } from './chat';
import { Membership } from './membership';

export enum LoginType {
  EMAIL = 'email',
  KAKAO = 'kakao',
  NAVER = 'naver',
  APPLE = 'apple',
}
export enum ProfileApprovalState {
  WAIT = 'waitApproval',
  CONFIRM = 'confirmApproval',
  NOTHING = 'beforeApproval',
  DENIED = 'refuseApproval',
}

export interface Address {
  postcode?: string;
  postcode1?: string;
  postcode2?: string;
  postcodeSeq?: string;
  zonecode?: string;
  address?: string;
  addressEnglish?: string;
  addressType?: string;
  bcode?: string;
  bname?: string;
  bnameEnglish?: string;
  bname1?: string;
  bname1English?: string;
  bname2?: string;
  bname2English?: string;
  sido?: string;
  sidoEnglish?: string;
  sigungu?: string;
  sigunguEnglish?: string;
  sigunguCode?: string;
  userLanguageType?: string;
  query?: string;
  buildingName?: string;
  buildingCode?: string;
  apartment?: string;
  jibunAddress?: string;
  jibunAddressEnglish?: string;
  roadAddress?: string;
  roadAddressEnglish?: string;
  autoRoadAddress?: string;
  autoRoadAddressEnglish?: string;
  autoJibunAddress?: string;
  autoJibunAddressEnglish?: string;
  userSelectedType?: string;
  noSelected?: string;
  hname?: string;
  roadnameCode?: string;
  roadname?: string;
  roadnameEnglish?: string;
  address2?: string;
}
export interface AttachImage {
  id: string;
  url: string;
  file?: RcFile;
  originFileName?: string;
  contentType?: string;
}

export interface TutorContract {
  contractType: string;
  contractPhoto?: string;
  licensePhoto?: string | AttachImage[];
  bankName?: string;
  bankAccountHolder?: string;
  bankAccountNumber?: string;
  bankAccountPhoto?: string | AttachImage[];
  contractEmail?: string;
  businessName?: string;
  representative?: string;
  address?: any;
  businessNumber?: string;
}

export interface UserProfile {
  name?: string;
  nickname?: string;
  message?: string;
  realName?: string;
  educationArea?: any;
  agreement?: boolean;
  homepageId?: string;
  educationTitle?: string;
  imageUrl?: string;
  phone?: string;
  positiveWeeks?: string[];
  favorites: string[];
  contract?: TutorContract;
  creditPoints?: number;
  coupons?: string[];
  otherAlarmAgree?: boolean;
  marketingEmailAgree?: boolean;
  marketingSMSAgree?: boolean;
  children?: ChildInfo[];
  address?: Address;
  categorySubList?: string[];
  customerServicePhone?: string; // for seller
  customerServiceUrl?: string; // for seller
  mainCustomerService?: string; // for seller
  contractPhoto?: string;
  licensePhoto?: string;
  bankAccountPhoto?: string;
  commissionRate?: string;
  contractStatus?: string;
  salesChannels?: string[];
}

export interface SelectedDistricts {
  city?: string;
  district?: string;
}

export interface PartnerProfile {
  intro?: string; //greeting
  academics?: Academic[];
  careers?: Career[];
  certificates?: Certificate[];
  mainActivityZone?: SelectedDistricts[];
  updatedAt?: Date;
}

export interface Academic {
  degree?: string;
  school?: string;
  graduation?: string;
  year?: string;
  imageUrl?: string;
  approvalState?: ProfileApprovalState;
  rejectedReasonMessage?: string;
}

export interface Career {
  career?: string;
  startYearMonth?: string; // 2024-09 > migration api
  endYearMonth?: string;
  imageUrl?: string;
  approvalState?: ProfileApprovalState;
  description?: string;
  period?: string;
}

export interface Certificate {
  name?: string;
  issuer?: string;
  issuanceYearMonth?: string;
  imageUrl?: string;
  approvalState?: ProfileApprovalState;
}

export interface User {
  id: string;
  password?: string;
  email: string;
  loginType: LoginType;
  role: Roles;
  profile?: UserProfile;
  partnerProfile?: PartnerProfile;
}

export interface AdminUser {
  _id: string;
  password?: string;
  emails: { address: string; verified?: boolean }[];
  loginType: LoginType;
  roles: Roles[];
  profile?: UserProfile;
  partnerProfile?: PartnerProfile;
  membership?: Membership;
  createdAt: Date;
  deletedAt?: Date;
}

export interface CreateSellerUserBody {
  type: 'email';
  email?: string;
  password?: string;
  profileImage?: string;
  name?: string;
  roles?: Roles[];
  nickname?: string;
  phoneNumber?: string;
  customerServicePhone?: string;
  customerServiceUrl?: string;
  mainCustomerService?: string;
  contractPhotoTemp?: string;
  contractPhoto?: string;
  licensePhotoTemp?: string;
  licensePhoto?: string;
  bankAccountPhotoTemp?: string;
  bankAccountPhoto?: string;
  commissionRate?: string;
  contractStatus?: string;
  salesChannels?: string[];
}

export interface UpdateUserBody {
  type?: string;
  email?: string;
  password?: string;
  profileImage?: string;
  name?: string;
  nickname?: string;
  message?: string;
  realName?: string;
  phoneNumber?: string;
  customerServicePhone?: string;
  customerServiceUrl?: string;
  mainCustomerService?: string;
  contractPhotoTemp?: string;
  contractPhoto?: string;
  licensePhotoTemp?: string;
  licensePhoto?: string;
  bankAccountPhotoTemp?: string;
  bankAccountPhoto?: string;
  commissionRate?: string;
  contractStatus?: string;
  salesChannels?: string[];
  roles?: Roles[];
  membership?: Membership;
}
