import CheckOffIcon from '@assets/images/app/select-check.png';
import CheckOnIcon from '@assets/images/app/selection-check-on.png';
import { DaumPostcodeModal } from '@components/class-invoice/DaumPostcodeModal';
import { PAYMENT_METHODS_DICT, PaymentMethod } from '@consts/payment';
import { usePayment } from '@hooks/payment';
import { Address } from '@models/auth';
import { ProductSelectedOptionItem } from '@models/commerce';
import { ProductModel } from '@models/product';
import { useAuthStore } from '@stores/authStore';
import { cdnImageUrl } from '@utils/image';
import { getUniqueKeyFromUUID } from '@utils/string';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PaymentInProgress from './PaymentInProgress';
import PointUse from './PointUse';
import SelectPaymentMethod from './SelectPaymentMethod';

const Divider: React.FC = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '8px',
        backgroundColor: '#eff1f4',
      }}
    />
  );
};

const ProductInfo: React.FC<{ imageUrl?: string; productName?: string; optionNameList: string[] }> = ({
  imageUrl,
  productName,
  optionNameList,
}) => {
  return (
    <div
      style={{
        marginTop: '32px',
        paddingLeft: '24px',
        paddingRight: '24px',
        marginBottom: '24px',
      }}>
      <div
        style={{
          fontSize: '18px',
          lineHeight: '21px',
          color: '#242424',
          fontWeight: 'bold',
        }}>
        주문상품 정보
      </div>
      <div
        style={{
          marginTop: '20px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
        }}>
        <img
          style={{
            width: '74px',
            height: '74px',
            borderRadius: '8px',
            marginRight: '12px',
            objectFit: 'cover',
          }}
          src={cdnImageUrl(imageUrl)}
        />
        <div
          style={{
            flexDirection: 'column',
            justifyContent: 'center',
          }}>
          <div
            style={{
              fontSize: '16px',
              lineHeight: '22px',
              color: '#424242',
            }}>
            {productName}
          </div>
          <div
            style={{
              fontSize: '13px',
              lineHeight: '18px',
              color: '#9b9da0',
            }}>
            {optionNameList}
          </div>
        </div>
      </div>
    </div>
  );
};

const CustomerInfo: React.FC<{
  userName?: string;
  userPhone?: string;
  isShippingProduct?: boolean;
  isMemoRequired?: boolean;
  requestMemo?: string;
  onChangeMemo?: (value: string) => void;
}> = ({ userName, userPhone, isShippingProduct, isMemoRequired, requestMemo, onChangeMemo }) => {
  const [memo, setMemo] = useState<string>();

  useEffect(() => {
    if (memo) {
      onChangeMemo && onChangeMemo(memo);
    }
  }, [memo]);

  const errorMessage = ''; // state
  return (
    <div
      style={{
        marginTop: '24px',
        paddingLeft: '24px',
        paddingRight: '24px',
        marginBottom: '24px',
      }}>
      <div
        style={{
          fontSize: '18px',
          lineHeight: '21px',
          color: '#242424',
          fontWeight: 'bold',
        }}>
        구매자 정보
      </div>
      <div
        style={{
          marginTop: '20px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
        }}>
        <div
          style={{
            fontSize: '14px',
            lineHeight: '17px',
            color: '#66686b',
            marginRight: '40px',
          }}>
          이름
        </div>
        <div
          style={{
            fontSize: '16px',
            lineHeight: '19px',
            color: '#242424',
          }}>
          {userName}
        </div>
      </div>
      <div
        style={{
          marginTop: '20px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
        }}>
        <div
          style={{
            fontSize: '14px',
            lineHeight: '17px',
            color: '#66686b',
            marginRight: '28px',
          }}>
          연락처
        </div>
        <div
          style={{
            fontSize: '16px',
            lineHeight: '19px',
            color: '#242424',
          }}>
          {(userPhone || '').replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3').replace(/-{1,2}$/g, '')}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '4px',
          backgroundColor: '#f5f7fb',
          height: '32px',
          marginTop: '16px',
        }}>
        <div
          style={{
            fontSize: '13px',
            color: '#66686b',
            lineHeight: '16px',
          }}>
          {'구매자 정보변경 : 마이페이지 > 프로필 관리'}
        </div>
      </div>
      {!isShippingProduct && (
        <div
          style={{
            height: 1,
            backgroundColor: '#eff1f4',
            marginTop: '24px',
            marginBottom: '4px',
          }}
        />
      )}
      {!isShippingProduct && (
        <div
          style={{
            marginTop: 0,
            flexDirection: 'column',
            justifyContent: 'center',
          }}>
          {isMemoRequired ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <div
                style={{
                  fontSize: '14px',
                  lineHeight: '17px',
                  color: '#66686b',
                  marginTop: '20px',
                  marginRight: '4px',
                }}>
                필수입력정보
              </div>
              <div
                style={{
                  fontSize: '14px',
                  lineHeight: '17px',
                  color: '#ef4747',
                  marginTop: '20px',
                }}>
                *
              </div>
            </div>
          ) : (
            <div
              style={{
                fontSize: '14px',
                lineHeight: '17px',
                color: '#66686b',
                marginTop: '20px',
              }}>
              요청사항
            </div>
          )}
          <textarea
            style={{
              width: '100%',
              height: '98px',
              borderRadius: '8px',
              border: '1px solid #dadcdf',
              paddingLeft: '16px',
              paddingRight: '16px',
              marginTop: '8px',
              fontSize: '16px',
              lineHeight: '20px',
              color: '#242424',
              marginBottom: 0,
              paddingTop: '15px',
            }}
            placeholder={
              isMemoRequired
                ? '하단 요구 사항 확인 후 입력해주세요'
                : '판매자에게 전달되는 요청사항입니다 \n(특수문자 입력불가/최대 200자)'
            }
            value={memo}
            onChange={(e) => {
              setMemo(e.target.value);
            }}
            maxLength={200}
          />
          {errorMessage !== '' && (
            <div
              style={{
                fontSize: '14px',
                color: '#ef4747',
                marginTop: '8px',
              }}>
              {errorMessage}
            </div>
          )}
          {isMemoRequired && (
            <div
              style={{
                display: 'flex',
                paddingLeft: '16px',
                paddingRight: '16px',
                backgroundColor: '#f4f0ff',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '12px',
                paddingTop: '8px',
                paddingBottom: '8px',
                borderRadius: '4px',
              }}>
              <div style={{ fontSize: '13px', color: '#7950ef', whiteSpace: 'pre-wrap' }}>{requestMemo}</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

type ShippingInfoKeys =
  | 'recipientName'
  | 'recipientPhone'
  | 'recipientAddress'
  | 'recipientAddressDetail'
  | 'addressData'
  | 'memo';

interface ShippingInfoProps {
  userName?: string;
  userPhone?: string;
  userAddress?: string;
  userAddressDetail?: string;
  standardAddressData?: Address;
  isMemoRequired?: boolean;
  requestMemo?: string;
  onChangeShippingInfo?: (data: ShippingData) => void;
}

interface ShippingData {
  recipientName?: string;
  recipientPhone?: string;
  recipientAddress?: string;
  recipientAddressDetail?: string;
  addressData?: Address;
  memo?: string;
}

const ShippingInfo: React.FC<ShippingInfoProps> = (props) => {
  const [shippingInfo, setShippingInfo] = useState<ShippingData>({});
  const [showPostcodeModal, setShowPostcodeModal] = useState<boolean>(false);
  const [checkSameUserInfo, setCheckSameUserInfo] = useState<boolean>();
  const { userName, userPhone, userAddress, userAddressDetail, standardAddressData, isMemoRequired, requestMemo } =
    props;

  const errorMessage = ''; // state

  const onChangeProperty = (key: ShippingInfoKeys, value: any) => {
    setShippingInfo((prev) => {
      const newProperties = { ...prev };
      newProperties[key] = value;
      return newProperties;
    });
  };

  useEffect(() => {
    props.onChangeShippingInfo && props.onChangeShippingInfo(shippingInfo);
  }, [shippingInfo]);

  return (
    <div
      style={{
        marginTop: '24px',
        paddingLeft: '24px',
        paddingRight: '24px',
        marginBottom: '24px',
      }}>
      <DaumPostcodeModal
        open={showPostcodeModal}
        onCancel={() => {
          setShowPostcodeModal(false);
        }}
        onComplete={(address) => {
          setShowPostcodeModal(false);
          onChangeProperty('addressData', address);
          onChangeProperty('recipientAddress', address.address);
        }}
      />

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <div
          style={{
            fontSize: '18px',
            lineHeight: '21px',
            color: '#242424',
            fontWeight: 'bold',
          }}>
          배송지 정보
        </div>
        <div
          onClick={() => {
            setCheckSameUserInfo((value) => {
              if (!value) {
                setShippingInfo({
                  recipientName: userName,
                  recipientPhone: userPhone,
                  recipientAddress: userAddress,
                  recipientAddressDetail: userAddressDetail && userAddressDetail !== '' ? userAddressDetail : '',
                  addressData: standardAddressData,
                });
              }
              return !value;
            });
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            <div
              style={{
                fontSize: '14px',
                lineHeight: '17px',
                color: '#242424',
                marginRight: '8px',
              }}>
              구매자 정보와 동일
            </div>
            <img style={{ width: '24px', height: '24px' }} src={checkSameUserInfo ? CheckOnIcon : CheckOffIcon} />
          </div>
        </div>
      </div>
      <div
        style={{
          marginTop: '20px',
          flexDirection: 'column',
          justifyContent: 'center',
        }}>
        <div
          style={{
            fontSize: '14px',
            lineHeight: '17px',
            color: '#66686b',
          }}>
          받는사람
        </div>
        <input
          style={{
            height: '48px',
            borderRadius: '8px',
            border: '1px solid #dadcdf',
            paddingLeft: '16px',
            paddingRight: '16px',
            marginTop: '8px',
            fontSize: '16px',
            lineHeight: '20px',
            color: '#242424',
          }}
          value={shippingInfo?.recipientName}
          onChange={(e) => {
            onChangeProperty('recipientName', e.target.value);
          }}
          placeholder={'이름을 입력해 주세요'}
          //   // placeholderTextColor={'#b9bbbe'}
        />
      </div>
      <div
        style={{
          marginTop: '20px',
          flexDirection: 'column',
          justifyContent: 'center',
        }}>
        <div
          style={{
            fontSize: '14px',
            lineHeight: '17px',
            color: '#66686b',
          }}>
          연락처
        </div>
        <input
          style={{
            height: '48px',
            borderRadius: '8px',
            border: '1px solid #dadcdf',
            paddingLeft: '16px',
            paddingRight: '16px',
            marginTop: '8px',
            fontSize: '16px',
            lineHeight: '20px',
            color: '#242424',
          }}
          value={shippingInfo?.recipientPhone}
          onChange={(e) => {
            onChangeProperty('recipientPhone', e.target.value.replace(/[^\d]+/g, '').slice(0, 11));
          }}
          placeholder={'010-0000-0000'}
        />
      </div>
      <div
        style={{
          marginTop: '20px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
        }}>
        <div
          style={{
            fontSize: '14px',
            lineHeight: '17px',
            color: '#66686b',
          }}>
          주소
        </div>
        <div
          style={{
            marginTop: '8px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
          <div
            style={{ width: '100%', cursor: 'pointer' }}
            onClick={() => {
              setShowPostcodeModal(true);
            }}>
            <div
              style={{
                display: 'flex',
                height: '48px',
                borderRadius: '8px',
                border: '1px solid #dadcdf',
                paddingLeft: '16px',
                paddingRight: '16px',
                alignItems: 'center',
              }}>
              <div
                style={{
                  fontSize: '16px',
                  lineHeight: '20px',
                  color: '#242424',
                }}>
                {shippingInfo?.recipientAddress}
              </div>
            </div>
          </div>
          <button
            className="link-button"
            style={{
              display: 'flex',
              height: '48px',
              width: '88px',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '8px',
              backgroundColor: '#66686b',
              marginLeft: '8px',
            }}
            onClick={() => {
              setShowPostcodeModal(true);
            }}>
            <div
              style={{
                fontSize: '16px',
                lineHeight: '19px',
                fontWeight: '500',
                color: '#ffffff',
              }}>
              주소검색
            </div>
          </button>
        </div>
        <input
          style={{
            height: '48px',
            borderRadius: '8px',
            border: '1px solid #dadcdf',
            paddingLeft: '16px',
            paddingRight: '16px',
            marginTop: '12px',
            fontSize: '16px',
            lineHeight: '20px',
            color: '#242424',
          }}
          value={shippingInfo?.recipientAddressDetail}
          onChange={(e) => {
            onChangeProperty('recipientAddressDetail', e.target.value);
          }}
          placeholder={'상세주소를 입력해 주세요'}
        />
        {isMemoRequired ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            <div
              style={{
                fontSize: '14px',
                lineHeight: '17px',
                color: '#66686b',
                marginTop: '20px',
                marginRight: '4px',
              }}>
              필수입력정보
            </div>
            <div
              style={{
                fontSize: '14px',
                lineHeight: '17px',
                color: '#ef4747',
                marginTop: '20px',
              }}>
              *
            </div>
          </div>
        ) : (
          <div
            style={{
              fontSize: '14px',
              lineHeight: '17px',
              color: '#66686b',
              marginTop: '20px',
            }}>
            요청사항
          </div>
        )}
        <textarea
          style={{
            height: '98px',
            borderRadius: '8px',
            border: '1px solid #dadcdf',
            paddingLeft: '16px',
            paddingRight: '16px',
            marginTop: '8px',
            fontSize: '16px',
            lineHeight: '20px',
            color: '#242424',
            marginBottom: isMemoRequired ? 0 : '24px',
            paddingTop: '15px',
          }}
          placeholder={
            isMemoRequired
              ? '하단 요구 사항 확인 후 입력해주세요'
              : '판매자에게 전달되는 요청사항입니다 \n(특수문자 입력불가/최대 200자)'
          }
          value={shippingInfo.memo}
          onChange={(e) => {
            onChangeProperty('memo', e.target.value);
          }}
          maxLength={200}
        />
        {errorMessage !== '' && (
          <div
            style={{
              fontSize: '14px',
              color: '#ef4747',
              marginTop: '8px',
            }}>
            {errorMessage}
          </div>
        )}
        {isMemoRequired && (
          <div
            style={{
              display: 'flex',
              paddingLeft: '16px',
              paddingRight: '16px',
              backgroundColor: '#f4f0ff',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '12px',
              paddingTop: '8px',
              paddingBottom: '8px',
              borderRadius: '4px',
            }}>
            <div style={{ fontSize: '13px', color: '#7950ef' }}>{requestMemo}</div>
          </div>
        )}
      </div>
    </div>
  );
};

const PaymentAmount: React.FC<{
  totalFee: number;
  usedCreditPoint: number;
  productShippingFee: number;
  isShippingProduct?: boolean;
}> = ({ totalFee, usedCreditPoint, productShippingFee, isShippingProduct }) => {
  return (
    <div
      style={{
        marginTop: '24px',
        paddingLeft: '24px',
        paddingRight: '24px',
        marginBottom: '24px',
      }}>
      <div
        style={{
          fontSize: '18px',
          lineHeight: '21px',
          color: '#242424',
          fontWeight: 'bold',
        }}>
        결제 금액
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: '17px',
          justifyContent: 'space-between',
          marginBottom: '12px',
        }}>
        <div
          style={{
            fontSize: '14px',
            lineHeight: '17px',
            color: '#66686b',
          }}>
          상품 가격
        </div>
        <div
          style={{
            fontSize: '16px',
            lineHeight: '20px',
            color: '#242424',
          }}>
          {`${totalFee}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
        </div>
      </div>
      {isShippingProduct && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 0,
            justifyContent: 'space-between',
            marginBottom: '12px',
          }}>
          <div
            style={{
              fontSize: '14px',
              lineHeight: '17px',
              color: '#66686b',
            }}>
            배송비
          </div>
          <div
            style={{
              fontSize: '16px',
              lineHeight: '20px',
              color: '#242424',
            }}>
            {!productShippingFee ? '무료' : `${productShippingFee}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원'}
          </div>
        </div>
      )}
      {(usedCreditPoint || 0) > 0 && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 0,
            justifyContent: 'space-between',
            marginBottom: '20px',
          }}>
          <div
            style={{
              fontSize: '14px',
              lineHeight: '17px',
              color: '#66686b',
            }}>
            포인트 사용
          </div>
          <div
            style={{
              fontSize: '16px',
              lineHeight: '20px',
              color: '#ef4747',
            }}>
            -{`${usedCreditPoint}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
          </div>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: '20px',
          paddingTop: '20px',
          justifyContent: 'space-between',
          borderTop: '1px solid #eff1f4',
        }}>
        <div
          style={{
            fontSize: '14px',
            lineHeight: '17px',
            color: '#66686b',
          }}>
          최종 결제 금액
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
          <div
            style={{
              fontSize: '22px',
              lineHeight: '26px',
              color: '#242424',
              fontWeight: 'bold',
            }}>
            {`${totalFee - usedCreditPoint + productShippingFee}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </div>
          <div
            style={{
              fontSize: '16px',
              lineHeight: '19px',
              color: '#242424',
              fontWeight: 'bold',
            }}>
            원
          </div>
        </div>
      </div>
    </div>
  );
};

interface CommercePaymentProps {
  product?: ProductModel;
  totalFee: number;
  selectedOptions?: ProductSelectedOptionItem[];

  onClickBack?: () => void;
  onConfirm?: () => void;
}

export const CommercePayment: React.FC<CommercePaymentProps> = (props) => {
  const payment = usePayment();
  const { product, totalFee } = props;
  const selectedOptions = props.selectedOptions || [];

  const [usedCreditPoint, setUsedCreditPoint] = useState<number>(0);
  const [shippingData, setShippingData] = useState<ShippingData>({});
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(PAYMENT_METHODS_DICT.card);
  const [memo, setMemo] = useState<string>();

  const authStore = useAuthStore();

  const productId = product?._id;
  const isShippingProduct = product?.shippingProduct;
  const productShippingFee = product?.shippingFee || 0;
  const productImageUrl = product?.thumbnailImage;
  const productName = product?.title;
  const isMemoRequired = product?.memoRequired;
  const requestMemo = product?.memoComment;
  const optionNameList: string[] = [];
  // const optionNameList = product?.optionNameList || [];

  const userId = authStore.user?.id;
  const userName = authStore.user?.profile?.name;
  const userPhone = authStore.user?.profile?.phone;
  const userAddress = authStore.user?.profile?.address?.address;
  const userAddressDetail = authStore.user?.profile?.address?.address2;
  const standardAddressData = authStore.user?.profile?.address;
  const userPoint = authStore.user?.profile?.creditPoints || 0;
  const billingAmount = totalFee - usedCreditPoint + productShippingFee;

  const onClickPayment = () => {
    if (isMemoRequired && !memo) {
      toast.error('필수 입력 정보를 확인해주세요.', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      return;
    }
    const paymentStatement = {
      uuid: getUniqueKeyFromUUID(),
      seller: { id: product?.sellerId || '', name: product?.sellerName },
      buyer: {
        id: userId!,
        name: userName!,
        phone: userPhone!,
      },
      product: {
        saleType: 'commerce',
        id: productId!,
        name: productName!,
      },
      purchases: selectedOptions.map((item) => {
        let fee = item.optionFee;
        if (product?.optionType === 'ticket' || product?.optionType === 'combine') {
          fee = item.optionFee + product?.fee;
        }

        return { name: item.optionName, fee: fee, count: item.optionCount };
      }),
      payment: {
        method: paymentMethod.type,
        gateway: paymentMethod.paymentGateway,
        amount: totalFee + productShippingFee,
        point: usedCreditPoint,
        billingAmount: billingAmount,
      },
      memo: {
        shipping: shippingData.memo,
        payment: memo,
      },
    };

    alert(JSON.stringify(paymentStatement));
  };

  if (payment.status === 'in_progress') {
    return <PaymentInProgress />;
  }

  return (
    <OverlayScrollbarsComponent
      defer
      options={{
        scrollbars: { autoHide: 'scroll' },
        overflow: {
          x: 'hidden',
        },
      }}
      style={{
        backgroundColor: '#ffffff',
        marginTop: 0,
        position: 'relative',
        height: '100%',
        overflow: 'auto',
        width: '100%',
      }}>
      <ProductInfo imageUrl={productImageUrl as string} productName={productName} optionNameList={optionNameList} />
      <Divider />
      <CustomerInfo
        userName={userName}
        userPhone={userPhone}
        isShippingProduct={isShippingProduct}
        isMemoRequired={isMemoRequired}
        requestMemo={requestMemo}
        onChangeMemo={(value) => {
          setMemo(value);
        }}
      />
      <Divider />
      {isShippingProduct && (
        <ShippingInfo
          userName={userName}
          userPhone={userPhone}
          userAddress={userAddress}
          userAddressDetail={userAddressDetail}
          standardAddressData={standardAddressData}
          isMemoRequired={isMemoRequired}
          requestMemo={requestMemo}
          onChangeShippingInfo={(data) => {
            setShippingData(data);
          }}
        />
      )}
      {isShippingProduct && <Divider />}
      <PointUse
        userPoint={userPoint}
        totalFee={totalFee}
        productShippingFee={productShippingFee}
        onChangeApplyPoint={(value) => {
          setUsedCreditPoint(value);
        }}
      />
      <Divider />
      <PaymentAmount
        totalFee={totalFee}
        usedCreditPoint={usedCreditPoint}
        productShippingFee={productShippingFee}
        isShippingProduct={isShippingProduct}
      />
      <Divider />
      <SelectPaymentMethod
        onChange={(value) => {
          setPaymentMethod(value);
        }}
      />
      <div
        style={{
          display: 'flex',
          height: '80px',
          backgroundColor: '#eff1f4',
          paddingLeft: '16px',
          paddingRight: '16px',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <div
          style={{
            fontSize: '14px',
            lineHeight: '17px',
            color: '#808387',
            paddingBottom: '18px',
          }}>
          이 상품의 배송안내, 환불규정을 모두 확인하였습니다.
        </div>
      </div>
      <button
        className="link-button"
        style={{ height: '60px', width: '100%' }}
        onClick={() => {
          onClickPayment();
        }}>
        <div
          style={{
            display: 'flex',
            height: '60px',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#8358ff',
          }}>
          <div
            style={{
              fontSize: '18px',
              lineHeight: '27px',
              color: '#ffffff',
              fontWeight: 'bold',
            }}>
            결제하기
          </div>
        </div>
      </button>
    </OverlayScrollbarsComponent>
  );
};

export default CommercePayment;
