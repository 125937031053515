import React from 'react';
import { MainFeedCellProps } from '.';
import { Carousel } from '@components/common/Carousel';
import { cdnImageUrl } from '@utils/image';
import { actionStringFromItem } from '@utils/string';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';

export const ShortcutCell: React.FC<MainFeedCellProps> = (props) => {
  const data = props.data || [];
  const dialog = useOkCancelDialog();

  return (
    <div id="shortcut-cell" className="embla">
      <Carousel
        options={{
          dragFree: true,
          align: 'start',
        }}>
        {data.map((item: any, index: number) => {
          return (
            <div key={`short-cut-${index}`} className={'embla__slide'} style={{ flex: '0 0 110px', padding: '0 16px' }}>
              <button
                className="link-button"
                style={{
                  width: '100px',
                  height: '80px',
                  backgroundColor: '#f5f7fb',
                  position: 'relative',
                  borderRadius: '8px',
                  marginRight: '10px',
                }}
                onClick={() => {
                  dialog.open({
                    type: 'ok',
                    confirmColor: '#FF3D8F',
                    title: '이동 정보',
                    content: actionStringFromItem(item),
                  });
                }}>
                {item.mainCopy && item.mainCopy != '' && (
                  <div
                    style={{
                      textAlign: 'left',
                      fontSize: '13px',
                      lineHeight: '16px',
                      fontWeight: 'bold',
                      color: '#242424',
                      position: 'absolute',
                      top: '10px',
                      left: '12px',
                      whiteSpace: 'pre-wrap',
                    }}>
                    {item.mainCopy}
                  </div>
                )}
                {item.imageUrl && item.imageUrl != '' && (
                  <img
                    style={{
                      width: '32px',
                      height: '32px',
                      position: 'absolute',
                      bottom: 8,
                      right: 8,
                    }}
                    src={cdnImageUrl(item.imageUrl)}
                  />
                )}
              </button>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default ShortcutCell;
