import { PaymentStatementWithLogs } from '@models/paymentStatement';
import { create } from 'zustand';

export interface PaymentStatementModalStore {
  show?: boolean;
  data?: PaymentStatementWithLogs;
  close: () => boolean;
  open: (data?: PaymentStatementWithLogs) => void;
}

export const usePaymentStatementModalStore = create<PaymentStatementModalStore>((set, get) => ({
  show: false,
  close: () => {
    const isCloseExecute = !!get().show;
    set({ show: false });
    return isCloseExecute;
  },
  open: (data?: PaymentStatementWithLogs) => {
    set({ data: data, show: true });
  },
}));
