import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import * as userApi from '@apis/admin-user';
import * as api from '@apis/product';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { DaumPostcodeModal } from '@components/class-invoice/DaumPostcodeModal';
import FileUploadButton from '@components/common/FileUploadButton';
import { TextArea, TextInput } from '@components/common/TextInput';
import { ClassDetailEdit } from '@components/product/ClassDetailEdit';
import DistrictSelect from '@components/product/DistrictSelect/DistrictSelect';
import ProductDetailView from '@components/product/ProductDetailView';
import { ProductOptionEditView } from '@components/product/ProductOptionEditView';
import { SelectUser } from '@components/product/SelectUser';
import { PRODUCT_CATEGORIES, PRODUCT_CATEGORIES_KEYS } from '@consts/product';
import { Roles } from '@consts/role';
import { closestCenter, DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, horizontalListSortingStrategy, SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useAppSocket } from '@hooks/appSocket';
import { UserProfile } from '@models/auth';
import { ClassBadge } from '@models/class';
import {
  Concept,
  ProductApproveStatus,
  ProductModel,
  ProductStatus,
  PRODUCT_ACTIVITY_TYPE_TEXT,
  QuestionOption,
  ProductActivityType,
} from '@models/product';
import {
  NaverCommerceProduct,
  NaverCommerceProductDisplayStatusLabel,
  NaverCommerceProductStatusLabel,
} from '@models/sales-channels';
import { useAuthStore } from '@stores/authStore';
import { useContentLoaderStore } from '@stores/contentLoaderStore';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { useMutation, useQuery } from '@tanstack/react-query';
import { CKEditorIgogoImageUploader } from '@utils/ckeditorImageUpload';
import { removeTempProperties } from '@utils/dataTransform';
import { cdnImageUrl, resizeImage } from '@utils/image';
import { formatDateFromStrings } from '@utils/payment';
import { extractFileName } from '@utils/string';
import { Button, Checkbox, DatePicker, Modal, Select, Switch, Tooltip } from 'antd';
import { RcFile } from 'antd/es/upload';
import * as cheerio from 'cheerio';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

interface SortableDetailImageProps {
  onDeleteDetailImage?: (event: any) => void;
  value?: any;
  key: any;
  id: any;
  imageUrl: string;
}

export const SortableDetailImage: React.FC<SortableDetailImageProps> = (props) => {
  const [hover, setHover] = useState<boolean>();
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const { onDeleteDetailImage, imageUrl } = props;
  const onClickRemove = (event: any) => {
    event.preventDefault();
    onDeleteDetailImage && onDeleteDetailImage(event);
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          width: '184px',
        }}>
        <div style={{ width: '184px', height: '184px', position: 'relative', borderRadius: '8px', overflow: 'hidden' }}>
          <img src={cdnImageUrl(imageUrl)} style={{ width: '184px', height: '184px', objectFit: 'cover' }}></img>
          {hover && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
                backgroundColor: 'rgba(0,0,0,0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <button className="link-button" style={{ width: '48px', height: '48px' }} onClick={onClickRemove}>
                <div style={{ color: 'white' }}>
                  <DeleteOutlined style={{ color: 'white' }} size={48} />
                  <div>삭제하기</div>
                </div>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

interface ProductEditViewProps {
  productId: string;
  onClose?: () => void;
  needRefetch?: () => void;
}

interface ProductEditModel extends ProductModel {
  classIntroductionImage?: string;
  thumbnailImageTemp?: string;
  detailImagesTemp?: string[];
}

const BADGES = {
  이벤트: {
    type: 'blue',
    isActive: true,
    value: '이벤트',
  },
  업데이트: {
    type: 'green',
    isActive: true,
    value: '업데이트',
  },
  할인특가: {
    type: 'red',
    isActive: true,
    value: '할인특가',
  },
  얼리버드: {
    type: 'red',
    isActive: true,
    value: '얼리버드',
  },
};

export const ProductEditView: React.FC<ProductEditViewProps> = (props) => {
  const loader = useContentLoaderStore();
  const appSocket = useAppSocket();
  const authStore = useAuthStore();
  const [files, setFiles] = useState<{ [key: string]: File }>({});
  const [modifiedProduct, setModifiedProduct] = useState<ProductEditModel>({} as ProductEditModel);
  const [naverStoreProduct, setNaverStoreProduct] = useState<NaverCommerceProduct>();
  const [changedData, setChangedData] = useState<any>({});
  const [showOptionEdit, setShowOptionEdit] = useState<boolean>(false);
  const dialog = useOkCancelDialog();
  const [showPostcodeModal, setShowPostcodeModal] = useState<boolean>(false);
  const [changeCustomerCenter, setChangeCustomerCenter] = useState<Partial<UserProfile>>({});
  const [loadingEditorImageUpload, setLoadingEditorImageUpload] = useState<boolean>(false);
  const editorTimerRef = useRef<any>();
  const optionsDictRef = useRef<{ [key: string]: number }>({});

  const getTempProductOptionsQuery = useQuery({
    queryKey: [`temp-product-options-${props.productId}`],
    queryFn: () => api.getTempProductOptions(props.productId),
    enabled: false,
  });

  const partnerUserQuery = useQuery({
    queryKey: [`user-${modifiedProduct.sellerId}`],
    queryFn: () => userApi.getUser(modifiedProduct.sellerId),
    enabled: !!modifiedProduct.sellerId,
  });

  const partnerData = partnerUserQuery.data?.data;

  const mutaionUpdatePartnerProfile = useMutation({
    mutationFn: userApi.updateUserProfile,
    onSuccess: async () => {
      dialog.open({
        content: '대표고객센터 정보가 변경되었습니다.',
        type: 'ok',
      });
    },
  });

  const tempProductOptions = getTempProductOptionsQuery.data?.data;

  useEffect(() => {
    appSocket.socket.addSocketEventListener('upload_excel_status', 'upload_excel_status_listner_in_edit', (data) => {
      if (data.type === 'product_options') {
        if (data.status.toLowerCase() === 'done') {
          getTempProductOptionsQuery.refetch();
        }

        if (data.status) {
          if (data.status === 'error_duplicate_step') {
            dialog.open({
              type: 'ok',
              title: '중복된 옵션이 있습니다.',
              content: `${data.message}`,
            });
          } else {
            toast.error(data.message, {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              pauseOnFocusLoss: false,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });
          }
          loader.off();
        }
      }
    });
  }, []);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 10 },
    }),
  );

  const productItemQuery = useQuery({
    queryKey: [`product-item-${props.productId}`],
    queryFn: () => api.getDraftProduct(props.productId!),
    enabled: !!props.productId,
  });

  const mutationUpdateProduct = useMutation({
    mutationFn: api.updateProduct,
    onSuccess: async (res) => {
      setModifiedProduct(res.data);
      props.needRefetch && props.needRefetch();
      toast.dark('저장되었습니다', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: false,
        draggable: true,
        progress: undefined,
        bodyStyle: {
          color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
          textAlign: 'center',
          fontFamily: 'Pretendard',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '20px',
        },
        theme: 'dark',
      });
      productItemQuery.refetch();
    },
  });

  const mutationDeleteDraftProduct = useMutation({
    mutationFn: api.deleteDraftProduct,
    onSuccess: () => {
      toast.dark('임시데이터가 삭제되었습니다', { bodyStyle: { textAlign: 'center' } });
      props.needRefetch && props.needRefetch();
      props.onClose && props.onClose();
    },
  });

  const onChangeProperty = (key: string, value: any) => {
    setChangedData((prev: any) => {
      const newProperties = { ...prev };
      const isKeyDepth = key.split('.').length > 1 ? true : false;
      if (isKeyDepth) {
        const keys = key.split('.');
        if (keys.length === 2) {
          if (!newProperties[keys[0]]) {
            if (!isNaN(Number(keys[1]))) {
              newProperties[keys[0]] = modifiedProduct[keys[0] as 'refundPolicies'] || [];
            } else {
              const isArray = Array.isArray(modifiedProduct[keys[0] as 'refundPolicies']);
              newProperties[keys[0]] = isArray ? {} : modifiedProduct[keys[0] as 'refundPolicies'] || {};
            }
          }
          newProperties[keys[0]][keys[1]] = value;
        } else if (keys.length === 3) {
          if (!newProperties[keys[0]]) {
            if (!isNaN(Number(keys[1]))) {
              newProperties[keys[0]] = modifiedProduct[keys[0] as 'refundPolicies'] || [];
            } else {
              newProperties[keys[0]] = modifiedProduct[keys[0] as 'refundPolicies'] || {};
            }
          }
          if (!newProperties[keys[0]]) {
            newProperties[keys[0]] = {};
          }
          if (!newProperties[keys[0]][keys[1]]) {
            newProperties[keys[0]][keys[1]] = {};
          }
          newProperties[keys[0]][keys[1]][keys[2]] = value;
        }
      } else {
        newProperties[key] = value;
      }

      return newProperties;
    });
  };

  const onChangeBadgeChecked = (key: '이벤트' | '업데이트' | '할인특가' | '얼리버드', checked: boolean) => {
    let badges = (changedData?.badges || modifiedProduct.badges || []).filter((badge: ClassBadge) => badge.isActive);

    if (checked) {
      badges.push(BADGES[key]);
    } else {
      badges = badges.filter((badge: ClassBadge) => badge.value !== key);
    }
    onChangeProperty('badges', badges);
  };

  useEffect(() => {
    if (tempProductOptions) {
      onChangeProperty('productOptions', tempProductOptions.productOptions);
      onChangeProperty('optionLabels', tempProductOptions.optionLabels);
    }
  }, [tempProductOptions]);

  const onChangeClassDetailImage = async (files: FileList) => {
    const { fileName } = extractFileName(files[0].name);
    const newFiles = await Promise.all(
      Array.from(files)
        .slice(0, 10)
        .map(async (file) => {
          const result = await resizeImage(file, { maxWidth: 856 });

          return result;
        }),
    );
    const contentTypes = newFiles[0].type.split('/');
    const uploadFileName = `${fileName}.${contentTypes[1]}`;
    const newImageFile = new File([newFiles[0].image], `${fileName}.${contentTypes[1]}`) as RcFile;
    const tempUrl = URL.createObjectURL(newFiles[0].image);
    onChangeProperty('classIntroductionImageTemp', tempUrl);
    onChangeProperty('classIntroductionImage', uploadFileName);
    setFiles((value) => {
      const newValue = { ...value };
      newValue['classIntroductionImage'] = newImageFile;
      return newValue;
    });
  };

  const onChangeBottomTextBannerIconFile = async (files: FileList) => {
    const { fileName } = extractFileName(files[0].name);
    const newFiles = await Promise.all(
      Array.from(files)
        .slice(0, 10)
        .map(async (file) => {
          const result = await resizeImage(file, { maxWidth: 372 });

          return result;
        }),
    );
    const contentTypes = newFiles[0].type.split('/');
    const uploadFileName = `${fileName}.${contentTypes[1]}`;
    const newImageFile = new File([newFiles[0].image], `${fileName}.${contentTypes[1]}`) as RcFile;
    const tempUrl = URL.createObjectURL(newFiles[0].image);
    onChangeProperty('bottomTextBanner.iconTemp', tempUrl);
    onChangeProperty('bottomTextBanner.icon', uploadFileName);
    setFiles((value) => {
      const newValue = { ...value };
      newValue['bottomTextBanner.icon'] = newImageFile;
      return newValue;
    });
  };

  const onChangeThumbnailFile = async (files: FileList) => {
    const { fileName } = extractFileName(files[0].name);
    const newFiles = await Promise.all(
      Array.from(files)
        .slice(0, 10)
        .map(async (file) => {
          const result = await resizeImage(file, { maxWidth: 372 });

          return result;
        }),
    );
    const contentTypes = newFiles[0].type.split('/');
    const uploadFileName = `${fileName}.thumb.${contentTypes[1]}`;
    const newThumbnailFile = new File([newFiles[0].image], `${fileName}.thumb.${contentTypes[1]}`) as RcFile;
    const tempUrl = URL.createObjectURL(newFiles[0].image);
    onChangeProperty('thumbnailImageTemp', tempUrl);
    onChangeProperty('thumbnailImage', uploadFileName);
    setFiles((value) => {
      const newValue = { ...value };
      newValue['thumbnailImage'] = newThumbnailFile;
      return newValue;
    });
  };

  const onChangeDetailImageFiles = async (files: FileList) => {
    const imageFiles: any = {};
    const newFiles = await Promise.all(
      Array.from(files).map(async (file) => {
        const result = await resizeImage(file, { maxWidth: 856 });
        const { fileName } = extractFileName(file.name);
        const contentTypes = result.type.split('/');
        const imageFile = new File([result.image], `${fileName}.${contentTypes[1]}`) as RcFile;
        const tempUrl = URL.createObjectURL(result.image);

        imageFiles[file.name] = imageFile;

        return { result, fileName: `${fileName}.${contentTypes[1]}`, imageFile, tempUrl };
      }),
    );

    setFiles((value) => {
      const newValue = { ...value, ...imageFiles };
      return newValue;
    });

    const tempUrls: string[] = [];
    const fileNames: string[] = [];

    newFiles.forEach((newFile) => {
      tempUrls.push(newFile.tempUrl);
      fileNames.push(newFile.fileName);
    });

    onChangeProperty('detailImagesTemp', [
      ...(modifiedProduct.detailImagesTemp || modifiedProduct.detailImages),
      ...tempUrls,
    ]);
    onChangeProperty('detailImages', [...modifiedProduct.detailImages, ...fileNames]);
  };

  const onChangeDetailBodyImageFiles = async (files: FileList) => {
    try {
      const imageFiles: any = {};
      const newFiles = await Promise.all(
        Array.from(files).map(async (file) => {
          const result = await resizeImage(file, { maxWidth: 856 });
          const { fileName } = extractFileName(file.name);
          const contentTypes = result.type.split('/');
          const imageFile = new File([result.image], `${fileName}.${contentTypes[1]}`) as RcFile;
          const tempUrl = URL.createObjectURL(result.image);

          imageFiles[file.name] = imageFile;

          return { result, fileName: `${fileName}.${contentTypes[1]}`, imageFile, tempUrl };
        }),
      );

      setFiles((value) => {
        const newValue = { ...value, ...imageFiles };
        return newValue;
      });

      const tempUrls: string[] = [];
      const fileNames: string[] = [];

      newFiles.forEach((newFile) => {
        tempUrls.push(newFile.tempUrl);
        fileNames.push(newFile.fileName);
      });

      onChangeProperty('detailBodyImagesTemp', [
        ...(modifiedProduct.detailBodyImagesTemp || modifiedProduct.detailBodyImages || []),
        ...tempUrls,
      ]);
      onChangeProperty('detailBodyImages', [...(modifiedProduct.detailBodyImages || []), ...fileNames]);
    } catch (e: any) {
      if (e.code === 'TOO_LARGE_IMAGE') {
        const type = e.property === 'width' ? '넓이' : '높이';

        dialog.open({
          title: '이미지 크기 오류',
          content: `이미지가 너무 큽니다. 이미지를 나누어서 업로드해주세요.\n[${type}]\n현재 크기: ${e.originSize}px\n기준 크기: ${e.expectedSize}px`,
          type: 'ok',
        });
      } else {
        dialog.open({
          title: '알수없는 오류',
          content: `새로고침 후 다시 시도해주시거나 그래도 안되시면 개발팀에 문의해주세요.`,
          type: 'ok',
        });
      }
    }
  };

  useEffect(() => {
    if (productItemQuery.data?.data) {
      const product = {
        ...productItemQuery.data?.data,
        productOptions: (productItemQuery.data?.data.productOptions || []).sort((lv, rv) => {
          return (lv.index || 0) - (rv.index || 0);
        }),
      };

      setModifiedProduct(removeTempProperties(product));
      if (product.naverStoreProduct) {
        setNaverStoreProduct(product.naverStoreProduct);

        (product.productOptions || []).forEach((option) => {
          const key = formatDateFromStrings(option.step);
          if (key) {
            optionsDictRef.current[key] = option.inventory || 0;
          }
        });
      }
    }
  }, [productItemQuery.data?.data]);

  useEffect(() => {
    setModifiedProduct((value) => {
      return { ...value, ...changedData };
    });
  }, [changedData]);

  const onDeleteDetailImage = (index: number) => {
    const newDetailImages = [...modifiedProduct.detailImages];
    const newDetailImagesTemp = [...(modifiedProduct.detailImagesTemp || modifiedProduct.detailImages)];

    newDetailImages.splice(index, 1);
    newDetailImagesTemp.splice(index, 1);
    onChangeProperty('detailImagesTemp', newDetailImagesTemp);
    onChangeProperty('detailImages', newDetailImages);
  };

  const onDetailImagesSortEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const oldIndex = (modifiedProduct.detailImagesTemp || modifiedProduct.detailImages).findIndex(
        (item) => item === active.id,
      );
      const newIndex = (modifiedProduct.detailImagesTemp || modifiedProduct.detailImages).findIndex(
        (item) => item === over?.id,
      );
      const newDetailImages = arrayMove(modifiedProduct.detailImages, oldIndex, newIndex);
      const newDetailImagesTemp = arrayMove(
        modifiedProduct.detailImagesTemp || modifiedProduct.detailImages,
        oldIndex,
        newIndex,
      );

      onChangeProperty('detailImages', newDetailImages);
      onChangeProperty('detailImagesTemp', newDetailImagesTemp);
    }
  };

  const onDeleteDetailBodyImage = (index: number) => {
    const newDetailImages = [...(modifiedProduct.detailBodyImages || [])];
    const newDetailImagesTemp = [...(modifiedProduct.detailBodyImagesTemp || modifiedProduct.detailBodyImages || [])];

    newDetailImages.splice(index, 1);
    newDetailImagesTemp.splice(index, 1);
    onChangeProperty('detailBodyImagesTemp', newDetailImagesTemp);
    onChangeProperty('detailBodyImages', newDetailImages);
  };

  const onDetailBodyImagesSortEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const oldIndex = (modifiedProduct.detailBodyImagesTemp || modifiedProduct.detailBodyImages || []).findIndex(
        (item) => item === active.id,
      );
      const newIndex = (modifiedProduct.detailBodyImagesTemp || modifiedProduct.detailBodyImages || []).findIndex(
        (item) => item === over?.id,
      );
      const newDetailImages = arrayMove(modifiedProduct.detailBodyImages || [], oldIndex, newIndex);
      const newDetailImagesTemp = arrayMove(
        modifiedProduct.detailBodyImagesTemp || modifiedProduct.detailBodyImages || [],
        oldIndex,
        newIndex,
      );

      onChangeProperty('detailBodyImages', newDetailImages);
      onChangeProperty('detailBodyImagesTemp', newDetailImagesTemp);
    }
  };

  const refundPolicyText =
    changedData?.refundPolicyText !== undefined
      ? changedData?.refundPolicyText
      : modifiedProduct?.refundPolicyText ||
        changedData?.classTermsText ||
        modifiedProduct?.classTermsText ||
        (changedData?.refundPolicies || modifiedProduct.refundPolicies || []).join('\n');

  const discountInfo = changedData?.discountInfo || modifiedProduct.discountInfo;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100dvh',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'white',
        zIndex: 900,
        borderLeft: '1px solid #eaeaea',
      }}>
      <div style={{ display: 'flex', height: '100%' }}>
        <DaumPostcodeModal
          open={showPostcodeModal}
          onCancel={() => {
            setShowPostcodeModal(false);
          }}
          onComplete={(address) => {
            setShowPostcodeModal(false);
            onChangeProperty('location.specificAddress', address);
          }}
        />
        <Modal
          width={'calc(100dvw - 400px)'}
          open={showOptionEdit}
          footer={
            <div>
              <Button
                onClick={() => {
                  const formData: FormData = new FormData();
                  const json = removeTempProperties(changedData);
                  formData.append('productId', props.productId);
                  formData.append('json', JSON.stringify(json));

                  Object.values(files).forEach((file) => {
                    formData.append('files', file);
                  });
                  mutationUpdateProduct.mutate(formData);
                }}>
                저장
              </Button>
              <Button
                onClick={() => {
                  setShowOptionEdit(false);
                }}>
                닫기
              </Button>
            </div>
          }
          onCancel={() => {
            setShowOptionEdit(false);
          }}
          onOk={() => {
            setShowOptionEdit(false);
          }}>
          <ProductOptionEditView
            productOptions={changedData?.productOptions || modifiedProduct.productOptions}
            optionLabels={changedData?.optionLabels || modifiedProduct.optionLabels}
            purchaseQuantityOptions={changedData?.purchaseQuantityOptions || modifiedProduct.purchaseQuantityOptions}
            onChangeProductOptions={(value) => {
              onChangeProperty('productOptions', value);
            }}
            onChangeOptionLabels={(value) => {
              onChangeProperty('optionLabels', value);
            }}
            onChangePurchaseQuantityOptions={(value) => {
              onChangeProperty('purchaseQuantityOptions', value);
            }}
          />
        </Modal>
        <div style={{ flex: 1 }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '56px',
              borderBottom: '1px solid #eff1f4',
              fontSize: '17px',
              fontWeight: 'bold',
              color: '#242424',
              paddingLeft: '24px',
            }}>
            <div>
              <span style={{ color: 'red' }}>{productItemQuery.data?.data.isTemp ? '[임시데이터]' : ''}</span>{' '}
              {`${modifiedProduct.title || '이름 없음'} 상품 편집`}
            </div>
            {modifiedProduct.saleType === 'commerce' && (
              <div
                style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', gap: '8px', marginRight: '8px' }}>
                <Button
                  onClick={async () => {
                    setShowOptionEdit((value) => !value);
                  }}>
                  옵션 수정
                </Button>
                <FileUploadButton
                  className="ant-btn ant-btn-default"
                  style={{
                    fontSize: '14px',
                    height: '32px',
                    padding: '4px 15px',
                    borderRadius: '6px',
                    backgroundColor: '#ffffff',
                    border: '1px solid #d9d9d9',
                    color: 'rgba(0, 0, 0, 0.88)',
                    boxShadow: '0 2px 0 rgba(0, 0, 0, 0.02)',
                    cursor: 'pointer',
                  }}
                  onChange={(e) => {
                    if (e.target.files && authStore.user?.role === Roles.ADMIN) {
                      const files = e.target.files;
                      loader.on();
                      appSocket.uploadExcelFile({
                        productId: props.productId,
                        sender: {
                          userId: authStore.user?.id,
                          role: authStore.user?.role,
                        },
                        data: { type: 'product_options', file: files[0] },
                      });
                    }
                  }}>
                  {'옵션 엑셀 파일 업로드'}
                </FileUploadButton>
                <Button
                  onClick={async () => {
                    const fileName = `igogo-product-options-${moment().format('YYYYMMDD-HHmmss')}-${
                      props.productId
                    }.xlsx`;
                    const blob = await api.getProductOptionsExcelFile(props.productId);
                    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
                      // IE variant
                      (window.navigator as any).msSaveOrOpenBlob(new Blob(blob.data), fileName);
                    } else {
                      const url = window.URL.createObjectURL(blob.data);
                      const link = document.createElement('a');
                      link.href = url;
                      link.setAttribute('download', fileName);
                      document.body.appendChild(link);
                      link.click();
                      link.parentNode && link.parentNode.removeChild(link);
                    }
                  }}>
                  옵션 엑셀 파일 다운로드
                </Button>
              </div>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '24px',
              padding: '12px',
              height: 'calc(100% - 100px)',
              overflow: 'auto',
            }}>
            <div style={{ borderRadius: '12px', border: '1px solid #eaeaea', padding: '16px' }}>
              <div
                style={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                  borderBottom: '1px solid #eaeaea',
                  height: '32px',
                }}>
                승인 노출 상태
              </div>
              <div style={{ marginTop: '12px', marginLeft: '12px' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <table>
                    <thead>
                      <tr>
                        <th style={{ width: '140px' }}>승인상태 변경</th>
                        <th style={{ width: '100px' }}>노출설정</th>
                        <th style={{ width: '100px' }}>판매여부</th>
                        <th style={{ width: '100px' }}>
                          프라이빗 여부
                          <Tooltip title="url을 통해서만 구매 가능">
                            <QuestionCircleOutlined style={{ marginLeft: '4px' }} />
                          </Tooltip>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ textAlign: 'center' }}>
                          <Select
                            value={modifiedProduct.approveStatus}
                            style={{ width: 120 }}
                            onChange={(value) => {
                              onChangeProperty('approveStatus', value);
                            }}
                            options={[
                              { value: ProductApproveStatus.ACCEPTED, label: '승인' },
                              { value: ProductApproveStatus.REQUESTED, label: '승인 요청' },
                              { value: ProductApproveStatus.DENIED, label: '승인 반려' },
                              { value: ProductApproveStatus.NOT_READY, label: '수정중' },
                            ]}
                          />
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <Switch
                            checked={modifiedProduct.display}
                            onChange={(value) => {
                              onChangeProperty('display', value);
                            }}
                          />
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <Switch
                            checked={
                              modifiedProduct.status === ProductStatus.OPEN ||
                              modifiedProduct.status === ProductStatus.PRIVATE
                            }
                            onChange={(value) => {
                              onChangeProperty('status', value ? ProductStatus.OPEN : ProductStatus.CLOSE);
                            }}
                          />
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <Switch
                            checked={modifiedProduct.status === ProductStatus.PRIVATE}
                            onChange={(value) => {
                              onChangeProperty('status', value ? ProductStatus.PRIVATE : ProductStatus.CLOSE);
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div style={{ marginTop: '12px' }}>
                  {modifiedProduct.approveStatus === ProductApproveStatus.DENIED && (
                    <div style={{ marginTop: '24px' }}>
                      <div style={{ fontSize: '14px', fontWeight: 'bold' }}>승인 반려 사유</div>
                      <TextInput
                        style={{ maxWidth: '428px' }}
                        value={
                          changedData?.deniedReason !== undefined
                            ? changedData?.deniedReason
                            : modifiedProduct.deniedReason
                        }
                        onChangeValue={(value) => {
                          onChangeProperty('deniedReason', value);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div style={{ borderRadius: '12px', border: '1px solid #eaeaea', padding: '16px' }}>
              <div
                style={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                  borderBottom: '1px solid #eaeaea',
                  height: '32px',
                }}>
                기본 정보
              </div>
              <div style={{ marginTop: '12px', marginLeft: '12px' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <table>
                    <thead>
                      <tr>
                        <th style={{ width: '120px' }}>판매타입 변경</th>
                        <th style={{ width: '100px' }}>그룹할인</th>
                        <th style={{ width: '120px' }}>
                          검색 우선 순위
                          <Tooltip title="높은 숫자가 우선순위 높음">
                            <QuestionCircleOutlined style={{ marginLeft: '4px' }} />
                          </Tooltip>
                        </th>
                        <th style={{ width: '100px' }}>앱 전용상품</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ textAlign: 'center' }}>
                          <Select
                            value={modifiedProduct.saleType}
                            style={{ width: 120 }}
                            onChange={(value) => {
                              onChangeProperty('saleType', value);
                            }}
                            options={[
                              { value: 'commerce', label: '커머스' },
                              { value: 'class', label: '클래스' },
                            ]}
                          />
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <Switch
                            checked={modifiedProduct.applyGroupDiscount}
                            onChange={(value) => {
                              onChangeProperty('applyGroupDiscount', value);
                            }}
                          />
                        </td>
                        <td>
                          <TextInput
                            style={{ maxWidth: '428px' }}
                            value={
                              changedData?.priority !== undefined ? changedData?.priority : modifiedProduct.priority
                            }
                            onChangeValue={(value) => {
                              onChangeProperty('priority', parseInt(value) || 0);
                            }}
                            onChange={(e) => {
                              onChangeProperty('priority', parseInt(e.target.value) || 0);
                            }}></TextInput>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <Switch
                            checked={modifiedProduct?.appOnly}
                            onChange={(value) => {
                              onChangeProperty('appOnly', value);
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div style={{ marginTop: '12px' }}>
                  <table>
                    <thead>
                      <tr>
                        <th style={{ width: '120px' }}>수업 방식</th>
                        <th style={{ width: '120px' }}>수업 시간</th>
                        <th style={{ width: '120px' }}>수강 연령 최소</th>
                        <th style={{ width: '120px' }}>수강 연령 최대</th>
                        <th style={{ width: '120px' }}>수업 인원 최소(-1: 제한없음)</th>
                        <th style={{ width: '120px' }}>수업 인원 최대(-1: 제한없음)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <Select
                            value={changedData?.activityType || modifiedProduct.activityType}
                            style={{ width: 120 }}
                            onChange={(value) => {
                              onChangeProperty('activityType', value);
                            }}
                            options={Object.keys(PRODUCT_ACTIVITY_TYPE_TEXT).map((key) => {
                              const item = PRODUCT_ACTIVITY_TYPE_TEXT[key as ProductActivityType];
                              return { value: key, label: item.title };
                            })}
                          />
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <TextInput
                            style={{ width: '60px', textAlign: 'right' }}
                            value={
                              changedData?.classDuration !== undefined
                                ? changedData?.classDuration
                                : modifiedProduct.classDuration
                            }
                            onChangeValue={(value) => {
                              onChangeProperty('classDuration', parseInt(value) || 0);
                            }}
                            onChange={(e) => {
                              onChangeProperty('classDuration', parseInt(e.target.value) || 0);
                            }}></TextInput>
                          <span>분</span>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <span>만</span>
                          <TextInput
                            style={{ width: '60px', textAlign: 'right' }}
                            value={changedData?.ageMin !== undefined ? changedData?.ageMin : modifiedProduct.ageMin}
                            onChangeValue={(value) => {
                              onChangeProperty('ageMin', parseInt(value) || 0);
                            }}
                            onChange={(e) => {
                              onChangeProperty('ageMin', parseInt(e.target.value) || 0);
                            }}></TextInput>
                          <span>세</span>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <span>만</span>
                          <TextInput
                            style={{ width: '60px', textAlign: 'right' }}
                            value={changedData?.ageMax !== undefined ? changedData?.ageMax : modifiedProduct.ageMax}
                            onChangeValue={(value) => {
                              onChangeProperty('ageMax', parseInt(value) || 0);
                            }}
                            onChange={(e) => {
                              onChangeProperty('ageMax', parseInt(e.target.value) || 0);
                            }}></TextInput>
                          <span>세</span>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <TextInput
                            style={{ width: '60px', textAlign: 'right' }}
                            value={
                              changedData?.studentsMin !== undefined
                                ? changedData?.studentsMin
                                : modifiedProduct.studentsMin
                            }
                            onChangeValue={(value) => {
                              onChangeProperty('studentsMin', Number(value) || 0);
                            }}
                            onChange={(e) => {
                              onChangeProperty('studentsMin', Number(e.target.value) || 0);
                            }}></TextInput>
                          <span>명</span>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <TextInput
                            style={{ width: '60px', textAlign: 'right' }}
                            value={
                              changedData?.studentsMax !== undefined
                                ? changedData?.studentsMax
                                : modifiedProduct.studentsMax
                            }
                            onChangeValue={(value) => {
                              onChangeProperty('studentsMax', Number(value) || 0);
                            }}
                            onChange={(e) => {
                              onChangeProperty('studentsMax', Number(e.target.value) || 0);
                            }}></TextInput>
                          <span>명</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div style={{ marginTop: '24px' }}>
                    <div style={{ fontSize: '14px', fontWeight: 'bold' }}>판매자 코멘트</div>
                    <TextInput
                      style={{ maxWidth: '428px' }}
                      value={
                        changedData?.sellerShortComment !== undefined
                          ? changedData?.sellerShortComment
                          : modifiedProduct.sellerShortComment
                      }
                      onChangeValue={(value) => {
                        onChangeProperty('sellerShortComment', value);
                      }}
                      onChange={(e) => {
                        onChangeProperty('sellerShortComment', e.target.value);
                      }}></TextInput>
                  </div>
                  <div style={{ marginTop: '24px' }}>
                    <div style={{ fontSize: '14px', fontWeight: 'bold' }}>구매안내 멘트</div>
                    <TextArea
                      rows={4}
                      style={{ maxWidth: '428px' }}
                      value={
                        changedData?.sellerComment !== undefined
                          ? changedData?.sellerComment
                          : modifiedProduct.sellerComment
                      }
                      onChangeValue={(value) => {
                        onChangeProperty('sellerComment', value);
                      }}
                      onChange={(e) => {
                        onChangeProperty('sellerComment', e.target.value);
                      }}></TextArea>
                  </div>
                  {(changedData?.activityType || modifiedProduct.activityType) === ProductActivityType.HOME && (
                    <div style={{ width: '540px' }}>
                      <DistrictSelect
                        defaultDistricts={
                          changedData?.location?.availableDistricts || modifiedProduct.location?.availableDistricts
                        }
                        onUpdate={(districts) => {
                          onChangeProperty('location.availableDistricts', districts);
                        }}></DistrictSelect>
                    </div>
                  )}
                  {[ProductActivityType.STUDIO, ProductActivityType.EXPERIENTIAL].includes(
                    changedData?.activityType || modifiedProduct.activityType,
                  ) && (
                    <div style={{ alignItems: 'center', marginTop: '12px' }}>
                      <div style={{ fontSize: '16px', fontWeight: 'bold' }}>수업 장소</div>
                      <div style={{ display: 'flex', flexDirection: 'column', width: '480px' }}>
                        <div
                          style={{
                            marginTop: '8px',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}>
                          <div
                            style={{ width: '100%', cursor: 'pointer' }}
                            onClick={() => {
                              setShowPostcodeModal(true);
                            }}>
                            <div
                              style={{
                                display: 'flex',
                                height: '48px',
                                borderRadius: '8px',
                                border: '1px solid #dadcdf',
                                paddingLeft: '16px',
                                paddingRight: '16px',
                                alignItems: 'center',
                              }}>
                              <div
                                style={{
                                  fontSize: '16px',
                                  lineHeight: '20px',
                                  color: '#242424',
                                }}>
                                {changedData?.location?.specificAddress?.address ||
                                  modifiedProduct.location?.specificAddress?.address}
                              </div>
                            </div>
                          </div>
                          <button
                            className="link-button"
                            style={{
                              display: 'flex',
                              height: '48px',
                              width: '88px',
                              alignItems: 'center',
                              justifyContent: 'center',
                              borderRadius: '8px',
                              backgroundColor: '#66686b',
                              marginLeft: '8px',
                            }}
                            onClick={() => {
                              setShowPostcodeModal(true);
                            }}>
                            <div
                              style={{
                                fontSize: '16px',
                                lineHeight: '19px',
                                fontWeight: '500',
                                color: '#ffffff',
                              }}>
                              주소검색
                            </div>
                          </button>
                        </div>
                        <input
                          style={{
                            height: '48px',
                            borderRadius: '8px',
                            border: '1px solid #dadcdf',
                            paddingLeft: '16px',
                            paddingRight: '16px',
                            marginTop: '12px',
                            fontSize: '16px',
                            lineHeight: '20px',
                            color: '#242424',
                          }}
                          value={
                            changedData?.location?.specificAddress?.address2 ||
                            modifiedProduct.location?.specificAddress?.address2
                          }
                          onChange={(e) => {
                            onChangeProperty('location.specificAddress.address2', e.target.value);
                          }}
                          placeholder={'상세주소를 입력해 주세요'}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div style={{ marginTop: '12px' }}>
                  <div style={{ fontSize: '14px', fontWeight: 'bold' }}>상품명</div>
                  <TextInput
                    style={{ maxWidth: '800px' }}
                    value={changedData?.title !== undefined ? changedData?.title : modifiedProduct.title}
                    onChangeValue={(value) => {
                      onChangeProperty('title', value);
                    }}
                    onChange={(e) => {
                      onChangeProperty('title', e.target.value);
                    }}></TextInput>
                </div>
                <div style={{ marginTop: '12px' }}>
                  <div style={{ fontSize: '14px', fontWeight: 'bold' }}>파트너</div>
                  <SelectUser
                    style={{ width: '528px' }}
                    selectedUserId={changedData?.sellerId || modifiedProduct.sellerId}
                    onSelect={(partner) => {
                      onChangeProperty('sellerId', partner._id);
                      onChangeProperty('sellerName', partner.profile?.nickname);
                    }}></SelectUser>
                </div>
                <div style={{ marginTop: '24px' }}>
                  <div style={{ fontSize: '16px', fontWeight: 'bold' }}>썸네일 이미지</div>
                  <div
                    style={{
                      width: '184px',
                      height: '184px',
                      border: '1px solid #eaeaea',
                      overflow: 'hidden',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '8px',
                    }}>
                    {modifiedProduct.thumbnailImageTemp || modifiedProduct.thumbnailImage ? (
                      <img
                        src={cdnImageUrl(modifiedProduct.thumbnailImageTemp || modifiedProduct.thumbnailImage)}
                        style={{ width: '184px', height: '184px', objectFit: 'cover' }}
                      />
                    ) : (
                      <div>{'NO IMAGE'}</div>
                    )}
                  </div>
                  <FileUploadButton
                    accept={['jpg', 'jpeg', 'png', 'tif', 'tiff', 'webp', 'svg']
                      .map((ext) => {
                        return '.' + ext;
                      })
                      .join(',')}
                    className="link-button"
                    style={{
                      display: 'flex',
                      alignSelf: 'center',
                      justifyContent: 'center',
                      height: '36px',
                      width: '184px',
                      border: '1px solid #aaaaaa',
                      borderRadius: '8px',
                      lineHeight: '36px',
                      marginTop: '4px',
                    }}
                    onChange={(e) => {
                      if (e.target.files) {
                        const files = e.target.files;
                        onChangeThumbnailFile(files);
                      }
                    }}>
                    썸네일 변경
                  </FileUploadButton>
                </div>
                <div style={{ marginTop: '12px' }}>
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                    <div style={{ fontSize: '14px', fontWeight: 'bold' }}>커버 이미지</div>
                    <FileUploadButton
                      multiple
                      accept={['jpg', 'jpeg', 'png', 'tif', 'tiff', 'webp', 'svg']
                        .map((ext) => {
                          return '.' + ext;
                        })
                        .join(',')}
                      className="link-button"
                      style={{
                        fontSize: '14px',
                        height: '32px',
                        padding: '4px 15px',
                        borderRadius: '6px',
                        backgroundColor: '#ffffff',
                        border: '1px solid #d9d9d9',
                        color: 'rgba(0, 0, 0, 0.88)',
                        boxShadow: '0 2px 0 rgba(0, 0, 0, 0.02)',
                        cursor: 'pointer',
                        marginLeft: '12px',
                      }}
                      onChange={(e) => {
                        if (e.target.files) {
                          const files = e.target.files;
                          onChangeDetailImageFiles(files);
                        }
                      }}>
                      커버 이미지 추가
                    </FileUploadButton>
                  </div>
                  <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={onDetailImagesSortEnd}>
                    <SortableContext
                      items={(modifiedProduct.detailImagesTemp || modifiedProduct.detailImages || []).map(
                        (item) => item,
                      )}
                      strategy={horizontalListSortingStrategy}>
                      <div style={{ display: 'flex', gap: '4px', overflow: 'auto', maxWidth: '600px' }}>
                        {(modifiedProduct.detailImagesTemp || modifiedProduct.detailImages || []).map(
                          (imageUrl: string, index: number) => {
                            return (
                              <SortableDetailImage
                                key={`image-${imageUrl}-${index}`}
                                id={imageUrl}
                                onDeleteDetailImage={() => {
                                  onDeleteDetailImage(index);
                                }}
                                imageUrl={imageUrl}
                              />
                            );
                          },
                        )}
                      </div>
                    </SortableContext>
                  </DndContext>
                </div>
                <div style={{ marginTop: '12px' }}>
                  <div style={{ fontSize: '14px', fontWeight: 'bold' }}>카테고리</div>
                  <div style={{ fontSize: '13px', marginTop: '8px' }}>
                    {Object.keys(PRODUCT_CATEGORIES).map((key) => {
                      return (
                        <div key={key} style={{ display: 'flex' }}>
                          <div
                            style={{
                              marginRight: '20px',
                              fontWeight: 'bold',
                              width: '52px',
                              textAlign: 'right',
                            }}>
                            {key}
                          </div>
                          <div>
                            {PRODUCT_CATEGORIES[key as PRODUCT_CATEGORIES_KEYS].map((item) => {
                              return (
                                <Checkbox
                                  key={item}
                                  style={{ width: '100px' }}
                                  checked={(modifiedProduct.categories || []).includes(item)}
                                  onChange={(e) => {
                                    let categories = [...(changedData?.categories || modifiedProduct.categories || [])];

                                    if (e.target.checked) {
                                      categories.push(item);
                                    } else {
                                      categories = categories.filter((cate) => cate !== item);
                                    }
                                    onChangeProperty('categories', categories.slice(0, 4));
                                  }}>
                                  {item}
                                </Checkbox>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div style={{ marginTop: '24px' }}>
                  <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
                    태그
                    <Tooltip title="쉼표로 구분, 앞 2개만 노출">
                      <QuestionCircleOutlined style={{ marginLeft: '4px' }} />
                    </Tooltip>
                  </div>
                  <TextArea
                    rows={4}
                    style={{ maxWidth: '800px', marginTop: '8px' }}
                    value={((changedData?.tags !== undefined ? changedData?.tags : modifiedProduct.tags) || []).join(
                      ',',
                    )}
                    onChangeValue={(value) => {
                      onChangeProperty(
                        'tags',
                        value.split(',').map((v) => v.trim()),
                      );
                    }}
                    onChange={(e) => {
                      onChangeProperty(
                        'tags',
                        e.target.value.split(',').map((value) => value.trim()),
                      );
                    }}></TextArea>
                </div>
              </div>
            </div>
            <div style={{ borderRadius: '12px', border: '1px solid #eaeaea', padding: '16px' }}>
              <div
                style={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                  borderBottom: '1px solid #eaeaea',
                  height: '32px',
                }}>
                상세 정보
              </div>
              <div style={{ marginTop: '12px', marginLeft: '12px' }}>
                <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                  <div>상세정보 타입</div>
                  <Select
                    value={changedData?.detailType || modifiedProduct.detailType}
                    style={{ width: '240px', marginLeft: '12px' }}
                    onChange={(value) => {
                      onChangeProperty('detailType', value);
                    }}
                    options={[
                      { value: 'template', label: '템플릿(구 클래스)' },
                      { value: 'images', label: '이미지' },
                      { value: 'html', label: 'HTML(구 커머스)' },
                    ]}
                  />
                </div>
                <div style={{ marginTop: '12px' }}>
                  {modifiedProduct.detailType === 'html' && (
                    <div style={{ width: '640px' }}>
                      {loadingEditorImageUpload && '이미지를 업로드 중 입니다...'}
                      <CKEditor
                        config={{ extraPlugins: [CKEditorIgogoImageUploader] }}
                        editor={ClassicEditor}
                        data={changedData?.detailHtml || modifiedProduct.detailHtml}
                        onChange={(event, editor) => {
                          const htmlString = editor.ui.editor.getData();
                          if (!htmlString.includes('<img>')) {
                            const dom = cheerio.load(htmlString);
                            dom('figure').attr('style', 'margin:0;');
                            dom('img').attr('width', '100%');
                            dom('img').removeAttr('height');
                            const result = dom
                              .html()
                              .replace('<html>', '')
                              .replace('</html>', '')
                              .replace('<head>', '')
                              .replace('</head>', '')
                              .replace('<body>', '')
                              .replace('</body>', '');

                            if (editorTimerRef.current) {
                              clearTimeout(editorTimerRef.current);
                            }

                            editorTimerRef.current = setTimeout(() => {
                              onChangeProperty('detailHtml', result);
                              setLoadingEditorImageUpload(false);
                            }, 500);
                          } else {
                            setLoadingEditorImageUpload(true);
                          }
                        }}
                      />
                    </div>
                  )}
                  {modifiedProduct.detailType === 'images' && (
                    <div style={{ marginTop: '12px' }}>
                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                        <div style={{ fontSize: '16px', fontWeight: 'bold' }}>상세 소개 이미지</div>
                        <FileUploadButton
                          multiple
                          accept={['jpg', 'jpeg', 'png', 'tif', 'tiff', 'webp', 'svg']
                            .map((ext) => {
                              return '.' + ext;
                            })
                            .join(',')}
                          className="link-button"
                          style={{
                            fontSize: '14px',
                            height: '32px',
                            padding: '4px 15px',
                            borderRadius: '6px',
                            backgroundColor: '#ffffff',
                            border: '1px solid #d9d9d9',
                            color: 'rgba(0, 0, 0, 0.88)',
                            boxShadow: '0 2px 0 rgba(0, 0, 0, 0.02)',
                            cursor: 'pointer',
                            marginLeft: '12px',
                          }}
                          onChange={(e) => {
                            if (e.target.files) {
                              const files = e.target.files;
                              onChangeDetailBodyImageFiles(files);
                            }
                          }}>
                          상세 소개 이미지 추가
                        </FileUploadButton>
                      </div>
                      <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragEnd={onDetailBodyImagesSortEnd}>
                        <SortableContext
                          items={(modifiedProduct.detailBodyImagesTemp || modifiedProduct.detailBodyImages || []).map(
                            (item) => item,
                          )}
                          strategy={horizontalListSortingStrategy}>
                          <div style={{ display: 'flex', gap: '4px', overflow: 'auto', maxWidth: '600px' }}>
                            {(modifiedProduct.detailBodyImagesTemp || modifiedProduct.detailBodyImages || []).map(
                              (imageUrl: string, index: number) => {
                                return (
                                  <SortableDetailImage
                                    key={`image-${imageUrl}-${index}`}
                                    id={imageUrl}
                                    onDeleteDetailImage={() => {
                                      onDeleteDetailBodyImage(index);
                                    }}
                                    imageUrl={imageUrl}
                                  />
                                );
                              },
                            )}
                          </div>
                        </SortableContext>
                      </DndContext>
                    </div>
                  )}
                  {modifiedProduct.detailType === 'template' && (
                    <div>
                      <div style={{ alignItems: 'center', marginTop: '12px' }}>
                        <div style={{ fontSize: '16px', fontWeight: 'bold' }}>클래스를 소개합니다</div>
                        <div>
                          <img
                            style={{
                              width: '480px',
                              height: '240px',
                              objectFit: 'cover',
                              marginTop: '4px',
                              display: 'block',
                            }}
                            src={cdnImageUrl(
                              changedData?.classIntroductionImageTemp ||
                                modifiedProduct.classIntroductionImage ||
                                (changedData?.detailImagesTemp || modifiedProduct.detailImages || [])[0],
                            )}></img>
                          <FileUploadButton
                            accept={['jpg', 'jpeg', 'png', 'tif', 'tiff', 'webp', 'svg']
                              .map((ext) => {
                                return '.' + ext;
                              })
                              .join(',')}
                            className="link-button"
                            style={{
                              display: 'flex',
                              alignSelf: 'center',
                              justifyContent: 'center',
                              height: '36px',
                              width: '480px',
                              border: '1px solid #aaaaaa',
                              borderRadius: '8px',
                              lineHeight: '36px',
                              marginTop: '4px',
                            }}
                            onChange={(e) => {
                              if (e.target.files) {
                                const files = e.target.files;
                                onChangeClassDetailImage(files);
                              }
                            }}>
                            클래스 소개 이미지 변경
                          </FileUploadButton>
                        </div>
                        <ClassDetailEdit
                          items={(
                            changedData?.details?.introductions ||
                            modifiedProduct.details?.introductions ||
                            []
                          ).map((item: { title?: string; description?: string }) => {
                            return { title: item.title, description: item.description };
                          })}
                          onChange={(items) => {
                            onChangeProperty('details.introductions', items);
                          }}></ClassDetailEdit>
                      </div>
                      <div style={{ alignItems: 'center', marginTop: '12px' }}>
                        <div style={{ fontSize: '16px', fontWeight: 'bold' }}>이런 친구에게 추천해요</div>
                        <TextArea
                          rows={4}
                          style={{ maxWidth: '428px' }}
                          value={
                            changedData?.details?.recommendText !== undefined
                              ? changedData?.details?.recommendText
                              : modifiedProduct.details?.recommendText
                          }
                          onChangeValue={(value) => {
                            onChangeProperty('details.recommendText', value);
                          }}
                          onChange={(e) => {
                            onChangeProperty('details.recommendText', e.target.value);
                          }}></TextArea>
                      </div>
                      <div style={{ alignItems: 'center', marginTop: '12px' }}>
                        <div style={{ fontSize: '16px', fontWeight: 'bold' }}>클래스는 이렇게 진행돼요</div>
                        <ClassDetailEdit
                          editImage
                          items={(changedData?.details?.concepts || modifiedProduct.details?.concepts || []).map(
                            (item: Concept) => {
                              return {
                                title: item.conceptTitle,
                                description: item.conceptData,
                                images: item.images as string[],
                                imagesTemp: item.imagesTemp as string[],
                              };
                            },
                          )}
                          onChange={(items) => {
                            onChangeProperty(
                              'details.concepts',
                              items.map((item, index) => {
                                if (item.files) {
                                  item.files.forEach((file, innerIndex) => {
                                    setFiles((value) => {
                                      const newValue = { ...value };
                                      newValue[`details.concepts.${index}.images.${innerIndex}`] = file;
                                      return newValue;
                                    });
                                  });
                                }
                                return {
                                  conceptTitle: item.title,
                                  conceptData: item.description,
                                  images: item.images,
                                  imagesTemp: item.imagesTemp,
                                };
                              }),
                            );
                          }}></ClassDetailEdit>
                      </div>
                      <div style={{ alignItems: 'center', marginTop: '12px' }}>
                        <div style={{ fontSize: '16px', fontWeight: 'bold' }}>클래스 준비물</div>
                        <TextArea
                          rows={4}
                          style={{ maxWidth: '428px' }}
                          value={
                            changedData?.details?.preparationText !== undefined
                              ? changedData?.details?.preparationText
                              : modifiedProduct.details?.preparationText
                          }
                          onChangeValue={(value) => {
                            onChangeProperty('details.preparationText', value);
                          }}
                          onChange={(e) => {
                            onChangeProperty('details.preparationText', e.target.value);
                          }}></TextArea>
                      </div>
                    </div>
                  )}
                </div>
                <div style={{ marginTop: '12px' }}>
                  <div style={{ fontSize: '14px', fontWeight: 'bold' }}>문의하기</div>
                  {partnerData && (
                    <div style={{ width: '520px' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ width: '120px' }}>대표고객센터</div>
                        <Select
                          placeholder={'대표고객센터 선택'}
                          value={changeCustomerCenter.mainCustomerService || partnerData.profile?.mainCustomerService}
                          options={[
                            { value: 'phone', label: '전화번호' },
                            { value: 'url', label: 'URL' },
                          ]}
                          onSelect={(value) => {
                            setChangeCustomerCenter((prev: Partial<UserProfile>) => {
                              return { ...prev, mainCustomerService: value };
                            });
                          }}></Select>
                        <div style={{ marginLeft: 'auto' }}>
                          <Button
                            disabled={Object.keys(changeCustomerCenter).length === 0}
                            onClick={() => {
                              mutaionUpdatePartnerProfile.mutate({
                                userId: partnerData._id,
                                body: { ...partnerData.profile, ...changeCustomerCenter },
                              });
                            }}>
                            변경사항 적용하기
                          </Button>
                        </div>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                        <div style={{ width: '120px' }}>전화번호</div>
                        <TextInput
                          value={
                            changeCustomerCenter?.customerServicePhone !== undefined
                              ? changeCustomerCenter?.customerServicePhone
                              : partnerData.profile?.customerServicePhone
                          }
                          onChangeValue={(value) => {
                            setChangeCustomerCenter((prev: Partial<UserProfile>) => {
                              return { ...prev, customerCenterPhone: value };
                            });
                          }}
                          onChange={(e) => {
                            setChangeCustomerCenter((prev: Partial<UserProfile>) => {
                              return { ...prev, customerCenterPhone: e.target.value };
                            });
                          }}></TextInput>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                        <div style={{ width: '120px' }}>URL</div>
                        <TextInput
                          value={
                            changeCustomerCenter?.customerServiceUrl !== undefined
                              ? changeCustomerCenter?.customerServiceUrl
                              : partnerData.profile?.customerServiceUrl
                          }
                          onChangeValue={(value) => {
                            setChangeCustomerCenter((prev: Partial<UserProfile>) => {
                              return { ...prev, customerServiceUrl: value };
                            });
                          }}
                          onChange={(e) => {
                            setChangeCustomerCenter((prev: Partial<UserProfile>) => {
                              return { ...prev, customerServiceUrl: e.target.value };
                            });
                          }}></TextInput>
                      </div>
                    </div>
                  )}
                </div>

                <div style={{ marginTop: '24px' }}>
                  <div style={{ fontSize: '14px', fontWeight: 'bold' }}>지역검색어(추가검색어)</div>
                  <TextArea
                    rows={4}
                    style={{ maxWidth: '800px' }}
                    value={changedData?.regionText !== undefined ? changedData?.regionText : modifiedProduct.regionText}
                    onChangeValue={(value) => {
                      onChangeProperty('regionText', value);
                    }}
                    onChange={(e) => {
                      onChangeProperty('regionText', e.target.value);
                    }}></TextArea>
                </div>
              </div>
            </div>
            <div style={{ borderRadius: '12px', border: '1px solid #eaeaea', padding: '16px' }}>
              <div
                style={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                  borderBottom: '1px solid #eaeaea',
                  height: '32px',
                }}>
                가격 정보
              </div>
              <div style={{ marginTop: '12px', marginLeft: '12px' }}>
                <div style={{ marginTop: '24px' }}>
                  <table>
                    <thead>
                      <tr>
                        <th style={{ width: '120px' }}>판매가(필수)</th>
                        <th style={{ width: '120px' }}>정가</th>
                        <th style={{ width: '160px' }}>할인율(자동계산)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <TextInput
                            style={{ width: '100px', textAlign: 'right' }}
                            value={changedData?.fee !== undefined ? changedData?.fee : modifiedProduct.fee}
                            onChangeValue={(value) => {
                              const numberValue = parseInt(value) || 0;
                              onChangeProperty('fee', numberValue);
                              const originFee = changedData?.originFee || modifiedProduct.originFee;
                              if (originFee) {
                                const numberOriginFee = parseInt(originFee) || 0;
                                if (!numberOriginFee || !numberValue) {
                                  onChangeProperty('discount', null);
                                } else {
                                  onChangeProperty(
                                    'discount',
                                    `${100 - Math.ceil((numberValue / numberOriginFee) * 100)}%`,
                                  );
                                }
                              }
                            }}></TextInput>
                          <span>원</span>
                        </td>
                        <td>
                          <TextInput
                            style={{ width: '100px', textAlign: 'right' }}
                            value={
                              changedData?.originFee !== undefined ? changedData?.originFee : modifiedProduct.originFee
                            }
                            onChangeValue={(value) => {
                              const numberValue = parseInt(value) || 0;
                              onChangeProperty('originFee', numberValue);
                              const fee = changedData?.fee || modifiedProduct.fee;
                              if (fee) {
                                const numberFee = parseInt(fee) || 0;
                                if (!numberFee || !numberValue) {
                                  onChangeProperty('discount', null);
                                } else {
                                  onChangeProperty('discount', `${100 - Math.ceil((numberFee / numberValue) * 100)}%`);
                                }
                              }
                            }}></TextInput>
                          <span>원</span>
                        </td>
                        <td style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          {changedData?.discount || modifiedProduct.discount}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div style={{ marginTop: '12px' }}>
                  <div>
                    <Checkbox
                      checked={changedData?.applyPeriodDiscount || modifiedProduct.applyPeriodDiscount}
                      onChange={(e) => {
                        onChangeProperty('applyPeriodDiscount', e.target.checked);
                      }}>
                      기간 한정 할인
                    </Checkbox>
                  </div>
                  {modifiedProduct.applyPeriodDiscount && (
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', marginTop: '8px' }}>
                      <div
                        style={{
                          fontSize: '14px',
                          fontWeight: 500,
                          color: '#424242',
                          fontFamily: 'Pretendard',
                          marginTop: '12px',
                        }}>
                        시작일
                      </div>
                      <DatePicker
                        allowClear={false}
                        style={{ height: '40px', borderRadius: 0, paddingRight: 0, cursor: 'pointer' }}
                        placeholder={'시작일 선택'}
                        popupStyle={{ zIndex: 99999 }}
                        value={discountInfo?.discountStartDate ? dayjs(discountInfo?.discountStartDate) : undefined}
                        showTime={{ showSecond: false }}
                        onChange={(value, dateString) => {
                          onChangeProperty(
                            'discountInfo.discountStartDate',
                            moment(dateString, 'YYYY-MM-DD HH:mm:00').toDate(),
                          );
                        }}
                      />
                      <div
                        style={{
                          fontSize: '14px',
                          fontWeight: 500,
                          color: '#424242',
                          fontFamily: 'Pretendard',
                          marginTop: '12px',
                        }}>
                        종료일
                      </div>
                      <DatePicker
                        allowClear={false}
                        style={{ height: '40px', borderRadius: 0, paddingRight: 0, cursor: 'pointer' }}
                        placeholder={'종료일 선택'}
                        popupStyle={{ zIndex: 99999 }}
                        value={discountInfo?.discountEndDate ? dayjs(discountInfo?.discountEndDate) : undefined}
                        showTime={{ showSecond: false }}
                        onChange={(value, dateString) => {
                          onChangeProperty(
                            'discountInfo.discountEndDate',
                            moment(dateString, 'YYYY-MM-DD HH:mm:00').toDate(),
                          );
                        }}></DatePicker>
                      <div
                        style={{
                          fontSize: '14px',
                          fontWeight: 500,
                          color: '#424242',
                          fontFamily: 'Pretendard',
                          marginTop: '12px',
                        }}>
                        판매가
                      </div>
                      <TextInput
                        type="number"
                        value={discountInfo?.discountedFee || 0}
                        onChangeValue={(value) => {
                          onChangeProperty('discountInfo.discountedFee', parseInt(value) || 0);
                        }}></TextInput>
                      원
                    </div>
                  )}
                </div>
                <div style={{ marginTop: '24px' }}>
                  {modifiedProduct.activityType === ProductActivityType.DELIVERY && (
                    <div>
                      <div
                        style={{
                          fontSize: '13px',
                          fontWeight: 'bold',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                        }}>
                        <div>배송비</div>
                        <TextInput
                          style={{ width: '100px', textAlign: 'right' }}
                          value={
                            changedData?.shippingFee !== undefined
                              ? changedData?.shippingFee
                              : modifiedProduct.shippingFee
                          }
                          onChangeValue={(value) => {
                            onChangeProperty('shippingFee', parseInt(value) || 0);
                          }}
                          onChange={(e) => {
                            onChangeProperty('shippingFee', parseInt(e.target.value) || 0);
                          }}></TextInput>
                        <span>원</span>
                      </div>
                      <div style={{ marginTop: '24px' }}>
                        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>배송 정책</div>
                        <TextArea
                          rows={4}
                          style={{ maxWidth: '428px' }}
                          value={
                            changedData?.shippingPolicyText !== undefined
                              ? changedData?.shippingPolicyText
                              : modifiedProduct.shippingPolicyText
                          }
                          onChangeValue={(value) => {
                            onChangeProperty('shippingPolicyText', value);
                          }}
                          onChange={(e) => {
                            onChangeProperty('shippingPolicyText', e.target.value);
                          }}></TextArea>
                      </div>
                    </div>
                  )}
                </div>
                <div style={{ marginTop: '24px' }}>
                  <div style={{ fontSize: '14px', fontWeight: 'bold' }}>환불 정책</div>
                  <TextArea
                    rows={4}
                    style={{ maxWidth: '428px' }}
                    value={refundPolicyText}
                    onChangeValue={(value) => {
                      onChangeProperty('refundPolicyText', value);
                    }}
                    onChange={(e) => {
                      onChangeProperty('refundPolicyText', e.target.value);
                    }}></TextArea>
                </div>
                <div style={{ marginTop: '24px' }}>
                  <div style={{ fontSize: '14px', fontWeight: 'bold' }}>판매 완료 시 노출 문구(4자)</div>
                  <TextArea
                    rows={4}
                    style={{ maxWidth: '428px' }}
                    value={changedData?.closeText !== undefined ? changedData?.closeText : modifiedProduct.closeText}
                    onChangeValue={(value) => {
                      onChangeProperty('closeText', value);
                    }}
                    onChange={(e) => {
                      onChangeProperty('closeText', e.target.value);
                    }}></TextArea>
                </div>
              </div>
            </div>
            <div style={{ borderRadius: '12px', border: '1px solid #eaeaea', padding: '16px' }}>
              <div
                style={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                  borderBottom: '1px solid #eaeaea',
                  height: '32px',
                }}>
                부가 정보 ({modifiedProduct.saleType})
              </div>
              <div style={{ marginTop: '12px', marginLeft: '12px' }}>
                <div style={{ marginTop: '12px' }}>
                  {modifiedProduct.saleType === 'commerce' && (
                    <div>
                      <div>
                        <div style={{ marginTop: '24px' }}>
                          <div
                            style={{
                              fontSize: '14px',
                              fontWeight: 'bold',
                              display: 'flex',
                              flexDirection: 'column',
                              marginRight: '10px',
                            }}>
                            <div>입력 옵션(최대 2개)</div>
                            <br />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              {(changedData?.questionOptions || modifiedProduct?.questionOptions || []).map(
                                (option: QuestionOption, index: number) => {
                                  return (
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        gap: '5px',
                                        marginBottom: '5px',
                                      }}>
                                      <TextInput
                                        style={{ maxWidth: '428px' }}
                                        value={option?.question}
                                        onChangeValue={(value) => {
                                          const newValue = [
                                            ...(changedData?.questionOptions || modifiedProduct?.questionOptions || []),
                                          ];
                                          newValue[index] = { question: value };
                                          onChangeProperty('questionOptions', newValue);
                                        }}
                                        onChange={(e) => {
                                          const newValue = [
                                            ...(changedData?.questionOptions || modifiedProduct?.questionOptions || []),
                                          ];
                                          newValue[index] = { question: e.target.value };
                                          onChangeProperty('questionOptions', newValue);
                                        }}></TextInput>
                                      <Button
                                        onClick={() => {
                                          const newValue: QuestionOption[] = [
                                            ...(changedData?.questionOptions || modifiedProduct?.questionOptions || []),
                                          ];
                                          newValue.splice(index, 1);
                                          onChangeProperty('questionOptions', newValue);
                                        }}>
                                        삭제
                                      </Button>
                                    </div>
                                  );
                                },
                              )}
                            </div>
                            {(changedData?.questionOptions || modifiedProduct?.questionOptions || []).length < 2 && (
                              <Button
                                style={{ width: '60px' }}
                                onClick={() => {
                                  const newValue = [
                                    ...(changedData?.questionOptions || modifiedProduct?.questionOptions || []),
                                  ];
                                  newValue.push({ question: '' });
                                  onChangeProperty('questionOptions', newValue);
                                }}>
                                추가
                              </Button>
                            )}
                          </div>
                        </div>
                        <div style={{ marginTop: '24px' }}>
                          <div style={{ fontSize: '14px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                            <div>구매 시 입력 요청 멘트</div>
                            <div style={{ marginLeft: '24px', marginRight: '4px' }}>필수입력</div>
                            <Switch
                              checked={modifiedProduct.memoRequired}
                              onChange={(value) => {
                                onChangeProperty('memoRequired', value);
                              }}
                            />
                          </div>
                          <TextInput
                            style={{ maxWidth: '428px' }}
                            value={
                              changedData?.memoComment !== undefined
                                ? changedData?.memoComment
                                : modifiedProduct.memoComment
                            }
                            onChangeValue={(value) => {
                              onChangeProperty('memoComment', value);
                            }}
                            onChange={(e) => {
                              onChangeProperty('memoComment', e.target.value);
                            }}></TextInput>
                        </div>
                      </div>
                    </div>
                  )}
                  {modifiedProduct.saleType === 'class' && (
                    <div>
                      <div style={{ marginTop: '12px', marginBottom: '12px' }}>
                        <div style={{ display: 'flex', gap: '8px' }}>
                          <div style={{ fontSize: '14px', fontWeight: 'bold' }}>하단 텍스트 배너 설정(CTA)</div>
                          <div>
                            <Switch
                              checked={modifiedProduct.bottomTextBanner?.show}
                              onChange={(value) => {
                                onChangeProperty('bottomTextBanner.show', value);
                              }}
                            />
                          </div>
                        </div>
                        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                          <FileUploadButton
                            accept={['jpg', 'jpeg', 'png', 'tif', 'tiff', 'webp', 'svg']
                              .map((ext) => {
                                return '.' + ext;
                              })
                              .join(',')}
                            className="link-button"
                            style={{
                              display: 'flex',
                              alignSelf: 'center',
                              justifyContent: 'center',
                              height: '36px',
                              width: '184px',
                              border: '1px solid #aaaaaa',
                              borderRadius: '8px',
                              lineHeight: '36px',
                              marginTop: '4px',
                            }}
                            onChange={(e) => {
                              if (e.target.files) {
                                const files = e.target.files;
                                onChangeBottomTextBannerIconFile(files);
                              }
                            }}>
                            아이콘 변경
                          </FileUploadButton>
                          <img
                            style={{ width: '20px', height: '20px', objectFit: 'cover' }}
                            src={cdnImageUrl(
                              changedData?.bottomTextBanner?.iconTemp || modifiedProduct.bottomTextBanner?.icon,
                            )}></img>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            gap: '8px',
                            width: '528px',
                            marginTop: '8px',
                            alignItems: 'center',
                          }}>
                          <div style={{ flexShrink: 0, fontWeight: 'bold' }}>강조 텍스트</div>
                          <TextInput
                            value={
                              changedData?.bottomTextBanner?.highlightText !== undefined
                                ? changedData?.bottomTextBanner?.highlightText
                                : modifiedProduct.bottomTextBanner?.highlightText
                            }
                            onChangeValue={(value) => {
                              onChangeProperty('bottomTextBanner.highlightText', value);
                            }}></TextInput>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            gap: '8px',
                            width: '528px',
                            marginTop: '8px',
                            alignItems: 'center',
                          }}>
                          <div style={{ flexShrink: 0, fontWeight: 'bold' }}>일반 텍스트</div>
                          <TextInput
                            value={
                              changedData?.bottomTextBanner?.normalText !== undefined
                                ? changedData?.bottomTextBanner?.normalText
                                : modifiedProduct.bottomTextBanner?.normalText
                            }
                            onChangeValue={(value) => {
                              onChangeProperty('bottomTextBanner.normalText', value);
                            }}></TextInput>
                        </div>
                      </div>
                      <div style={{ alignItems: 'center', marginTop: '12px' }}>
                        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>뱃지</div>
                        <div style={{ display: 'flex', marginTop: '8px', gap: '12px' }}>
                          {Object.keys(BADGES).map((key) => {
                            return (
                              <Checkbox
                                key={key}
                                checked={(changedData?.badges || modifiedProduct.badges || []).find(
                                  (badge: ClassBadge) => badge.value === key,
                                )}
                                onChange={(event) => {
                                  onChangeBadgeChecked(key as any, event.target.checked);
                                }}>
                                {key}
                              </Checkbox>
                            );
                          })}
                        </div>

                        <div style={{ fontSize: '13px' }}>
                          <div>{'* 뱃지 최대 2개 적용'}</div>
                          <div>{'* 우선순위 : 이벤트 > 신청마감 > 업데이트 > NEW > 혜택 > 그룹할인'}</div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div style={{ borderRadius: '12px', border: '1px solid #eaeaea', padding: '16px' }}>
              <div
                style={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                  borderBottom: '1px solid #eaeaea',
                  height: '32px',
                }}>
                재고 마감 설정
              </div>
              <div style={{ marginTop: '12px', marginLeft: '12px' }}>
                <Checkbox
                  style={{ width: '140px' }}
                  checked={changedData.autoCloseEnabled || modifiedProduct.autoCloseEnabled}
                  onChange={(e) => {
                    onChangeProperty('autoCloseEnabled', e.target.checked);
                  }}>
                  자동 마감 사용
                </Checkbox>
                <div style={{ fontWeight: 'bold', marginTop: '8px' }}>
                  <div>자동 마감 기간</div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>
                  D+
                  <TextInput
                    style={{ width: '100px' }}
                    value={
                      changedData?.autoCloseDuration !== undefined
                        ? changedData?.autoCloseDuration
                        : modifiedProduct.autoCloseDuration
                    }
                    onChangeValue={(value) => {
                      onChangeProperty('autoCloseDuration', Number(value) || 0);
                    }}></TextInput>
                  일
                </div>
                <div style={{ fontWeight: 'normal' }}>
                  예시: 위 값이 2이면, 12월 17일에 재고마감 시 12월 19일 23시 59분 59초까지 모든 일정을 마감
                </div>
              </div>
            </div>
            <div style={{ borderRadius: '12px', border: '1px solid #eaeaea', padding: '16px' }}>
              <div
                style={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                  borderBottom: '1px solid #eaeaea',
                  height: '32px',
                }}>
                외부 판매 채널
              </div>
              <div style={{ marginTop: '12px', marginLeft: '12px' }}>
                <div style={{ marginTop: '12px' }}>
                  <div
                    style={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '8px',
                      gap: '8px',
                    }}>
                    <div
                      style={{
                        backgroundColor: '#03c75a',
                        color: 'white',
                        fontSize: '12px',
                        padding: '4px 8px',
                        borderRadius: '4px',
                      }}>
                      네이버스토어
                    </div>
                    {naverStoreProduct?.originProduct?.statusType && (
                      <div>{NaverCommerceProductStatusLabel[naverStoreProduct?.originProduct?.statusType]}</div>
                    )}
                    {naverStoreProduct?.smartstoreChannelProduct?.channelProductDisplayStatusType && (
                      <div>
                        {
                          NaverCommerceProductDisplayStatusLabel[
                            naverStoreProduct?.smartstoreChannelProduct?.channelProductDisplayStatusType
                          ]
                        }
                      </div>
                    )}

                    <Button
                      disabled={
                        !(
                          changedData?.linkedSalesChannel?.naverStore?.channelProductNo ||
                          modifiedProduct?.linkedSalesChannel?.naverStore?.channelProductNo
                        )
                      }
                      onClick={() => {
                        const channelProductNo =
                          changedData?.linkedSalesChannel?.naverStore?.channelProductNo ||
                          modifiedProduct?.linkedSalesChannel?.naverStore?.channelProductNo;
                        window.open(`https://smartstore.naver.com/igogo/products/${channelProductNo}`, '_blank');
                      }}>
                      이동하기
                    </Button>
                    {/* <Button>등록하기</Button> */}
                  </div>
                </div>
                <div style={{ marginTop: '12px' }}>
                  <div style={{ fontSize: '14px', fontWeight: 'bold' }}>채널 상품 번호(필수)</div>
                  <TextInput
                    style={{ maxWidth: '428px' }}
                    value={
                      changedData?.linkedSalesChannel?.naverStore?.channelProductNo ||
                      modifiedProduct?.linkedSalesChannel?.naverStore?.channelProductNo
                    }
                    onChangeValue={(value) => {
                      onChangeProperty('linkedSalesChannel.naverStore.channelProductNo', value);
                    }}
                    onChange={(e) => {
                      onChangeProperty('linkedSalesChannel.naverStore.channelProductNo', e.target.value);
                    }}></TextInput>
                </div>
                <div style={{ marginTop: '12px' }}>
                  <div style={{ fontSize: '14px', fontWeight: 'bold' }}>원 상품 번호(필수)</div>
                  <TextInput
                    style={{ maxWidth: '428px' }}
                    value={
                      changedData?.linkedSalesChannel?.naverStore?.originProductNo ||
                      modifiedProduct?.linkedSalesChannel?.naverStore?.originProductNo
                    }
                    onChangeValue={(value) => {
                      onChangeProperty('linkedSalesChannel.naverStore.originProductNo', value);
                    }}
                    onChange={(e) => {
                      onChangeProperty('linkedSalesChannel.naverStore.originProductNo', e.target.value);
                    }}></TextInput>
                </div>
                <div style={{ marginTop: '12px' }}>
                  <table>
                    <thead>
                      <tr>
                        <th style={{ width: '120px' }}>판매가(필수)</th>
                        <th style={{ width: '120px' }}>정가</th>
                        <th style={{ width: '160px' }}>할인율(자동계산)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <TextInput
                            style={{ width: '100px', textAlign: 'right' }}
                            value={
                              (naverStoreProduct?.originProduct?.salePrice || 0) -
                              (naverStoreProduct?.originProduct?.customerBenefit?.immediateDiscountPolicy
                                ?.discountMethod?.value || 0)
                            }></TextInput>
                          <span>원</span>
                        </td>
                        <td>
                          <TextInput
                            style={{ width: '100px', textAlign: 'right' }}
                            value={naverStoreProduct?.originProduct?.salePrice}></TextInput>
                          <span>원</span>
                        </td>
                        <td style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          {`${
                            ((naverStoreProduct?.originProduct?.customerBenefit?.immediateDiscountPolicy?.discountMethod
                              ?.value || 0) /
                              (naverStoreProduct?.originProduct?.salePrice || 1)) *
                            100
                          }%`}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div style={{ marginTop: '12px' }}>
                  <div
                    style={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                    }}>{`옵션 (${naverStoreProduct?.originProduct?.detailAttribute?.optionInfo?.optionCombinations?.length})`}</div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '4px',
                      overflow: 'auto',
                      maxHeight: '480px',
                    }}>
                    <div style={{ display: 'flex', gap: '8px' }}>
                      <div style={{ width: '80px' }}>재고</div>
                      <div style={{ width: '240px' }}>옵션</div>
                      <div style={{ width: '120px' }}>플랫폼 재고</div>
                      <div></div>
                    </div>
                    {(naverStoreProduct?.originProduct?.detailAttribute?.optionInfo?.optionCombinations || []).map(
                      (option, index) => {
                        let platformInventory = 0;
                        const step = [option.optionName1];
                        if (option?.optionName2) {
                          step.push(option?.optionName2);
                        }
                        if (option?.optionName3) {
                          step.push(option?.optionName3);
                        }

                        const key = formatDateFromStrings(step);

                        if (key) {
                          platformInventory = optionsDictRef.current[key];
                        }
                        return (
                          <div style={{ display: 'flex', gap: '8px' }}>
                            <div style={{ width: '80px' }}>{`${option.stockQuantity}`}</div>
                            <div style={{ width: '240px' }}>{`${option.optionName1} ${option?.optionName2 || ''} ${
                              option?.optionName3 || ''
                            }`}</div>
                            <div style={{ width: '120px' }}>{platformInventory}</div>
                            {option.stockQuantity === 0 ||
                              (platformInventory === 0 && (
                                <button
                                  onClick={() => {
                                    setNaverStoreProduct((prev) => {
                                      const optionCombinations =
                                        prev?.originProduct?.detailAttribute?.optionInfo?.optionCombinations || [];
                                      optionCombinations.splice(index, 1);
                                      return {
                                        ...prev,
                                        originProduct: {
                                          ...prev?.originProduct,
                                          detailAttribute: {
                                            ...prev?.originProduct?.detailAttribute,
                                            optionInfo: {
                                              ...prev?.originProduct?.detailAttribute?.optionInfo,
                                              optionCombinations: optionCombinations,
                                            },
                                          },
                                        },
                                      } as any;
                                    });
                                  }}>
                                  삭제
                                </button>
                              ))}
                          </div>
                        );
                      },
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ padding: '4px' }}></div>
          </div>
        </div>
        <div style={{ width: '480px' }}>
          <ProductDetailView
            product={{
              ...modifiedProduct,
            }}></ProductDetailView>
        </div>
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          left: 0,
          height: '44px',
          backgroundColor: '#eaeaea',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: '12px',
          paddingRight: '24px',
        }}>
        <Button
          disabled={!productItemQuery.data?.data.isTemp}
          onClick={() => {
            mutationDeleteDraftProduct.mutate(props.productId);
          }}>
          임시 데이터 제거
        </Button>
        <Button
          disabled={loadingEditorImageUpload}
          onClick={() => {
            const formData: FormData = new FormData();
            const json = removeTempProperties(changedData);
            json.isDistribution = true;

            formData.append('productId', props.productId);
            formData.append('json', JSON.stringify(json));

            Object.values(files).forEach((file) => {
              formData.append('files', file);
            });
            mutationUpdateProduct.mutate(formData);
          }}>
          저장 후 배포
        </Button>
        <Button
          disabled={loadingEditorImageUpload}
          onClick={() => {
            const formData: FormData = new FormData();
            const json = removeTempProperties(changedData);
            formData.append('productId', props.productId);
            formData.append('json', JSON.stringify(json));

            Object.values(files).forEach((file) => {
              formData.append('files', file);
            });
            mutationUpdateProduct.mutate(formData);
          }}>
          저장
        </Button>

        <Button
          onClick={() => {
            props.onClose && props.onClose();
          }}>
          닫기
        </Button>
      </div>
    </div>
  );
};

export default ProductEditView;
