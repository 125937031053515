import GrayDownIcon from '@assets/images/app/icon-arr-gray-down-12.png';
import GrayUpIcon from '@assets/images/app/icon-arr-gray-up-12.png';
import LogoBlackImage from '@assets/images/app/logo-logotype-black.png';
import React, { useState } from 'react';

export const FeedFooterCell: React.FC = () => {
  const [showInfo, setShowInfo] = useState<boolean>(false);

  return (
    <div
      style={{
        backgroundColor: '#eff1f4',
        padding: '32px 16px 60px 16px',
        marginTop: '12px',
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <img style={{ width: '66px', height: '14px' }} src={LogoBlackImage} />
        <div
          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => {
            setShowInfo((value) => !value);
          }}>
          <div style={{ color: '#808387', fontSize: '12px' }}>사업자정보</div>
          <img
            style={{ width: '12px', height: '12px', marginLeft: '8px' }}
            src={showInfo ? GrayUpIcon : GrayDownIcon}
          />
        </div>
      </div>
      {showInfo && (
        <div style={{ marginTop: 20 }}>
          <div
            style={{
              color: '#9b9da0',
              fontSize: '12px',
              lineHeight: '22px',
              fontWeight: 'bold',
            }}>
            주식회사 아이들랩
          </div>
          <div
            style={{
              color: '#9b9da0',
              fontSize: '12px',
              lineHeight: '22px',
            }}>
            대표이사: 박형준{'\n'}
            사업자등록번호: 819-88-00756{'\n'}
            통신판매업자신고: 제2021-서울강남-06986호{'\n'}
            주소: 서울시 강남구 역삼로 228, 2층{'\n'}
            고객센터: 02-568-4255{'\n'}
            이메일: hello@igogo.kr
          </div>
        </div>
      )}
      <div
        style={{
          color: '#9b9da0',
          fontSize: '12px',
          lineHeight: '18px',
          marginTop: '22px',
        }}>
        (주)아이들랩은 통신판매의 당사자가 아닌 통신판매중개자(플랫폼서비스사업자)로서, 튜터 및 판매자가 등록한 상품 및
        클래스의 정보, 거래에 대한 책임이 제한될 수 있습니다. 각 상품 및 클래스 별 페이지에서 구체적인 내용을 확인하시기
        바랍니다.
      </div>
      <div
        style={{
          backgroundColor: '#e8eaed',
          height: '1px',
          marginTop: '20px',
          marginBottom: '20px',
        }}
      />
      <div style={{ flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <button
            className="link-button"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid #dadcdf',
              borderRadius: '4px',
              marginRight: '8px',
              width: '110px',
              height: '28px',
            }}>
            <div style={{ color: '#9b9da0', fontSize: '12px' }}>이용약관</div>
          </button>
          <button
            className="link-button"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid #dadcdf',
              borderRadius: '4px',
              width: '110px',
              height: '28px',
            }}>
            <div style={{ color: '#9b9da0', fontSize: '12px' }}>개인정보처리방침</div>
          </button>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '8px' }}>
          <button
            className="link-button"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid #dadcdf',
              borderRadius: '4px',
              marginRight: '8px',
              width: '110px',
              height: '28px',
            }}>
            <div style={{ color: '#9b9da0', fontSize: '12px' }}>사업자정보확인</div>
          </button>
          <button
            className="link-button"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid #dadcdf',
              borderRadius: '4px',
              width: '110px',
              height: '28px',
            }}>
            <div style={{ color: '#9b9da0', fontSize: '12px' }}>제휴문의</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default FeedFooterCell;
