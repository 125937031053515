import CheckCircleIcon from '@assets/images/app/icon-check-purple-big.png';
import BottomRoundButton from '@components/common/BottomRoundButton';
import { PaymentStatement } from '@models/paymentStatement';
import { getPaymentMethodText } from '@utils/payment';
import moment from 'moment';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React from 'react';

export const CommercePaymentComplete: React.FC<{ paymentStatement: PaymentStatement; onConfirm?: () => void }> = (
  props,
) => {
  const paymentStatement = props.paymentStatement;
  const paymentMethod = getPaymentMethodText(paymentStatement.payment);

  return (
    <OverlayScrollbarsComponent
      defer
      options={{
        scrollbars: { autoHide: 'scroll' },
        overflow: {
          x: 'hidden',
        },
      }}
      style={{
        backgroundColor: '#ffffff',
        marginTop: 0,
        position: 'relative',
        height: '100%',
        overflow: 'auto',
        width: '100%',
      }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <div
          style={{
            marginTop: '40px',
            width: '60px',
            height: '60px',
            borderRadius: '50%',
            border: '3px solid #8358ff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <img style={{ width: '36px', height: '36px' }} src={CheckCircleIcon} />
        </div>
        <div
          style={{
            marginTop: '24px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '24px',
            lineHeight: '29px',
            fontWeight: 'bold',
          }}>
          <span style={{ color: '#242424' }}>결제가&nbsp;</span>
          <span style={{ color: '#7950ef' }}>완료</span>
          <span style={{ color: '#242424' }}>되었습니다.</span>
        </div>
      </div>
      <div style={{ marginTop: '20px', padding: '20px', borderTop: '1px solid #eff1f4' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '12px',
          }}>
          <div style={{ fontSize: '14px', lineHeight: '17px', color: '#66686b' }}>상품명</div>
          <div
            style={{
              fontSize: '14px',
              lineHeight: '19px',
              color: '#424242',
              textAlign: 'right',
            }}>
            {paymentStatement.product.name}
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '12px' }}>
          <div style={{ fontSize: '14px', lineHeight: '17px', color: '#66686b' }}>옵션</div>
          <div
            style={{
              fontSize: '14px',
              lineHeight: '19px',
              color: '#424242',
              textAlign: 'right',
            }}>
            {paymentStatement.purchases.map((item) => {
              return <div>{`${item.name}(${item.count})`}</div>;
            })}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '12px',
          }}>
          <div style={{ fontSize: '14px', lineHeight: '17px', color: '#66686b' }}>결제일시</div>
          <div
            style={{
              fontSize: '14px',
              lineHeight: '19px',
              color: '#424242',
              textAlign: 'right',
            }}>
            {moment(paymentStatement.payment.paidAt).format('YYYY. MM. DD HH:mm:ss')}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '12px',
          }}>
          <div style={{ fontSize: '14px', lineHeight: '17px', color: '#66686b' }}>결제금액</div>
          <div
            style={{
              fontSize: '16px',
              lineHeight: '19px',
              color: '#7950ef',
              fontWeight: 'bold',
              textAlign: 'right',
            }}>
            {paymentStatement.payment.billingAmount}원
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '12px',
          }}>
          <div style={{ fontSize: '14px', lineHeight: '17px', color: '#66686b' }}>ㄴ상품가격</div>
          <div
            style={{
              fontSize: '14px',
              lineHeight: '19px',
              color: '#424242',
              textAlign: 'right',
            }}>
            {paymentStatement.purchases.reduce((prev, curr) => {
              return prev + (curr.fee || 0) * (curr.count || 0);
            }, 0)}
            원
          </div>
        </div>
        {paymentStatement?.shipping && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '12px',
            }}>
            <div style={{ fontSize: '14px', lineHeight: '17px', color: '#66686b' }}>ㄴ배송비</div>
            <div
              style={{
                fontSize: '14px',
                lineHeight: '19px',
                color: '#424242',
                textAlign: 'right',
              }}>
              {(paymentStatement?.shipping?.fee || 0).toLocaleString()}
            </div>
          </div>
        )}
        {paymentStatement.payment.point > 0 && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '12px',
            }}>
            <div style={{ fontSize: '14px', lineHeight: '17px', color: '#66686b' }}>ㄴ포인트 사용</div>
            <div
              style={{
                fontSize: '14px',
                lineHeight: '19px',
                color: '#424242',
                textAlign: 'right',
              }}>
              -{paymentStatement.payment.point.toLocaleString()}원
            </div>
          </div>
        )}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '12px',
          }}>
          <div style={{ fontSize: '14px', lineHeight: '17px', color: '#66686b' }}>결제수단</div>
          <div
            style={{
              fontSize: '14px',
              lineHeight: '19px',
              color: '#424242',
              textAlign: 'right',
            }}>
            {paymentMethod}
          </div>
        </div>
      </div>
      {paymentStatement?.shipping && (
        <div style={{ marginTop: '20px', padding: '20px', borderTop: '1px solid #eff1f4' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '12px',
            }}>
            <div style={{ fontSize: '14px', lineHeight: '17px', color: '#66686b' }}>받는사람</div>
            <div
              style={{
                fontSize: '14px',
                lineHeight: '19px',
                color: '#424242',
                textAlign: 'left',
              }}>
              {paymentStatement?.shipping?.name}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '12px',
            }}>
            <div style={{ fontSize: '14px', lineHeight: '17px', color: '#66686b' }}>연락처</div>
            <div
              style={{
                fontSize: '14px',
                lineHeight: '19px',
                color: '#424242',
                textAlign: 'left',
              }}>
              {paymentStatement?.shipping?.phone}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '12px',
            }}>
            <div style={{ fontSize: '14px', lineHeight: '17px', color: '#66686b' }}>배송지</div>
            <div
              style={{
                fontSize: '14px',
                lineHeight: '19px',
                color: '#424242',
                textAlign: 'left',
              }}>
              {`${paymentStatement?.shipping?.address} ${paymentStatement?.shipping?.addressDetail}`}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '12px',
            }}>
            <div style={{ fontSize: '14px', lineHeight: '17px', color: '#66686b' }}>요청사항</div>
            <div
              style={{
                fontSize: '14px',
                lineHeight: '19px',
                color: '#424242',
                textAlign: 'left',
              }}>
              {paymentStatement?.memo?.shipping}
            </div>
          </div>
        </div>
      )}
      {paymentStatement?.memo?.payment && (
        <div style={{ marginTop: '20px', padding: '20px', borderTop: '1px solid #eff1f4' }}>
          <div
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
              marginBottom: '12px',
            }}>
            <div style={{ fontSize: '14px', lineHeight: '17px', color: '#66686b' }}>요청사항</div>
            <div
              style={{
                fontSize: '14px',
                lineHeight: '19px',
                color: '#424242',
                textAlign: 'left',
              }}>
              {paymentStatement.memo.payment}
            </div>
          </div>
        </div>
      )}
      <div style={{ padding: '20px' }}>
        <BottomRoundButton
          theme="secondary"
          style={{ flex: 1 }}
          onClick={() => {
            props.onConfirm && props.onConfirm();
          }}>
          닫기
        </BottomRoundButton>
      </div>
    </OverlayScrollbarsComponent>
  );
};

export default CommercePaymentComplete;
