import * as commonApi from '@apis/common';
import * as api from '@apis/payment-statement';
import { useAuthentication } from '@hooks/authentication';
import { PaymentStatementWithLogs } from '@models/paymentStatement';
import { useClassInvoiceModalStore } from '@stores/classInvoiceModalStore';
import { useErrorPaymentStatemetsStore } from '@stores/errorPaymentStatementsStore';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { useMutation, useQuery } from '@tanstack/react-query';
import { localTimeToUtcISOString, utcTimcToLocalISOString } from '@utils/date';
import { cdnImageUrl } from '@utils/image';
import { Button, DatePicker, DatePickerProps, Input, Popover, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { PhotoView } from 'react-photo-view';
import { toast } from 'react-toastify';

export const SystemPaymentsPage: React.FC = () => {
  useAuthentication();
  const store = useErrorPaymentStatemetsStore();
  const dialog = useOkCancelDialog();
  const classInvoiceModalStore = useClassInvoiceModalStore();
  const searchInputRef = useRef<any>();
  const [searchKeyword, setSearchKeyword] = useState<string | undefined>('');

  const TABLE_COLUMNS: ColumnsType<PaymentStatementWithLogs> = [
    {
      title: 'ID',
      dataIndex: '_id',
      render: (_id) => {
        return (
          <Button
            onClick={() => {
              navigator.clipboard.writeText(_id);
              toast.dark('결제내역 ID를 클립보드에 복사했습니다', {
                position: 'top-center',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                pauseOnFocusLoss: false,
                draggable: true,
                progress: undefined,
                bodyStyle: {
                  color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                  textAlign: 'center',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '20px',
                },
                theme: 'dark',
              });
            }}>
            ID 복사
          </Button>
        );
      },
      fixed: 'left',
    },
    {
      title: '구분',
      dataIndex: 'product',
      render: (product) => `${product?.saleType}`,
      fixed: 'left',
    },
    {
      fixed: 'left',
      title: '결제상태',
      render: (record) => {
        const statuses = record.statuses;
        const payStatus = (statuses?.payment || '').toLowerCase();
        let status = '-';
        if (payStatus === 'done') {
          status = '결제완료';
        } else if (payStatus === 'ready') {
          status = '결제대기';
        } else if (payStatus === 'error') {
          status = '결제오류';
        } else if (payStatus === 'none') {
          status = '-';
        } else {
          status = payStatus;
        }
        return (
          <div>
            <Popover content={JSON.stringify(record.payment.error, undefined, 2)}>{status}</Popover>
          </div>
        );
      },
    },
    {
      title: '구매자',
      dataIndex: 'buyerDetail',
      render: (buyer) => {
        if (buyer) {
          return (
            <div>
              <div
                style={{ whiteSpace: 'pre-wrap' }}>{`${buyer[0]?.profile?.name}\n(${buyer[0]?.profile?.phone})`}</div>
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(buyer[0]._id);
                  toast.dark(`구매자 ID를 클립보드에 복사했습니다(${buyer[0]._id})`, {
                    position: 'top-center',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    pauseOnFocusLoss: false,
                    draggable: true,
                    progress: undefined,
                    bodyStyle: {
                      color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                      textAlign: 'center',
                      fontFamily: 'Pretendard',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '20px',
                    },
                    theme: 'dark',
                  });
                }}>
                ID 복사
              </Button>
            </div>
          );
        }
        return '-';
      },
      fixed: 'left',
    },
    {
      title: '판매자',
      dataIndex: 'sellerDetail',
      align: 'center',
      render: (seller) => {
        if (!seller || seller.length === 0) {
          return '-';
        }
        return (
          <div>
            <div>{`${seller[0].profile?.name}${
              seller[0].profile?.nickname ? `(${seller[0].profile?.nickname})` : ''
            }`}</div>
            <div>
              {seller[0].profile?.contract?.licensePhoto && (
                <PhotoView src={cdnImageUrl(seller[0].profile?.contract?.licensePhoto)}>
                  <Button>신분증</Button>
                </PhotoView>
              )}
            </div>
            <div>
              {seller[0].profile?.contract?.bankAccountPhoto && (
                <PhotoView src={cdnImageUrl(seller[0].profile?.contract?.bankAccountPhoto)}>
                  <Button>통장</Button>
                </PhotoView>
              )}
            </div>
          </div>
        );
      },
    },
    {
      title: '상품명',
      dataIndex: 'productDetail',
      render: (product) => {
        if (!product || product.length === 0) {
          return '-';
        }
        return (
          <Popover content={product[0].title || product[0].name}>
            <div style={{ width: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {product[0].title || product[0].name}
            </div>
          </Popover>
        );
      },
    },
    {
      title: '구매옵션',
      dataIndex: 'purchases',
      render: (purchases) => {
        return (
          <div>{`${(purchases || [])
            .map((purchase: any) => {
              let optionName = '';
              if (purchase.option && purchase.option.length > 0) {
                optionName = `${purchase.option.join(' ')}`;
              }
              if (Array.isArray(purchase.name)) {
                return `${purchase.name.join('/')}(${purchase.count})`;
              }
              return `${optionName}${purchase.name}(${purchase.count})`;
            })
            .join('\n')}`}</div>
        );
      },
    },
    {
      title: '수강증',
      dataIndex: 'classInvoice',
      render: (classInvoice) => {
        if (!classInvoice || classInvoice.length === 0 || classInvoice[0]._id === null) {
          return '-';
        }
        return (
          <Button
            onClick={() => {
              classInvoiceModalStore.open({
                classId: classInvoice[0].programId,
                classInvoiceId: classInvoice[0]._id,
                mode: 'view',
              });
            }}>
            수강증 보기
          </Button>
        );
      },
      width: 130,
    },
    {
      title: '결제금액',
      dataIndex: 'payment',
      render: (payment) => `${payment?.amount}원${payment?.point ? `(${payment?.point}P)` : ''}`,
    },
    {
      title: '생성일',
      dataIndex: 'createdAt',
      render: (createdAt) => {
        return moment(createdAt).format('YYYY.MM.DD HH:mm:ss');
      },
    },
  ];

  const paymentStatementSearchQuery = useQuery({
    queryKey: [`admin-search-error-payment-statement`, JSON.stringify(store.searchQuery)],
    queryFn: () => api.searchErrorPaymentStatements(store.searchQuery),
  });

  useEffect(() => {
    const searchResult = paymentStatementSearchQuery.data?.data;
    if (searchResult) {
      store.setPaymentStatemetsContainer(searchResult);
    }
  }, [paymentStatementSearchQuery.data?.data]);

  const changePaymentStatusToCancelMutation = useMutation({
    mutationFn: commonApi.changePaymentStatusToCancelFromReady,
    onSuccess: async () => {
      paymentStatementSearchQuery.refetch();
      dialog.open({ type: 'ok', title: '알림', content: '결제대기를 취소로 변경했습니다' });
    },
    onError: async (e) => {
      dialog.open({ type: 'ok', title: '오류', content: JSON.stringify(e) });
    },
  });

  const onChangeStartDate: DatePickerProps['onChange'] = (date) => {
    store.setStartDate(localTimeToUtcISOString(date ? new Date(date.toISOString()) : new Date(), { timeToZero: true }));
  };

  const onChangeEndDate: DatePickerProps['onChange'] = (date) => {
    store.setEndDate(localTimeToUtcISOString(date ? new Date(date.toISOString()) : new Date(), { timeToZero: true }));
  };

  return (
    <div className="dashboard">
      <div className="page-header">
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div>결제내역 오류 관리</div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <div>
            <Button
              style={{ borderColor: store.searchQuery.dateType === 'payment.paidAt' ? 'blue' : '#d9d9d9' }}
              onClick={() => {
                store.updateSearchQuery({ dateType: 'payment.paidAt' });
              }}>
              결제일
            </Button>
          </div>
          <div>
            <Button
              style={{ borderColor: store.searchQuery.dateType === 'createdAt' ? 'blue' : '#d9d9d9' }}
              onClick={() => {
                store.updateSearchQuery({ dateType: 'createdAt' });
              }}>
              생성일
            </Button>
          </div>
          <div>
            <Button
              style={{ borderColor: store.searchQuery.dateType === 'updatedAt' ? 'blue' : '#d9d9d9' }}
              onClick={() => {
                store.updateSearchQuery({ dateType: 'updatedAt' });
              }}>
              수정일
            </Button>
          </div>
          <div>
            <Button
              style={{ borderColor: store.searchQuery.dateType === 'plannedDates' ? 'blue' : '#d9d9d9' }}
              onClick={() => {
                store.updateSearchQuery({ dateType: 'plannedDates' });
              }}>
              수업일
            </Button>
          </div>
          <div style={{ marginLeft: '10px' }}>
            <DatePicker
              onChange={onChangeStartDate}
              value={dayjs(utcTimcToLocalISOString(store.searchQuery.startDate as any))}
            />
          </div>
          <div style={{ marginLeft: '8px' }}>
            <DatePicker
              onChange={onChangeEndDate}
              value={dayjs(utcTimcToLocalISOString(store.searchQuery.endDate as any))}
            />
          </div>
        </div>
      </div>
      <div className="page-content">
        <div style={{ display: 'flex', marginBottom: '10px', justifyContent: 'space-between' }}>
          <div>
            <Select
              defaultValue={store.searchQuery.saleType || 'all'}
              style={{ width: '120px', marginRight: '10px' }}
              onChange={(value) => {
                store.updateSearchQuery({ saleType: value });
              }}
              options={[
                { value: 'all', label: '전체' },
                { value: 'commerce', label: '커머스' },
                { value: 'class', label: '클래스' },
              ]}
            />
            <Input
              ref={searchInputRef}
              style={{ width: '240px', marginRight: '10px' }}
              value={searchKeyword}
              placeholder={'검색어 입력 후 엔터'}
              onChange={(e) => {
                setSearchKeyword(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.code === 'Enter' && searchKeyword) {
                  store.updateSearchQuery({ keyword: searchKeyword });
                }
              }}
            />
            <Button
              style={{ marginRight: '10px' }}
              onClick={() => {
                setSearchKeyword(undefined);
                store.resetSearchQuery();
              }}>
              검색 초기화
            </Button>
          </div>
          <div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div>
                <Button
                  style={{ marginLeft: '10px' }}
                  onClick={() => {
                    changePaymentStatusToCancelMutation.mutate();
                  }}>
                  결제 대기를 오류로 변경
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Table
          columns={TABLE_COLUMNS}
          scroll={{ x: 'max-content', y: 'calc(100dvh - 280px)' }}
          style={{ whiteSpace: 'pre-wrap' }}
          rowKey={(record, index) => record?._id || `${index}`}
          dataSource={store.searchResultContainer.contents}
          pagination={{
            current: (store.searchResultContainer.page || 0) + 1,
            pageSize: store.searchResultContainer.size || 20,
            total: store.searchResultContainer.total,
          }}
          loading={paymentStatementSearchQuery.isLoading}
          onChange={(e) => {
            store.updateSearchQuery({ page: (e.current || 2) - 1, size: e.pageSize || 20 });
          }}
        />
      </div>
    </div>
  );
};

export default SystemPaymentsPage;
