import React from 'react';
import { MainFeedCellProps } from '.';
import { cdnImageUrl } from '@utils/image';
import { actionStringFromItem } from '@utils/string';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';

const ButtonCategory: React.FC<{ item: any }> = (props) => {
  const { item } = props;
  const dialog = useOkCancelDialog();
  return (
    <button
      className="link-button"
      style={{ alignItems: 'center', width: '64px' }}
      onClick={() => {
        dialog.open({
          type: 'ok',
          confirmColor: '#FF3D8F',
          title: '이동 정보',
          content: actionStringFromItem(item),
        });
      }}>
      <img
        src={cdnImageUrl(item.imageUrlTemp || item.imageUrl)}
        style={{ width: '48px', height: '48px', borderRadius: 0 }}
      />
      <div
        style={{
          alignItems: 'center',
          marginLeft: -1,
          height: '15px',
          justifyContent: 'flex-start',
        }}>
        <div style={{ fontSize: '12px', color: '#424242' }}>{item.categoryTitle}</div>
      </div>
    </button>
  );
};

export const CategoryCell: React.FC<MainFeedCellProps> = (props) => {
  const { data } = props;

  if (!data || data.length === 0) {
    return <div className="empty-category-cell"></div>;
  }

  return (
    <div
      id="category-cell"
      style={{
        display: 'flex',
        justifyContent: 'space-around',
        width: '100%',
        gap: '20px 8px',
        flexWrap: 'wrap',
        padding: '0 16px',
      }}>
      {data.map((category: any, index: number) => {
        return <ButtonCategory key={`category-${index}`} item={category}></ButtonCategory>;
      })}
    </div>
  );
};

export default CategoryCell;
