import { NoticeModel } from '@models/notification';

interface TransNoticeBodyProps {
  data: NoticeModel;
  isNew: boolean;
  rawData: SubmitData;
}
interface SubmitData {
  title: string;
  content: string;
  targets: ('app' | 'seller' | 'partner')[];
  options: string[];
  priority: number;
}

const transNoticeBody = ({ data, isNew, rawData }: TransNoticeBodyProps) => {
  const isPopup = rawData.options.includes('popup');
  const priority = Number(rawData.priority) || 0;

  if (isNew) {
    return {
      title: rawData.title,
      content: rawData.content,
      targets: rawData.targets,
      popup: isPopup,
      priority: priority,
    };
  } else {
    const body: Partial<NoticeModel> = {};

    for (const [key, value] of Object.entries(rawData)) {
      if (key === 'options') {
        if (data.popup !== isPopup) body.popup = isPopup;
      } else if (key === 'priority') {
        body[key] = priority;
      } else if (key === 'targets') {
        if (JSON.stringify(data.targets) !== JSON.stringify(value)) body.targets = value;
      } else if (data[key as keyof NoticeModel] !== value) {
        body[key as keyof NoticeModel] = value;
      }
    }

    return body;
  }
};

export default transNoticeBody;
