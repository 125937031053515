import * as appApi from '@apis/app-feed';
import * as statisticsApi from '@apis/statistics';
import { closestCenter, DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FeedData, FeedDataItem } from '@models/feed';
import { Button, Input, Switch } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

interface SortablePopularKeywordsItemProps {
  onDeleteItem?: (event: any) => void;
  onChange?: (item: FeedDataItem) => void;
  id: any;
  item: FeedDataItem;
}

export const SortablePopularKeywordsItem: React.FC<SortablePopularKeywordsItemProps> = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const { onChange, item } = props;

  return (
    <div ref={setNodeRef} style={{ ...style }} {...attributes} {...listeners}>
      <div style={{ display: 'flex', marginBottom: '8px' }}>
        <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
          <Input
            value={item._id}
            onChange={(e) => {
              onChange && onChange({ ...item, _id: e.target.value });
            }}></Input>
          {`(${item.count})`}
        </div>
      </div>
    </div>
  );
};

export const PopularKeywordsEdit: React.FC<{
  clear?: boolean;
  data: Partial<FeedData>;
  onChange?: (data: Partial<FeedData>) => void;
}> = (props) => {
  const [changedContents, setChangedContents] = useState<FeedDataItem[]>();
  const [changedTitle, setChangedTitle] = useState<string>();
  const [changedSync, setChangedSync] = useState<boolean>();
  const [period, setPeriod] = useState<number>(7);

  const { data } = props;
  const contents = changedContents || data.data || [];
  const sync = changedSync === undefined ? data.sync : changedSync;

  const sortableSensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 10 },
    }),
  );

  useEffect(() => {
    if (props.clear) {
      setChangedContents(undefined);
      setChangedTitle(undefined);
      setChangedSync(undefined);
    }
  }, [props.clear]);

  useEffect(() => {
    props.onChange &&
      props.onChange({
        ...data,
        data: changedContents || data.data,
        title: changedTitle || data.title,
        sync: sync,
      });
  }, [changedContents, changedTitle, changedSync]);

  const onSortEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      if (contents) {
        const oldIndex = contents.findIndex((item) => item._id === active.id);
        const newIndex = contents.findIndex((item) => item._id === over?.id);
        const newData = arrayMove(contents, oldIndex, newIndex);
        setChangedContents(newData);
      }
    }
  };

  const getPopularKeywords = (startDate: Date, endDate: Date) => {
    statisticsApi.getSearchTextRank(startDate.toISOString(), endDate.toISOString()).then((response) => {
      const keywords = response.data;
      if (keywords && keywords.length > 0) {
        setChangedContents(keywords.slice(0, 6));
      }
    });
  };

  const getAppPopularKeywords = () => {
    appApi.getSearchPopularKeywords().then((response: any) => {
      const keywords = response.data;
      if (keywords && keywords.length > 0) {
        setChangedContents(keywords.slice(0, 6));
      }
    });
  };

  return (
    <div>
      <div style={{ marginBottom: '12px' }}>
        <div style={{ fontWeight: 'bold' }}>타이틀</div>
        <Input
          value={data.title}
          placeholder={'실시간 인기 검색어 TOP 6'}
          onChange={(e) => {
            setChangedTitle(e.target.value);
          }}></Input>
        <div style={{ display: 'flex', gap: '4px', marginTop: '8px' }}>
          <div style={{ fontWeight: 'bold' }}>검색창과 동기화</div>
          <Switch
            checked={sync}
            onChange={(checked) => {
              setChangedSync(checked);
            }}></Switch>
        </div>
        {!sync && (
          <div style={{ display: 'flex', marginTop: '12px', gap: '4px' }}>
            최근
            <Input
              style={{ width: '120px' }}
              value={period}
              onChange={(e) => {
                setPeriod(Number(e.target.value) || 0);
              }}></Input>
            일
            <Button
              onClick={() => {
                getPopularKeywords(moment().subtract(period, 'days').toDate(), new Date());
              }}>
              검색어 불러오기
            </Button>
            <Button
              onClick={() => {
                getAppPopularKeywords();
              }}>
              검색입력창 검색어 불러오기
            </Button>
          </div>
        )}
      </div>
      {!sync && (
        <DndContext sensors={sortableSensors} collisionDetection={closestCenter} onDragEnd={onSortEnd}>
          <SortableContext
            items={contents.map((item) => {
              return { ...item, id: item._id || '' };
            })}
            strategy={verticalListSortingStrategy}>
            <div style={{ maxWidth: '600px' }}>
              {contents.map((item, index) => {
                return (
                  <SortablePopularKeywordsItem
                    item={item}
                    id={item._id}
                    onDeleteItem={() => {
                      setChangedContents((prev) => {
                        const newValues = [...(prev || contents)];
                        newValues.splice(index, 1);
                        return newValues;
                      });
                    }}
                    onChange={(item) => {
                      setChangedContents((prev) => {
                        const newValues = [...(prev || contents)];
                        newValues[index] = item;
                        return newValues;
                      });
                    }}></SortablePopularKeywordsItem>
                );
              })}
            </div>
          </SortableContext>
        </DndContext>
      )}
    </div>
  );
};

export default PopularKeywordsEdit;
