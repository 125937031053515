import { ProductActivityType, ProductModel, ProductStatus } from '@models/product';
import { getAddressText, getBadges } from '@utils/dataTransform';
import moment from 'moment';
import React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import { cdnImageUrl } from '@utils/image';
import FavoriteButton from './FavoriteButton';
import CreditCardIcon from '@assets/images/app/card.png';

interface ClassListItemProps {
  item?: ProductModel;
  isFavorited?: boolean;
  style?: any;
  hideBorderBottom?: boolean;
  hideBadges?: boolean;
  onClick?: (event: any) => void;
  onChangeFavorite?: (value: boolean) => void;
  index?: number;
}

const IMAGE_WIDTH = '80px';

export const ClassListItem: React.FC<ClassListItemProps> = (props) => {
  const item = props.item;

  const _renderItemImage = (imageUrl: string) => {
    const fixImageUrl = imageUrl;

    return (
      <div
        style={{
          width: IMAGE_WIDTH,
          height: IMAGE_WIDTH,
          borderRadius: '3px',
          position: 'relative',
        }}>
        <img
          style={{
            width: IMAGE_WIDTH,
            height: IMAGE_WIDTH,
            borderRadius: '8px',
            objectFit: 'cover',
          }}
          src={cdnImageUrl(fixImageUrl)}
        />
        <div
          style={{
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            position: 'absolute',
            width: IMAGE_WIDTH,
            height: IMAGE_WIDTH,
            border: '1px solid black',
            opacity: 0.04,
            borderRadius: '8px',
          }}
        />
      </div>
    );
  };

  const addressText = getAddressText({
    activityType: item?.activityType,
    badges: item?.badges,
    availableArea: item?.location,
  });

  const mainImageUrl = item?.thumbnailImage as string;
  const badgeList = item?.badges || [];

  let isNew = false;
  const firstApprovedAt = props && item && item?.approvedAt ? item?.approvedAt : new Date(1990, 0, 1);
  const monthCheck = moment(new Date()).diff(moment(firstApprovedAt), 'day');
  if (monthCheck <= 30) {
    isNew = true;
  }

  const activeBadgeList = getBadges({
    badges: badgeList,
    isNew,
    isClosed: item?.status === ProductStatus.CLOSE,
    applyGroupDiscount: item?.applyGroupDiscount,
  });

  const isFirstIndex: boolean = props.index !== undefined && props.index < 1;

  const amount = item?.fee || 0;
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {!isFirstIndex && !props.hideBorderBottom && (
        <div
          style={{
            padding: '0 16px',
            width: '100%',
          }}>
          <div
            style={{
              width: '100%',
              height: '1px',
              backgroundColor: '#f5f7fb',
            }}
          />
        </div>
      )}
      <div
        onClick={props.onClick}
        style={{
          padding: '16px',
          cursor: 'pointer',
          width: '100%',
          height: '100%',
          ...props.style,
        }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
          }}>
          {mainImageUrl && _renderItemImage(mainImageUrl)}
          <div
            style={{
              marginLeft: '12px',
              marginRight: '12px',
              width: '100%',
              paddingTop: 0,
              paddingBottom: 0,
            }}>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                {item?.activityType && (
                  <>
                    <div
                      style={{
                        fontSize: '12px',
                        textAlign: 'center',
                        color: '#808387',
                        fontWeight: 'bold',
                        flexShrink: 0,
                      }}>
                      {item?.activityType === ProductActivityType.ONLINE
                        ? '비대면'
                        : item?.activityType === ProductActivityType.STUDIO
                          ? '스튜디오'
                          : '가정방문'}
                    </div>
                    <div
                      style={{
                        width: '1px',
                        height: '8px',
                        backgroundColor: '#d8d8d8',
                        marginRight: '6px',
                        marginLeft: '6px',
                      }}
                    />
                  </>
                )}
              </div>
              {addressText && addressText !== '-' && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexShrink: 0,
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}>
                    <div>
                      <div
                        style={{
                          fontSize: '12px',
                          textAlign: 'center',
                          color: '#808387',
                        }}>
                        {addressText}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: '1px',
                      height: '8px',
                      backgroundColor: '#d8d8d8',
                      marginRight: '6px',
                      marginLeft: '6px',
                    }}
                  />
                </div>
              )}
              <div
                style={{
                  fontSize: '12px',
                  color: '#808387',
                  wordBreak: 'break-word',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  width: `${428 - 290}px`,
                }}>
                {item && item?.sellerName && item?.sellerName !== '' ? item?.sellerName : ''}
              </div>
            </div>
            <div
              style={{
                marginTop: '6px',
                fontSize: '14px',
                color: '#424242',
                lineHeight: '19px',
                maxHeight: '38px',
              }}>
              <LinesEllipsis text={item?.title} maxLine={2} ellipsis="..." trimRight></LinesEllipsis>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '6px',
                height: '18px',
                alignItems: 'center',
              }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {item && item?.showRegularTime ? (
                  <div
                    style={{
                      fontWeight: 'bold',
                      fontSize: '13px',
                      color: '#242424',
                    }}>
                    {(
                      amount * (item && item?.regularTime && item?.regularTime > 0 ? item?.regularTime : 1)
                    ).toLocaleString()}
                    원{item && item?.regularTime && item?.regularTime > 0 ? ` / ${item?.regularTime}회` : ''}
                  </div>
                ) : (
                  <div
                    style={{
                      fontWeight: 'bold',
                      fontSize: '13px',
                      color: '#242424',
                    }}>
                    {amount.toLocaleString()}원
                  </div>
                )}
              </div>
              {item?.discount && item.discount.replace(/[^0-9]/g, '') !== '' && parseInt(item.discount) > 0 && (
                <div
                  style={{
                    color: '#F03F45',
                    fontFamily: '"SF Pro"',
                    fontSize: '13px',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    marginLeft: '4px',
                  }}>
                  {item?.discount}
                </div>
              )}
              {item?.saleType === 'commerce' && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '4px' }}>
                  <img style={{ display: 'inline-block' }} src={CreditCardIcon} />
                  <div
                    style={{
                      color: '#7950EF',
                      fontFamily: 'Monoplex KR',
                      fontSize: '13px',
                      fontStyle: 'italic',
                      fontWeight: 700,
                      lineHeight: 'normal',
                      letterSpacing: '-1px',
                    }}>
                    바로결제
                  </div>
                </div>
              )}
              {activeBadgeList?.length > 0 && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginLeft: '6px',
                  }}>
                  {activeBadgeList.map((item) => {
                    return (
                      <div
                        key={`key-${item?.type}`}
                        style={{
                          display: 'flex',
                          marginRight: '4px',
                          paddingLeft: '4px',
                          paddingRight: '4px',
                          height: '18px',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: '4px',
                          backgroundColor: item?.backgroundColor,
                        }}>
                        <div
                          style={{
                            fontSize: '11px',
                            color: item?.color,
                            fontWeight: 500,
                          }}>
                          {item?.value}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          <FavoriteButton productId={item?._id} saleType={'class'} />
        </div>
      </div>
    </div>
  );
};
