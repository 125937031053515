import * as api from '@apis/feeds';
import ChatIntroWarningEdit from '@components/feed/ChatIntroWarningEdit';
import LoungeCategoriesEdit from '@components/feed/LoungeCategoriesEdit';
import SearchPlaceholderEdit from '@components/feed/SearchPlaceholderEdit';
import SearchPopularKeywordsEdit from '@components/feed/SearchPopularKeywordsEdit';
import SearchRecommendKeywordsEdit from '@components/feed/SearchRecommendKeywordsEdit';
import { useAuthentication } from '@hooks/authentication';
import { Feed } from '@models/feed';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button } from 'antd';
import React, { useState } from 'react';

export const FeedElementsPage: React.FC = () => {
  const [changedFeedElementsData, setChangedFeedElementsData] = useState<{
    searchPlaceholder?: Feed;
    popularKeywords?: Feed;
    recommendKeywords?: Feed;
    chatIntroWarnings?: Feed;
    loungeCurationCategories?: Feed;
  }>();
  const dialog = useOkCancelDialog();

  useAuthentication();

  const feedEtcQuery = useQuery({
    queryKey: [`feed-elements`],
    queryFn: () => api.getFeedElements(),
  });

  const feedEtc = feedEtcQuery.data?.data;

  const updateMainFeedMutation = useMutation({
    mutationFn: api.updateFeedElements,
    onSuccess: async () => {
      dialog.open({ type: 'ok', title: '알림', content: '저장했습니다' });
    },
    onError: async (e) => {
      dialog.open({ type: 'ok', title: '오류', content: JSON.stringify(e) });
    },
  });

  return (
    <div className="dashboard" style={{ position: 'relative' }}>
      <div className="page-header">
        <div>피드 표시 항목 관리</div>
      </div>
      <div style={{ display: 'flex' }}>
        <div className="page-content" style={{ width: '100%', height: 'calc(100vh - 80px)', overflow: 'auto' }}>
          <div>
            <Button
              onClick={() => {
                if (changedFeedElementsData) {
                  updateMainFeedMutation.mutate(changedFeedElementsData);
                }
              }}>
              저장
            </Button>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>
              <div style={{ marginTop: '12px' }}>
                <div style={{ fontWeight: 'bold', fontSize: '16px' }}>검색</div>
                <SearchPlaceholderEdit
                  data={changedFeedElementsData?.searchPlaceholder || feedEtc?.searchPlaceholder}
                  onChange={(data) => {
                    setChangedFeedElementsData((prev) => {
                      return { ...prev, searchPlaceholder: data as Feed };
                    });
                  }}></SearchPlaceholderEdit>
              </div>
              <div style={{ marginTop: '48px' }}>
                <div style={{ fontWeight: 'bold', fontSize: '16px' }}>인기 검색어 관리</div>
                <SearchPopularKeywordsEdit
                  data={changedFeedElementsData?.popularKeywords || feedEtc?.popularKeywords}
                  onChange={(data) => {
                    setChangedFeedElementsData((prev) => {
                      return { ...prev, popularKeywords: data as Feed };
                    });
                  }}></SearchPopularKeywordsEdit>
              </div>
              <div style={{ marginTop: '48px' }}>
                <div style={{ fontWeight: 'bold', fontSize: '16px' }}>추천 검색어 관리</div>
                <SearchRecommendKeywordsEdit
                  data={changedFeedElementsData?.recommendKeywords || feedEtc?.recommendKeywords}
                  onChange={(data) => {
                    setChangedFeedElementsData((prev) => {
                      return { ...prev, recommendKeywords: data as Feed };
                    });
                  }}></SearchRecommendKeywordsEdit>
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <div style={{ marginTop: '12px' }}>
                <div style={{ fontWeight: 'bold', fontSize: '16px' }}>채팅</div>
                <ChatIntroWarningEdit
                  data={changedFeedElementsData?.chatIntroWarnings || feedEtc?.chatIntroWarnings}
                  onChange={(data) => {
                    setChangedFeedElementsData((prev) => {
                      return { ...prev, chatIntroWarnings: data as Feed };
                    });
                  }}></ChatIntroWarningEdit>
              </div>

              <div style={{ marginTop: '24px' }}>
                <div style={{ fontWeight: 'bold', fontSize: '16px' }}>라운지 주제 관리(콘텐츠 발견)</div>
                <LoungeCategoriesEdit
                  data={changedFeedElementsData?.loungeCurationCategories || feedEtc?.loungeCurationCategories}
                  onChange={(data) => {
                    setChangedFeedElementsData((prev) => {
                      return { ...prev, loungeCurationCategories: data as Feed };
                    });
                  }}></LoungeCategoriesEdit>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedElementsPage;
