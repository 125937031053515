import { AttachImage, ClassAvailableArea, ClassBadge } from './class';
import HomeIcon from '@assets/images/app/icon-detail-home.png';
import OfficeIcon from '@assets/images/app/icon-detail-office.png';
import OnlineIcon from '@assets/images/app/icon-detail-online.png';
import ExperienceIcon from '@assets/images/app/icon-detail-experience.png';
import { NaverCommerceProduct } from './sales-channels';

interface StatusText {
  text: string;
  textColor: string;
  backgroundColor: string;
}

export enum LegacyProductStatus {
  ALL = 'all',
  OPEN = 'open',
  CLOSE = 'close',
  HIDE = 'hide',
  DENIED = 'denied',
  OPEN_REQUEST = 'open_request',
  EDIT = 'edit',
}
export enum ProductStatus {
  OPEN = 'open',
  CLOSE = 'close',
  PRIVATE = 'private',
}
export enum ProductApproveStatus {
  ACCEPTED = 'accepted',
  REQUESTED = 'requested',
  DENIED = 'denied',
  NOT_READY = 'not_ready',
}

export enum ProductActivityType {
  HOME = 'home',
  STUDIO = 'studio',
  EXPERIENTIAL = 'experiential',
  ONLINE = 'online',
  DIGITAL = 'digital',
  DELIVERY = 'delivery',
  TICKET = 'ticket',
}

export const PRODUCT_STATUS_TEXT: Record<ProductStatus, StatusText> = {
  open: { text: '판매중', textColor: 'white', backgroundColor: 'green' },
  close: { text: '판매중지', textColor: 'white', backgroundColor: '#aaaaaa' },
  private: { text: '프라이빗', textColor: 'white', backgroundColor: '#e8ac07' },
};

export const PRODUCT_APPROVE_STATUS_TEXT: Record<ProductApproveStatus, StatusText> = {
  accepted: { text: '승인 완료', textColor: 'white', backgroundColor: 'green' },
  requested: { text: '승인 요청', textColor: 'white', backgroundColor: 'orange' },
  denied: { text: '승인 반려', textColor: 'white', backgroundColor: 'red' },
  not_ready: { text: '수정중', textColor: 'white', backgroundColor: 'blue' },
};

export const PRODUCT_ACTIVITY_TYPE_TEXT: { [key: string]: { title: string; description: string; icon: string } } = {
  [ProductActivityType.STUDIO]: {
    title: '스튜디오',
    description: '튜터님이 운영하는 공간으로 방문하는 수업입니다.',
    icon: OfficeIcon,
  },
  [ProductActivityType.EXPERIENTIAL]: { title: '체험수업', description: '', icon: ExperienceIcon },
  [ProductActivityType.HOME]: {
    title: '가정방문',
    description: '튜터님이 집으로 오시는 방문수업입니다.',
    icon: HomeIcon,
  },
  [ProductActivityType.ONLINE]: {
    title: '비대면',
    description: '튜터님과 협의한 시간에 실시간 비대면으로 진행되는 수업입니다.',
    icon: OnlineIcon,
  },
  [ProductActivityType.DIGITAL]: {
    title: '디지털상품',
    description: 'PDF 등의 디지털 파일을 구매하는 상품입니다.',
    icon: OnlineIcon,
  },
  [ProductActivityType.TICKET]: { title: '티켓상품', description: '티켓 상품입니다.', icon: OnlineIcon },
  [ProductActivityType.DELIVERY]: {
    title: '배송상품',
    description: '기입하신 주소지로 배송되는 상품입니다.',
    icon: OnlineIcon,
  },
};

interface BottomTextBanner {
  show?: boolean;
  iconTemp?: string;
  icon?: string;
  highlightText?: string;
  normalText?: string;
}

export interface Concept {
  conceptTitle?: string;
  conceptData?: string;
  images?: string[] | AttachImage[];
  imagesTemp?: string[];
}

export interface CommerceFixedOptionItem {
  title: string;
  fee: number;
  essential: boolean;
}

export interface QuestionOption {
  question: string;
}

export interface QuestionOptionEditModel extends QuestionOption {
  answer?: string;
}

export interface ProductOption {
  _id?: string;
  productId?: string;
  step: string[];
  type: string; // direct / sequential
  fee?: number;
  inventory?: number;
  index?: number;
  yyyymmdd?: string;
  date?: Date;
  createdAt?: Date;
  updatedAt?: Date;
}

export class DiscountInfo {
  discountStartDate?: Date;
  discountEndDate?: Date;
  discountedFee?: number;
}

export class SalesChannelInfo {
  naverStore?: {
    channelProductNo: string;
    originProductNo?: string;
  };
  kakaoReservation?: any;
}

export interface ProductModel {
  _id: string;
  sellerName: string;
  sellerId: string;
  sellerProfileImage?: string;
  sellerIntroduction?: string;
  title: string;
  saleType: string;
  detailType?: string;
  activityType: ProductActivityType;
  thumbnailImage: string;
  closeText?: string;
  discount?: string;
  originFee: number;
  fee: number;
  classDuration?: number;
  totalNumber: number;
  shippingProduct?: boolean;
  showRegularTime?: boolean;
  regularTime?: number;
  tags: string[];
  regionText?: string;
  badges: ClassBadge[];
  location: ClassAvailableArea;
  classIntroductionImage?: string;
  classIntroductionImageTemp?: string;
  detailImages: string[];
  detailImagesTemp?: string[];
  detailBodyImages?: string[];
  detailBodyImagesTemp?: string[];
  detailHtml: string;
  details: {
    introductions?: { title?: string; description?: string }[];
    concepts?: Concept[];
    preparationText?: string;
    recommendText?: string;
  };
  refundPolicyText?: string;
  shippingPolicyText?: string;
  classTermsText?: string;
  refundPolicies?: string[];
  applyGroupDiscount?: boolean;
  ageMin: number;
  ageMax: number;
  studentsMin?: number;
  studentsMax?: number;
  categories: string[];
  bottomTextBanner?: BottomTextBanner;
  display?: boolean;
  soldout?: boolean;
  isClosed?: boolean;
  totalInventory?: number;
  shippingFee?: number;
  memoRequired?: boolean;
  memoComment?: string;
  linkedSalesChannel?: SalesChannelInfo;
  sellerComment?: string;
  sellerShortComment?: string;
  status?: LegacyProductStatus | ProductStatus;
  approveStatus?: ProductApproveStatus;
  optionType?: string; // direct / sequential / sequential_with_direct
  purchaseQuantityOptions: CommerceFixedOptionItem[];
  productOptions?: ProductOption[];
  optionLabels?: string[];
  questionOptions?: QuestionOption[];
  meta: any;
  priority?: number;
  applyPeriodDiscount?: boolean;
  discountInfo?: DiscountInfo;
  minStudents?: number;
  maxStudents?: number;
  approvedAt: Date;
  createdAt: Date;
  updatedAt: Date;
  deniedReason: string;
  isTemp?: boolean;
  favoritedCount?: number;
  naverStoreProduct?: NaverCommerceProduct;
  autoCloseEnabled?: boolean;
  autoCloseDuration?: number;
  appOnly?: boolean;
}

export interface ProductSearchQuery {
  keyword?: string;
  saleType?: string;
  sort?: string;
  activityType?: string;
  category?: string;
  age?: string;
  region?: string;
  status?: string;
  displayStatus?: string;
  stockStatus?: string;
  page?: number;
  size?: number;
  sellerId?: string;
}

export interface ReviewData {
  rate1: number;
  rate2: number;
  rate3: number;
  rate4: number;
  comment: string;
}

export interface CreateReviewBody extends ReviewData {
  paymentStatementId: string;
  productId: string;
}

export interface UpdateReviewBody extends ReviewData {
  reviewId: string;
  paymentStatementId: string;
  productId: string;
}

export interface ProductInventoryClosingLog {
  _id: string;
  type: string; // naver, comerce
  sellerId: string;
  sellerName: string;
  productName: string;
  origin: any;
  new: any;
  deletedOptions: any;
  updateResult?: any;
  createdAt: Date;
}
