import React, { useState } from 'react';
import ArrowDownIcon from '@assets/images/app/icon-arr-down.png';
import { MainFeedCellProps } from '.';
import { cdnImageUrl } from '@utils/image';
import { actionStringFromItem } from '@utils/string';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';

export const ThemeCell: React.FC<MainFeedCellProps> = (props) => {
  const [listLimitCount, setListLimitCount] = useState<number>(6);
  const dialog = useOkCancelDialog();
  return (
    <div
      id="theme-cell"
      style={{
        width: '100%',
        backgroundColor: 'white',
        padding: '0 16px',
      }}>
      <div>
        <div
          style={{
            marginBottom: '20px',
            backgroundColor: 'white',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <div
            id="theme-cell-title"
            style={{
              fontSize: '19px',
              fontWeight: 'bold',
              color: '#242424',
            }}>
            {props.title || '테마 클래스 추천'}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginTop: 0,
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}>
          {props.data.slice(0, listLimitCount).map((item: any, index: number) => {
            return (
              <div
                id="theme-cell-item"
                key={`theme-data-${index}`}
                style={{
                  width: '50%',
                  height: '80px',
                  marginBottom: '8px',
                  paddingLeft: '4px',
                  paddingRight: '4px',
                }}>
                <div
                  style={{
                    position: 'relative',
                    cursor: 'pointer',
                    overflow: 'hidden',
                    borderRadius: '10px',
                    height: '80px',
                  }}
                  onClick={() => {
                    dialog.open({
                      type: 'ok',
                      confirmColor: '#FF3D8F',
                      title: '이동 정보',
                      content: actionStringFromItem(item),
                    });
                  }}>
                  <img
                    src={cdnImageUrl(item.mainImageUrlTemp || item.mainImageUrl)}
                    style={{
                      width: '100%',
                      height: '80px',
                      objectFit: 'cover',
                      borderRadius: '10px',
                    }}
                  />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                      height: '80px',
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0,
                      backgroundColor: 'rgba(0,0,0,0.2)',
                      borderRadius: '10px',
                    }}>
                    <div
                      style={{
                        color: 'white',
                        fontSize: '14px',
                        lineHeight: '20px',
                        fontWeight: '700',
                        alignSelf: 'center',
                        whiteSpace: 'pre-wrap',
                        textAlign: 'center',
                      }}>
                      {item.mainText}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {props.data.length > listLimitCount && (
          <div
            style={{
              display: 'flex',
              height: '40px',
              marginTop: '12px',
              marginBottom: 0,
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid #e8eaed',
              borderRadius: '8px',
              cursor: 'pointer',
            }}
            onClick={() => {
              setListLimitCount(props.data.length);
            }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <div
                style={{
                  alignSelf: 'center',
                  fontSize: '14px',
                  color: '#424242',
                  marginRight: '4px',
                }}>
                테마 더보기
              </div>
              <img src={ArrowDownIcon} style={{ width: '12px', height: '12px' }} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ThemeCell;
