import { User } from './auth';
import { ProductModel } from './product';

export enum PaymentStatementReviewStatus {
  OPEN = 'open',
  HIDDEN = 'hidden',
}

export interface PaymentStatementReview {
  _id: string;
  rootId: string;
  productId: string;
  classInvoiceId: string;
  paymentStatementId: string;
  userId: string;
  comment: string;
  rates: { rate1: number; rate2: number; rate3: number; rate4: number };
  step: number;
  createdAt: Date;
  updatedAt: Date;
  product?: ProductModel;
  user?: User;
  status?: PaymentStatementReviewStatus;
}
