import HeartOffIcon from '@assets/images/app/icon-heart-dark-20.png';
import HeartOnIcon from '@assets/images/app/icon-heart-on-24-new.png';
import React from 'react';

interface FavoriteButtonProps {
  productId?: string;
  saleType?: string;
  style?: any;
}

export const FavoriteButton: React.FC<FavoriteButtonProps> = (props) => {
  const isFavorite = false;
  const imgSource = isFavorite ? HeartOnIcon : HeartOffIcon;

  return (
    <button
      className="link-button"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '20px',
        height: '20px',
        ...props.style,
      }}>
      <img style={{ width: '20px' }} src={imgSource} />
    </button>
  );
};

export default FavoriteButton;
