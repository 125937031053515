import { PagenatedResponse, SearchPagenateQuery } from '@models/common';
import { LegacyProductStatus, ProductApproveStatus, ProductModel, ProductStatus } from '@models/product';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const PAGE_SIZE = 20;

interface ProductsContainer {
  contents: ProductModel[];
  page: number;
  size: number;
  total: number;
  hasNext: boolean;
}

export interface ProductModelsQuery extends SearchPagenateQuery {
  buyerId?: string;
  paymentStatus?: string;
  dateType?: string;
  saleType?: string;
  status?: LegacyProductStatus | ProductStatus;
  approveStatus?: ProductApproveStatus;
  stockStatus?: string;
  displayStatus?: string;
  sortType?: string;
  appOnly?: boolean;
}

interface ProductsStoreProps {
  searchResultContainer: ProductsContainer;
  searchQuery: ProductModelsQuery;
  searchKeyword?: string;
  editProductId?: string;
  setEditProductId: (productId?: string) => void;
  setSearchKeyword: (keyword: string) => void;
  updateSearchQuery: (query: { [key: string]: any }) => void;
  resetSearchQuery: () => void;
  setProductsContainer: (result: PagenatedResponse<ProductModel>) => void;
}

export const useProductsStore = create(
  persist<ProductsStoreProps>(
    (set) => ({
      searchResultContainer: {
        contents: [] as ProductModel[],
        total: 0,
        page: 0,
        size: 0,
        hasNext: false,
      },
      searchQuery: {
        page: 0,
        size: PAGE_SIZE,
        saleType: 'all',
      },
      editProductId: undefined,
      setEditProductId: (productId?: string) => {
        set({ editProductId: productId });
      },
      searchKeyword: undefined,
      setProductsContainer: (result) => {
        set((state) => {
          const contents = new Array(result.total || PAGE_SIZE);
          contents.splice(result.page * result.size, result.size, ...(result?.contents || []));
          const newValue = { ...state.searchResultContainer, ...result, contents: contents };
          return { ...state, searchResultContainer: newValue };
        });
      },
      setSearchKeyword: (keyword: string) => {
        set((state) => {
          return {
            ...state,
            searchKeyword: keyword,
            searchResultContainer: { ...state.searchResultContainer, size: 0 },
          };
        });
      },
      updateSearchQuery: (query: { [key: string]: any }) => {
        set((state) => {
          return {
            ...state,
            searchQuery: { ...state.searchQuery, ...query },
            searchResultContainer: { ...state.searchResultContainer, size: 0 },
          };
        });
      },
      resetSearchQuery: () => {
        set({
          searchQuery: {
            page: 0,
            size: PAGE_SIZE,
            saleType: 'all',
            keyword: '',
          },
        });
      },
    }),
    {
      name: 'igogo-admin-products',
    },
  ),
);
