import { PagenatedResponse } from '@models/common';
import { ProductSearchQuery } from '@models/product';
import { ProductCollection } from '@models/productCollection';
import { apiClient } from '.';

export const searchProductCollections = async (query?: ProductSearchQuery) => {
  return apiClient.get<PagenatedResponse<ProductCollection>>(`/admin/v1/product-collections`, {
    params: query,
  });
};

export const createProductCollection = async (body: Partial<ProductCollection>) => {
  return apiClient.post(`/admin/v1/product-collections`, body);
};

export const getProductCollection = async (productCollectionId: string) => {
  return apiClient.get<ProductCollection>(`/admin/v1/product-collections/${productCollectionId}`);
};

export const deleteProductCollection = async (productCollectionId: string) => {
  return apiClient.delete(`/admin/v1/product-collections/${productCollectionId}`);
};

export const updateProductCollection = async (formData: FormData) => {
  const productCollectionId = formData.get('productCollectionId');
  return apiClient.put<ProductCollection>(`/admin/v1/product-collections/${productCollectionId}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
