import * as paymentStatementsApi from '@apis/payment-statement';
import * as productOrdersApi from '@apis/product-orders';
import { getSearchPaymentStatementsExcelFile, getCommerceSettlementsExcelFile } from '@apis/payment-statement';
import { useAppSocket } from '@hooks/appSocket';
import { useAuthentication } from '@hooks/authentication';
import {
  PaymentStatementReportStatus,
  PaymentStatementSettlementStatus,
  PaymentStatementWithLogs,
  RefundStatus,
} from '@models/paymentStatement';
import { useClassInvoiceModalStore } from '@stores/classInvoiceModalStore';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { usePaymentStatementModalStore } from '@stores/paymentStatementModalStore';
import { useMutation, useQuery } from '@tanstack/react-query';
import { formatPhoneNumber } from '@utils/dataTransform';
import { localTimeToUtcISOString, utcTimcToLocalISOString } from '@utils/date';
import { getPaymentMethodText } from '@utils/payment';
import { WEB_URL } from '@variables';
import { Button, Checkbox, DatePicker, DatePickerProps, Input, Modal, Popover, Progress, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { ProductOrdersViewQuery, useProductOrdersStore } from '@stores/productOrdersStore';
import { ProductOrderViewModel } from '@models/productOrderView';
import { SalesChannelsType } from '@models/sales-channels';
import { PhotoView } from 'react-photo-view';
import { cdnImageUrl } from '@utils/image';

type ExcelSheet = 'payment' | 'settlement';
export const SettlementsPage: React.FC = () => {
  useAuthentication();
  const appSocket = useAppSocket();
  const store = useProductOrdersStore();
  const dialog = useOkCancelDialog();
  const classInvoiceModalStore = useClassInvoiceModalStore();
  const paymentStatementModalStore = usePaymentStatementModalStore();
  const searchInputRef = useRef<any>();
  const [searchKeyword, setSearchKeyword] = useState<string | undefined>('');
  const [selectedItemIndex, setSelectedItemIndex] = useState<number[]>([]);
  const [checkAll, setCheckAll] = useState<boolean>(false);
  const [showCommerceSettlementModal, setShowCommerceSettlementModal] = useState<boolean>(false);
  const [syncState, setSyncState] = useState<{
    status: string;
    message: string;
    current?: number;
    total?: number;
    app?: number;
    naver?: number;
    kakao?: number;
    inbound?: number;
  }>({ status: 'ready', message: '' });

  useEffect(() => {
    const targets: number[] = [];
    if (checkAll) {
      if (store.searchResultContainer.contents && store.searchResultContainer.contents.length > 0) {
        const { page = 0, size = 0 } = store.searchQuery;
        const maxSize = Math.min((page + 1) * size, store.searchResultContainer.contents.length);

        const currentPageResultList = store.searchResultContainer.contents.slice(page * size, maxSize) || [];
        currentPageResultList.map((paymentStatement, idx) => {
          const commerceTypeCondition =
            paymentStatement.product.saleType === 'commerce' &&
            [RefundStatus.RESERVED, RefundStatus.ACCEPTED, RefundStatus.NONE].includes(
              paymentStatement.statuses.refund || RefundStatus.NONE,
            );
          const classTypeCondition =
            paymentStatement.product.saleType === 'class' && store.searchQuery.saleType === 'class';
          if (commerceTypeCondition || classTypeCondition) {
            targets.push(idx);
          }
        });
        setSelectedItemIndex(targets);
      }
    } else {
      setSelectedItemIndex([]);
    }
  }, [checkAll]);

  const TABLE_COLUMNS: ColumnsType<ProductOrderViewModel> = [
    {
      title: () => {
        const isAllType = store.searchQuery.saleType === 'all';
        return (
          <Checkbox
            onChange={() => {
              setCheckAll((value) => {
                return !value;
              });
            }}
            checked={checkAll}
            disabled={isAllType}></Checkbox>
        );
      },
      render: (data: PaymentStatementWithLogs, _, index) => {
        const checked = selectedItemIndex.includes(index);
        return (
          <Checkbox
            disabled={
              data.product?.saleType === 'commerce' &&
              ![RefundStatus.RESERVED, RefundStatus.ACCEPTED, RefundStatus.NONE].includes(
                data?.statuses?.refund || RefundStatus.NONE,
              )
            }
            checked={checked}
            onChange={() => {
              setSelectedItemIndex((prev) => {
                const newValue = [...prev];
                const isInclude = newValue.includes(index);
                if (isInclude) {
                  const idx = newValue.indexOf(index);
                  newValue.splice(idx, 1);
                } else if (data?._id) {
                  newValue.push(index);
                }
                return newValue;
              });
            }}></Checkbox>
        );
      },
      fixed: 'left',
    },
    {
      title: 'ID',
      dataIndex: '_id',
      render: (_id, record) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(_id);
                toast.dark('결제내역 ID를 클립보드에 복사했습니다', {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  pauseOnFocusLoss: false,
                  draggable: true,
                  progress: undefined,
                  bodyStyle: {
                    color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                    textAlign: 'center',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                  },
                  theme: 'dark',
                });
              }}>
              ID 복사
            </Button>
            <Button
              onClick={() => {
                window.open(`${WEB_URL}/product/${record?.product?.id}`);
              }}>
              상품 바로가기
            </Button>
          </div>
        );
      },
      fixed: 'left',
    },
    {
      title: '채널',
      width: 80,
      render: (record) => {
        if (record?.channel) {
          if ([SalesChannelsType.NAVER_STORE].includes(record.channel)) {
            return '네이버';
          } else if ([SalesChannelsType.KAKAO_RESERVATION].includes(record.channel)) {
            return '카카오';
          } else if ([SalesChannelsType.INBOUND_APP, SalesChannelsType.INBOUND_NAVER].includes(record.channel)) {
            return '인바운드';
          } else {
            return '-';
          }
        }
        return '앱';
      },
      fixed: 'left',
      align: 'center',
    },
    {
      title: '유형',
      dataIndex: 'product',
      render: (product) => `${product?.saleType || 'commerce'}`,
      fixed: 'left',
    },
    {
      title: '정산상태',
      render: (data: PaymentStatementWithLogs) => {
        if (!data) {
          return '-';
        }
        const settlementStatus = (data.statuses?.settlement || '').toLowerCase();
        let status = '-';
        if (settlementStatus === 'done' && data?.settlement?.transferAt) {
          const transperAt = moment(data?.settlement?.transferAt).format('YYYY-MM-DD HH:mm');
          status = `정산완료\n(${transperAt})`;
        }
        return status;
      },
      width: 120,
      fixed: 'left',
    },
    {
      title: '결제상태',
      dataIndex: 'statuses',
      render: (statuses) => {
        const payStatus = (statuses?.payment || '').toLowerCase();
        let status = '-';
        if (payStatus === 'done') {
          status = '결제완료';
        } else if (payStatus === 'ready') {
          status = '결제대기';
        } else if (payStatus === 'none') {
          status = '-';
        } else {
          status = payStatus;
        }
        return status;
      },
      fixed: 'left',
    },
    {
      title: '환불상태',
      width: 100,
      dataIndex: 'statuses',
      render: (statuses) => {
        const refundStatus = statuses?.refund;
        let status = '-';
        if (refundStatus === RefundStatus.ALL) {
          status = '전체환불';
        } else if (refundStatus === RefundStatus.PARTIAL) {
          status = '부분환불';
        } else if (refundStatus === RefundStatus.RESERVED) {
          status = '환불예약';
        } else if (refundStatus === RefundStatus.REQUESTED) {
          status = '환불요청';
        } else if (refundStatus === RefundStatus.ACCEPTED) {
          status = '환불승인';
        } else if (refundStatus === RefundStatus.DENIED) {
          status = '환불반려';
        }
        return status;
      },
      fixed: 'left',
    },
    {
      title: '구매자',
      dataIndex: 'buyer',
      align: 'center',
      render: (buyer) => {
        if (buyer) {
          return (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <div>{`${buyer?.name}`}</div>
              <div>{`(${formatPhoneNumber(buyer?.phone)})`}</div>
              <div>
                {buyer?.membership?.name && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      borderRadius: '8px',
                      background: 'var(--Blue-10, #EBF9FF)',
                      color: 'var(--Blue-60, #1890FF)',
                      fontFamily: 'Pretendard',
                      fontSize: '13px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      padding: '4px',
                    }}>
                    {buyer?.membership?.name}
                  </div>
                )}
              </div>
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(buyer?.id);
                  toast.dark(`구매자 ID를 클립보드에 복사했습니다(${buyer?.id})`, {
                    position: 'top-center',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    pauseOnFocusLoss: false,
                    draggable: true,
                    progress: undefined,
                    bodyStyle: {
                      color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                      textAlign: 'center',
                      fontFamily: 'Pretendard',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '20px',
                    },
                    theme: 'dark',
                  });
                }}>
                ID 복사
              </Button>
            </div>
          );
        }
        return '-';
      },
      fixed: 'left',
    },
    {
      title: '판매자',
      dataIndex: 'sellerDetail',
      align: 'center',
      render: (sellerDetail) => {
        const seller = sellerDetail?.[0];
        if (!seller) {
          return '-';
        }
        return (
          <div>
            <div>{`${seller?.profile?.name}${seller?.profile?.nickname ? `(${seller?.profile?.nickname})` : ''}`}</div>
            <div>
              {seller?.profile?.contract?.licensePhoto && (
                <PhotoView src={cdnImageUrl(seller?.profile?.contract?.licensePhoto)}>
                  <Button>신분증</Button>
                </PhotoView>
              )}
            </div>
            <div>
              {seller?.profile?.contract?.bankAccountPhoto && (
                <PhotoView src={cdnImageUrl(seller?.profile?.contract?.bankAccountPhoto)}>
                  <Button>통장</Button>
                </PhotoView>
              )}
            </div>
          </div>
        );
      },
    },
    {
      title: '상품명',
      dataIndex: 'product',
      render: (product) => {
        if (!product) {
          return '-';
        }
        return (
          <Popover content={product?.title || product?.name}>
            <div style={{ width: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {product?.title || product?.name}
            </div>
          </Popover>
        );
      },
    },
    {
      title: '구매옵션',
      render: (record) => {
        const purchases = record?.purchase ? [record.purchase] : [...(record?.purchases || [])];
        return (
          <div>{`${(purchases || [])
            .map((purchase: any) => {
              let optionName = '';
              if (purchase.option && purchase.option.length > 0) {
                optionName = `${purchase.option.join(' ')}`;
              }
              if (Array.isArray(purchase.name)) {
                return `${purchase.name.join('/')}(${purchase.count})`;
              }
              return `${optionName}${purchase.name}(${purchase.count})`;
            })
            .join('\n')}`}</div>
        );
      },
    },
    {
      title: '수강증',
      render: (record) => {
        const classProductId = record?.product?.id;
        const classInvoiceId = record?.product?.classInvoiceId;
        if (!classProductId || !classInvoiceId) {
          return '-';
        }
        return (
          <Button
            onClick={() => {
              classInvoiceModalStore.open({
                classId: classProductId,
                classInvoiceId: classInvoiceId,
                mode: 'view',
              });
            }}>
            수강증 보기
          </Button>
        );
      },
      width: 130,
    },
    {
      title: '청구금액',
      dataIndex: 'payment',
      render: (payment) => {
        return (
          <div>
            <div>{`${payment?.amount?.toLocaleString()}원`}</div>
            {payment?.membershipDiscountAmount && (
              <div
                style={{
                  fontSize: '12px',
                }}>{`${payment?.membershipDiscountAmount.toLocaleString()}원 멤버십 할인`}</div>
            )}
          </div>
        );
      },
    },
    {
      title: '결제금액',
      dataIndex: 'payment',
      render: (payment) => {
        return (
          <div>
            <div>
              {payment?.amount?.toLocaleString()}원{payment?.point ? `(${payment?.point?.toLocaleString()}P)` : ''}
            </div>
            {payment?.bankAccountTransferAmount && (
              <div
                style={{
                  color: '#424242',
                }}>{`(계좌이체: ${payment?.bankAccountTransferAmount?.toLocaleString()}원)`}</div>
            )}
          </div>
        );
      },
    },
    {
      title: '정산금액',
      width: 120,
      dataIndex: 'settlement',
      render: (settlement) => {
        return <div>{settlement?.transferAmount ? `${settlement?.transferAmount?.toLocaleString()}원` : '-'}</div>;
      },
    },
    {
      title: '환불금액',
      width: 120,
      dataIndex: 'refund',
      render: (refund) => {
        return <div>{refund?.amount ? `${refund?.amount?.toLocaleString()}원` : '-'}</div>;
      },
    },
    {
      title: '결제 적립',
      width: 120,
      render: (record) => {
        const paybackPoint = record?.payback?.amount || 0;
        if (paybackPoint) return `${paybackPoint.toLocaleString()}P`;
        return '-';
      },
    },
    {
      title: '결제수단',
      width: 120,
      render: (record) => getPaymentMethodText(record.payment),
    },
    {
      title: '시간',
      align: 'center',
      width: 220,
      render: (record) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <div style={{ display: 'flex', gap: '4px' }}>
              <div style={{ fontWeight: 'bold' }}>생성</div>
              <div>{moment(record.createdAt).format('YYYY.MM.DD HH:mm')}</div>
            </div>
            <div style={{ display: 'flex', gap: '4px' }}>
              <div style={{ fontWeight: 'bold' }}>결제</div>
              <div>{record?.payment?.paidAt ? moment(record.payment.paidAt).format('YYYY.MM.DD HH:mm') : '-'}</div>
            </div>
            <div style={{ display: 'flex', gap: '4px' }}>
              <div style={{ fontWeight: 'bold' }}>업데이트</div>
              <div>{moment(record.updatedAt).format('YYYY.MM.DD HH:mm')}</div>
            </div>
            <div style={{ display: 'flex', gap: '4px' }}>
              <div style={{ fontWeight: 'bold' }}>결제완성</div>
              <div>{record?.completionDate ? moment(record.completionDate).format('YYYY.MM.DD HH:mm') : '-'}</div>
            </div>
          </div>
        );
      },
    },
    {
      title: '배송상태',
      dataIndex: 'statuses',
      render: (statuses) => {
        const status = `${statuses?.shipping}`.toLowerCase();
        if (status === 'none' || !statuses?.shipping) {
          return '-';
        } else {
          return status;
        }
      },
      width: 84,
    },
    {
      title: '리포트 상태',
      render: (data: PaymentStatementWithLogs) => {
        if (!data) {
          return '-';
        }

        const saleType = data.product.saleType;
        const reportStatus = data.statuses?.report || '';
        let status = '-';
        if (saleType === 'class') {
          if (reportStatus === PaymentStatementReportStatus.PUBLISHED) {
            const publishedAt = moment(data.report?.publishedAt).format('YYYY-MM-DD') || '-';
            status = `발송완료\n(${publishedAt})`;
          } else if (reportStatus === PaymentStatementReportStatus.EDITTING) {
            const createdAt = moment(data.report?.createdAt).format('YYYY-MM-DD') || '-';
            status = `임시저장\n(${createdAt})`;
          } else {
            status = '미작성';
          }
        }
        return status;
      },
      width: 100,
    },
  ];

  const productOrdersViewSearchQuery = useQuery({
    queryKey: [
      `admin-search-product-orders`,
      JSON.stringify(store.searchQuery),
      store.searchKeyword,
      paymentStatementModalStore.show,
      dialog.show,
    ],
    queryFn: () => productOrdersApi.searchProductOrders(store.searchQuery),
  });

  const settlementPaymentStatementMutation = useMutation({
    mutationFn: paymentStatementsApi.settlementPaymentStatements,
    onSuccess: async (response) => {
      if (response.status === 200) {
        setSelectedItemIndex([]);
        setCheckAll(false);
      }
      dialog.open({
        type: 'ok',
        confirmColor: '#FF3D8F',
        title: response.status === 200 ? '완료' : '실패',
        content: response.status === 200 ? `정산 완료되었습니다.` : '에러가 발생했습니다.',
      });
    },
  });

  useEffect(() => {
    const searchResult = productOrdersViewSearchQuery.data?.data;
    if (searchResult) {
      store.setProductOrdersContainer(searchResult);
    }
    setSelectedItemIndex([]);
  }, [productOrdersViewSearchQuery.data?.data]);

  const onCommerceSettlementsStatus = (data: {
    status: string;
    message: string;
    current?: number;
    total?: number;
    app?: number;
    naver?: number;
  }) => {
    setSyncState((prev) => {
      if (data.status === 'done') {
        return { ...prev, message: '완료!', status: data.status };
      } else if (data.status === 'cancelled') {
        return { ...prev, message: '취소됨', status: data.status };
      }
      return { ...data };
    });
  };

  useEffect(() => {
    appSocket.socket.addSocketEventListener(
      'sync_settlement_commerce_payments_status',
      'sync_settlement_commerce_payments_status',
      onCommerceSettlementsStatus,
    );
    return () => {
      appSocket.socket.removeSocketEventListener(
        'sync_settlement_commerce_payments_status',
        'sync_settlement_commerce_payments_status',
      );
    };
  }, []);

  const onChangeStartDate: DatePickerProps['onChange'] = (date) => {
    store.setStartDate(localTimeToUtcISOString(date ? new Date(date.toISOString()) : new Date(), { timeToZero: true }));
  };

  const onChangeEndDate: DatePickerProps['onChange'] = (date) => {
    store.setEndDate(localTimeToUtcISOString(date ? new Date(date.toISOString()) : new Date(), { timeToZero: true }));
  };

  const getExcelFile = async (type: ExcelSheet) => {
    const fileName = `igogo-${type}-${moment().format('YYYYMMDD-HHmmss')}.xlsx`;
    const blob = await getSearchPaymentStatementsExcelFile(store.searchQuery, type);
    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
      // IE variant
      (window.navigator as any).msSaveOrOpenBlob(new Blob(blob.data), fileName);
    } else {
      const url = window.URL.createObjectURL(blob.data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode && link.parentNode.removeChild(link);
    }
  };

  const getExcelFileCommerceSettlemets = async () => {
    const startDate = store.searchQuery.startDate;
    const endDate = store.searchQuery.endDate;
    const fileName = `[통합]커머스&네이버-${moment(startDate).format('YYYYMMDD')}~${moment(endDate).format(
      'YYYYMMDD',
    )}.xlsx`;
    const blob = await getCommerceSettlementsExcelFile(store.searchQuery);

    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
      // IE variant
      (window.navigator as any).msSaveOrOpenBlob(new Blob(blob.data), fileName);
    } else {
      const url = window.URL.createObjectURL(blob.data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode && link.parentNode.removeChild(link);
    }
  };

  const syncCurrent = syncState?.current || 0;
  const syncTotal = syncState?.total || 1;
  const syncApp = syncState?.app || 0;
  const syncNaver = syncState?.naver || 0;
  const syncKakao = syncState?.kakao || 0;
  const syncInbound = syncState?.inbound || 0;

  return (
    <div className="dashboard">
      <Modal
        width={'480px'}
        open={showCommerceSettlementModal}
        footer={
          <div>
            <Button
              disabled={['prepare', 'in_progress'].includes(syncState.status)}
              onClick={() => {
                appSocket.settlementCommercePayments({ type: 'commerce', query: store?.searchQuery });
              }}>
              정산하기
            </Button>
            <Button
              disabled={['none', 'done', 'error', 'cancelled'].includes(syncState.status)}
              onClick={() => {
                appSocket.cancelSettlementCommercePayments();
              }}>
              정산 작업 취소
            </Button>
            <Button
              onClick={() => {
                setShowCommerceSettlementModal(false);
                productOrdersViewSearchQuery.refetch();
                setSyncState({ status: 'ready', message: '' });
              }}>
              닫기
            </Button>
          </div>
        }
        onCancel={() => {
          setShowCommerceSettlementModal(false);
          productOrdersViewSearchQuery.refetch();
        }}
        onOk={() => {
          setShowCommerceSettlementModal(false);
          productOrdersViewSearchQuery.refetch();
        }}>
        <div>
          <div style={{ fontWeight: 'bold', fontSize: '16px' }}>커머스(네이버 통합) 결제내역 정산하기</div>
          <div
            style={{
              marginTop: '24px',
              padding: '12px 0',
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              alignItems: 'flex-end',
            }}>
            <div>
              <Progress percent={Math.floor((syncCurrent / syncTotal) * 100)} size={[400, 20]} />
            </div>
            {syncCurrent > 0 && syncTotal > 1 && <div>{`${syncCurrent} / ${syncTotal}`}</div>}
            {syncApp > 0 && syncTotal > 1 && <div>{`앱 정산: ${syncApp} 건`}</div>}
            {syncNaver > 0 && syncTotal > 1 && <div>{`네이버 정산: ${syncNaver} 건`}</div>}
            {syncKakao > 0 && syncTotal > 1 && <div>{`카카오 정산: ${syncKakao} 건`}</div>}
            {syncInbound > 0 && syncTotal > 1 && <div>{`인바운드 정산: ${syncInbound} 건`}</div>}
            <div style={{ width: '100%', height: '36px' }}>정산 상태: {syncState.message}</div>
          </div>
        </div>
      </Modal>
      <div className="page-header">
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div>정산관리</div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <div>
            <Button
              style={{ borderColor: store.searchQuery.dateType === 'settlement.transferAt' ? 'blue' : '#d9d9d9' }}
              onClick={() => {
                store.updateSearchQuery({ dateType: 'settlement.transferAt' });
              }}>
              정산일
            </Button>
          </div>
          <div>
            <Button
              style={{ borderColor: store.searchQuery.dateType === 'payment.paidAt' ? 'blue' : '#d9d9d9' }}
              onClick={() => {
                store.updateSearchQuery({ dateType: 'payment.paidAt' });
              }}>
              결제일
            </Button>
          </div>
          <div>
            <Button
              style={{ borderColor: store.searchQuery.dateType === 'createdAt' ? 'blue' : '#d9d9d9' }}
              onClick={() => {
                store.updateSearchQuery({ dateType: 'createdAt' });
              }}>
              생성일
            </Button>
          </div>
          <div>
            <Button
              style={{ borderColor: store.searchQuery.dateType === 'updatedAt' ? 'blue' : '#d9d9d9' }}
              onClick={() => {
                store.updateSearchQuery({ dateType: 'updatedAt' });
              }}>
              수정일
            </Button>
          </div>
          <div>
            <Button
              style={{ borderColor: store.searchQuery.dateType === 'plannedDates' ? 'blue' : '#d9d9d9' }}
              onClick={() => {
                store.updateSearchQuery({ dateType: 'plannedDates' });
              }}>
              수업일
            </Button>
          </div>
          <div style={{ marginLeft: '10px' }}>
            <DatePicker
              onChange={onChangeStartDate}
              value={dayjs(utcTimcToLocalISOString(store.searchQuery.startDate as any))}
            />
          </div>
          <div style={{ marginLeft: '8px' }}>
            <DatePicker
              onChange={onChangeEndDate}
              value={dayjs(utcTimcToLocalISOString(store.searchQuery.endDate as any))}
            />
          </div>
        </div>
      </div>
      <div className="page-content">
        <div style={{ display: 'flex', marginBottom: '10px', justifyContent: 'space-between', flexWrap: 'wrap' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', flexWrap: 'wrap' }}>
            <div
              style={{
                lineHeight: '2rem',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '10px',
                flexWrap: 'wrap',
              }}>
              <Select
                value={store.searchQuery.channel || 'all'}
                style={{ width: '120px' }}
                onChange={(value) => {
                  store.updateSearchQuery({ channel: value });
                }}
                options={[
                  { value: 'all', label: '전체' },
                  { value: 'app', label: '앱' },
                  { value: 'naver', label: '네이버' },
                  { value: 'kakao', label: '카카오' },
                  { value: 'inbound', label: '인바운드' },
                ]}
              />
              <Select
                value={store.searchQuery.saleType || 'all'}
                style={{ width: '120px' }}
                onChange={(value) => {
                  store.updateSearchQuery({ saleType: value });
                }}
                options={[
                  { value: 'all', label: '전체' },
                  { value: 'commerce', label: '커머스' },
                  { value: 'class', label: '클래스' },
                ]}
              />
              <Select
                defaultValue={store.searchQuery?.settlementStatus || ''}
                style={{ width: '120px' }}
                onChange={(value) => {
                  store.updateSearchQuery({ settlementStatus: value });
                }}
                options={[
                  { value: '', label: '전체' },
                  { value: PaymentStatementSettlementStatus.DONE, label: '정산완료' },
                  { value: PaymentStatementSettlementStatus.NONE, label: '정산완료X' },
                ]}
              />
              <Input
                ref={searchInputRef}
                style={{ width: '240px' }}
                value={searchKeyword}
                placeholder={'검색어 입력 후 엔터'}
                onChange={(e) => {
                  setSearchKeyword(e.target.value);
                }}
                onKeyUp={(e) => {
                  if (e.code === 'Enter' && searchKeyword) {
                    store.updateSearchQuery({ keyword: searchKeyword });
                  }
                }}
              />
              <Button
                onClick={() => {
                  setSearchKeyword(undefined);
                  store.resetSearchQuery();
                }}>
                검색 초기화
              </Button>
              <div style={{ fontWeight: 'bold' }}>검색결과: {store.searchResultContainer.total}</div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', minHeight: '36px' }}>
              <Checkbox
                checked={store.searchQuery.isSettlementTarget}
                onChange={() => {
                  const isSettlementTarget = store.searchQuery.isSettlementTarget;
                  const query = { isSettlementTarget: !isSettlementTarget } as ProductOrdersViewQuery;
                  if (isSettlementTarget) {
                    query['saleType'] = 'all';
                  } else {
                    query['saleType'] = 'class';
                  }
                  store.updateSearchQuery(query);
                }}>
                클래스 정산대상 보기
              </Checkbox>
              {store.searchQuery.saleType === 'class' && store.searchQuery.isSettlementTarget && (
                <div>
                  <Button
                    style={{ marginLeft: '10px' }}
                    onClick={async () => {
                      if (selectedItemIndex.length < 1) {
                        dialog.open({
                          type: 'ok',
                          confirmColor: '#FF3D8F',
                          title: '실패',
                          content: '정산 대상을 선택해주세요.',
                        });
                        return;
                      }
                      if (confirm(`선택된 ${selectedItemIndex.length}건 정산처리 하시겠습니까?`)) {
                        settlementPaymentStatementMutation.mutate({
                          type: 'class',
                          paymentStatementIds: selectedItemIndex.map(
                            (selectedIndex) => store.searchResultContainer.contents[selectedIndex]._id,
                          ),
                        });
                      }
                    }}>
                    선택항목 정산처리
                  </Button>
                  <Button
                    style={{ marginLeft: '10px' }}
                    onClick={() => {
                      getExcelFile('settlement');
                    }}>
                    클래스 정산대상 목록 다운
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', flexWrap: 'wrap' }}>
            <div style={{ marginTop: '6px', marginRight: '10px' }}>엑셀 파일 다운로드</div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
              <Button
                onClick={() => {
                  getExcelFile('payment');
                }}>
                검색결과
              </Button>
              <Button
                onClick={() => {
                  getExcelFileCommerceSettlemets();
                }}>
                커머스 통합 정산 시트
              </Button>
              <Popover content={<div>타입 선택(커머스), 수업일 정렬, 기간선택이 필요합니다.</div>}>
                <Button
                  disabled={
                    !(
                      store?.searchQuery?.saleType === 'commerce' &&
                      store?.searchQuery?.dateType === 'plannedDates' &&
                      store?.searchQuery.startDate &&
                      store?.searchQuery.endDate
                    )
                  }
                  onClick={() => {
                    dialog.open({
                      title: '커머스 정산처리',
                      content: `현재 검색된 결제내역을 정산처리 하시겠습니까?\n정산 가능한 상태의 결제건만 정산처리됩니다.`,
                      type: 'ok_cancel',
                      onConfirm: () => {
                        setShowCommerceSettlementModal(true);
                      },
                    });
                  }}>
                  커머스 정산처리
                </Button>
              </Popover>
            </div>
          </div>
        </div>
        <Table
          columns={TABLE_COLUMNS}
          scroll={{ x: 'max-content', y: 'calc(100dvh - 330px)' }}
          style={{ whiteSpace: 'pre-wrap' }}
          rowKey={(record, index) => record?._id || `${index}`}
          dataSource={store.searchResultContainer.contents}
          pagination={{
            current: (store.searchResultContainer.page || 0) + 1,
            pageSize: store.searchResultContainer.size || 20,
            total: store.searchResultContainer.total,
          }}
          loading={productOrdersViewSearchQuery.isLoading}
          onChange={(e) => {
            store.updateSearchQuery({ page: (e.current || 2) - 1, size: e.pageSize || 20 });
          }}
        />
      </div>
    </div>
  );
};

export default SettlementsPage;
