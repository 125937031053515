import { ProductModel, ProductStatus } from '@models/product';
import { ProductCollection } from '@models/productCollection';
import { shuffleArray } from '@utils/dataTransform';
import { cdnImageUrl } from '@utils/image';
import React, { useEffect, useState } from 'react';
import ClassItem from './ClassItem';
import CommerceItem from './CommerceItem';

export const ProductCollectionPreview: React.FC<{ data: ProductCollection }> = (props) => {
  const [products, setProducts] = useState<ProductModel[]>([]);
  const data = props.data;
  useEffect(() => {
    let collectionProducts = data?.products || [];
    if (data?.shuffle) {
      collectionProducts = shuffleArray(data?.products || []);
    }
    setProducts(collectionProducts);
  }, [data?.products]);
  return (
    <div>
      <div
        style={{
          position: 'sticky',
          zIndex: 1,
          top: 0,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '56px',
          backgroundColor: '#ffffff',
          borderBottom: '1px solid #eff1f4',
          fontSize: '17px',
          fontWeight: 'bold',
          color: '#242424',
        }}>
        {data.title}
      </div>
      <div style={{ height: 'calc(100dvh - 100px)', overflowX: 'hidden', overflowY: 'auto', position: 'relative' }}>
        <div
          style={{
            backgroundColor: '#ffffff',
            marginTop: 0,
            position: 'relative',
            width: '100%',
          }}>
          <div
            style={{
              justifyContent: 'center',
              position: 'relative',
              alignItems: 'center',
              flex: 1,
            }}>
            {data?.coverImageUrl && (
              <img
                style={{
                  width: '100%',
                }}
                src={cdnImageUrl(data?.coverImageUrl)}
              />
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              gap: '12px',
              padding: '16px',
            }}>
            {products.map((item, index) => {
              const exposed =
                item.display && (item.status === ProductStatus.OPEN || item.status === ProductStatus.CLOSE);
              if (exposed) {
                if (item.saleType === 'commerce') {
                  return (
                    <CommerceItem
                      key={`product-item-${index}`}
                      item={item}
                      style={{ flex: '0 0 auto', width: 'calc(50% - 6px)' }}
                      onClick={() => {}}></CommerceItem>
                  );
                } else {
                  return (
                    <ClassItem
                      key={`product-item-${index}`}
                      item={item}
                      style={{
                        flex: '0 0 auto',
                        width: 'calc(50% - 6px)',
                        cursor: 'pointer',
                      }}
                      onClick={() => {}}></ClassItem>
                  );
                }
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
