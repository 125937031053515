import { apiClient } from '.';
import { User } from '../models/auth';

export interface LoginParam {
  email: string;
  password: string;
}

export const login = async (body: LoginParam) => {
  return apiClient.post('/auth/v1/login', body);
};

export const refresh = async () => {
  return apiClient.post('/auth/v1/refresh');
};

export const getMe = async () => {
  return apiClient.get<User>('/auth/v1/me');
};

export const logout = async () => {
  return apiClient.post('/auth/v1/logout');
};
