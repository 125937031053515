import { PagenatedResponse } from '@models/common';
import { ClassInvoice } from '@models/invoice';
import { ProductSearchQuery } from '@models/product';
import { apiClient } from '.';

export const searchClassInvoices = async (query?: ProductSearchQuery) => {
  return apiClient.get<PagenatedResponse<ClassInvoice>>(`/admin/v1/class-invoices`, {
    params: query,
  });
};

export const getClassInvoice = async (userId: string) => {
  return apiClient.get<ClassInvoice>(`/admin/v1/class-invoices/${userId}`);
};

export const updateClassInvoice = async (formData: FormData) => {
  const userId = formData.get('userId');
  return apiClient.put<ClassInvoice>(`/admin/v1/class-invoices/${userId}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
