import { UserContent } from '@models/user-contents';
import { cdnImageUrl } from '@utils/image';
import * as cheerio from 'cheerio';
import moment from 'moment';
import 'moment/locale/ko';
import React from 'react';

moment.locale('ko');

export interface UserContentSummaryProps {
  item: UserContent;
  loungeCurationCategoriesDict?: { [key: string]: string | undefined };
  textOnly?: boolean;
}

export const UserContentSummary: React.FC<UserContentSummaryProps> = (props) => {
  const userContent = props.item;
  const loungeCurationCategoriesDict = props.loungeCurationCategoriesDict;

  if (!userContent) {
    return <></>;
  }
  const htmlText = userContent.text;
  const dom = cheerio.load(htmlText);
  const title = dom('h1').text();
  dom('h1').remove();
  const description = dom('body').text();
  const board = userContent.board;
  let badgeText: string | undefined = '후기';
  if (board !== 'review' && userContent.category) {
    badgeText = loungeCurationCategoriesDict?.[userContent.category];
  }
  return (
    <div style={{ display: 'flex', minWidth: 0, justifyContent: 'space-between', gap: '12px' }}>
      <div style={{ minWidth: 0 }}>
        {title && (
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center', marginBottom: '4px' }}>
            {badgeText && (
              <div
                style={{
                  borderRadius: '4px',
                  background: board === 'review' ? 'var(--Blue-10, #EBF9FF)' : '#F5F7FB',
                  color: board === 'review' ? 'var(--Blue-60, #1890FF)' : '#808387',
                  fontFamily: 'Pretendard',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  padding: '2px 6px',
                  flexShrink: 0,
                }}>
                {badgeText}
              </div>
            )}
            <div
              style={{
                wordBreak: 'break-word',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                color: 'var(--Neutral-90, #424242)',
                fontFamily: 'Pretendard',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 600,
              }}>
              {title}
            </div>
          </div>
        )}
        <div
          style={{
            display: '-webkit-box',
            maxWidth: '100%',
            maxHeight: '60px',
            margin: '0 auto',
            fontSize: '14px',
            WebkitLineClamp: '3',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: 'var(--Neutral-80, #66686B)',
            whiteSpace: 'pre-wrap',
            fontFamily: 'Pretendard',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '20px',
          }}>
          {!title && badgeText && (
            <span
              style={{
                borderRadius: '4px',
                background: board === 'review' ? 'var(--Blue-10, #EBF9FF)' : '#F5F7FB',
                color: board === 'review' ? 'var(--Blue-60, #1890FF)' : '#808387',
                fontFamily: 'Pretendard',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 600,
                padding: '2px 6px',
                flexShrink: 0,
                marginRight: '4px',
              }}>
              {badgeText}
            </span>
          )}
          {description}
        </div>
        {!props.textOnly && (
          <div
            style={{
              color: 'var(--Neutral-60, #9B9DA0)',
              fontFamily: 'Pretendard',
              fontSize: '13px',
              fontStyle: 'normal',
              fontWeight: 400,
              display: 'flex',
              alignItems: 'center',
              lineHeight: '18px',
            }}>
            <div>{`${userContent.publisher?.nickname || '익명'} · ${moment(userContent.updatedAt).fromNow()}`}</div>
          </div>
        )}
      </div>
      {!props.textOnly && userContent.images && userContent.images.length > 0 && (
        <div
          style={{
            width: '88px',
            height: '88px',
            borderRadius: '8px',
            border: '1px solid #F8F9FC',
            overflow: 'hidden',
            flexShrink: 0,
          }}>
          <img
            src={cdnImageUrl(userContent.images[0])}
            alt="first"
            style={{ width: '88px', height: '88px', objectFit: 'cover' }}
          />
        </div>
      )}
    </div>
  );
};

export default UserContentSummary;
