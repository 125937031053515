import * as api from '@apis/product-collection';
import { ProductCollection } from '@models/productCollection';
import { useProductCollectionsStore } from '@stores/proudctCollectionsStore';
import { useQuery } from '@tanstack/react-query';
import { Button, Input, Select } from 'antd';
import React, { useEffect, useRef } from 'react';

interface SelectProductCollectionProps {
  selectedId?: string;
  onSelect?: (value: ProductCollection) => void;
  style?: any;
}

export const SelectProductCollection: React.FC<SelectProductCollectionProps> = (props) => {
  const searchInputRef = useRef<any>();
  const store = useProductCollectionsStore();

  const productCollectionSearchQuery = useQuery({
    queryKey: [`admin-search-product-collections`, JSON.stringify(store.searchQuery)],
    queryFn: () => api.searchProductCollections(store.searchQuery),
  });

  useEffect(() => {
    const searchResult = productCollectionSearchQuery.data?.data;
    if (searchResult) {
      store.setProductCollectionsContainer(searchResult);
    }
  }, [productCollectionSearchQuery.data?.data]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', ...props.style }}>
      <div style={{ display: 'flex', gap: '8px' }}>
        <Select
          defaultValue="전체"
          style={{ width: 180 }}
          onChange={(value) => {
            store.updateSearchQuery({ type: value });
          }}
          options={[
            { value: 'all', label: '전체' },
            { value: 'md_pick', label: 'MD PICK' },
            { value: 'general', label: '일반형' },
            { value: 'list', label: '리스트형' },
            { value: 'grid', label: '그리드형' },
            { value: 'thema', label: '테마' },
            { value: 'combine', label: '혼합형' },
          ]}
        />
        <Input ref={searchInputRef}></Input>
        <Button
          onClick={() => {
            if (searchInputRef.current?.input.value) {
              store.updateSearchQuery({
                keyword: searchInputRef.current.input.value,
                page: 0,
                size: 500,
              });
            } else {
              store.resetSearchQuery();
            }
          }}>
          검색
        </Button>
      </div>
      <div style={{ display: 'flex', marginTop: '12px', gap: '12px', alignItems: 'center' }}>
        <Select
          style={{ width: '100%' }}
          placeholder={'기획전을 선택하세요'}
          value={props.selectedId}
          onSelect={(_id) => {
            const selectedItem = store.searchResultContainer.contents.find((collection) => collection._id === _id);
            if (selectedItem) {
              props.onSelect && props.onSelect(selectedItem);
            }
          }}>
          {(store.searchResultContainer.contents || []).map((item) => {
            if (!item?._id) {
              return undefined;
            }
            return <Select.Option value={item._id}>{item.title}</Select.Option>;
          })}
        </Select>
        <div style={{ flexShrink: 0 }}>{`${store.searchResultContainer.contents.length || 0}개`}</div>
      </div>
    </div>
  );
};
