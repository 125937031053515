import * as api from '@apis/memberships';
import FileUploadButton from '@components/common/FileUploadButton';
import SelectProductModal from '@components/product/SelectProductModal';
import { useAuthentication } from '@hooks/authentication';
import { Membership } from '@models/membership';
import { ProductModel } from '@models/product';
import { useMembershipsStore } from '@stores/membershipsStore';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { useMutation, useQuery } from '@tanstack/react-query';
import { removeTempProperties } from '@utils/dataTransform';
import { cdnImageUrl, resizeImage } from '@utils/image';
import { extractFileName } from '@utils/string';
import { WEB_URL } from '@variables';
import { Button, Checkbox, ColorPicker, DatePicker, Input, Modal, Select, Switch, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { RcFile } from 'antd/es/upload';
import dayjs from 'dayjs';
import moment from 'moment';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { DeleteOutlined } from '@ant-design/icons';
import SvgInline from '@components/common/SvgInline';

interface MembershipsEditModalProps {
  open?: boolean;
  data?: Membership;
  onClose?: () => void;
  needRefetch?: () => void;
}

const MembershipsEditModal: React.FC<MembershipsEditModalProps> = (props) => {
  const [specialDiscountPercent, setSpecialDiscountPercent] = useState<number>(0);
  const [showProductAdd, setShowProductAdd] = useState<boolean>();
  const [data, setData] = useState<Membership>();
  const [imageFiles, setImageFiles] = useState<{ [key: string]: File }>({});
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
  }, [props.data]);
  const dialog = useOkCancelDialog();
  const updateMembershipsMutation = useMutation({
    mutationFn: api.updateMembership,
    onSuccess: async () => {
      setData(undefined);
      props.onClose && props.onClose();
      dialog.open({
        content: '수정했습니다.',
        type: 'ok',
      });

      props.needRefetch && props.needRefetch();
    },
    onError: async (e: any) => {
      setData(undefined);
      props.onClose && props.onClose();
      dialog.open({
        title: '오류',
        content: e?.response?.data?.message,
        type: 'ok',
      });
    },
  });

  const createMembershipsMutation = useMutation({
    mutationFn: api.createMembership,
    onSuccess: async () => {
      setData(undefined);
      props.onClose && props.onClose();
      dialog.open({
        content: '추가했습니다.',
        type: 'ok',
      });

      props.needRefetch && props.needRefetch();
    },
    onError: async (e: any) => {
      setData(undefined);
      props.onClose && props.onClose();
      dialog.open({
        title: '오류',
        content: e?.response?.data?.message,
        type: 'ok',
      });
    },
  });

  const onChangeImage = async (files: FileList, key: string) => {
    const { fileName } = extractFileName(files[0].name);
    const newFiles = await Promise.all(
      Array.from(files)
        .slice(0, 10)
        .map(async (file) => {
          const { fileExt } = extractFileName(file.name);

          if (fileExt === 'svg') {
            return { image: file, type: file.type };
          }

          const result = await resizeImage(file, { maxWidth: 856 });

          return result;
        }),
    );
    const contentTypes = newFiles[0].type.split('/');
    const uploadFileName = `${fileName}.${contentTypes[1]}`.replace('+xml', '');
    const newImageFile = new File([newFiles[0].image], `${fileName}.${contentTypes[1]}`.replace('+xml', '')) as RcFile;
    const tempUrl = URL.createObjectURL(newFiles[0].image);
    setData((prev) => {
      const newValue = {
        ...prev,
        [key]: uploadFileName,
        [`${key}Temp`]: tempUrl,
      } as Membership;
      return newValue;
    });

    setImageFiles((value) => {
      const newValue = { ...value };
      newValue[key] = newImageFile;
      return newValue;
    });
  };

  const onChangeJoinPageImage = async (files: FileList, key: string) => {
    const { fileName } = extractFileName(files[0].name);
    const newFiles = await Promise.all(
      Array.from(files)
        .slice(0, 10)
        .map(async (file) => {
          const result = await resizeImage(file, { maxWidth: 856 });

          return result;
        }),
    );
    const contentTypes = newFiles[0].type.split('/');
    const uploadFileName = `${fileName}.${contentTypes[1]}`;
    const newImageFile = new File([newFiles[0].image], `${fileName}.${contentTypes[1]}`) as RcFile;
    const tempUrl = URL.createObjectURL(newFiles[0].image);
    setData((prev) => {
      const newValue = {
        ...prev,
        joinPage: { ...(prev?.joinPage || {}), [key]: uploadFileName, [`${key}Temp`]: tempUrl },
      } as Membership;
      return newValue;
    });

    setImageFiles((value) => {
      const newValue = { ...value };
      newValue[key] = newImageFile;
      return newValue;
    });
  };

  return (
    <Modal
      width={960}
      styles={{ footer: { height: '44px' } }}
      open={props.open}
      footer={
        <div style={{ width: '100%' }}>
          <Button
            style={{
              width: 120,
            }}
            onClick={props.onClose}>
            취소
          </Button>
          <Button
            style={{
              width: 120,
              marginLeft: '8px',
            }}
            onClick={() => {
              const formData: FormData = new FormData();
              const json = removeTempProperties(data);
              formData.append('json', JSON.stringify(json));

              Object.values(imageFiles).forEach((file) => {
                formData.append('files', file);
              });

              if (props.data?._id) {
                formData.append('membershipId', props.data?._id);
                updateMembershipsMutation.mutate(formData);
              } else {
                createMembershipsMutation.mutate(formData);
              }
            }}>
            {props.data?._id ? '수정하기' : '생성하기'}
          </Button>
        </div>
      }
      onCancel={props.onClose}>
      <SelectProductModal
        open={showProductAdd}
        onAddProducts={(products: ProductModel[]) => {
          setData((prev) => {
            const prevProducts = prev?.benefits?.products || [];
            const candidates: ProductModel[] = [];
            products.forEach((product) => {
              const found = prevProducts.find((item) => item._id === product._id);
              if (!found) {
                candidates.push(product);
              }
            });

            toast.info(`${candidates.length}개의 상품이 추가되었습니다.`, {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              pauseOnFocusLoss: false,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });

            const newProducts = [...candidates, ...prevProducts];

            return {
              ...prev,
              benefits: { ...prev?.benefits, productIds: newProducts.map((item) => item._id), products: newProducts },
            } as Membership;
          });
        }}
        onClose={() => {
          setShowProductAdd(false);
        }}></SelectProductModal>
      <div style={{ height: '48px', fontSize: '18px', fontWeight: 'bold' }}>
        {props.data?._id ? '멤버십 수정' : '신규 멤버십 생성'}
      </div>
      <OverlayScrollbarsComponent
        defer
        options={{ scrollbars: { autoHide: 'scroll' } }}
        style={{
          backgroundColor: '#ffffff',
          marginTop: '8px',
          position: 'relative',
          height: 'calc(100dvh - 340px)',
          overflow: 'auto',
        }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', marginRight: '8px' }}>
          <Select
            style={{ width: '200px' }}
            value={data?.status}
            placeholder={'상태'}
            options={[
              { value: 'active', label: '사용' },
              { value: 'inactive', label: '중단' },
            ]}
            onChange={(value) => {
              setData((prev) => {
                return { ...data, ...prev, status: value } as Membership;
              });
            }}></Select>
          <div style={{ fontSize: '15px', fontWeight: 'bold', marginTop: '12px', marginBottom: '8px' }}>기본 설정</div>
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <div style={{ width: '110px', flexShrink: 0 }}>멤버십 이름</div>
            <div style={{ width: '100%' }}>
              <Input
                value={data?.name}
                onChange={(e) => {
                  setData((prev) => {
                    return { ...prev, name: e.target.value } as Membership;
                  });
                }}></Input>
            </div>
          </div>
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <div style={{ width: '110px', flexShrink: 0 }}>멤버십 별명</div>
            <div style={{ width: '100%', display: 'flex', gap: '8px' }}>
              <Input
                value={data?.alias}
                onChange={(e) => {
                  setData((prev) => {
                    return { ...prev, alias: e.target.value } as Membership;
                  });
                }}></Input>
            </div>
          </div>
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <div style={{ width: '110px', flexShrink: 0 }}>배지 이미지</div>
            <FileUploadButton
              accept={['jpg', 'jpeg', 'png', 'tif', 'tiff', 'webp', 'svg']
                .map((ext) => {
                  return '.' + ext;
                })
                .join(',')}
              className="link-button"
              style={{
                fontSize: '14px',
                height: '32px',
                padding: '4px 12px',
                borderRadius: '6px',
                backgroundColor: '#ffffff',
                border: '1px solid #d9d9d9',
                color: 'rgba(0, 0, 0, 0.88)',
                boxShadow: '0 2px 0 rgba(0, 0, 0, 0.02)',
                cursor: 'pointer',
              }}
              onChange={(e) => {
                if (e.target.files) {
                  const files = e.target.files;
                  onChangeImage(files, 'badgeUrl');
                }
              }}>
              배지 이미지 변경
            </FileUploadButton>
            <div>
              {(data?.badgeUrlTemp || data?.badgeUrl || '').endsWith('svg') ? (
                <SvgInline url={cdnImageUrl(data?.badgeUrlTemp || data?.badgeUrl)!} />
              ) : (
                <img
                  src={cdnImageUrl(data?.badgeUrlTemp || data?.badgeUrl)}
                  alt=""
                  style={{ height: '20px', display: 'block' }}
                />
              )}
            </div>
            {(data?.badgeUrlTemp || data?.badgeUrl) && (
              <Button
                style={{ marginLeft: 'auto' }}
                onClick={() => {
                  setData((prev) => {
                    return {
                      ...prev,
                      badgeUrl: undefined,
                      badgeUrlTemp: undefined,
                    } as Membership;
                  });
                }}>
                <DeleteOutlined style={{ color: 'red' }} />
              </Button>
            )}
          </div>
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <div style={{ width: '110px', flexShrink: 0 }}>배지 색상</div>
            <ColorPicker
              value={data?.color || '#aaaaaa'}
              onChange={(value, hex) => {
                setData((prev) => {
                  return { ...prev, color: hex } as Membership;
                });
              }}></ColorPicker>
            <div
              className="membership-badge"
              style={{
                background: data?.color || '#aaaaaa',
              }}>
              {data?.name}
            </div>
          </div>
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <div style={{ width: '110px', flexShrink: 0 }}>기존 회원 가입 허용</div>
            <div style={{ width: '100%' }}>
              <Switch
                value={data?.allowExistingMemberJoin}
                onChange={(checked) => {
                  setData((prev) => {
                    return { ...prev, allowExistingMemberJoin: checked } as Membership;
                  });
                }}></Switch>
            </div>
          </div>
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <div style={{ width: '110px', flexShrink: 0 }}>할인율(%)</div>
            <div style={{ width: '100%', display: 'flex', gap: '8px', alignItems: 'center' }}>
              <Input
                value={data?.discountPercent}
                onChange={(e) => {
                  setData((prev) => {
                    return { ...prev, discountPercent: Number(e.target.value) || 0 } as Membership;
                  });
                }}></Input>
              <Checkbox
                style={{ flexShrink: 0 }}
                checked={data?.hideDiscountPercent}
                onChange={(e) => {
                  setData((prev) => {
                    return { ...prev, hideDiscountPercent: e.target.checked } as Membership;
                  });
                }}>
                할인율 노출하지 않음
              </Checkbox>
            </div>
          </div>
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <div style={{ width: '110px', flexShrink: 0 }}>멤버십 시작</div>
            <div style={{ width: '100%' }}>
              <DatePicker
                value={dayjs(data?.startDate)}
                showTime={{ showSecond: false }}
                onChange={(value, dateString) => {
                  setData((prev) => {
                    return {
                      ...prev,
                      startDate: moment(dateString, 'YYYY-MM-DD HH:mm:00').toDate(),
                    } as Membership;
                  });
                }}
              />
            </div>
          </div>
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <div style={{ width: '110px', flexShrink: 0 }}>멤버십 종료</div>
            <div style={{ width: '100%' }}>
              <DatePicker
                value={dayjs(data?.endDate)}
                showTime={{ showSecond: false }}
                onChange={(value, dateString) => {
                  setData((prev) => {
                    return {
                      ...prev,
                      endDate: moment(dateString, 'YYYY-MM-DD HH:mm:00').toDate(),
                    } as Membership;
                  });
                }}
              />
            </div>
          </div>
          <div style={{ fontSize: '15px', fontWeight: 'bold', marginTop: '12px', marginBottom: '8px' }}>
            랜딩 페이지 설정
          </div>
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <div style={{ width: '110px', flexShrink: 0 }}>제목</div>
            <div style={{ width: '100%' }}>
              <Input.TextArea
                value={data?.joinPage?.title}
                onChange={(e) => {
                  setData((prev) => {
                    return { ...prev, joinPage: { ...prev?.joinPage, title: e.target.value } } as Membership;
                  });
                }}></Input.TextArea>
            </div>
          </div>
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <div style={{ width: '110px', flexShrink: 0 }}>부제목</div>
            <div style={{ width: '100%' }}>
              <Input.TextArea
                value={data?.joinPage?.description}
                onChange={(e) => {
                  setData((prev) => {
                    return { ...prev, joinPage: { ...prev?.joinPage, description: e.target.value } } as Membership;
                  });
                }}></Input.TextArea>
            </div>
          </div>
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <div style={{ width: '110px', flexShrink: 0 }}>혜택 소개</div>
            <div style={{ width: '100%' }}>
              <Input.TextArea
                value={data?.benefits?.summary}
                onChange={(e) => {
                  setData((prev) => {
                    return { ...prev, benefits: { ...prev?.benefits, summary: e.target.value } } as Membership;
                  });
                }}></Input.TextArea>
            </div>
          </div>
          <FileUploadButton
            accept={['jpg', 'jpeg', 'png', 'tif', 'tiff', 'webp', 'svg']
              .map((ext) => {
                return '.' + ext;
              })
              .join(',')}
            className="link-button"
            style={{
              fontSize: '14px',
              height: '32px',
              padding: '4px 12px',
              borderRadius: '6px',
              backgroundColor: '#ffffff',
              border: '1px solid #d9d9d9',
              color: 'rgba(0, 0, 0, 0.88)',
              boxShadow: '0 2px 0 rgba(0, 0, 0, 0.02)',
              cursor: 'pointer',
            }}
            onChange={(e) => {
              if (e.target.files) {
                const files = e.target.files;
                onChangeJoinPageImage(files, 'imageUrl');
              }
            }}>
            이미지 변경
          </FileUploadButton>
          <img
            src={cdnImageUrl(data?.joinPage?.imageUrlTemp || data?.joinPage?.imageUrl)}
            style={{ width: '100%', height: '240px', borderRadius: 0, objectFit: 'cover' }}
          />
          <div
            style={{
              fontSize: '15px',
              fontWeight: 'bold',
              marginTop: '12px',
              marginBottom: '8px',
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <div>상품 설정</div>
            <Button
              style={{ marginLeft: 'auto' }}
              onClick={() => {
                setShowProductAdd(true);
              }}>
              상품 추가
            </Button>
          </div>
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <div style={{ width: '110px', flexShrink: 0 }}>모든 상품 할인</div>
            <div style={{ width: '100%' }}>
              <Switch
                value={data?.benefits?.allProducts}
                onChange={(checked) => {
                  setData((prev) => {
                    return { ...prev, benefits: { ...prev?.benefits, allProducts: checked } } as Membership;
                  });
                }}></Switch>
            </div>
          </div>
          <div>
            {selectedIds.length > 0 && (
              <div style={{ display: 'flex', gap: '8px', alignItems: 'center', marginBottom: '12px' }}>
                <div>{`${selectedIds.length}개 선택 항목을`} </div>
                <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                  <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                    <Input
                      style={{ width: '40px' }}
                      value={specialDiscountPercent}
                      onChange={(e) => {
                        setSpecialDiscountPercent(Number(e.target.value) || 0);
                      }}></Input>
                    %
                    <Button
                      style={{ color: 'blue' }}
                      onClick={() => {
                        setData((prev) => {
                          const specialOfferProducts = prev?.benefits?.specialOfferProducts || {};
                          selectedIds.forEach((selectedId) => {
                            specialOfferProducts[selectedId] = { discountPercent: specialDiscountPercent };
                          });

                          return {
                            ...prev,
                            benefits: {
                              ...prev?.benefits,
                              specialOfferProducts: specialOfferProducts,
                            },
                          } as Membership;
                        });

                        setSelectedIds([]);
                      }}>
                      별도 할인율 적용
                    </Button>
                  </div>
                  <Button
                    style={{ color: 'red' }}
                    onClick={() => {
                      setData((prev) => {
                        const prevProducts = prev?.benefits?.products || [];
                        const specialOfferProducts = prev?.benefits?.specialOfferProducts || {};
                        const newProducts = prevProducts.filter((product) => {
                          delete specialOfferProducts[product._id.toString()];
                          return !selectedIds.includes(product._id);
                        });

                        return {
                          ...prev,
                          benefits: {
                            ...prev?.benefits,
                            productIds: newProducts.map((item) => item._id),
                            products: newProducts,
                            specialOfferProducts: specialOfferProducts,
                          },
                        } as Membership;
                      });

                      setSelectedIds([]);
                    }}>
                    삭제
                  </Button>
                </div>
              </div>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '12px',
              height: '48px',
              borderBottom: '1px solid #eaeaea',
              width: '880px',
            }}>
            <div>
              <Checkbox
                onChange={(e) => {
                  setSelectedIds(() => {
                    if (e.target.checked) {
                      return (data?.benefits?.products || []).map((product) => product._id);
                    } else {
                      return [];
                    }
                  });
                }}></Checkbox>
            </div>
            <div style={{ width: '40px' }}></div>
            <div
              style={{
                fontWeight: 'bold',
                color: '#aaaaaa',
                width: '160px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}>
              판매자명
            </div>
            <div style={{ width: '320px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              상품명
            </div>
            <div style={{ width: '120px' }}>특별 할인율</div>
            <div style={{ fontWeight: 'bold' }}>가격</div>
          </div>
          <div>
            {(data?.benefits?.products || []).map((product, index) => {
              const specialOfferProducts = data?.benefits?.specialOfferProducts || {};
              const specialDiscountPercent = specialOfferProducts[product._id]?.discountPercent;
              return (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '12px',
                    height: '48px',
                    borderBottom: '1px solid #eaeaea',
                    width: '880px',
                  }}>
                  <div>
                    <Checkbox
                      checked={selectedIds.includes(product._id)}
                      onChange={(e) => {
                        setSelectedIds((values) => {
                          const newValues = [...values];
                          if (e.target.checked) {
                            if (!newValues.includes(product._id)) {
                              newValues.push(product._id);
                            }
                            return newValues;
                          } else {
                            return newValues.filter((value) => value !== product._id);
                          }
                        });
                      }}></Checkbox>
                  </div>
                  <div>
                    <img
                      src={cdnImageUrl(product.thumbnailImage)}
                      style={{ width: '40px', height: '40px', objectFit: 'cover', borderRadius: '8px' }}></img>
                  </div>
                  <div
                    style={{
                      fontWeight: 'bold',
                      color: '#aaaaaa',
                      width: '160px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}>
                    {product.sellerName}
                  </div>
                  <div style={{ width: '320px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {product.title}
                  </div>
                  <div style={{ width: '120px', fontWeight: 'bold' }}>
                    {specialDiscountPercent ? `${specialDiscountPercent}%` : ''}
                  </div>
                  <div style={{ fontWeight: 'bold' }}>{`${product.fee.toLocaleString()}원`}</div>
                  <Button
                    style={{ marginLeft: 'auto' }}
                    onClick={() => {
                      setData((prev) => {
                        const prevProducts = prev?.benefits?.products || [];
                        const newProducts = [...prevProducts];
                        newProducts.splice(index, 1);

                        return {
                          ...prev,
                          benefits: {
                            ...prev?.benefits,
                            productIds: newProducts.map((item) => item._id),
                            products: newProducts,
                          },
                        } as Membership;
                      });
                    }}>
                    <DeleteOutlined style={{ color: 'red' }} />
                  </Button>
                </div>
              );
            })}
          </div>
        </div>
      </OverlayScrollbarsComponent>
    </Modal>
  );
};

export const MembershipsPage: React.FC = () => {
  const [editItem, setEditItem] = useState<Membership>();
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [searchKeyword, setSearchKeyword] = useState<string>();
  const searchInputRef = useRef<any>();

  const store = useMembershipsStore();

  useAuthentication();

  const membershipsQuery = useQuery({
    queryKey: [`memberships`, JSON.stringify(store.searchQuery)],
    queryFn: () => api.searchMemberships(store.searchQuery),
  });

  const memberships = membershipsQuery.data?.data;

  useEffect(() => {
    if (memberships) {
      store.setMembershipsContainer(memberships);
    }
  }, [memberships]);

  const TABLE_COLUMNS: ColumnsType<Membership> = [
    {
      title: 'ID',
      dataIndex: '_id',
      render: (_id) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(_id);
                toast.dark('ID를 클립보드에 복사했습니다', {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  pauseOnFocusLoss: false,
                  draggable: true,
                  progress: undefined,
                  bodyStyle: {
                    color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                    textAlign: 'center',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                  },
                  theme: 'dark',
                });
              }}>
              ID
            </Button>
          </div>
        );
      },
    },
    {
      title: '이름',
      render: (record: Membership) => {
        return (
          <div
            className="membership-badge"
            style={{
              background: record?.color || '#aaaaaa',
            }}>
            {record?.name}
          </div>
        );
      },
    },
    {
      title: '타이틀',
      render: (record: Membership) => {
        return record.joinPage?.title;
      },
    },
    {
      title: '설명',
      render: (record: Membership) => {
        return record.joinPage?.description;
      },
    },
    {
      title: '상태',
      width: 100,
      render: (record: Membership) => {
        if (record.status === 'active') {
          return (
            <div className="badge" style={{ backgroundColor: '#3fb547' }}>
              사용중
            </div>
          );
        }
        return (
          <div className="badge" style={{ backgroundColor: '#bd334a' }}>
            중단
          </div>
        );
      },
    },
    {
      title: 'URL',
      width: 100,
      render: (record: Membership) => {
        return (
          <Button
            onClick={() => {
              navigator.clipboard.writeText(`${WEB_URL}/join/membership/${record.alias}`);
              toast.dark('멤버십 가입 URL을 클립보드에 복사했습니다', {
                position: 'top-center',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                pauseOnFocusLoss: false,
                draggable: true,
                progress: undefined,
                bodyStyle: {
                  color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                  textAlign: 'center',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '20px',
                },
                theme: 'dark',
              });
            }}>
            URL 복사
          </Button>
        );
      },
    },
    {
      title: '수정하기',
      width: 100,
      render: (record: Membership) => {
        return (
          <Button
            onClick={() => {
              setEditItem(record);
              setShowCreateModal(true);
            }}>
            수정
          </Button>
        );
      },
    },
    {
      title: '멤버',
      render: (record: Membership) => {
        return (
          <Button
            onClick={() => {
              window.location.href = `/users?membership=${record.alias}`;
            }}>
            유저 관리에서 보기
          </Button>
        );
      },
    },
    {
      title: '생성',
      dataIndex: 'createdAt',
      render: (createdAt) => {
        if (!createdAt) {
          return '-';
        }
        return moment(createdAt).format('YYYY.MM.DD HH:mm');
      },
    },
    {
      title: '수정',
      dataIndex: 'updatedAt',
      render: (updatedAt) => {
        if (!updatedAt) {
          return '-';
        }
        return moment(updatedAt).format('YYYY.MM.DD HH:mm');
      },
    },
  ];
  return (
    <div className="dashboard" style={{ position: 'relative' }}>
      <MembershipsEditModal
        data={editItem}
        open={showCreateModal}
        onClose={() => {
          setShowCreateModal(false);
          setEditItem(undefined);
        }}
        needRefetch={() => {
          membershipsQuery.refetch();
        }}></MembershipsEditModal>

      <div className="page-header">
        <div>멤버십 페이지 관리</div>
      </div>
      <div style={{ display: 'flex' }}>
        <div className="page-content" style={{ width: '100%', height: 'calc(100vh - 80px)', overflow: 'auto' }}>
          <div style={{ display: 'flex', gap: '12px', width: '100%', height: '52px', alignItems: 'center' }}>
            <Input
              ref={searchInputRef}
              style={{ width: '240px' }}
              value={searchKeyword}
              placeholder={'검색어 입력 후 엔터'}
              onChange={(e) => {
                setSearchKeyword(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.code === 'Enter' && searchKeyword) {
                  store.updateSearchQuery({ keyword: searchKeyword });
                }
              }}></Input>
            <Button
              onClick={() => {
                setSearchKeyword(undefined);
                store.resetSearchQuery();
              }}>
              검색 초기화
            </Button>
            <div style={{ display: 'flex', marginLeft: 'auto', gap: '12px', alignItems: 'center' }}>
              <Button
                onClick={() => {
                  setShowCreateModal(true);
                }}>
                신규 멤버십 생성
              </Button>
            </div>
          </div>
          <Table
            columns={TABLE_COLUMNS}
            scroll={{ x: 'max-content', y: 'calc(100dvh - 280px)' }}
            style={{ whiteSpace: 'pre-wrap' }}
            rowKey={(record) => record?._id}
            dataSource={store.searchResultContainer.contents.filter((item) => item?._id)}
            pagination={{
              current: (store.searchResultContainer.page || 0) + 1,
              pageSize: store.searchResultContainer.size || 20,
              total: store.searchResultContainer.total,
            }}
            loading={membershipsQuery.isLoading}
            onChange={(e) => {
              store.updateSearchQuery({ page: (e.current || 2) - 1, size: e.pageSize || 20 });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default MembershipsPage;
