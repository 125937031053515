import { SelectProductCollection } from '@components/feed/SelectProductCollection';
import SelectProductModal from '@components/product/SelectProductModal';
import { SelectUser } from '@components/product/SelectUser';
import { DeeplinkAction, DeepLinkActions } from '@models/common';
import { FeedDataItem } from '@models/feed';
import { Button, Checkbox, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';

export const DEEPLINK_ACTION_LABELS: { [key: string]: any } = {
  go_to_collection: { label: '기획전으로 이동' },
  go_to_product: { label: '상품상세로 이동' },
  go_to_theme_collection: { label: '테마 기획전으로 이동' },
  search_keyword: { label: '검색하기' },
  go_to_community: { label: '커뮤니티로 이동' },
  go_to_notice: { label: '공지사항으로 이동' },
  open_link: { label: '링크 열기' },
  go_to_coupon: { label: '쿠폰으로 이동' },
  go_to_chat: { label: '채팅으로 이동' },
  go_to_chat_list: { label: '채팅 목록으로 이동' },
  go_to_reward: { label: '리워드 페이지로 이동' },
  go_to_lounge: { label: '라운지로 이동' },
  go_to_favorites: { label: '나의 찜 페이지로 이동' },
  go_to_partner: { label: '파트너 프로필로 이동' },
};

export const DeeplinkActionEdit: React.FC<{
  data: Partial<FeedDataItem>;
  onChange: (actionData: DeeplinkAction) => void;
}> = (props) => {
  const [changedActionData, setChangedActionData] = useState<DeeplinkAction>();
  const [showProductSelect, setShowProductSelect] = useState<boolean>(false);
  const action = changedActionData?.action || props.data.action;
  const payload = changedActionData?.payload || props.data.payload;
  const productTitle = changedActionData?.payload?.title || props.data.payload?.title;

  useEffect(() => {
    if (changedActionData) {
      props.onChange(changedActionData);
    }
  }, [changedActionData]);

  useEffect(() => {
    if (!props.data.action && props.data.direction) {
      let action = undefined;
      let payload = undefined;
      if (
        props.data.direction === '통합 기획전' ||
        props.data.direction === '기획전' ||
        props.data.direction === '한정판매 기획전'
      ) {
        action = DeepLinkActions.GO_TO_COLLECTION;
        payload = { title: props.data.direction2_name, collectionId: props.data.direction2 };
      } else if (
        props.data.direction === '클래스 상세' ||
        props.data.direction === '상품상세' ||
        props.data.direction === '한정판매'
      ) {
        action = DeepLinkActions.GO_TO_PRODUCT;
        payload = { title: props.data.direction2_name, productId: props.data.direction2 };
      } else if (props.data.direction === '커뮤니티(리뷰)') {
        action = DeepLinkActions.GO_TO_COMMUNITY;
      } else if (props.data.direction === '직접 링크') {
        action = DeepLinkActions.OPEN_LINK;
        payload = { url: props.data.direction2 };
      } else if (props.data.direction === '검색어 직접 입력') {
        action = DeepLinkActions.SEARCH_KEYWORD;
        payload = { keyword: props.data.direction2 };
      }

      if (action) {
        setChangedActionData({ action, payload });
      }
    }
  }, [props.data]);

  return (
    <div>
      <SelectProductModal
        open={showProductSelect}
        onAddProducts={(products) => {
          setChangedActionData((prev) => {
            return { ...prev, payload: { productId: products[0]._id, title: products[0].title } };
          });
        }}
        onClose={() => {
          setShowProductSelect(false);
        }}></SelectProductModal>
      <Select
        style={{ width: 320 }}
        value={action}
        onChange={(value) => {
          setChangedActionData((prev) => {
            return { ...prev, action: value as DeepLinkActions };
          });
        }}
        options={Object.keys(DEEPLINK_ACTION_LABELS).map((key) => {
          return { value: key, label: DEEPLINK_ACTION_LABELS[key].label };
        })}></Select>
      <div>
        {action === DeepLinkActions.GO_TO_COLLECTION && (
          <div>
            <div>기획전 선택</div>
            <SelectProductCollection
              onSelect={(value) => {
                setChangedActionData((prev) => {
                  return { ...prev, payload: { collectionId: value._id, title: value.title } };
                });
              }}></SelectProductCollection>
            <div style={{ marginTop: '8px' }}>
              <span>선택: </span>
              {productTitle}
            </div>
          </div>
        )}
        {action === DeepLinkActions.GO_TO_PRODUCT && (
          <div style={{ marginTop: '8px' }}>
            <Button
              onClick={() => {
                setShowProductSelect(true);
              }}>
              상품 선택하기
            </Button>
            <div style={{ marginTop: '8px' }}>
              <span>선택: </span>
              {productTitle}
            </div>
          </div>
        )}
        {action === DeepLinkActions.OPEN_LINK && (
          <div style={{ marginTop: '8px' }}>
            <Select
              style={{ width: 320 }}
              value={action}
              onChange={(value) => {
                setChangedActionData((prev) => {
                  return { ...prev, action: value as DeepLinkActions };
                });
              }}
              options={Object.keys(DEEPLINK_ACTION_LABELS).map((key) => {
                return { value: key, label: DEEPLINK_ACTION_LABELS[key].label };
              })}></Select>
            <Input
              value={payload?.url}
              onChange={(e) => {
                setChangedActionData((prev) => {
                  return { ...prev, payload: { url: e.target.value } };
                });
              }}></Input>
          </div>
        )}
        {action === DeepLinkActions.SEARCH_KEYWORD && (
          <div style={{ marginTop: '8px' }}>
            <Input
              value={payload?.keyword}
              onChange={(e) => {
                setChangedActionData((prev) => {
                  return { ...prev, payload: { keyword: e.target.value } };
                });
              }}></Input>
          </div>
        )}
        {action === DeepLinkActions.GO_TO_LOUNGE && (
          <div style={{ marginTop: '8px', display: 'flex', gap: '4px', flexDirection: 'column' }}>
            <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
              <Select
                style={{ width: 320 }}
                value={payload?.tab}
                onChange={(value) => {
                  setChangedActionData((prev) => {
                    return { ...prev, payload: { ...prev?.payload, tab: value } };
                  });
                }}
                options={[
                  { value: 'all', label: '홈' },
                  { value: 'review', label: '후기' },
                  { value: 'curation', label: '콘텐츠발견' },
                ]}></Select>
              <Checkbox
                style={{ flexShrink: 0 }}
                checked={payload?.write}
                onChange={(e) => {
                  setChangedActionData((prev) => {
                    return { ...prev, payload: { ...prev?.payload, write: e.target.checked } };
                  });
                }}>
                글작성
              </Checkbox>
            </div>

            <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
              <div style={{ flexShrink: 0 }}>콘텐츠 ID</div>
              <Input
                value={payload?.userContentId}
                onChange={(e) => {
                  setChangedActionData((prev) => {
                    return { ...prev, payload: { ...prev?.payload, userContentId: e.target.value } };
                  });
                }}></Input>
            </div>
          </div>
        )}
        {action === DeepLinkActions.GO_TO_FAVORITES && (
          <div style={{ marginTop: '8px', display: 'flex', gap: '4px', flexDirection: 'column' }}>
            <Select
              style={{ width: 320 }}
              value={payload?.tab}
              onChange={(value) => {
                setChangedActionData((prev) => {
                  return { ...prev, payload: { ...prev?.payload, tab: value } };
                });
              }}
              options={[
                { value: 'product', label: '상품' },
                { value: 'partner', label: '파트너' },
                { value: 'user-content', label: '라운지' },
              ]}></Select>
          </div>
        )}
        {action === DeepLinkActions.GO_TO_PARTNER && (
          <div style={{ marginTop: '8px', display: 'flex', gap: '4px', flexDirection: 'column' }}>
            <SelectUser
              style={{ width: '528px' }}
              onSelect={(partner) => {
                setChangedActionData((prev) => {
                  return { ...prev, payload: { ...prev?.payload, partnerId: partner._id } } as any;
                });
              }}></SelectUser>
          </div>
        )}
      </div>
    </div>
  );
};

export default DeeplinkActionEdit;
