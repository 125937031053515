import { PaymentStatementPurchase } from './paymentStatement';

export enum RefundLogStatus {
  DONE = 'done',
  REQUESTED = 'requested',
  ACCEPTED = 'accepted',
  DENIED = 'denied',
  RESERVED = 'reserved',
  RESERVE_CANCELED = 'reserve_canceled',
  ADMIN_SUBMISSON = 'admin_submission',
  ADMIN_SUBMISSON_CANCELED = 'admin_submission_canceled',
}
export enum UserRole {
  USER = 'user',
  PARTNER = 'partner',
}

export interface RefundUser {
  id: string;
  name: string;
  phone: string;
  role: UserRole;
}

export interface TossPaymentsCancelData {
  transactionKey: string;
  cancelReason: string;
  taxExemptionAmount: number;
  canceledAt: string;
  easyPayDiscountAmount: number;
  receiptKey: string | null;
  cancelAmount: number;
  taxFreeAmount: number;
  refundableAmount: number;
  cancelStatus: string;
  cancelRequestId: string | null;
}

export interface IamportPaymentsCancelData {
  pg_tid: string;
  amount: number;
  cancelled_at: string;
  reason: string;
  receipt_url: string;
}

export interface RefundLog {
  _id: string;
  paymentStatementId: string;
  status: RefundLogStatus;
  refundItems: PaymentStatementPurchase[];
  user: RefundUser;
  referenceId?: string;
  memo?: string;
  amount?: number;
  billingAmount?: number;
  point?: number;
  receiptUrl?: string;
  cancelData?: TossPaymentsCancelData | IamportPaymentsCancelData;
  noMoreRefundsAvailable?: boolean;
  createdAt: Date;
}
