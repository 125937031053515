import * as api from '@apis/admin-credit-points';
import { CreatePointCouponBody } from '@models/credit-points';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { useMutation } from '@tanstack/react-query';
import { Button, Checkbox, DatePicker, Input, Modal, Radio } from 'antd';
import moment from 'moment';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React, { useState } from 'react';

export interface PointCouponCreateModalProps {
  open?: boolean;
  onClose?: () => void;
  needRefetch?: () => void;
}

export const PointCouponCreateModal: React.FC<PointCouponCreateModalProps> = (props) => {
  const [createPointCouponData, setCreatePointCouponData] = useState<CreatePointCouponBody>();

  const dialog = useOkCancelDialog();

  const createPointCouponMutation = useMutation({
    mutationFn: api.createCreditPointCoupon,
    onSuccess: async () => {
      props.onClose && props.onClose();
      props.needRefetch && props.needRefetch();
      dialog.open({
        content: '생성되었습니다.',
        type: 'ok',
      });
    },
    onError: async (e: any) => {
      props.onClose && props.onClose();
      props.needRefetch && props.needRefetch();
      dialog.open({
        title: '오류',
        content: e?.response?.data?.message,
        type: 'ok',
      });
    },
  });

  return (
    <Modal
      styles={{ footer: { height: '44px' } }}
      open={props.open}
      footer={
        <div style={{ width: '100%' }}>
          <Button
            style={{
              width: 120,
            }}
            onClick={props.onClose}>
            취소
          </Button>
          <Button
            style={{
              width: 120,
              marginLeft: '8px',
            }}
            onClick={() => {
              if (createPointCouponData) {
                createPointCouponMutation.mutate(createPointCouponData);
              }
            }}>
            추가하기
          </Button>
        </div>
      }
      onCancel={props.onClose}>
      <OverlayScrollbarsComponent
        defer
        options={{ scrollbars: { autoHide: 'scroll' } }}
        style={{
          backgroundColor: '#ffffff',
          marginTop: 0,
          position: 'relative',
          height: 'calc(100dvh - 240px)',
          overflow: 'auto',
        }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              fontSize: 20,
              fontWeight: 'bold',
              textAlign: 'center',
              marginBottom: 10,
            }}>
            쿠폰 생성
          </div>
          <div style={{ textAlign: 'left', padding: 50 }}>
            <div style={{ marginTop: 10 }}>
              쿠폰 이름
              <Input
                placeholder="쿠폰 이름을 입력하세요"
                value={createPointCouponData?.couponName}
                onChange={(e) => {
                  setCreatePointCouponData((prev) => {
                    return { ...prev, couponName: e.target.value } as CreatePointCouponBody;
                  });
                }}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              적립 포인트
              <Input
                placeholder="적립 포인트를 입력하세요"
                value={createPointCouponData?.creditPoints}
                onChange={(e) => {
                  setCreatePointCouponData((prev) => {
                    return { ...prev, creditPoints: Number(e.target.value) || 0 } as CreatePointCouponBody;
                  });
                }}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              쿠폰타입
              <br />
              <Radio.Group
                onChange={(e) => {
                  setCreatePointCouponData((prev) => {
                    return { ...prev, couponType: e.target.value } as CreatePointCouponBody;
                  });
                }}
                value={createPointCouponData?.couponType}>
                <Radio value={'multi'}>반복 쿠폰</Radio>
                <Radio value={'one'}>1회용 쿠폰</Radio>
              </Radio.Group>
              <div style={{ marginLeft: '24px' }}>
                {createPointCouponData?.couponType === 'multi' && (
                  <div>
                    <div style={{ marginTop: 10 }}>
                      반복쿠폰 코드
                      <Input
                        placeholder="반복쿠폰 코드 입력"
                        value={createPointCouponData?.couponKeyName}
                        onChange={(e) => {
                          setCreatePointCouponData((prev) => {
                            return {
                              ...prev,
                              couponKeyName: e.target.value.toUpperCase(),
                            } as CreatePointCouponBody;
                          });
                        }}
                      />
                    </div>
                    <div style={{ marginTop: 10 }}>
                      반복쿠폰 코드 발행 개수( 입력 값 없을 시 기간내 무제한 )
                      <Input
                        placeholder="개수 입력"
                        value={createPointCouponData?.createEventCouponNumber}
                        onChange={(e) => {
                          setCreatePointCouponData((prev) => {
                            return {
                              ...prev,
                              createEventCouponNumber: Number(e.target.value) || 0,
                            } as CreatePointCouponBody;
                          });
                        }}
                      />
                    </div>
                  </div>
                )}
                {createPointCouponData?.couponType === 'one' && (
                  <div>
                    <div style={{ marginTop: 10 }}>
                      쿠폰 개수 입력(1회성 소진 쿠폰)
                      <Checkbox
                        checked={createPointCouponData?.isBlockDuplicate}
                        onChange={(e) => {
                          setCreatePointCouponData((prev) => {
                            return {
                              ...prev,
                              isBlockDuplicate: e.target.checked,
                            } as CreatePointCouponBody;
                          });
                        }}>
                        중복 적립 불가
                      </Checkbox>
                      <Input
                        placeholder="쿠폰 개수 입력(1회성 소진 쿠폰)"
                        value={createPointCouponData?.onetimeCouponCount}
                        onChange={(e) => {
                          setCreatePointCouponData((prev) => {
                            return {
                              ...prev,
                              onetimeCouponCount: Number(e.target.value) || 0,
                            } as CreatePointCouponBody;
                          });
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div style={{ marginTop: 10 }}>
              가입일(선택) - 설정일 이후 가입자만 사용 가능
              <br />
              <DatePicker
                showTime={{ showSecond: false }}
                onChange={(value, dateString) => {
                  setCreatePointCouponData((prev) => {
                    return {
                      ...prev,
                      requiredSignUpDate: moment(dateString, 'YYYY-MM-DD HH:mm:00').toDate(),
                    } as CreatePointCouponBody;
                  });
                }}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              쿠폰 만료일시
              <br />
              <DatePicker
                showTime={{ showSecond: false }}
                onChange={(value, dateString) => {
                  setCreatePointCouponData((prev) => {
                    return {
                      ...prev,
                      validEndDate: moment(dateString, 'YYYY-MM-DD HH:mm:00').toDate(),
                    } as CreatePointCouponBody;
                  });
                }}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              쿠폰 상세 설명
              <Input
                value={createPointCouponData?.couponDescription}
                placeholder="쿠폰 상세 설명"
                onChange={(e) => {
                  setCreatePointCouponData((prev) => {
                    return {
                      ...prev,
                      couponDescription: e.target.value,
                    } as CreatePointCouponBody;
                  });
                }}
              />
            </div>
          </div>
        </div>
      </OverlayScrollbarsComponent>
    </Modal>
  );
};
