import * as productsApi from '@apis/product';
import { ReactComponent as ChevronDownIcon } from '@assets/images/app/chevron-down-nutral90.svg';
import { ReactComponent as ChevronUpIcon } from '@assets/images/app/chevron-up-nutral90.svg';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const COLOR_NAVER = '#03c75a';

const ProductsInventoryPage: React.FC = () => {
  const [showRemain, setShowRemain] = useState<{ [key: string]: boolean | undefined }>({});
  const [showDelete, setShowDelete] = useState<{ [key: string]: boolean | undefined }>({});
  const [summary, setSummary] = useState({
    total: 0,
    totalOption: 0,
    totalNaverOption: 0,
    differentOption: 0,
    noRemainNaverOption: 0,
  });
  const dialog = useOkCancelDialog();
  const deadlineProductsQuery = useQuery({
    queryKey: [`deadline-products`],
    queryFn: () => productsApi.getCloseCandidateProductAndOptions(),
  });

  const closeOptionsMutation = useMutation({
    mutationFn: productsApi.closeProductOptions,
    onSuccess: async () => {
      deadlineProductsQuery.refetch();
      toast.dark('해당되는 재고를 마감했습니다.', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: false,
        draggable: true,
        progress: undefined,
        bodyStyle: {
          color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
          textAlign: 'center',
          fontFamily: 'Pretendard',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '20px',
        },
        theme: 'dark',
      });
    },
  });

  const deadlineProducts = deadlineProductsQuery.data?.data || [];

  useEffect(() => {
    if (deadlineProducts.length > 0) {
      const summary = {
        total: deadlineProducts.length,
        totalOption: 0,
        totalNaverOption: 0,
        differentOption: 0,
        noRemainNaverOption: 0,
      };
      deadlineProducts.forEach((item) => {
        const hasNaverProduct = !!item.naverProduct?.originProduct?.name;
        const remainOptions = item.remainOptions || [];
        const remainNaverOptions =
          item.naverProduct?.originProduct?.detailAttribute?.optionInfo?.remainOptionCombinations || [];

        summary.totalOption = summary.totalOption + item.options.length;
        summary.totalNaverOption =
          summary.totalNaverOption +
          (item.naverProduct?.originProduct?.detailAttribute?.optionInfo?.optionCombinations || []).length;
        if (hasNaverProduct && remainOptions.length !== remainNaverOptions.length) {
          summary.differentOption = summary.differentOption + 1;
          // setShowRemain((value) => {
          //   const newValue = { ...value };
          //   newValue[`${index}`] = true;
          //   return newValue;
          // });
        }

        if ((hasNaverProduct && remainNaverOptions.length === 0) || remainOptions.length === 0) {
          summary.noRemainNaverOption = summary.noRemainNaverOption + 1;
        }
      });
      setSummary(summary);
    }
  }, [deadlineProducts]);

  return (
    <div className="notification" style={{ position: 'relative' }}>
      <div className="page-header">
        <div>상품 재고 마감</div>
        <div style={{ display: 'flex', gap: '4px' }}></div>
      </div>
      <div className="page-content" style={{ width: '100%', height: 'calc(100vh - 80px)', overflow: 'auto' }}>
        {deadlineProductsQuery.isLoading && <div>불러오는 중 입니다....</div>}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            border: '1px solid #eaeaea',
            background: 'white',
            padding: '12px',
            borderRadius: '12px',
            width: '720px',
            marginBottom: '20px',
          }}>
          <div style={{ display: 'flex', gap: '8px' }}>
            <div
              style={{ fontWeight: 'bold', textAlign: 'right', display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <div>전체 상품</div>
              <div>삭제 대상 옵션(앱)</div>
              <div>삭제 대상 옵션(네이버)</div>
              <div style={{ background: '#f5c842' }}>남은 옵션 차이 상품</div>
              <div style={{ background: '#f54e42' }}>모든 옵션 삭제 상품</div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <div>{`${summary.total}개`}</div>
              <div>{`${summary.totalOption}개`}</div>
              <div>{`${summary.totalNaverOption}개`}</div>
              <div style={{ fontWeight: 'bold' }}>{`${summary.differentOption}개`}</div>
              <div style={{ fontWeight: 'bold' }}>{`${summary.noRemainNaverOption}개`}</div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <Button
                onClick={() => {
                  setShowDelete((value) => {
                    const newValue = { ...value };
                    deadlineProducts.forEach((item, index) => {
                      newValue[`${index}`] = true;
                    });
                    return newValue;
                  });
                }}>
                모든 삭제 옵션 펼치기
              </Button>
              <Button
                onClick={() => {
                  setShowDelete({});
                }}>
                모든 삭제 옵션 접기
              </Button>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <Button
                onClick={() => {
                  setShowRemain((value) => {
                    const newValue = { ...value };
                    deadlineProducts.forEach((item, index) => {
                      newValue[`${index}`] = true;
                    });
                    return newValue;
                  });
                }}>
                모든 유지 옵션 펼치기
              </Button>
              <Button
                onClick={() => {
                  setShowRemain({});
                }}>
                모든 유지 옵션 접기
              </Button>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <Button
                onClick={() => {
                  dialog.open({
                    title: '삭제 대상 옵션 모두 마감',
                    content: `${summary.totalOption}개의 앱 옵션\n${summary.totalNaverOption}개의 네이버 옵션을 삭제합니다.`,
                    type: 'ok_cancel',
                    onConfirm: () => {
                      closeOptionsMutation.mutate();
                    },
                  });
                }}>
                삭제 대상 옵션 모두 마감
              </Button>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '12px' }}>
          {deadlineProducts.map((item, index) => {
            const hasNaverProduct = !!item.naverProduct?.originProduct?.name;
            const remainOptions = item.remainOptions || [];
            const remainNaverOptions =
              item.naverProduct?.originProduct?.detailAttribute?.optionInfo?.remainOptionCombinations || [];
            const remainOptionNames = remainOptions.map((item) => item.step.join(' / ').replace(/ /g, ''));
            const remainNaverOptionNames = remainNaverOptions.map((item) =>
              `${item.optionName1} / ${item.optionName2} / ${item.optionName3}`
                .replace(/\/ undefined/g, '')
                .replace(/ /g, ''),
            );

            const differentRemainOption = hasNaverProduct && remainOptions.length !== remainNaverOptions.length;
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '4px',
                  border: '1px solid #eaeaea',
                  background: 'white',
                  padding: '12px',
                  borderRadius: '12px',
                  minWidth: '720px',
                }}>
                <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                  <div
                    style={{
                      borderRadius: '4px',
                      background: '#424242',
                      color: 'white',
                      fontWeight: 'bold',
                      height: '24px',
                      padding: '0 8px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    {index + 1}
                  </div>
                  <div style={{ fontWeight: 'bold' }}>{item.product.sellerName}</div>
                  <div>{item.product.title}</div>
                </div>
                <div style={{ display: 'flex', gap: '8px', alignItems: 'center', justifyContent: 'flex-end' }}>
                  <Button
                    onClick={() => {
                      navigator.clipboard.writeText(item._id);
                      toast.dark('ID를 클립보드에 복사했습니다', {
                        position: 'top-center',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        pauseOnFocusLoss: false,
                        draggable: true,
                        progress: undefined,
                        bodyStyle: {
                          color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                          textAlign: 'center',
                          fontFamily: 'Pretendard',
                          fontSize: '14px',
                          fontStyle: 'normal',
                          fontWeight: 500,
                          lineHeight: '20px',
                        },
                        theme: 'dark',
                      });
                    }}>
                    상품 ID 복사
                  </Button>
                  <Button
                    onClick={() => {
                      window.open(`https://app.igogo.kr/product/${item.product._id}`, '_blank');
                    }}>
                    앱에서 보기
                  </Button>
                  {hasNaverProduct && (
                    <Button
                      onClick={() => {
                        window.open(
                          `https://smartstore.naver.com/igogo/products/${item.naverProduct?.channelProductNo}`,
                          '_blank',
                        );
                      }}>
                      네이버에서 보기
                    </Button>
                  )}
                </div>
                {hasNaverProduct && (
                  <div
                    style={{
                      display: 'flex',
                      gap: '4px',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: COLOR_NAVER,
                      color: 'white',
                      padding: '4px 8px',
                      fontWeight: 'bold',
                    }}>
                    {item.naverProduct?.originProduct?.name}
                  </div>
                )}
                <div>
                  <div
                    style={{
                      padding: '4px 8px',
                      fontWeight: 'bold',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      background: '#eaeaea',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setShowDelete((value) => {
                        const newValue = { ...value };
                        newValue[`${index}`] = !newValue[`${index}`];
                        return newValue;
                      });
                    }}>
                    <div>
                      {hasNaverProduct
                        ? `삭제 옵션(${item.options.length}개 / ${item.naverProduct?.originProduct?.detailAttribute?.optionInfo?.optionCombinations.length}개)`
                        : `삭제 옵션(${item.options.length}개)`}
                    </div>
                    <div>{showDelete[`${index}`] ? <ChevronUpIcon /> : <ChevronDownIcon />}</div>
                  </div>
                  {showDelete[`${index}`] && (
                    <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-around' }}>
                      <div style={{ flex: 1 }}>
                        <div
                          style={{
                            padding: '4px 8px',
                            fontWeight: 'bold',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: '#eaeaea',
                          }}>
                          {`앱(${item.options.length}개)`}
                        </div>
                        <div>
                          {item.options
                            .sort((lv, rv) => new Date(lv.date || '').getTime() - new Date(rv.date || '').getTime())
                            .map((option, optionIndex) => {
                              const optionName = option.step.join(' / ');
                              return (
                                <div
                                  key={`product-${index}-option-${optionIndex}`}
                                  style={{
                                    display: 'flex',
                                    gap: '8px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}>
                                  <div>{optionName}</div>
                                  <div>{option.inventory}</div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                      {hasNaverProduct && (
                        <div style={{ flex: 1 }}>
                          <div
                            style={{
                              backgroundColor: COLOR_NAVER,
                              color: 'white',
                              padding: '4px 8px',
                              fontWeight: 'bold',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}>
                            {`네이버 스토어(${item.naverProduct?.originProduct?.detailAttribute?.optionInfo?.optionCombinations.length}개)`}
                          </div>
                          <div>
                            <div>
                              {(item.naverProduct?.originProduct?.detailAttribute?.optionInfo?.optionCombinations || [])
                                .sort((lv, rv) => new Date(lv.date || '').getTime() - new Date(rv.date || '').getTime())
                                .map((option, optionIndex) => {
                                  const optionName =
                                    `${option.optionName1} / ${option.optionName2} / ${option.optionName3}`.replace(
                                      /\/ undefined/g,
                                      '',
                                    );

                                  return (
                                    <div
                                      key={`naver-product-${index}-option-${optionIndex}`}
                                      style={{
                                        display: 'flex',
                                        gap: '8px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '22px',
                                      }}>
                                      <div>{optionName}</div>
                                      <div>{option.stockQuantity}</div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div>
                  <div
                    style={{
                      padding: '4px 8px',
                      fontWeight: 'bold',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      background:
                        (hasNaverProduct && remainNaverOptions.length === 0) || remainOptions.length === 0
                          ? '#f54e42'
                          : differentRemainOption
                            ? '#f5c842'
                            : '#eaeaea',
                      gap: '8px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setShowRemain((value) => {
                        const newValue = { ...value };
                        newValue[`${index}`] = !newValue[`${index}`];
                        return newValue;
                      });
                    }}>
                    <div>
                      {hasNaverProduct
                        ? `유지 옵션(${remainOptions.length}개 / ${remainNaverOptions.length}개)`
                        : `유지 옵션(${remainOptions.length}개)`}
                    </div>
                    <div>{showRemain[`${index}`] ? <ChevronUpIcon /> : <ChevronDownIcon />}</div>
                  </div>
                  {showRemain[`${index}`] && (
                    <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-around' }}>
                      <div style={{ flex: 1 }}>
                        <div
                          style={{
                            padding: '4px 8px',
                            fontWeight: 'bold',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: '#eaeaea',
                          }}>
                          {`앱(${remainOptions.length}개)`}
                        </div>
                        <div>
                          {remainOptions
                            .sort((lv, rv) => new Date(lv.date || '').getTime() - new Date(rv.date || '').getTime())
                            .map((option, optionIndex) => {
                              const optionName = option.step.join(' / ');
                              return (
                                <div
                                  key={`product-${index}-option-${optionIndex}`}
                                  style={{
                                    display: 'flex',
                                    gap: '8px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}>
                                  <div
                                    style={{
                                      fontWeight:
                                        hasNaverProduct &&
                                        !remainNaverOptionNames.includes(optionName.replace(/ /g, ''))
                                          ? 'bold'
                                          : 'normal',
                                    }}>
                                    {optionName}
                                  </div>
                                  <div>{option.inventory}</div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                      {hasNaverProduct && (
                        <div style={{ flex: 1 }}>
                          <div
                            style={{
                              backgroundColor: COLOR_NAVER,
                              color: 'white',
                              padding: '4px 8px',
                              fontWeight: 'bold',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}>
                            {`네이버 스토어(${remainNaverOptions.length}개)`}
                          </div>
                          <div>
                            {remainNaverOptions
                              .sort((lv, rv) => new Date(lv.date || '').getTime() - new Date(rv.date || '').getTime())
                              .map((option, optionIndex) => {
                                const optionName =
                                  `${option.optionName1} / ${option.optionName2} / ${option.optionName3}`.replace(
                                    /\/ undefined/g,
                                    '',
                                  );
                                return (
                                  <div
                                    key={`naver-product-${index}-option-${optionIndex}`}
                                    style={{
                                      display: 'flex',
                                      gap: '8px',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      height: '22px',
                                    }}>
                                    <div
                                      style={{
                                        fontWeight: remainOptionNames.includes(optionName.replace(/ /g, ''))
                                          ? 'normal'
                                          : 'bold',
                                      }}>
                                      {optionName}
                                    </div>
                                    <div>{option.stockQuantity}</div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ProductsInventoryPage;
