import { PagenatedResponse, SearchPagenateQuery } from '@models/common';
import { ProductCount } from '@models/statistics';
import moment from 'moment';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const PAGE_SIZE = 20;

interface ProductReportContainer {
  contents: ProductCount[];
  page: number;
  size: number;
  total: number;
  hasNext: boolean;
}

export interface ProductReportQuery extends SearchPagenateQuery {
  year: number;
  month?: number;
  day?: number;
  week?: number;
  sort?: string;
}

interface ProductReportStoreProps {
  searchResultContainer: ProductReportContainer;
  searchQuery: ProductReportQuery;
  searchKeyword?: string;
  setStartDate: (date: string | null) => void;
  setEndDate: (date: string | null) => void;
  setSearchKeyword: (keyword: string) => void;
  updateSearchQuery: (query: { [key: string]: any }) => void;
  resetSearchQuery: () => void;
  setProductReportContainer: (result: PagenatedResponse<ProductCount>) => void;
}

const currentYear = parseInt(moment().format('YYYY'));
const currentMonth = parseInt(moment().format('MM'));

export const useProductReportStore = create(
  persist<ProductReportStoreProps>(
    (set) => ({
      searchResultContainer: {
        contents: [] as ProductCount[],
        total: 0,
        page: 0,
        size: 0,
        hasNext: false,
      },
      searchQuery: {
        page: 0,
        size: PAGE_SIZE,
        year: currentYear,
        month: currentMonth,
        day: undefined,
        week: undefined,
        sort: 'view',
      },
      searchKeyword: undefined,
      setStartDate: (date: string | null) => {
        set((state) => {
          return { ...state, searchQuery: { ...state.searchQuery, startDate: date } };
        });
      },
      setEndDate: (date: string | null) => {
        set((state) => {
          return { ...state, searchQuery: { ...state.searchQuery, endDate: date } };
        });
      },
      setProductReportContainer: (result) => {
        set((state) => {
          const contents = new Array(result.total || PAGE_SIZE);
          contents.splice(result.page * result.size, result.size, ...(result?.contents || []));
          const newValue = { ...state.searchResultContainer, ...result, contents: contents };
          return { ...state, searchResultContainer: newValue };
        });
      },
      setSearchKeyword: (keyword: string) => {
        set((state) => {
          return {
            ...state,
            searchKeyword: keyword,
            searchResultContainer: { ...state.searchResultContainer, size: 0 },
          };
        });
      },
      updateSearchQuery: (query: { [key: string]: any }) => {
        set((state) => {
          return {
            ...state,
            searchQuery: { ...state.searchQuery, ...query },
            searchResultContainer: { ...state.searchResultContainer, size: 0 },
          };
        });
      },
      resetSearchQuery: () => {
        set({
          searchQuery: {
            page: 0,
            size: PAGE_SIZE,
            year: currentYear,
            month: currentMonth,
            day: undefined,
            week: undefined,
            sort: 'view',
          },
        });
      },
    }),
    {
      name: 'igogo-product-report',
    },
  ),
);
