export const STAGE = process.env.REACT_APP_STAGE || 'local';
export const API_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

export const IMP_ID = process.env.REACT_APP_IMP_ID || '';
export const IMP_MERCHANT_ID_CLASS = process.env.REACT_APP_IMP_MERCHANT_ID_CLASS || '';
export const IMP_MERCHANT_ID_COMMERCE = process.env.REACT_APP_IMP_MERCHANT_ID_COMMERCE || '';
export const IMP_PAYMENT_HOOK_PATH = process.env.REACT_APP_IMP_PAYMENT_HOOK_PATH || '';

export const DAUM_POSTCODE_URL = process.env.REACT_APP_DAUM_POSTCODE_URL || '';

export const APP_BASE_PATH = process.env.REACT_APP_APP_BASE_PATH || '';

export const CDN_BASE_URL = process.env.REACT_APP_CDN_BASE_URL;

export const PRODUCT_SHARE_BASE_URL = process.env.REACT_APP_PRODUCT_SHARE_BASE_URL || 'https://s.igogo.kr';
export const COLLECTION_SHARE_BASE_URL = process.env.REACT_APP_COLLECTION_SHARE_BASE_URL || 'https://s.igogo.kr/c';
export const WEB_URL = process.env.REACT_APP_WEB_URL || 'https://app.igogo.kr';
