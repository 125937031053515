import * as api from '@apis/join-promotions';
import { useAuthentication } from '@hooks/authentication';
import { JoinPromotionLog } from '@models/join-promotion';
import { useJoinPromotionLogsStore } from '@stores/joinPromotionLogsStore';
import { useQuery } from '@tanstack/react-query';
import { localTimeToUtcISOString, utcTimcToLocalISOString } from '@utils/date';
import { Button, DatePicker, DatePickerProps, Input, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

export const JoinPromotionLogsPage: React.FC = () => {
  const [searchKeyword, setSearchKeyword] = useState<string>();
  const searchInputRef = useRef<any>();

  const store = useJoinPromotionLogsStore();

  useAuthentication();

  const couponLogsQuery = useQuery({
    queryKey: [`coupon-logs`, JSON.stringify(store.searchQuery)],
    queryFn: () => api.searchJoinPromotionLogs(store.searchQuery),
  });

  const couponLogs = couponLogsQuery.data?.data;

  useEffect(() => {
    if (couponLogs) {
      store.setJoinPromotionLogsContainer(couponLogs);
    }
  }, [couponLogs]);

  const TABLE_COLUMNS: ColumnsType<JoinPromotionLog> = [
    {
      title: 'ID',
      dataIndex: '_id',
      render: (_id) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(_id);
                toast.dark('ID를 클립보드에 복사했습니다', {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  pauseOnFocusLoss: false,
                  draggable: true,
                  progress: undefined,
                  bodyStyle: {
                    color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                    textAlign: 'center',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                  },
                  theme: 'dark',
                });
              }}>
              ID
            </Button>
          </div>
        );
      },
    },
    {
      title: '정보',
      width: 200,
      render: (record: JoinPromotionLog) => {
        if (record.promotion) {
          return (
            <div>
              <div>{record.promotion?.name}</div>
              <div>{`이벤트 코드: ${record.promotion?.eventCode}`}</div>
            </div>
          );
        } else if (record.invitation) {
          return (
            <div>
              <div>
                <Button
                  onClick={() => {
                    if (record.invitation?.inviterId) {
                      navigator.clipboard.writeText(record.invitation?.inviterId);
                      toast.dark('ID를 클립보드에 복사했습니다', {
                        position: 'top-center',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        pauseOnFocusLoss: false,
                        draggable: true,
                        progress: undefined,
                        bodyStyle: {
                          color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                          textAlign: 'center',
                          fontFamily: 'Pretendard',
                          fontSize: '14px',
                          fontStyle: 'normal',
                          fontWeight: 500,
                          lineHeight: '20px',
                        },
                        theme: 'dark',
                      });
                    }
                  }}>
                  초대한 유저 ID
                </Button>
              </div>
              <div>{`초대 코드: ${record.invitation?.rewardCode}`}</div>
            </div>
          );
        }

        return '-';
      },
    },

    {
      title: '유저 전화번호',
      width: 200,
      render: (record: JoinPromotionLog) => {
        return record.userPhone;
      },
    },
    {
      title: '지급 포인트',
      width: 200,
      render: (record: JoinPromotionLog) => {
        return record.creditPoint;
      },
    },
    {
      title: '상태',
      width: 200,
      render: (record: JoinPromotionLog) => {
        const TEXT: { [key: string]: string } = { success: '지급', rejected: '지급 거절' };
        return (
          <div>
            <div>{record.status ? TEXT[record.status] : record.status}</div>
            {record.reason && <div>{record.reason}</div>}
          </div>
        );
      },
    },
    {
      title: '생성',
      dataIndex: 'createdAt',
      render: (createdAt) => {
        if (!createdAt) {
          return '-';
        }
        return moment(createdAt).format('YYYY.MM.DD HH:mm');
      },
    },
  ];

  const onChangeStartDate: DatePickerProps['onChange'] = (date) => {
    store.setStartDate(localTimeToUtcISOString(date ? new Date(date.toISOString()) : new Date(), { timeToZero: true }));
  };

  const onChangeEndDate: DatePickerProps['onChange'] = (date) => {
    store.setEndDate(localTimeToUtcISOString(date ? new Date(date.toISOString()) : new Date(), { timeToZero: true }));
  };

  return (
    <div className="dashboard" style={{ position: 'relative' }}>
      <div className="page-header">
        <div>초대/이벤트 회원가입 내역</div>
        <div style={{ display: 'flex', gap: '8px' }}>
          <DatePicker
            onChange={onChangeStartDate}
            value={dayjs(utcTimcToLocalISOString(store.searchQuery.startDate as any))}
          />
          <DatePicker
            onChange={onChangeEndDate}
            value={dayjs(utcTimcToLocalISOString(store.searchQuery.endDate as any))}
          />
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <div className="page-content" style={{ width: '100%', height: 'calc(100vh - 80px)', overflow: 'auto' }}>
          <div style={{ display: 'flex', gap: '12px', width: '100%', height: '52px', alignItems: 'center' }}>
            <Select
              style={{ width: '200px' }}
              placeholder={'상태'}
              options={[
                { value: 'all', label: '전체' },
                { value: 'success', label: '지급' },
                { value: 'rejected', label: '지급 거절' },
              ]}
              onChange={(value) => {
                store.updateSearchQuery({ status: value });
              }}></Select>
            <Input
              ref={searchInputRef}
              style={{ width: '240px' }}
              value={searchKeyword}
              placeholder={'검색어 입력 후 엔터'}
              onChange={(e) => {
                setSearchKeyword(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.code === 'Enter' && searchKeyword) {
                  store.updateSearchQuery({ keyword: searchKeyword });
                }
              }}></Input>

            <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
              <Button
                onClick={() => {
                  setSearchKeyword(undefined);
                  store.resetSearchQuery();
                }}>
                검색 초기화
              </Button>
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <Button
                onClick={async () => {
                  const fileName = `igogo-invitation-rank.xlsx`;
                  const blob = await api.exportInvitationRank(store.searchQuery?.startDate, store.searchQuery?.endDate);
                  if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
                    // IE variant
                    (window.navigator as any).msSaveOrOpenBlob(new Blob(blob.data), fileName);
                  } else {
                    const url = window.URL.createObjectURL(blob.data);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode && link.parentNode.removeChild(link);
                  }
                }}>
                누적 순위 엑셀 파일
              </Button>
            </div>
          </div>
          <Table
            columns={TABLE_COLUMNS}
            scroll={{ x: 'max-content', y: 'calc(100dvh - 280px)' }}
            style={{ whiteSpace: 'pre-wrap' }}
            rowKey={(record) => record?._id}
            dataSource={store.searchResultContainer.contents.filter((item) => item?._id)}
            pagination={{
              current: (store.searchResultContainer.page || 0) + 1,
              pageSize: store.searchResultContainer.size || 20,
              total: store.searchResultContainer.total,
            }}
            loading={couponLogsQuery.isLoading}
            onChange={(e) => {
              store.updateSearchQuery({ page: (e.current || 2) - 1, size: e.pageSize || 20 });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default JoinPromotionLogsPage;
