import { PagenatedResponse, SearchPagenateQuery } from '@models/common';
import { Event } from '@models/event';
import { apiClient } from '.';

export const searchEvents = async (query?: SearchPagenateQuery) => {
  return apiClient.get<PagenatedResponse<Event>>(`/admin/v1/events`, {
    params: query,
  });
};

export const updateEvent = async (formData: FormData) => {
  const eventId = formData.get('eventId');
  return apiClient.put<Event>(`/admin/v1/events/${eventId}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const createEvent = async (formData: FormData) => {
  return apiClient.post<Event>(`/admin/v1/events`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
