import * as api from '@apis/admin-credit-points';
import { CreatePointTemplateBody } from '@models/credit-points';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { useMutation } from '@tanstack/react-query';
import { Button, Input, Modal } from 'antd';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React, { useEffect, useState } from 'react';

export interface PointTemplateCreateModalProps {
  data?: CreatePointTemplateBody;
  open?: boolean;
  onClose?: () => void;
  needRefetch?: () => void;
}

export const PointTemplateCreateModal: React.FC<PointTemplateCreateModalProps> = (props) => {
  const [createPointTemplateData, setCreatePointTemplateData] = useState<CreatePointTemplateBody>();

  const isEditMode = !!props.data;
  const dialog = useOkCancelDialog();

  const createPointTemplateMutation = useMutation({
    mutationFn: api.createCreditPointTemplate,
    onSuccess: async () => {
      dialog.open({
        content: '생성되었습니다.',
        type: 'ok',
        onConfirm: () => {
          props.onClose && props.onClose();
        },
      });
      props.needRefetch && props.needRefetch();
    },
    onError: async (e: any) => {
      dialog.open({
        title: '오류',
        content: e?.response?.data?.message,
        type: 'ok',
      });
    },
  });

  useEffect(() => {
    if (props.data) {
      setCreatePointTemplateData(props.data);
    }
  }, [props.data]);

  const updatePointTemplateMutation = useMutation({
    mutationFn: api.updateCreditPointTemplate,
    onSuccess: async () => {
      props.needRefetch && props.needRefetch();
      props.onClose && props.onClose();
      dialog.open({
        content: '수정했습니다.',
        type: 'ok',
      });
    },
    onError: async (e: any) => {
      props.needRefetch && props.needRefetch();
      props.onClose && props.onClose();
      dialog.open({
        title: '오류',
        content: e?.response?.data?.message,
        type: 'ok',
      });
    },
  });

  return (
    <Modal
      styles={{ footer: { height: '44px' } }}
      open={props.open}
      footer={
        <div style={{ width: '100%' }}>
          <Button
            style={{
              width: 120,
            }}
            onClick={props.onClose}>
            취소
          </Button>
          <Button
            style={{
              width: 120,
              marginLeft: '8px',
            }}
            onClick={() => {
              if (createPointTemplateData) {
                if (isEditMode) {
                  updatePointTemplateMutation.mutate({
                    templateId: createPointTemplateData._id!,
                    body: createPointTemplateData,
                  });
                } else {
                  createPointTemplateMutation.mutate(createPointTemplateData);
                }
              }
            }}>
            {isEditMode ? '수정하기' : '추가하기'}
          </Button>
        </div>
      }
      onCancel={props.onClose}>
      <OverlayScrollbarsComponent
        defer
        options={{ scrollbars: { autoHide: 'scroll' } }}
        style={{
          backgroundColor: '#ffffff',
          marginTop: 0,
          position: 'relative',
          height: 'calc(100dvh - 240px)',
          overflow: 'auto',
        }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              fontSize: 20,
              fontWeight: 'bold',
              textAlign: 'center',
              marginBottom: 10,
            }}>
            {isEditMode ? '포인트 템플릿 편집' : '포인트 템플릿 생성'}
          </div>
          <div style={{ textAlign: 'left', padding: 50 }}>
            <div style={{ marginTop: 10 }}>
              포인트 템플릿 이름
              <Input
                placeholder="포인트 템플릿 이름을 입력하세요"
                value={createPointTemplateData?.creditPointName}
                onChange={(e) => {
                  setCreatePointTemplateData((prev) => {
                    return { ...prev, creditPointName: e.target.value } as CreatePointTemplateBody;
                  });
                }}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              적립 포인트
              <Input
                placeholder="적립 포인트를 입력하세요"
                value={createPointTemplateData?.creditPoint}
                onChange={(e) => {
                  setCreatePointTemplateData((prev) => {
                    return { ...prev, creditPoint: Number(e.target.value) || 0 } as CreatePointTemplateBody;
                  });
                }}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              메모
              <Input
                value={createPointTemplateData?.creditPointMemo}
                placeholder="메모"
                onChange={(e) => {
                  setCreatePointTemplateData((prev) => {
                    return {
                      ...prev,
                      creditPointMemo: e.target.value,
                    } as CreatePointTemplateBody;
                  });
                }}
              />
            </div>
          </div>
        </div>
      </OverlayScrollbarsComponent>
    </Modal>
  );
};
