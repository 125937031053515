import { closestCenter, DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Feed, FeedData } from '@models/feed';
import { Button, Input } from 'antd';
import React, { useEffect, useRef, useState } from 'react';

interface SortableLoungeCategoriesItemProps {
  onDeleteItem?: (event: any) => void;
  onChange?: (item: FeedData) => void;
  id: any;
  item: FeedData;
}

export const SortableLoungeCategoriesItem: React.FC<SortableLoungeCategoriesItemProps> = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const { onDeleteItem, onChange, item } = props;

  return (
    <div ref={setNodeRef} style={{ ...style }} {...attributes} {...listeners}>
      <div>
        <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
          <Input
            style={{ flex: 1 }}
            value={item.type}
            onChange={(e) => {
              onChange && onChange({ ...item, type: e.target.value });
            }}></Input>
          <Input
            style={{ flex: 2 }}
            value={item.title}
            onChange={(e) => {
              onChange && onChange({ ...item, title: e.target.value });
            }}></Input>
          <Button
            style={{ color: 'red' }}
            onClick={(e) => {
              onDeleteItem && onDeleteItem(e);
            }}>
            삭제
          </Button>
        </div>
      </div>
    </div>
  );
};

export const LoungeCategoriesEdit: React.FC<{
  clear?: boolean;
  data?: Partial<Feed>;
  onChange?: (data: Partial<Feed>) => void;
}> = (props) => {
  const newItemInputRef = useRef<any>();
  const [changedContents, setChangedContents] = useState<FeedData[]>();
  const [newData, setNewData] = useState<Partial<FeedData>>({});

  const { data } = props;
  const contents = changedContents || data?.data || [];

  const sortableSensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 10 },
    }),
  );

  useEffect(() => {
    if (props.clear) {
      setChangedContents(undefined);
    }
  }, [props.clear]);

  useEffect(() => {
    if (changedContents) {
      props.onChange && props.onChange({ ...data, data: changedContents });
    }
  }, [changedContents]);

  const onSortEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      if (contents) {
        const oldIndex = contents.findIndex((item) => item.type === active.id);
        const newIndex = contents.findIndex((item) => item.type === over?.id);
        const newData = arrayMove(contents, oldIndex, newIndex);
        setChangedContents(newData);
      }
    }
  };

  return (
    <div>
      <div style={{ marginTop: '8px' }}>
        <div style={{ display: 'flex', gap: '4px', alignItems: 'center', maxWidth: '600px' }}>
          <div style={{ fontWeight: 'bold', flex: 1, padding: '8px' }}>키값</div>
          <div style={{ fontWeight: 'bold', flex: 2, padding: '8px' }}>라벨(한글)</div>
          <div style={{ width: '56px' }}></div>
        </div>
        <div style={{ display: 'flex', gap: '4px', alignItems: 'center', maxWidth: '600px' }}>
          <Input
            ref={newItemInputRef}
            style={{ flex: 1 }}
            value={newData.type}
            placeholder={'key'}
            onChange={(e) => {
              setNewData((prev) => {
                return { ...prev, type: e.target.value };
              });
            }}></Input>
          <Input
            style={{ flex: 2 }}
            value={newData.title}
            placeholder={'라벨'}
            onChange={(e) => {
              setNewData((prev) => {
                return { ...prev, title: e.target.value };
              });
            }}
            onKeyUp={(e) => {
              if (e.code === 'Enter') {
                if (newData && Object.keys(newData).length > 0) {
                  setChangedContents((prev) => {
                    const newContents = [...(prev || data?.data || [])];
                    const found = newContents.find((item) => {
                      return newData.type === item.type;
                    });
                    if (!found) {
                      newContents.unshift(newData as FeedData);
                    }
                    return newContents;
                  });
                  setNewData({});
                  if (newItemInputRef.current) {
                    newItemInputRef.current.focus();
                  }
                }
              }
            }}></Input>
          <Button
            onClick={() => {
              if (newData && Object.keys(newData).length > 0) {
                setChangedContents((prev) => {
                  const newContents = [...(prev || data?.data || [])];
                  const found = newContents.find((item) => {
                    return newData.type === item.type;
                  });
                  if (!found) {
                    newContents.unshift(newData as FeedData);
                  }
                  return newContents;
                });
                setNewData({});
                if (newItemInputRef.current) {
                  newItemInputRef.current.focus();
                }
              }
            }}>
            추가
          </Button>
        </div>
      </div>
      <div style={{ marginTop: '8px' }}>
        <DndContext sensors={sortableSensors} collisionDetection={closestCenter} onDragEnd={onSortEnd}>
          <SortableContext
            items={contents.map((item) => {
              return { ...item, id: item.type || '' };
            })}
            strategy={verticalListSortingStrategy}>
            <div style={{ maxWidth: '600px', display: 'flex', flexDirection: 'column', gap: '4px' }}>
              {contents.map((item, index) => {
                return (
                  <SortableLoungeCategoriesItem
                    item={item}
                    id={item.type}
                    onDeleteItem={() => {
                      setChangedContents((prev) => {
                        const newValues = [...(prev || contents)];
                        newValues.splice(index, 1);
                        return newValues;
                      });
                    }}
                    onChange={(item) => {
                      setChangedContents((prev) => {
                        const newValues = [...(prev || contents)];
                        newValues[index] = item;
                        return newValues;
                      });
                    }}></SortableLoungeCategoriesItem>
                );
              })}
            </div>
          </SortableContext>
        </DndContext>
      </div>
    </div>
  );
};

export default LoungeCategoriesEdit;
