import * as api from '@apis/admin-credit-points';
import { PointTemplateCreateModal } from '@components/credit-point/PointTemplateCreateModal';
import { useAuthentication } from '@hooks/authentication';
import { AdminCreditPointTemplate } from '@models/credit-points';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { usePointTemplatesStore } from '@stores/pointTemplatesStore';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Input, Switch, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

export const PointTemplatesPage: React.FC = () => {
  const [editItem, setEditItem] = useState<AdminCreditPointTemplate>();
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [searchKeyword, setSearchKeyword] = useState<string>();
  const searchInputRef = useRef<any>();

  const store = usePointTemplatesStore();
  const dialog = useOkCancelDialog();

  useAuthentication();

  const pointTemplatesQuery = useQuery({
    queryKey: [`point-templates`, JSON.stringify(store.searchQuery)],
    queryFn: () => api.searchCreditPointTemplates(store.searchQuery),
  });

  const updatePointTemplateMutation = useMutation({
    mutationFn: api.updateCreditPointTemplate,
    onSuccess: async () => {
      setEditItem(undefined);
      setShowCreateModal(false);
      dialog.open({
        content: '수정했습니다.',
        type: 'ok',
      });

      pointTemplatesQuery.refetch();
    },
    onError: async (e: any) => {
      setEditItem(undefined);
      setShowCreateModal(false);
      dialog.open({
        title: '오류',
        content: e?.response?.data?.message,
        type: 'ok',
      });
    },
  });

  const pointTemplates = pointTemplatesQuery.data?.data;

  useEffect(() => {
    if (pointTemplates) {
      store.setPointTemplatesContainer(pointTemplates);
    }
  }, [pointTemplates]);

  const TABLE_COLUMNS: ColumnsType<AdminCreditPointTemplate> = [
    {
      title: 'ID',
      dataIndex: '_id',
      render: (_id) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(_id);
                toast.dark('ID를 클립보드에 복사했습니다', {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  pauseOnFocusLoss: false,
                  draggable: true,
                  progress: undefined,
                  bodyStyle: {
                    color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                    textAlign: 'center',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                  },
                  theme: 'dark',
                });
              }}>
              ID
            </Button>
          </div>
        );
      },
    },
    {
      title: '이름',
      render: (record: AdminCreditPointTemplate) => {
        return record.creditPointName;
      },
    },
    {
      title: '메모',
      render: (record: AdminCreditPointTemplate) => {
        return record.creditPointMemo;
      },
    },
    {
      title: '금액',
      render: (record: AdminCreditPointTemplate) => {
        return record.creditPoint;
      },
    },
    {
      title: '상태',
      width: 100,
      render: (record: AdminCreditPointTemplate) => {
        if (record.creditPointStatus) {
          return (
            <div className="badge" style={{ backgroundColor: '#3fb547' }}>
              유효
            </div>
          );
        }
        return (
          <div className="badge" style={{ backgroundColor: '#bd334a' }}>
            중지
          </div>
        );
      },
    },
    {
      title: '수정하기',
      width: 100,
      render: (record: AdminCreditPointTemplate) => {
        return (
          <Button
            onClick={() => {
              setEditItem(record);
              setShowCreateModal(true);
            }}>
            수정
          </Button>
        );
      },
    },
    {
      title: '상태 변경',
      render: (record: AdminCreditPointTemplate) => {
        return (
          <Switch
            checked={record.creditPointStatus}
            onChange={(checked) => {
              updatePointTemplateMutation.mutate({ templateId: record._id, body: { creditPointStatus: checked } });
            }}></Switch>
        );
      },
    },
    {
      title: '생성',
      dataIndex: 'createdAt',
      render: (createdAt) => {
        if (!createdAt) {
          return '-';
        }
        return moment(createdAt).format('YYYY.MM.DD HH:mm');
      },
    },
    {
      title: '수정',
      dataIndex: 'updatedAt',
      render: (updatedAt) => {
        if (!updatedAt) {
          return '-';
        }
        return moment(updatedAt).format('YYYY.MM.DD HH:mm');
      },
    },
  ];
  return (
    <div className="dashboard" style={{ position: 'relative' }}>
      <PointTemplateCreateModal
        data={editItem}
        open={showCreateModal}
        onClose={() => {
          setShowCreateModal(false);
          setEditItem(undefined);
        }}
        needRefetch={() => {
          pointTemplatesQuery.refetch();
        }}></PointTemplateCreateModal>

      <div className="page-header">
        <div>포인트 템플릿 관리</div>
      </div>
      <div style={{ display: 'flex' }}>
        <div className="page-content" style={{ width: '100%', height: 'calc(100vh - 80px)', overflow: 'auto' }}>
          <div style={{ display: 'flex', gap: '12px', width: '100%', height: '52px', alignItems: 'center' }}>
            <Input
              ref={searchInputRef}
              style={{ width: '240px' }}
              value={searchKeyword}
              placeholder={'검색어 입력 후 엔터'}
              onChange={(e) => {
                setSearchKeyword(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.code === 'Enter' && searchKeyword) {
                  store.updateSearchQuery({ keyword: searchKeyword });
                }
              }}></Input>

            <div style={{ display: 'flex', marginLeft: 'auto', gap: '12px', alignItems: 'center' }}>
              <Button
                onClick={() => {
                  setSearchKeyword(undefined);
                  store.resetSearchQuery();
                }}>
                검색 초기화
              </Button>
              <Button
                onClick={() => {
                  setShowCreateModal(true);
                }}>
                신규 템플릿 생성
              </Button>
            </div>
          </div>
          <Table
            columns={TABLE_COLUMNS}
            scroll={{ x: 'max-content', y: 'calc(100dvh - 280px)' }}
            style={{ whiteSpace: 'pre-wrap' }}
            rowKey={(record) => record?._id}
            dataSource={store.searchResultContainer.contents.filter((product) => product?._id)}
            pagination={{
              current: (store.searchResultContainer.page || 0) + 1,
              pageSize: store.searchResultContainer.size || 20,
              total: store.searchResultContainer.total,
            }}
            loading={pointTemplatesQuery.isLoading}
            onChange={(e) => {
              store.updateSearchQuery({ page: (e.current || 2) - 1, size: e.pageSize || 20 });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PointTemplatesPage;
