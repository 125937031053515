import React, { useEffect, useState } from 'react';
import './LoginPage.scss';
import { Input } from 'antd';
import { validateEmail } from '@utils/string';
import { useNavigate } from 'react-router-dom';
import { useAuthentication } from '@hooks/authentication';
import { Roles } from '@consts/role';

export const LoginPage: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const navigate = useNavigate();
  const { authStore, login, logout } = useAuthentication();

  useEffect(() => {
    if (authStore.user?.role === Roles.ADMIN) {
      navigate('/dashboard');
    } else {
      logout();
    }
  }, [authStore.user]);

  return (
    <div
      className="login-page"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100wv',
        height: 'calc(100vh - 66px)',
      }}>
      <div>
        <div style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '16px', textAlign: 'center' }}>
          어드민 로그인
        </div>
        <div
          style={{
            backgroundColor: '#FFFFFF',
            padding: '24px',
            width: '480px',
            borderRadius: '20px',
            boxShadow: '0 20px 27px rgba(0, 0, 0, 0.05)',
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingLeft: '20px',
              paddingRight: '20px',
            }}>
            <Input
              className="login-input"
              value={email}
              onChange={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setEmail(e.target.value);
              }}
              type={'email'}
              placeholder="이메일을 입력해주세요."
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '12px',
              paddingLeft: '20px',
              paddingRight: '20px',
            }}>
            <Input
              className="login-input"
              value={password}
              onChange={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setPassword(e.target.value);
              }}
              type={'password'}
              placeholder="비밀번호를 입력해주세요"
            />
          </div>
          <div style={{ marginTop: '28px', paddingLeft: '20px', paddingRight: '20px' }}>
            <button
              className="login-button"
              disabled={email.length === 0 || password.length === 0 || !validateEmail(email)}
              onClick={() => {
                login({ email, password });
              }}>
              <div
                style={{
                  display: 'flex',
                  fontSize: '18px',
                  fontWeight: 700,
                  color: '#FFFFFF',
                  justifyContent: 'center',
                  textAlign: 'center',
                  flexDirection: 'row',
                  marginLeft: '8px',
                  marginRight: '8px',
                }}>
                로그인
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
