import { DeleteOutlined } from '@ant-design/icons';
import { closestCenter, DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { CommerceFixedOptionItem } from '@models/commerce';
import { ProductOption } from '@models/product';
import { Button, Checkbox, Input, Select, Switch } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { FixedSizeList } from 'react-window';

interface OptionBulkEditProps {
  productOptions?: ProductOption[];
  optionLabels?: string[];
  onChange?: (options: ProductOption[], labels: string[]) => void;
}

const OptionBulkEdit: React.FC<OptionBulkEditProps> = (props) => {
  const [bulkOptionValues, setBulkOptionValues] = useState<{
    type?: string;
    fee?: number;
    inventory?: number;
  }>({});
  const { optionLabels, productOptions } = props;

  const [optionNameValues, setOptionNameValues] = useState<{ name: string; value: string[] }[]>([]);

  return (
    <div style={{ flex: 2, borderRadius: '8px', border: '1px solid #eaeaea', padding: '16px', marginTop: '12px' }}>
      <div style={{ display: 'flex', fontSize: '16px', fontWeight: 'bold', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
          <div>옵션 일괄 수정</div>
          <Button
            onClick={() => {
              const nameValues: { name: string; value: string[] }[] = [];
              (optionLabels || []).forEach((label, index) => {
                nameValues[index] = { name: label, value: [] };
              });
              (productOptions || [])
                .sort((lv, rv) => (lv.index || 0) - (rv.index || 0))
                .forEach((item) => {
                  const step = item.step || [];
                  step.forEach((value, index) => {
                    if (!nameValues[index].value.includes(value)) {
                      nameValues[index].value.push(value);
                    }
                  });
                });
              setOptionNameValues(nameValues);
            }}>
            불러오기
          </Button>
        </div>

        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              <div style={{ fontSize: '14px' }}>옵션 구분</div>
              <Select
                value={bulkOptionValues.type}
                style={{ width: '100px' }}
                options={[
                  { value: 'direct', label: '단순형' },
                  { value: 'sequential', label: '순차형' },
                ]}
                onChange={(value) => {
                  setBulkOptionValues((values) => {
                    return { ...values, type: value };
                  });
                }}
              />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              <div style={{ fontSize: '14px' }}>옵션가</div>
              <Input
                value={bulkOptionValues.fee}
                onChange={(e) => {
                  setBulkOptionValues((values) => {
                    return { ...values, fee: Number(e.target.value) || 0 };
                  });
                }}
                style={{ width: '140px' }}></Input>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              <div style={{ fontSize: '14px' }}>재고</div>
              <Input
                value={bulkOptionValues.inventory}
                onChange={(e) => {
                  setBulkOptionValues((values) => {
                    return { ...values, inventory: Number(e.target.value) || 0 };
                  });
                }}
                style={{ width: '100px' }}></Input>
            </div>
          </div>
          <Button
            style={{ color: 'red' }}
            onClick={() => {
              let steps: (string | undefined)[] = [undefined];
              const optionLabels = optionNameValues.map((item) => {
                const newSteps: string[] = [];
                steps.forEach((step) => {
                  item.value.forEach((value) => {
                    if (step) {
                      newSteps.push(`${step} / ${value}`);
                    } else {
                      newSteps.push(`${value}`);
                    }
                  });
                });
                steps = newSteps;
                return item.name;
              });

              const options: ProductOption[] = steps.map((step, index) => {
                return {
                  fee: bulkOptionValues.fee || 0,
                  index: index,
                  inventory: bulkOptionValues.inventory || 0,
                  step: bulkOptionValues.type === 'direct' ? [step] : step?.split(' / '),
                  type: bulkOptionValues.type || 'sequential',
                } as ProductOption;
              });

              props.onChange && props.onChange(options, optionLabels);
            }}>
            옵션 목록에 적용하기
          </Button>
        </div>
      </div>
      <div style={{ display: 'flex', gap: '8px' }}>
        <div className="edit-product-option-title" style={{ width: '240px' }}>
          옵션명
        </div>
        <div className="edit-product-option-title">옵션값</div>
      </div>
      <div>
        {(optionNameValues || []).map((item, index) => {
          return (
            <div
              key={`purchase-quantity-option-${index}`}
              style={{ display: 'flex', gap: '8px', alignItems: 'flex-start', marginBottom: '8px' }}>
              <Input
                style={{ width: '240px' }}
                value={item.name}
                onChange={(e) => {
                  setOptionNameValues((prev) => {
                    const newValues = [...prev];
                    newValues[index] = { name: e.target.value, value: newValues[index].value };
                    return newValues;
                  });
                }}></Input>
              <Input.TextArea
                value={item.value.join(',')}
                onChange={(e) => {
                  setOptionNameValues((prev) => {
                    const newValues = [...prev];
                    newValues[index] = {
                      name: newValues[index].name,
                      value: e.target.value.split(',').map((value) => value.trim()),
                    };
                    return newValues;
                  });
                }}></Input.TextArea>
              <Button
                onClick={() => {
                  setOptionNameValues((prev) => {
                    const newValues = [...prev];
                    newValues.splice(index, 1);
                    return newValues;
                  });
                }}>
                <DeleteOutlined style={{ color: 'red' }} />
              </Button>
            </div>
          );
        })}
        <Button
          onClick={() => {
            setOptionNameValues((prev) => {
              const newValues = [...prev];
              newValues.push({ name: `new_${Object.keys(optionNameValues).length}`, value: [] });
              return newValues;
            });
          }}>
          추가하기
        </Button>
      </div>
    </div>
  );
};

interface SortableOptionItemProps {
  onDeleteItem?: (event: any) => void;
  onChangeItem?: (value: ProductOption) => void;
  id: any;
  item: ProductOption;
  selected?: boolean;
  style?: any;
  onSelect?: (checked: boolean) => void;
}

export const SortableOptionItem: React.FC<SortableOptionItemProps> = (props) => {
  const [edit, setEdit] = useState<boolean>(false);
  const [newItem, setNewItem] = useState<ProductOption>();
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id, disabled: edit });
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const { onDeleteItem, onChangeItem, item } = props;

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <div className="edit-product-option-line" style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
        <div>
          <Checkbox
            checked={props.selected}
            onChange={(e) => {
              props.onSelect && props.onSelect(e.target.checked);
            }}></Checkbox>
        </div>
        {!edit && (
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <div className="edit-product-option-item" style={{ width: '520px' }}>
              {item.step.join(' / ')}
            </div>
            <div className="edit-product-option-item">{item?.type}</div>
            <div className="edit-product-option-item">{item.fee}</div>
            <div className="edit-product-option-item">{item.inventory}</div>
          </div>
        )}
        {edit && (
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <div className="edit-product-option-item" style={{ width: '520px' }}>
              <Input
                value={newItem?.step?.join(' / ') || item.step.join(' / ')}
                onChange={(e) => {
                  const value = e.target.value;
                  // onChangeItem && onChangeItem(newOption);
                  setNewItem((prev) => {
                    let newStep = [value];
                    if (item?.type === 'sequential') {
                      newStep = value.split(' / ').map((item) => item.trim());
                    }
                    const newOption = { ...item, ...prev, step: newStep };
                    return newOption;
                  });
                }}></Input>
            </div>
            <div className="edit-product-option-item">
              <Select
                value={newItem?.type || item?.type || 'direct'}
                onChange={(value) => {
                  setNewItem((prev) => {
                    const newOption = { ...item, ...prev, type: value };
                    return newOption;
                  });
                  // onChangeItem && onChangeItem(newOption);
                }}
                options={[
                  { value: 'direct', label: '단순형' },
                  { value: 'sequential', label: '순차형' },
                ]}
              />
            </div>
            <div className="edit-product-option-item">
              <Input
                value={newItem?.fee || item.fee}
                onChange={(e) => {
                  setNewItem((prev) => {
                    const newOption = { ...item, ...prev, fee: Number(e.target.value) || 0 };
                    return newOption;
                  });

                  // onChangeItem && onChangeItem(newOption);
                }}></Input>
            </div>
            <div className="edit-product-option-item">
              <Input
                value={newItem?.inventory !== undefined ? newItem?.inventory : item.inventory}
                onChange={(e) => {
                  setNewItem((prev) => {
                    const newOption = { ...item, ...prev, inventory: parseInt(e.target.value) || 0 };
                    return newOption;
                  });

                  // onChangeItem && onChangeItem(newOption);
                }}></Input>
            </div>
          </div>
        )}
        <div className="edit-product-option-item" style={{ display: 'flex', gap: '8px' }}>
          <Button
            onClick={(e) => {
              onDeleteItem && onDeleteItem(e);
            }}>
            <DeleteOutlined style={{ color: 'red' }} />
          </Button>
          <Button
            onClick={() => {
              setEdit((value) => !value);
              if (edit) {
                onChangeItem && newItem && onChangeItem(newItem);
              }
            }}>
            {edit ? '완료' : '수정'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export const ProductOptionEditView: React.FC<{
  productOptions?: ProductOption[];
  optionLabels?: string[];
  purchaseQuantityOptions?: CommerceFixedOptionItem[];
  onChangeProductOptions?: (productOptions: ProductOption[]) => void;
  onChangeOptionLabels?: (optionLabels: string[]) => void;
  onChangePurchaseQuantityOptions?: (purchaseQuantityOptions: CommerceFixedOptionItem[]) => void;
}> = (props) => {
  const [changedProductOptions, setChangedProductOptions] = useState<ProductOption[]>();
  const [selectedOptionIndexes, setSelectedOptionIndexes] = useState<number[]>([]);
  const [changedOptionLabels, setChangedOptionLabels] = useState<string[]>();
  const [changedPurchaseQuantityOptions, setChangedPurchaseQuantityOptions] = useState<CommerceFixedOptionItem[]>();
  const [selectedOptionChangeValues, setSelectedOptionChangeValues] = useState<{
    type?: string;
    fee?: number;
    inventory?: number;
  }>({});
  const [newQuantityOption, setNewQuantityOption] = useState<{
    title?: string;
    fee?: number;
    essential?: boolean;
    inventory?: number;
  }>();
  const [newOption, setNewOption] = useState<{
    step?: string;
    type?: string;
    fee?: number;
    inventory?: number;
  }>();
  const newOptionLabelInputRef = useRef<any>();

  const productOptionSensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 10 },
    }),
  );

  const productOptions = changedProductOptions || props.productOptions || [];
  const optionLabels = changedOptionLabels || props.optionLabels || [];
  const purchaseQuantityOptions = changedPurchaseQuantityOptions || props.purchaseQuantityOptions || [];
  const selectedAllOptions = selectedOptionIndexes.length === productOptions.length;

  useEffect(() => {
    props.onChangeProductOptions &&
      props.onChangeProductOptions(
        productOptions
          .sort((lv, rv) => (lv.index || 0) - (rv.index || 0))
          .map((item, index) => {
            return { ...item, index };
          }),
      );
  }, [changedProductOptions]);

  useEffect(() => {
    props.onChangeOptionLabels && props.onChangeOptionLabels(optionLabels);
  }, [changedOptionLabels]);

  useEffect(() => {
    props.onChangePurchaseQuantityOptions && props.onChangePurchaseQuantityOptions(purchaseQuantityOptions);
  }, [changedPurchaseQuantityOptions]);

  const onProductOptionsSortEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const options = [...productOptions];
      const oldIndex = productOptions.findIndex((item) => item.step.join('') === active.id);
      const newIndex = productOptions.findIndex((item) => item.step.join('') === over?.id);
      const newProductOptions = arrayMove(options, oldIndex, newIndex);
      setChangedProductOptions(
        newProductOptions.map((option, index) => {
          return { ...option, index };
        }),
      );
    }
  };

  const optionItems = productOptions.map((item) => {
    return `${item.step.join('')}`;
  });
  return (
    <div className="edit-product-option-view" style={{ height: 'calc(100dvh - 300px)', overflow: 'auto' }}>
      <div style={{ display: 'flex', gap: '24px' }}>
        <div style={{ flex: 1, borderRadius: '8px', border: '1px solid #eaeaea', padding: '16px' }}>
          <div style={{ fontSize: '16px', fontWeight: 'bold' }}>옵션명 설정</div>
          <div style={{ display: 'flex', gap: '12px', marginTop: '12px' }}>
            <Input
              ref={newOptionLabelInputRef}
              onKeyUp={(e) => {
                if (e.code === 'Enter') {
                  const newValues = [...optionLabels];
                  const newValue = newOptionLabelInputRef.current.input.value;
                  if (!newValues.includes(newValue)) {
                    newValues.push(newValue);
                  }
                  setChangedOptionLabels(newValues);
                }
              }}></Input>
            <Button
              onClick={() => {
                const newValues = [...optionLabels];
                const newValue = newOptionLabelInputRef.current.input.value;
                if (!newValues.includes(newValue)) {
                  newValues.push(newValue);
                }
                setChangedOptionLabels(newValues);
              }}>
              추가
            </Button>
          </div>
          <div>
            {optionLabels.map((label) => {
              return (
                <div
                  key={`option-label-${label}`}
                  style={{ display: 'flex', alignItems: 'center', height: '32px', marginTop: '8px' }}>
                  <div
                    style={{
                      width: '200px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}>
                    {label}
                  </div>
                  <Button
                    onClick={() => {
                      setChangedOptionLabels(optionLabels?.filter((item) => item !== label));
                    }}>
                    <DeleteOutlined style={{ color: 'red' }} />
                  </Button>
                </div>
              );
            })}
          </div>
        </div>
        <div style={{ flex: 2, borderRadius: '8px', border: '1px solid #eaeaea', padding: '16px' }}>
          <div style={{ fontSize: '16px', fontWeight: 'bold' }}>수량 선택 옵션 설정</div>
          <div style={{ display: 'flex', gap: '8px' }}>
            <div className="edit-product-option-title">제목</div>
            <div className="edit-product-option-title">옵션 가격</div>
            <div className="edit-product-option-title">필수 여부</div>
            <div className="edit-product-option-title">재고(선택옵션 수량)</div>
          </div>
          <div>* 필수여부 체크 시 재고는 순차형 옵션의 재고수량과 동일하게 설정됩니다.</div>
          <div style={{ display: 'flex', gap: '8px' }}>
            <div className="edit-product-option-item">
              <Input
                onChange={(e) => {
                  setNewQuantityOption((value) => {
                    return { ...value, title: e.target.value };
                  });
                }}></Input>
            </div>
            <div className="edit-product-option-item">
              <Input
                onChange={(e) => {
                  setNewQuantityOption((value) => {
                    return { ...value, fee: Number(e.target.value) || 0 };
                  });
                }}></Input>
            </div>
            <div className="edit-product-option-item">
              <Switch
                onChange={(checked) => {
                  setNewQuantityOption((value) => {
                    return { ...value, essential: checked };
                  });
                }}></Switch>
            </div>
            <div className="edit-product-option-item">
              <Input
                disabled={newQuantityOption?.essential}
                onChange={(e) => {
                  setNewQuantityOption((value) => {
                    return { ...value, inventory: Number(e.target.value) || 0 };
                  });
                }}></Input>
            </div>
            <div className="edit-product-option-item">
              <Button
                onClick={() => {
                  const newValues = [...purchaseQuantityOptions];
                  const found = newValues.find((item) => item.title === newQuantityOption?.title);
                  if (!found) {
                    newValues.push(newQuantityOption as CommerceFixedOptionItem);
                  }
                  setChangedPurchaseQuantityOptions(newValues);
                }}>
                추가
              </Button>
            </div>
          </div>
          <div>
            {purchaseQuantityOptions.map((option, index) => {
              return (
                <div key={`purchase-quantity-option-${index}`} style={{ display: 'flex', gap: '8px' }}>
                  <div className="edit-product-option-item">
                    <Input
                      value={option.title}
                      onChange={(e) => {
                        const values = [...purchaseQuantityOptions];
                        const newValue = { ...option, title: e.target.value };
                        values[index] = newValue;
                        setChangedPurchaseQuantityOptions(values);
                      }}></Input>
                  </div>
                  <div className="edit-product-option-item">
                    <Input
                      value={option.fee}
                      onChange={(e) => {
                        const values = [...purchaseQuantityOptions];
                        const newValue = { ...option, fee: Number(e.target.value) || 0 };
                        values[index] = newValue;
                        setChangedPurchaseQuantityOptions(values);
                      }}></Input>
                  </div>
                  <div className="edit-product-option-item">
                    <Switch
                      checked={option.essential}
                      onChange={(checked) => {
                        const values = [...purchaseQuantityOptions];
                        const newValue = { ...option, essential: checked };
                        values[index] = newValue;
                        setChangedPurchaseQuantityOptions(values);
                      }}></Switch>
                  </div>
                  <div className="edit-product-option-item">
                    <Input
                      value={option.inventory}
                      disabled={option.essential}
                      onChange={(e) => {
                        const values = [...purchaseQuantityOptions];
                        const newValue = { ...option, inventory: Number(e.target.value) || 0 };
                        values[index] = newValue;
                        setChangedPurchaseQuantityOptions(values);
                      }}></Input>
                  </div>
                  <div className="edit-product-option-item">
                    <Button
                      onClick={() => {
                        const values = [...purchaseQuantityOptions];
                        values.splice(index, 1);
                        setChangedPurchaseQuantityOptions(values);
                      }}>
                      <DeleteOutlined style={{ color: 'red' }} />
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <OptionBulkEdit
        productOptions={productOptions}
        optionLabels={optionLabels}
        onChange={(options, labels) => {
          setChangedProductOptions(options);
          setChangedOptionLabels(labels);
        }}></OptionBulkEdit>
      <div style={{ marginTop: '12px', borderRadius: '8px', border: '1px solid #eaeaea', padding: '16px' }}>
        <div style={{ display: 'flex', fontSize: '16px', fontWeight: 'bold', justifyContent: 'flex-end' }}>
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <div style={{ fontSize: '14px' }}>옵션 구분</div>
                <Select
                  value={selectedOptionChangeValues.type}
                  style={{ width: '100px' }}
                  options={[
                    { value: 'direct', label: '단순형' },
                    { value: 'sequential', label: '순차형' },
                  ]}
                  onChange={(value) => {
                    setSelectedOptionChangeValues((values) => {
                      return { ...values, type: value };
                    });
                  }}
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <div style={{ fontSize: '14px' }}>옵션가</div>
                <Input
                  value={selectedOptionChangeValues.fee}
                  style={{ width: '140px' }}
                  onChange={(e) => {
                    setSelectedOptionChangeValues((values) => {
                      return { ...values, fee: Number(e.target.value) || 0 };
                    });
                  }}></Input>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <div style={{ fontSize: '14px' }}>재고</div>
                <Input
                  value={selectedOptionChangeValues.inventory}
                  style={{ width: '100px' }}
                  onChange={(e) => {
                    setSelectedOptionChangeValues((values) => {
                      return { ...values, inventory: Number(e.target.value) || 0 };
                    });
                  }}></Input>
              </div>
            </div>
            <Button
              style={{ color: 'blue' }}
              onClick={() => {
                setChangedProductOptions((prev) => {
                  const newValue = [...(prev || productOptions)];
                  if (selectedAllOptions) {
                    return newValue.map((item) => {
                      return {
                        ...item,
                        type:
                          selectedOptionChangeValues.type !== undefined ? selectedOptionChangeValues.type : item.type,
                        fee: selectedOptionChangeValues.fee !== undefined ? selectedOptionChangeValues.fee : item.fee,
                        inventory:
                          selectedOptionChangeValues.inventory !== undefined
                            ? selectedOptionChangeValues.inventory
                            : item.inventory,
                      };
                    });
                  } else {
                    selectedOptionIndexes.forEach((idx) => {
                      newValue[idx] = {
                        ...newValue[idx],
                        type:
                          selectedOptionChangeValues.type !== undefined
                            ? selectedOptionChangeValues.type
                            : newValue[idx].type,
                        fee:
                          selectedOptionChangeValues.fee !== undefined
                            ? selectedOptionChangeValues.fee
                            : newValue[idx].fee,
                        inventory:
                          selectedOptionChangeValues.inventory !== undefined
                            ? selectedOptionChangeValues.inventory
                            : newValue[idx].inventory,
                      };
                    });
                  }
                  return newValue;
                });
                setChangedOptionLabels(optionLabels);
              }}>
              선택항목 일괄수정
            </Button>
            <Button
              style={{ color: 'red' }}
              onClick={() => {
                setChangedProductOptions((prev) => {
                  const newValue = [...(prev || productOptions)];
                  if (selectedAllOptions) {
                    return [];
                  } else {
                    const remainValues: any[] = [];
                    newValue.forEach((value, index) => {
                      if (!selectedOptionIndexes.includes(index)) {
                        remainValues.push(value);
                      }
                    });
                    return remainValues;
                  }
                });
                setSelectedOptionIndexes([]);
              }}>
              선택항목 일괄삭제
            </Button>
          </div>
        </div>
        <div style={{ display: 'flex', gap: '8px' }}>
          <div className="edit-product-option-title" style={{ width: '520px' }}>
            옵션명
          </div>
          <div className="edit-product-option-title">옵션 구분</div>
          <div className="edit-product-option-title">옵션가</div>
          <div className="edit-product-option-title">재고</div>
          <div className="edit-product-option-title">동작</div>
        </div>
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <div>
            <Checkbox
              checked={selectedAllOptions}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedOptionIndexes(productOptions.map((item, index) => index));
                } else {
                  setSelectedOptionIndexes([]);
                }
              }}></Checkbox>
          </div>
          <div className="edit-product-option-item" style={{ width: '520px' }}>
            <Input
              value={newOption?.step}
              onChange={(e) => {
                setNewOption((value) => {
                  return { ...value, step: e.target.value };
                });
              }}
              onKeyUp={(e) => {
                if (e.code === 'Enter') {
                  const newProductOptions = [...productOptions];
                  let newSteps: string[] = [];
                  if (newOption?.step && newOption?.type === 'sequential' && optionLabels.length > 1) {
                    newSteps = (newOption?.step || '').split(' / ').map((item: string) => item.trim());
                  } else {
                    if (newOption?.step) {
                      newSteps = [newOption?.step];
                    }
                  }
                  if (newSteps.length === 0) {
                    return;
                  }
                  const found = newProductOptions.find((option) => option.step.join(' / ') === newSteps.join(' / '));
                  if (!found) {
                    newProductOptions.push({
                      step: newSteps,
                      type: newOption?.type || 'direct',
                      fee: newOption?.fee || 0,
                      inventory: newOption?.inventory || 0,
                    });
                  }
                  setChangedProductOptions(newProductOptions);
                }
              }}></Input>
          </div>
          <div className="edit-product-option-item">
            <Select
              value={newOption?.type || 'direct'}
              onChange={(newValue) => {
                setNewOption((value) => {
                  return { ...value, type: newValue };
                });
              }}
              options={[
                { value: 'direct', label: '단순형' },
                { value: 'sequential', label: '순차형' },
              ]}
            />
          </div>
          <div className="edit-product-option-item" style={{ width: '120px' }}>
            <Input
              value={newOption?.fee}
              onChange={(e) => {
                setNewOption((value) => {
                  return { ...value, fee: Number(e.target.value) || 0 };
                });
              }}></Input>
          </div>
          <div className="edit-product-option-item" style={{ width: '120px' }}>
            <Input
              value={newOption?.inventory}
              onChange={(e) => {
                setNewOption((value) => {
                  return { ...value, inventory: parseInt(e.target.value) || 0 };
                });
              }}></Input>
          </div>
          <div className="edit-product-option-item">
            <Button
              onClick={() => {
                const newProductOptions = [...productOptions];
                let newSteps: string[] = [];
                if (newOption?.step && newOption?.type === 'sequential' && optionLabels.length > 1) {
                  newSteps = (newOption?.step || '').split(' / ').map((item: string) => item.trim());
                } else {
                  if (newOption?.step) {
                    newSteps = [newOption?.step];
                  }
                }
                if (newSteps.length === 0) {
                  return;
                }
                const found = newProductOptions.find((option) => option.step.join(' / ') === newSteps.join(' / '));
                if (!found) {
                  newProductOptions.push({
                    step: newSteps,
                    type: newOption?.type || 'direct',
                    fee: newOption?.fee || 0,
                    inventory: newOption?.inventory || 0,
                  });
                }
                setChangedProductOptions(newProductOptions);
              }}>
              추가
            </Button>
          </div>
          <Button
            style={{ color: 'red' }}
            onClick={() => {
              setChangedProductOptions([]);
            }}>
            모두 삭제
          </Button>
        </div>
        <DndContext
          sensors={productOptionSensors}
          collisionDetection={closestCenter}
          onDragEnd={onProductOptionsSortEnd}>
          <SortableContext items={optionItems} strategy={verticalListSortingStrategy}>
            <FixedSizeList
              width={'100%'}
              height={400}
              itemData={productOptions}
              itemCount={productOptions.length}
              itemSize={45}
              overscanCount={10}>
              {({ index, style, data: productOptions }) => {
                const item = productOptions[index];
                return (
                  <SortableOptionItem
                    style={style}
                    selected={selectedOptionIndexes.includes(index)}
                    onSelect={(checked) => {
                      setSelectedOptionIndexes((prev) => {
                        const newValue = [...prev];
                        if (checked) {
                          if (!newValue.includes(index)) {
                            newValue.push(index);
                          }
                          return newValue;
                        } else {
                          const idx = newValue.findIndex((item) => item === index);
                          newValue.splice(idx, 1);
                          return newValue;
                        }
                      });
                    }}
                    id={`${item.step.join('')}`}
                    onDeleteItem={() => {
                      const newProductOptions = [...productOptions];
                      newProductOptions.splice(index, 1);
                      setChangedProductOptions(newProductOptions);
                    }}
                    onChangeItem={(value) => {
                      const newProductOptions = [...productOptions];
                      newProductOptions[index] = value;
                      setChangedProductOptions(newProductOptions);
                    }}
                    item={item}
                  />
                );
              }}
            </FixedSizeList>
            {/* <div>
              {productOptions.map((item: ProductOption, index: number) => {
                return (
                  <SortableOptionItem
                    selected={selectedOptionIndexes.includes(index)}
                    onSelect={(checked) => {
                      setSelectedOptionIndexes((prev) => {
                        const newValue = [...prev];
                        if (checked) {
                          if (!newValue.includes(index)) {
                            newValue.push(index);
                          }
                          return newValue;
                        } else {
                          const idx = newValue.findIndex((item) => item === index);
                          newValue.splice(idx, 1);
                          return newValue;
                        }
                      });
                    }}
                    id={`${item.step.join('')}`}
                    onDeleteItem={() => {
                      const newProductOptions = [...productOptions];
                      newProductOptions.splice(index, 1);
                      setChangedProductOptions(newProductOptions);
                    }}
                    onChangeItem={(value) => {
                      const newProductOptions = [...productOptions];
                      newProductOptions[index] = value;
                      setChangedProductOptions(newProductOptions);
                    }}
                    item={item}
                  />
                );
              })}
            </div> */}
          </SortableContext>
        </DndContext>
      </div>
    </div>
  );
};
