import {
  BarChartOutlined,
  BarcodeOutlined,
  BookOutlined,
  DollarOutlined,
  FilePptOutlined,
  IdcardOutlined,
  LogoutOutlined,
  NotificationOutlined,
  PartitionOutlined,
  ReconciliationOutlined,
  RiseOutlined,
  ScheduleOutlined,
  SnippetsOutlined,
  UserOutlined,
  WechatOutlined,
} from '@ant-design/icons';
import { useAuthentication } from '@hooks/authentication';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { Layout, Menu, MenuProps } from 'antd';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import 'overlayscrollbars/overlayscrollbars.css';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Sider.scss';

interface SiderProps {
  hide?: boolean;
}

const DEFAULT_OPEN_KEYS = [
  'sub1',
  'sub2',
  'sub3',
  'sub4',
  'sub5',
  'notices-menu',
  'report-menu',
  'schedule-menu',
  'workshops-menu',
];

type MenuItem = Required<MenuProps>['items'][number];

const MENU_ITEMS: MenuItem[] = [
  {
    label: <Link to={'/dashboard'}>대시보드</Link>,
    icon: <BarChartOutlined />,
    key: 'dashboard',
  },
  {
    label: <Link to={'/report/products'}>보고서</Link>,
    icon: <ReconciliationOutlined />,
    key: 'reports',
    children: [
      {
        label: <Link to={'/report/products'}>상품</Link>,
        key: 'product-report',
      },
      {
        label: <Link to={'/report/sales'}>파트너 판매</Link>,
        key: 'sales-report',
      },
      {
        label: <Link to={'/report/purchases'}>유저 구매</Link>,
        key: 'purchases-report',
      },
    ],
  },

  {
    label: <Link to={'/users'}>유저 관리</Link>,
    icon: <UserOutlined />,
    key: 'users',
  },
  {
    label: <Link to={'/products'}>상품 관리</Link>,
    icon: <BookOutlined />,
    key: 'products',
  },
  {
    label: <Link to={'/products-inventory'}>상품 재고 관리</Link>,
    icon: <BarcodeOutlined />,
    key: 'products-inventory',
    children: [
      {
        label: <Link to={'/products-inventory'}>상품 재고 마감</Link>,
        key: 'products-inventory-list',
      },
      {
        label: <Link to={'/product-inventory-closing-history'}>재고 마감 이력</Link>,
        key: 'product-inventory-closing-history',
      },
    ],
  },
  {
    label: <Link to={'/class-invoices'}>클래스 수강증 관리</Link>,
    icon: <SnippetsOutlined />,
    key: 'class-invoices',
  },
  {
    label: <Link to={'/payment-statements'}>결제 관리</Link>,
    icon: <DollarOutlined />,
    key: 'payment-statements',
    children: [
      {
        label: <Link to={'/payment-statements'}>결제내역</Link>,
        key: 'payment-statements',
      },
      {
        label: <Link to={'/settlements'}>정산내역</Link>,
        key: 'settlements',
      },
    ],
  },
  {
    label: <Link to={'/memberships'}>멤버십 관리</Link>,
    icon: <IdcardOutlined />,
    key: 'memberships',
  },
  {
    label: <Link to={'/naver-orders'}>외부 판매 채널</Link>,
    icon: <ScheduleOutlined />,
    key: 'sales-channels',
    children: [
      // {
      //   label: <Link to={'/sales-channels/products'}>외부 채널 상품 관리</Link>,
      //   key: 'sales-channel-products',
      // },
      {
        label: <Link to={'/naver-orders'}>네이버 판매 내역</Link>,
        key: 'naver-orders',
      },
      {
        label: <Link to={'/kakao-orders'}>카카오(R) 판매내역</Link>,
        key: 'kakao-orders',
      },
      {
        label: <Link to={'/inbound-orders'}>인바운드 판매 내역</Link>,
        key: 'inbound-orders',
      },
      {
        label: <Link to={'/naver-commerce-orders'}>네이버 주문 연동(API)</Link>,
        key: 'naver-commerce-orders',
      },
      {
        label: <Link to={'/naver-commerce-products'}>네이버 상품(API)</Link>,
        key: 'naver-commerce-products',
      },
    ],
  },
  {
    label: <Link to={'/push-notifications'}>푸시 & 카카오 알림</Link>,
    icon: <NotificationOutlined />,
    key: 'notifications',
    children: [
      {
        label: <Link to={'/push-notifications'}>푸시 알림</Link>,
        key: 'push-notifications',
      },
      {
        label: <Link to={'/kakao-notifications'}>카카오 알림톡</Link>,
        key: 'kakao-notifications',
      },
      {
        label: <Link to={'/kakao-reserved-notifications'}>카카오 알림톡(예약)</Link>,
        key: 'kakao-reserved-notifications',
      },
    ],
  },
  {
    label: <Link to={'/main-feed'}>상품 큐레이션</Link>,
    icon: <ScheduleOutlined />,
    key: 'curation',
    children: [
      {
        label: <Link to={'/main-feed'}>홈 / MD PICK</Link>,
        key: 'main-feed',
      },
      {
        label: <Link to={'/product-collections'}>기획전 관리</Link>,
        key: 'product-collections',
      },
      {
        label: <Link to={'/banners'}>배너 관리</Link>,
        key: 'banners',
      },
      {
        label: <Link to={'/feed-elements'}>피드 표시 항목 관리</Link>,
        key: 'feed-elements',
      },
    ],
  },
  {
    label: <Link to={'/chat-rooms'}>클래스 채팅</Link>,
    icon: <WechatOutlined />,
    key: 'chat-monitor',
    children: [
      {
        label: <Link to={'/chat-rooms'}>대화방 모니터링</Link>,
        key: 'chat-rooms',
      },
      {
        label: <Link to={'/chat-messages'}>메시지 모니터링</Link>,
        key: 'chat-messages',
      },
      {
        label: <Link to={'/violation-detected-messages'}>정책 위반 의심 메시지</Link>,
        key: 'violation-detected-messages',
      },
    ],
  },
  {
    label: <Link to={'/user-contents'}>고객 참여 관리</Link>,
    icon: <RiseOutlined />,
    key: 'customer-engagement',
    children: [
      {
        label: <Link to={'/user-contents'}>라운지 콘텐츠</Link>,
        key: 'user-contents',
      },
      {
        label: <Link to={'/customer-request'}>클래스 오픈 요청</Link>,
        key: 'customer-request',
      },
      {
        label: <Link to={'/review'}>(구)리뷰 관리</Link>,
        key: 'review',
      },
    ],
  },
  {
    label: <Link to={'/point-template'}>포인트/쿠폰/이벤트</Link>,
    icon: <FilePptOutlined />,
    key: 'point-coupon',
    children: [
      {
        label: <Link to={'/point-template'}>포인트 템플릿</Link>,
        key: 'point-template',
      },
      {
        label: <Link to={'/coupon'}>쿠폰 관리</Link>,
        key: 'coupon',
      },
      {
        label: <Link to={'/coupon-log'}>쿠폰 사용내역</Link>,
        key: 'coupon-log',
      },
      {
        label: <Link to={'/point-log'}>포인트 적립/사용 내역</Link>,
        key: 'point-log',
      },
      {
        label: <Link to={'/join-promotion'}>회원가입 이벤트</Link>,
        key: 'join-promotion',
      },
      {
        label: <Link to={'/join-promotion-log'}>회원가입 이벤트 이력</Link>,
        key: 'join-promotion-log',
      },
      {
        label: <Link to={'/events'}>이벤트 관리</Link>,
        key: 'event-management',
      },
    ],
  },
  {
    label: <Link to={'/notices'}>시스템 관리</Link>,
    icon: <PartitionOutlined />,
    key: 'system-management',
    children: [
      {
        label: <Link to={'/notices'}>공지사항</Link>,
        key: 'notices',
      },
      {
        label: <Link to={'/remote-config'}>플랫폼 관리</Link>,
        key: 'remote-config',
      },
      {
        label: <Link to={'/payments'}>결제 내역 관리</Link>,
        key: 'payments',
      },
    ],
  },

  { key: 'bottom-divider' },
  { label: '로그아웃', icon: <LogoutOutlined />, key: 'logout' },
];

const Sider: React.FC<SiderProps> = (props) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const dialog = useOkCancelDialog();
  const auth = useAuthentication();

  if (props.hide) {
    return <div id="hidden-sider"></div>;
  }

  return (
    <>
      <div>
        <button
          className="link-button"
          style={{ width: '48px', height: '48px', position: 'fixed', top: 0, left: 0, zIndex: 1025 }}
          onClick={() => {
            setShowMenu((value) => !value);
          }}></button>
      </div>
      <Layout.Sider width={200} className={showMenu ? 'sider show' : 'sider'}>
        <OverlayScrollbarsComponent
          defer
          options={{
            scrollbars: { autoHide: 'scroll' },
            overflow: {
              x: 'hidden',
            },
          }}
          style={{
            marginTop: 0,
            position: 'relative',
            maxHeight: '100vh',
            overflow: 'auto',
            width: '100%',
          }}>
          <div className="menu-title">아이고고 어드민</div>
          <Menu
            mode="inline"
            theme="dark"
            defaultOpenKeys={DEFAULT_OPEN_KEYS}
            items={MENU_ITEMS}
            style={{ borderRight: 0 }}
            onClick={(info) => {
              if (info.key === 'logout') {
                dialog.open({
                  title: '로그아웃',
                  content: '로그아웃 할까요?',
                  type: 'ok_cancel',
                  onConfirm: () => {
                    auth.logout();
                  },
                });
              }
            }}></Menu>
        </OverlayScrollbarsComponent>
      </Layout.Sider>
    </>
  );
};

export default Sider;
