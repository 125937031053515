import { create } from 'zustand';
import { User } from '../models/auth';

export enum LoginStatus {
  LOGIN = 'login',
  ANONYMOUS = 'anonymous',
  READY = 'ready',
}

interface AuthStore {
  status: LoginStatus;
  user?: User | null;
  setUser: (user?: User) => void;
}

export const useAuthStore = create<AuthStore>((set) => ({
  status: LoginStatus.READY,
  user: null,
  setUser: (user?: User) => {
    set({ user: user });
    set((state) => {
      const newStatus = user ? LoginStatus.LOGIN : LoginStatus.ANONYMOUS;

      if (newStatus !== state.status) {
        return { status: newStatus };
      }
      return state;
    });
  },
}));
