import * as api from '@apis/common';
import DateTimeButtonPicker from '@components/class-invoice/DateTimeButtonPicker';
import FileUploadButton from '@components/common/FileUploadButton';
import { SortableDetailImage } from '@components/product/ProductEditView';
import { PlatformConfig, RemoteConfig } from '@models/remote-config';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { localTimeToUtcISOString } from '@utils/date';
import { resizeImage } from '@utils/image';
import { extractFileName } from '@utils/string';
import { Button, Form, FormProps, Input, Radio } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { RcFile } from 'antd/es/upload';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

interface ConfigSubmitData {
  'android.active': boolean;
  'android.target': string;
  'android.method': 'recommend' | 'force';
  'ios.active': boolean;
  'ios.target': string;
  'ios.method': 'recommend' | 'force';
  'maintenance.active': boolean;
  'maintenance.description': string;
  'maintenance.startDate': string;
  'maintenance.endDate': string;
  'splash.active': boolean;
  'splash.imageUrls': string[];
  'splash.duration': number;
}

const RemoteConfigPage: React.FC = () => {
  const [generateShareLinkStatus, setGenerateShareLinkStatus] = useState<string>();
  const [files, setFiles] = useState<{ [key: string]: File }>({});
  const [splashImages, setSplashImages] = useState<string[]>();
  const [tempSplashImages, setTempSplashImages] = useState<string[]>();
  const dialog = useOkCancelDialog();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const getRemoteConfigQuery = useQuery({
    queryKey: ['remote-config'],
    queryFn: () => api.getRemoteConfig(),
  });

  const updateRemoteConfigMutation = useMutation({
    mutationFn: api.updateRemoteConfig,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['remote-config'] });
      dialog.open({
        content: '저장되었습니다.',
        type: 'ok',
      });
    },
    onError: async (e: any) => {
      dialog.open({
        title: '업데이트 오류',
        content: e?.response?.data?.message,
        type: 'ok',
      });
    },
  });

  const generateSharePagesSitemapMutation = useMutation({
    mutationFn: api.generateSitemap,
    onSuccess: async () => {
      dialog.open({ type: 'ok', title: '알림', content: '사이트맵을 갱신했습니다' });
    },
    onError: async (e) => {
      dialog.open({ type: 'ok', title: '오류', content: JSON.stringify(e) });
    },
  });

  const migrateUserContentMutation = useMutation({
    mutationFn: api.migrateUserContents,
    onSuccess: async () => {
      dialog.open({ type: 'ok', title: '알림', content: '마이그레이션 완료' });
    },
    onError: async (e) => {
      dialog.open({ type: 'ok', title: '오류', content: JSON.stringify(e) });
    },
  });

  const generateSharePagesMutation = useMutation({
    mutationFn: api.generateShareLinks,
    onSuccess: async () => {
      dialog.open({ type: 'ok', title: '알림', content: '공유 페이지 갱신을 요청했습니다' });
    },
    onError: async (e) => {
      dialog.open({ type: 'ok', title: '오류', content: JSON.stringify(e) });
    },
  });

  useEffect(() => {
    const configData = getRemoteConfigQuery.data?.data;
    if (configData) {
      const submitFormData = {
        'android.active': configData.android?.active,
        'android.target': configData.android?.target,
        'android.method': configData.android?.method,
        'ios.active': configData.ios?.active,
        'ios.target': configData.ios?.target,
        'ios.method': configData.ios?.method,
        'maintenance.active': configData.maintenance?.active,
        'maintenance.description': configData.maintenance?.description,
        'maintenance.startDate': dayjs(configData?.maintenance?.startDate as any),
        'maintenance.endDate': dayjs(configData?.maintenance?.endDate as any),
        'splash.active': configData?.splash?.active,
        'splash.duration': configData?.splash?.duration,
      };
      if (configData?.splash?.imageUrls && configData?.splash?.imageUrls.length > 0) {
        setSplashImages([...configData.splash.imageUrls]);
      }
      form.setFieldsValue(submitFormData);
    }
  }, [getRemoteConfigQuery.data?.data]);

  const convertToConfigForm = (submitData: ConfigSubmitData) => {
    const updateValue = {
      android: {},
      ios: {},
      maintenance: {},
      splash: {},
    } as RemoteConfig;
    Object.entries(submitData).map(([key, value]) => {
      const configType = key.split('.')[0] as keyof RemoteConfig;
      if (configType === 'android' || configType === 'ios') {
        const configKey = key.split('.')[1] as keyof PlatformConfig;
        updateValue[`${configType}`][`${configKey}`] = value;
      } else if (configType === 'maintenance') {
        const configKey = key.split('.')[1] as keyof RemoteConfig['maintenance'];
        if (configKey === 'startDate' || configKey === 'endDate') {
          const dateString = localTimeToUtcISOString(value ? new Date(value.toISOString()) : null, {
            timeToZero: false,
          });
          value = moment(dateString).format('YYYY-MM-DD HH:mm:ss');
        }
        updateValue[`${configType}`][`${configKey}`] = value;
      } else if (configType === 'splash') {
        const configKey = key.split('.')[1] as keyof RemoteConfig['splash'];
        if (configKey !== 'imageUrls') {
          if (configKey === 'duration') {
            updateValue[`${configType}`][`${configKey}`] = Number(value);
          } else if (configKey === 'active') {
            updateValue[`${configType}`][`${configKey}`] = value;
          }
        } else if (configKey === 'imageUrls') {
          updateValue[`${configType}`][`${configKey}`] = [...(splashImages || [])];
        }
      }
    });
    return updateValue;
  };

  const onSubmit: FormProps['onFinish'] = (e) => {
    const formData: FormData = new FormData();
    const updateData = convertToConfigForm(e);
    formData.append('json', JSON.stringify(updateData));
    Object.values(files).forEach((file) => {
      formData.append('files', file);
    });
    if (updateData) {
      updateRemoteConfigMutation.mutate(formData);
    }
  };

  const onChangeSplashImageFiles = async (files: FileList) => {
    const imageFiles: any = {};
    const newFiles = await Promise.all(
      Array.from(files).map(async (file) => {
        const result = await resizeImage(file, { maxWidth: 1024 });
        const { fileName } = extractFileName(file.name);
        const contentTypes = result.type.split('/');
        const imageFile = new File([result.image], `${fileName}.${contentTypes[1]}`) as RcFile;
        const tempUrl = URL.createObjectURL(result.image);

        imageFiles[file.name] = imageFile;

        return { result, fileName: `${fileName}.${contentTypes[1]}`, imageFile, tempUrl };
      }),
    );

    setFiles((value) => {
      const newValue = { ...value, ...imageFiles };
      return newValue;
    });

    const tempUrls: string[] = [];
    const fileNames: string[] = [];

    newFiles.forEach((newFile) => {
      tempUrls.push(newFile.tempUrl);
      fileNames.push(newFile.fileName);
    });

    setSplashImages([...(splashImages || []), ...fileNames]);
    setTempSplashImages([...(tempSplashImages || []), ...(splashImages || []), ...tempUrls]);
  };

  const onDeleteSplashImage = (index: number) => {
    const newSplashImages = [...(splashImages || [])];
    const newSplashImagesTemp = [...(tempSplashImages || splashImages || [])];

    newSplashImages.splice(index, 1);
    newSplashImagesTemp.splice(index, 1);
    setSplashImages(newSplashImages);
    setTempSplashImages(newSplashImagesTemp);
  };

  return (
    <div className="notification" style={{ position: 'relative' }}>
      <div className="page-header">
        <div>플랫폼 관리</div>
      </div>
      <div className="page-content" style={{ width: '100%', height: 'calc(100vh - 80px)', overflow: 'auto' }}>
        <div>
          <Button
            htmlType="submit"
            form="remoteConfig"
            style={{ marginBottom: '10px' }}
            loading={updateRemoteConfigMutation.isPending}>
            저장
          </Button>
          <Form
            form={form}
            name="remoteConfig"
            layout="inline"
            onFinish={onSubmit}
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              gap: '40px',
              flexDirection: 'column',
              marginBottom: '40px',
              padding: '20px',
              border: '1px solid #242424',
              borderRadius: '20px',
            }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '40px' }}>
              <div>
                <div style={{ fontSize: '16px', fontWeight: 'bold' }}>안드로이드</div>
                <Form.Item name="android.active" label={'적용'} style={{ marginTop: '12px' }}>
                  <Radio.Group
                    options={[
                      { label: '활성', value: true },
                      { label: '비활성', value: false },
                    ]}
                  />
                </Form.Item>
                <Form.Item name="android.method" label={'타입'}>
                  <Radio.Group
                    options={[
                      { label: '강제', value: 'force' },
                      { label: '권장', value: 'recommend' },
                    ]}
                  />
                </Form.Item>
                <Form.Item name="android.target" label={'버전'}>
                  <Input placeholder={'안드로이드 버전'}></Input>
                </Form.Item>
              </div>
              <div>
                <div style={{ fontSize: '16px', fontWeight: 'bold' }}>IOS</div>
                <Form.Item name="ios.active" label={'적용'} style={{ marginTop: '12px' }}>
                  <Radio.Group
                    options={[
                      { label: '활성', value: true },
                      { label: '비활성', value: false },
                    ]}
                  />
                </Form.Item>
                <Form.Item name="ios.method" label={'타입'}>
                  <Radio.Group
                    options={[
                      { label: '강제', value: 'force' },
                      { label: '권장', value: 'recommend' },
                    ]}
                  />
                </Form.Item>
                <Form.Item name="ios.target" label={'버전'}>
                  <Input placeholder={'IOS 버전'}></Input>
                </Form.Item>
              </div>
              <div>
                <div style={{ fontSize: '16px', fontWeight: 'bold' }}>점검 공지</div>
                <Form.Item name="maintenance.active" label={'적용'} style={{ marginTop: '12px' }}>
                  <Radio.Group
                    options={[
                      { label: '활성', value: true },
                      { label: '비활성', value: false },
                    ]}
                  />
                </Form.Item>
                <div style={{ marginBottom: '10px' }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Form.Item name="maintenance.startDate" label={'시작시간'}>
                      <DateTimeButtonPicker />
                    </Form.Item>
                    <Form.Item name="maintenance.endDate" label={'종료시간'}>
                      <DateTimeButtonPicker />
                    </Form.Item>
                  </div>
                </div>
                <Form.Item name="maintenance.description" label={'점검 문구'}>
                  <Input.TextArea placeholder={'점검 문구'} />
                </Form.Item>
              </div>
            </div>
            <div>
              <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
                모바일 스플래시
                <span style={{ color: 'red', marginLeft: '10px', fontWeight: 'normal', fontSize: '14px' }}>
                  *활성화 할 경우 기본 스플래시는 비활성화 됩니다.
                </span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <Form.Item name="splash.active" label={'적용'} style={{ marginTop: '12px' }}>
                  <Radio.Group
                    options={[
                      { label: '활성', value: true },
                      { label: '비활성', value: false },
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  name="splash.imageUrls"
                  label={'이미지'}
                  tooltip={<span>여러장을 등록할 경우 랜덤으로 노출됩니다.</span>}>
                  <FileUploadButton
                    multiple
                    accept={['jpg', 'jpeg', 'png', 'tif', 'tiff', 'webp', 'svg']
                      .map((ext) => {
                        return '.' + ext;
                      })
                      .join(',')}
                    className="link-button"
                    style={{
                      fontSize: '14px',
                      height: '32px',
                      padding: '4px 15px',
                      borderRadius: '6px',
                      backgroundColor: '#ffffff',
                      border: '1px solid #d9d9d9',
                      color: 'rgba(0, 0, 0, 0.88)',
                      boxShadow: '0 2px 0 rgba(0, 0, 0, 0.02)',
                      cursor: 'pointer',
                      marginBottom: '10px',
                    }}
                    onChange={(e) => {
                      if (e.target.files) {
                        const files = e.target.files;
                        onChangeSplashImageFiles(files);
                      }
                    }}>
                    스플래시 이미지 추가
                  </FileUploadButton>
                  <div style={{ display: 'flex', gap: '4px', overflow: 'auto', maxWidth: '600px' }}>
                    {(tempSplashImages || splashImages || []).map((imageUrl: string, index: number) => {
                      return (
                        <SortableDetailImage
                          key={`image-${imageUrl}-${index}`}
                          id={imageUrl}
                          onDeleteDetailImage={() => {
                            onDeleteSplashImage(index);
                          }}
                          imageUrl={imageUrl}
                        />
                      );
                    })}
                  </div>
                </Form.Item>
                <Form.Item name="splash.duration" label={'시간(초)'}>
                  <Input type="number" placeholder={'스플래시 시간'} />
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
        <div>
          <div style={{ fontSize: '16px', fontWeight: 'bold' }}>일괄 작업</div>
          <div style={{ display: 'flex', gap: '12px', flexWrap: 'wrap', marginTop: '12px' }}>
            <Button
              disabled={migrateUserContentMutation.isPending}
              onClick={() => {
                migrateUserContentMutation.mutate();
              }}>
              마이그레이션 - 리뷰/유저콘텐츠
            </Button>
            <Button
              disabled={generateSharePagesSitemapMutation.isPending}
              onClick={() => {
                generateSharePagesSitemapMutation.mutate();
              }}>
              공유 HTML 생성 - 사이트맵
            </Button>
            <Button
              disabled={generateSharePagesMutation.isPending}
              onClick={() => {
                generateSharePagesMutation.mutate('product');
              }}>
              공유 HTML 생성 - 상품 전체
            </Button>
            <Button
              disabled={generateSharePagesMutation.isPending}
              onClick={() => {
                generateSharePagesMutation.mutate('partner');
              }}>
              공유 HTML 생성 - 파트너 전체
            </Button>
            <Button
              disabled={generateSharePagesMutation.isPending}
              onClick={() => {
                generateSharePagesMutation.mutate('content');
              }}>
              공유 HTML 생성 - 콘텐츠 전체
            </Button>
            <Button
              onClick={async () => {
                const response = await api.getGenerateShareLinkStatus();
                setGenerateShareLinkStatus(JSON.stringify(response.data, undefined, 4));
              }}>
              공유 HTML 생성 - 상태 보기
            </Button>
          </div>
          <TextArea
            value={generateShareLinkStatus}
            style={{ marginTop: '20px', width: '480px', height: '180px' }}></TextArea>
        </div>
      </div>
    </div>
  );
};

export default RemoteConfigPage;
