import { DefaultInfoNotification, NoticeSearchQuery, NoticeModel } from '@models/notification';
import { apiClient } from '.';
import { PagenatedResponse } from '@models/common';

interface updateNotificationProps {
  notificationId: string;
  body: Partial<NoticeModel>;
}

export const getAllNotification = (query?: NoticeSearchQuery) => {
  return apiClient.get<PagenatedResponse<DefaultInfoNotification>>('/admin/v1/notices', { params: query });
};
export const getNotification = (notificationId: string) => {
  return apiClient.get<NoticeModel>(`/admin/v1/notices/${notificationId}`);
};
export const createNotification = (body: Partial<NoticeModel>) => {
  return apiClient.post('/admin/v1/notices', body);
};
export const updateNotification = ({ notificationId, body }: updateNotificationProps) => {
  return apiClient.patch<NoticeModel>(`/admin/v1/notices/${notificationId}`, body);
};
export const deleteNotification = (notificationId: string) => {
  return apiClient.delete(`/admin/v1/notices/${notificationId}`);
};
