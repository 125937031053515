import { PagenatedResponse, SearchPagenateQuery } from '@models/common';
import { JoinPromotion, JoinPromotionLog } from '@models/join-promotion';
import { apiClient } from '.';

export const searchJoinPromotions = async (query?: SearchPagenateQuery) => {
  return apiClient.get<PagenatedResponse<JoinPromotion>>(`/admin/v1/join-promotions`, {
    params: query,
  });
};

export const updateJoinPromotion = async (formData: FormData) => {
  const promotionId = formData.get('joinPromotionId');
  return apiClient.put<JoinPromotion>(`/admin/v1/join-promotions/${promotionId}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const createJoinPromotion = async (formData: FormData) => {
  return apiClient.post<JoinPromotion>(`/admin/v1/join-promotions`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const searchJoinPromotionLogs = async (query?: SearchPagenateQuery) => {
  return apiClient.get<PagenatedResponse<JoinPromotionLog>>(`/admin/v1/join-promotions/logs`, {
    params: query,
  });
};

export const exportInvitationRank = async (startDate?: string | null, endDate?: string | null) => {
  return apiClient.get('/admin/v1/join-promotions/rank/export', {
    params: { startDate, endDate },
    responseType: 'blob',
    headers: { 'Cache-Control': 'no-cache' },
  });
};
