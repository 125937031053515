import { ChildInfo } from './chat';
import { ClassAvailableArea, ClassBadge } from './class';
import { ClassPurchase, PaymentStatement } from './paymentStatement';
import { ClassSchedule } from './schedule';

export enum LegacyClassInvoiceStatus {
  CREATED = 'BEFORE_CREATE_STATUS', // 수강증 생성됨
  WAITING_FOR_PAYMENT = 'BEFORE_APPROVED_STATUS', // 클래스 승인 요청, 결제 대기
  PAYMENT_COMPLETED = 'ONGOING_STATUS', // 클래스 진행중,
  CLASS_COMPLETED = 'COMPLETE_STATUS', // 클래스 수강 완료
  PAYMENT_CANCELED = 'CANCELED_STATUS', // 클래스 수강 취소, PAYMENT_CANCELED, PAYMENT_REFUND
  DENIED = 'DENIED_STATUS', // 클래스 신청 거절
}

export enum ClassInvoiceStatus {
  CREATED = 'created', // 생성 시점 변경됨. 이 상태는 없음
  WAITING_FOR_PAYMENT = 'waiting_for_payment', // 튜터가 수강증 생성하여 부모에게 전송
  PAYMENT_COMPLETED = 'payment_completed', // 부모가 수강증에서 결제 완료 ~ 클래스 전까지 상태
  PAYMENT_CANCELED = 'payment_canceled', // 부모가 수강증에서 결제하지 않고 취소
  PAYMENT_REFUND = 'payment_refund', // 부모가 결제하고 환불 후 취소
  CLASS_COMPLETED = 'class_completed', // 클래스 완료
  DENIED = 'denied', // 튜터가 생성한 수강증을 튜터가 거절?
}

export interface CreateClassInvoiceData {
  classId?: string;
  parentId?: string;
  tutorId?: string;
  students?: ChildInfo[];
  additionalParticipantCount?: number;
  applyGroupDiscount?: boolean;
  totalClassTimes?: number;
  classDuration?: number;
  classFeePerTime?: number;
  totalAmount?: number;
  discountedAmount?: number;
  classTitle?: string;
  classThumbnail?: string;
  parentName?: string;
  tutorName?: string;
  tutorProfileImage?: string;
  chatRoomId?: string;
  dates?: Date[];
}

export interface ClassInvoiceCountByState {
  waiting_for_payment?: number;
  payment_completed?: number;
  class_completed?: number;
  total?: number;
}

export interface ExtendedClassInvoice extends ClassInvoice {
  count?: ClassInvoiceCountByState;
}

export interface ClassInvoice {
  _id: string;
  address?: string;
  addressDetail?: string;
  tutorId: string;
  parentId: string;
  userId?: string;
  classId: string;
  programId: string;
  tutorName: string;
  tutorProfileImage?: string;
  classTitle: string;
  classThumbnail: string;
  totalClassTimes: number;
  classDuration: number;
  candidates: ChildInfo[];
  students: ChildInfo[];
  children: ChildInfo[];
  additionalParticipantCount: number;
  applyGroupDiscount: boolean;
  classFeePerTime: number;
  totalAmount: number;
  discountedAmount: number;
  requestedFee: number;
  times: number;
  status: ClassInvoiceStatus | LegacyClassInvoiceStatus;
  report: any;
  schedules: ClassSchedule[];
  payment: ClassPurchase;
  paymentStatement: PaymentStatement;
  hasReview: boolean;
  count?: ClassInvoiceCountByState;
  lastStartDate: string;
  //legacy
  classOriginFee?: number;
  isGroupSales?: boolean;
  otherChildCount: number;
}

export interface GetClassInvoiceQuery {
  classId?: string;
  search?: string;
  invoiceState?: string;
  orderBy?: string;
  skip?: number;
  limit?: number;
}

export interface CouponToUse {}

export interface User {
  id: string;
  email: string;
  role: string;
  profile: Profile;
  deletedAt?: Date;
}

export interface Profile {
  name: string;
  phone: string;
  children: ChildInfo[];
  agreement: boolean;
  creditPoints: number;
  alarmAgree: boolean;
  otherAlarmAgree: boolean;
  otherChildren: any[];
  categorySubList: string[];
  active: boolean;
  activeAt: string;
  disactiveAt: string;
  marketingEmailAgree: boolean;
  marketingSMSAgree: boolean;
  coupons: any[];
  imageUrl: string;
  address: Address;
}

export interface Address {
  postcode: string;
  postcode1: string;
  postcode2: string;
  postcodeSeq: string;
  zonecode: string;
  address: string;
  addressEnglish: string;
  addressType: string;
  bcode: string;
  bname: string;
  bnameEnglish: string;
  bname1: string;
  bname1English: string;
  bname2: string;
  bname2English: string;
  sido: string;
  sidoEnglish: string;
  sigungu: string;
  sigunguEnglish: string;
  sigunguCode: string;
  userLanguageType: string;
  query: string;
  buildingName: string;
  buildingCode: string;
  apartment: string;
  jibunAddress: string;
  jibunAddressEnglish: string;
  roadAddress: string;
  roadAddressEnglish: string;
  autoRoadAddress: string;
  autoRoadAddressEnglish: string;
  autoJibunAddress: string;
  autoJibunAddressEnglish: string;
  userSelectedType: string;
  noSelected: string;
  hname: string;
  roadnameCode: string;
  roadname: string;
  roadnameEnglish: string;
  address2: string;
}

export interface Class {
  _id: string;
  categorySubList: string[];
  classStep: string;
  classType: string;
  concept: Concept[];
  createdAt: string;
  dumptimes: number;
  educationArea: ClassAvailableArea;
  groupAvailable: GroupAvailable;
  isApprovedByAdmin: boolean;
  isPublic: boolean;
  isTemp: boolean;
  minutes: number;
  newAgeMax: number;
  newAgeMin: number;
  onedayClassAvailable: boolean;
  tags: string[];
  thumImages: string[];
  title: string;
  tutorName: string;
  updatedAt: string;
  userId: string;
  classIntroduction: string;
  classIntroductionImages: string[];
  intelligences: string[];
  preparation: string;
  recommendType: string;
  studyContent: string;
  studyContentImages: string[];
  fee: number;
  images: string[];
  isGroupSales: boolean;
  totalNumber: number;
  viewCount: number;
  adminChangedAt: string;
  approvedCount: number;
  shortDescription: string;
  individualAvailable: boolean;
  chatButtonCTAImages: string[];
  isActiveCTA: boolean;
  isApplyDefaultRefundTerms: boolean;
  classTerms: string;
  isApplyPriorityOrder: boolean;
  badgeList: ClassBadge[];
  classbadge: string[];
  isActiveRegularFeeText: boolean;
  regularTime: number;
  classIntroList: ClassIntroList[];
  highlightText: string;
  normalText: string;
  isClosed: boolean;
}

export interface Concept {
  conceptData: string;
  images: string[];
  conceptTitle: string;
}

export interface AvailableDistrict {
  state: string;
  city: string;
}

export interface GroupAvailable {
  available: boolean;
  minimum: number;
  maximum: number;
}

export interface ClassIntroList {
  title: string;
  description: string;
}

export interface Payment {
  _id: string;
  programId: string;
  requestId: string;
  userId: string;
  response: Response;
  creditPoints: number;
  userPayCount: number;
  createdAt: string;
}

export interface Response {
  imp_success: string;
  imp_uid: string;
  merchant_uid: string;
  amount: number;
}

export interface StudentInfo {
  children: ChildInfo[];
  additionalParticipantCount: number;
  total: number;
}

export interface ClassTimes {
  totalClassTimes: number;
  duration: number;
}

export interface ClassDates {
  hasEmpty: boolean;
  dates: Date[];
}

export interface ClassFee {
  fee: number;
  total: number;
  regular: number;
}

export interface LegacyClassInvoice {
  _id: string;
  programId: string;
  userId: string;
  children: ChildInfo[];
  otherChildren: any[];
  thisClassOneday: boolean;
  times: number;
  isGroupSales: boolean;
  status: string;
  createdAt: string;
  classOriginFee: number;
  minutes: number;
  additionalParticipantCount: number;
  requestedFee: number;
  updatedAt: string;
  couponToUse: CouponToUse;
  creditPoints: number;
  lastStartDate: string;
  firstStartDate: string;
  parentName: string;
  classTitle: string;
  tutorName: string;
  user: User;
  class: Class;
  doneTimes: number;
}
