import { CITY_ALL } from '@consts/location';
import { AvailableDistrict } from '@models/class';
import { Button } from 'antd';
import _ from 'lodash';
import React, { useState } from 'react';
import './DistrictSelect.scss';
import DistrictSelectItem from './DistrictSelectItem';

interface DistrictSelectProps {
  onUpdate: (districts: AvailableDistrict[]) => void;
  defaultDistricts?: AvailableDistrict[] | undefined;
}

export const DistrictSelect: React.FC<DistrictSelectProps> = (props) => {
  const [districts, setDistricts] = useState(props.defaultDistricts || [{} as AvailableDistrict]);

  const onUpdateDistrict = (districtData: AvailableDistrict, index: number) => {
    if (districtData.city === CITY_ALL) {
      setDistricts((items: any) => {
        const newItems = [...items, districtData];
        newItems[index] = districtData;
        const filteredDistricts = _.filter(newItems, (district, districtIndex) => {
          if (districtIndex === index) {
            return true;
          }
          if (district.state === districtData.state) {
            return false;
          }
          return true;
        });
        return filteredDistricts;
      });
      props.onUpdate(districts);

      return;
    }

    setDistricts((items: any) => {
      const newItems = [...items];
      newItems[index] = districtData;
      props.onUpdate(newItems);
      return newItems;
    });
  };

  const onClickRemove = (index: number) => {
    setDistricts((items: any) => {
      const newItems = [...items];
      newItems.splice(index, 1);
      props.onUpdate(newItems);
      return newItems;
    });
  };

  const onClickUp = (index: number) => {
    setDistricts((items: any) => {
      const newItems = [...items];
      const from = index;
      const to = index - 1 >= 0 ? index - 1 : index;
      newItems.splice(to, 0, newItems.splice(from, 1)[0]);
      props.onUpdate(newItems);
      return newItems;
    });
  };

  const onClickDown = (index: number) => {
    setDistricts((items: any) => {
      const newItems = [...items];
      const from = index;
      const to = index + 1 >= 0 ? index + 1 : index;
      newItems.splice(to, 0, newItems.splice(from, 1)[0]);
      props.onUpdate(newItems);
      return newItems;
    });
  };

  const onClickAdd = () => {
    setDistricts((items: any) => {
      const newItems = [...items, {}];
      props.onUpdate(newItems);
      return newItems;
    });
  };

  return (
    <div className="district-select">
      {districts.map((district: AvailableDistrict, index: number) => {
        return (
          <DistrictSelectItem
            key={`district-${index}`}
            index={index}
            district={district}
            onUpdate={onUpdateDistrict}
            onClickRemove={onClickRemove}
            onClickUp={onClickUp}
            onClickDown={onClickDown}
          />
        );
      })}
      <Button className="district-select__add btn-purple" onClick={onClickAdd}>
        지역 추가
      </Button>
    </div>
  );
};

export default DistrictSelect;
