import { PagenatedResponse } from '@models/common';
import {
  ChatCounts,
  Counts,
  DailyCount,
  DailyPaymentCount,
  PaymentCounts,
  PaymentRankData,
  ProductCount,
  ProductCounts,
  ProductCountSummary,
  PurchasesReport,
  SalesReport,
  UserCounts,
} from '@models/statistics';
import { apiClient } from '.';

export const getPurchasesReport = async (query: {
  startDate: Date;
  endDate: Date;
  sort: string;
  page: number;
  size: number;
}) => {
  return apiClient.get<PagenatedResponse<PurchasesReport>>('/admin/v1/statistics/user-purchases-report', {
    params: query,
  });
};

export const getPurchasesReportExcelFile = async (query: {
  startDate: Date;
  endDate: Date;
  sort: string;
  page: number;
  size: number;
  detail?: boolean;
}) => {
  return apiClient.get(`/admin/v1/statistics/user-purchases-report/export`, {
    responseType: 'blob',
    headers: { 'Cache-Control': 'no-cache' },
    params: query,
  });
};

export const getSalesReportByPartner = async (startDate: Date, endDate: Date) => {
  return apiClient.get<SalesReport[]>('/admin/v1/statistics/sales', {
    params: { startDate, endDate },
  });
};

export const getCounts = async (startDate: string | null, endDate: string | null) => {
  return apiClient.get<Counts>('/admin/v1/statistics/counts', {
    params: { startDate, endDate },
  });
};

export const getUsersCounts = async (startDate: string | null, endDate: string | null) => {
  return apiClient.get<UserCounts>('/admin/v1/statistics/counts/users', {
    params: { startDate, endDate },
  });
};

export const getDailyActiveUserCounts = async (startDate: string | null, endDate: string | null) => {
  return apiClient.get<Counts>('/admin/v1/statistics/counts/dau', {
    params: { startDate, endDate },
  });
};

export const getMonthlyActiveUserCounts = async (startDate: string | null, endDate: string | null) => {
  return apiClient.get<Counts>('/admin/v1/statistics/counts/mau', {
    params: { startDate, endDate },
  });
};

export const getVisitCounts = async (startDate: string | null, endDate: string | null) => {
  return apiClient.get<Counts>('/admin/v1/statistics/counts/visit', {
    params: { startDate, endDate },
  });
};

export const getNewParentsCounts = async (startDate: string | null, endDate: string | null) => {
  return apiClient.get<Counts>('/admin/v1/statistics/counts/new-parents', {
    params: { startDate, endDate },
  });
};

export const getNewTutorsCounts = async (startDate: string | null, endDate: string | null) => {
  return apiClient.get<Counts>('/admin/v1/statistics/counts/new-tutors', {
    params: { startDate, endDate },
  });
};

export const getSearchTextRank = async (startDate: string | null, endDate: string | null) => {
  return apiClient.get<
    {
      _id: string;
      count: number;
    }[]
  >('/admin/v1/statistics/keyword-rank', {
    params: { startDate, endDate },
  });
};

export const exportSearchTextRank = async (startDate: string | null, endDate: string | null) => {
  return apiClient.get('/admin/v1/statistics/keyword-rank/export', {
    params: { startDate, endDate },
    responseType: 'blob',
    headers: { 'Cache-Control': 'no-cache' },
  });
};

export const getDateSearchRank = async (startDate: string | null, endDate: string | null) => {
  return apiClient.get<{
    totalCount: number;
    dateSearchCount: number;
    rank: {
      _id: string;
      count: number;
      query?: any;
    }[];
  }>('/admin/v1/statistics/keyword-rank/date', {
    params: { startDate, endDate },
  });
};

export const getNoResultSearchTextRank = async (startDate: string | null, endDate: string | null) => {
  return apiClient.get<
    {
      _id: string;
      count: number;
    }[]
  >('/admin/v1/statistics/keyword-rank/no-result', {
    params: { startDate, endDate },
  });
};

export const exportNoResultSearchTextRank = async (startDate: string | null, endDate: string | null) => {
  return apiClient.get('/admin/v1/statistics/keyword-rank/no-result/export', {
    params: { startDate, endDate },
    responseType: 'blob',
    headers: { 'Cache-Control': 'no-cache' },
  });
};

export const exportDateSearchRank = async (startDate: string | null, endDate: string | null) => {
  return apiClient.get('/admin/v1/statistics/keyword-rank/date/export', {
    params: { startDate, endDate },
    responseType: 'blob',
    headers: { 'Cache-Control': 'no-cache' },
  });
};

export const getDailyActiveUsersCount = async (startDate: string | null, endDate: string | null) => {
  return apiClient.get<DailyCount[]>('/admin/v1/statistics/dau', {
    params: { startDate, endDate },
  });
};

export const getDailyJoinUsersCount = async (startDate: string | null, endDate: string | null) => {
  return apiClient.get<DailyCount[]>('/admin/v1/statistics/daily-join', {
    params: { startDate, endDate },
  });
};

export const getDailyActiveSessionsCount = async (startDate: string | null, endDate: string | null) => {
  return apiClient.get<DailyCount[]>('/admin/v1/statistics/daily-session', {
    params: { startDate, endDate },
  });
};

export const getDailyViewCount = async (startDate: string | null, endDate: string | null) => {
  return apiClient.get<DailyCount[]>('/admin/v1/statistics/daily-view', {
    params: { startDate, endDate },
  });
};

export const getProductDailyViewCount = async (startDate: string | null, endDate: string | null) => {
  return apiClient.get<ProductCountSummary[]>('/admin/v1/statistics/product-report/daily-view', {
    params: { startDate, endDate },
  });
};

export const getDailyPaymentCount = async (startDate: string | null, endDate: string | null) => {
  return apiClient.get<DailyPaymentCount[]>('/admin/v1/statistics/daily-payment', {
    params: { startDate, endDate },
  });
};

export const getDailyPaymentErrorCount = async (startDate: string | null, endDate: string | null) => {
  return apiClient.get<DailyPaymentCount[]>('/admin/v1/statistics/daily-payment-error', {
    params: { startDate, endDate },
  });
};

export const getDailyPaymentReadyCount = async (startDate: string | null, endDate: string | null) => {
  return apiClient.get<DailyPaymentCount[]>('/admin/v1/statistics/daily-payment-ready', {
    params: { startDate, endDate },
  });
};

export const getProductCounts = async () => {
  return apiClient.get<ProductCounts>('/admin/v1/statistics/product-counts');
};

export const getChatCounts = async (startDate: string | null, endDate: string | null) => {
  return apiClient.get<ChatCounts>('/admin/v1/statistics/chat-counts', { params: { startDate, endDate } });
};

export const getPaymentCounts = async (startDate: string | null, endDate: string | null) => {
  return apiClient.get<PaymentCounts>('/admin/v1/statistics/payment-counts', { params: { startDate, endDate } });
};

export const getPaymentRank = async (options: {
  startDate: string | null;
  endDate: string | null;
  saleType: 'commerce' | 'class';
  sort: 'desc' | 'asc';
  limit: number;
}) => {
  return apiClient.get<PaymentRankData[]>('/admin/v1/statistics/payment-rank', { params: options });
};

export const getRefundRankSeller = async (startDate: string | null, endDate: string | null) => {
  return apiClient.get<PaymentRankData[]>('/admin/v1/statistics/refund-seller-rank', {
    params: { startDate, endDate },
  });
};

export const getRefundRankProduct = async (startDate: string | null, endDate: string | null) => {
  return apiClient.get<PaymentRankData[]>('/admin/v1/statistics/refund-product-rank', {
    params: { startDate, endDate },
  });
};

export const getUserCountByState = async (startDate: string | null, endDate: string | null) => {
  return apiClient.get<{ state: string; count: number }[]>('/admin/v1/statistics/user-region-count', {
    params: { startDate, endDate },
  });
};

export const getPageViewCount = async (options: { path: string; by: string }) => {
  return apiClient.get<PaymentRankData[]>('/admin/v1/statistics/page/view-count', { params: options });
};

export const getProductReport = async (options: {
  year: number;
  month?: number;
  day?: number;
  week?: number;
  sort?: string;
}) => {
  return apiClient.get<PagenatedResponse<ProductCount>>('/admin/v1/statistics/product-report', {
    params: options,
  });
};

export const getProductReportExcelFile = async (options: {
  year: number;
  month?: number;
  day?: number;
  week?: number;
  sort?: string;
}) => {
  return apiClient.get('/admin/v1/statistics/product-report/export', {
    params: options,
    responseType: 'blob',
    headers: { 'Cache-Control': 'no-cache' },
  });
};

export const generateProductReport = async (options: { year: number; month?: number; day?: number }) => {
  return apiClient.post<ProductCount[]>(
    '/admin/v1/statistics/product-report',
    {},
    {
      params: options,
    },
  );
};

export const getClicksRank = async (options: { startDate: string | null; endDate: string | null }) => {
  return apiClient.get<
    {
      _id: {
        actionId: string;
        actionType: string;
        collectionId?: string;
        productId?: string;
        link?: string;
        keyword?: string;
      };
      count: number;
    }[]
  >('/admin/v1/statistics/clicks', { params: options });
};

export const exportClicksRank = async (startDate: string | null, endDate: string | null) => {
  return apiClient.get('/admin/v1/statistics/clicks/export', {
    params: { startDate, endDate },
    responseType: 'blob',
    headers: { 'Cache-Control': 'no-cache' },
  });
};
