import * as api from '@apis/product';
import { useAppSocket } from '@hooks/appSocket';
import { useAuthentication } from '@hooks/authentication';
import { ProductInventoryClosingLog } from '@models/product';
import { useProductInventoryClosingHistoryStore } from '@stores/productInventoryClosingHistoryStore';
import { useMutation, useQuery } from '@tanstack/react-query';
import { localTimeToUtcISOString, utcTimcToLocalISOString } from '@utils/date';
import { Button, Checkbox, DatePicker, DatePickerProps, Input, Modal, Progress, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import JsonFormatter from 'react-json-formatter';
import { toast } from 'react-toastify';

export const ProductInventoryClosingHistoryPage: React.FC = () => {
  const appSocket = useAppSocket();
  const [jsonItem, setJsonItem] = useState<any>();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [searchKeyword, setSearchKeyword] = useState<string>();
  const [blacklist, setBlacklist] = useState<string>(
    '새싹여행, 스쿨김영사, 라이브뮤지엄, 고미선생, 숲에온아이들, 챌린저스',
  );
  const [whitelist, setWhitelist] = useState<string>();
  const [closingDate, setClosingDate] = useState<string>();
  const [showSyncModal, setShowSyncModal] = useState<boolean>(false);
  const [showAutoCloseModal, setShowAutoCloseModal] = useState<boolean>(false);
  const [syncState, setSyncState] = useState<{
    status: string;
    message: string;
    current?: number;
    total?: number;
    closingCount?: number;
  }>({ status: 'ready', message: '' });
  const searchInputRef = useRef<any>();
  const store = useProductInventoryClosingHistoryStore();

  useAuthentication();

  useEffect(() => {
    if (!searchKeyword && store.searchQuery.keyword) {
      setSearchKeyword(store.searchQuery.keyword);
    }
  }, [store.searchQuery.keyword]);

  const productInventoryClosingHistoryQuery = useQuery({
    queryKey: [`product-inventory-closing-history`, JSON.stringify(store.searchQuery)],
    queryFn: () => api.searchProductInventoryClosingHistory(store.searchQuery),
  });

  const updateProductInventoryClosingHistoryToOriginMutation = useMutation({
    mutationFn: api.updateProductInventoryClosingHistoryToOrigin,
    onSuccess: async () => {
      productInventoryClosingHistoryQuery.refetch();
      toast.dark('업데이트 했습니다.', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: false,
        draggable: true,
        progress: undefined,
        bodyStyle: {
          color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
          textAlign: 'center',
          fontFamily: 'Pretendard',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '20px',
        },
        theme: 'dark',
      });
    },
  });

  const productInventoryClosingHistory = productInventoryClosingHistoryQuery.data?.data;

  useEffect(() => {
    if (productInventoryClosingHistory) {
      store.setProductInventoryClosingHistoryContainer(productInventoryClosingHistory);
    }
  }, [productInventoryClosingHistory]);

  const onProductInventoryClosingHistoryStatus = (data: {
    status: string;
    message: string;
    current?: number;
    total?: number;
    closingCount?: number;
  }) => {
    setSyncState((prev) => {
      if (data.status === 'done') {
        return { ...prev, message: '완료!', status: data.status };
      } else if (data.status === 'cancelled') {
        return { ...prev, message: '취소됨', status: data.status };
      }
      return { ...data };
    });
  };

  useEffect(() => {
    appSocket.socket.addSocketEventListener(
      'sync_naver_commerce_products_status',
      'sync_naver_commerce_products_status',
      onProductInventoryClosingHistoryStatus,
    );
    return () => {
      appSocket.socket.removeSocketEventListener(
        'sync_naver_commerce_products_status',
        'sync_naver_commerce_products_status',
      );
    };
  }, []);

  const TABLE_COLUMNS: ColumnsType<ProductInventoryClosingLog> = [
    {
      fixed: 'left',
      title: () => {
        return (
          <Checkbox
            checked={
              JSON.stringify(selectedIds.sort()) ===
              JSON.stringify(
                (store.searchResultContainer.contents || [])
                  .filter((product) => !!product)
                  .map((product) => product._id)
                  .sort(),
              )
            }
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedIds(
                  (store.searchResultContainer.contents || [])
                    .filter((product) => !!product)
                    .map((product) => product._id),
                );
              } else {
                setSelectedIds([]);
              }
            }}></Checkbox>
        );
      },
      dataIndex: '_id',
      width: 60,
      render: (_id) => {
        return (
          <Checkbox
            onChange={(e) => {
              setSelectedIds((values) => {
                const checked = e.target.checked;
                const newValues = [...values];
                if (checked) {
                  if (!newValues.includes(_id)) {
                    newValues.push(_id);
                  }
                  return newValues;
                } else {
                  return newValues.filter((value) => value !== _id);
                }
              });
            }}
            checked={selectedIds.includes(_id)}></Checkbox>
        );
      },
    },
    {
      fixed: 'left',
      title: 'ID',
      render: (record: ProductInventoryClosingLog) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(record?._id);
                toast.dark('ID를 클립보드에 복사했습니다', {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  pauseOnFocusLoss: false,
                  draggable: true,
                  progress: undefined,
                  bodyStyle: {
                    color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                    textAlign: 'center',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                  },
                  theme: 'dark',
                });
              }}>
              ID
            </Button>
            {record?.origin?.[0]?.productId && (
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(record?.origin?.[0]?.productId);
                  toast.dark('상품ID를 클립보드에 복사했습니다', {
                    position: 'top-center',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    pauseOnFocusLoss: false,
                    draggable: true,
                    progress: undefined,
                    bodyStyle: {
                      color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                      textAlign: 'center',
                      fontFamily: 'Pretendard',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '20px',
                    },
                    theme: 'dark',
                  });
                }}>
                상품 ID
              </Button>
            )}
            {record?.origin.channelProductNo && (
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(record?.origin.channelProductNo);
                  toast.dark('상품번호를 클립보드에 복사했습니다', {
                    position: 'top-center',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    pauseOnFocusLoss: false,
                    draggable: true,
                    progress: undefined,
                    bodyStyle: {
                      color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                      textAlign: 'center',
                      fontFamily: 'Pretendard',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '20px',
                    },
                    theme: 'dark',
                  });
                }}>
                네이버 상품번호
              </Button>
            )}
          </div>
        );
      },
    },
    {
      title: '판매자',
      render: (record: ProductInventoryClosingLog) => {
        return `${record?.sellerName}${record?.sellerId ? ' ✅' : ''}`;
      },
    },
    {
      title: '채널',
      render: (record: ProductInventoryClosingLog) => {
        if (record?.type === 'naver') {
          return `네이버`;
        }
        if (record?.type === 'commerce') {
          return `앱`;
        }
        return '-';
      },
    },
    {
      title: '상품명',
      width: 320,
      render: (record: ProductInventoryClosingLog) => {
        return record?.productName;
      },
    },
    {
      title: '총 재고 변경',
      render: (record: ProductInventoryClosingLog) => {
        if (record?.type === 'naver') {
          return `${record?.origin?.originProduct?.stockQuantity} -> ${record?.new?.originProduct?.stockQuantity}`;
        }

        if (record?.type === 'commerce') {
          const origin = (record?.origin || []).reduce((prev: number, current: any) => {
            return prev + current.inventory;
          }, 0);
          const deleted = (record?.deletedOptions || []).reduce((prev: number, current: any) => {
            return prev + current.inventory;
          }, 0);

          return `${origin} -> ${origin - deleted}`;
        }
        return '-';
      },
    },
    {
      title: '마감 대상 재고',
      render: (record: ProductInventoryClosingLog) => {
        if (record?.type === 'naver' || record?.type === 'commerce') {
          return (
            <Button
              onClick={() => {
                setJsonItem(record?.deletedOptions);
              }}>
              보기
            </Button>
          );
        }
        return '-';
      },
    },
    {
      title: '반영',
      render: (record: ProductInventoryClosingLog) => {
        if (record?.type === 'naver' || record?.type === 'commerce') {
          return (
            <Button
              onClick={() => {
                updateProductInventoryClosingHistoryToOriginMutation.mutate({
                  inventoryClosingLogId: record._id,
                  action: 'apply',
                });
              }}>
              반영하기
            </Button>
          );
        }
        return '-';
      },
    },
    {
      title: '복구',
      render: (record: ProductInventoryClosingLog) => {
        if (record?.type === 'naver' || record?.type === 'commerce') {
          return (
            <Button
              onClick={() => {
                updateProductInventoryClosingHistoryToOriginMutation.mutate({
                  inventoryClosingLogId: record._id,
                  action: 'restore',
                });
              }}>
              복구하기
            </Button>
          );
        }
        return '-';
      },
    },
    {
      title: '업데이트 결과',
      width: 110,
      render: (record: ProductInventoryClosingLog) => {
        if (record?.type === 'naver' && record?.updateResult) {
          return (
            <Button
              onClick={() => {
                setJsonItem(record?.updateResult);
              }}>
              보기
            </Button>
          );
        }
        return '-';
      },
    },
    {
      title: '업데이트',
      dataIndex: 'updatedAt',
      render: (updatedAt) => {
        return updatedAt ? moment(updatedAt).format('YYYY.MM.DD HH:mm') : '-';
      },
    },
    {
      title: '생성',
      dataIndex: 'createdAt',
      render: (createdAt) => {
        return createdAt ? moment(createdAt).format('YYYY.MM.DD HH:mm') : '-';
      },
    },
  ];

  const onChangeStartDate: DatePickerProps['onChange'] = (date) => {
    store.setStartDate(localTimeToUtcISOString(date ? new Date(date.toISOString()) : new Date(), { timeToZero: true }));
  };

  const onChangeEndDate: DatePickerProps['onChange'] = (date) => {
    store.setEndDate(localTimeToUtcISOString(date ? new Date(date.toISOString()) : new Date(), { timeToZero: true }));
  };

  const syncCurrent = syncState?.current || 0;
  const syncTotal = syncState?.total || 1;
  const closingCount = syncState?.closingCount || 0;

  return (
    <div className="dashboard" style={{ position: 'relative' }}>
      <Modal
        width={'480px'}
        open={!!jsonItem}
        onCancel={() => {
          setJsonItem(undefined);
        }}
        styles={{ body: { paddingTop: '24px', height: 'calc(100dvh - 240px)' } }}>
        <div style={{ height: 'calc(100dvh - 280px)', overflow: 'auto' }}>
          <JsonFormatter
            json={jsonItem}
            tabWith={4}
            jsonStyle={{
              propertyStyle: { color: '#b16b2a' },
              stringStyle: { color: 'green' },
              numberStyle: { color: 'blue' },
            }}
          />
        </div>
      </Modal>
      <Modal
        width={'480px'}
        open={showSyncModal}
        footer={
          <div>
            <Button
              disabled={['prepare', 'in_progress'].includes(syncState.status)}
              onClick={() => {
                appSocket.syncNaverCommerceProducts({ type: 'closing', blacklist, whitelist, closingDate });
              }}>
              동기화
            </Button>
            <Button
              disabled={['none', 'done', 'error', 'cancelled'].includes(syncState.status)}
              onClick={() => {
                appSocket.cancelSyncNaverCommerceProducts();
              }}>
              동기화 작업 취소
            </Button>
            <Button
              onClick={() => {
                setShowSyncModal(false);
                productInventoryClosingHistoryQuery.refetch();
                setSyncState({ status: 'ready', message: '' });
              }}>
              닫기
            </Button>
          </div>
        }
        onCancel={() => {
          setShowSyncModal(false);
          productInventoryClosingHistoryQuery.refetch();
        }}
        onOk={() => {
          setShowSyncModal(false);
          productInventoryClosingHistoryQuery.refetch();
        }}>
        <div>
          <div style={{ fontWeight: 'bold', fontSize: '16px' }}>네이버 상품 정보 가져오기</div>
          <div style={{ marginTop: '24px' }}>
            <div>포함할 상품 검색어(쉼표(,)로 구분)</div>
            <Input.TextArea
              value={whitelist}
              onChange={(e) => {
                setWhitelist(e.target.value);
              }}></Input.TextArea>
          </div>
          <div style={{ marginTop: '24px' }}>
            <div>제외할 상품 검색어(쉼표(,)로 구분)</div>
            <Input.TextArea
              value={blacklist}
              onChange={(e) => {
                setBlacklist(e.target.value);
              }}></Input.TextArea>
          </div>
          <div style={{ marginTop: '8px', display: 'flex', alignItems: 'center', gap: '8px' }}>
            <div>마감 대상 날짜</div>
            <DatePicker
              value={dayjs(closingDate)}
              showTime={false}
              onChange={(value, dateString) => {
                const datetime = localTimeToUtcISOString(dateString, { timeToZero: true });
                if (datetime) {
                  setClosingDate(datetime);
                }
              }}
            />
          </div>

          <div
            style={{
              marginTop: '24px',
              padding: '12px 0',
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              alignItems: 'flex-end',
            }}>
            <div>
              <Progress percent={Math.floor((syncCurrent / syncTotal) * 100)} size={[400, 20]} />
            </div>
            {syncCurrent > 0 && syncTotal > 1 && <div>{`${syncCurrent} / ${syncTotal}`}</div>}
            {closingCount > 0 && <div>{`${closingCount}개 상품 재고마감`}</div>}
            <div style={{ width: '100%', height: '36px' }}>{syncState.message}</div>
          </div>
        </div>
      </Modal>
      <Modal
        width={'480px'}
        open={showAutoCloseModal}
        footer={
          <div>
            <Button
              disabled={['prepare', 'in_progress'].includes(syncState.status)}
              onClick={() => {
                appSocket.syncNaverCommerceProducts({ type: 'closing', blacklist, whitelist, closingDate });
              }}>
              동기화
            </Button>
            <Button
              disabled={['none', 'done', 'error', 'cancelled'].includes(syncState.status)}
              onClick={() => {
                appSocket.cancelSyncNaverCommerceProducts();
              }}>
              동기화 작업 취소
            </Button>
            <Button
              onClick={() => {
                setShowAutoCloseModal(false);
                productInventoryClosingHistoryQuery.refetch();
                setSyncState({ status: 'ready', message: '' });
              }}>
              닫기
            </Button>
          </div>
        }
        onCancel={() => {
          setShowAutoCloseModal(false);
          productInventoryClosingHistoryQuery.refetch();
        }}
        onOk={() => {
          setShowAutoCloseModal(false);
          productInventoryClosingHistoryQuery.refetch();
        }}>
        <div>
          <div style={{ fontWeight: 'bold', fontSize: '16px' }}>자동 재고마감</div>
          <div style={{ marginTop: '24px' }}>
            <div>포함할 상품 검색어(쉼표(,)로 구분)</div>
            <Input.TextArea
              value={whitelist}
              onChange={(e) => {
                setWhitelist(e.target.value);
              }}></Input.TextArea>
          </div>
          <div style={{ marginTop: '24px' }}>
            <div>제외할 상품 검색어(쉼표(,)로 구분)</div>
            <Input.TextArea
              value={blacklist}
              onChange={(e) => {
                setBlacklist(e.target.value);
              }}></Input.TextArea>
          </div>
          <div style={{ marginTop: '8px', display: 'flex', alignItems: 'center', gap: '8px' }}>
            <div>마감 대상 날짜</div>
            <DatePicker
              value={dayjs(closingDate)}
              showTime={false}
              onChange={(value, dateString) => {
                const datetime = localTimeToUtcISOString(dateString, { timeToZero: true });
                if (datetime) {
                  setClosingDate(datetime);
                }
              }}
            />
          </div>

          <div
            style={{
              marginTop: '24px',
              padding: '12px 0',
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              alignItems: 'flex-end',
            }}>
            <div>
              <Progress percent={Math.floor((syncCurrent / syncTotal) * 100)} size={[400, 20]} />
            </div>
            {syncCurrent > 0 && syncTotal > 1 && <div>{`${syncCurrent} / ${syncTotal}`}</div>}
            {closingCount > 0 && <div>{`${closingCount}개 상품 재고마감`}</div>}
            <div style={{ width: '100%', height: '36px' }}>{syncState.message}</div>
          </div>
        </div>
      </Modal>
      <div className="page-header">
        <div>재고 마감 이력 관리</div>
        <div style={{ display: 'flex', gap: '8px' }}>
          <DatePicker
            onChange={onChangeStartDate}
            value={dayjs(utcTimcToLocalISOString(store.searchQuery.startDate as any))}
          />
          <DatePicker
            onChange={onChangeEndDate}
            value={dayjs(utcTimcToLocalISOString(store.searchQuery.endDate as any))}
          />
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <div className="page-content" style={{ width: '100%', height: 'calc(100vh - 80px)', overflow: 'auto' }}>
          <div style={{ display: 'flex', gap: '12px', width: '100%', height: '52px', alignItems: 'center' }}>
            <Input
              ref={searchInputRef}
              style={{ width: '240px' }}
              value={searchKeyword}
              onChange={(e) => {
                setSearchKeyword(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.code === 'Enter' && searchKeyword) {
                  store.updateSearchQuery({ keyword: searchKeyword });
                }
              }}></Input>
            <Button
              onClick={() => {
                setSelectedIds([]);
                setSearchKeyword(undefined);
                store.resetSearchQuery();
              }}>
              검색 초기화
            </Button>
            <div>{`결과: ${store.searchResultContainer?.total || 0}개`}</div>

            <Button
              onClick={() => {
                setShowSyncModal(true);
              }}>
              네이버에서 재고 마감 상품 가져오기
            </Button>
            {/* <Button
              onClick={() => {
                setShowAutoCloseModal(true);
              }}>
              자동 재고마감
            </Button> */}
          </div>
          <Table
            columns={TABLE_COLUMNS}
            scroll={{ x: 'max-content', y: 'calc(100dvh - 320px)' }}
            style={{ whiteSpace: 'pre-wrap' }}
            rowKey={(record) => record?._id}
            dataSource={store.searchResultContainer.contents.filter((product) => product?._id)}
            pagination={{
              current: (store.searchResultContainer.page || 0) + 1,
              pageSize: store.searchResultContainer.size || 20,
              total: store.searchResultContainer.total,
            }}
            loading={productInventoryClosingHistoryQuery.isLoading}
            onChange={(e) => {
              store.updateSearchQuery({ page: (e.current || 2) - 1, size: e.pageSize || 20 });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductInventoryClosingHistoryPage;
