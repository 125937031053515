import { ClassInquiryInfo } from '@components/class-invoice/ClassInquiryModal';
import { ClassInvoice } from '@models/invoice';
import { create } from 'zustand';

export type ClassInvoiceViewMode =
  | 'create'
  | 'create_confirm'
  | 'payment'
  | 'payment_completed'
  | 'class_schedule_edit'
  | 'view';
export interface ClassInvoiceModalData {
  classId: string;
  parentId?: string;
  classInvoiceId?: string;
  data?: Partial<ClassInvoice>;
  mode?: ClassInvoiceViewMode;
  classInquiryInfo?: ClassInquiryInfo;
  chatRoomId?: string;
  isTutor?: boolean;
}

interface ClassInvoiceModalStore {
  show?: boolean;
  data?: ClassInvoiceModalData;
  close: () => boolean;
  open: (data?: ClassInvoiceModalData) => void;
}

export const useClassInvoiceModalStore = create<ClassInvoiceModalStore>((set, get) => ({
  show: false,
  close: () => {
    const isCloseExecute = !!get().show;
    set({ show: false });
    return isCloseExecute;
  },
  open: (data?: ClassInvoiceModalData) => {
    set({ data: data, show: true });
  },
}));
