import { User } from './auth';
import { SpecificAddress } from './class';
import { CommerceProduct } from './commerce';
import { ClassInvoice } from './invoice';
import { Membership } from './membership';
import { ProductModel } from './product';
import { RefundLog } from './refundLog';
import { ClassSchedule } from './schedule';

export interface IamportResponse {
  imp_success: string;
  imp_uid: string;
  merchant_uid: string;
  amount: number;
  payType: string;
}

export interface ClassPurchase {
  _id: string;
  programId: string;
  requestId: string;
  userId: string;
  response: IamportResponse;
  userPayCount?: number;
  createdAt: Date;
  updatedAt?: Date;
  creditPoints?: number;
  refundCreditPoints?: number;
  refundAmount?: number;
  isRefundRegister?: boolean;
  refundRegisterCount?: number;
  isPaidout?: Date;
}

export interface SettlementsModel extends ClassPurchase {
  purchasedUserName?: string;
  classTitle?: string;
  purchasedFee?: number;
  invoiceState?: string;
  scheduleTimes?: string;
  settlementFee?: number;
  reportPushDate?: string;
}

export interface ProductOptionList {
  optionName: string | string[];
  selectOption?: string[];
  optionFee: number;
  optionCount: number;
}

export interface RefundList {
  optionName: string | string[];
  selectOption?: string[];
  optionFee: number;
  optionCount: number;
  refundAmount?: number;
  essential?: boolean;
}
export interface CommerceRefundList {
  refundList: RefundList[];
  refundReciptUrl: string;
  amount: number;
  creditPoints: number;
  createdAt: Date;
}

export interface CommercePurchase {
  _id: string;
  productId: string;
  userId: string;
  response: IamportResponse;
  productOptionList: ProductOptionList[];
  productShippingFee: number;
  productSaleFee: number;
  productState: string;
  buyerName: string;
  buyerPhone: string;
  shippingName: string;
  shippingPhone: string;
  shippingMemo?: string;
  shippingAddress?: SpecificAddress;
  payType: string;
  creditPoints: number;
  createdAt: Date;
  shippingId: string;
  shippingNumber: string;
  shippingInfoName: string;
  updatedAt: string;
  shippingStatus: string;
  shippingStatusName: string;
  productName: string;
  userName: string;
  sellerName: string;
  refundMemo?: string;
  refundAmount?: number;
  refundCreditPoints?: number;
  refundState?: string;
  isRefundShippingFee?: boolean;
  refundList?: CommerceRefundList[];
  refundRegisterCount?: number;
}

export interface CommercePurchaseModel extends CommercePurchase {
  commerceProduct?: CommerceProduct;
}

export interface GetPurchasesQuery {
  startDate?: Date;
  endDate?: Date;
  searchText?: string;
  searchType?: string;
  page?: number;
  size?: number;
}

export interface CreatePaymentStatementsBody {
  uuid: string;
  buyer: PaymentStatementUser;
  seller: PaymentStatementUser;
  product: PaymentStatementProduct;
  purchases: PaymentStatementPurchase[];
  payment: PaymentStatementPayment;
  shipping?: PaymentStatementShipping;
  memo?: PaymentStatementMemo;
}

export enum RefundStatus {
  REFUNDREGISTER = 'refundRegister', // migrated
  NONE = 'none',
  ALL = 'all',
  PARTIAL = 'partial',
  REQUESTED = 'requested',
  ACCEPTED = 'accepted',
  DENIED = 'denied',
  RESERVED = 'reserved',
  ADMIN_SUBMISSON = 'admin_submission',
  REQUEST_CANCELLED = 'request_cancelled',
}

export enum PaymentStatementSettlementStatus {
  DONE = 'done',
  READY = 'ready',
  NONE = 'none',
}

export enum PaymentStatementReportStatus {
  PUBLISHED = 'published',
  EDITTING = 'editting',
}

export interface PaymentStatementStatus {
  payment?: string;
  refund?: RefundStatus;
  settlement?: PaymentStatementSettlementStatus;
  shipping?: string;
  report?: PaymentStatementReportStatus;
}

export interface PaymentStatementUser extends Partial<User> {
  id: string;
  name?: string;
  phone?: string;
  membership?: Partial<Membership>;
}

export interface PaymentStatementProduct extends Partial<ProductModel> {
  saleType?: string;
  id: string;
  name: string;
  classInvoiceId?: string;
  applyGroupDiscount?: boolean;
  favoritedCount?: number;
}

export interface PaymentStatementShipping {
  name?: string;
  phone?: string;
  fee?: number;
  address?: string;
  addressDetail?: string;
  invoiceNumber?: string;
  company?: string;
  status?: string;
}

export interface PaymentStatementPurchase {
  option?: string[];
  name?: string;
  fee?: number;
  count: number;
}

export interface PaymentStatementPayment {
  method: string;
  gateway: string;
  amount: number;
  point: number;
  billingAmount: number;
  membershipDiscountAmount?: number;
  paidAt?: Date;
  result?: any;
  bankAccountTransferAmount?: number;
}

export enum ReserveStatus {
  RESERVE = 'reserve',
  DONE = 'done',
  CANCELED = 'canceled',
  NONE = 'none',
}

export enum RefundSubmissionStatus {
  NOTIFIED = 'notified',
  NONE = 'none',
}

export interface RefundData {
  // migrated
  receiptUrl?: string;
  createdAt?: Date;
  partnerRefundAmout?: number;
  amount?: number;
  billingAmount?: number;
  point?: number;
  refundItems?: PaymentStatementPurchase[];
  status?: ReserveStatus;
  submissionStatus?: RefundSubmissionStatus;
}

export interface PaymentStatementRefund {
  amount?: number;
  point?: number;
  billingAmount?: number;
  data: (RefundData | RefundLog)[];
  legacyHistory?: RefundData[];
  noMoreRefundsAvailable?: boolean;
  submissionStatus?: RefundSubmissionStatus;
}

export interface PaymentStatementSettlement {
  ratio: number;
  amount: number;
  transferAmount: number;
  transferAt?: Date;
}

export interface PaymentStatementMemo {
  payment?: string;
  refund?: string;
  refundUser?: string;
  refundPartner?: string;
  settlement?: string;
  shipping?: string;
  bankAccountTransfer?: string;
  qna?: string;
}

export interface ReviewModel {
  _id: string;
  userId: string;
  comment: string;
  rootId: string;
  productId: string;
  paymentStatementId: string;
  rates: Rates;
  step: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}

export interface Rates {
  rate1: number;
  rate2: number;
  rate3: number;
  rate4: number;
}

export class PaymentStatementReport {
  id?: string;
  publishedAt?: Date;
  createdAt?: Date;
}

export class PaymentStatementPayback {
  isNotApplicable?: boolean;
  amount?: number;
  type?: string; // purchase / event
  unit?: string; // 'point'
  transferAt?: Date;
}

export interface PaymentStatement {
  _id: string;
  uuid: string;
  buyer: PaymentStatementUser;
  seller: PaymentStatementUser;
  product: PaymentStatementProduct;
  purchases: PaymentStatementPurchase[];
  refunds?: PaymentStatementPurchase[];
  payment: PaymentStatementPayment;
  refund?: PaymentStatementRefund;
  report?: PaymentStatementReport;
  shipping?: PaymentStatementShipping;
  settlement?: PaymentStatementSettlement;
  statuses: PaymentStatementStatus;
  memo: PaymentStatementMemo;
  legacy?: object;
  review?: ReviewModel;
  plannedDates?: Date[];
  createdAt?: Date;
  updatedAt?: Date;
  completionDate?: Date;
  payback?: PaymentStatementPayback;
}

export interface PaymentStatementLog {
  _id: string;
  statementUuid: string;
  event: string;
  data: PaymentStatement;
  createdAt: Date;
}

export interface PaymentStatementWithLogs extends PaymentStatement {
  statementLogs?: PaymentStatementLog[];
  classInvoiceReport?: any;
  productDetail?: ProductModel;
  buyerDetail?: User[];
  sellerDetail?: User[];
  classInvoice?: ClassInvoice;
}

export enum UpdatePaymentStatementEventType {
  CREATE = 'create',
  PAYMENT_DONE = 'payment_done',
  PAYMENT_CANCEL = 'payment_cancel',
  SETTLEMENT = 'settlement',
  REFUND = 'refund',
  SUBMISSION = 'submission',
  CANCEL_SUBMISSION = 'cancel_submission',
  RESERVE = 'reserve',
  REMOVE_RESERVE = 'remove_reserve',
}

export interface AdminRefundBody {
  referenceId?: string;
  fee: number;
  refundItems: PaymentStatementPurchase[];
  memo?: string;
  noMoreRefundsAvailable?: boolean;
}

export type ClassChangePurchaseItem = ScheduleInfo<Pick<ClassSchedule, '_id' | 'startDate'>>;
export type CommerceChangePurchasesItem = ScheduleInfo<PaymentStatementPurchase>;
interface ScheduleInfo<T> {
  currentItem: T;
  newItem: string;
}
interface AdminCorrectMemo {
  payment: string;
  shipping: string;
}
export interface ChangeClassScheduleBody {
  saleType: 'class';
  changePurchaseItems: ClassChangePurchaseItem[];
  memo?: Partial<AdminCorrectMemo>;
}
export interface ChangeCommerceOptionBody {
  saleType: 'commerce';
  changePurchaseItems: CommerceChangePurchasesItem[];
  memo?: Partial<AdminCorrectMemo>;
  paidAt: Date;
}
