import { ChatMessage, ChatRoom } from '@models/chat';
import { PagenatedResponse } from '@models/common';
import { apiClient } from '.';

export const getChatMessages = async (chatRoomId: string, page: number = 0, size: number = 20) => {
  return apiClient.get<PagenatedResponse<ChatMessage>>(`/app/v1/chats/${chatRoomId}/messages`, {
    params: { page, size },
  });
};

export const getChatRoom = async (chatRoomId: string) => {
  return apiClient.get<ChatRoom>(`/app/v1/chats/${chatRoomId}`);
};
