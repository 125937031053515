import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import LinkPreviewComponent from './LinkPreviewComponent';

export const LinkPreview = Node.create({
  name: 'link-preview',
  group: 'block',
  content: 'inline*',
  parseHTML() {
    return [
      {
        tag: 'div[link-preview]',
      },
    ];
  },
  addAttributes() {
    return {
      imageUrl: {
        default: null,
        parseHTML(element) {
          return element.getAttribute('image-url');
        },
      },
      ogUrl: {
        default: null,
        parseHTML(element) {
          return element.getAttribute('og-url');
        },
      },
      title: {
        default: null,
        parseHTML(element) {
          return element.getAttribute('title');
        },
      },
      description: {
        default: null,
        parseHTML(element) {
          return element.getAttribute('description');
        },
      },
      link: {
        default: null,
        parseHTML(element) {
          return element.getAttribute('link');
        },
      },
    };
  },
  renderHTML({ node, HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(HTMLAttributes, {
        'link-preview': 'link-preview',
        'image-url': node.attrs.imageUrl,
        title: node.attrs.title,
        description: node.attrs.description,
        link: node.attrs.link,
        'og-url': node.attrs.ogUrl,
      }),
      0,
    ];
  },
  addNodeView() {
    return ReactNodeViewRenderer(LinkPreviewComponent);
  },
});

export default LinkPreview;
