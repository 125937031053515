import { PagenatedResponse, SearchPagenateQuery } from '@models/common';
import { Event } from '@models/event';
import { create } from 'zustand';

const PAGE_SIZE = 20;

interface EventsContainer {
  contents: Event[];
  page: number;
  size: number;
  total: number;
  hasNext: boolean;
}

interface EventsStoreProps {
  searchResultContainer: EventsContainer;
  searchQuery: SearchPagenateQuery;
  searchKeyword?: string;
  setSearchKeyword: (keyword: string) => void;
  updateSearchQuery: (query: { [key: string]: any }) => void;
  resetSearchQuery: () => void;
  setEventsContainer: (result: PagenatedResponse<Event>) => void;
}

export const useEventsStore = create<EventsStoreProps>((set) => ({
  searchResultContainer: {
    contents: [] as Event[],
    total: 0,
    page: 0,
    size: 0,
    hasNext: false,
  },
  searchQuery: {
    page: 0,
    size: PAGE_SIZE,
  },
  searchKeyword: undefined,
  setEventsContainer: (result) => {
    set((state) => {
      const contents = new Array(result.total || PAGE_SIZE);
      contents.splice(result.page * result.size, result.size, ...(result?.contents || []));
      const newValue = { ...state.searchResultContainer, ...result, contents: contents };
      return { ...state, searchResultContainer: newValue };
    });
  },
  setSearchKeyword: (keyword: string) => {
    set((state) => {
      return {
        ...state,
        searchKeyword: keyword,
        searchResultContainer: { ...state.searchResultContainer, size: 0 },
      };
    });
  },
  updateSearchQuery: (query: { [key: string]: any }) => {
    set((state) => {
      return {
        ...state,
        searchQuery: { ...state.searchQuery, ...query },
        searchResultContainer: { ...state.searchResultContainer, size: 0 },
      };
    });
  },
  resetSearchQuery: () => {
    set({
      searchQuery: {
        page: 0,
        size: PAGE_SIZE,
        type: 'all',
      },
    });
  },
}));
