import FileUploadButton from '@components/common/FileUploadButton';
import { closestCenter, DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FeedData, FeedDataItem } from '@models/feed';
import { cdnImageUrl, resizeImage } from '@utils/image';
import { extractFileName } from '@utils/string';
import { Button, Input } from 'antd';
import { RcFile } from 'antd/es/upload';
import React, { useEffect, useState } from 'react';
import DeeplinkActionEdit from './DeepLinkActionEdit';

interface SortableCategoryItemProps {
  onDeleteItem?: (event: any) => void;
  onChange?: (item: FeedDataItem) => void;
  onChangeImage?: (files: FileList) => void;
  id: any;
  item: FeedDataItem;
}

export const SortableCategoryItem: React.FC<SortableCategoryItemProps> = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const { onDeleteItem, onChange, item } = props;

  return (
    <div ref={setNodeRef} style={{ ...style }} {...attributes} {...listeners}>
      <div style={{ display: 'flex', marginBottom: '8px' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100px',
            marginRight: '8px',
            alignItems: 'center',
          }}>
          <button className="link-button" style={{ alignItems: 'center', width: '64px' }}>
            <img
              src={cdnImageUrl(item.imageUrlTemp || item.imageUrl)}
              style={{ width: '48px', height: '48px', borderRadius: 0 }}
            />
            <div
              style={{
                alignItems: 'center',
                marginLeft: -1,
                height: '15px',
                justifyContent: 'flex-start',
              }}>
              <div style={{ fontSize: '12px', color: '#424242' }}>{item.categoryTitle}</div>
            </div>
          </button>
          <FileUploadButton
            accept={['jpg', 'jpeg', 'png', 'tif', 'tiff', 'webp', 'svg']
              .map((ext) => {
                return '.' + ext;
              })
              .join(',')}
            className="link-button"
            style={{
              fontSize: '14px',
              height: '32px',
              padding: '4px 12px',
              borderRadius: '6px',
              backgroundColor: '#ffffff',
              border: '1px solid #d9d9d9',
              color: 'rgba(0, 0, 0, 0.88)',
              boxShadow: '0 2px 0 rgba(0, 0, 0, 0.02)',
              cursor: 'pointer',
            }}
            onChange={(e) => {
              if (e.target.files) {
                const files = e.target.files;
                props.onChangeImage && props.onChangeImage(files);
              }
            }}>
            아이콘 변경
          </FileUploadButton>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', marginRight: '8px' }}>
          <Input
            value={item.categoryTitle}
            onChange={(e) => {
              onChange && onChange({ ...item, categoryTitle: e.target.value });
            }}></Input>
          <div style={{ border: '1px solid #eaeaea', borderRadius: '8px', padding: '8px' }}>
            <div>클릭시 이동 설정</div>
            <DeeplinkActionEdit
              data={item}
              onChange={(value) => {
                onChange && onChange({ ...item, ...value });
              }}></DeeplinkActionEdit>
          </div>
        </div>
        <div>
          <Button
            onClick={(e) => {
              onDeleteItem && onDeleteItem(e);
            }}>
            삭제
          </Button>
        </div>
      </div>
    </div>
  );
};

export const CategoryEdit: React.FC<{
  clear?: boolean;
  data: Partial<FeedData>;
  onChange?: (data: Partial<FeedData>) => void;
  onChangeImageFile?: (files: { [key: string]: File }) => void;
}> = (props) => {
  const [changedContents, setChangedContents] = useState<FeedDataItem[]>();
  const [imageFiles, setImageFiles] = useState<{ [key: string]: File }>({});
  const { data } = props;
  const contents = changedContents || data.data || [];

  const sortableSensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 10 },
    }),
  );

  useEffect(() => {
    if (props.clear) {
      setChangedContents(undefined);
      setImageFiles({});
    }
  }, [props.clear]);

  useEffect(() => {
    props.onChangeImageFile && props.onChangeImageFile(imageFiles);
  }, [imageFiles]);

  useEffect(() => {
    props.onChange &&
      props.onChange({
        ...data,
        data: changedContents || data.data,
      });
  }, [changedContents]);

  const onChangeImage = async (files: FileList, index: number) => {
    const { fileName } = extractFileName(files[0].name);
    const newFiles = await Promise.all(
      Array.from(files)
        .slice(0, 10)
        .map(async (file) => {
          const result = await resizeImage(file, { maxWidth: 96, maxHeight: 96 });

          return result;
        }),
    );
    const contentTypes = newFiles[0].type.split('/');
    const uploadFileName = `${fileName}.${contentTypes[1]}`;
    const newImageFile = new File([newFiles[0].image], `${fileName}.${contentTypes[1]}`) as RcFile;
    const tempUrl = URL.createObjectURL(newFiles[0].image);
    setChangedContents((prev) => {
      const newValues = [...(prev || contents)];
      newValues[index] = { ...newValues[index], imageUrl: uploadFileName, imageUrlTemp: tempUrl };
      return newValues;
    });

    setImageFiles((value) => {
      const newValue = { ...value };
      newValue[`${data.type}.${index}`] = newImageFile;
      return newValue;
    });
  };

  const onSortEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      if (contents) {
        const oldIndex = contents.findIndex((item) => item.mainCopy === active.id);
        const newIndex = contents.findIndex((item) => item.mainCopy === over?.id);
        const newData = arrayMove(contents, oldIndex, newIndex);
        setChangedContents(newData);
      }
    }
  };

  return (
    <div>
      <div style={{ display: 'flex', height: '44px', justifyContent: 'space-between' }}>
        <Button
          onClick={() => {
            setChangedContents((prev) => {
              const newValues = [{}, ...(prev || contents)];
              return newValues;
            });
          }}>
          빈 항목 추가
        </Button>
      </div>
      <DndContext sensors={sortableSensors} collisionDetection={closestCenter} onDragEnd={onSortEnd}>
        <SortableContext
          items={contents.map((item) => {
            return { ...item, id: item.mainCopy || '' };
          })}
          strategy={verticalListSortingStrategy}>
          <div style={{ maxWidth: '600px' }}>
            {contents.map((item, index) => {
              return (
                <SortableCategoryItem
                  item={item}
                  id={item.mainCopy}
                  onDeleteItem={() => {
                    setChangedContents((prev) => {
                      const newValues = [...(prev || contents)];
                      newValues.splice(index, 1);
                      return newValues;
                    });
                  }}
                  onChangeImage={(files: FileList) => {
                    onChangeImage(files, index);
                  }}
                  onChange={(item) => {
                    setChangedContents((prev) => {
                      const newValues = [...(prev || contents)];
                      newValues[index] = item;
                      return newValues;
                    });
                  }}></SortableCategoryItem>
              );
            })}
          </div>
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default CategoryEdit;
