import BadgeBlueImage from '@assets/images/app/bg-badge-blue.png';
import BadgePurpleImage from '@assets/images/app/bg-badge-purple.png';
import BadgeRedImage from '@assets/images/app/bg-badge-red.png';
import SearchIcon from '@assets/images/app/icon-search-home.png';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { cdnImageUrl } from '@utils/image';
import { actionStringFromItem } from '@utils/string';
import React, { useRef } from 'react';
import { Virtuoso } from 'react-virtuoso';

const HEADER_HEIGHT = '56px';

export const MdPickView: React.FC<{ data: any[] }> = (props) => {
  const virtuoso = useRef<any>();
  const feedData = props.data || [];
  const dialog = useOkCancelDialog();
  return (
    <div>
      <div>
        <div
          style={{
            display: 'flex',
            height: HEADER_HEIGHT,
            justifyContent: 'space-between',
            width: '100%',
          }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: '56px',
              height: HEADER_HEIGHT,
            }}>
            <div></div>
          </div>
          <div style={{ alignSelf: 'center', fontSize: '17px', fontWeight: 'bold', color: '#242424' }}>PICK</div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: HEADER_HEIGHT,
              minWidth: '56px',
            }}>
            <div>
              <button className="link-button">
                <div
                  style={{
                    display: 'flex',
                    width: '24px',
                    height: HEADER_HEIGHT,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <img style={{ height: '24px', width: '24px' }} src={SearchIcon} />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Virtuoso
          ref={virtuoso}
          style={{ height: 'calc(100dvh - 100px)', overflowX: 'hidden' }}
          totalCount={feedData.length}
          itemContent={(index) => {
            const item = feedData[index];

            if (item.type === 'banner') {
              return (
                <img key={`curation-${index}`} style={{ width: '100%', marginBottom: '24px' }} src={item.imageUrl} />
              );
            } else if (item.type === 'brand') {
              return (
                <div key={`curation-${index}`} style={{ paddingTop: '12px', paddingBottom: '12px' }}>
                  <div
                    style={{
                      flex: 1,
                      borderRadius: '12px',
                      marginLeft: '24px',
                      marginRight: '24px',
                      backgroundColor: 'white',
                      border: '1px solid #e8eaed',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      dialog.open({
                        type: 'ok',
                        confirmColor: '#FF3D8F',
                        title: '이동 정보',
                        content: actionStringFromItem(item),
                      });
                    }}>
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        aspectRatio: 1.48,
                        position: 'relative',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px',
                        backgroundImage: `url(${cdnImageUrl(item.brandImageUrlTemp || item.brandImageUrl)})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                      }}>
                      {item?.brandTag && (
                        <div style={{ position: 'absolute', bottom: '16px', left: '16px' }}>
                          <div
                            style={{
                              display: 'flex',
                              backgroundColor: 'rgba(255,255,255,.9)',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '32px',
                              borderRadius: '4px',
                              padding: '0 12px',
                            }}>
                            <div style={{ color: '#242424', fontSize: '14px', fontWeight: 'bold' }}>
                              {item.brandTag}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        padding: '20px',
                        position: 'relative',
                      }}>
                      <div style={{ position: 'absolute', top: '-22px', right: 0 }}>
                        {item.badgeStatus == 'onlyBadge' && (
                          <div style={{ position: 'relative', alignItems: 'center', justifyContent: 'center' }}>
                            <img
                              src={BadgeBlueImage}
                              style={{
                                display: 'flex',
                                width: '80px',
                                height: '70px',
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            />
                          </div>
                        )}
                        {item.badgeStatus == 'lightningBadge' && (
                          <div style={{ position: 'relative', alignItems: 'center', justifyContent: 'center' }}>
                            <img
                              src={BadgePurpleImage}
                              style={{
                                width: '80px',
                                height: '70px',
                                position: 'absolute',
                                top: 0,
                                right: 0,
                              }}
                            />
                          </div>
                        )}
                        {item.badgeStatus == 'saleBadge' && (
                          <div style={{ position: 'relative', alignItems: 'center', justifyContent: 'center' }}>
                            <img
                              src={BadgeRedImage}
                              style={{
                                width: '80px',
                                height: '70px',
                                position: 'absolute',
                                top: 0,
                                right: 0,
                              }}
                            />
                          </div>
                        )}
                        {(item.badgeStatus == 'onlyBadge' ||
                          item.badgeStatus == 'lightningBadge' ||
                          item.badgeStatus == 'saleBadge') && (
                          <div
                            style={{
                              display: 'flex',
                              width: '80px',
                              height: '70px',
                              position: 'absolute',
                              top: 0,
                              right: '2px',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}>
                            <div style={{ color: 'white', fontSize: '12px', fontWeight: 'bold' }}>{item.badgeText}</div>
                          </div>
                        )}
                      </div>
                      <div style={{ marginBottom: 0 }}>
                        <div
                          style={{
                            display: 'flex',
                            color: '#424242',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            lineHeight: '17px',
                          }}>
                          {item.tags &&
                            item.tags.map((tag: string, index2: number) => {
                              return (
                                <div key={`curation-tag-${index}-${index2}`} style={{ marginRight: '6px' }}>
                                  #{tag}&nbsp;
                                </div>
                              );
                            })}
                        </div>
                      </div>
                      <div style={{ marginTop: '8px', marginBottom: '10px' }}>
                        <div style={{ flexDirection: 'row', alignItems: 'center' }}>
                          <div style={{ fontWeight: 'bold', fontSize: '26px', lineHeight: '34px', color: '#2e2e2e' }}>
                            {item.brandTitle && item.brandTitle.split('\n')[0]}
                          </div>
                        </div>
                        <div style={{ flexDirection: 'row', alignItems: 'center' }}>
                          <div style={{ fontWeight: 'bold', fontSize: '26px', lineHeight: '34px', color: '#2e2e2e' }}>
                            {item.brandTitle && item.brandTitle.split('\n')[1]}
                          </div>
                        </div>
                      </div>
                      <div style={{ width: '100%' }}>
                        <div style={{ fontSize: '14px', color: '#808387', lineHeight: '21px' }}>{item.brandDesc}</div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
            return <div key={`curation-${index}`}>{item.type}</div>;
          }}
        />
      </div>
    </div>
  );
};

export default MdPickView;
