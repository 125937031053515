import * as api from '@apis/statistics';
import { useAuthentication } from '@hooks/authentication';
import { PagenatedResponse } from '@models/common';
import { PurchasesReport } from '@models/statistics';
import { useQuery } from '@tanstack/react-query';
import { Button, DatePicker, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const PAGE_SIZE = 20;

export const PurchasesReportPage: React.FC = () => {
  const [expandAll, setExpandAll] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState({
    page: 0,
    size: PAGE_SIZE,
    startDate: moment().subtract(7, 'days').toDate(),
    endDate: moment().endOf('days').toDate(),
    sort: 'totalAmount:descend',
  });
  const [purchasesContainer, setPurchasesContainer] = useState<PagenatedResponse<PurchasesReport>>({
    page: -1,
    total: 0,
    size: PAGE_SIZE,
    hasNext: false,
    contents: [],
  });

  useAuthentication();

  const purchasesReportQuery = useQuery({
    queryKey: [`purchases-report`, searchQuery],
    queryFn: () => api.getPurchasesReport(searchQuery),
  });

  const purchasesReports = purchasesReportQuery.data?.data;

  useEffect(() => {
    if (purchasesReports) {
      setPurchasesContainer((prev) => {
        const contents = new Array(purchasesReports.total || PAGE_SIZE);
        contents.splice(
          purchasesReports.page * purchasesReports.size,
          purchasesReports.size,
          ...(purchasesReports?.contents || []),
        );
        return { ...prev, ...purchasesReports, contents: contents };
      });
    }
  }, [purchasesReports]);

  const TABLE_COLUMNS: ColumnsType<PurchasesReport> = [
    {
      fixed: 'left',
      width: 80,
      title: '순번',
      render: (value, record, index: number) => {
        return index + 1;
      },
    },
    {
      fixed: 'left',
      width: 100,
      title: '고객 ID',
      render: (record: PurchasesReport) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(record?._id.id || '');
                toast.dark('ID를 클립보드에 복사했습니다', {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  pauseOnFocusLoss: false,
                  draggable: true,
                  progress: undefined,
                  bodyStyle: {
                    color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                    textAlign: 'center',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                  },
                  theme: 'dark',
                });
              }}>
              ID
            </Button>
          </div>
        );
      },
    },
    {
      fixed: 'left',
      width: 80,
      title: '채널',
      render: (value, record) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            {(record.productTypes || []).map((productType) => {
              return (
                <div
                  style={{
                    borderRadius: '4px',
                    background:
                      productType === 'naver-store'
                        ? 'rgb(3, 199, 90)'
                        : productType === 'class'
                          ? 'rgb(24, 144, 255)'
                          : '#FF3D8F',
                    color: 'white',
                    fontFamily: 'Pretendard',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    padding: '2px 6px',
                    flexShrink: 0,
                    lineHeight: 'normal',
                    marginRight: '4px',
                  }}>
                  {productType === 'naver-store' && '네이버'}
                  {productType === 'commerce' && '커머스'}
                  {productType === 'class' && '클래스'}
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      title: '고객명',
      width: 124,
      render: (record: PurchasesReport) => {
        return (
          <div>
            <div>{`${
              record?._id.userName ? record?._id.userName + '(앱)' : record?.userName
            }(${record?._id.phoneNumber.substring(record?._id.phoneNumber.length - 4)})`}</div>
            {record._id.productType && (
              <span
                style={{
                  borderRadius: '4px',
                  background:
                    record._id.productType === 'naver-store'
                      ? 'rgb(3, 199, 90)'
                      : record._id.productType === 'class'
                        ? 'rgb(24, 144, 255)'
                        : '#FF3D8F',
                  color: 'white',
                  fontFamily: 'Pretendard',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  padding: '2px 6px',
                  flexShrink: 0,
                  lineHeight: 'normal',
                  marginRight: '4px',
                }}>
                {record._id.productType === 'naver-store' && '네이버'}
                {record._id.productType === 'commerce' && '커머스'}
                {record._id.productType === 'class' && '클래스'}
              </span>
            )}
          </div>
        );
      },
    },
    {
      title: '가입일',
      width: 114,
      render: (record: PurchasesReport) => {
        return record?._id.joinDate ? moment(record?._id.joinDate).format('YYYY-MM-DD') : '';
      },
    },
    {
      title: '최초결제일',
      key: 'firstPaidDate',
      width: 114,
      render: (record: PurchasesReport) => {
        return moment(record?.firstPaidDate).format('YYYY-MM-DD');
      },
      sorter: (a, b) => a.appCount - b.appCount,
    },
    {
      title: '최근결제일',
      key: 'lastPaidDate',
      width: 114,
      render: (record: PurchasesReport) => {
        return moment(record?.lastPaidDate).format('YYYY-MM-DD');
      },
      sorter: (a, b) => a.appCount - b.appCount,
    },
    {
      title: '결제건수',
      key: 'totalCount',
      width: 114,
      render: (record: PurchasesReport) => {
        return (record?.appCount + record?.naverCount).toLocaleString();
      },
      sorter: (a, b) => a.totalCount - b.totalCount,
    },
    {
      width: 124,
      key: 'totalAmount',
      title: '총지출액',
      render: (record: PurchasesReport) => {
        return (record?.appAmount + record?.naverAmount).toLocaleString();
      },
      sorter: (a, b) => a.totalAmount - b.totalAmount,
    },
    {
      width: 124,
      title: '건당 평균결제액',
      render: (record: PurchasesReport) => {
        return Math.floor((record?.appAmount + record?.naverAmount) / record.totalCount).toLocaleString();
      },
    },
    {
      title: '환불건수',
      width: 124,
      key: 'totalRefundCount',
      render: (record: PurchasesReport) => {
        return (record?.appRefundCount + record?.naverRefundCount).toLocaleString();
      },
      sorter: (a, b) => a.totalRefundCount - b.totalRefundCount,
    },
    {
      title: '총환불액',
      width: 124,
      key: 'totalRefund',
      render: (record: PurchasesReport) => {
        return (record?.appRefund + record?.naverRefund).toLocaleString();
      },
      sorter: (a, b) => a.totalRefund - b.totalRefund,
    },
    {
      width: 124,
      title: '건당 최종결제액',
      render: (record: PurchasesReport) => {
        return Math.floor(
          (record?.appAmount + record?.naverAmount - record?.appRefund - record?.naverRefund) / record.totalCount,
        ).toLocaleString();
      },
    },
  ];

  return (
    <div className="dashboard" style={{ position: 'relative' }}>
      <div className="page-header">
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <div>유저 구매 보고서</div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <div>
            <DatePicker
              onChange={(value) => {
                setSearchQuery((prev) => {
                  return { ...prev, startDate: moment(value?.toDate()).startOf('day') } as any;
                });
              }}
              value={dayjs(searchQuery.startDate)}
            />
          </div>
          <div style={{ marginLeft: '8px' }}>
            <DatePicker
              onChange={(value) => {
                setSearchQuery((prev) => {
                  return { ...prev, endDate: moment(value?.toDate()).startOf('day') } as any;
                });
              }}
              value={dayjs(searchQuery.endDate)}
            />
          </div>
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <div className="page-content" style={{ width: '100%', height: 'calc(100vh - 80px)', overflow: 'auto' }}>
          <div
            style={{
              display: 'flex',
              gap: '12px',
              width: '100%',
              height: '52px',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}>
            <Button
              onClick={() => {
                setExpandAll((value) => !value);
              }}>
              {expandAll ? '전체 접기' : '전체 펼치기'}
            </Button>
            <Button
              onClick={async () => {
                const fileName = `user-purchase-report-${moment(searchQuery.startDate).format('YYYYMMDD')}_-${moment(
                  searchQuery.endDate,
                ).format('YYYYMMDD')}.xlsx`;
                const blob = await api.getPurchasesReportExcelFile(searchQuery);
                if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
                  // IE variant
                  (window.navigator as any).msSaveOrOpenBlob(new Blob(blob.data), fileName);
                } else {
                  const url = window.URL.createObjectURL(blob.data);
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', fileName);
                  document.body.appendChild(link);
                  link.click();
                  link.parentNode && link.parentNode.removeChild(link);
                }
              }}>
              엑셀파일 다운로드
            </Button>
            <Button
              onClick={async () => {
                const fileName = `user-purchase-report-${moment(searchQuery.startDate).format('YYYYMMDD')}_-${moment(
                  searchQuery.endDate,
                ).format('YYYYMMDD')}_detail.xlsx`;
                const blob = await api.getPurchasesReportExcelFile({ ...searchQuery, detail: true });
                if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
                  // IE variant
                  (window.navigator as any).msSaveOrOpenBlob(new Blob(blob.data), fileName);
                } else {
                  const url = window.URL.createObjectURL(blob.data);
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', fileName);
                  document.body.appendChild(link);
                  link.click();
                  link.parentNode && link.parentNode.removeChild(link);
                }
              }}>
              엑셀파일 다운로드(상세)
            </Button>
          </div>
          <Table
            key={`table-${expandAll}`}
            expandable={{ defaultExpandAllRows: expandAll }}
            columns={TABLE_COLUMNS}
            pagination={{
              current: (purchasesContainer.page || 0) + 1,
              pageSize: purchasesContainer.size || 20,
              total: purchasesContainer.total,
            }}
            scroll={{ x: 'max-content', y: 'calc(100dvh - 320px)' }}
            style={{ whiteSpace: 'pre-wrap' }}
            rowKey={(record) => `${record?._id.phoneNumber}_${record?._id.productType}`}
            dataSource={purchasesReports?.contents}
            loading={purchasesReportQuery.isLoading}
            sortDirections={['ascend', 'descend']}
            onChange={(pagenation, filters, sorter: any) => {
              setSearchQuery((prev) => {
                return {
                  ...prev,
                  page: (pagenation.current || 2) - 1,
                  size: pagenation.pageSize || 20,
                  sort: `${sorter.columnKey}:${sorter.order}`,
                };
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PurchasesReportPage;
