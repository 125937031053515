import * as productApi from '@apis/product';
import { ProductModel } from '@models/product';
import { useProductsStore } from '@stores/proudctsStore';
import { useQuery } from '@tanstack/react-query';
import { getAddressText } from '@utils/dataTransform';
import { cdnImageUrl } from '@utils/image';
import { Button, Checkbox, Input, Modal, Popover, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

interface SelectProductModalProps {
  open?: boolean;
  onAddProducts: (products: ProductModel[]) => void;
  onClose?: () => void;
  selectLimit?: number;
  sellerId?: string;
}

export const SelectProductModal: React.FC<SelectProductModalProps> = (props) => {
  const [selectedProducts, setSelectedProducts] = useState<ProductModel[]>([]);
  const [searchKeyword, setSearchKeyword] = useState<string>();
  const store = useProductsStore();

  const productSearchQuery = useQuery({
    queryKey: [
      `admin-search-products`,
      JSON.stringify({ ...store.searchQuery, status: 'open', displayStatus: 'open' }),
    ],
    queryFn: () =>
      productApi.searchProducts({
        ...store.searchQuery,
        status: 'open',
        displayStatus: 'open',
        sellerId: props?.sellerId,
      }),
  });

  useEffect(() => {
    const searchResult = productSearchQuery.data?.data;
    if (searchResult) {
      store.setProductsContainer(searchResult);
    }
  }, [productSearchQuery.data?.data]);

  const TABLE_COLUMNS: ColumnsType<ProductModel> = [
    {
      fixed: 'left',
      title: () => {
        return (
          <Checkbox
            disabled={!!props.selectLimit}
            checked={
              JSON.stringify(selectedProducts.sort()) ===
              JSON.stringify(
                (store.searchResultContainer.contents || [])
                  .filter((order) => !!order)
                  .map((order) => order._id)
                  .sort(),
              )
            }
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedProducts(store.searchResultContainer.contents);
              } else {
                setSelectedProducts([]);
              }
            }}></Checkbox>
        );
      },
      width: 60,
      render: (record) => {
        return (
          <Checkbox
            disabled={
              !!props.selectLimit &&
              selectedProducts.length === props.selectLimit &&
              selectedProducts[0]?._id !== record?._id
            }
            onChange={(e) => {
              setSelectedProducts((values) => {
                const checked = e.target.checked;
                const newValues = [...values];
                if (checked) {
                  if (!newValues.find((item) => item._id === record._id)) {
                    newValues.push(record);
                  }
                  return newValues;
                } else {
                  return newValues.filter((value) => value._id !== record._id);
                }
              });
            }}
            checked={!!selectedProducts.find((item) => item?._id === record?._id)}></Checkbox>
        );
      },
    },
    {
      title: '썸네일',
      dataIndex: 'thumbnailImage',
      render: (thumbnailImage) => (
        <img
          src={cdnImageUrl(thumbnailImage)}
          style={{ width: '40px', height: '40px', objectFit: 'cover', borderRadius: '4px' }}
        />
      ),
    },
    {
      title: '판매방식',
      width: 120,
      dataIndex: 'saleType',
      render: (saleType) => `${saleType}`,
    },
    {
      title: '구분',
      dataIndex: 'activityType',
      render: (activityType) => {
        if (!activityType) {
          return '-';
        }
        return `${activityType}`;
      },
    },
    {
      title: '지역',
      dataIndex: 'location',
      render: (location) => {
        let content = '';
        if (location?.specificAddress) {
          content = `${location?.specificAddress?.address} ${location?.specificAddress?.address2}`;
        }
        return (
          <Popover content={content}>
            <div style={{ color: content ? 'green' : 'black' }}>{getAddressText({ availableArea: location })}</div>
          </Popover>
        );
      },
    },
    {
      title: '판매자',
      dataIndex: 'sellerName',
      render: (sellerName) => {
        return `${sellerName}`;
      },
    },
    {
      title: '상픔명',
      width: 240,
      dataIndex: 'title',
      render: (title) => `${title}`,
    },
    {
      title: '금액',
      dataIndex: 'fee',
      render: (fee) => `${fee?.toLocaleString()}원`,
    },
    {
      title: '생성/수정',
      render: (record) => {
        return (
          <div>
            <div>{moment(record.createdAt).format('YYYY.MM.DD HH:mm')}</div>
            <div style={{ fontWeight: 'bold' }}>{moment(record.updatedAt).format('YYYY.MM.DD HH:mm')}</div>
          </div>
        );
      },
    },
    {
      title: '선택',
      dataIndex: '_id',
      render: (_id, record) => {
        return (
          <Button
            key={_id}
            onClick={() => {
              props.onAddProducts && props.onAddProducts([record]);
            }}>
            선택
          </Button>
        );
      },
    },
  ];

  return (
    <Modal
      open={props.open}
      footer={false}
      onCancel={props.onClose}
      onOk={props.onClose}
      width={1280}
      zIndex={1500}
      styles={{ body: { height: '600px', overflow: 'auto' } }}>
      <div style={{ marginBottom: '12px', display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', gap: '8px' }}>
          <Input
            style={{ width: '240px' }}
            value={searchKeyword}
            placeholder={'검색어 입력 후 엔터'}
            onChange={(e) => {
              setSearchKeyword(e.target.value);
            }}
            onKeyUp={(e) => {
              if (e.code === 'Enter' && searchKeyword) {
                store.updateSearchQuery({ keyword: searchKeyword });
                setSelectedProducts([]);
              }
            }}></Input>
          <Button
            onClick={() => {
              setSearchKeyword(undefined);
              store.resetSearchQuery();
              setSelectedProducts([]);
            }}>
            검색 초기화
          </Button>
        </div>

        <div style={{ marginRight: '20px' }}>
          {(selectedProducts.filter((item) => item._id) || []).length > 0 && (
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
              <div>{`${(selectedProducts.filter((item) => item._id) || []).length}개 선택 항목을`} </div>
              <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                <Button
                  onClick={() => {
                    props.onAddProducts && props.onAddProducts(selectedProducts.filter((item) => item._id));
                    setSelectedProducts([]);
                    props.onClose && props.onClose();
                  }}>
                  추가하기
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>

      <Table
        scroll={{ x: 'max-content', y: 'calc(100dvh - 500px)' }}
        columns={TABLE_COLUMNS}
        rowKey={(record) => record?._id}
        dataSource={store.searchResultContainer.contents.filter((product) => product?._id)}
        pagination={{
          current: (store.searchResultContainer.page || 0) + 1,
          pageSize: store.searchResultContainer.size || 20,
          total: store.searchResultContainer.total,
        }}
        loading={productSearchQuery.isLoading}
        onChange={(e) => {
          store.updateSearchQuery({ page: (e.current || 2) - 1, size: e.pageSize || 20 });
        }}
      />
    </Modal>
  );
};

export default SelectProductModal;
