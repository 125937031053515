import React, { useEffect, useState } from 'react';
import { NumberEdit } from '../common/NumberEdit';
import { StudentInfo } from '@models/invoice';
import CellTitle from './CellTitle';
import { ageTextFromBirthday } from '@utils/string';
import CheckBox from '@components/common/CheckBox';
import { ChildInfo } from '@models/chat';

export interface StudentEditProps {
  allChildren: ChildInfo[];
  selected?: ChildInfo[];
  student?: StudentInfo;
  onChange?: (student: StudentInfo) => void;
}

export const StudentEdit: React.FC<StudentEditProps> = (props) => {
  const [selectedChildren, setSelectedChildren] = useState<ChildInfo[]>([]);
  const [otherChildrenCount, setOtherChildrenCount] = useState<number>(0);

  const children = props.allChildren;

  useEffect(() => {
    const newStudent = {
      children: selectedChildren,
      additionalParticipantCount: otherChildrenCount,
      total: selectedChildren.length + otherChildrenCount,
    };
    if (JSON.stringify(props.student) !== JSON.stringify(newStudent)) {
      props.onChange && props.onChange(newStudent);
    }
  }, [selectedChildren, otherChildrenCount]);

  useEffect(() => {
    if (props.student) {
      setSelectedChildren(props.student.children);
      setOtherChildrenCount(props.student.additionalParticipantCount);
    }
  }, [props.student]);

  return (
    <div style={{ padding: '28px 20px' }}>
      <CellTitle>수강생</CellTitle>
      <div style={{ marginTop: '16px', padding: '16px 20px', borderRadius: '12px', border: '1px solid #E8EAED' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingBottom: '12px',
            borderBottom: '1px solid #EFF1F4',
            height: '44px',
            marginTop: '12px',
          }}>
          <div
            style={{
              color: '#424242',
              fontFamily: 'Pretendard',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '22px',
            }}>
            등록된 아이
          </div>
          <div>
            <CheckBox
              checked={selectedChildren && selectedChildren.length === children.length}
              onChecked={(value) => {
                if (value) {
                  setSelectedChildren(children);
                } else {
                  setSelectedChildren([]);
                }
              }}>
              전체 선택
            </CheckBox>
          </div>
        </div>
        <div style={{ marginTop: '12px' }}>
          {children.map((child, index) => {
            const birthday = child.birthday || '';
            const gender = child.gender === 'f' ? '여' : '남';
            const age = ageTextFromBirthday(birthday !== '' ? birthday.replace(/-/g, '') : '');
            let subText = birthday !== '' ? birthday.replace(/-/g, '') : '';
            if (subText.length === 8) {
              subText = `${gender} / ${age}`;
            }

            const checked =
              (selectedChildren || []).findIndex(
                (value) => new ChildInfo(value).toString() === new ChildInfo(child).toString(),
              ) > -1;
            return (
              <div
                key={`child-${index}`}
                style={{ display: 'flex', alignItems: 'center', height: '24px', marginBottom: '12px' }}>
                <CheckBox
                  checked={checked}
                  onChecked={(value) => {
                    if (!value) {
                      setSelectedChildren((value) => {
                        const newValue = (value || []).filter((selectedChild) => {
                          return new ChildInfo(selectedChild).toString() !== new ChildInfo(child).toString();
                        });
                        return newValue;
                      });
                    } else {
                      setSelectedChildren((value) => {
                        const newValue = [...(value || [])];
                        if (
                          !newValue.find(
                            (selectedChild) =>
                              new ChildInfo(selectedChild).toString() === new ChildInfo(child).toString(),
                          )
                        ) {
                          newValue.push(child);
                        }
                        return newValue;
                      });
                    }
                  }}>
                  <div style={{ display: 'flex' }}>
                    <div
                      style={{
                        color: '#424242',
                        fontFamily: 'Pretendard',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        lineHeight: '22px',
                      }}>
                      {child.name}
                    </div>
                    <div
                      style={{
                        marginLeft: '4px',
                        color: '#808387',
                        fontFamily: 'Pretendard',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '20px',
                      }}>
                      {subText}
                    </div>
                  </div>
                </CheckBox>
              </div>
            );
          })}
          {children.length === 0 && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '40px',
                color: '#9B9DA0',
                textAlign: 'center',
                fontFamily: 'Pretendard',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '20px',
              }}>
              부모님께서 등록한 아이 정보가 없어요
            </div>
          )}
        </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '16px' }}>
        <div
          style={{
            color: '#424242',
            fontFamily: 'Pretendard',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '22px',
          }}>
          수강생 추가
        </div>
        <NumberEdit
          value={otherChildrenCount}
          max={10}
          min={0}
          onChangeValue={(value) => {
            setOtherChildrenCount(value);
          }}
          onClickMinus={(value) => {
            setOtherChildrenCount(value);
          }}
          onClickPlus={(value) => {
            setOtherChildrenCount(value);
          }}></NumberEdit>
      </div>
      <div style={{ padding: '16px', backgroundColor: '#F8F9FC', borderTop: '1px solid #EFF1F4', marginTop: '16px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div
            style={{
              color: 'var(--Neutral-90, #424242)',
              fontFamily: 'Pretendard',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '22px',
            }}>
            총 수강 인원
          </div>
          <div
            style={{
              color: 'var(--Primary-80, #FF64A5)',
              fontFamily: 'Pretendard',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '22px',
            }}>
            {`${(selectedChildren || []).length + otherChildrenCount}명`}
          </div>
        </div>
        <div
          style={{
            color: 'var(--Neutral-80, #66686B)',
            fontFamily: 'Pretendard',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '20px',
          }}>
          {otherChildrenCount > 0
            ? `${(selectedChildren || []).map((val) => val.name).join(', ')} 외 ${otherChildrenCount}명`
            : `${(selectedChildren || []).map((val) => val.name).join(', ')}`}
        </div>
      </div>
    </div>
  );
};

export default StudentEdit;
