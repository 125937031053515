import { ClassContainer } from '@models/class';
import { ClassInvoice } from '@models/invoice';
import { PaymentStatement } from '@models/paymentStatement';
import { ClassReportItem } from '@models/report';
import { apiClient } from '.';

export const getPopularClasses = async () => {
  return apiClient.get<ClassContainer>(`/app/v1/classes/popular`);
};

export const searchClasses = async () => {
  return apiClient.get(`/app/v1/classes`);
};

// export const getClassRievews = async (classId: string) => {
//   return apiClient.get(`/app/v1/classes/${classId}/reviews`);
// };

// export const getClassProduct = async (classId: string) => {
//   return apiClient.get<ClassProduct>(`/app/v1/classes/${classId}`);
// };

export const getRecommendClasses = async (classId: string) => {
  return apiClient.get(`/app/v1/classes/${classId}/recommends`);
};

export const addMyFavorites = async (classId: string) => {
  return apiClient.patch(`/app/v1/classes/${classId}/favorite`);
};

export const removeMyFavorites = async (classId: string) => {
  return apiClient.delete(`/app/v1/classes/${classId}/favorite`);
};

export const getClassInvoice = async (classId: string, invoiceId: string) => {
  return apiClient.get<ClassInvoice>(`/app/v1/classes/${classId}/invoices/${invoiceId}`);
};

// export const getReview = async (classProductId: string, classInvoiceId: string) => {
//   return apiClient.get<ReviewItem>(`/app/v1/classes/${classProductId}/invoices/${classInvoiceId}/review`);
// };

// export const createReview = async (createReviewBody: CreateReviewBody) => {
//   const { classProductId, classInvoiceId } = createReviewBody;
//   return apiClient.post<ReviewItem>(
//     `/app/v1/classes/${classProductId}/invoices/${classInvoiceId}/review`,
//     createReviewBody,
//   );
// };

// export const updateReview = async (updateReviewBody: UpdateReviewBody) => {
//   const { classProductId, classInvoiceId } = updateReviewBody;
//   return apiClient.put<ReviewItem>(
//     `/app/v1/classes/${classProductId}/invoices/${classInvoiceId}/review`,
//     updateReviewBody,
//   );
// };

export const getClassReport = async (classProductId: string, classInvoiceId: string) => {
  return apiClient.get<ClassReportItem>(`/app/v1/classes/${classProductId}/invoices/${classInvoiceId}/report`);
};

export const getPaymentStatement = async (classId: string, invoiceId: string) => {
  return apiClient.get<PaymentStatement>(`/app/v1/classes/${classId}/invoices/${invoiceId}/payment-statement`);
};
