import { DeleteOutlined } from '@ant-design/icons';
import * as commonApi from '@apis/common';
import * as api from '@apis/product-collection';
import FileUploadButton from '@components/common/FileUploadButton';
import { ProductCollectionPreview } from '@components/product-collection/ProductCollectionPreview';
import SelectProductModal from '@components/product/SelectProductModal';
import { closestCenter, DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useAppSocket } from '@hooks/appSocket';
import { useAuthentication } from '@hooks/authentication';
import { ProductModel, ProductStatus } from '@models/product';
import { ProductCollection } from '@models/productCollection';
import { useContentLoaderStore } from '@stores/contentLoaderStore';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { useProductCollectionsStore } from '@stores/proudctCollectionsStore';
import { useMutation, useQuery } from '@tanstack/react-query';
import { clearEmptyKeysFromObject } from '@utils/dataTransform';
import { cdnImageUrl, resizeImage } from '@utils/image';
import { extractFileName } from '@utils/string';
import { COLLECTION_SHARE_BASE_URL } from '@variables';
import { Button, Checkbox, Input, Select, Switch, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { RcFile } from 'antd/es/upload';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { toast } from 'react-toastify';

interface SortableProductItemProps {
  onDeleteProductItem?: (event: any) => void;
  value?: any;
  key: any;
  id: any;
  product: ProductModel;
  checked?: boolean;
  onChecked?: (value: boolean) => void;
}

export const SortableProductItem: React.FC<SortableProductItemProps> = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const { onDeleteProductItem, product } = props;
  const onClickRemove = (event: any) => {
    event.preventDefault();
    onDeleteProductItem && onDeleteProductItem(event);
  };

  const exposed = product.display && (product.status === ProductStatus.OPEN || product.status === ProductStatus.CLOSE);

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          height: '48px',
          borderBottom: '1px solid #eaeaea',
          width: '760px',
        }}>
        <div>
          <Checkbox
            checked={props.checked}
            onChange={(e) => {
              props.onChecked && props.onChecked(e.target.checked);
            }}></Checkbox>
        </div>
        <div>
          <img
            src={cdnImageUrl(product.thumbnailImage)}
            style={{ width: '40px', height: '40px', objectFit: 'cover', borderRadius: '8px' }}></img>
        </div>
        <div
          style={{
            fontWeight: 'bold',
            color: '#aaaaaa',
            width: '160px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>
          {product.sellerName}
        </div>
        <div style={{ width: '320px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {product.title}
        </div>
        <div style={{ fontWeight: 'bold' }}>{`${product.fee.toLocaleString()}원`}</div>
        <div style={{ whiteSpace: 'pre-wrap' }}>{exposed ? '✅' : '❌'}</div>
        <Button style={{ marginLeft: '12px' }} onClick={onClickRemove}>
          <DeleteOutlined style={{ color: 'red' }} />
        </Button>
      </div>
    </div>
  );
};

interface ProductCollectionEditViewProps {
  productCollectionId: string;
  onClose?: () => void;
  needRefetch?: () => void;
}

interface EditProductCollection extends ProductCollection {
  coverImageUrlTemp?: string;
}

const ProductCollectionEditView: React.FC<ProductCollectionEditViewProps> = (props) => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [showProductAdd, setShowProductAdd] = useState<boolean>();
  const [files, setFiles] = useState<{ [key: string]: File }>({});
  const [modifiedProductCollection, setModifiedProductCollection] = useState<EditProductCollection>(
    {} as EditProductCollection,
  );
  const [changedData, setChangedData] = useState<any>({});
  const dialog = useOkCancelDialog();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 10 },
    }),
  );

  const productCollectionQuery = useQuery({
    queryKey: [`product-item-${props.productCollectionId}`],
    queryFn: () => api.getProductCollection(props.productCollectionId!),
    enabled: !!props.productCollectionId,
  });

  const mutaionUpdateProduct = useMutation({
    mutationFn: api.updateProductCollection,
    onSuccess: async (res) => {
      setModifiedProductCollection(res.data);
      props.onClose && props.onClose();
      props.needRefetch && props.needRefetch();
      dialog.open({
        content: '저장되었습니다.',
        type: 'ok',
      });
    },
  });

  const onChangeProperty = (key: string, value: any) => {
    setChangedData((prev: any) => {
      const newProperties = { ...prev };
      newProperties[key] = value;
      return newProperties;
    });
  };

  const onChangeCoverImage = async (files: FileList) => {
    const { fileName } = extractFileName(files[0].name);
    const newFiles = await Promise.all(
      Array.from(files)
        .slice(0, 10)
        .map(async (file) => {
          const result = await resizeImage(file, { maxWidth: 828 });

          return result;
        }),
    );
    const contentTypes = newFiles[0].type.split('/');
    const uploadFileName = `${fileName}.${contentTypes[1]}`;
    const newImageFile = new File([newFiles[0].image], `${fileName}.${contentTypes[1]}`) as RcFile;
    const tempUrl = URL.createObjectURL(newFiles[0].image);
    onChangeProperty('coverImageUrlTemp', tempUrl);
    onChangeProperty('coverImageUrl', uploadFileName);
    setFiles((value) => {
      const newValue = { ...value };
      newValue['coverImageUrl'] = newImageFile;
      return newValue;
    });
  };

  const onDeleteProductItem = (index: number) => {
    const newProducts = [...modifiedProductCollection.products];
    newProducts.splice(index, 1);
    onChangeProperty('products', newProducts);
  };

  const onProductSortEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const oldIndex = modifiedProductCollection.products.findIndex((item) => item._id === active.id);
      const newIndex = modifiedProductCollection.products.findIndex((item) => item._id === over?.id);
      const newProducts = arrayMove(modifiedProductCollection.products, oldIndex, newIndex);
      onChangeProperty('products', newProducts);
    }
  };

  useEffect(() => {
    if (productCollectionQuery.data?.data) {
      setModifiedProductCollection(productCollectionQuery.data?.data);
    }
  }, [productCollectionQuery.data?.data]);

  useEffect(() => {
    setModifiedProductCollection((value) => {
      return { ...value, ...changedData };
    });
  }, [changedData]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100dvh',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'white',
        zIndex: 900,
        borderLeft: '1px solid #eaeaea',
      }}>
      <SelectProductModal
        open={showProductAdd}
        onAddProducts={(products: ProductModel[]) => {
          const candidates: ProductModel[] = [];
          products.forEach((product) => {
            const found = (modifiedProductCollection.products || []).find((item) => item._id === product._id);
            if (!found) {
              candidates.push(product);
            }
          });

          const newProducts = [...candidates, ...modifiedProductCollection.products];
          onChangeProperty('products', newProducts);

          toast.info(`${candidates.length}개의 상품이 추가되었습니다.`, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            pauseOnFocusLoss: false,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        }}
        onClose={() => {
          setShowProductAdd(false);
        }}></SelectProductModal>
      <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ flex: 1 }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '56px',
              borderBottom: '1px solid #eff1f4',
              fontSize: '17px',
              fontWeight: 'bold',
              color: '#242424',
              paddingLeft: '24px',
            }}>{`${modifiedProductCollection.title || '이름 없음'} 기획전 편집`}</div>
          <div style={{ padding: '12px', height: 'calc(100% - 100px)', overflow: 'auto' }}>
            <div>
              <div style={{ fontSize: '16px', fontWeight: 'bold' }}>기획전 구분</div>
              <Select
                defaultValue="일반형"
                style={{ width: 120 }}
                onChange={(value) => {
                  onChangeProperty('type', value);
                }}
                options={[
                  { value: 'md_pick', label: 'MD PICK' },
                  { value: 'general', label: '일반형' },
                  { value: 'list', label: '리스트형' },
                  { value: 'grid', label: '그리드형' },
                  { value: 'thema', label: '테마' },
                  { value: 'combine', label: '혼합형' },
                ]}
              />
            </div>
            <div style={{ marginTop: '12px' }}>
              <div style={{ fontSize: '16px', fontWeight: 'bold' }}>기획전 제목</div>
              <Input
                style={{ maxWidth: '428px' }}
                value={changedData?.title || modifiedProductCollection.title}
                onChange={(e) => {
                  onChangeProperty('title', e.target.value.substring(0, 20));
                }}></Input>{' '}
              <span>{`(${(changedData?.title || modifiedProductCollection.title || '').length} / 20)`}</span>
            </div>
            <div style={{ marginTop: '12px' }}>
              <div style={{ fontSize: '16px', fontWeight: 'bold' }}>설명</div>
              <Input.TextArea
                style={{ maxWidth: '428px' }}
                value={changedData?.description || modifiedProductCollection.description}
                onChange={(e) => {
                  onChangeProperty('description', e.target.value);
                }}></Input.TextArea>
            </div>
            <div style={{ marginTop: '12px' }}>
              <div style={{ fontSize: '16px', fontWeight: 'bold' }}>커버이미지</div>
              <div
                style={{
                  width: '428px',
                  height: '200px',
                  border: '1px solid #eaeaea',
                  overflow: 'hidden',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                {modifiedProductCollection.coverImageUrlTemp || modifiedProductCollection.coverImageUrl ? (
                  <img
                    src={cdnImageUrl(
                      modifiedProductCollection.coverImageUrlTemp || modifiedProductCollection.coverImageUrl,
                    )}
                    style={{ width: '428px', height: '200px', objectFit: 'cover' }}
                  />
                ) : (
                  <div>{'NO IMAGE'}</div>
                )}
              </div>
              <FileUploadButton
                accept={['jpg', 'jpeg', 'png', 'tif', 'tiff', 'webp', 'svg']
                  .map((ext) => {
                    return '.' + ext;
                  })
                  .join(',')}
                className="link-button"
                style={{
                  display: 'flex',
                  alignSelf: 'center',
                  justifyContent: 'center',
                  height: '36px',
                  width: '428px',
                  border: '1px solid #aaaaaa',
                  borderRadius: '8px',
                  lineHeight: '36px',
                  marginTop: '4px',
                }}
                onChange={(e) => {
                  if (e.target.files) {
                    const files = e.target.files;
                    onChangeCoverImage(files);
                  }
                }}>
                커버이미지 바꾸기
              </FileUploadButton>
            </div>
            <div style={{ marginTop: '12px', width: '740px' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <Checkbox
                  checked={
                    JSON.stringify(selectedIds.sort()) ===
                    JSON.stringify(
                      (modifiedProductCollection.products || [])
                        .filter((product) => !!product)
                        .map((product) => product._id)
                        .sort(),
                    )
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedIds(
                        (modifiedProductCollection.products || [])
                          .filter((product) => !!product)
                          .map((product) => product._id),
                      );
                    } else {
                      setSelectedIds([]);
                    }
                  }}></Checkbox>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ fontSize: '16px', fontWeight: 'bold' }}>상품 목록</div>
                  <div style={{ fontSize: '13px', marginLeft: '5px' }}>(노출여부: ✅ / ❌)</div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span>무작위 노출: </span>
                  <Switch
                    checked={changedData?.shuffle || modifiedProductCollection.shuffle}
                    onChange={(checked) => {
                      onChangeProperty('shuffle', checked);
                    }}></Switch>
                </div>
                <Button
                  style={{ marginLeft: 'auto' }}
                  onClick={() => {
                    setShowProductAdd(true);
                  }}>
                  상품 추가
                </Button>
              </div>
              <div>
                {selectedIds.length > 0 && (
                  <div style={{ display: 'flex', gap: '8px', alignItems: 'center', marginBottom: '12px' }}>
                    <div>{`${selectedIds.length}개 선택 항목을`} </div>
                    <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                      <Button
                        style={{ color: 'red' }}
                        onClick={() => {
                          const newProducts = [...modifiedProductCollection.products];
                          onChangeProperty(
                            'products',
                            newProducts.filter((product) => !selectedIds.includes(product._id)),
                          );
                          setSelectedIds([]);
                        }}>
                        삭제
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={onProductSortEnd}>
                <SortableContext
                  items={(modifiedProductCollection.products || []).map((item) => item._id)}
                  strategy={verticalListSortingStrategy}>
                  {(modifiedProductCollection.products || []).map((item: ProductModel, index: number) => {
                    return (
                      <SortableProductItem
                        key={`image-${item}-${index}`}
                        id={item._id}
                        onDeleteProductItem={() => {
                          onDeleteProductItem(index);
                        }}
                        product={item}
                        onChecked={(checked) => {
                          setSelectedIds((values) => {
                            const newValues = [...values];
                            if (checked) {
                              if (!newValues.includes(item._id)) {
                                newValues.push(item._id);
                              }
                              return newValues;
                            } else {
                              return newValues.filter((value) => value !== item._id);
                            }
                          });
                        }}
                        checked={selectedIds.includes(item._id)}
                      />
                    );
                  })}
                </SortableContext>
              </DndContext>
            </div>
          </div>
        </div>
        <div style={{ width: '428px' }}>
          <div style={{}}></div>
          <ProductCollectionPreview
            data={{
              ...modifiedProductCollection,
              coverImageUrl: cdnImageUrl(
                modifiedProductCollection.coverImageUrlTemp || modifiedProductCollection.coverImageUrl,
              ),
            }}></ProductCollectionPreview>
        </div>
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          left: 0,
          height: '44px',
          backgroundColor: '#eaeaea',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: '12px',
          paddingRight: '24px',
        }}>
        <Button
          disabled={!changedData}
          onClick={() => {
            const formData: FormData = new FormData();
            const json = { ...changedData };
            if (json.products) {
              json.products = json.products.map((product: ProductModel) => {
                return { _id: product._id };
              });
            }
            formData.append('productCollectionId', props.productCollectionId);
            formData.append('json', JSON.stringify(json));

            Object.values(files).forEach((file) => {
              formData.append('files', file);
            });
            mutaionUpdateProduct.mutate(formData);
          }}>
          저장
        </Button>

        <Button
          onClick={() => {
            props.onClose && props.onClose();
          }}>
          닫기
        </Button>
      </div>
    </div>
  );
};

export const ProductCollectionsPage: React.FC = () => {
  const [shareLinkProductId, setShareLinkProductId] = useState<string>();
  const [searchKeyword, setSearchKeyword] = useState<string>();
  const [previewProductCollection, setPreviewProductCollection] = useState<ProductCollection>();
  const [productCollectionEditId, setProductCollectionEditId] = useState<string>();
  const searchInputRef = useRef<any>();
  const appSocket = useAppSocket();
  const store = useProductCollectionsStore();
  const loader = useContentLoaderStore();
  const dialog = useOkCancelDialog();
  useAuthentication();

  const generateOneShareLinkMutate = useMutation({
    mutationFn: commonApi.generateProductCollectionShareLink,
  });

  const productCollectionSearchQuery = useQuery({
    queryKey: [`admin-search-product-collections`, JSON.stringify(store.searchQuery)],
    queryFn: () => api.searchProductCollections(store.searchQuery),
  });

  const createCollectionMutation = useMutation({
    mutationFn: api.createProductCollection,
    onSuccess: () => {
      productCollectionSearchQuery.refetch();
      dialog.open({
        content: `새로운 기획전이 추가되었습니다`,
      });
    },
  });

  const deleteCollectionMutation = useMutation({
    mutationFn: api.deleteProductCollection,
    onSuccess: () => {
      productCollectionSearchQuery.refetch();
      toast.info('기획전이 삭제되었습니다', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  useEffect(() => {
    const searchResult = productCollectionSearchQuery.data?.data;
    if (searchResult) {
      store.setProductCollectionsContainer(searchResult);
    }
  }, [productCollectionSearchQuery.data?.data]);

  useEffect(() => {
    appSocket.socket.addSocketEventListener('upload_excel_status', 'upload_excel_status', (data) => {
      const status = (data.status || '').toLowerCase();
      if (status === 'done') {
        productCollectionSearchQuery.refetch();
      }
      if (status === 'done' || status === 'error') {
        loader.off();
      }

      if (data.status) {
        toast.error(data.message, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    });
  }, []);

  window.onbeforeunload = () => {
    appSocket.socket.removeSocketEventListener('upload_excel_status', 'upload_excel_status');
  };

  const TABLE_COLUMNS: ColumnsType<ProductCollection> = [
    {
      title: 'ID',
      dataIndex: '_id',
      render: (_id) => {
        return (
          <Button
            onClick={() => {
              navigator.clipboard.writeText(_id);
              toast.dark('기획전 ID를 클립보드에 복사했습니다', {
                position: 'top-center',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                pauseOnFocusLoss: false,
                draggable: true,
                progress: undefined,
                bodyStyle: {
                  color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                  textAlign: 'center',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '20px',
                },
                theme: 'dark',
              });
            }}>
            ID 복사
          </Button>
        );
      },
    },
    {
      title: '커버 이미지',
      dataIndex: 'coverImageUrl',
      render: (coverImageUrl) => {
        if (!coverImageUrl) {
          return 'NO IMAGE';
        }
        return (
          <img
            src={cdnImageUrl(coverImageUrl)}
            style={{ width: '40px', height: '40px', objectFit: 'cover', borderRadius: '4px' }}
          />
        );
      },
    },
    {
      title: '구분',
      dataIndex: 'type',
      render: (type) => {
        if (!type) {
          return '-';
        }
        return `${type}`;
      },
    },
    {
      title: '기획전 제목',
      dataIndex: 'title',
      render: (title) => `${title}`,
    },
    {
      title: '상품수',
      width: 80,
      dataIndex: 'products',
      render: (products) => {
        if (!products) {
          return '-';
        }
        return `${products.length}`;
      },
    },
    {
      title: '노출 우선순위',
      dataIndex: 'priority',
      render: (priority) => {
        if (priority) {
          return '-';
        }
        return `${priority}`;
      },
    },
    {
      title: '복제',
      render: (record) => {
        return (
          <Button
            onClick={() => {
              api.getProductCollection(record._id).then((response) => {
                const duplicatedProductCollection = {
                  ...response.data,
                  _id: undefined,
                  meta: undefined,
                  updatedAt: undefined,
                  createdAt: undefined,
                  title: `[복제됨] ${response.data.title}`,
                };
                createCollectionMutation.mutate(clearEmptyKeysFromObject(duplicatedProductCollection));
              });
            }}>
            복제본 생성
          </Button>
        );
      },
    },
    {
      title: '공유링크',
      dataIndex: '_id',
      render: (_id) => {
        const url = `${COLLECTION_SHARE_BASE_URL}/${_id}`;
        return (
          <Button
            onClick={() => {
              navigator.clipboard.writeText(url);
              toast.dark('주소를 클립보드에 복사했습니다', {
                position: 'top-center',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                pauseOnFocusLoss: false,
                draggable: true,
                progress: undefined,
                bodyStyle: {
                  color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                  textAlign: 'center',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '20px',
                },
                theme: 'dark',
              });
            }}>
            복사
          </Button>
        );
      },
    },
    {
      title: '수정',
      dataIndex: '_id',
      render: (_id: string) => {
        return (
          <Button
            key={`${_id}`}
            onClick={() => {
              setProductCollectionEditId(_id);
            }}>
            수정
          </Button>
        );
      },
    },
    {
      title: '삭제',
      dataIndex: '_id',
      render: (_id: string) => {
        return (
          <Button
            style={{ color: 'red' }}
            key={`${_id}`}
            onClick={() => {
              dialog.open({
                content: '삭제하면 복구할 수 없습니다.\n해당 기획전을 삭제하시겠습니까?',
                onConfirm: () => {
                  deleteCollectionMutation.mutate(_id);
                },
              });
            }}>
            삭제
          </Button>
        );
      },
    },
    {
      title: '미리보기',
      dataIndex: '_id',
      render: (_id: string) => {
        return (
          <Button
            onClick={() => {
              api.getProductCollection(_id).then((response) => {
                setPreviewProductCollection(response.data);
              });
            }}>
            미리보기
          </Button>
        );
      },
    },
    {
      title: '생성/수정',
      render: (record) => {
        return (
          <div>
            <div>{record.createdAt ? moment(record.createdAt).format('YYYY.MM.DD HH:mm') : '-'}</div>
            <div style={{ fontWeight: 'bold' }}>
              {record.updatedAt ? moment(record.updatedAt).format('YYYY.MM.DD HH:mm') : '-'}
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="dashboard" style={{ position: 'relative' }}>
      <div className="page-header">
        <div>기획전 관리</div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Input
            placeholder="신규 기획전 ID"
            onChange={(e) => {
              setShareLinkProductId(e.target.value);
            }}></Input>
          <button
            disabled={generateOneShareLinkMutate.isPending}
            className="link-button"
            style={{
              color: 'white',
              fontWeight: 'bold',
              width: '240px',
              display: 'flex',
              justifyContent: 'center',
              height: '40px',
              padding: '4px',
              backgroundColor: '#2259a1',
              alignItems: 'center',
              borderRadius: '6px',
            }}
            onClick={() => {
              if (shareLinkProductId) {
                generateOneShareLinkMutate.mutate(shareLinkProductId);
              }
            }}>
            {generateOneShareLinkMutate.isPending && <ClipLoader size={'20px'} color={'#ffffff'}></ClipLoader>}
            {!generateOneShareLinkMutate.isPending && '신규기획전 공유링크 생성'}
          </button>
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <div className="page-content" style={{ width: '100%', height: 'calc(100vh - 80px)', overflow: 'auto' }}>
          <div style={{ display: 'flex', gap: '12px', width: '100%', height: '52px', alignItems: 'center' }}>
            <Select
              defaultValue="all"
              style={{ width: 120 }}
              onChange={(value) => {
                store.updateSearchQuery({ type: value });
              }}
              options={[
                { value: 'all', label: '전체' },
                { value: 'md_pick', label: 'MD PICK' },
                { value: 'general', label: '일반형' },
                { value: 'list', label: '리스트형' },
                { value: 'grid', label: '그리드형' },
                { value: 'thema', label: '테마' },
                { value: 'combine', label: '혼합형' },
              ]}
            />
            <Input
              ref={searchInputRef}
              style={{ width: '240px' }}
              value={searchKeyword}
              placeholder={'검색어 입력 후 엔터'}
              onChange={(e) => {
                setSearchKeyword(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.code === 'Enter' && searchKeyword) {
                  store.updateSearchQuery({ keyword: searchKeyword });
                }
              }}></Input>

            <div style={{ display: 'flex', marginLeft: 'auto', gap: '12px', alignItems: 'center' }}>
              <Button
                onClick={() => {
                  createCollectionMutation.mutate({ type: 'general', title: '신규 기획전' });
                }}>
                신규 기획전 추가
              </Button>
              <Button
                onClick={() => {
                  setSearchKeyword(undefined);
                  store.resetSearchQuery();
                }}>
                검색 초기화
              </Button>
            </div>
          </div>
          <Table
            columns={TABLE_COLUMNS}
            scroll={{ x: 'max-content', y: 'calc(100dvh - 280px)' }}
            style={{ whiteSpace: 'pre-wrap' }}
            rowKey={(record) => record?._id}
            dataSource={store.searchResultContainer.contents.filter((product) => product?._id)}
            pagination={{
              current: (store.searchResultContainer.page || 0) + 1,
              pageSize: store.searchResultContainer.size || 20,
              total: store.searchResultContainer.total,
            }}
            loading={productCollectionSearchQuery.isLoading}
            onChange={(e) => {
              store.updateSearchQuery({ page: (e.current || 2) - 1, size: e.pageSize || 20 });
            }}
          />
        </div>
        {productCollectionEditId && (
          <ProductCollectionEditView
            productCollectionId={productCollectionEditId}
            onClose={() => {
              setProductCollectionEditId(undefined);
            }}
            needRefetch={() => {
              productCollectionSearchQuery.refetch();
            }}
          />
        )}
        {previewProductCollection && (
          <div
            style={{
              width: '428px',
              height: '100vh',
              position: 'fixed',
              top: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'white',
              zIndex: 900,
              borderLeft: '1px solid #eaeaea',
            }}>
            <div style={{ height: '44px', backgroundColor: '#eaeaea', display: 'flex', alignItems: 'center' }}>
              <Button
                onClick={() => {
                  setPreviewProductCollection(undefined);
                }}>
                닫기
              </Button>
            </div>
            <ProductCollectionPreview data={previewProductCollection}></ProductCollectionPreview>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductCollectionsPage;
