import * as api from '@apis/admin-reviews';
import { useAuthentication } from '@hooks/authentication';
import { PaymentStatement, RefundStatus } from '@models/paymentStatement';
import { PaymentStatementReview, PaymentStatementReviewStatus } from '@models/review';
import { useReviewsStore } from '@stores/reviewsStore';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, DatePicker, DatePickerProps, Input, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { localTimeToUtcISOString, utcTimcToLocalISOString } from '@utils/date';

export const ReviewsPage: React.FC = () => {
  const [searchKeyword, setSearchKeyword] = useState<string>();
  const searchInputRef = useRef<any>();

  const store = useReviewsStore();

  useAuthentication();

  const onChangeStartDate: DatePickerProps['onChange'] = (date) => {
    store.setStartDate(localTimeToUtcISOString(date ? new Date(date.toISOString()) : new Date(), { timeToZero: true }));
  };

  const onChangeEndDate: DatePickerProps['onChange'] = (date) => {
    store.setEndDate(localTimeToUtcISOString(date ? new Date(date.toISOString()) : new Date(), { timeToZero: true }));
  };

  const reviewsQuery = useQuery({
    queryKey: [`reviews`, JSON.stringify(store.searchQuery)],
    queryFn: () => api.searchReviews(store.searchQuery),
  });

  const updateReviewMutation = useMutation({
    mutationFn: api.updateReview,
    onSuccess: async () => {
      toast.dark('리뷰 상태가 변경되었습니다', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: false,
        draggable: true,
        progress: undefined,
        bodyStyle: {
          color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
          textAlign: 'center',
          fontFamily: 'Pretendard',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '20px',
        },
        theme: 'dark',
      });
      reviewsQuery.refetch();
    },
  });

  const reviews = reviewsQuery.data?.data;

  useEffect(() => {
    if (reviews) {
      store.setReviewsContainer(reviews);
    }
  }, [reviews]);

  const TABLE_COLUMNS: ColumnsType<PaymentStatementReview> = [
    {
      title: 'ID',
      render: (record: PaymentStatementReview) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(record._id);
                toast.dark('ID를 클립보드에 복사했습니다', {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  pauseOnFocusLoss: false,
                  draggable: true,
                  progress: undefined,
                  bodyStyle: {
                    color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                    textAlign: 'center',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                  },
                  theme: 'dark',
                });
              }}>
              리뷰 ID
            </Button>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(record.paymentStatementId);
                toast.dark('결제내역 ID를 클립보드에 복사했습니다', {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  pauseOnFocusLoss: false,
                  draggable: true,
                  progress: undefined,
                  bodyStyle: {
                    color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                    textAlign: 'center',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                  },
                  theme: 'dark',
                });
              }}>
              결제 ID
            </Button>
          </div>
        );
      },
    },
    {
      title: '작성자',
      dataIndex: 'user',
      render: (user) => {
        return `${user?.profile?.name}\n(${user?.profile?.phone?.substring(user?.profile?.phone?.length - 4)})`;
      },
    },
    {
      title: '판매자',
      dataIndex: 'product',
      render: (product, record) => {
        return (
          <div>
            <div>{product.sellerName}</div>
            <div>
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(record.product?.sellerId || '');
                  toast.dark('판매자 ID를 클립보드에 복사했습니다', {
                    position: 'top-center',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    pauseOnFocusLoss: false,
                    draggable: true,
                    progress: undefined,
                    bodyStyle: {
                      color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                      textAlign: 'center',
                      fontFamily: 'Pretendard',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '20px',
                    },
                    theme: 'dark',
                  });
                }}>
                판매자 ID
              </Button>
            </div>
          </div>
        );
      },
    },
    {
      title: '상품명',
      width: 200,
      dataIndex: 'product',
      render: (product) => {
        return product.title;
      },
    },
    {
      title: '리뷰 내용',
      width: 400,
      dataIndex: 'comment',
      render: (comment) => {
        return comment;
      },
    },
    {
      title: '평가',
      width: 320,
      render: (record: PaymentStatementReview) => {
        return (
          <table>
            <tr>
              <td>친화력</td>
              <td>전문성</td>
              <td>준비성</td>
              <td>시간준수</td>
            </tr>
            <tr>
              <td>{record.rates.rate1}</td>
              <td>{record.rates.rate2}</td>
              <td>{record.rates.rate3}</td>
              <td>{record.rates.rate4}</td>
            </tr>
          </table>
        );
      },
    },
    {
      title: '노출 상태 변경',
      render: (record) => {
        return (
          <Button
            onClick={() => {
              updateReviewMutation.mutate({
                reviewId: record._id,
                body: {
                  status:
                    record.status === PaymentStatementReviewStatus.HIDDEN
                      ? PaymentStatementReviewStatus.OPEN
                      : PaymentStatementReviewStatus.HIDDEN,
                },
              });
            }}>
            {record.status === PaymentStatementReviewStatus.HIDDEN ? '노출' : '노출 중지'}
          </Button>
        );
      },
    },
    {
      title: '작성',
      dataIndex: 'createdAt',
      render: (createdAt) => {
        if (!createdAt) {
          return '-';
        }
        return moment(createdAt).format('YYYY.MM.DD HH:mm');
      },
    },
    {
      title: '결제 정보',
      render: (record) => {
        const paymentStatement: PaymentStatement = record?.paymentStatement;
        if (!paymentStatement) {
          return '결제 내역 없음';
        }

        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <div style={{ display: 'flex', gap: '8px' }}>
              <div>결제일시:</div>
              <div>{paymentStatement.createdAt && moment(paymentStatement.createdAt).format('YYYY.MM.DD HH:mm')}</div>
            </div>
            {(paymentStatement.plannedDates || []).length > 0 && (
              <div style={{ display: 'flex', gap: '8px' }}>
                <div>수업일시:</div>
                <div>
                  {(paymentStatement.plannedDates || []).map((date) => {
                    return <div>{moment(date).format('YYYY.MM.DD HH:mm')}</div>;
                  })}
                </div>
              </div>
            )}
            <div style={{ display: 'flex', gap: '4px' }}>
              <div>상태:</div>
              <div>
                {(() => {
                  const payStatus = (paymentStatement.statuses.payment || '').toLowerCase();
                  let status = '';
                  if (payStatus === 'done') {
                    status = '결제완료';
                  } else if (payStatus === 'ready') {
                    status = '결제대기';
                  } else if (payStatus === 'none') {
                    status = '';
                  } else {
                    status = payStatus;
                  }
                  return status;
                })()}
              </div>
              <div style={{ fontWeight: 'bold' }}>
                {(() => {
                  const refundStatus = paymentStatement.statuses?.refund;
                  let status = '';
                  if (refundStatus === RefundStatus.ALL) {
                    status = '전체환불';
                  } else if (refundStatus === RefundStatus.PARTIAL) {
                    status = '부분환불';
                  } else if (refundStatus === RefundStatus.REFUNDREGISTER || refundStatus === RefundStatus.ACCEPTED) {
                    status = '환불접수';
                  } else if (refundStatus === RefundStatus.RESERVED) {
                    status = '환불예약';
                  }
                  return status;
                })()}
              </div>
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="dashboard" style={{ position: 'relative' }}>
      <div className="page-header">
        <div>리뷰 관리</div>
        <div style={{ display: 'flex', gap: '8px' }}>
          <div>
            <Button
              style={{
                borderColor: store.searchQuery.dateType === 'paymentStatement.payment.paidAt' ? 'blue' : '#d9d9d9',
              }}
              onClick={() => {
                store.updateSearchQuery({ dateType: 'paymentStatement.payment.paidAt' });
              }}>
              결제일
            </Button>
          </div>
          <div>
            <Button
              style={{ borderColor: store.searchQuery.dateType === 'createdAt' ? 'blue' : '#d9d9d9' }}
              onClick={() => {
                store.updateSearchQuery({ dateType: 'createdAt' });
              }}>
              생성일
            </Button>
          </div>
          <div>
            <Button
              style={{
                borderColor: store.searchQuery.dateType === 'paymentStatement.plannedDates' ? 'blue' : '#d9d9d9',
              }}
              onClick={() => {
                store.updateSearchQuery({ dateType: 'paymentStatement.plannedDates' });
              }}>
              수업일
            </Button>
          </div>
          <div>
            <DatePicker
              onChange={onChangeStartDate}
              value={dayjs(utcTimcToLocalISOString(store.searchQuery.startDate as any))}
            />
          </div>
          <div>
            <DatePicker
              onChange={onChangeEndDate}
              value={dayjs(utcTimcToLocalISOString(store.searchQuery.endDate as any))}
            />
          </div>
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <div className="page-content" style={{ width: '100%', height: 'calc(100vh - 80px)', overflow: 'auto' }}>
          <div
            style={{
              display: 'flex',
              gap: '12px',
              width: '100%',
              height: '52px',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
              <Select
                value={store.searchQuery.sort || 'createdAt'}
                style={{ width: 180 }}
                onChange={(value) => {
                  store.updateSearchQuery({ sort: value });
                }}
                options={[
                  { value: 'createdAt', label: '최근 작성 순' },
                  { value: 'paymentStatement.payment.paidAt', label: '최근 결제 순' },
                  { value: 'paymentStatement.plannedDates', label: '최근 수업 순' },
                  { value: 'rateDesc', label: '평가 높은 순' },
                  { value: 'rateAsc', label: '평가 낮은 순' },
                ]}
              />
              <Input
                ref={searchInputRef}
                style={{ width: '240px' }}
                value={searchKeyword}
                placeholder={'검색어 입력 후 엔터'}
                onChange={(e) => {
                  setSearchKeyword(e.target.value);
                }}
                onKeyUp={(e) => {
                  if (e.code === 'Enter' && searchKeyword) {
                    store.updateSearchQuery({ keyword: searchKeyword });
                  }
                }}></Input>
              <Button
                onClick={() => {
                  setSearchKeyword(undefined);
                  store.resetSearchQuery();
                }}>
                검색 초기화
              </Button>
            </div>

            <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
              <Button
                onClick={async () => {
                  const fileName = `igogo-reviews-${moment().format('YYYYMMDD-HHmmss')}.xlsx`;
                  const blob = await api.getReviewsExcelFile(store.searchQuery);
                  if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
                    // IE variant
                    (window.navigator as any).msSaveOrOpenBlob(new Blob(blob.data), fileName);
                  } else {
                    const url = window.URL.createObjectURL(blob.data);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode && link.parentNode.removeChild(link);
                  }
                }}>
                결과 엑셀 파일 다운로드
              </Button>
            </div>
          </div>
          <Table
            columns={TABLE_COLUMNS}
            scroll={{ x: 'max-content', y: 'calc(100dvh - 280px)' }}
            style={{ whiteSpace: 'pre-wrap' }}
            rowKey={(record) => record?._id}
            dataSource={store.searchResultContainer.contents.filter((review) => review?._id)}
            pagination={{
              current: (store.searchResultContainer.page || 0) + 1,
              pageSize: store.searchResultContainer.size || 20,
              total: store.searchResultContainer.total,
            }}
            loading={reviewsQuery.isLoading}
            onChange={(e) => {
              store.updateSearchQuery({ page: (e.current || 2) - 1, size: e.pageSize || 20 });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ReviewsPage;
