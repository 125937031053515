import { PagenatedResponse, SearchPagenateQuery } from '@models/common';
import { ProductSearchQuery } from '@models/product';
import { UserContent } from '@models/user-contents';
import { apiClient } from '.';

export const searchUserContents = async (query?: SearchPagenateQuery) => {
  return apiClient.get<PagenatedResponse<UserContent>>(`/admin/v1/user-contents`, {
    params: query,
  });
};

export const getUserContentsExcelFile = async (query?: ProductSearchQuery) => {
  return apiClient.get(`/admin/v1/user-contents/export`, {
    params: query,
    responseType: 'blob',
    headers: { 'Cache-Control': 'no-cache' },
  });
};

export const getUserContent = async (userContentId: string) => {
  return apiClient.get<UserContent>(`/admin/v1/user-contents/${userContentId}`);
};

export const createUserContent = async (formData: FormData) => {
  return apiClient.post<UserContent>(`/admin/v1/user-contents`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const updateUserContent = async (formData: FormData) => {
  const userContentId = formData.get('userContentId');
  return apiClient.put<UserContent>(`/admin/v1/user-contents/${userContentId}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const updateUserContentStatus = async (props: { userContentId: string; status: string }) => {
  return apiClient.patch<UserContent>(`/admin/v1/user-contents/${props.userContentId}/status`, {
    status: props.status,
  });
};
