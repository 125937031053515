import FileUploadButton from '@components/common/FileUploadButton';
import { closestCenter, DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FeedData, FeedDataItem } from '@models/feed';
import { cdnImageUrl, resizeImage } from '@utils/image';
import { extractFileName } from '@utils/string';
import { Button, Checkbox, Input, Switch } from 'antd';
import { RcFile } from 'antd/es/upload';
import React, { useEffect, useState } from 'react';
import DeeplinkActionEdit from './DeepLinkActionEdit';

interface SortableVerticalBannerItemProps {
  onDeleteItem?: (event: any) => void;
  onChange?: (item: FeedDataItem) => void;
  onChangeImage?: (files: FileList) => void;
  id: any;
  item: FeedDataItem;
}

export const SortableVerticalBannerItem: React.FC<SortableVerticalBannerItemProps> = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const { onDeleteItem, onChange, item } = props;

  return (
    <div ref={setNodeRef} style={{ ...style }} {...attributes} {...listeners}>
      <div style={{ display: 'flex', marginBottom: '8px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '160px', marginRight: '8px' }}>
          <div style={{ marginBottom: '4px' }}>
            <div
              style={{
                width: '160px',
                marginRight: '12px',
                borderRadius: '8px',
                height: '220px',
                backgroundColor: 'white',
                position: 'relative',
                overflow: 'hidden',
                cursor: 'pointer',
              }}>
              <img
                style={{ width: '160px', height: '220px', objectFit: 'cover' }}
                src={cdnImageUrl(item.imageUrlTemp || item.imageUrl)}
              />
              <div
                style={{
                  position: 'absolute',
                  bottom: 0,
                  marginLeft: '12px',
                  marginBottom: '12px',
                }}>
                <div
                  style={{
                    color: 'white',
                    fontSize: '15px',
                    fontWeight: 700,
                    lineHeight: 1.4,
                    whiteSpace: 'pre-wrap',
                  }}>
                  {item.mainCopy1}
                  <br />
                  {item.mainCopy2}
                </div>
                <div
                  style={{
                    marginTop: '4px',
                    fontSize: '12px',
                    color: 'white',
                    opacity: 0.8,
                    lineHeight: 1.4,
                  }}>
                  {item.subCopy}
                </div>
              </div>
            </div>
          </div>
          <FileUploadButton
            accept={['jpg', 'jpeg', 'png', 'tif', 'tiff', 'webp', 'svg']
              .map((ext) => {
                return '.' + ext;
              })
              .join(',')}
            className="link-button"
            style={{
              fontSize: '14px',
              height: '32px',
              padding: '4px 15px',
              borderRadius: '6px',
              backgroundColor: '#ffffff',
              border: '1px solid #d9d9d9',
              color: 'rgba(0, 0, 0, 0.88)',
              boxShadow: '0 2px 0 rgba(0, 0, 0, 0.02)',
              cursor: 'pointer',
            }}
            onChange={(e) => {
              if (e.target.files) {
                const files = e.target.files;
                props.onChangeImage && props.onChangeImage(files);
              }
            }}>
            썸네일 변경
          </FileUploadButton>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', marginRight: '8px' }}>
          <Checkbox
            checked={item.fixed}
            onChange={(e) => {
              onChange && onChange({ ...item, fixed: e.target.checked });
            }}>
            위치 고정
          </Checkbox>
          <Input
            value={item.mainCopy1}
            onChange={(e) => {
              onChange && onChange({ ...item, mainCopy1: e.target.value });
            }}></Input>
          <Input
            value={item.mainCopy2}
            onChange={(e) => {
              onChange && onChange({ ...item, mainCopy2: e.target.value });
            }}></Input>
          <Input
            value={item.subCopy}
            onChange={(e) => {
              onChange && onChange({ ...item, subCopy: e.target.value });
            }}></Input>
          <div style={{ border: '1px solid #eaeaea', borderRadius: '8px', padding: '8px' }}>
            <div>클릭시 이동 설정</div>
            <DeeplinkActionEdit
              data={item}
              onChange={(value) => {
                onChange && onChange({ ...item, ...value });
              }}></DeeplinkActionEdit>
          </div>
        </div>
        <div>
          <Button
            onClick={(e) => {
              onDeleteItem && onDeleteItem(e);
            }}>
            삭제
          </Button>
        </div>
      </div>
    </div>
  );
};

export const VerticalBannerEdit: React.FC<{
  clear?: boolean;
  data: Partial<FeedData>;
  onChange?: (data: Partial<FeedData>) => void;
  onChangeImageFile?: (files: { [key: string]: File }) => void;
}> = (props) => {
  const [changedContents, setChangedContents] = useState<FeedDataItem[]>();
  const [randomDisplay, setRandomDisplay] = useState<boolean>();
  const [imageFiles, setImageFiles] = useState<{ [key: string]: File }>({});
  const { data } = props;
  const contents = changedContents || data.data || [];
  const sortableSensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 10 },
    }),
  );

  useEffect(() => {
    if (props.clear) {
      setChangedContents(undefined);
      setImageFiles({});
    }
  }, [props.clear]);

  useEffect(() => {
    props.onChange &&
      props.onChange({
        ...data,
        data: changedContents || data.data,
        shuffle: randomDisplay !== undefined ? randomDisplay : data.shuffle,
      });
  }, [changedContents, randomDisplay]);

  useEffect(() => {
    props.onChangeImageFile && props.onChangeImageFile(imageFiles);
  }, [imageFiles]);

  const onSortEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      if (contents) {
        const oldIndex = contents.findIndex((item) => item.imageUrl === active.id);
        const newIndex = contents.findIndex((item) => item.imageUrl === over?.id);
        const newData = arrayMove(contents, oldIndex, newIndex);
        setChangedContents(newData);
      }
    }
  };

  const onChangeImage = async (files: FileList, index: number) => {
    const { fileName } = extractFileName(files[0].name);
    const newFiles = await Promise.all(
      Array.from(files)
        .slice(0, 10)
        .map(async (file) => {
          const result = await resizeImage(file, { maxWidth: 320, maxHeight: 440 });

          return result;
        }),
    );
    const contentTypes = newFiles[0].type.split('/');
    const uploadFileName = `${fileName}.${contentTypes[1]}`;
    const newImageFile = new File([newFiles[0].image], `${fileName}.${contentTypes[1]}`) as RcFile;
    const tempUrl = URL.createObjectURL(newFiles[0].image);
    setChangedContents((prev) => {
      const newValues = [...(prev || contents)];
      newValues[index] = { ...newValues[index], imageUrl: uploadFileName, imageUrlTemp: tempUrl };
      return newValues;
    });

    setImageFiles((value) => {
      const newValue = { ...value };
      newValue[`${data.type}.${index}`] = newImageFile;
      return newValue;
    });
  };

  return (
    <div>
      <div style={{ display: 'flex', height: '44px', justifyContent: 'space-between' }}>
        <div>
          <span>무작위 노출: </span>
          <Switch
            checked={randomDisplay !== undefined ? randomDisplay : data.shuffle}
            onChange={(checked) => {
              setRandomDisplay(checked);
            }}></Switch>
        </div>
        <Button
          onClick={() => {
            setChangedContents((prev) => {
              const newValues = [{}, ...(prev || contents)];
              return newValues;
            });
          }}>
          빈 배너 추가
        </Button>
      </div>
      <DndContext sensors={sortableSensors} collisionDetection={closestCenter} onDragEnd={onSortEnd}>
        <SortableContext
          items={contents.map((item) => {
            return { ...item, id: item.imageUrl || '' };
          })}
          strategy={verticalListSortingStrategy}>
          <div style={{ maxWidth: '600px' }}>
            {contents.map((item, index) => {
              return (
                <SortableVerticalBannerItem
                  item={item}
                  id={item.imageUrl}
                  onDeleteItem={() => {
                    setChangedContents((prev) => {
                      const newValues = [...(prev || contents)];
                      newValues.splice(index, 1);
                      return newValues;
                    });
                  }}
                  onChangeImage={(files: FileList) => {
                    onChangeImage(files, index);
                  }}
                  onChange={(item) => {
                    setChangedContents((prev) => {
                      const newValues = [...(prev || contents)];
                      newValues[index] = item;
                      return newValues;
                    });
                  }}></SortableVerticalBannerItem>
              );
            })}
          </div>
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default VerticalBannerEdit;
