import React from 'react';

export interface FileUploadButtonPros {
  style?: any;
  children: Element[] | JSX.Element[] | Element | JSX.Element | any;
  className?: string;
  title?: string;
  disabled?: boolean | undefined;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  accept?: string;
  multiple?: boolean;
}

const defaultAccepts = ['xls', 'xlsx']
  .map((ext) => {
    return '.' + ext;
  })
  .join(',');

const FileUploadButton: React.FC<FileUploadButtonPros> = (props) => {
  const { style, title, disabled, accept, children, className, onChange, multiple } = props;
  const hiddenFileInput = React.useRef<any>();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }
    hiddenFileInput.current.value = null;
  };
  return (
    <>
      <button title={title} disabled={disabled} className={className} style={style} onClick={handleClick}>
        {children}
      </button>
      <input
        type="file"
        accept={accept || defaultAccepts}
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
        multiple={multiple}
      />
    </>
  );
};
export default FileUploadButton;
