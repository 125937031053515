import { ProfileApprovalState, User } from './auth';

export interface Dictionary {
  [key: string]: any;
}

export interface PagenatedResponse<T> {
  page: number;
  size: number;
  total: number;
  hasNext: boolean;
  contents: T[];
}
export interface PhoneValidatorResponse {
  // validationCode?: number;
  tts?: number;
}

export interface MainFeedMarketingModalOption {
  isActive: boolean;
  isAutoSwipeMarketingPopUp: boolean;
  marketingPopUpSwipeStartTerms: number;
  marketingPopUpSwipeTerms: number;
  isRandomDisplay: boolean;
}

export enum DeepLinkActions {
  GO_TO_MAIN = 'go_to_main',
  GO_TO_COMMUNITY = 'go_to_community',
  GO_TO_NOTICE = 'go_to_notice',
  GO_TO_NEWS = 'go_to_news',
  GO_TO_CLASS_DETAIL = 'go_to_class_detail',
  OPEN_LINK = 'open_link',
  GO_TO_COUPON = 'go_to_coupon',
  GO_TO_COLLECTION = 'go_to_collection',
  GO_TO_PRODUCT = 'go_to_product',
  GO_TO_CLASS_COLLECTION = 'go_to_class_collection',
  GO_TO_PICK = 'go_to_pick',
  GO_TO_COMMERCE_COLLECTION = 'go_to_commerce_collection',
  GO_TO_THEME_COLLECTION = 'go_to_theme_collection',
  GO_TO_COMMERCE_DETAIL = 'go_to_commerce_detail',
  OPEN_APP_LOGIN_MODAL = 'open_app_login_modal',
  SEARCH_KEYWORD = 'search_keyword',
  GO_TO_CHAT = 'go_to_chat',
  GO_TO_CHAT_LIST = 'go_to_chat_list',
  GO_TO_REWARD = 'go_to_reward',
  GO_TO_MY_PAGE = 'go_to_my_page',
  GO_TO_PURCHASE = 'go_to_purchase',
  GO_TO_LOUNGE = 'go_to_lounge',
  GO_TO_FAVORITES = 'go_to_favorites',
  GO_TO_PARTNER = 'go_to_partner',
}

export interface DeeplinkActionPayload {
  classId?: string;
  tutorId?: string;
  partnerId?: string;
  parentId?: string;
  chatRoomId?: string;
  collectionId?: string;
  brandId?: string;
  productId?: string;
  userContentId?: string;
  title?: string;
  keyword?: string;
  url?: string;
  noticeId?: string;
  tab?: string;
  write?: boolean;
}
export interface DeeplinkAction {
  action?: DeepLinkActions;
  payload?: DeeplinkActionPayload;
}

export interface PagenatedQuery {
  page?: number;
  size?: number;
}

export interface DatePeriodQuery {
  startDate?: string | null;
  endDate?: string | null;
  dateType?: string;
}

export interface SearchPagenateQuery extends PagenatedQuery, DatePeriodQuery {
  board?: string;
  category?: string;
  keyword?: string;
  type?: string;
  profileStatus?: ProfileApprovalState | 'all';
  sort?: string;
}

export interface CustomerRequest {
  _id: string;
  type: string;
  userId: string;
  user: User;
  userPhone: string;
  data: CustomerRequestData;
  createdAt: string;
  updatedAt: string;
}

export interface CustomerRequestData {
  saleType: string;
  productId: string;
  productName: string;
  productUpdatedAt: string;
  memo: string;
  weekdays: string[];
}
