import { PhoneValidatorResponse } from '@models/common';
import { RemoteConfig } from '@models/remote-config';
import {
  CreatePaymentStatementsBody,
  PaymentStatement,
  UpdatePaymentStatementEventType,
} from '@models/paymentStatement';
import { apiClient } from '.';
import { UrlPreviewData } from '@models/user-contents';

export const migrateUserContents = async () => {
  return apiClient.post(`/admin/v1/user-contents/migrate`);
};

export const changePaymentStatusToCancelFromReady = async () => {
  return apiClient.post(`/admin/v1/schedule/payment-statements/cancel`);
};

export const generateSitemap = async () => {
  return apiClient.post(`/admin/v1/schedule/share-pages-sitemap`);
};

export const generateShareLinks = async (type: string) => {
  return apiClient.post(`/admin/v1/share-pages`, {}, { params: { type } });
};

export const getGenerateShareLinkStatus = async () => {
  return apiClient.get(`/admin/v1/share-pages/status`);
};

export const generateProductShareLink = async (productId: string) => {
  return apiClient.post(`/admin/v1/share-pages/product/${productId}`);
};

export const generateProductCollectionShareLink = async (productCollectionId: string) => {
  return apiClient.post(`/admin/v1/share-pages/product-collection/${productCollectionId}`);
};

export const requestPhoneValidate = async (phoneNumber: string) => {
  return apiClient.post<PhoneValidatorResponse>(`/app/v1/phone`, { phoneNumber });
};

export const validatePhone = async (data: { phoneNumber: string; codeNumber: number }) => {
  return apiClient.post<{ resultMessage: string }>(`/app/v1/phone/validation`, {
    data,
  });
};

export const getPaymentAgency = async () => {
  return apiClient.get<{ agency: string }>('remote-config/v1/payment-agency');
};

export const createPaymentStatement = async (data: CreatePaymentStatementsBody) => {
  return apiClient.post<PaymentStatement>(`/app/v1/payment/statement`, {
    data,
  });
};

export const updatePaymentStatement = async (data: {
  data: PaymentStatement;
  event: UpdatePaymentStatementEventType;
}) => {
  return apiClient.put<PaymentStatement>(`/app/v1/payment/statement/${data.data.uuid}`, {
    data,
  });
};

export const sendKakaoNotification = async (body: {
  targets: { phone: string; name: string }[];
  templateId: string;
  content: any;
}) => {
  return apiClient.post(`/admin/v1/notification/kakao`, body);
};

export const getRemoteConfig = async () => {
  return apiClient.get<RemoteConfig>('admin/v1/remote-config');
};

export const updateRemoteConfig = async (formData: FormData) => {
  return apiClient.put<RemoteConfig>('admin/v1/remote-config', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const getUrlPreview = async (url: string) => {
  return apiClient.get<UrlPreviewData>(`/preview`, { params: { url } });
};
