import { apiClient } from '@apis/index';
import { API_URL } from '@variables';
import { cdnImageUrl } from './image';

class CKEditorIgogoImageUploadAdapter {
  loader: any;
  constructor(loader: any) {
    this.loader = loader;
  }

  upload() {
    const formData = new FormData();
    return this.loader.file.then(
      (file: any) =>
        new Promise((resolve, reject) => {
          formData.append('file', file, file.name);
          return apiClient
            .post(`${API_URL}/admin/v1/image-upload`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
              if (response.data.filePath) {
                this.loader.uploaded = true;
                resolve({
                  default: cdnImageUrl(response.data.filePath),
                });
              } else {
                reject(`Error uploading file: ${file.name}.`);
              }
            });
        }),
    );
  }

  abort() {
    // Cancel the upload process if it is currently in progress.
  }
}

export function CKEditorIgogoImageUploader(editor: any) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
    return new CKEditorIgogoImageUploadAdapter(loader);
  };
}
