import * as notificationApi from '@apis/notification';
import Table, { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { DefaultInfoNotification } from '@models/notification';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import useNotificationStore from '@stores/notificationStore';
import { useEffect, useState } from 'react';
import { Button } from 'antd';
import NoticeEditModal from '@components/notice/NoticeEditModal';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import NoticeContentMenu from '@components/notice/NoticeContentMenu';

const NoticesPage: React.FC = () => {
  const [editNoticeId, setEditNoticeId] = useState<string>();
  const store = useNotificationStore();
  const dialog = useOkCancelDialog();
  const queryClient = useQueryClient();

  const getNotificationQuery = useQuery({
    queryKey: ['notification-all', JSON.stringify(store.searchQuery)],
    queryFn: () => notificationApi.getAllNotification(store.searchQuery),
  });

  const deleteNotificationMutation = useMutation({
    mutationFn: notificationApi.deleteNotification,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['notification-all'] });
      dialog.open({
        content: '삭제되었습니다.',
        type: 'ok',
      });
    },
    onError: async (e: any) => {
      dialog.open({
        title: '오류',
        content: e?.response?.data?.message,
        type: 'ok',
      });
    },
  });

  useEffect(() => {
    const searchResult = getNotificationQuery.data?.data;
    if (searchResult) store.setNoticeContainer(searchResult);
  }, [getNotificationQuery.data?.data]);

  const TABLE_COLUMNS: ColumnsType<DefaultInfoNotification> = [
    {
      title: '도메인',
      render: (record) => <div>{(record.targets || []).join(' / ')}</div>,
    },
    {
      title: '제목',
      dataIndex: 'title',
      render: (title) => <div>{title}</div>,
      fixed: 'left',
      width: '50%',
    },
    {
      title: '팝업',
      render: (record) => <div>{record.popup ? 'O' : 'X'}</div>,
    },
    {
      title: '우선순위',
      render: (record) => <div>{record?.priority || ''}</div>,
      width: 100,
    },
    {
      title: '관리',
      dataIndex: '_id',
      width: '10%',
      render: (_id) => (
        <div key={_id} style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <Button onClick={() => setEditNoticeId(_id)}>수정</Button>
          <Button
            style={{ color: 'red' }}
            onClick={() => {
              dialog.open({
                content: '해당 공지사항을 삭제하시겠습니까?',
                onConfirm: () => {
                  deleteNotificationMutation.mutate(_id);
                },
              });
            }}>
            삭제
          </Button>
        </div>
      ),
    },
    {
      title: '생성 / 수정',
      dataIndex: 'createdAt',
      width: '20%',
      render: (createdAt, record) => (
        <div style={{ width: '120px' }}>
          <div style={{ fontSize: '13px' }}>{moment(createdAt).format('YYYY.MM.DD HH:mm')}</div>
          <div style={{ fontSize: '13px', fontWeight: 'bold' }}>
            {moment(record.updatedAt).format('YYYY.MM.DD HH:mm')}
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="notification" style={{ position: 'relative' }}>
      {!!editNoticeId && <NoticeEditModal notificationId={editNoticeId} onClose={() => setEditNoticeId(undefined)} />}
      <div className="page-header">
        <div>공지 사항</div>
      </div>
      <div className="page-content" style={{ width: '100%', height: 'calc(100vh - 80px)', overflow: 'auto' }}>
        <NoticeContentMenu openModal={() => setEditNoticeId('new')} />
        <Table
          columns={TABLE_COLUMNS}
          scroll={{ x: 'max-content', y: 'calc(100vh - 280px)' }}
          style={{ whiteSpace: 'pre-wrap', backgroundColor: 'transparent' }}
          rowKey={(record) => record?._id}
          dataSource={store.searchResultContainer.contents.filter((notice) => notice?._id)}
          pagination={{
            current: (store.searchResultContainer.page || 0) + 1,
            pageSize: store.searchResultContainer.size || 20,
            total: store.searchResultContainer.total,
          }}
          loading={getNotificationQuery.isLoading}
          onChange={(e) => {
            store.updateSearchQuery({ page: (e.current || 2) - 1, size: e.pageSize || 20 });
          }}
        />
      </div>
    </div>
  );
};

export default NoticesPage;
