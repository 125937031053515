import { useAuthentication } from '@hooks/authentication';
import { SalesChannelOrder, SalesChannelsType } from '@models/sales-channels';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Checkbox, DatePicker, DatePickerProps, Input, Modal, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import * as api from '@apis/sales-channels';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { useContentLoaderStore } from '@stores/contentLoaderStore';
import { useAppSocket } from '@hooks/appSocket';
import { AdminUser } from '@models/auth';
import { localTimeToUtcISOString, utcTimcToLocalISOString } from '@utils/date';
import dayjs from 'dayjs';
import { formatPhoneNumber } from '@utils/dataTransform';
import useAddAccountTransferModalStore from '@stores/addAccountTransferModalStore';
import { useInboundOrdersStore } from '@stores/inboundOrdersStore';
import { SelectParent } from '@components/product/SelectParent';
import { TextInput } from '@components/common/TextInput';
import SelectProductModal from '@components/product/SelectProductModal';
import { PaymentStatementSettlementStatus } from '@models/paymentStatement';
import { SelectUser } from '@components/product/SelectUser';

const REFUND_STATUSES: { [key: string]: any } = {
  all: (
    <div className="badge" style={{ backgroundColor: '#bd334a' }}>
      전체 환불
    </div>
  ),
  partial: (
    <div className="badge" style={{ backgroundColor: '#d944db' }}>
      부분 환불
    </div>
  ),
  requested: (
    <div className="badge" style={{ backgroundColor: '#db9737' }}>
      환불 요청
    </div>
  ),
  accepted: (
    <div className="badge" style={{ backgroundColor: '#2782b3' }}>
      환불 접수
    </div>
  ),
  denied: (
    <div className="badge" style={{ backgroundColor: '#6e6e6e' }}>
      환불 반려
    </div>
  ),
  none: '-',
};

export const InboundOrdersPage: React.FC = () => {
  const [editItem, setEditItem] = useState<SalesChannelOrder>();
  const [showChangeStatus, setShowChangeStatus] = useState<boolean>(false);
  const [showSelectPartner, setShowSelectPartner] = useState<boolean>(false);
  const [selectedPartner, setSelectedPartner] = useState<AdminUser>();
  const [selectedChangeStatus, setSelectedChangeStatus] = useState<string>('none');
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [searchKeyword, setSearchKeyword] = useState<string>();
  const [showCreateOrder, setShowCreateOrder] = useState<boolean>(false);
  const [newOrder, setNewOrder] = useState<Partial<SalesChannelOrder>>({});
  const [manualProductInput, setManualProductInput] = useState<boolean>(false);
  const [manualBuyerInput, setManualBuyerInput] = useState<boolean>(false);
  const [showProductSelect, setShowProductSelect] = useState<boolean>(false);
  const searchInputRef = useRef<any>();
  const dialog = useOkCancelDialog();
  const store = useInboundOrdersStore();
  const loader = useContentLoaderStore();
  const appSocket = useAppSocket();
  const addAccountTransferModalStore = useAddAccountTransferModalStore();

  const selectedIdsCount = (selectedIds || []).filter((id) => !!id).length;

  useAuthentication();

  useEffect(() => {
    if (!searchKeyword && store.searchQuery.keyword) {
      setSearchKeyword(store.searchQuery.keyword);
    }
  }, [store.searchQuery.keyword]);

  const inboundOrdersQuery = useQuery({
    queryKey: [`inbound-orders`, JSON.stringify(store.searchQuery), addAccountTransferModalStore.show],
    queryFn: () => api.searchInboundOrders(store.searchQuery),
  });

  const deleteOrdersMutation = useMutation({
    mutationFn: api.deleteNaverOrders,
    onSuccess: async () => {
      setShowSelectPartner(false);
      setSelectedPartner(undefined);
      const content = `삭제했습니다`;
      dialog.open({
        content: content,
        type: 'ok',
      });

      inboundOrdersQuery.refetch();
    },
    onError: async (e: any) => {
      dialog.open({
        title: '오류',
        content: e?.response?.data?.message,
        type: 'ok',
      });
    },
  });

  const sendKakaoNotificationMutation = useMutation({
    mutationFn: api.sendKakaoNotification,
    onSuccess: async (response) => {
      setShowSelectPartner(false);
      setSelectedPartner(undefined);
      const content = `알림톡을 발송했습니다.\n전체: ${response.data.total}\n요청: ${response.data.request}`;
      dialog.open({
        content: content,
        type: 'ok',
      });

      inboundOrdersQuery.refetch();
    },
    onError: async (e: any) => {
      dialog.open({
        title: '오류',
        content: e?.response?.data?.message,
        type: 'ok',
      });
    },
  });

  const updateInboundOrderMutaion = useMutation({
    mutationFn: api.updateSalesChannelOrders,
    onSuccess: async () => {
      setEditItem(undefined);
      setShowSelectPartner(false);
      setSelectedPartner(undefined);
      setShowChangeStatus(false);
      dialog.open({
        content: '수정했습니다.',
        type: 'ok',
      });

      inboundOrdersQuery.refetch();
    },
    onError: async (e: any) => {
      dialog.open({
        title: '오류',
        content: e?.response?.data?.message,
        type: 'ok',
      });
    },
  });

  const inboundOrders = inboundOrdersQuery.data?.data;

  const createOrderMutation = useMutation({
    mutationFn: api.createBankAccountTransferOrder,
    onSuccess: async (response) => {
      if (response.status === 201) {
        setShowCreateOrder(false);
        setNewOrder({});
      }
      dialog.open({
        type: 'ok',
        confirmColor: '#FF3D8F',
        title: response.status === 201 ? '완료' : '실패',
        content: response.status === 201 ? `결제내역 생성되었습니다.` : '에러가 발생했습니다.',
      });
      inboundOrdersQuery.refetch();
    },
  });

  useEffect(() => {
    if (inboundOrders) {
      store.setInboundOrdersContainer(inboundOrders);
    }
  }, [inboundOrders]);

  useEffect(() => {
    if (!showCreateOrder) {
      setNewOrder({});
    }
  }, [showCreateOrder]);

  useEffect(() => {
    appSocket.socket.addSocketEventListener('upload_excel_status', 'upload_excel_status', (data) => {
      const status = (data.status || '').toLowerCase();
      if (status === 'done') {
        inboundOrdersQuery.refetch();
      }
      if (status === 'done' || status === 'error') {
        loader.off();
      }

      if (data.status) {
        toast.error(data.message, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    });
  }, []);

  const onChangeStartDate: DatePickerProps['onChange'] = (date) => {
    store.setStartDate(localTimeToUtcISOString(date ? new Date(date.toISOString()) : new Date(), { timeToZero: true }));
  };

  const onChangeEndDate: DatePickerProps['onChange'] = (date) => {
    store.setEndDate(localTimeToUtcISOString(date ? new Date(date.toISOString()) : new Date(), { timeToZero: true }));
  };

  const TABLE_COLUMNS: ColumnsType<SalesChannelOrder> = [
    {
      fixed: 'left',
      title: () => {
        return (
          <Checkbox
            checked={
              JSON.stringify(selectedIds.sort()) ===
              JSON.stringify(
                (store.searchResultContainer.contents || [])
                  .filter((order) => !!order)
                  .map((order) => order._id)
                  .sort(),
              )
            }
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedIds(
                  (store.searchResultContainer.contents || []).filter((order) => !!order).map((order) => order._id),
                );
              } else {
                setSelectedIds([]);
              }
            }}></Checkbox>
        );
      },
      dataIndex: '_id',
      width: 60,
      render: (_id) => {
        return (
          <Checkbox
            onChange={(e) => {
              setSelectedIds((values) => {
                const checked = e.target.checked;
                const newValues = [...values];
                if (checked) {
                  if (!newValues.includes(_id)) {
                    newValues.push(_id);
                  }
                  return newValues;
                } else {
                  return newValues.filter((value) => value !== _id);
                }
              });
            }}
            checked={selectedIds.includes(_id)}></Checkbox>
        );
      },
    },
    {
      fixed: 'left',
      title: 'ID',
      render: (record: SalesChannelOrder) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(record?._id);
                toast.dark('ID를 클립보드에 복사했습니다', {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  pauseOnFocusLoss: false,
                  draggable: true,
                  progress: undefined,
                  bodyStyle: {
                    color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                    textAlign: 'center',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                  },
                  theme: 'dark',
                });
              }}>
              ID
            </Button>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(record?.uid);
                toast.dark('상품주문번호를 클립보드에 복사했습니다', {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  pauseOnFocusLoss: false,
                  draggable: true,
                  progress: undefined,
                  bodyStyle: {
                    color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                    textAlign: 'center',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                  },
                  theme: 'dark',
                });
              }}>
              상품주문번호
            </Button>
          </div>
        );
      },
    },
    {
      fixed: 'left',
      title: '구분',
      dataIndex: 'channel',
      render: (channel: SalesChannelsType) => {
        let channelName = '';
        if (channel === SalesChannelsType.INBOUND_APP) {
          channelName = '앱';
        } else if (channel === SalesChannelsType.INBOUND_NAVER) {
          channelName = '네이버';
        }
        return channelName;
      },
    },
    {
      fixed: 'left',
      align: 'center',
      title: '환불',
      width: 120,
      render: (record: SalesChannelOrder) => {
        return (
          <div>
            <div>{REFUND_STATUSES[record?.statuses?.refund] || '-'}</div>
            <div>{record?.memo?.refund}</div>
          </div>
        );
      },
    },
    {
      fixed: 'left',
      title: '구매자(수취인)',
      render: (record: SalesChannelOrder) => {
        let name = record?.buyer?.name;
        let phoneNumber = formatPhoneNumber(record?.buyer?.phone);
        if (record?.buyer?.name !== record?.receiver?.name) {
          name = `${record?.buyer?.name}(${record?.receiver?.name})`;
        }

        if (record?.buyer?.phone?.replace(/-/g, '') !== record?.receiver?.phone?.replace(/-/g, '')) {
          phoneNumber = `${formatPhoneNumber(record?.buyer?.phone)}(${formatPhoneNumber(record?.receiver?.phone)})`;
        }
        return `${name}\n${phoneNumber}`;
      },
    },
    {
      fixed: 'left',
      title: '정산일',
      render: (record: SalesChannelOrder) => {
        const settlementStatus = record?.statuses?.settlement;
        if (settlementStatus === 'done') {
          const transferAt = record?.settlement?.transferAt;
          return `${moment(transferAt).format('YYYY.MM.DD HH:mm')}`;
        }
        return '-';
      },
    },
    {
      title: '판매자',
      render: (record: SalesChannelOrder) => {
        return <div>{`${record?.seller?.name} ${record?.seller?.id ? '✅' : ''} (${record?.sellerUser?.role})`}</div>;
      },
    },
    {
      title: '상품명',
      width: 400,
      render: (record: SalesChannelOrder) => {
        return record?.product?.name;
      },
    },
    {
      title: '수업일',
      width: 112,
      render: (record: SalesChannelOrder) => {
        if (!record?.plannedDate) {
          return '-';
        }
        return moment(record?.plannedDate).format('YYYY.MM.DD HH:mm');
      },
    },
    {
      title: '수정',
      render: (record: SalesChannelOrder) => {
        return (
          <Button
            onClick={() => {
              setEditItem(record);
            }}>
            수정
          </Button>
        );
      },
    },
    {
      title: '옵션',
      render: (record: SalesChannelOrder) => {
        return record?.purchase?.name;
      },
    },
    {
      title: '메모',
      width: 240,
      render: (record: SalesChannelOrder) => {
        return record?.memo?.payment;
      },
    },
    {
      title: '고객 요청사항',
      width: 240,
      render: (record: SalesChannelOrder) => {
        return record?.memo?.shipping;
      },
    },
    {
      title: '수량',
      width: 80,
      render: (record: SalesChannelOrder) => {
        return record?.purchase?.count;
      },
    },
    {
      title: '실결제금액',
      width: 100,
      render: (record: SalesChannelOrder) => {
        return `${record?.payment?.billingAmount.toLocaleString()}원`;
      },
    },
    {
      title: '정산대상금액',
      width: 108,
      render: (record: SalesChannelOrder) => {
        return (
          <div>
            <div>{`${record?.payment?.amount.toLocaleString()}원`}</div>
            {record?.refund?.amount && record?.refund.amount > 0 && (
              <div style={{ color: 'red' }}>{`-${record?.refund?.amount.toLocaleString()}원`}</div>
            )}
            {record?.payment?.bankAccountTransferAmount && (
              <div
                style={{
                  color: '#424242',
                }}>{`(계좌이체: ${record?.payment?.bankAccountTransferAmount?.toLocaleString()}원)`}</div>
            )}
            {/* {record?.payment?.expectedAmount && (
              <div style={{ color: '#aaaaaa' }}>{`${record?.payment?.expectedAmount?.toLocaleString()}원`}</div>
            )} */}
          </div>
        );
      },
    },
    {
      title: '알림톡 발송',
      width: 280,
      render: (record: SalesChannelOrder) => {
        let confirmPurchaseNotificationDate = '-';
        let purchaseNotificationDate = '-';
        let refundNotificationDate = '-';
        if (record?.confirmPurchaseNotificationDate) {
          confirmPurchaseNotificationDate = moment(record?.confirmPurchaseNotificationDate).format('YYYY.MM.DD HH:mm');
        }

        if (record?.purchaseNotificationDate) {
          purchaseNotificationDate = moment(record?.purchaseNotificationDate).format('YYYY.MM.DD HH:mm');
        }

        if (record?.refundNotificationDate) {
          refundNotificationDate = moment(record?.refundNotificationDate).format('YYYY.MM.DD HH:mm');
        }

        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <div style={{ display: 'flex', gap: '8px' }}>
              <div>구매 확인(구매자)</div>
              <div>{confirmPurchaseNotificationDate}</div>
            </div>
            <div style={{ display: 'flex', gap: '8px' }}>
              <div>구매 알림(판매자)</div>
              <div>{purchaseNotificationDate}</div>
            </div>
            <div style={{ display: 'flex', gap: '8px' }}>
              <div>환불 알림(판매자)</div>
              <div>{refundNotificationDate}</div>
            </div>
          </div>
        );
      },
    },
    {
      title: '계좌이체 금액 추가',
      render: (record: SalesChannelOrder) => {
        return (
          <Button
            onClick={() => {
              addAccountTransferModalStore.open({
                _id: record._id,
                memo: record?.memo,
                payment: record?.payment,
                buyer: record?.buyer,
                product: record.product,
                channel: record.channel,
              });
            }}>
            추가
          </Button>
        );
      },
    },
    {
      title: '정산상태',
      render: (data: SalesChannelOrder) => {
        if (!data) {
          return '-';
        }
        const settlementStatus = (data.statuses?.settlement || '').toLowerCase();
        let status = '-';
        if (settlementStatus === 'done' && data?.settlement?.transferAt) {
          const transperAt = moment(data?.settlement?.transferAt).format('YYYY-MM-DD HH:mm');
          status = `정산완료\n(${transperAt})`;
        }
        return status;
      },
      width: 84,
    },
    {
      title: '결제일',
      width: 112,
      render: (record: SalesChannelOrder) => {
        if (!record?.payment?.paidAt) {
          return '-';
        }
        return moment(record?.payment?.paidAt).format('YYYY.MM.DD HH:mm');
      },
    },
    {
      title: '생성',
      dataIndex: 'createdAt',
      render: (createdAt) => {
        return createdAt ? moment(createdAt).format('YYYY.MM.DD HH:mm') : '-';
      },
    },
    {
      title: '업데이트',
      dataIndex: 'updatedAt',
      render: (updatedAt) => {
        return updatedAt ? moment(updatedAt).format('YYYY.MM.DD HH:mm') : '-';
      },
    },
  ];

  const getExcelFile = async (type: 'default' | 'settlement' = 'default') => {
    const fileName = `igogo-naver-${type}-${moment().format('YYYYMMDD-HHmmss')}.xlsx`;
    let blob = undefined;

    if (type === 'settlement') {
      blob = await api.getSalesChannelExcelFileForSettlement(store.searchQuery);
    } else {
      blob = await api.getSalesChannelExcelFile(store.searchQuery);
    }

    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
      (window.navigator as any).msSaveOrOpenBlob(new Blob(blob.data), fileName);
    } else {
      const url = window.URL.createObjectURL(blob.data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode && link.parentNode.removeChild(link);
    }
  };

  const onCreateBankAccountTransferOrder = () => {
    const newSalesOrder: Partial<SalesChannelOrder> = {
      channel: newOrder?.channel,
      seller: newOrder?.seller,
      buyer: newOrder?.buyer,
      product: newOrder?.product,
      purchase: newOrder?.purchase,
      payment: {
        method: 'bank_account_transfer',
        paidAt: new Date(),
        amount: newOrder?.purchase?.fee || 0,
        billingAmount: 0,
        bankAccountTransferAmount: newOrder?.purchase?.fee || 0,
      },
      memo: newOrder?.memo,
      statuses: {
        payment: 'done',
        refund: 'none',
        settlement: 'none',
      },
    };
    // console.log('NEW___ORDER____', newSalesOrder);
    // return;
    createOrderMutation.mutate(newSalesOrder);
  };

  return (
    <div className="dashboard" style={{ position: 'relative' }}>
      <Modal
        width={600}
        open={!!editItem}
        onCancel={() => {
          setEditItem(undefined);
        }}
        footer={
          <div>
            <Button
              onClick={() => {
                setEditItem(undefined);
              }}>
              취소
            </Button>
            <Button
              style={{ color: 'red' }}
              onClick={() => {
                if (editItem) {
                  updateInboundOrderMutaion.mutate({
                    channel: 'inbound',
                    targetIds: [editItem._id],
                    data: {
                      memo: editItem.memo,
                      statuses: editItem.statuses,
                      receiver: editItem.receiver,
                      buyer: editItem.buyer,
                      refund: editItem.refund,
                      payment: editItem.payment,
                      purchase: editItem.purchase,
                      product: editItem.product,
                    },
                  });
                }
              }}>
              저장하기
            </Button>
          </div>
        }>
        <div>
          <div style={{ height: '44px', fontSize: '16px', fontWeight: 'bold' }}>판매 내역 수정</div>
          <div style={{ marginTop: '8px' }}>
            <div style={{ fontWeight: 'bold' }}>구매자</div>
            <div style={{ display: 'flex', gap: '8px' }}>
              <Input
                style={{ flex: 1 }}
                value={editItem?.buyer.name}
                onChange={(e) => {
                  setEditItem((prev) => {
                    return { ...prev, buyer: { ...prev?.buyer, name: e.target.value } } as SalesChannelOrder;
                  });
                }}></Input>
              <Input
                style={{ flex: 2 }}
                value={editItem?.buyer.phone}
                onChange={(e) => {
                  setEditItem((prev) => {
                    return { ...prev, buyer: { ...prev?.buyer, phone: e.target.value } } as SalesChannelOrder;
                  });
                }}></Input>
            </div>
          </div>
          <div style={{ marginTop: '8px' }}>
            <div style={{ fontWeight: 'bold' }}>수취인</div>
            <div style={{ display: 'flex', gap: '8px' }}>
              <Input
                style={{ flex: 1 }}
                value={editItem?.receiver.name}
                onChange={(e) => {
                  setEditItem((prev) => {
                    return { ...prev, receiver: { ...prev?.receiver, name: e.target.value } } as SalesChannelOrder;
                  });
                }}></Input>
              <Input
                style={{ flex: 2 }}
                value={editItem?.receiver.phone}
                onChange={(e) => {
                  setEditItem((prev) => {
                    return { ...prev, receiver: { ...prev?.receiver, phone: e.target.value } } as SalesChannelOrder;
                  });
                }}></Input>
            </div>
          </div>
          <div style={{ marginTop: '8px' }}>
            <div style={{ fontWeight: 'bold' }}>상품명</div>
            <div style={{ display: 'flex', gap: '8px' }}>
              <Input
                style={{ flex: 1 }}
                value={editItem?.product.name}
                onChange={(e) => {
                  setEditItem((prev) => {
                    return { ...prev, product: { ...prev?.product, name: e.target.value } } as SalesChannelOrder;
                  });
                }}></Input>
            </div>
          </div>
          <div style={{ marginTop: '8px' }}>
            <div style={{ fontWeight: 'bold' }}>메모</div>
            <Input.TextArea
              value={editItem?.memo.payment}
              onChange={(e) => {
                setEditItem((prev) => {
                  return { ...prev, memo: { ...prev?.memo, payment: e.target.value } } as SalesChannelOrder;
                });
              }}></Input.TextArea>
          </div>
          <div style={{ marginTop: '8px' }}>
            <div style={{ fontWeight: 'bold' }}>고객 요청사항</div>
            <Input.TextArea
              value={editItem?.memo.shipping}
              onChange={(e) => {
                setEditItem((prev) => {
                  return { ...prev, memo: { ...prev?.memo, shipping: e.target.value } } as SalesChannelOrder;
                });
              }}></Input.TextArea>
          </div>
          <div style={{ marginTop: '8px' }}>
            <div style={{ fontWeight: 'bold' }}>정산대상금액</div>
            <Input
              value={editItem?.payment.amount}
              onChange={(e) => {
                setEditItem((prev) => {
                  return {
                    ...prev,
                    payment: { ...prev?.payment, amount: Number(e.target.value) || 0 },
                  } as SalesChannelOrder;
                });
              }}></Input>
          </div>
          <div style={{ marginTop: '8px' }}>
            <div style={{ fontWeight: 'bold' }}>옵션</div>
            <div style={{ display: 'flex', gap: '8px' }}>
              <Input
                style={{ flex: 9 }}
                value={editItem?.purchase.name}
                onChange={(e) => {
                  setEditItem((prev) => {
                    return {
                      ...prev,
                      purchase: { ...prev?.purchase, name: e.target.value },
                    } as SalesChannelOrder;
                  });
                }}></Input>
              <Input
                style={{ flex: 1 }}
                value={editItem?.purchase.count}
                onChange={(e) => {
                  setEditItem((prev) => {
                    return {
                      ...prev,
                      purchase: { ...prev?.purchase, count: Number(e.target.value) || 0 },
                    } as SalesChannelOrder;
                  });
                }}></Input>
            </div>
          </div>
          <div style={{ marginTop: '8px' }}>
            <div style={{ fontWeight: 'bold' }}>환불</div>
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
              <div style={{ width: '40px', flexShrink: 0 }}>상태</div>
              <Select
                style={{ width: '120px' }}
                value={editItem?.statuses.refund}
                options={[
                  { value: 'none', label: '없음' },
                  { value: 'all', label: '전체 환불' },
                  { value: 'partial', label: '부분 환불' },
                  { value: 'requested', label: '환불 요청' },
                  { value: 'accepted', label: '환불 접수' },
                  { value: 'denied', label: '환불 반려' },
                ]}
                onChange={(value) => {
                  setEditItem((prev) => {
                    return { ...prev, statuses: { ...prev?.statuses, refund: value } } as SalesChannelOrder;
                  });
                }}></Select>
            </div>
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center', marginTop: '8px' }}>
              <div style={{ width: '40px', flexShrink: 0 }}>금액</div>
              <Input
                value={editItem?.refund?.amount}
                onChange={(e) => {
                  setEditItem((prev) => {
                    return {
                      ...prev,
                      refund: { ...prev?.refund, amount: Number(e.target.value) || 0 },
                    } as SalesChannelOrder;
                  });
                }}></Input>
            </div>
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center', marginTop: '8px' }}>
              <div style={{ width: '40px', flexShrink: 0 }}>메모</div>
              <Input.TextArea
                value={editItem?.memo.refund}
                onChange={(e) => {
                  setEditItem((prev) => {
                    return { ...prev, memo: { ...prev?.memo, refund: e.target.value } } as SalesChannelOrder;
                  });
                }}></Input.TextArea>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        width={600}
        open={showChangeStatus}
        onCancel={() => {
          setShowChangeStatus(false);
        }}
        footer={
          <div>
            <Button
              onClick={() => {
                setShowChangeStatus(false);
              }}>
              취소
            </Button>
            <Button
              style={{ color: 'red' }}
              onClick={() => {
                updateInboundOrderMutaion.mutate({
                  channel: 'inbound',
                  targetIds: selectedIds,
                  data: {
                    statuses: { payment: 'done', refund: selectedChangeStatus },
                  },
                });
              }}>
              변경하기
            </Button>
          </div>
        }>
        <div>
          <div style={{ height: '44px' }}>환불 상태 일괄 변경</div>
          <Select
            style={{ width: '120px' }}
            value={selectedChangeStatus || 'none'}
            options={[
              { value: 'none', label: '없음' },
              { value: 'all', label: '전체 환불' },
              { value: 'partial', label: '부분 환불' },
              { value: 'requested', label: '환불 요청' },
              { value: 'accepted', label: '환불 접수' },
              { value: 'denied', label: '환불 반려' },
            ]}
            onChange={(value) => {
              setSelectedChangeStatus(value);
            }}></Select>
        </div>
      </Modal>
      <Modal
        width={600}
        open={showSelectPartner}
        onCancel={() => {
          setShowSelectPartner(false);
        }}
        footer={
          <div>
            <Button
              onClick={() => {
                setShowSelectPartner(false);
              }}>
              취소
            </Button>
            <Button
              style={{ color: 'red' }}
              onClick={() => {
                if (!selectedPartner) {
                  dialog.open({ type: 'ok', title: '알림', content: '판매자를 선택해주세요' });
                  return;
                }

                updateInboundOrderMutaion.mutate({
                  channel: 'inbound',
                  targetIds: selectedIds,
                  data: {
                    seller: {
                      id: selectedPartner?._id,
                      phone: selectedPartner?.profile?.phone,
                      name: selectedPartner?.profile?.name,
                    },
                  },
                });
              }}>
              변경하기
            </Button>
          </div>
        }>
        <div>
          <div style={{ height: '44px' }}>판매자 선택 및 변경</div>
          <SelectUser
            style={{ width: '528px' }}
            selectedUserId={selectedPartner?._id}
            onSelect={(partner) => {
              setSelectedPartner(partner);
            }}></SelectUser>
        </div>
      </Modal>
      {showCreateOrder && (
        <Modal
          width={'480px'}
          open={showCreateOrder}
          footer={
            <div>
              <Button
                onClick={() => {
                  onCreateBankAccountTransferOrder();
                }}>
                생성
              </Button>
              <Button
                onClick={() => {
                  setShowCreateOrder(false);
                }}>
                취소
              </Button>
            </div>
          }
          onCancel={() => {
            setShowCreateOrder(false);
            inboundOrdersQuery.refetch();
          }}
          onOk={() => {
            setShowCreateOrder(false);
            inboundOrdersQuery.refetch();
          }}>
          <div>
            <div style={{ fontWeight: 'bold', fontSize: '16px' }}>결제내역 생성(계좌이체)</div>
            <div
              style={{
                padding: '12px 0',
                display: 'flex',
                flexDirection: 'column',
                gap: '12px',
                alignItems: 'flex-start',
              }}>
              <div>
                <div style={{ fontWeight: 'bold' }}>결제 플랫폼 선택</div>
                <Select
                  placeholder="선택"
                  value={newOrder?.channel}
                  style={{ width: 120 }}
                  onChange={(value) => {
                    setNewOrder((prev) => {
                      return { ...prev, channel: value };
                    });
                  }}
                  options={[
                    { value: 'inbound-app', label: '앱' },
                    { value: 'inbound-naver', label: '네이버' },
                  ]}
                />
              </div>
              <div>
                <div style={{ fontWeight: 'bold' }}>판매방식 선택</div>
                <Select
                  placeholder="선택"
                  value={newOrder?.product?.saleType}
                  style={{ width: 120 }}
                  onChange={(value) => {
                    setNewOrder((prev) => {
                      const newProduct = {
                        ...prev.product,
                        saleType: value,
                        name: prev?.product?.name || '',
                      };
                      return { ...prev, product: newProduct };
                    });
                  }}
                  options={[
                    { value: 'commerce', label: '커머스' },
                    { value: 'class', label: '클래스' },
                  ]}
                />
              </div>
              <div style={{ width: '100%' }}>
                <div style={{ fontWeight: 'bold' }}>파트너 선택</div>
                <SelectUser
                  onSelect={(partner) => {
                    setNewOrder((prev) => {
                      const seller = {
                        id: partner?._id,
                        name: partner?.profile?.nickname,
                        phone: partner?.profile?.phone,
                      };
                      const newValue = { ...prev, seller };
                      delete newValue['product'];
                      return newValue;
                    });
                  }}></SelectUser>
              </div>
              <div style={{ width: '100%' }}>
                <div style={{ fontWeight: 'bold' }}>
                  구매자 선택
                  <Checkbox
                    style={{ marginLeft: '10px' }}
                    checked={manualBuyerInput}
                    onChange={(e) => {
                      setManualBuyerInput(e.target.checked);
                    }}>
                    직접입력
                  </Checkbox>
                </div>
                {!manualBuyerInput ? (
                  <SelectParent
                    onSelect={(parent) => {
                      setNewOrder((prev) => {
                        const buyer = {
                          id: parent?._id,
                          name: parent?.profile?.name || '',
                          phone: parent?.profile?.phone || '',
                        };
                        return { ...prev, buyer };
                      });
                    }}></SelectParent>
                ) : (
                  <div>
                    <div>
                      이름:
                      <TextInput
                        onChangeValue={(value) => {
                          setNewOrder((prev) => {
                            const newBuyer = {
                              ...prev.buyer,
                              name: value,
                              phone: prev?.buyer?.phone || '',
                            };
                            return { ...prev, buyer: newBuyer };
                          });
                        }}></TextInput>
                    </div>
                    <div>
                      연락처:
                      <TextInput
                        onChangeValue={(value) => {
                          setNewOrder((prev) => {
                            const newBuyer = {
                              ...prev.buyer,
                              phone: value,
                              name: prev?.buyer?.name || '',
                            };
                            return { ...prev, buyer: newBuyer };
                          });
                        }}></TextInput>
                    </div>
                  </div>
                )}
              </div>
              <div style={{ width: '100%' }}>
                <div style={{ fontWeight: 'bold' }}>
                  상품 선택
                  <Checkbox
                    style={{ marginLeft: '10px' }}
                    checked={manualProductInput}
                    onChange={(e) => {
                      setManualProductInput(e.target.checked);
                    }}>
                    직접입력
                  </Checkbox>
                </div>
                {!manualProductInput ? (
                  newOrder?.product?.name ? (
                    <div>
                      <div>선택된 상품: {newOrder?.product?.name}</div>
                      <Button
                        disabled={!!newOrder?.seller?.id}
                        onClick={() =>
                          setNewOrder((prev) => {
                            const newValue = { ...prev };
                            delete newValue['product'];
                            return newValue;
                          })
                        }>
                        삭제
                      </Button>
                    </div>
                  ) : (
                    <Button
                      onClick={() => {
                        if (newOrder?.seller?.name) {
                          setShowProductSelect(true);
                        }
                      }}>
                      선택
                    </Button>
                  )
                ) : (
                  <div>
                    상품 이름:
                    <TextInput
                      onChangeValue={(value) => {
                        setNewOrder((prev) => {
                          return { ...prev, product: { ...prev.product, name: value } };
                        });
                      }}></TextInput>
                  </div>
                )}
                {newOrder?.seller?.name && showProductSelect && (
                  <SelectProductModal
                    selectLimit={1}
                    sellerId={newOrder?.seller?.id}
                    open={showProductSelect}
                    onAddProducts={(products) => {
                      setNewOrder((prev) => {
                        return {
                          ...prev,
                          product: { id: products[0]._id, name: products[0].title, saleType: products[0].saleType },
                        };
                      });
                    }}
                    onClose={() => {
                      setShowProductSelect(false);
                    }}></SelectProductModal>
                )}
                <div style={{ width: '100%', marginTop: '12px' }}>
                  <div style={{ fontWeight: 'bold' }}>
                    상품옵션 입력(수업일 포함) 예시: ( 삼국시대 / 11/30(토) / 15:30 )
                    <div style={{ color: 'red', fontSize: '13px' }}>
                      * 수업일은 하루만 입력 가능합니다. 여러날이 입력될 경우 마지막 날짜만 유효합니다.
                    </div>
                  </div>
                  <TextInput
                    placeholder="ex) 삼국시대 / 11/30(토) / 15:30"
                    onChangeValue={(value) => {
                      setNewOrder((prev) => {
                        const newPurchase = {
                          ...prev?.purchase,
                          name: `${value}`,
                          fee: prev?.purchase?.fee || 0,
                          count: 1,
                        };
                        return { ...prev, purchase: newPurchase };
                      });
                    }}></TextInput>
                </div>
                <div style={{ width: '100%' }}>
                  <div style={{ fontWeight: 'bold' }}>금액 입력</div>
                  <TextInput
                    type="number"
                    placeholder="숫자만 입력해주세요."
                    onChangeValue={(value) => {
                      setNewOrder((prev) => {
                        const newPurchase = {
                          ...prev?.purchase,
                          name: prev?.purchase?.name || '',
                          fee: Number(value),
                          count: 1,
                        };
                        return { ...prev, purchase: newPurchase };
                      });
                    }}></TextInput>
                </div>
                <div style={{ width: '100%' }}>
                  <div style={{ fontWeight: 'bold' }}>메모</div>
                  <TextInput
                    onChangeValue={(value) => {
                      setNewOrder((prev) => {
                        return { ...prev, memo: { ...prev?.memo, payment: `${value}` } };
                      });
                    }}></TextInput>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
      <div className="page-header">
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <div>인바운드 판매 관리</div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <div>
            <Button
              style={{ width: '160px', marginRight: '50px' }}
              onClick={() => {
                setShowCreateOrder(true);
              }}>
              계좌이체 결제내역 생성
            </Button>
            <Button
              style={{ borderColor: store.searchQuery.dateType === 'payment.paidAt' ? 'blue' : '#d9d9d9' }}
              onClick={() => {
                store.updateSearchQuery({ dateType: 'payment.paidAt' });
              }}>
              결제일
            </Button>
          </div>
          <div>
            <Button
              style={{ borderColor: store.searchQuery.dateType === 'createdAt' ? 'blue' : '#d9d9d9' }}
              onClick={() => {
                store.updateSearchQuery({ dateType: 'createdAt' });
              }}>
              생성일
            </Button>
          </div>
          <div>
            <Button
              style={{ borderColor: store.searchQuery.dateType === 'updatedAt' ? 'blue' : '#d9d9d9' }}
              onClick={() => {
                store.updateSearchQuery({ dateType: 'updatedAt' });
              }}>
              수정일
            </Button>
          </div>
          <div>
            <Button
              style={{ borderColor: store.searchQuery.dateType === 'plannedDate' ? 'blue' : '#d9d9d9' }}
              onClick={() => {
                store.updateSearchQuery({ dateType: 'plannedDate' });
              }}>
              수업일
            </Button>
          </div>
          <div style={{ marginLeft: '10px' }}>
            <DatePicker
              onChange={onChangeStartDate}
              value={dayjs(utcTimcToLocalISOString(store.searchQuery.startDate as any))}
            />
          </div>
          <div style={{ marginLeft: '8px' }}>
            <DatePicker
              onChange={onChangeEndDate}
              value={dayjs(utcTimcToLocalISOString(store.searchQuery.endDate as any))}
            />
          </div>
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <div className="page-content" style={{ width: '100%', height: 'calc(100vh - 80px)', overflow: 'auto' }}>
          <div style={{ display: 'flex', gap: '12px', width: '100%', height: '52px', alignItems: 'center' }}>
            <Select
              style={{ width: '120px' }}
              value={store.searchQuery.refundStatus || 'all_result'}
              options={[
                { value: 'all_result', label: '모든 상태' },
                { value: 'none', label: '없음' },
                { value: 'all', label: '전체 환불' },
                { value: 'partial', label: '부분 환불' },
                { value: 'requested', label: '환불 요청' },
                { value: 'accepted', label: '환불 접수' },
                { value: 'denied', label: '환불 반려' },
              ]}
              onChange={(value) => {
                store.updateSearchQuery({ refundStatus: value });
              }}></Select>
            <Select
              defaultValue={store.searchQuery.settlementStatus || ''}
              style={{ width: '120px', marginRight: '10px' }}
              onChange={(value) => {
                store.updateSearchQuery({ settlementStatus: value });
              }}
              options={[
                { value: '', label: '전체' },
                { value: PaymentStatementSettlementStatus.DONE, label: '정산완료' },
              ]}
            />
            <Select
              style={{ width: '240px' }}
              value={store.searchQuery.type || 'all'}
              options={[
                { value: 'all', label: '모두' },
                { value: 'none', label: '발송내역 없음' },
                { value: 'confirm_purchase_send', label: '구매 확인 알림 발송(구매자)' },
                { value: 'confirm_purchase_not_send', label: '구매 확인 알림 발송 X(구매자)' },
                { value: 'purchase_send', label: '구매 알림 발송' },
                { value: 'purchase_not_send', label: '구매 알림 발송 X' },
                { value: 'refund_send', label: '환불 알림 발송' },
                { value: 'refund_not_send', label: '환불 알림 발송 X' },
                { value: 'payment_amount_zero', label: '정산대상 금액 0원' },
                { value: 'payment_diff_500', label: '정산대상 금액 차이 500원 초과' },
                { value: 'seller_id_null', label: '판매자 매칭 안됨' },
                { value: 'invalid_phone_number', label: '전화번호 없음' },
              ]}
              onChange={(value) => {
                store.updateSearchQuery({ type: value });
              }}></Select>
            <Select
              placeholder={'검색 타입'}
              style={{ width: '140px' }}
              value={store.searchQuery.keywordType || 'all'}
              options={[
                { value: 'all', label: '전체' },
                { value: 'buyer.phone', label: '구매자 전화번호' },
                { value: 'buyer.name', label: '구매자 이름' },
                { value: 'receiver.phone', label: '수취인 전화번호' },
                { value: 'receiver.name', label: '수취인 이름' },
                { value: 'product.name', label: '상품 이름' },
                { value: 'purchase.name', label: '옵션 이름' },
                { value: 'seller.name', label: '판매자 이름' },
                { value: 'memo.refund', label: '환불 메모' },
                { value: 'memo.payment', label: '구매 메모' },
                { value: 'memo.shipping', label: '배송 메모' },
                { value: 'payment.amount', label: '정산대상금액' },
              ]}
              onChange={(value) => {
                store.updateSearchQuery({ keywordType: value });
              }}></Select>
            <Input
              ref={searchInputRef}
              style={{ width: '240px' }}
              value={searchKeyword}
              placeholder={'검색어 입력 후 엔터'}
              onChange={(e) => {
                setSearchKeyword(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.code === 'Enter' && searchKeyword) {
                  store.updateSearchQuery({ keyword: searchKeyword });
                }
              }}></Input>
            <Button
              onClick={() => {
                setSelectedIds([]);
                setSearchKeyword(undefined);
                store.resetSearchQuery();
              }}>
              검색 초기화
            </Button>
            <div>{`결과: ${store.searchResultContainer?.total || 0}개`}</div>
            <div style={{ display: 'flex', marginLeft: 'auto', gap: '12px', alignItems: 'center' }}>
              <div style={{ display: 'flex', gap: '8px', alignItems: 'center', fontSize: '14px' }}>
                <div style={{ width: '72px', textAlign: 'right', fontSize: '12px', lineHeight: '12px' }}>
                  검색결과 엑셀 파일 다운로드
                </div>
                <Button
                  onClick={() => {
                    getExcelFile();
                  }}>
                  기본
                </Button>
                <Button
                  onClick={() => {
                    getExcelFile('settlement');
                  }}>
                  정산용
                </Button>
              </div>
            </div>
          </div>
          {/* <div style={{ display: 'flex', gap: '12px', alignItems: 'center', marginBottom: '12px' }}>
            <Button
              disabled={sendKakaoNotificationMutation.isPending}
              onClick={() => {
                dialog.open({
                  title: '판매내역 알림톡 발송 확인',
                  content: `24시간 이내 판매된 판매내역 요약 알림톡을 전송합니다.`,
                  type: 'ok_cancel',
                  onConfirm: () => {
                    sendNaverOrdersSummaryKakaoNotificationMutation.mutate();
                  },
                });
              }}>
              판매 요약 알림톡 발송
            </Button>
            <Button
              disabled={sendKakaoNotificationMutation.isPending}
              onClick={() => {
                dialog.open({
                  title: '모든 환불내역 알림톡 발송 확인',
                  content: (
                    <div
                      style={{
                        color: 'red',
                      }}>{`환불 상태(전체/부분)인 항목 중 알림톡을 발송하지 않은 항목 모두 환불 알림톡을 발송합니다.`}</div>
                  ),
                  type: 'ok_cancel',
                  onConfirm: () => {
                    sendRefundsKakaoNotificationMutation.mutate();
                  },
                });
              }}>
              환불내역 알림톡 발송
            </Button>
            <Button
              disabled={updatePaymentAmountMutation.isPending}
              onClick={() => {
                updatePaymentAmountMutation.mutate();
              }}>
              정산대상 금액 업데이트
            </Button>
          </div> */}
          {selectedIdsCount > 0 && (
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center', marginBottom: '12px' }}>
              <div>{`${selectedIdsCount}개 선택에 대해`} </div>
              <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                <Button
                  onClick={() => {
                    setShowChangeStatus(true);
                  }}>
                  상태 변경
                </Button>
                <Button
                  onClick={() => {
                    setShowSelectPartner(true);
                  }}>
                  판매자 변경
                </Button>
                <Button
                  disabled={sendKakaoNotificationMutation.isPending}
                  onClick={() => {
                    dialog.open({
                      title: '판매 확인 알림톡 발송 확인',
                      content: `선택한 항목 ${selectedIdsCount}개에 판매 확인 알림톡을 전송합니다.`,
                      type: 'ok_cancel',
                      onConfirm: () => {
                        sendKakaoNotificationMutation.mutate({
                          targetIds: selectedIds,
                          templateId: 'igogo_naver_confirm_naver_purchase',
                        });
                      },
                    });
                  }}>
                  판매 확인 알림톡 발송(구매자)
                </Button>
                <Button
                  disabled={sendKakaoNotificationMutation.isPending}
                  onClick={() => {
                    dialog.open({
                      title: '판매 알림톡 발송 확인',
                      content: `선택한 항목 ${selectedIdsCount}개에 판매 알림톡을 전송합니다.`,
                      type: 'ok_cancel',
                      onConfirm: () => {
                        sendKakaoNotificationMutation.mutate({
                          targetIds: selectedIds,
                          templateId: 'igogopush_purchase_naver_store',
                        });
                      },
                    });
                  }}>
                  판매 알림톡 발송
                </Button>
                <Button
                  disabled={sendKakaoNotificationMutation.isPending}
                  onClick={() => {
                    dialog.open({
                      title: '환불 알림톡 발송 확인',
                      content: (
                        <div
                          style={{
                            color: 'red',
                          }}>{`선택한 항목 ${selectedIdsCount}개에 환불 알림톡을 전송합니다.`}</div>
                      ),
                      type: 'ok_cancel',
                      onConfirm: () => {
                        sendKakaoNotificationMutation.mutate({
                          targetIds: selectedIds,
                          templateId: 'igogopush_refund_naver_store',
                        });
                      },
                    });
                  }}>
                  환불 알림톡 발송
                </Button>
                <Button
                  style={{ color: 'red' }}
                  disabled={deleteOrdersMutation.isPending}
                  onClick={() => {
                    dialog.open({
                      title: '삭제 확인',
                      content: (
                        <div
                          style={{
                            color: 'red',
                          }}>{`선택한 항목 ${selectedIdsCount}개를 삭제합니다.`}</div>
                      ),
                      type: 'ok_cancel',
                      onConfirm: () => {
                        deleteOrdersMutation.mutate(selectedIds.join(','));
                      },
                    });
                  }}>
                  삭제
                </Button>
                <Button
                  onClick={() => {
                    setSelectedIds([]);
                  }}>
                  선택 해제
                </Button>
              </div>
            </div>
          )}
          <Table
            columns={TABLE_COLUMNS}
            scroll={{ x: 'max-content', y: 'calc(100dvh - 380px)' }}
            style={{ whiteSpace: 'pre-wrap' }}
            rowKey={(record) => record?._id}
            dataSource={store.searchResultContainer.contents.filter((product) => product?._id)}
            pagination={{
              current: (store.searchResultContainer.page || 0) + 1,
              pageSize: store.searchResultContainer.size || 20,
              total: store.searchResultContainer.total,
            }}
            loading={inboundOrdersQuery.isLoading}
            onChange={(e) => {
              store.updateSearchQuery({ page: (e.current || 2) - 1, size: e.pageSize || 20 });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default InboundOrdersPage;
