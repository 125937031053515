import { lazy } from 'react';

export const Dashboard = lazy(() => import('./Dashboard'));
// export const ClassTagPage = lazy(() => import('./Class/ClassTagPage'));
// export const ClassAttendancePage = lazy(() => import('./Class/ClassAttendancePage'));
// export const ClassDetailPage = lazy(() => import('./Class/ClassDetailPage'));
// export const ClassPage = lazy(() => import('./Class/ClassPage'));
// export const CommerceProductCreatePage = lazy(() => import('./Commerce/CommerceProductCreatePage'));
// export const CommerceProductExtraPage = lazy(() => import('./Commerce/CommerceProductExtraPage'));
// export const CommerceSellerPage = lazy(() => import('./Commerce/CommerceSellerPage'));
// export const CommerceCurationEditPage = lazy(() => import('./Commerce/CommerceCurationEditPage'));
// export const CommerceCurationPage = lazy(() => import('./Commerce/CommerceCurationPage'));
// export const CommerceProductPage = lazy(() => import('./Commerce/CommerceProductPage'));
// export const CommerceCurationCreatePage = lazy(() => import('./Commerce/CommerceCurationCreatePage'));
// export const CommerceSearchOrderPage = lazy(() => import('./Commerce/CommerceSearchOrderPage'));
// export const DefaultPolicyPage = lazy(() => import('./Commerce/DefaultPolicyPage'));
// export const CommerceProductEditPage = lazy(() => import('./Commerce/CommerceProductEditPage'));
// export const CommerceCategoryPage = lazy(() => import('./Commerce/CommerceCategoryPage'));
// export const CommercePurchaseHistoryPage = lazy(() => import('./Commerce/CommercePurchaseHistoryPage'));
// export const CommerceBannerPage = lazy(() => import('./Commerce/CommerceBannerPage'));
// export const ClassWaitingListPage = lazy(() => import('./CustomerService/ClassWaitingListPage'));
// export const ChatListPage = lazy(() => import('./CustomerService/ChatListPage'));
// export const ClassOpenRequestAreaPage = lazy(() => import('./CustomerService/ClassOpenRequestAreaPage'));
// export const PushNotificationPage = lazy(() => import('./CustomerService/PushNotificationPage'));
// export const CurationCategoryPage = lazy(() => import('./MainFeed/CurationCategoryPage'));
// export const CurationPage = lazy(() => import('./MainFeed/CurationPage'));
// export const DataDownloadPage = lazy(() => import('./MainFeed/DataDownloadPage'));
// export const CurationItemPage = lazy(() => import('./MainFeed/CurationItemPage'));
// export const CurationBannerPage = lazy(() => import('./MainFeed/CurationBannerPage'));
// export const ShortcutPage = lazy(() => import('./MainFeed/ShortcutPage'));
// export const SearchOrderPage = lazy(() => import('./MainFeed/SearchOrderPage'));
// export const SearchKeywordPage = lazy(() => import('./MainFeed/SearchKeywordPage'));
// export const NoticePage = lazy(() => import('./Notice/NoticePage'));
// export const PurchaseHistoryPage = lazy(() => import('./PayrollSettlement/PurchaseHistoryPage'));
// export const TutorPayrollSettlementPage = lazy(() => import('./PayrollSettlement/TutorPayrollSettlementPage'));
// export const ReportQuestionPage = lazy(() => import('./Report/ReportQuestionPage'));
// export const ReportPage = lazy(() => import('./Report/ReportPage'));
// export const ReviewPage = lazy(() => import('./Review/ReviewPage'));
// export const CouponPage = lazy(() => import('./Reward/CouponPage'));
// export const CreditPointPage = lazy(() => import('./Reward/CreditPointPage'));
// export const ScheduleEntirePage = lazy(() => import('./Schedule/ScheduleEntirePage'));
// export const ScheduleTutorPage = lazy(() => import('./Schedule/ScheduleTutorPage'));
// export const UsersPage = lazy(() => import('./User/UsersPage'));
// export const UserDetailPage = lazy(() => import('./User/UserDetailPage'));
