import {
  ClassChangePurchaseItem,
  CommerceChangePurchasesItem,
  PaymentStatementMemo,
  PaymentStatementPurchase,
} from '@models/paymentStatement';
import { create } from 'zustand';

export interface ChangeScheduleModalData {
  type: 'class' | 'commerce';
  paymentStatementId: string;
  productName: string;
  buyerName: string;
  purchases: PaymentStatementPurchase[];
  paidAmount: number;
  memo?: PaymentStatementMemo;
  paidAt: Date;
  productId: string;
  classInvoiceId?: string;
}
interface State {
  data?: ChangeScheduleModalData;
  result?: ClassChangePurchaseItem[] | CommerceChangePurchasesItem[];
  show: boolean;
}
interface Actions {
  open: (data: ChangeScheduleModalData) => void;
  close: () => void;
  onChange: (data: ClassChangePurchaseItem[] | CommerceChangePurchasesItem[]) => void;
}

const changeScheduleModalStore = create<State & Actions>((set) => ({
  show: false,
  data: undefined,
  result: undefined,
  open: (data) => {
    set({ data: data, show: true });
  },
  close: () => {
    set({ show: false, result: [] });
  },
  onChange: (data) => {
    set({ result: data });
  },
}));

export default changeScheduleModalStore;
