import HomePopupBannerEdit from '@components/feed/HomePopupBannerEdit';
import { useAuthentication } from '@hooks/authentication';
import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import * as api from '@apis/feeds';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FeedData } from '@models/feed';
import { removeTempProperties } from '@utils/dataTransform';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import LineBannerEdit from '@components/feed/LineBannerEdit';

export const BannersPage: React.FC = () => {
  const [editMode, setEditMode] = useState<string>('home-popup');
  const [homePopupData, setHomePopupData] = useState<Partial<FeedData>>();
  const [chatListBannersData, setChatListBannersData] = useState<Partial<FeedData>>();
  const [myPageBannersData, setMyPageBannersData] = useState<Partial<FeedData>>();
  const [modifiedFiles, setModifiedFiles] = useState<{ [key: string]: File }>({});
  const dialog = useOkCancelDialog();

  useAuthentication();

  const bannersQuery = useQuery({
    queryKey: [`banners`],
    queryFn: () => api.getBanners(),
  });

  const banners = bannersQuery.data?.data;

  const updateMainFeedMutation = useMutation({
    mutationFn: api.updateBanners,
    onSuccess: async () => {
      dialog.open({ type: 'ok', title: '알림', content: '배너를 수정했습니다' });
    },
    onError: async (e) => {
      dialog.open({ type: 'ok', title: '오류', content: JSON.stringify(e) });
    },
  });

  useEffect(() => {
    if (banners) {
      banners.data.forEach((feedData) => {
        if (feedData.type === 'home-popup') {
          setHomePopupData(feedData);
        } else if (feedData.type === 'chat-list') {
          setChatListBannersData(feedData);
        } else if (feedData.type === 'my-page') {
          setMyPageBannersData(feedData);
        }
      });
    }
  }, [banners]);

  return (
    <div className="dashboard" style={{ position: 'relative' }}>
      <div className="page-header">
        <div>배너 관리</div>
      </div>
      <div style={{ display: 'flex' }}>
        <div className="page-content" style={{ width: '100%', height: 'calc(100vh - 80px)', overflow: 'auto' }}>
          <div>
            <Button
              onClick={() => {
                const formData: FormData = new FormData();
                const json = {
                  ...banners,
                  data: [
                    { ...homePopupData, type: 'home-popup' },
                    { ...chatListBannersData, type: 'chat-list' },
                    { ...myPageBannersData, type: 'my-page' },
                  ],
                };
                json.data = removeTempProperties(json.data);

                formData.append('json', JSON.stringify(json));
                Object.values(modifiedFiles).forEach((file) => {
                  formData.append('files', file);
                });
                updateMainFeedMutation.mutate(formData);
              }}>
              저장
            </Button>
          </div>
          <div
            style={{
              display: 'flex',
              gap: '12px',
              width: '100%',
              height: '36px',
              alignItems: 'center',
              marginBottom: '16px',
            }}>
            <div>배너 관리 선택:</div>
            <Button
              style={{
                backgroundColor: editMode === 'home-popup' ? '#2e63b8' : 'white',
                color: editMode === 'home-popup' ? 'white' : '#2e63b8',
                fontWeight: 'bold',
              }}
              onClick={() => {
                setEditMode('home-popup');
              }}>
              홈 팝업 배너
            </Button>
            <Button
              style={{
                backgroundColor: editMode === 'chat-list' ? '#2e63b8' : 'white',
                color: editMode === 'chat-list' ? 'white' : '#2e63b8',
                fontWeight: 'bold',
              }}
              onClick={() => {
                setEditMode('chat-list');
              }}>
              채팅 목록
            </Button>
            <Button
              style={{
                backgroundColor: editMode === 'my-page' ? '#2e63b8' : 'white',
                color: editMode === 'my-page' ? 'white' : '#2e63b8',
                fontWeight: 'bold',
              }}
              onClick={() => {
                setEditMode('my-page');
              }}>
              마이페이지
            </Button>
          </div>
          <div>
            {editMode === 'home-popup' && (
              <HomePopupBannerEdit
                data={homePopupData}
                onChange={(data) => {
                  setHomePopupData(data);
                }}
                onChangeImageFile={(files) => {
                  setModifiedFiles((prev) => {
                    return { ...prev, ...files };
                  });
                }}></HomePopupBannerEdit>
            )}
            {editMode === 'chat-list' && (
              <LineBannerEdit
                mode={editMode}
                data={chatListBannersData}
                onChange={(data) => {
                  setChatListBannersData(data);
                }}
                onChangeImageFile={(files) => {
                  setModifiedFiles((prev) => {
                    return { ...prev, ...files };
                  });
                }}></LineBannerEdit>
            )}
            {editMode === 'my-page' && (
              <LineBannerEdit
                mode={editMode}
                data={myPageBannersData}
                onChange={(data) => {
                  setMyPageBannersData(data);
                }}
                onChangeImageFile={(files) => {
                  setModifiedFiles((prev) => {
                    return { ...prev, ...files };
                  });
                }}></LineBannerEdit>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannersPage;
