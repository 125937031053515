import HeartIcon from '@assets/images/app/icon-heart-outline-dark-home.png';
import NotificationIcon from '@assets/images/app/icon-notification-home.png';
import SearchIcon from '@assets/images/app/icon-search-home.png';
import AppLogoImage from '@assets/images/app/igogo_home.png';
import { useQuery } from '@tanstack/react-query';
import { shuffleArray } from '@utils/dataTransform';
import React, { useRef } from 'react';
import { Virtuoso } from 'react-virtuoso';
import CategoryCell from './main-feed/CategoryCell';
import ClassGeneralCell from './main-feed/ClassGeneralCell';
import ClassGridCell from './main-feed/ClassGridCell';
import ClassListCell from './main-feed/ClassListCell';
import CommerceCarouselCell from './main-feed/CommerceCarouselCell';
import FeedFooterCell from './main-feed/FeedFooterCell';
import PopularKeywordsCell from './main-feed/PopularKeywordsCell';
import SearchCell from './main-feed/SearchCell';
import ShortcutCell from './main-feed/ShortcutCell';
import ThemeCell from './main-feed/ThemeCell';
import TutorOnCell from './main-feed/TutorOnCell';
import VerticalBannerCell from './main-feed/VerticalBannerCell';
import * as feedApi from '@apis/app-feed';
import PurchasedProductsCell from './main-feed/PurchasedProductsCell';

const HEADER_HEIGHT = '56px';
const ITEM_GAP = '12px';

export const MainFeedView: React.FC<{ data: any[] }> = (props) => {
  const virtuoso = useRef<any>();
  const feedData = props.data || [];

  const popularKeywordsQuery = useQuery({
    queryKey: [`feed-popular-keywords`],
    queryFn: () => feedApi.getSearchPopularKeywords(),
  });

  return (
    <div>
      <div>
        <div
          style={{
            display: 'flex',
            height: HEADER_HEIGHT,
            justifyContent: 'space-between',
            width: '100%',
          }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: '56px',
              height: HEADER_HEIGHT,
            }}>
            <div>
              <div
                style={{
                  height: HEADER_HEIGHT,
                  backgroundColor: '#ffffff',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: 15,
                  display: 'flex',
                }}>
                <img
                  style={{
                    height: '26px',
                    width: '50px',
                  }}
                  src={AppLogoImage}
                />
              </div>
            </div>
          </div>
          <div className="app-header-title"></div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: HEADER_HEIGHT,
              minWidth: '56px',
            }}>
            <div>
              <div style={{ display: 'flex' }}>
                <button className="link-button" style={{ marginRight: '20px' }}>
                  <div
                    style={{
                      display: 'flex',
                      width: '24px',
                      height: HEADER_HEIGHT,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <img style={{ height: '24px', width: '24px' }} src={SearchIcon} />
                  </div>
                </button>
                <button className="link-button" style={{ marginRight: '20px' }}>
                  <div
                    style={{
                      display: 'flex',
                      width: '24px',
                      height: HEADER_HEIGHT,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <img style={{ height: '24px', width: '24px' }} src={HeartIcon} />
                  </div>
                </button>
                <button className="link-button" style={{ marginRight: '16px' }}>
                  <div
                    style={{
                      display: 'flex',
                      width: '24px',
                      height: HEADER_HEIGHT,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <img style={{ height: '24px', width: '24px' }} src={NotificationIcon} />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Virtuoso
          ref={virtuoso}
          style={{ height: 'calc(100dvh - 100px)', overflowX: 'hidden' }}
          totalCount={feedData.length + 1}
          itemContent={(index) => {
            if (index === feedData.length) {
              return <FeedFooterCell />;
            }
            const item = feedData[index];
            switch (item.type) {
              case 'curation-vertical': {
                let contents = item.data;
                if (item.shuffle) {
                  contents = shuffleArray(contents);
                }
                return (
                  <div style={{ padding: `${ITEM_GAP} 0` }}>
                    <VerticalBannerCell data={contents}></VerticalBannerCell>
                  </div>
                );
              }
              case 'curation-search':
                return (
                  <div style={{ padding: `${ITEM_GAP} 0` }}>
                    <SearchCell title={item.title}></SearchCell>
                  </div>
                );
              case 'curation-shortcut':
                return (
                  <div style={{ padding: `${ITEM_GAP} 0` }}>
                    <ShortcutCell data={item.data}></ShortcutCell>
                  </div>
                );
              case 'curation-commerce': {
                let contents = item.data;
                if (item.shuffle) {
                  contents = shuffleArray(contents);
                }

                return (
                  <div style={{ padding: `${ITEM_GAP} 0` }}>
                    <CommerceCarouselCell data={contents} title={item.title} size={item.size}></CommerceCarouselCell>
                  </div>
                );
              }
              case 'curation-keywords': {
                let data = item.data;
                if (item.sync) {
                  data = popularKeywordsQuery.data?.data || [];
                }
                return (
                  <div style={{ padding: `${ITEM_GAP} 0` }}>
                    <PopularKeywordsCell
                      title={item.title}
                      style={{ width: '100%', padding: '0 16px' }}
                      data={data}></PopularKeywordsCell>
                  </div>
                );
              }

              case 'curation-class-grid':
                return (
                  <div style={{ padding: `${ITEM_GAP} 0` }}>
                    <ClassGridCell collectionId={item._id} data={item.data} title={item.title}></ClassGridCell>
                  </div>
                );
              case 'curation-class-general':
                return (
                  <div style={{ padding: `${ITEM_GAP} 0` }}>
                    <ClassGeneralCell collectionId={item._id} data={item.data} title={item.title}></ClassGeneralCell>
                  </div>
                );
              case 'curation-tutor-on':
                return (
                  <div style={{ padding: `${ITEM_GAP} 0` }}>
                    <TutorOnCell data={item.data}></TutorOnCell>
                  </div>
                );
              case 'curation-category':
                return (
                  <div style={{ padding: `${ITEM_GAP} 0` }}>
                    <CategoryCell data={item.data}></CategoryCell>
                  </div>
                );
              case 'curation-class-list':
                return (
                  <div style={{ padding: `${ITEM_GAP} 0` }}>
                    <ClassListCell data={item.data} title={item.title} description={item.description}></ClassListCell>
                  </div>
                );
              case 'curation-purchased-list':
                return (
                  <div style={{ padding: `${ITEM_GAP} 0` }}>
                    <PurchasedProductsCell
                      data={item.data}
                      title={item.title}
                      description={item.description}></PurchasedProductsCell>
                  </div>
                );
              case 'curation-class-theme':
                return (
                  <div style={{ padding: `${ITEM_GAP} 0` }}>
                    <ThemeCell title={item.title} data={item.data}></ThemeCell>
                  </div>
                );
            }
            return (
              <div style={{ padding: `${ITEM_GAP} 0` }}>
                <div>{item.type}</div>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default MainFeedView;
