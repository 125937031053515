import { PagenatedResponse } from '@models/common';
import {
  AdminRefundBody,
  ChangeClassScheduleBody,
  ChangeCommerceOptionBody,
  PaymentStatement,
  PaymentStatementPurchase,
  UpdatePaymentStatementEventType,
} from '@models/paymentStatement';
import { PaymentStatementsQuery } from '@stores/paymentStatementsStore';
import { apiClient } from '.';

export interface ChangeScheduleBody {
  paymentStatementId: string;
  body: ChangeClassScheduleBody | ChangeCommerceOptionBody;
}

export const searchPaymentStatements = async (query?: PaymentStatementsQuery) => {
  return apiClient.get<PagenatedResponse<PaymentStatement>>(`/admin/v1/payment-statements`, {
    params: query,
  });
};

export const searchErrorPaymentStatements = async (query?: PaymentStatementsQuery) => {
  return apiClient.get<PagenatedResponse<PaymentStatement>>(`/admin/v1/payment-statements/errors`, {
    params: query,
  });
};

export const getSearchPaymentStatementsExcelFile = async (query?: PaymentStatementsQuery, type?: string) => {
  return apiClient.get(`/admin/v1/payment-statements/export`, {
    params: { query, type },
    responseType: 'blob',
    headers: { 'Cache-Control': 'no-cache' },
  });
};

export const getPBPaymentStatementsExcelFile = async (query?: PaymentStatementsQuery) => {
  return apiClient.get(`/admin/v1/payment-statements/export/pb`, {
    params: query,
    responseType: 'blob',
    headers: { 'Cache-Control': 'no-cache' },
  });
};

export const getPlannedCommerceExcelFile = async (query?: PaymentStatementsQuery) => {
  return apiClient.get(`/admin/v1/payment-statements/export/planned`, {
    params: query,
    responseType: 'blob',
    headers: { 'Cache-Control': 'no-cache' },
  });
};

export const getCommerceSettlementsExcelFile = async (query?: PaymentStatementsQuery) => {
  return apiClient.get(`/admin/v1/payment-statements/export/commerce-settlement`, {
    params: query,
    responseType: 'blob',
    headers: { 'Cache-Control': 'no-cache' },
  });
};

export const updateRefundStatus = async (data: {
  paymentStatementId: string;
  body: {
    event: UpdatePaymentStatementEventType;
    refundItems?: PaymentStatementPurchase[];
    referenceId?: string;
    memo?: string;
    noMoreRefundsAvailable?: boolean;
  };
}) => {
  apiClient.patch(`/admin/v1/payment-statements/${data.paymentStatementId}/refund`, data.body);
};

export const registerRefunds = async (data: { paymentStatementIds: string[] }) => {
  return await apiClient.post<{
    targetIds: string[];
    success: number;
    failed: number;
    successIds: string[];
    failedIds: string[];
  }>(`/admin/v1/payment-statements/refund/register`, data);
};

export const requestRefundWithBody = async (data: {
  paymentStatementId: string;
  data: AdminRefundBody | Pick<AdminRefundBody, 'noMoreRefundsAvailable'>;
}) => {
  return await apiClient.post<PaymentStatement>(
    `/admin/v1/payment-statements/${data.paymentStatementId}/refund`,
    data.data,
  );
};

export const requestRefunds = async (data: { paymentStatementIds: string[] }) => {
  return await apiClient.post<{
    targetIds: string[];
    success: number;
    failed: number;
    successIds: string[];
    failedIds: string[];
  }>(`/admin/v1/payment-statements/refund`, data);
};

export const classReportMigrate = async () => {
  return apiClient.post(`/admin/v1/payment-statements/migrate/class-report`);
};

export const settlementPaymentStatements = async (body: {
  type: 'class' | 'commerce';
  paymentStatementIds?: string[];
  query?: PaymentStatementsQuery;
}) => {
  return apiClient.put(`/admin/v1/payment-statements/settlements`, body);
};

export const changeSchedule = async (data: ChangeScheduleBody) => {
  return apiClient.patch(`/admin/v1/payment-statements/${data.paymentStatementId}/planned-date`, data.body);
};

export const addPaymentStatementAccountTransferAmount = async (data: {
  id: string;
  body: { amount: number; memo: string };
}) => {
  const paymentStatementId = data.id;
  return apiClient.patch(`/admin/v1/payment-statements/${paymentStatementId}/account-transfer-amount`, data.body);
};

export const updateCompletionDate = async (data: { id: string; body: { completionDate: string; memo: string } }) => {
  const paymentStatementId = data.id;
  return apiClient.post(`/admin/v1/payment-statements/${paymentStatementId}/completion-date`, data.body);
};
