import SearchImage from '@assets/images/app/home-search.png';
import React from 'react';
import { MainFeedCellProps } from '.';

export const PopularKeywordsCell: React.FC<MainFeedCellProps> = (props) => {
  const { data } = props;

  const splitedKeywords = [];

  for (let i = 0; i < data.length; i += 3) {
    const chunk = data.slice(i, i + 3);
    splitedKeywords.push(chunk);
  }

  return (
    <div id="keywords-cell" style={props.style}>
      <div
        style={{
          backgroundColor: 'white',
          fontSize: '19px',
          fontWeight: 'bold',
          color: '#242424',
        }}>
        {props.title || '실시간 인기 검색어 TOP 6'}
      </div>
      <div style={{ marginTop: '16px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {splitedKeywords.map((item, parentIndex) => {
            return (
              <div
                key={`keyword-parent-${parentIndex}`}
                style={{ width: 'calc(100% - 4px)', marginRight: parentIndex === 0 ? '8px' : 0 }}>
                {item.map((keyword: any, index: number) => {
                  return (
                    <button
                      className="link-button"
                      key={`keyword-child-${index}`}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        height: '40px',
                        paddingLeft: '12px',
                        marginBottom: '8px',
                        borderRadius: '8px',
                        backgroundColor: '#f8f9fc',
                      }}
                      onClick={() => {
                        props.onClick && props.onClick(keyword._id);
                        // this._selectBestKeyword(keyword._id);
                        // common.sendAmplitude('2022_home_bestsearchtext_click', {
                        //   seach_text: keyword._id,
                        // });
                      }}>
                      <div
                        style={{
                          alignSelf: 'center',
                          display: 'flex',
                          color: '#2d363d',
                          fontSize: '14px',
                        }}>
                        <div
                          style={{
                            fontWeight: 'bold',
                            fontSize: '15px',
                            color: parentIndex === 0 ? '#ff5e5e' : '#424242',
                            marginRight: '8px',
                          }}>
                          {parentIndex * 3 + index + 1}
                        </div>
                        {keyword._id}
                      </div>
                      <div style={{ marginLeft: 'auto', alignSelf: 'center' }}>
                        <img
                          style={{
                            height: 20,
                            width: 20,
                            justifyContent: 'center',
                            marginRight: 8,
                            marginLeft: 10,
                          }}
                          src={SearchImage}
                        />
                      </div>
                    </button>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PopularKeywordsCell;
