import {
  TossPaymentsPaymentMethodEN,
  TossPaymentsPaymentMethodKR,
  TOSS_PAYMENTS_CARD_COMPANY_CODE,
} from '@consts/payment';
import { PaymentStatementPayment, PaymentStatementPurchase } from '@models/paymentStatement';
import moment from 'moment';
import { dateToUtcDate, isValidDateString } from './date';

export const getPaymentValues = (props: {
  totalClassTimes: number;
  totalStudentCount: number;
  applyGroupDiscount?: boolean;
  classFeePerTimes: number;
}) => {
  const totalClassTimes = props.totalClassTimes || 0;
  const studentCount = props.totalStudentCount || 0;

  const groupSaleChildrenCount =
    props.applyGroupDiscount && studentCount > 1 ? 10 - (studentCount > 4 ? 4 : studentCount) : 10;
  const discountsRatio = groupSaleChildrenCount / 10;
  const totalAmount = props.classFeePerTimes * totalClassTimes * studentCount;
  const discountedAmount = totalAmount * discountsRatio;
  const discountAmount = totalAmount - discountedAmount;

  return {
    totalAmount: totalAmount || 0,
    discountedAmount: discountedAmount || 0,
    discountAmount: discountAmount || 0,
    discountPercent: 100 - discountsRatio * 100,
  };
};

export const getPaymentMethodText = (payment: PaymentStatementPayment) => {
  let paymentMethod = '-';
  let paymentIssuer = '';
  const gateway = payment?.gateway || undefined;
  if (gateway && gateway === 'toss') {
    if (payment.method === TossPaymentsPaymentMethodEN.CARD || payment.method === TossPaymentsPaymentMethodKR.CARD) {
      paymentMethod = '카드결제';
      if (payment.result?.data?.card?.issuerCode) {
        paymentIssuer = TOSS_PAYMENTS_CARD_COMPANY_CODE[`${payment.result.data.card.issuerCode}`];
      }
    } else if (
      payment.method === TossPaymentsPaymentMethodEN.EASY_PAY ||
      payment.method === TossPaymentsPaymentMethodKR.EASY_PAY
    ) {
      paymentMethod = '간편결제';
      if (payment.result?.data?.easyPay) {
        const provider = payment.result.data.easyPay.provider;
        if (provider === 'NAVERPAY' || provider === '네이버페이') {
          paymentIssuer = '네이버페이';
        } else if (provider === 'KAKAOPAY' || provider === '카카오페이') {
          paymentIssuer = '카카오페이';
        } else if (provider === 'TOSSPAY' || provider === '토스페이' || provider === '토스결제') {
          paymentIssuer = '토스페이';
        } else {
          paymentIssuer = provider;
        }
      }
    } else if (
      payment.method === TossPaymentsPaymentMethodEN.TRANSFER ||
      payment.method === TossPaymentsPaymentMethodKR.TRANSFER
    ) {
      paymentMethod = '계좌이체';
    } else if (
      payment.method === TossPaymentsPaymentMethodEN.VIRTUAL_ACCOUNT ||
      payment.method === TossPaymentsPaymentMethodKR.VIRTUAL_ACCOUNT
    ) {
      paymentMethod = '가상계좌';
    } else if (payment.method === 'point_only') {
      paymentMethod = '포인트결제';
    }
  } else {
    if (payment.method === 'creditPoints') {
      paymentMethod = '포인트결제';
    } else if (payment.method === 'kakaopay') {
      paymentMethod = '카카오페이';
    } else if (payment.method === 'naverpay') {
      paymentMethod = '네이버페이';
    } else if (payment.method === 'card') {
      paymentMethod = '카드결제';
    }
  }
  return `${paymentMethod}` + (paymentIssuer ? ` (${paymentIssuer})` : '');
};

export const extractDateFromStrings = (
  array: string[] = [],
): {
  matchDays: string[];
  matchTimes: string[];
  matches: string[];
  notMatches: string[];
} => {
  const regexDate = /\d{1,2}\/\d{1,2}/g;
  const regexDate3 = /\d{1,2}-\d{1,2}-\d{1,2}/;
  const regexTime = /\d{1,2}:\d{1,2}/;
  const regexTime2 = /\d{1,2}시\d{1,2}/;
  const regexTime3 = /\d{1,2}시/;

  const matchDays: string[] = [];
  const matchTimes: string[] = [];
  const matches: string[] = [];
  const notMatches: string[] = [];

  array.map((dateTimeString: string) => {
    let matched = false;
    const targetString = dateTimeString.replace('월', '/').replace('일', '');
    const checkDate1 = targetString.replace(/\s/g, '').match(regexDate);
    const checkDate3 = targetString.replace(/\s/g, '').match(regexDate3);
    const checkTime1 = targetString.replace(/\s/g, '').match(regexTime);
    const checkTime2 = targetString.replace(/\s/g, '').match(regexTime2);
    const checkTime3 = targetString.replace(/\s/g, '').match(regexTime3);

    if (checkDate1) {
      checkDate1.forEach((date) => {
        if (isValidDateString(date)) {
          matched = true;
          matchDays.push(date);
        }
      });
    } else if (checkDate3) {
      checkDate3.forEach((date) => {
        if (isValidDateString(date)) {
          matched = true;
          matchDays.push(date);
        }
      });
    }

    if (checkTime1) {
      matched = true;
      matchTimes.push(checkTime1[0]);
    } else if (checkTime2) {
      matched = true;
      matchTimes.push(checkTime2[0]);
    } else if (checkTime3) {
      matched = true;
      matchTimes.push(checkTime3[0]);
    }

    if (!matched) {
      notMatches.push(dateTimeString);
    } else {
      matches.push(dateTimeString);
    }
  });

  return {
    matchDays,
    matchTimes,
    matches,
    notMatches,
  };
};

export const formatDateFromStrings = (strings: string[], format: string = 'YYYYMMDD_HHmm') => {
  let result: string | undefined = undefined;
  const date = new Date();
  const thisYear = date.getFullYear();

  const { matchDays, matchTimes } = extractDateFromStrings(strings);
  if (matchDays && matchDays.length > 0) {
    matchDays.some((matchDay: string) => {
      const monthAndDay = matchDay.replace('월', '/').replace('일', '');
      let time = '';
      if (matchTimes.length > 0) {
        time = matchTimes[0].replace('시', ':').replace('분', '');
        if (time.slice(-1) === ':') {
          time = time + '00';
        }
      }

      const thisYearDate = new Date(`${thisYear}/${monthAndDay}${time ? `,${time}` : ''}`);
      const prevYearDate = new Date(`${thisYear - 1}/${monthAndDay}${time ? `,${time}` : ''}`);
      const nextYearDate = new Date(`${thisYear + 1}/${monthAndDay}${time ? `,${time}` : ''}`);

      let minDate = thisYearDate;
      let minDateDiff = Math.abs(date.getTime() - thisYearDate.getTime());

      if (Math.abs(date.getTime() - prevYearDate.getTime()) < minDateDiff) {
        minDateDiff = Math.abs(date.getTime() - prevYearDate.getTime());
        minDate = prevYearDate;
      }

      if (Math.abs(date.getTime() - nextYearDate.getTime()) < minDateDiff) {
        minDateDiff = Math.abs(date.getTime() - nextYearDate.getTime());
        minDate = nextYearDate;
      }

      result = moment(minDate).format(format);
      return true;
    });
  }
  return result;
};

export const getPlannedDatesFromPaymentStatement = (
  saleType: string,
  items: PaymentStatementPurchase[],
  paidDate: Date = new Date(),
): Date[] => {
  const plannedDates: Date[] = [];
  const regexClassSchedule = /\d{1,2}-\d{1,2}-\d{1,2}/;
  const thisYear = new Date(paidDate).getFullYear();
  const date = new Date(paidDate);

  if (items.length > 0) {
    items.map((item: PaymentStatementPurchase) => {
      if (saleType === 'commerce') {
        let selectedOptions = [];
        if (item.option && item.option.length > 0) {
          selectedOptions = item.option;
        } else {
          if (!Array.isArray(item.name)) {
            selectedOptions = item.name?.split(' / ') || [];
          } else {
            selectedOptions = item.name;
          }
        }

        const { matchDays, matchTimes } = extractDateFromStrings(selectedOptions);
        if (matchDays && matchDays.length > 0) {
          matchDays.map((matchDay: string) => {
            const monthAndDay = matchDay.replace('월', '/').replace('일', '');
            let time = '';
            if (matchTimes.length > 0) {
              time = matchTimes[0].replace('시', ':').replace('분', '');
              if (time.slice(-1) === ':') {
                time = time + '00';
              }
            }

            const thisYearDate = new Date(`${thisYear}/${monthAndDay}${time ? `,${time}` : ''}`);
            const prevYearDate = new Date(`${thisYear - 1}/${monthAndDay}${time ? `,${time}` : ''}`);
            const nextYearDate = new Date(`${thisYear + 1}/${monthAndDay}${time ? `,${time}` : ''}`);

            let minDate = thisYearDate;
            let minDateDiff = Math.abs(date.getTime() - thisYearDate.getTime());

            if (Math.abs(date.getTime() - prevYearDate.getTime()) < minDateDiff) {
              minDateDiff = Math.abs(date.getTime() - prevYearDate.getTime());
              minDate = prevYearDate;
            }

            if (Math.abs(date.getTime() - nextYearDate.getTime()) < minDateDiff) {
              minDateDiff = Math.abs(date.getTime() - nextYearDate.getTime());
              minDate = nextYearDate;
            }

            const utcDate = dateToUtcDate(minDate);
            plannedDates.push(utcDate);
          });
        }
      } else {
        const isStringTypeDate = (item.name as string).replace(/\s/g, '').match(regexClassSchedule);
        if (isStringTypeDate && isStringTypeDate.length > 0) {
          const classSchedule = new Date(item.name as string);
          const utcDate = dateToUtcDate(classSchedule);
          plannedDates.push(utcDate);
        }
      }
    });
    const result = plannedDates.filter((item: Date, index, selfArr) => {
      if (item && typeof item === 'object') {
        return selfArr.findIndex((date) => date.getTime() === item.getTime()) === index;
      }
    });
    return result;
  } else {
    return [];
  }
};
