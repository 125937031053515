import { useEffect, useState } from 'react';

interface useDebounceProps<T> {
  value: T;
  delay?: number;
}

const useDebounce = <T>({ value, delay = 500 }: useDebounceProps<T>) => {
  const [debounceValue, setDebounceValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebounceValue(value);
    }, delay);

    return () => clearTimeout(timer);
  }, [value, delay]);

  return debounceValue;
};

export default useDebounce;
