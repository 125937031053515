export const LEGACY_PRODUCT_CATEGORIES = [
  {
    categoryId: 'physical',
    categoryName: '신체',
    categorySubList: ['맨몸', '교구', '구기', '발레', '댄스'],
  },
  {
    categoryId: 'artistic',
    categoryName: '예술',
    categorySubList: ['음악', '미술', '퍼포먼스'],
  },
  {
    categoryId: 'scientific',
    categoryName: 'STEAM',
    categorySubList: ['과학', '코딩', '엔지니어링', '브레인', '수학'],
  },
  {
    categoryId: 'lang',
    categoryName: '언어',
    categorySubList: ['한글', '영어', '중국어', '기타 언어'],
  },
  {
    categoryId: 'special',
    categoryName: '특별',
    categorySubList: ['스피치', '요리', '자연', '심리', '성교육', '기타 활동'],
  },
];

export type PRODUCT_CATEGORIES_KEYS = '신체' | '예술' | 'STEAM' | '언어' | '특별';

export const PRODUCT_CATEGORIES = {
  신체: ['맨몸', '교구', '구기', '발레', '댄스'],
  예술: ['음악', '미술', '퍼포먼스'],
  STEAM: ['과학', '코딩', '엔지니어링', '브레인', '수학'],
  언어: ['한글', '영어', '중국어', '기타 언어'],
  특별: ['스피치', '요리', '자연', '심리', '성교육', '역사', '기타 활동'],
};

export const CLASS_REFUND_POLICY = `수업 예정일 2일 전 17시까지 취소 요청시 전액 환불
환불 가능 기간 경과 후에는 환불 불가
환불 가능 기간 중에는 수업일 변경 요청 가능
환불 가능 기간 경과 후 수업일 연장 요청 1회 가능
수업일 변경 이후로는 환불 불가
정기 다회차 클래스 예약시, 수강 횟수가 1/2를 넘어가면 개인사유로 인한 환불 불가`;

export const COMMERCE_REFUND_POLICY = `• 수업 4일 전까지 : 전액 환불
• 수업 3일 전 : 50% 환불
• 수업 2일 전 ~ 당일 : 환불 불가
- 최소 인원(2인) 미달로 폐강 시 전액 환불됩니다.
- 노쇼, 지각의 경우 보강 및 환불이 불가합니다.
- 천재지변 등 불가항력의 사유 발생 시 전액 환불 됩니다.
- 질병사유(코로나 등)로 참석이 불가할 경우, 수업일 기준 앞뒤 3일 내 관련 자료 제출 시 전액 환불 가능합니다.`;
