import React from 'react';
import { AvailableDistrict } from '@models/class';
import { Button, Select } from 'antd';
import classNames from 'classnames';
import { DISTRICTS } from '@consts/location';

interface DistrictSelectItemProps {
  size?: string;
  district: AvailableDistrict;
  index: number;
  onUpdate: (value: AvailableDistrict, index: number) => void;
  onClickRemove: (index: number) => void;
  onClickUp: (index: number) => void;
  onClickDown: (index: number) => void;
}

export const DistrictSelectItem: React.FC<DistrictSelectItemProps> = (props) => {
  const { size, district, index, onUpdate, onClickRemove, onClickUp, onClickDown } = props;
  const districtList: any = DISTRICTS;
  const stateList: any = [];
  Object.keys(districtList).map((item) => {
    stateList.push({ value: item, label: <span>{item}</span> });
  });

  const onChangeState = (value: string) => {
    district.state = value;
    if (value === '전체') {
      district.city = '전체';
    }
    update(district);
  };

  const onChangeCity = (value: string) => {
    district.city = value;
    update(district);
  };

  const update = (district: AvailableDistrict) => {
    onUpdate(district, index);
  };

  if (!districtList) {
    return <div />;
  }

  const cityList: any = [];
  if (district.state && districtList[district.state]) {
    districtList[district.state].map((city: string) => {
      cityList.push({ value: city, label: <span>{city}</span> });
    });
  }

  const removeButton = (
    <Button
      style={{
        marginLeft: '3px',
        width: '15px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onClick={() => onClickRemove(index)}>
      -
    </Button>
  );

  const upButton = (
    <Button
      style={{
        marginLeft: '3px',
        width: '15px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onClick={() => onClickUp(index)}>
      ↑
    </Button>
  );

  const downButton = (
    <Button
      style={{
        marginLeft: '3px',
        width: '15px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onClick={() => onClickDown(index)}>
      ↓
    </Button>
  );

  const stateClass = classNames({
    'district-select-item__state': true,
    'ant-select-custom': size === 'normal',
  });

  const cityClass = classNames({
    'district-select-item__city': true,
    'ant-select-custom': size === 'normal',
  });

  return (
    <div className="district-select-item">
      <Select
        className={stateClass}
        placeholder="시/도 선택"
        notFoundContent="데이터 로드중입니다"
        value={district.state}
        onChange={onChangeState}
        options={stateList}
      />
      <Select
        className={cityClass}
        placeholder="시군구 선택"
        notFoundContent="시/도를 먼저 선택해주세요"
        value={district.city}
        onChange={onChangeCity}
        options={cityList}
      />
      {removeButton}
      {upButton}
      {downButton}
    </div>
  );
};

export default DistrictSelectItem;
