import { PagenatedResponse } from '@models/common';
import { DefaultInfoNotification, NotificationTarget, NotificationOption } from '@models/notification';
import { create } from 'zustand';

const PAGE_SIZE = 20;

interface NotificationContainer {
  contents: DefaultInfoNotification[];
  page: number;
  size: number;
  total: number;
  hasNext: boolean;
}

interface NotificationSearchQueryProps extends Pick<NotificationContainer, 'page' | 'size'> {
  targetType: NotificationTarget;
  optionType: NotificationOption;
  keyword: string;
}

export interface State {
  searchResultContainer: NotificationContainer;
  searchQuery: NotificationSearchQueryProps;
}
export interface Actions {
  setNoticeContainer: (result: PagenatedResponse<DefaultInfoNotification>) => void;
  setSearchKeyword: (keyword: string) => void;
  updateSearchQuery: (query: { [key: string]: any }) => void;
}

const useNotificationStore = create<State & Actions>((set) => ({
  searchResultContainer: {
    contents: [],
    total: 0,
    page: 0,
    size: 0,
    hasNext: false,
  },
  searchQuery: {
    page: 0,
    size: PAGE_SIZE,
    targetType: '',
    optionType: '',
    keyword: '',
  },
  setNoticeContainer: (result) => {
    set((state) => {
      const contents = new Array(result.total || PAGE_SIZE);
      contents.splice(result.page * result.size, result.size, ...(result?.contents || []));
      const newValue = { ...state.searchResultContainer, ...result, contents: contents };
      return { ...state, searchResultContainer: newValue };
    });
  },
  setSearchKeyword: (keyword: string) => {
    set((state) => {
      return {
        ...state,
        searchKeyword: keyword,
        searchResultContainer: { ...state.searchResultContainer, size: 0 },
      };
    });
  },
  updateSearchQuery: (query: { [key: string]: any }) => {
    set((state) => {
      return {
        ...state,
        searchQuery: { ...state.searchQuery, ...query },
        searchResultContainer: { ...state.searchResultContainer, size: 0 },
      };
    });
  },
}));

export default useNotificationStore;
