import GrayRightIcon from '@assets/images/app/icon-arr-gray-right-12.png';
import { Carousel } from '@components/common/Carousel';
import ClassItem from '@components/product-collection/ClassItem';
import CommerceItem from '@components/product-collection/CommerceItem';
import React from 'react';
import { MainFeedCellProps } from '.';

export const ClassGeneralCell: React.FC<MainFeedCellProps> = (props) => {
  const data = props.data || [];

  const itemWidth = 428 / 2 - 48;
  return (
    <div id="class-general-cell">
      <div
        style={{
          width: '100%',
          backgroundColor: 'white',
        }}>
        <div
          style={{
            display: 'flex',
            marginBottom: '20px',
            backgroundColor: 'white',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0 16px',
          }}>
          <div
            id="class-general-cell-title"
            style={{
              fontSize: '19px',
              fontWeight: 'bold',
              color: '#242424',
            }}>
            {props.title}
          </div>
          <button
            className="link-button"
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            <div
              style={{
                fontSize: '14px',
                color: '#808387',
                lineHeight: '17px',
              }}>
              전체보기
            </div>
            <img src={GrayRightIcon} style={{ width: '12px', height: '12px' }} />
          </button>
        </div>
        <div>
          <Carousel
            styles={{ containerStyle: { padding: '0 16px' } }}
            options={{
              dragFree: true,
              align: 'start',
              watchDrag: () => {
                // common.sendAmplitude('2022_home_generaltypetrain_touch', {});
                return true;
              },
            }}>
            {data.map((item: any, index: number) => {
              return (
                <div
                  key={`class-general-item-${index}`}
                  className={'embla__slide'}
                  style={{ flex: `0 0 ${itemWidth + 12}px`, cursor: 'pointer' }}>
                  {item.saleType === 'commerce' && (
                    <CommerceItem
                      key={`product-item-${index}`}
                      item={item}
                      width={itemWidth}
                      style={{ width: `${itemWidth}px` }}
                      onClick={() => {}}></CommerceItem>
                  )}
                  {item.saleType === 'class' && (
                    <ClassItem item={item} width={itemWidth} style={{ width: `${itemWidth}px` }}></ClassItem>
                  )}
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default ClassGeneralCell;
