import { create } from 'zustand';

interface UpdateCompletionDateModalProps {
  show: boolean;
  data: {
    _id?: string;
    memo?: string;
    completionDate?: Date;
  };
  open: (data: { _id: string; memo?: string; completionDate?: Date }) => void;
  close: () => void;
}

const useUpdateCompletionDateModalStore = create<UpdateCompletionDateModalProps>((set) => ({
  show: false,
  data: {},
  open: (data) => {
    set({ data: data, show: true });
  },
  close: () => {
    set({ show: false });
  },
}));

export default useUpdateCompletionDateModalStore;
