import * as api from '@apis/chats';
import ChatPreview from '@components/chat/ChatPreview';
import { useAuthentication } from '@hooks/authentication';
import { ExtendedChatRoom } from '@models/chat';
import { useChatRoomsStore } from '@stores/chatRoomsStore';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Input, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

export const ChatRoomsPage: React.FC = () => {
  const [selectedChatRoom, setSelectedChatRoom] = useState<ExtendedChatRoom>();
  const [searchKeyword, setSearchKeyword] = useState<string>();
  const searchInputRef = useRef<any>();
  const dialog = useOkCancelDialog();

  const store = useChatRoomsStore();

  useAuthentication();

  const chatRoomsQuery = useQuery({
    queryKey: [`feed-chat-rooms`, JSON.stringify(store.searchQuery)],
    queryFn: () => api.searchChatRooms(store.searchQuery),
  });
  const remindChatMutation = useMutation({
    mutationFn: api.remindChat,
    onSuccess: async () => {
      toast.dark('알림톡을 성공적으로 전송했습니다.', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: false,
        draggable: true,
        progress: undefined,
        bodyStyle: {
          color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
          textAlign: 'center',
          fontFamily: 'Pretendard',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '20px',
        },
        theme: 'dark',
      });
    },
  });

  const chatRooms = chatRoomsQuery.data?.data;

  useEffect(() => {
    if (chatRooms) {
      store.setChatRoomsContainer(chatRooms);
    }
  }, [chatRooms]);

  const TABLE_COLUMNS: ColumnsType<ExtendedChatRoom> = [
    {
      title: 'ID',
      dataIndex: '_id',
      render: (_id, record) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(_id);
                toast.dark('대화방 ID를 클립보드에 복사했습니다', {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  pauseOnFocusLoss: false,
                  draggable: true,
                  progress: undefined,
                  bodyStyle: {
                    color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                    textAlign: 'center',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                  },
                  theme: 'dark',
                });
              }}>
              대화방 ID
            </Button>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(record.classId);
                toast.dark('클래스 ID를 클립보드에 복사했습니다', {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  pauseOnFocusLoss: false,
                  draggable: true,
                  progress: undefined,
                  bodyStyle: {
                    color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                    textAlign: 'center',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                  },
                  theme: 'dark',
                });
              }}>
              클래스 ID
            </Button>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(record.tutorId);
                toast.dark('튜터 ID를 클립보드에 복사했습니다', {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  pauseOnFocusLoss: false,
                  draggable: true,
                  progress: undefined,
                  bodyStyle: {
                    color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                    textAlign: 'center',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                  },
                  theme: 'dark',
                });
              }}>
              튜터 ID
            </Button>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(record.parentId);
                toast.dark('부모 ID를 클립보드에 복사했습니다', {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  pauseOnFocusLoss: false,
                  draggable: true,
                  progress: undefined,
                  bodyStyle: {
                    color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                    textAlign: 'center',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                  },
                  theme: 'dark',
                });
              }}>
              부모 ID
            </Button>
          </div>
        );
      },
    },
    {
      title: '클래스',
      width: 200,
      render: (record: ExtendedChatRoom) => {
        return `[${record.tutor?.profile?.nickname}] ${record.class?.title}`;
      },
    },
    {
      title: '부모님',
      render: (record: ExtendedChatRoom) => {
        return (
          <div>
            <div>
              {`${record.parent?.profile?.name}\n(${record.parent?.profile?.phone?.substring(
                record.parent?.profile?.phone?.length - 4,
              )})`}
            </div>
            <div>
              {(record.parent?.profile?.children || [])
                .map((item) => {
                  return item.name;
                })
                .join(', ')}
            </div>
          </div>
        );
      },
    },
    {
      title: '마지막 메시지',
      width: 428,
      dataIndex: 'chattext',
      render: (chattext) => {
        return chattext;
      },
    },
    {
      title: '읽지 않음',
      render: (record) => {
        return (
          <div>
            <div>{`튜터(${record.unreadTutorCount})`}</div>
            <div>{`부모님(${record.unreadParentCount})`}</div>
          </div>
        );
      },
    },
    {
      title: '채팅 시작',
      dataIndex: 'createdAt',
      render: (createdAt) => {
        if (!createdAt) {
          return '-';
        }
        return moment(createdAt).format('YYYY.MM.DD HH:mm');
      },
    },
    {
      title: '마지막 채팅',
      dataIndex: 'updatedAt',
      render: (updatedAt) => {
        if (!updatedAt) {
          return '-';
        }
        return moment(updatedAt).format('YYYY.MM.DD HH:mm');
      },
    },
    {
      title: '채팅 보기',
      render: (record) => {
        return (
          <Button
            onClick={() => {
              setSelectedChatRoom(record);
            }}>
            보기
          </Button>
        );
      },
    },
    {
      title: '채팅 응답 요청',
      render: (record) => {
        return (
          <div>
            <Button
              onClick={() => {
                dialog.open({
                  title: '카카오톡 알림톡 전송',
                  content: '"부모" 에게 채팅 리마인드 알림톡을 전송하시겠습니까?',
                  type: 'ok_cancel',
                  onConfirm: () => {
                    remindChatMutation.mutate({
                      type: 'parent',
                      receiverPhoneNumber: record.parent.profile.phone,
                      receiverName: record.parent.profile.name,
                      className: record.class.title,
                    });
                  },
                });
              }}>
              부모
            </Button>
            <Button
              onClick={() => {
                dialog.open({
                  title: '카카오톡 알림톡 전송',
                  content: '"튜터" 에게 채팅 리마인드 알림톡을 전송하시겠습니까?',
                  type: 'ok_cancel',
                  onConfirm: () => {
                    remindChatMutation.mutate({
                      type: 'tutor',
                      receiverPhoneNumber: record.tutor.profile.phone,
                      receiverName: record.tutor.profile.nickname,
                      className: record.class.title,
                      userName: record.parent.profile.name,
                    });
                  },
                });
              }}>
              튜터
            </Button>
          </div>
        );
      },
    },
  ];
  return (
    <div className="dashboard" style={{ position: 'relative' }}>
      <div className="page-header">
        <div>대화방 모니터링</div>
      </div>
      <div style={{ display: 'flex' }}>
        <div className="page-content" style={{ width: '100%', height: 'calc(100vh - 80px)', overflow: 'auto' }}>
          <div style={{ display: 'flex', gap: '12px', width: '100%', height: '52px', alignItems: 'center' }}>
            <Input
              ref={searchInputRef}
              style={{ width: '240px' }}
              value={searchKeyword}
              placeholder={'검색어 입력 후 엔터'}
              onChange={(e) => {
                setSearchKeyword(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.code === 'Enter' && searchKeyword) {
                  store.updateSearchQuery({ keyword: searchKeyword });
                }
              }}></Input>

            <div style={{ display: 'flex', marginLeft: 'auto', gap: '12px', alignItems: 'center' }}>
              <Button
                onClick={() => {
                  setSearchKeyword(undefined);
                  store.resetSearchQuery();
                }}>
                검색 초기화
              </Button>
            </div>
          </div>
          <Table
            columns={TABLE_COLUMNS}
            scroll={{ x: 'max-content', y: 'calc(100dvh - 280px)' }}
            style={{ whiteSpace: 'pre-wrap' }}
            rowKey={(record) => record?._id}
            dataSource={store.searchResultContainer.contents.filter((product) => product?._id)}
            pagination={{
              current: (store.searchResultContainer.page || 0) + 1,
              pageSize: store.searchResultContainer.size || 20,
              total: store.searchResultContainer.total,
            }}
            loading={chatRoomsQuery.isLoading}
            onChange={(e) => {
              store.updateSearchQuery({ page: (e.current || 2) - 1, size: e.pageSize || 20 });
            }}
          />
        </div>
      </div>
      {selectedChatRoom && (
        <div
          style={{
            width: '428px',
            height: '100vh',
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'white',
            zIndex: 900,
            borderLeft: '1px solid #eaeaea',
          }}>
          <div style={{ height: '44px', backgroundColor: '#eaeaea', display: 'flex', alignItems: 'center' }}>
            <Button
              onClick={() => {
                setSelectedChatRoom(undefined);
              }}>
              닫기
            </Button>
          </div>
          <ChatPreview
            productId={selectedChatRoom.classId}
            tutorId={selectedChatRoom.tutorId}
            parentId={selectedChatRoom.parentId}></ChatPreview>
        </div>
      )}
    </div>
  );
};

export default ChatRoomsPage;
