import * as api from '@apis/admin-user';
import PartnerProfileHeader1 from '@assets/images/app/partner-profile-header-1.png';
import PartnerProfileHeader2 from '@assets/images/app/partner-profile-header-2.png';
import PartnerProfileHeader3 from '@assets/images/app/partner-profile-header-3.png';
import PartnerProfileHeader4 from '@assets/images/app/partner-profile-header-4.png';
import PartnerProfileHeader5 from '@assets/images/app/partner-profile-header-5.png';
import PartnerProfileHeader6 from '@assets/images/app/partner-profile-header-6.png';
import PartnerProfileHeader7 from '@assets/images/app/partner-profile-header-7.png';
import PartnerProfileHeader8 from '@assets/images/app/partner-profile-header-8.png';
import PartnerProfileHeader9 from '@assets/images/app/partner-profile-header-9.png';
import { ReactComponent as ChevronDownIcon } from '@assets/images/app/chevron-down-nutral90.svg';
import { ReactComponent as ChevronUpIcon } from '@assets/images/app/chevron-up-nutral90.svg';
import { ReactComponent as QualifiedIcon } from '@assets/images/app/qualified-check-circle.svg';
import { ReactComponent as AttachmentIcon } from '@assets/images/app/icon-attachment.svg';
import TutorDefaultAvatar from '@assets/images/app/tutorintro.png';
import { Divider } from '@components/common/Divider';
import { UserCreateModal } from '@components/user/UserCreateModal';
import { UserEditModal } from '@components/user/UserEditModal';
import UserPointEditModal from '@components/user/UserPointEditModal';
import { Roles, ROLE_LABELS } from '@consts/role';
import { useAppSocket } from '@hooks/appSocket';
import { useAuthentication } from '@hooks/authentication';
import { AdminUser, ProfileApprovalState } from '@models/auth';
import { useAdminUsersStore } from '@stores/adminUsersStore';
import { useContentLoaderStore } from '@stores/contentLoaderStore';
import { useMutation, useQuery } from '@tanstack/react-query';
import { degreeValue, getAddressText, getChildrenText } from '@utils/dataTransform';
import { localTimeToUtcISOString, utcTimcToLocalISOString } from '@utils/date';
import { cdnImageUrl } from '@utils/image';
import { Button, DatePicker, DatePickerProps, Input, Modal, Popover, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import moment from 'moment';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React, { useEffect, useRef, useState } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import { PhotoView } from 'react-photo-view';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import { UserMembershipEditModal } from '@components/user/UserMembershipEditModal';

const HEADER_IMAGES = [
  PartnerProfileHeader1,
  PartnerProfileHeader2,
  PartnerProfileHeader3,
  PartnerProfileHeader4,
  PartnerProfileHeader5,
  PartnerProfileHeader6,
  PartnerProfileHeader7,
  PartnerProfileHeader8,
  PartnerProfileHeader9,
];

export const UsersPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [pointEditUser, setPointEditUser] = useState<AdminUser>();
  const [selectedTutorId, setSelectedTutorId] = useState<string>();
  const [searchKeyword, setSearchKeyword] = useState<string>();
  const [editUserId, setEditUserId] = useState<string>();
  const [editMembershipUserId, setEditMembershipUserId] = useState<string>();
  const [showUserCreate, setShowUserCreate] = useState<boolean>(false);
  const [showExperiences, setShowExperiences] = useState<boolean>(false);
  const headerImageRef = useRef<string>(HEADER_IMAGES[Math.floor(Math.random() * 9)]);
  const [isGreetingClamped, setIsGreetingClamped] = useState<boolean>(false);
  const [expandGreeting, setExpandGreeting] = useState<boolean>(false);
  const appSocket = useAppSocket();
  const store = useAdminUsersStore();
  const loader = useContentLoaderStore();
  useAuthentication();

  const membership = searchParams.get('membership');
  const userSearchQuery = useQuery({
    queryKey: [`admin-search-users`, JSON.stringify(store.searchQuery)],
    queryFn: () => api.searchUsers(store.searchQuery),
  });

  const userQuery = useQuery({
    queryKey: [`user-item-${selectedTutorId}`],
    queryFn: () => api.getUser(selectedTutorId!),
    enabled: !!selectedTutorId,
  });

  const partnerProfileMutation = useMutation({
    mutationFn: api.updatePartnerProfile,
    onSuccess: async () => {
      toast.dark('파트너 프로필을 수정했습니다.', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: false,
        draggable: true,
        progress: undefined,
        bodyStyle: {
          color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
          textAlign: 'center',
          fontFamily: 'Pretendard',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '20px',
        },
        theme: 'dark',
      });
    },
  });

  const userInfo = userQuery.data?.data;

  const partnerAcamemics = userInfo?.partnerProfile?.academics || [];

  const partnerCareers = userInfo?.partnerProfile?.careers || [];

  const partnerCertificates = userInfo?.partnerProfile?.certificates || [];

  useEffect(() => {
    store.updateSearchQuery({ membership });
  }, [membership]);

  useEffect(() => {
    if (store.searchQuery.keyword && !searchKeyword) {
      setSearchKeyword(store.searchQuery.keyword);
    }
  }, [store.searchQuery.keyword]);

  useEffect(() => {
    const searchResult = userSearchQuery.data?.data;
    if (searchResult) {
      store.setAdminUsersContainer(searchResult);
    }
  }, [userSearchQuery.data?.data]);

  useEffect(() => {
    appSocket.socket.addSocketEventListener('upload_excel_status', 'upload_excel_status', (data) => {
      const status = (data.status || '').toLowerCase();
      if (status === 'done') {
        userSearchQuery.refetch();
      }
      if (status === 'done' || status === 'error') {
        loader.off();
      }

      if (data.status) {
        toast.error(data.message, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    });
  }, []);

  window.onbeforeunload = () => {
    appSocket.socket.removeSocketEventListener('upload_excel_status', 'upload_excel_status');
  };

  const getExcelFile = async () => {
    const fileName = `igogo-users-${moment().format('YYYYMMDD-HHmmss')}.xlsx`;
    const blob = await api.getSearchUserssExcelFile(store.searchQuery);
    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
      // IE variant
      (window.navigator as any).msSaveOrOpenBlob(new Blob(blob.data), fileName);
    } else {
      const url = window.URL.createObjectURL(blob.data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode && link.parentNode.removeChild(link);
    }
  };

  const onChangeStartDate: DatePickerProps['onChange'] = (date) => {
    store.setStartDate(localTimeToUtcISOString(date ? new Date(date.toISOString()) : new Date(), { timeToZero: true }));
  };

  const onChangeEndDate: DatePickerProps['onChange'] = (date) => {
    store.setEndDate(localTimeToUtcISOString(date ? new Date(date.toISOString()) : new Date(), { timeToZero: true }));
  };

  const TABLE_COLUMNS: ColumnsType<AdminUser> = [
    {
      title: 'ID',
      dataIndex: '_id',
      render: (_id) => (
        <Button
          onClick={() => {
            navigator.clipboard.writeText(_id);
            toast.dark('사용자 ID를 클립보드에 복사했습니다', {
              position: 'top-center',
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              pauseOnFocusLoss: false,
              draggable: true,
              progress: undefined,
              bodyStyle: {
                color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                textAlign: 'center',
                fontFamily: 'Pretendard',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '20px',
              },
              theme: 'dark',
            });
          }}>
          ID 복사
        </Button>
      ),
    },
    {
      title: '수정',
      dataIndex: '_id',
      render: (_id) => (
        <Button
          onClick={() => {
            setEditUserId(_id);
          }}>
          수정
        </Button>
      ),
    },
    {
      title: '역할',
      dataIndex: 'roles',
      align: 'center',
      render: (roles, record) => {
        const roleText = (roles || []).map((r: string) => {
          return ROLE_LABELS[r] || r;
        });
        return (
          <div>
            <div style={{ width: '110px' }}>{roleText.join(' / ')}</div>
            <div>{record.profile?.salesChannels?.includes('naver') && '네이버 스토어'}</div>
            {(roles || []).some((role: Roles) => [Roles.TUTOR, Roles.SELLER].includes(role)) && (
              <Button
                onClick={() => {
                  setSelectedTutorId(record._id);
                }}>
                파트너 프로필
              </Button>
            )}
          </div>
        );
      },
    },
    {
      title: '주소',
      dataIndex: 'profile',
      render: (profile) => {
        return (
          <Popover
            content={profile?.address ? `${profile.address?.address} ${profile.address?.address2}` : '주소 없음'}>
            <div>{getAddressText({ availableArea: { specificAddress: profile?.address } })}</div>
          </Popover>
        );
      },
    },
    {
      title: '수신동의',
      dataIndex: 'profile',
      render: (profile) => {
        return (
          <div>
            <div>{`이메일: ${profile?.marketingEmailAgree ? '⭕' : 'X'}`}</div>
            <div>{`SMS: ${profile?.marketingSMSAgree ? '⭕' : 'X'}`}</div>
            <div>{`기타: ${profile?.otherAlarmAgree ? '⭕' : 'X'}`}</div>
          </div>
        );
      },
    },
    {
      title: '포인트',
      dataIndex: 'profile',
      render: (profile, record) => {
        return (
          <div>
            <div>{profile?.creditPoints ? profile.creditPoints.toLocaleString() : '-'}</div>
            <div>
              <Button
                onClick={() => {
                  setPointEditUser(record);
                }}>
                포인트 수정
              </Button>
            </div>
          </div>
        );
      },
    },
    {
      title: '리워드 코드',
      render: (record) => {
        return (
          <div>
            <div>{record?.rewardCode || '-'}</div>
            {record?.referral?.rewardCode && (
              <div>
                <div>가입 코드</div>
                <div>{record?.referral?.rewardCode}</div>
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: '회원가입 구분',
      dataIndex: 'services',
      render: (services, record) => {
        const loginInfo = { name: '이메일', email: '-' };
        if (services?.kakao) {
          loginInfo.name = '카카오';
          loginInfo.email = services.kakao.email || '-';
        } else if (services?.naver) {
          loginInfo.name = '네이버';
          loginInfo.email = services.naver.email || '-';
        } else if (services?.apple) {
          loginInfo.name = '애플';
          loginInfo.email = services.apple.email || '-';
        } else if (record.emails) {
          loginInfo.email = record.emails[0].address;
        }
        return (
          <div>
            <div>{loginInfo.name}</div>
            <div>{loginInfo.email}</div>
          </div>
        );
      },
    },
    {
      title: '이름',
      dataIndex: 'profile',
      align: 'center',
      render: (profile, record) => {
        return (
          <div>
            <button
              className="membership-badge"
              style={{
                background: record.membership?.color || 'transparent',
                border: 'none',
                cursor: 'pointer',
                color: record.membership?.color ? 'white' : '#242424',
              }}
              onClick={() => {
                setEditMembershipUserId(record._id);
              }}>
              {record.membership?.name || '멤버십 없음'}
            </button>
            <div>{profile?.name}</div>
            {profile?.children && <div style={{ whiteSpace: 'pre-wrap' }}>{getChildrenText(profile?.children)}</div>}
            {profile?.nickname && <div>{profile?.nickname}</div>}
          </div>
        );
      },
    },
    {
      title: '관심분야',
      dataIndex: 'profile',
      render: (profile) => {
        if (!profile?.categorySubList) {
          return '-';
        }
        return <div style={{ whiteSpace: 'pre-wrap' }}>{`${(profile.categorySubList || []).join('\n')}`}</div>;
      },
    },
    {
      title: '파트너 CS',
      dataIndex: 'profile',
      render: (profile) => {
        return (
          <div>
            <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
              <div>{profile?.mainCustomerService === 'phone' ? '(대표)전화' : '전화'}</div>
              <div>{profile?.customerServicePhone || '-'}</div>
            </div>
            <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
              <div>{profile?.mainCustomerService === 'url' ? '(대표)URL' : 'URL'}</div>
              <div>{profile?.customerServiceUrl || '-'}</div>
            </div>
          </div>
        );
      },
    },
    {
      title: '전화번호',
      dataIndex: 'profile',
      render: (profile) => {
        if (!profile?.phone) {
          return '-';
        }
        return (
          <Button
            onClick={() => {
              navigator.clipboard.writeText(profile.phone);
              toast.info('전화번호를 클립보드에 복사했습니다.', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                pauseOnFocusLoss: false,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
            }}>
            {profile.phone}
          </Button>
        );
      },
    },
    {
      title: '가입',
      render: (record) => {
        return (
          <div>
            <div>{moment(record.createdAt).format('YYYY.MM.DD HH:mm')}</div>
            {record.deletedAt && (
              <div style={{ color: 'red' }}>{moment(record.deletedAt).format('YYYY.MM.DD HH:mm')}</div>
            )}
          </div>
        );
      },
    },
    {
      title: '메모',
      dataIndex: 'deletedAt',
      render: (memo) => {
        if (!memo) {
          return <div style={{ width: '28px' }}>-</div>;
        }
        return memo;
      },
    },
  ];

  return (
    <div className="dashboard" style={{ position: 'relative' }}>
      <UserEditModal
        open={!!editUserId}
        userId={editUserId}
        needRefetch={() => {
          userSearchQuery.refetch();
        }}
        onClose={() => {
          setEditUserId(undefined);
        }}></UserEditModal>
      <UserMembershipEditModal
        open={!!editMembershipUserId}
        userId={editMembershipUserId}
        needRefetch={() => {
          userSearchQuery.refetch();
        }}
        onClose={() => {
          setEditMembershipUserId(undefined);
        }}></UserMembershipEditModal>
      <UserCreateModal
        open={showUserCreate}
        onClose={() => {
          setShowUserCreate(false);
        }}
        needRefetch={() => {
          userSearchQuery.refetch();
        }}></UserCreateModal>
      <UserPointEditModal
        open={!!pointEditUser}
        user={pointEditUser}
        onClose={() => {
          setPointEditUser(undefined);
        }}
        needRefetch={() => {
          userSearchQuery.refetch();
        }}></UserPointEditModal>
      <div className="page-header">
        <div>유저 관리</div>
        <div style={{ display: 'flex' }}>
          <div style={{ marginLeft: '10px' }}>
            <DatePicker
              onChange={onChangeStartDate}
              value={dayjs(utcTimcToLocalISOString(store.searchQuery.startDate as any))}
            />
          </div>
          <div style={{ marginLeft: '8px' }}>
            <DatePicker
              onChange={onChangeEndDate}
              value={dayjs(utcTimcToLocalISOString(store.searchQuery.endDate as any))}
            />
          </div>
        </div>
      </div>
      <Modal
        open={!!selectedTutorId}
        onCancel={() => {
          setSelectedTutorId(undefined);
        }}
        footer={
          <Button
            onClick={() => {
              setSelectedTutorId(undefined);
            }}>
            닫기
          </Button>
        }>
        <div>
          <OverlayScrollbarsComponent
            defer
            options={{ scrollbars: { autoHide: 'scroll' } }}
            style={{
              backgroundColor: '#ffffff',
              marginTop: 0,
              position: 'relative',
              height: 'calc(100dvh - 240px)',
              overflow: 'auto',
            }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', height: '44px', gap: '8px', alignItems: 'center', fontWeight: 'bold' }}>
                <div>{`${userInfo?.profile?.name}${
                  userInfo?.profile?.nickname ? `(${userInfo?.profile?.nickname})` : ''
                }`}</div>
                <div>
                  {userInfo?.profile?.contract?.licensePhoto ? (
                    <PhotoView src={cdnImageUrl(userInfo?.profile?.contract?.licensePhoto as string)}>
                      <Button>신분증</Button>
                    </PhotoView>
                  ) : (
                    <div>신분증 없음</div>
                  )}
                </div>
                <div>
                  {userInfo?.profile?.contract?.bankAccountPhoto ? (
                    <PhotoView src={cdnImageUrl(userInfo?.profile?.contract?.bankAccountPhoto as string)}>
                      <Button>통장</Button>
                    </PhotoView>
                  ) : (
                    <div>통장 없음</div>
                  )}
                </div>
              </div>
              <div style={{ position: 'relative' }}>
                <img
                  style={{
                    width: '88px',
                    height: '88px',
                    borderRadius: '50%',
                    objectFit: 'cover',
                    position: 'absolute',
                    left: '20px',
                    bottom: '-20px',
                  }}
                  src={userInfo?.profile?.imageUrl ? cdnImageUrl(userInfo?.profile.imageUrl) : TutorDefaultAvatar}
                  alt=""
                />
                <img
                  src={headerImageRef.current}
                  style={{ objectFit: 'cover', width: '100%' }}
                  alt="partner-profile-header"
                />
              </div>
              <div style={{ marginTop: '44px', padding: '0 20px' }}>
                <div
                  style={{
                    overflow: 'hidden',
                    color: 'var(--Neutral-100, #242424)',
                    textOverflow: 'ellipsis',
                    fontFamily: 'Pretendard',
                    fontSize: '28px',
                    fontStyle: 'normal',
                    fontWeight: 700,
                  }}>
                  {userInfo?.profile?.name}입니다.
                </div>
                {userInfo?.partnerProfile?.intro && (
                  <div style={{ marginTop: '8px' }}>
                    <div
                      style={{
                        color: 'var(--Neutral-80, #66686B)',
                        fontFamily: 'Pretendard',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        whiteSpace: 'pre-wrap',
                      }}>
                      <LinesEllipsis
                        text={userInfo?.partnerProfile?.intro}
                        maxLine={expandGreeting ? 100 : 3}
                        onReflow={(value) => {
                          setIsGreetingClamped(value.clamped || expandGreeting);
                        }}
                        trimRight></LinesEllipsis>
                    </div>
                    {isGreetingClamped && (
                      <button
                        className="link-button"
                        style={{
                          color: 'var(--Blue-60, #1890FF)',
                          fontFamily: 'Pretendard',
                          fontSize: '14px',
                          fontStyle: 'normal',
                          fontWeight: 500,
                          textDecorationLine: 'underline',
                          textDecorationStyle: 'solid',
                          textDecorationSkipInk: 'none',
                          textDecorationThickness: 'auto',
                          textUnderlineOffset: 'auto',
                          textUnderlinePosition: 'from-font',
                          marginTop: '8px',
                        }}
                        onClick={() => {
                          setExpandGreeting((value) => !value);
                        }}>
                        {expandGreeting ? <div>접기</div> : <div>더보기</div>}
                      </button>
                    )}
                  </div>
                )}
              </div>
              {userInfo?.partnerProfile?.mainActivityZone && (
                <>
                  <Divider style={{ margin: '20px 0' }} />
                  <div
                    style={{
                      color: 'var(--Neutral-100, #242424)',
                      fontFamily: 'Pretendard',
                      fontSize: '20px',
                      fontStyle: 'normal',
                      fontWeight: 700,
                      padding: '0 20px',
                    }}>
                    <div>주요 활동 지역</div>
                  </div>
                  <div
                    style={{
                      overflow: 'hidden',
                      color: 'var(--Neutral-80, #66686B)',
                      textOverflow: 'ellipsis',
                      fontFamily: 'Pretendard',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      marginTop: '8px',
                      padding: '0 20px',
                    }}>
                    {userInfo?.partnerProfile?.mainActivityZone
                      .map((item) => {
                        return `${item.city} ${item.district}`;
                      })
                      .join(', ')}
                  </div>
                </>
              )}

              <Divider style={{ margin: '20px 0' }} />
              <button
                className="link-button"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  color: 'var(--Neutral-100, #242424)',
                  fontFamily: 'Pretendard',
                  fontSize: '20px',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  padding: '0 20px',
                }}
                onClick={() => {
                  setShowExperiences((value) => !value);
                }}>
                활동 내역
                {showExperiences ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </button>
              {showExperiences && (
                <div style={{ padding: '20px' }}>
                  <div className={'partner-academy-content'} style={{ paddingBottom: '18px', paddingRight: '20px' }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '13px',
                        alignItems: 'center',
                      }}>
                      <div
                        style={{
                          overflow: 'hidden',
                          color: 'var(--Neutral-100, #242424)',
                          textOverflow: 'ellipsis',
                          fontFamily: 'Pretendard',
                          fontSize: '16px',
                          fontStyle: 'normal',
                          fontWeight: 700,
                        }}>
                        학력
                      </div>
                    </div>
                    {partnerAcamemics.map((item, index) => {
                      return (
                        <div key={`academic-${index}`}>
                          <div
                            style={{
                              flexDirection: 'row',
                              marginBottom: '10px',
                              alignItems: 'center',
                            }}>
                            <div
                              style={{
                                flex: 1,
                                paddingLeft: '10px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginLeft: 0,
                              }}>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '4px',
                                }}>
                                <div
                                  style={{
                                    backgroundColor: '#dadcdf',
                                    width: '6px',
                                    height: '6px',
                                    borderStyle: 'solid',
                                    borderRadius: '6px',
                                    marginRight: '13px',
                                    marginLeft: 0,
                                    marginTop: 7,
                                  }}
                                />
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                  }}>
                                  {`${item.school} ${item.graduation} (${item.degree ? degreeValue(item.degree) : ''})`}
                                  {item.approvalState === 'confirmApproval' && <QualifiedIcon />}
                                  <Select
                                    style={{ width: '120px' }}
                                    value={item.approvalState}
                                    options={[
                                      { value: ProfileApprovalState.WAIT, label: '승인 대기' },
                                      { value: ProfileApprovalState.CONFIRM, label: '승인' },
                                      { value: ProfileApprovalState.NOTHING, label: '첨부파일 없음' },
                                      { value: ProfileApprovalState.DENIED, label: '반려' },
                                    ]}
                                    onChange={(value) => {
                                      item.approvalState = value;
                                      if (selectedTutorId) {
                                        const newValues = [...partnerAcamemics];
                                        newValues[index] = { ...item, approvalState: value };

                                        partnerProfileMutation.mutate({
                                          userId: selectedTutorId,
                                          body: { academics: newValues },
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                {item.imageUrl && (
                                  <button
                                    className="link-button"
                                    onClick={() => {
                                      window.open(cdnImageUrl(item.imageUrl), '_blank');
                                    }}>
                                    <AttachmentIcon />
                                  </button>
                                )}
                              </div>
                              {item.year && item.graduation && item.degree && (
                                <div
                                  style={{
                                    fontSize: '12px',
                                    color: '#9b9da0',
                                    marginLeft: '20px',
                                    marginTop: '4px',
                                  }}>
                                  {item.year} - {item.graduation}{' '}
                                  {item.degree ? '(' + degreeValue(item.degree) + ' 과정)' : ''}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {partnerAcamemics.length === 0 && (
                      <div
                        style={{
                          color: '#9b9da0',
                          fontSize: '15px',
                          lineHeight: '22px',
                          marginBottom: '10px',
                        }}>
                        파트너님께서 학력 등록을 준비하고 계세요.
                      </div>
                    )}
                  </div>

                  <div className={'partner-career-content'} style={{ paddingBottom: '18px', paddingRight: '20px' }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '13px',
                        alignItems: 'center',
                      }}>
                      <div
                        style={{
                          overflow: 'hidden',
                          color: 'var(--Neutral-100, #242424)',
                          textOverflow: 'ellipsis',
                          fontFamily: 'Pretendard',
                          fontSize: '16px',
                          fontStyle: 'normal',
                          fontWeight: 700,
                        }}>
                        경력
                      </div>
                    </div>
                    {partnerCareers.map((item, index) => {
                      return (
                        (item.approvalState === 'confirmApproval' ||
                          item.approvalState === 'beforeApproval' ||
                          item.approvalState === 'waitApproval') && (
                          <div key={`career-${index}`}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginBottom: '10px',
                                alignItems: 'center',
                              }}>
                              <div style={{ flex: 1, paddingLeft: '10px', justifyContent: 'center' }}>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'flex-start',
                                  }}>
                                  <div
                                    style={{
                                      backgroundColor: '#dadcdf',
                                      width: '6px',
                                      height: '6px',
                                      borderStyle: 'solid',
                                      borderRadius: '6px',
                                      marginRight: '13px',
                                      marginLeft: 0,
                                      marginTop: '8px',
                                    }}
                                  />
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      flexWrap: 'wrap',
                                    }}>
                                    {(item.career || '').split(' ').map((item, idx) => {
                                      return (
                                        <div
                                          key={`company-item-${idx}`}
                                          style={{
                                            fontSize: '15px',
                                            lineHeight: '22px',
                                            marginRight: '6px',
                                            color: '#424242',
                                          }}>
                                          {item}
                                        </div>
                                      );
                                    })}
                                    {item.approvalState === 'confirmApproval' && <QualifiedIcon />}
                                    <Select
                                      style={{ width: '120px' }}
                                      value={item.approvalState}
                                      options={[
                                        { value: ProfileApprovalState.WAIT, label: '승인 대기' },
                                        { value: ProfileApprovalState.CONFIRM, label: '승인' },
                                        { value: ProfileApprovalState.NOTHING, label: '첨부파일 없음' },
                                        { value: ProfileApprovalState.DENIED, label: '반려' },
                                      ]}
                                      onChange={(value) => {
                                        item.approvalState = value;

                                        if (selectedTutorId) {
                                          const newValues = [...partnerCareers];
                                          newValues[index] = { ...item, approvalState: value };

                                          partnerProfileMutation.mutate({
                                            userId: selectedTutorId,
                                            body: { careers: newValues },
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                  {item.imageUrl && (
                                    <button
                                      className="link-button"
                                      onClick={() => {
                                        window.open(cdnImageUrl(item.imageUrl), '_blank');
                                      }}>
                                      <AttachmentIcon />
                                    </button>
                                  )}
                                </div>
                                {(item.startYearMonth || item.period) && (
                                  <div
                                    style={{
                                      fontSize: '12px',
                                      color: '#9b9da0',
                                      marginLeft: '20px',
                                      marginTop: '4px',
                                    }}>
                                    {item.startYearMonth
                                      ? `${item.startYearMonth.replace('-', '.')} - ${item.endYearMonth?.replace(
                                          '-',
                                          '.',
                                        )}`
                                      : item.period}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )
                      );
                    })}
                    {partnerCareers.length === 0 && (
                      <div
                        style={{
                          color: '#9b9da0',
                          fontSize: '15px',
                          lineHeight: '22px',
                          marginBottom: '10px',
                        }}>
                        파트너님께서 경력 등록을 준비하고 계세요.
                      </div>
                    )}
                  </div>

                  <div
                    className={'partner-certificate-content'}
                    style={{ paddingBottom: '18px', paddingRight: '20px' }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginBottom: '13px',
                      }}>
                      <div
                        style={{
                          overflow: 'hidden',
                          color: 'var(--Neutral-100, #242424)',
                          textOverflow: 'ellipsis',
                          fontFamily: 'Pretendard',
                          fontSize: '16px',
                          fontStyle: 'normal',
                          fontWeight: 700,
                        }}>
                        자격증
                      </div>
                    </div>
                    {partnerCertificates.map((item, index) => {
                      return (
                        (item.approvalState === 'confirmApproval' ||
                          item.approvalState === 'beforeApproval' ||
                          item.approvalState === 'waitApproval') && (
                          <div key={`certificate-${index}`}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginBottom: '10px',
                                alignItems: 'center',
                              }}>
                              <div style={{ flex: 1, paddingLeft: '10px', justifyContent: 'center' }}>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'flex-start',
                                  }}>
                                  <div
                                    style={{
                                      backgroundColor: '#dadcdf',
                                      width: '6px',
                                      height: '6px',
                                      borderStyle: 'solid',
                                      borderRadius: '6px',
                                      marginRight: '13px',
                                      marginLeft: 0,
                                      marginTop: '8px',
                                    }}
                                  />
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      flexWrap: 'wrap',
                                    }}>
                                    {(item.name || '').split(' ').map((item, idx) => {
                                      return (
                                        <div
                                          key={`name-item-${idx}`}
                                          style={{
                                            fontSize: '15px',
                                            color: '#424242',
                                            marginRight: '6px',
                                            lineHeight: '22px',
                                          }}>
                                          {item}
                                        </div>
                                      );
                                    })}
                                    {item.approvalState === 'confirmApproval' && <QualifiedIcon />}
                                    <Select
                                      style={{ width: '120px' }}
                                      value={item.approvalState}
                                      options={[
                                        { value: ProfileApprovalState.WAIT, label: '승인 대기' },
                                        { value: ProfileApprovalState.CONFIRM, label: '승인' },
                                        { value: ProfileApprovalState.NOTHING, label: '첨부파일 없음' },
                                        { value: ProfileApprovalState.DENIED, label: '반려' },
                                      ]}
                                      onChange={(value) => {
                                        item.approvalState = value;

                                        if (selectedTutorId) {
                                          const newValues = [...partnerCertificates];
                                          newValues[index] = { ...item, approvalState: value };

                                          partnerProfileMutation.mutate({
                                            userId: selectedTutorId,
                                            body: { certificates: newValues },
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                  {item.imageUrl && (
                                    <button
                                      className="link-button"
                                      onClick={() => {
                                        window.open(cdnImageUrl(item.imageUrl), '_blank');
                                      }}>
                                      <AttachmentIcon />
                                    </button>
                                  )}
                                </div>
                                {item.issuer && (
                                  <div
                                    style={{
                                      fontSize: '12px',
                                      color: '#9b9da0',
                                      marginLeft: '20px',
                                      marginTop: '4px',
                                    }}>
                                    {item.issuer} -{' '}
                                    {item.issuanceYearMonth ? `${item.issuanceYearMonth.replace('-', '.')}` : ''}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )
                      );
                    })}
                    {partnerCertificates.length === 0 && (
                      <div
                        style={{
                          color: '#9b9da0',
                          fontSize: '15px',
                          lineHeight: '22px',
                          marginBottom: '10px',
                        }}>
                        파트너님께서 자격증 등록을 준비하고 계세요.
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </OverlayScrollbarsComponent>
        </div>
      </Modal>
      <div style={{ display: 'flex' }}>
        <div className="page-content" style={{ width: '100%', height: 'calc(100vh - 80px)', overflow: 'auto' }}>
          <div style={{ display: 'flex', gap: '12px', width: '100%', height: '52px', alignItems: 'center' }}>
            <Select
              value={store.searchQuery.type || 'all'}
              style={{ width: 120 }}
              onChange={(value) => {
                store.updateSearchQuery({ type: value });
              }}
              options={[
                { value: 'all', label: '모든 역할' },
                { value: Roles.PARENT, label: ROLE_LABELS.parent },
                { value: Roles.TUTOR, label: ROLE_LABELS.teacher },
                { value: Roles.SELLER, label: ROLE_LABELS.seller },
                { value: Roles.PARTNER, label: ROLE_LABELS.partner },
                { value: Roles.MEMBERSHIP_PARTNER, label: ROLE_LABELS.membership_partner },
                { value: Roles.SUBADMIN, label: ROLE_LABELS.subadmin },
                { value: Roles.SALEADMIN, label: ROLE_LABELS.saleadmin },
                { value: Roles.ADMIN, label: ROLE_LABELS.admin },
              ]}
            />
            <Select
              value={store.searchQuery.profileStatus || 'all'}
              style={{ width: 180 }}
              onChange={(value) => {
                store.updateSearchQuery({ profileStatus: value });
              }}
              options={[
                { value: 'all', label: '튜터 프로필 상태 전체' },
                { value: ProfileApprovalState.WAIT, label: '승인 대기' },
                { value: ProfileApprovalState.CONFIRM, label: '승인' },
                { value: ProfileApprovalState.NOTHING, label: '첨부파일 없음' },
              ]}
            />
            <Select
              value={store.searchQuery.sort || 'createdAt'}
              style={{ width: 180 }}
              onChange={(value) => {
                store.updateSearchQuery({ sort: value });
              }}
              options={[
                { value: 'createdAt', label: '최근 가입일 순' },
                { value: 'updatedAt', label: '최근 업데이트 순' },
              ]}
            />
            <Input
              style={{ width: '240px' }}
              value={searchKeyword}
              placeholder={'검색어 입력 후 엔터'}
              onChange={(e) => {
                setSearchKeyword(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.code === 'Enter' && searchKeyword) {
                  store.updateSearchQuery({ keyword: searchKeyword });
                }
              }}></Input>
            <Button
              onClick={() => {
                setSearchKeyword(undefined);
                store.resetSearchQuery();
              }}>
              검색 초기화
            </Button>
            <div style={{ display: 'flex', marginLeft: 'auto', gap: '12px', alignItems: 'center' }}>
              <Button
                onClick={() => {
                  setShowUserCreate(true);
                }}>
                신규 유저 생성
              </Button>
              <Button
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  getExcelFile();
                }}>
                검색결과 엑셀 파일 다운로드
              </Button>
            </div>
          </div>
          <Table
            columns={TABLE_COLUMNS}
            scroll={{ x: 'max-content', y: 'calc(100dvh - 280px)' }}
            style={{ whiteSpace: 'pre-wrap' }}
            rowKey={(record) => record?._id}
            dataSource={store.searchResultContainer.contents.filter((user) => user?._id)}
            pagination={{
              current: (store.searchResultContainer.page || 0) + 1,
              pageSize: store.searchResultContainer.size || 20,
              total: store.searchResultContainer.total,
            }}
            loading={userSearchQuery.isLoading}
            onChange={(e) => {
              store.updateSearchQuery({ page: (e.current || 2) - 1, size: e.pageSize || 20 });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default UsersPage;
