import * as api from '@apis/admin-user';
import FileUploadButton from '@components/common/FileUploadButton';
import { Roles, ROLE_LABELS } from '@consts/role';
import { closestCenter, DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { CreateSellerUserBody } from '@models/auth';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { useMutation } from '@tanstack/react-query';
import { removeTempProperties } from '@utils/dataTransform';
import { resizeImage } from '@utils/image';
import { createRandomNumber, extractFileName } from '@utils/string';
import { Button, Checkbox, Input, Modal, Select } from 'antd';
import { RcFile } from 'antd/es/upload';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

export interface UserCreateModalProps {
  open?: boolean;
  onClose?: () => void;
  needRefetch?: () => void;
}

interface SortableItemProps {
  onDeleteItem?: (event: any) => void;
  id: any;
  item: { key: string; label: string };
  canDelete?: boolean;
}

export const SortableItem: React.FC<SortableItemProps> = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const { onDeleteItem, item } = props;
  return (
    <div ref={setNodeRef} style={{ ...style }} {...attributes} {...listeners}>
      <div
        style={{
          display: 'flex',
          width: '80px',
          borderRadius: '8px',
          border: '1px solid #aaaaaa',
          justifyContent: 'space-between',
          alignItems: 'center',
          fontSize: '12px',
          padding: '4px',
        }}>
        <div style={{ flex: 1, textAlign: 'center' }}>{item.label}</div>
        <div style={{ flexShrink: 0 }}>
          {props.canDelete && (
            <button
              style={{ width: '20px' }}
              className="link-button"
              onClick={(e) => {
                onDeleteItem && onDeleteItem(e);
              }}>
              X
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export const UserCreateModal: React.FC<UserCreateModalProps> = (props) => {
  const [selectedRole, setSelectedRole] = useState<Roles>();
  const [createUserData, setCreateUserData] = useState<CreateSellerUserBody>({ type: 'email' });
  const [modifiedFiles, setModifiedFiles] = useState<{ [key: string]: File }>({});

  const dialog = useOkCancelDialog();

  useEffect(() => {
    setCreateUserData({ type: 'email', password: createRandomNumber() });
  }, [props.open]);

  const createSellerMutation = useMutation({
    mutationFn: api.createUser,
    onSuccess: async () => {
      props.needRefetch && props.needRefetch();
      props.onClose && props.onClose();
      dialog.open({
        content: '생성되었습니다.',
        type: 'ok',
      });
    },
    onError: async (e: any) => {
      props.needRefetch && props.needRefetch();
      props.onClose && props.onClose();
      dialog.open({
        title: '오류',
        content: e?.response?.data?.message,
        type: 'ok',
      });
    },
  });

  const onChangeImage = async (files: FileList, key: string) => {
    const { fileName } = extractFileName(files[0].name);
    const newFiles = await Promise.all(
      Array.from(files)
        .slice(0, 10)
        .map(async (file) => {
          const result = await resizeImage(file, { maxWidth: 1024 });

          return result;
        }),
    );
    const contentTypes = newFiles[0].type.split('/');
    const uploadFileName = `${fileName}.${contentTypes[1]}`;
    const newImageFile = new File([newFiles[0].image], `${fileName}.${contentTypes[1]}`) as RcFile;
    const tempUrl = URL.createObjectURL(newFiles[0].image);
    setCreateUserData((prev) => {
      return { ...prev, [key]: uploadFileName, [`${key}Temp`]: tempUrl };
    });

    setModifiedFiles((prev) => {
      return { ...prev, [key]: newImageFile };
    });
  };

  const sortableSensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 10 },
    }),
  );

  const onSortEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    const roles = createUserData.roles;

    if (active.id !== over?.id) {
      if (roles) {
        const oldIndex = roles.findIndex((item) => item === active.id);
        const newIndex = roles.findIndex((item) => item === over?.id);
        const newData = arrayMove(roles, oldIndex, newIndex);
        setCreateUserData((prev) => {
          return { ...prev, roles: newData };
        });
      }
    }
  };

  return (
    <Modal
      styles={{ footer: { height: '44px' } }}
      open={props.open}
      footer={
        <div style={{ width: '100%' }}>
          <Button
            style={{
              width: 120,
            }}
            onClick={props.onClose}>
            취소
          </Button>
          <Button
            style={{
              width: 120,
              marginLeft: '8px',
            }}
            onClick={() => {
              const formData: FormData = new FormData();
              const json = removeTempProperties({ ...createUserData });

              formData.append('json', JSON.stringify(json));
              Object.values(modifiedFiles).forEach((file) => {
                formData.append('files', file);
              });

              createSellerMutation.mutate(formData);
            }}>
            추가하기
          </Button>
        </div>
      }
      onCancel={props.onClose}>
      <OverlayScrollbarsComponent
        defer
        options={{ scrollbars: { autoHide: 'scroll' } }}
        style={{
          backgroundColor: '#ffffff',
          marginTop: 0,
          position: 'relative',
          height: 'calc(100dvh - 240px)',
          overflow: 'auto',
        }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              fontSize: 20,
              fontWeight: 'bold',
              textAlign: 'center',
              marginBottom: 10,
            }}>
            신규 유저 생성
          </div>
          <div style={{ textAlign: 'left', padding: 50 }}>
            <div style={{ marginTop: 10, fontWeight: 'bold' }}>
              계정 비밀번호:
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(`${createUserData.password}`);
                  toast.dark('비밀번호를 클립보드에 복사했습니다', {
                    position: 'top-center',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    pauseOnFocusLoss: false,
                    draggable: true,
                    progress: undefined,
                    bodyStyle: {
                      color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                      textAlign: 'center',
                      fontFamily: 'Pretendard',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '20px',
                    },
                    theme: 'dark',
                  });
                }}>
                {createUserData.password}
              </Button>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                marginBottom: '8px',
                justifyContent: 'space-between',
              }}>
              <div>역할</div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <Select
                  value={selectedRole}
                  style={{ width: '120px', marginLeft: '12px' }}
                  options={Object.keys(ROLE_LABELS).map((key) => {
                    return { value: key, label: ROLE_LABELS[key] };
                  })}
                  onChange={(value) => {
                    setSelectedRole(value);
                  }}></Select>
                <Button
                  onClick={() => {
                    setCreateUserData((prev) => {
                      const roles = createUserData.roles || [];
                      if (selectedRole && !roles.includes(selectedRole)) {
                        roles?.push(selectedRole);
                      }
                      return { ...prev, roles: roles };
                    });
                  }}>
                  추가
                </Button>
              </div>
            </div>
            <div>
              <DndContext sensors={sortableSensors} collisionDetection={closestCenter} onDragEnd={onSortEnd}>
                <SortableContext
                  items={(createUserData.roles || []).map((role) => {
                    return { id: role, key: role, label: ROLE_LABELS[role] };
                  })}
                  strategy={verticalListSortingStrategy}>
                  <div style={{ width: '100%', display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                    {(createUserData.roles || []).map((key, index) => {
                      const item = { id: key, key: key, label: ROLE_LABELS[key] };
                      return (
                        <SortableItem
                          item={item}
                          id={item.id}
                          canDelete={(createUserData.roles || []).length > 1}
                          onDeleteItem={() => {
                            setCreateUserData((prev) => {
                              const roles = createUserData.roles;
                              roles?.splice(index, 1);
                              return { ...prev, roles: roles };
                            });
                          }}></SortableItem>
                      );
                    })}
                  </div>
                </SortableContext>
              </DndContext>
            </div>
            <div style={{ marginTop: 10 }}>
              가입자 실명
              <Input
                placeholder="가입자 실명(대표자명)을 입력하세요"
                value={createUserData?.name}
                onChange={(e) => {
                  setCreateUserData((prev) => {
                    return { ...prev, name: e.target.value };
                  });
                }}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              닉네임(활동명)
              <Input
                placeholder="닉네임(활동명)을 입력하세요"
                value={createUserData?.nickname}
                onChange={(e) => {
                  setCreateUserData((prev) => {
                    return { ...prev, nickname: e.target.value };
                  });
                }}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              연락처(휴대전화)
              <Input
                placeholder="연락처를 입력하세요"
                value={createUserData?.phoneNumber}
                onChange={(e) => {
                  setCreateUserData((prev) => {
                    return { ...prev, phoneNumber: e.target.value };
                  });
                }}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              이메일
              <Input
                placeholder="이메일를 입력하세요"
                value={createUserData?.email}
                onChange={(e) => {
                  setCreateUserData((prev) => {
                    return { ...prev, email: e.target.value.trim() };
                  });
                }}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              고객센터(연락처)
              <Input
                placeholder="고객센터 연락처를 입력하세요"
                value={createUserData?.customerServicePhone}
                onChange={(e) => {
                  setCreateUserData((prev) => {
                    return {
                      ...prev,
                      customerServicePhone: e.target.value,
                    };
                  });
                }}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              고객센터(URL)
              <Input
                placeholder="URL 주소를 입력하세요"
                value={createUserData?.customerServiceUrl}
                onChange={(e) => {
                  setCreateUserData((prev) => {
                    return {
                      ...prev,
                      customerServiceUrl: e.target.value,
                    };
                  });
                }}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              대표고객센터
              <br />
              <Select
                placeholder={'대표고객센터 선택'}
                value={createUserData?.mainCustomerService}
                options={[
                  { value: 'phone', label: '전화번호' },
                  { value: 'url', label: 'URL' },
                ]}
                onSelect={(value) => {
                  setCreateUserData((prev) => {
                    return {
                      ...prev,
                      mainCustomerService: value,
                    };
                  });
                }}></Select>
            </div>

            <div style={{ marginTop: 10 }}>
              수수료율(숫자만 입력, 단위: %)
              <Input
                type="number"
                value={createUserData?.commissionRate}
                placeholder="수수료율을 입력하세요"
                onChange={(e) => {
                  setCreateUserData((prev) => {
                    return {
                      ...prev,
                      commissionRate: e.target.value,
                    };
                  });
                }}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              계약 현황
              <br />
              <Select
                value={createUserData?.contractStatus}
                style={{ width: 200 }}
                placeholder="계약 상태 선택"
                onChange={(value) => {
                  setCreateUserData((prev) => {
                    return { ...prev, contractStatus: value };
                  });
                }}>
                <Select.Option key={'sales'} value={'sales'}>
                  판매중
                </Select.Option>
                <Select.Option key={'hold'} value={'hold'}>
                  홀드
                </Select.Option>
                <Select.Option key={'end'} value={'end'}>
                  계약 종료
                </Select.Option>
              </Select>
            </div>
            <div style={{ marginTop: 10 }}>
              외부 판매 채널 입점
              <br />
              <Checkbox
                checked={createUserData?.salesChannels?.includes('naver')}
                onChange={(e) => {
                  setCreateUserData((prev) => {
                    return { ...prev, salesChannels: e.target.checked ? ['naver'] : [] };
                  });
                }}>
                네이버 스토어
              </Checkbox>
            </div>

            <div style={{ marginTop: 10, display: 'flex', flexDirection: 'column', gap: '4px', width: '120px' }}>
              <div>계약서</div>
              <FileUploadButton
                accept={['jpg', 'jpeg', 'png', 'tif', 'tiff', 'webp', 'svg']
                  .map((ext) => {
                    return '.' + ext;
                  })
                  .join(',')}
                className="link-button"
                style={{
                  fontSize: '14px',
                  height: '32px',
                  padding: '4px 12px',
                  borderRadius: '6px',
                  backgroundColor: '#ffffff',
                  border: '1px solid #d9d9d9',
                  color: 'rgba(0, 0, 0, 0.88)',
                  boxShadow: '0 2px 0 rgba(0, 0, 0, 0.02)',
                  cursor: 'pointer',
                }}
                onChange={(e) => {
                  if (e.target.files) {
                    const files = e.target.files;
                    onChangeImage(files, 'contractPhoto');
                  }
                }}>
                이미지 업로드
              </FileUploadButton>
              <img
                src={createUserData?.contractPhotoTemp || createUserData?.contractPhoto}
                style={{ width: '120px', height: '160px', objectFit: 'cover' }}
              />
            </div>

            <div style={{ marginTop: 10, display: 'flex', flexDirection: 'column', gap: '4px', width: '120px' }}>
              <div>사업자 OR 신분증</div>
              <FileUploadButton
                accept={['jpg', 'jpeg', 'png', 'tif', 'tiff', 'webp', 'svg']
                  .map((ext) => {
                    return '.' + ext;
                  })
                  .join(',')}
                className="link-button"
                style={{
                  fontSize: '14px',
                  height: '32px',
                  padding: '4px 12px',
                  borderRadius: '6px',
                  backgroundColor: '#ffffff',
                  border: '1px solid #d9d9d9',
                  color: 'rgba(0, 0, 0, 0.88)',
                  boxShadow: '0 2px 0 rgba(0, 0, 0, 0.02)',
                  cursor: 'pointer',
                }}
                onChange={(e) => {
                  if (e.target.files) {
                    const files = e.target.files;
                    onChangeImage(files, 'licensePhoto');
                  }
                }}>
                이미지 업로드
              </FileUploadButton>
              <img
                src={createUserData?.licensePhotoTemp || createUserData?.licensePhoto}
                style={{ width: '120px', height: '160px', objectFit: 'cover' }}
              />
            </div>
            <div style={{ marginTop: 10, display: 'flex', flexDirection: 'column', gap: '4px', width: '120px' }}>
              <div>통장 사본</div>
              <FileUploadButton
                accept={['jpg', 'jpeg', 'png', 'tif', 'tiff', 'webp', 'svg']
                  .map((ext) => {
                    return '.' + ext;
                  })
                  .join(',')}
                className="link-button"
                style={{
                  fontSize: '14px',
                  height: '32px',
                  padding: '4px 12px',
                  borderRadius: '6px',
                  backgroundColor: '#ffffff',
                  border: '1px solid #d9d9d9',
                  color: 'rgba(0, 0, 0, 0.88)',
                  boxShadow: '0 2px 0 rgba(0, 0, 0, 0.02)',
                  cursor: 'pointer',
                }}
                onChange={(e) => {
                  if (e.target.files) {
                    const files = e.target.files;
                    onChangeImage(files, 'bankAccountPhoto');
                  }
                }}>
                이미지 업로드
              </FileUploadButton>
              <img
                src={createUserData?.bankAccountPhotoTemp || createUserData?.bankAccountPhoto}
                style={{ width: '120px', height: '160px', objectFit: 'cover' }}
              />
            </div>
          </div>
        </div>
      </OverlayScrollbarsComponent>
    </Modal>
  );
};
