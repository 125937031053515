import BadgeBlueImage from '@assets/images/app/bg-badge-blue.png';
import BadgePurpleImage from '@assets/images/app/bg-badge-purple.png';
import BadgeRedImage from '@assets/images/app/bg-badge-red.png';
import FileUploadButton from '@components/common/FileUploadButton';
import { FeedData } from '@models/feed';
import { cdnImageUrl, resizeImage } from '@utils/image';
import { extractFileName } from '@utils/string';
import { Input, Select } from 'antd';
import { RcFile } from 'antd/es/upload';
import React, { useEffect, useState } from 'react';
import DeeplinkActionEdit from './DeepLinkActionEdit';

export const MdPickItemEdit: React.FC<{
  clear?: boolean;
  data: Partial<FeedData>;
  onChange?: (data: Partial<FeedData>) => void;
  onChangeImageFile?: (files: { [key: string]: File }) => void;
}> = (props) => {
  const [changedData, setChangedData] = useState<FeedData>();
  const { data } = props;
  const item = { ...data, ...changedData };
  const [imageFiles, setImageFiles] = useState<{ [key: string]: File }>({});

  useEffect(() => {
    if (props.clear) {
      setChangedData(undefined);
    }
  }, [props.clear]);

  useEffect(() => {
    props.onChangeImageFile && props.onChangeImageFile(imageFiles);
  }, [imageFiles]);

  useEffect(() => {
    if (changedData) {
      props.onChange && props.onChange({ ...data, ...changedData });
    }
  }, [changedData]);

  const onChangeImage = async (files: FileList) => {
    const { fileName } = extractFileName(files[0].name);
    const newFiles = await Promise.all(
      Array.from(files)
        .slice(0, 10)
        .map(async (file) => {
          const result = await resizeImage(file, { maxWidth: 728 });

          return result;
        }),
    );
    const contentTypes = newFiles[0].type.split('/');
    const uploadFileName = `${fileName}.${contentTypes[1]}`;
    const newImageFile = new File([newFiles[0].image], `${fileName}.${contentTypes[1]}`) as RcFile;
    const tempUrl = URL.createObjectURL(newFiles[0].image);
    setChangedData((prev) => {
      const newValues = { ...(prev || item) };
      return { ...newValues, brandImageUrl: uploadFileName, brandImageUrlTemp: tempUrl } as FeedData;
    });

    setImageFiles((value) => {
      const newValue = { ...value };
      newValue[`${data.type}`] = newImageFile;
      return newValue;
    });
  };

  return (
    <div>
      <div style={{ display: 'flex', marginBottom: '8px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '362px', marginRight: '8px' }}>
          <div style={{ paddingTop: '12px', paddingBottom: '12px' }}>
            <div
              style={{
                flex: 1,
                borderRadius: '12px',
                marginLeft: '24px',
                marginRight: '24px',
                backgroundColor: 'white',
                border: '1px solid #e8eaed',
                cursor: 'pointer',
              }}>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  aspectRatio: 1.48,
                  position: 'relative',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderTopLeftRadius: '10px',
                  borderTopRightRadius: '10px',
                  backgroundImage: `url(${cdnImageUrl(item.brandImageUrlTemp || item.brandImageUrl)})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}>
                {item?.brandTag && (
                  <div style={{ position: 'absolute', bottom: '16px', left: '16px' }}>
                    <div
                      style={{
                        display: 'flex',
                        backgroundColor: 'rgba(255,255,255,.9)',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '32px',
                        borderRadius: '4px',
                        padding: '0 12px',
                      }}>
                      <div style={{ color: '#242424', fontSize: '14px', fontWeight: 'bold' }}>{item.brandTag}</div>
                    </div>
                  </div>
                )}
              </div>
              <div
                style={{
                  padding: '20px',
                  position: 'relative',
                }}>
                <div style={{ position: 'absolute', top: '-22px', right: 0 }}>
                  {item.badgeStatus == 'onlyBadge' && (
                    <div style={{ position: 'relative', alignItems: 'center', justifyContent: 'center' }}>
                      <img
                        src={BadgeBlueImage}
                        style={{
                          display: 'flex',
                          width: '80px',
                          height: '70px',
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      />
                    </div>
                  )}
                  {item.badgeStatus == 'lightningBadge' && (
                    <div style={{ position: 'relative', alignItems: 'center', justifyContent: 'center' }}>
                      <img
                        src={BadgePurpleImage}
                        style={{
                          width: '80px',
                          height: '70px',
                          position: 'absolute',
                          top: 0,
                          right: 0,
                        }}
                      />
                    </div>
                  )}
                  {item.badgeStatus == 'saleBadge' && (
                    <div style={{ position: 'relative', alignItems: 'center', justifyContent: 'center' }}>
                      <img
                        src={BadgeRedImage}
                        style={{
                          width: '80px',
                          height: '70px',
                          position: 'absolute',
                          top: 0,
                          right: 0,
                        }}
                      />
                    </div>
                  )}
                  {(item.badgeStatus == 'onlyBadge' ||
                    item.badgeStatus == 'lightningBadge' ||
                    item.badgeStatus == 'saleBadge') && (
                    <div
                      style={{
                        display: 'flex',
                        width: '80px',
                        height: '70px',
                        position: 'absolute',
                        top: 0,
                        right: '2px',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                      <div style={{ color: 'white', fontSize: '12px', fontWeight: 'bold' }}>{item.badgeText}</div>
                    </div>
                  )}
                </div>
                <div style={{ marginBottom: 0 }}>
                  <div
                    style={{
                      display: 'flex',
                      color: '#424242',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      lineHeight: '17px',
                    }}>
                    {item.tags &&
                      item.tags.map((tag: string, index2: number) => {
                        return (
                          <div key={`curation-tag-${index2}`} style={{ marginRight: '6px' }}>
                            #{tag}&nbsp;
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div style={{ marginTop: '8px', marginBottom: '10px' }}>
                  <div style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <div style={{ fontWeight: 'bold', fontSize: '26px', lineHeight: '34px', color: '#2e2e2e' }}>
                      {item.brandTitle && item.brandTitle.split('\n')[0]}
                    </div>
                  </div>
                  <div style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <div style={{ fontWeight: 'bold', fontSize: '26px', lineHeight: '34px', color: '#2e2e2e' }}>
                      {item.brandTitle && item.brandTitle.split('\n')[1]}
                    </div>
                  </div>
                </div>
                <div style={{ width: '100%' }}>
                  <div style={{ fontSize: '14px', color: '#808387', lineHeight: '21px', whiteSpace: 'pre-wrap' }}>
                    {item.brandDesc}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FileUploadButton
            accept={['jpg', 'jpeg', 'png', 'tif', 'tiff', 'webp', 'svg']
              .map((ext) => {
                return '.' + ext;
              })
              .join(',')}
            className="link-button"
            style={{
              fontSize: '14px',
              height: '32px',
              padding: '4px 15px',
              borderRadius: '6px',
              backgroundColor: '#ffffff',
              border: '1px solid #d9d9d9',
              color: 'rgba(0, 0, 0, 0.88)',
              boxShadow: '0 2px 0 rgba(0, 0, 0, 0.02)',
              cursor: 'pointer',
            }}
            onChange={(e) => {
              if (e.target.files) {
                const files = e.target.files;
                onChangeImage(files);
              }
            }}>
            이미지 변경
          </FileUploadButton>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', marginRight: '8px' }}>
          <Input
            value={item.brandTag}
            onChange={(e) => {
              setChangedData((prev) => {
                const newValues = { ...(prev || item) };
                return { ...newValues, brandTag: e.target.value } as FeedData;
              });
            }}></Input>
          <div style={{ display: 'flex', gap: '4px' }}>
            <Select
              style={{ width: '100px' }}
              value={item.badgeStatus}
              options={[
                { value: 'lightningBadge', label: '번개' },
                { value: 'saleBadge', label: '할인' },
              ]}
              onChange={(value) => {
                setChangedData((prev) => {
                  const newValues = { ...(prev || item) };
                  return { ...newValues, badgeStatus: value } as FeedData;
                });
              }}></Select>
            <Input
              value={item.badgeText}
              onChange={(e) => {
                setChangedData((prev) => {
                  const newValues = { ...(prev || item) };
                  return { ...newValues, badgeText: e.target.value } as FeedData;
                });
              }}></Input>
          </div>
          <Input
            value={(item.tags || []).join(',')}
            onChange={(e) => {
              setChangedData((prev) => {
                const newValues = { ...(prev || item) };
                return { ...newValues, tags: e.target.value.split(',').map((tag) => tag.trim()) } as FeedData;
              });
            }}></Input>
          <Input.TextArea
            value={item.brandTitle}
            onChange={(e) => {
              setChangedData((prev) => {
                const newValues = { ...(prev || item) };
                return { ...newValues, brandTitle: e.target.value } as FeedData;
              });
            }}></Input.TextArea>
          <Input.TextArea
            value={item.brandDesc}
            onChange={(e) => {
              setChangedData((prev) => {
                const newValues = { ...(prev || item) };
                return { ...newValues, brandDesc: e.target.value } as FeedData;
              });
            }}></Input.TextArea>
          <div style={{ border: '1px solid #eaeaea', borderRadius: '8px', padding: '8px' }}>
            <div>클릭시 이동 설정</div>
            <DeeplinkActionEdit
              data={item}
              onChange={(value) => {
                setChangedData((prev) => {
                  const newValues = { ...(prev || item) };
                  return { ...newValues, ...value } as FeedData;
                });
              }}></DeeplinkActionEdit>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MdPickItemEdit;
