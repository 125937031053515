import * as api from '@apis/admin-credit-points';
import OnetimePointCouponsModal from '@components/credit-point/OnetimePointCouponsModal';
import { PointCouponCreateModal } from '@components/credit-point/PointCouponCreateModal';
import { useAuthentication } from '@hooks/authentication';
import { AdminCreditPointCoupon } from '@models/credit-points';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { usePointCouponsStore } from '@stores/pointCouponsStore';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, DatePicker, Input, Modal, Switch, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

const POINT_COUPON_TYPE_TEXT = {
  one: '1회',
  multi: '반복',
};

export const CouponsPage: React.FC = () => {
  const [selectedCouponId, setSelectedCouponId] = useState<string>();
  const [searchKeyword, setSearchKeyword] = useState<string>();
  const searchInputRef = useRef<any>();
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [changedValidEndDate, setChangedValidEndDate] = useState<string>();
  const [editValidEndDateData, setEditValidEndDateData] = useState<AdminCreditPointCoupon>();
  const [changedRequiredSignUpDate, setChangedRequiredSignUpDate] = useState<string>();
  const [editRequiredSignUpDateData, setEditRequiredSignUpDateData] = useState<AdminCreditPointCoupon>();

  const store = usePointCouponsStore();
  const dialog = useOkCancelDialog();
  useAuthentication();

  const couponsQuery = useQuery({
    queryKey: [`point-coupons`, JSON.stringify(store.searchQuery)],
    queryFn: () => api.searchCreditPointCoupons(store.searchQuery),
  });

  const updatePointCouponMutation = useMutation({
    mutationFn: api.updateCreditPointCoupon,
    onSuccess: async () => {
      setSelectedCouponId(undefined);
      setChangedValidEndDate(undefined);
      dialog.open({
        content: '수정했습니다.',
        type: 'ok',
      });

      couponsQuery.refetch();
    },
    onError: async (e: any) => {
      setSelectedCouponId(undefined);
      setChangedValidEndDate(undefined);

      dialog.open({
        title: '오류',
        content: e?.response?.data?.message,
        type: 'ok',
      });
    },
  });

  const coupons = couponsQuery.data?.data;

  useEffect(() => {
    if (coupons) {
      store.setPointCouponsContainer(coupons);
    }
  }, [coupons]);

  const TABLE_COLUMNS: ColumnsType<AdminCreditPointCoupon> = [
    {
      title: 'ID',
      dataIndex: '_id',
      render: (_id) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(_id);
                toast.dark('ID를 클립보드에 복사했습니다', {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  pauseOnFocusLoss: false,
                  draggable: true,
                  progress: undefined,
                  bodyStyle: {
                    color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                    textAlign: 'center',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                  },
                  theme: 'dark',
                });
              }}>
              ID
            </Button>
          </div>
        );
      },
    },
    {
      title: '이름',
      render: (record: AdminCreditPointCoupon) => {
        return record?.couponName;
      },
    },
    {
      title: '설명',
      render: (record: AdminCreditPointCoupon) => {
        return record.couponDescription;
      },
    },
    {
      title: '타입',
      render: (record: AdminCreditPointCoupon) => {
        return POINT_COUPON_TYPE_TEXT[record.couponType as 'one' | 'multi'];
      },
    },
    {
      title: '코드',
      width: '100px',
      render: (record: AdminCreditPointCoupon) => {
        if (record.couponType === 'multi') {
          return record.couponKeyName;
        } else if (record.couponType === 'one') {
          return (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <Button
                onClick={() => {
                  setSelectedCouponId(record._id);
                }}>
                쿠폰 코드 확인
              </Button>
              <Button
                onClick={async () => {
                  const fileName = `${record.couponName}-${moment().format('YYYYMMDD-HHmmss')}.xlsx`;
                  const blob = await api.getOneTimeCouponCodesExcelFile(record._id);
                  if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
                    // IE variant
                    (window.navigator as any).msSaveOrOpenBlob(new Blob(blob.data), fileName);
                  } else {
                    const url = window.URL.createObjectURL(blob.data);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode && link.parentNode.removeChild(link);
                  }
                }}>
                쿠폰 코드 다운
              </Button>
            </div>
          );
        }
        return '-';
      },
    },
    {
      title: '금액',
      render: (record: AdminCreditPointCoupon) => {
        return `${(record.creditPoints || '').toLocaleString()}원`;
      },
    },
    {
      title: '사용횟수 / 발행횟수',
      render: (record: AdminCreditPointCoupon) => {
        return `${record.usedCount} / ${record.issuedCount}`;
      },
    },
    {
      title: '가입일(사용 조건)',
      width: '150px',
      render: (record) => {
        if (record.requiredSignUpDate) {
          return (
            <div>
              <div>
                {record.requiredSignUpDate ? moment(record.requiredSignUpDate).format('YYYY-MM-DD HH:mm') : '-'}
              </div>
              <div>
                <Button
                  onClick={() => {
                    setEditRequiredSignUpDateData(record);
                    setChangedRequiredSignUpDate(moment(record.requiredSignUpDate).format('YYYY-MM-DD HH:mm'));
                  }}>
                  가입일 변경
                </Button>
              </div>
            </div>
          );
        } else {
          return '-';
        }
      },
    },
    {
      title: '만료일',
      render: (record) => {
        return (
          <div>
            <div>{record.validEndDate ? moment(record.validEndDate).format('YYYY-MM-DD HH:mm') : '-'}</div>
            <div>
              <Button
                onClick={() => {
                  setEditValidEndDateData(record);
                  setChangedValidEndDate(moment(record.validEndDate).format('YYYY-MM-DD HH:mm'));
                }}>
                만료일 변경
              </Button>
            </div>
          </div>
        );
      },
    },
    {
      title: '상태',
      render: (record: AdminCreditPointCoupon) => {
        if (moment(record.validEndDate).diff(new Date()) < 0) {
          return (
            <div className="badge" style={{ backgroundColor: '#6e6e6e' }}>
              만료
            </div>
          );
        }

        if (record.couponStatus) {
          return (
            <div className="badge" style={{ backgroundColor: '#3fb547' }}>
              유효
            </div>
          );
        }
        return (
          <div className="badge" style={{ backgroundColor: '#bd334a' }}>
            중지
          </div>
        );
      },
    },
    {
      title: '상태 변경',
      render: (record: AdminCreditPointCoupon) => {
        if (moment(record.validEndDate).diff(new Date()) < 0) {
          return '-';
        }

        return (
          <Switch
            checked={record.couponStatus}
            onChange={(checked) => {
              updatePointCouponMutation.mutate({ couponId: record._id, body: { couponStatus: checked } });
            }}></Switch>
        );
      },
    },
    {
      title: '생성 / 수정',
      render: (record) => {
        return (
          <div>
            <div>{moment(record.createdAt).format('YYYY-MM-DD HH:mm')}</div>
            <div>{record.updatedAt ? moment(record.updatedAt).format('YYYY-MM-DD HH:mm') : '-'}</div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="dashboard" style={{ position: 'relative' }}>
      {selectedCouponId && (
        <OnetimePointCouponsModal
          open={!!selectedCouponId}
          couponId={selectedCouponId}
          onClose={() => {
            setSelectedCouponId(undefined);
          }}></OnetimePointCouponsModal>
      )}
      <PointCouponCreateModal
        open={showCreateModal}
        onClose={() => {
          setShowCreateModal(false);
        }}
        needRefetch={() => {
          couponsQuery.refetch();
        }}></PointCouponCreateModal>
      <Modal
        footer={
          <div>
            <Button>취소</Button>
            <Button
              onClick={() => {
                if (editValidEndDateData) {
                  updatePointCouponMutation.mutate({ couponId: editValidEndDateData._id, body: editValidEndDateData });
                }
              }}>
              저장
            </Button>
          </div>
        }
        open={!!editValidEndDateData}
        onCancel={() => {
          setEditValidEndDateData(undefined);
        }}>
        <div style={{ marginTop: '28px' }}>
          <div>만료일 변경</div>
          <DatePicker
            value={dayjs(changedValidEndDate)}
            showTime={{ showSecond: false }}
            onChange={(value, dateString) => {
              setChangedValidEndDate(dateString);
              setEditValidEndDateData((prev) => {
                return {
                  ...prev,
                  validEndDate: moment(dateString, 'YYYY-MM-DD HH:mm:00').toDate(),
                } as AdminCreditPointCoupon;
              });
            }}
          />
        </div>
      </Modal>
      <Modal
        footer={
          <div>
            <Button>취소</Button>
            <Button
              onClick={() => {
                if (editRequiredSignUpDateData) {
                  updatePointCouponMutation.mutate({
                    couponId: editRequiredSignUpDateData._id,
                    body: editRequiredSignUpDateData,
                  });
                }
              }}>
              저장
            </Button>
          </div>
        }
        open={!!editRequiredSignUpDateData}
        onCancel={() => {
          setEditRequiredSignUpDateData(undefined);
        }}>
        <div style={{ marginTop: '28px' }}>
          <div>가입일 변경</div>
          <DatePicker
            value={dayjs(changedRequiredSignUpDate)}
            showTime={{ showSecond: false }}
            onChange={(value, dateString) => {
              setChangedRequiredSignUpDate(dateString);
              setEditRequiredSignUpDateData((prev) => {
                return {
                  ...prev,
                  requiredSignUpDate: moment(dateString, 'YYYY-MM-DD HH:mm:00').toDate(),
                } as AdminCreditPointCoupon;
              });
            }}
          />
        </div>
      </Modal>
      <div className="page-header">
        <div>포인트 쿠폰 관리</div>
      </div>
      <div style={{ display: 'flex' }}>
        <div className="page-content" style={{ width: '100%', height: 'calc(100vh - 80px)', overflow: 'auto' }}>
          <div style={{ display: 'flex', gap: '12px', width: '100%', height: '52px', alignItems: 'center' }}>
            <Input
              ref={searchInputRef}
              style={{ width: '240px' }}
              value={searchKeyword}
              placeholder={'검색어 입력 후 엔터'}
              onChange={(e) => {
                setSearchKeyword(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.code === 'Enter' && searchKeyword) {
                  store.updateSearchQuery({ keyword: searchKeyword });
                }
              }}></Input>

            <div style={{ display: 'flex', marginLeft: 'auto', gap: '12px', alignItems: 'center' }}>
              <Button
                onClick={() => {
                  setSearchKeyword(undefined);
                  store.resetSearchQuery();
                }}>
                검색 초기화
              </Button>
              <Button
                onClick={() => {
                  setShowCreateModal(true);
                }}>
                신규 쿠폰 생성
              </Button>
            </div>
          </div>
          <Table
            columns={TABLE_COLUMNS}
            scroll={{ x: 'max-content', y: 'calc(100dvh - 320px)' }}
            style={{ whiteSpace: 'pre-wrap' }}
            rowKey={(record) => record?._id}
            dataSource={store.searchResultContainer.contents.filter((product) => product?._id)}
            pagination={{
              current: (store.searchResultContainer.page || 0) + 1,
              pageSize: store.searchResultContainer.size || 20,
              total: store.searchResultContainer.total,
            }}
            loading={couponsQuery.isLoading}
            onChange={(e) => {
              store.updateSearchQuery({ page: (e.current || 2) - 1, size: e.pageSize || 20 });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CouponsPage;
