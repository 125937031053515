import { PagenatedResponse, SearchPagenateQuery } from '@models/common';
import { AdminCreditPointCouponLog } from '@models/credit-points';
import { create } from 'zustand';

const PAGE_SIZE = 20;

interface PointCouponLogsContainer {
  contents: AdminCreditPointCouponLog[];
  page: number;
  size: number;
  total: number;
  hasNext: boolean;
}

interface PointCouponLogsStoreProps {
  searchResultContainer: PointCouponLogsContainer;
  searchQuery: SearchPagenateQuery;
  searchKeyword?: string;
  setSearchKeyword: (keyword: string) => void;
  updateSearchQuery: (query: { [key: string]: any }) => void;
  resetSearchQuery: () => void;
  setPointCouponLogsContainer: (result: PagenatedResponse<AdminCreditPointCouponLog>) => void;
}

export const usePointCouponLogsStore = create<PointCouponLogsStoreProps>((set) => ({
  searchResultContainer: {
    contents: [] as AdminCreditPointCouponLog[],
    total: 0,
    page: 0,
    size: 0,
    hasNext: false,
  },
  searchQuery: {
    page: 0,
    size: PAGE_SIZE,
  },
  searchKeyword: undefined,
  setPointCouponLogsContainer: (result) => {
    set((state) => {
      const contents = new Array(result.total || PAGE_SIZE);
      contents.splice(result.page * result.size, result.size, ...(result?.contents || []));
      const newValue = { ...state.searchResultContainer, ...result, contents: contents };
      return { ...state, searchResultContainer: newValue };
    });
  },
  setSearchKeyword: (keyword: string) => {
    set((state) => {
      return {
        ...state,
        searchKeyword: keyword,
        searchResultContainer: { ...state.searchResultContainer, size: 0 },
      };
    });
  },
  updateSearchQuery: (query: { [key: string]: any }) => {
    set((state) => {
      return {
        ...state,
        searchQuery: { ...state.searchQuery, ...query },
        searchResultContainer: { ...state.searchResultContainer, size: 0 },
      };
    });
  },
  resetSearchQuery: () => {
    set({
      searchQuery: {
        page: 0,
        size: PAGE_SIZE,
        type: 'all',
      },
    });
  },
}));
