import { PaymentStatement } from '@models/paymentStatement';
import { create } from 'zustand';

type PaymentStatus = 'ready' | 'in_progress' | 'done' | 'error' | 'cancel';

interface PaymenttoreProps {
  status: PaymentStatus;
  setStatus: (status: PaymentStatus) => void;
  paymentStatement?: PaymentStatement;
  setPaymentStatement: (paymentStatement?: PaymentStatement) => void;
}

export const usePaymentStore = create<PaymenttoreProps>((set) => ({
  status: 'ready',
  setStatus: (status: PaymentStatus) => {
    set({ status });
  },
  paymentStatement: undefined,
  setPaymentStatement: (paymentStatement?: PaymentStatement) => {
    set({ paymentStatement });
  },
}));
