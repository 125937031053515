import * as appApi from '@apis/app-feed';
import { Feed } from '@models/feed';
import { Button, Input } from 'antd';
import React, { useEffect, useState } from 'react';

export const SearchPlaceholderEdit: React.FC<{
  clear?: boolean;
  data?: Partial<Feed>;
  onChange?: (data: Partial<Feed>) => void;
}> = (props) => {
  const [changedData, setChangedData] = useState<Feed>();
  const { data } = props;
  const item = { ...data, ...changedData };

  useEffect(() => {
    if (props.clear) {
      setChangedData(undefined);
    }
  }, [props.clear]);

  useEffect(() => {
    if (changedData) {
      props.onChange && props.onChange({ ...data, ...changedData });
    }
  }, [changedData]);

  return (
    <div>
      <div style={{ display: 'flex', gap: '8px', alignItems: 'flex-end' }}>
        <div>검색어 입력창 Placeholder</div>
        <Button
          onClick={() => {
            appApi.getPlaceholders().then((response) => {
              const placeholder = response.data.searchPlaceholder;
              setChangedData((prev) => {
                return { ...prev, type: 'search-placeholder', data: [{ placeholder: placeholder }] } as Feed;
              });
            });
          }}>
          구 데이터 불러오기
        </Button>
      </div>

      <div style={{ display: 'flex', marginBottom: '8px' }}>
        <Input
          style={{ width: '428px' }}
          value={(item.data && item.data.length > 0 && item.data[0].placeholder) || ''}
          onChange={(e) => {
            setChangedData((prev) => {
              return { ...prev, type: 'search-placeholder', data: [{ placeholder: e.target.value }] } as Feed;
            });
          }}></Input>
      </div>
    </div>
  );
};

export default SearchPlaceholderEdit;
