import GrayLeftIcon from '@assets/images/app/icon-arr-gray-left.png';
import GrayRightIcon from '@assets/images/app/icon-arr-gray-right.png';
import { ClassListItem } from '@components/product-collection/ClassListItem';
import React, { useState } from 'react';
import { MainFeedCellProps } from '.';

const PAGE_SIZE = 4;

export const ClassListCell: React.FC<MainFeedCellProps> = (props) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const data = props.data || [];

  const splitedClasses = [];

  for (let i = 0; i < data.length; i += PAGE_SIZE) {
    const chunk = data.slice(i, i + PAGE_SIZE);
    splitedClasses.push(chunk);
  }

  const lastPage = splitedClasses.length;
  return (
    <div id="class-list-cell">
      <div
        style={{
          width: '100%',
          backgroundColor: 'white',
          padding: '0 16px',
        }}>
        <div
          id="class-list-cell-title"
          style={{
            backgroundColor: 'white',
            fontSize: '19px',
            fontWeight: 'bold',
            color: '#242424',
          }}>
          {props.title}
        </div>
        {props.description && props.description !== '' ? (
          <div
            style={{
              fontSize: '13px',
              marginTop: '8px',
              marginBottom: '16px',
              color: '#808387',
              whiteSpace: 'pre-wrap',
            }}>
            {props.description}
          </div>
        ) : (
          <div
            style={{
              height: '16px',
              width: '100%',
            }}
          />
        )}
        <div
          style={{
            height: '1px',
            flex: 1,
            backgroundColor: '#f5f7fb',
            marginBottom: '20px',
          }}
        />
        <div>
          {(splitedClasses[currentPage - 1] || []).map((item: any, index: number) => {
            return (
              <ClassListItem
                key={`class-list-${index}`}
                item={item}
                hideBorderBottom={true}
                style={{ padding: '8px 0' }}></ClassListItem>
            );
          })}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
              marginBottom: 0,
            }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',

                cursor: 'pointer',
                width: '56px',
                height: '36px',
                border: '1px solid #e8eaed',
                borderRadius: '8px',
              }}
              onClick={() => {
                setCurrentPage((page) => {
                  const newPage = page - 1;
                  if (newPage < 1) {
                    return 1;
                  }
                  return newPage;
                });
              }}>
              <img src={GrayLeftIcon} style={{ width: '12px', height: '12px' }} />
            </div>
            <div
              style={{
                display: 'flex',
                marginLeft: '12px',
                marginRight: '12px',
                height: '36px',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <div style={{ lineHeight: '36px' }}>{currentPage}</div>
              <div style={{ lineHeight: '36px', color: '#808387', width: '12px', textAlign: 'center' }}>/</div>
              <div style={{ lineHeight: '36px', color: '#808387' }}>{lastPage}</div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                width: '56px',
                height: '36px',
                border: '1px solid #e8eaed',
                borderRadius: '8px',
              }}
              onClick={() => {
                setCurrentPage((page) => {
                  const newPage = page + 1;
                  if (newPage > lastPage) {
                    return lastPage;
                  }
                  return newPage;
                });
              }}>
              <img src={GrayRightIcon} style={{ width: '12px', height: '12px' }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClassListCell;
