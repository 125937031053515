import { Input } from 'antd';
import { InputProps, InputRef, TextAreaProps } from 'antd/es/input';
import { TextAreaRef } from 'antd/es/input/TextArea';
import React, { useEffect, useState } from 'react';

export interface TextInputProps {
  onChangeValue?: (value: string) => void;
}

export const TextInput: React.FC<InputProps & React.RefAttributes<InputRef> & TextInputProps> = (props) => {
  const [editedValue, setEditedValue] = useState<string>();

  useEffect(() => {
    if (editedValue !== undefined) {
      props.onChangeValue && props.onChangeValue(editedValue);
    }
  }, [editedValue]);

  return (
    <Input
      {...props}
      value={editedValue || props.value}
      onChange={(e) => {
        setEditedValue(e.target.value);
      }}></Input>
  );
};

export const TextArea: React.FC<TextAreaProps & React.RefAttributes<TextAreaRef> & TextInputProps> = (props) => {
  const [editedValue, setEditedValue] = useState<string>();

  useEffect(() => {
    if (editedValue !== undefined) {
      props.onChangeValue && props.onChangeValue(editedValue);
    }
  }, [editedValue]);

  return (
    <Input.TextArea
      {...props}
      value={editedValue || props.value}
      onChange={(e) => {
        setEditedValue(e.target.value);
      }}></Input.TextArea>
  );
};
