import { ClassInvoiceModal } from '@components/class-invoice/ClassInvoiceModal';
import OkCancelDialog from '@components/common/OkCancelDialog';
import AddAccountTransferModal from '@components/payment-statement/AddAccountTransferModal';
import { PaymentStatementModal } from '@components/payment-statement/PaymentStatementModal';
import LoungeEditorModal from '@components/user-contents/LoungeEditorModal';
import { useAppSocket } from '@hooks/appSocket';
import { useAuthentication } from '@hooks/authentication';
import { useContentLoaderStore } from '@stores/contentLoaderStore';
import { Layout } from 'antd';
import React, { Suspense } from 'react';
import { PhotoProvider } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import { Route, Routes } from 'react-router-dom';
import PacmanLoader from 'react-spinners/PacmanLoader';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import Sider from './components/Sider/Sider';
import routes from './routes';

const App: React.FC = () => {
  useAppSocket();
  const authentication = useAuthentication();
  const loader = useContentLoaderStore();

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <PhotoProvider>
        <AddAccountTransferModal />
        <Layout className="admin-app ant-layout-has-sider">
          <Sider hide={!authentication.authStore.user} />
          <Layout>
            <Layout.Content>
              {loader.show && (
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <PacmanLoader />
                </div>
              )}
              {!loader.show && (
                <Routes>
                  {routes.map((route, index) => {
                    return <Route key={`route-${index}`} path={route.path} element={route.element} />;
                  })}
                </Routes>
              )}
              {/* <Layout.Footer>Copyright (주)아이들랩. All rights Reserved.</Layout.Footer> */}
            </Layout.Content>
          </Layout>
          <LoungeEditorModal />
          <ClassInvoiceModal />
          <PaymentStatementModal />
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <OkCancelDialog />
        </Layout>
      </PhotoProvider>
    </Suspense>
  );
};

export default App;
