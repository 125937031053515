import CommerceIcon from '@assets/images/app/badge-quick-pay@3x.png';
import HeartGrayIcon from '@assets/images/app/btn-heart-gray.png';
import HeartRedIcon from '@assets/images/app/btn-heart-red.png';
import SoldoutStampImage from '@assets/images/app/img-stamp-soldout-1.png';
import { ProductModel } from '@models/product';
import { cdnImageUrl } from '@utils/image';
import React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';

interface CommerceItemProps {
  item?: ProductModel;
  isFavorited?: boolean;
  style?: any;
  width?: number;
  onClick?: (event: any) => void;
}

interface CommerceThumbnailProps {
  imageUrl: string;
  thumDoneInfo?: string;
  showThumbDoneInfo?: boolean;
  isFavorited?: boolean;
}

const CommerceThumbnail: React.FC<CommerceThumbnailProps> = (props) => {
  const { isFavorited, thumDoneInfo, showThumbDoneInfo, imageUrl } = props;

  const fixImageUrl = imageUrl;
  // if (fixImageUrl != null && fixImageUrl != '') {
  //   const str1 = fixImageUrl.substr(0, fixImageUrl.indexOf('/upload/') + 7);
  //   const str2 = fixImageUrl.substr(fixImageUrl.indexOf('/upload/') + 7, fixImageUrl.length);
  //   fixImageUrl = str1 + '/w_500' + str2;
  // }

  return (
    <div style={{ width: '100%', aspectRatio: 1, borderRadius: '8px', overflow: 'hidden', position: 'relative' }}>
      {showThumbDoneInfo && (
        <div
          style={{
            width: '100%',
            height: '100%',
            borderRadius: '8px',
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: 'rgba(0,0,0,0.7)',
            zIndex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <img
            style={{
              width: '90px',
              height: '90px',
              marginLeft: 0,
              marginRight: 0,
              marginTop: 0,
              borderRadius: '12px',
              position: 'absolute',
            }}
            src={SoldoutStampImage}
          />
          <div
            style={{
              width: '90px',
              height: '90px',
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <div
              style={{
                color: 'white',
                fontWeight: 'bold',
                fontSize: '18px',
                width: '50px',
                textAlign: 'center',
                lineHeight: 1.1,
                whiteSpace: 'pre-wrap',
              }}>
              {thumDoneInfo}
            </div>
          </div>
        </div>
      )}
      <img
        style={{
          width: '100%',
          borderRadius: '8px',
          objectFit: 'cover',
          aspectRatio: 1,
        }}
        src={cdnImageUrl(fixImageUrl)}></img>
      {isFavorited && (
        <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
          <img
            style={{
              width: '24px',
              height: '24px',
              borderWidth: 1,
              border: '1px solid #d8d8d8',
              borderRadius: '50%',
            }}
            src={isFavorited ? HeartRedIcon : HeartGrayIcon}
          />
        </div>
      )}
    </div>
  );
};

export const CommerceItem: React.FC<CommerceItemProps> = (props) => {
  const item = props.item;
  const mainImageUrl = item?.thumbnailImage as string;

  return (
    <div style={{ ...props.style, position: 'relative', cursor: 'pointer' }} onClick={props.onClick}>
      {mainImageUrl && (
        <CommerceThumbnail
          imageUrl={mainImageUrl}
          thumDoneInfo={item?.closeText}
          showThumbDoneInfo={item?.totalInventory === 0 || item?.soldout}
          isFavorited={props.isFavorited}
        />
      )}
      <div style={{ marginTop: '10px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div
            style={{
              fontSize: '12px',
              textAlign: 'left',
              color: '#808387',
              fontWeight: 'normal',
              lineHeight: '16px',
            }}>
            {item?.sellerName}
          </div>
        </div>
      </div>
      <div
        style={{
          letterSpacing: -0.2,
          marginTop: '6px',
          flex: 1,
          fontSize: '14px',
          color: '#424242',
          lineHeight: '18px',
          maxHeight: '36px',
          overflow: 'hidden',
        }}>
        <LinesEllipsis text={item?.title} maxLine={2} ellipsis="..." trimRight></LinesEllipsis>
      </div>
      <div style={{ alignItems: 'flex-start', alignContent: 'flex-start', flexDirection: 'column', marginTop: '8px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '8px' }}>
          <div
            style={{
              fontWeight: 'bold',
              fontSize: '14px',
              color: '#242424',
              lineHeight: '18px',
            }}>
            {item?.fee.toLocaleString()}원
          </div>
          {item?.discount && item.discount.replace(/[^0-9]/g, '') !== '' && parseInt(item.discount) > 0 && (
            <div
              style={{
                fontWeight: 'bold',
                fontSize: '14px',
                color: '#f03f45',
                lineHeight: '18px',
                marginLeft: '4px',
              }}>
              {item?.discount}
            </div>
          )}
        </div>
      </div>
      <img src={CommerceIcon} style={{ width: '66px', height: '18px', marginTop: '4px' }}></img>
    </div>
  );
};

export default CommerceItem;
