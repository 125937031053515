import { ChildInfo } from '@models/chat';
import { ClassReport } from '@models/report';
import { ClassSchedule } from '@models/schedule';
import moment from 'moment';
import React from 'react';
import { ClassInvoiceButton } from './ClassInvoiceButton';
import 'moment/locale/ko';
import { cdnImageUrl } from '@utils/image';
moment.locale('ko');

const CheckCircle: React.FC<{ style?: any }> = (props) => {
  return (
    <svg
      style={{ ...props.style }}
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="Icon/Outline/check-circle">
        <path
          id="Icon"
          d="M9.0171 13L11.0209 15.0038L15.0285 10.9962M21.0398 13C21.0398 17.98 17.0028 22.0171 12.0228 22.0171C7.04281 22.0171 3.00574 17.98 3.00574 13C3.00574 8.02004 7.04281 3.98297 12.0228 3.98297C17.0028 3.98297 21.0398 8.02004 21.0398 13Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export interface ClassInvoiceSummaryProps {
  payment?: boolean;
  isTutor?: boolean;
  report?: ClassReport;
  tutorName: string;
  classTitle: string;
  classThumbnail: string;
  tutorProfileImage?: string;
  students: ChildInfo[];
  additionalParticipantCount: number;
  totalClassTimes: number;
  classDuration: number;
  schedules: Partial<ClassSchedule>[];
  paid?: boolean;
  refund?: boolean;
  onChangeSchedule?: (schedule: Partial<ClassSchedule>) => void;
  onClickScheduleEdit?: () => void;
  onClickCreateClassReport?: () => void;
  onClickShowClassReport?: () => void;
}

export const ClassInvoiceSummary: React.FC<ClassInvoiceSummaryProps> = (props) => {
  // const appBottomSheetStore = useAppBottomSheetStore();

  const scheduleCounts = { ready: 0, cancel: 0, done: 0 };
  (props.schedules || []).forEach((schedule) => {
    if (schedule.scheduleState === 'ready') {
      scheduleCounts.ready = scheduleCounts.ready + 1;
    } else if (schedule.scheduleState === 'done') {
      scheduleCounts.done = scheduleCounts.done + 1;
    } else if (schedule.scheduleState === 'cancel') {
      scheduleCounts.cancel = scheduleCounts.cancel + 1;
    }
  });

  const scheduleCountsSum = scheduleCounts.ready + scheduleCounts.cancel + scheduleCounts.done;
  const hasReadySchedule = scheduleCounts.ready > 0;

  const titleStyle = {
    icon: <CheckCircle style={{ marginRight: '4px' }} />,
    label: '수강증',
    progress: '',
    backgroundColor: '#FF3D8F',
  } as { icon: React.ReactElement | null; label: string; progress: string; backgroundColor: string };
  if (props.refund) {
    titleStyle.label = '전체 환불';
    titleStyle.progress = `0 / 0`;
    titleStyle.backgroundColor = '#B9BBBE';
  } else if (scheduleCountsSum === 0) {
    titleStyle.icon = null;
    titleStyle.label = '수강증';
    titleStyle.progress = '';
  } else if (props.payment) {
    titleStyle.icon = null;
    titleStyle.label = '수강증';
    titleStyle.progress = '';
  } else if (scheduleCountsSum === scheduleCounts.ready) {
    titleStyle.label = '수강 예정';
    titleStyle.progress = `${scheduleCounts.done} / ${scheduleCountsSum}`;
  } else if (scheduleCounts.ready > 0) {
    titleStyle.label = '수강 중';
    titleStyle.progress = `${scheduleCounts.done} / ${scheduleCountsSum}`;
  } else if (scheduleCountsSum === scheduleCounts.cancel) {
    titleStyle.label = '전체 환불';
    titleStyle.progress = `0 / 0`;
    titleStyle.backgroundColor = '#B9BBBE';
  } else if (scheduleCounts.ready === 0) {
    titleStyle.label = '수강 완료';
    titleStyle.progress = `${scheduleCountsSum} / ${scheduleCountsSum}`;
    titleStyle.backgroundColor = '#B9BBBE';
  }

  // const onClickStatus = (schedule: Partial<ClassSchedule>) => {
  //   appBottomSheetStore.open({
  //     height: 400,
  //     elements: (
  //       <div style={{ height: '400px' }}>
  //         <div style={{ height: '24px' }}></div>
  //         <div style={{ padding: '20px' }}>
  //           <div
  //             style={{
  //               color: 'var(--Neutral-100, #242424)',
  //               fontFamily: 'Pretendard',
  //               fontSize: '20px',
  //               fontStyle: 'normal',
  //               fontWeight: 700,
  //             }}>
  //             수강 상태 변경
  //           </div>
  //           <div
  //             style={{
  //               marginTop: '8px',
  //               color: 'var(--Neutral-60, #9B9DA0)',
  //               fontFamily: 'Pretendard',
  //               fontSize: '14px',
  //               fontStyle: 'normal',
  //               fontWeight: 400,
  //               lineHeight: '20px',
  //             }}>
  //             {
  //               '수업 진행에 따라 상태를 변경해 주세요. 모든 수강 일정이 ‘수강 완료’ 되어야 리포트를 작성하실 수 있습니다.'
  //             }
  //           </div>
  //           <div
  //             style={{
  //               marginTop: '20px',
  //               borderTop: '1px solid #EFF1F4',
  //             }}>
  //             <button
  //               className="link-button"
  //               style={{
  //                 width: '100%',
  //                 height: '56px',
  //                 color: 'var(--Neutral-90, #424242)',
  //                 textAlign: 'center',
  //                 fontFamily: 'Pretendard',
  //                 fontSize: '16px',
  //                 fontStyle: 'normal',
  //                 fontWeight: 400,
  //                 lineHeight: '22px',
  //               }}
  //               onClick={() => {
  //                 props.onChangeSchedule && props.onChangeSchedule({ ...schedule, scheduleState: 'ready' });
  //                 appBottomSheetStore.close();
  //               }}>
  //               수강 예정
  //             </button>
  //             <button
  //               className="link-button"
  //               style={{
  //                 width: '100%',
  //                 height: '56px',
  //                 color: 'var(--Neutral-90, #424242)',
  //                 textAlign: 'center',
  //                 fontFamily: 'Pretendard',
  //                 fontSize: '16px',
  //                 fontStyle: 'normal',
  //                 fontWeight: 400,
  //                 lineHeight: '22px',
  //               }}
  //               onClick={() => {
  //                 props.onChangeSchedule && props.onChangeSchedule({ ...schedule, scheduleState: 'done' });
  //                 appBottomSheetStore.close();
  //               }}>
  //               수강 완료
  //             </button>
  //             <button
  //               className="link-button"
  //               style={{
  //                 width: '100%',
  //                 height: '56px',
  //                 color: 'var(--Neutral-90, #424242)',
  //                 textAlign: 'center',
  //                 fontFamily: 'Pretendard',
  //                 fontSize: '16px',
  //                 fontStyle: 'normal',
  //                 fontWeight: 400,
  //                 lineHeight: '22px',
  //               }}
  //               onClick={() => {
  //                 appBottomSheetStore.close();
  //               }}>
  //               닫기
  //             </button>
  //           </div>
  //         </div>
  //       </div>
  //     ),
  //   });
  // };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '52px',
          padding: '0 20px',
          backgroundColor: titleStyle.backgroundColor,
          borderRadius: '12px',
        }}>
        <div
          style={{
            display: 'flex',
            color: 'var(--White-100, #FFF)',
            fontFamily: 'Pretendard',
            fontSize: '22px',
            fontStyle: 'normal',
            fontWeight: 700,
            alignItems: 'center',
          }}>
          {titleStyle.icon}
          {titleStyle.label}
        </div>
        <div
          style={{
            color: 'var(--White-100, #FFF)',
            textAlign: 'right',
            fontFamily: 'Pretendard',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
          }}>
          {titleStyle.progress}
        </div>
      </div>
      <div
        style={{
          padding: '20px',
          backgroundColor: 'white',
          borderRadius: '12px',
          border: '1px solid #EFF1F4',
          borderBottom: '1px dashed #EFF1F4',
          borderTop: 'none',
        }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          <div>
            <div
              style={{
                color: 'var(--Neutral-70, #808387)',
                fontFamily: 'Pretendard',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '20px',
              }}>
              {props.tutorName}
            </div>
            <div
              style={{
                color: 'var(--Neutral-90, #424242)',
                fontFamily: 'Pretendard',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: '24px',
                marginTop: '6px',
              }}>
              {props.classTitle}
            </div>
          </div>
          <div style={{ position: 'relative' }}>
            <img
              style={{ width: '72px', height: '72px', objectFit: 'cover', borderRadius: '8px' }}
              src={cdnImageUrl(props.classThumbnail)}></img>
            {props.tutorProfileImage && (
              <img
                style={{
                  width: '40px',
                  height: '40px',
                  objectFit: 'cover',
                  borderRadius: '50%',
                  border: '2px solid white',
                  position: 'absolute',
                  left: '-12px',
                  bottom: '-4px',
                }}
                src={cdnImageUrl(props.tutorProfileImage)}></img>
            )}
          </div>
        </div>
        <div
          style={{
            marginTop: '20px',
            padding: '4px 16px',
            backgroundColor: '#F8F9FC',
            borderRadius: '8px',
          }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '12px 0',
              borderBottom: '1px dashed #E8EAED',
            }}>
            <div className="class-invoice-table-title">수강생</div>
            <div className="class-invoice-table-description">
              {`${props.students
                .map((child) => {
                  return child.name;
                })
                .join(', ')}`}
              {props.additionalParticipantCount > 0 && `\n외 ${props.additionalParticipantCount}명`}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '12px 0',
              borderBottom: '1px dashed #E8EAED',
            }}>
            <div className="class-invoice-table-title">수강 회차</div>
            <div className="class-invoice-table-description">{`${props.totalClassTimes}회`}</div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '12px 0',
            }}>
            <div className="class-invoice-table-title">수업 시간</div>
            <div className="class-invoice-table-description">{`회당 ${props.classDuration}분`}</div>
          </div>
        </div>
      </div>
      <div
        style={{
          padding: '20px',
          backgroundColor: 'white',
          borderRadius: '12px',
          border: '1px solid #EFF1F4',
          borderTop: 'none',
        }}>
        <div
          style={{
            color: 'var(--Neutral-90, #424242)',
            fontFamily: 'Pretendard',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '22px',
          }}>
          일정
        </div>
        {props.isTutor && (
          <div
            style={{
              color: 'var(--Neutral-60, #9B9DA0)',
              fontFamily: 'Pretendard',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '20px',
              marginTop: '12px',
            }}>
            수업 진행에 따라 상태를 변경해 주세요.
          </div>
        )}
        <div style={{ marginTop: '12px' }}>
          {props.schedules.map((schedule, index) => {
            let scheduleStyle = {
              label: '',
              color: '#B9BBBE',
              backgroundColor: 'transparent',
              dateColor: '#424242',
            };
            if (!props.payment) {
              if (props.refund) {
                scheduleStyle = {
                  label: '수강취소',
                  color: '#B9BBBE',
                  backgroundColor: '#FFF0F0',
                  dateColor: '#FC4242',
                };
              } else if (schedule.scheduleState === 'done') {
                scheduleStyle = {
                  label: '수강완료',
                  color: '#242424',
                  backgroundColor: '#EFF1F4',
                  dateColor: '#B9BBBE',
                };
              } else if (schedule.scheduleState === 'ready') {
                scheduleStyle = {
                  label: '수강예정',
                  color: '#1890FF',
                  backgroundColor: '#E6F7FF',
                  dateColor: '#424242',
                };
              } else if (schedule.scheduleState === 'cancel') {
                scheduleStyle = {
                  label: '수강취소',
                  color: '#B9BBBE',
                  backgroundColor: '#FFF0F0',
                  dateColor: '#FC4242',
                };
              }
            }

            return (
              <div
                key={`class-invoice-summary-schedule-${index}`}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  color: scheduleStyle.dateColor,
                  fontFamily: 'Pretendard',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  height: '44px',
                }}>
                <div>{moment(schedule.startDate).format('YYYY. MM. DD(dddd) a hh:mm')}</div>
                {props.paid && props.isTutor && (
                  <div>
                    <button
                      className="link-button"
                      onClick={() => {
                        // onClickStatus(schedule);
                      }}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          color: scheduleStyle.color,
                          textAlign: 'right',
                          fontFamily: 'Pretendard',
                          fontSize: '14px',
                          fontStyle: 'normal',
                          fontWeight: 700,
                          lineHeight: '20px',
                        }}>
                        <div>{scheduleStyle.label}</div>
                        <div style={{ display: 'flex', marginLeft: '2px' }}>
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g id="Icon/Outline/chevron-down">
                              <path
                                id="Icon"
                                d="M12.6663 6L7.99967 10.6667L3.33301 6"
                                stroke={scheduleStyle.color}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                          </svg>
                        </div>
                      </div>
                    </button>
                  </div>
                )}
                {(!props.isTutor || !props.paid) && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      color: scheduleStyle.color,
                      fontFamily: 'Pretendard',
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: 'normal',
                      backgroundColor: scheduleStyle.backgroundColor,
                      borderRadius: '4px',
                      padding: '4px 6px',
                    }}>
                    <div>{scheduleStyle.label}</div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        {props.isTutor && hasReadySchedule && (
          <ClassInvoiceButton
            theme="secondary"
            onClick={() => {
              props.onClickScheduleEdit && props.onClickScheduleEdit();
            }}>
            일정 변경
          </ClassInvoiceButton>
        )}
        {props.isTutor && !hasReadySchedule && !props.report && (
          <ClassInvoiceButton
            theme="primary"
            onClick={() => {
              props.onClickCreateClassReport && props.onClickCreateClassReport();
            }}>
            리포트 작성
          </ClassInvoiceButton>
        )}
        {props.isTutor && !hasReadySchedule && props.report && (
          <ClassInvoiceButton
            theme="secondary"
            onClick={() => {
              props.onClickShowClassReport && props.onClickShowClassReport();
            }}>
            리포트 확인
          </ClassInvoiceButton>
        )}
        {props.isTutor && (
          <div
            style={{
              marginTop: '12px',
              padding: '12px 16px',
              backgroundColor: '#E6F7FF',
              textAlign: 'center',
              borderRadius: '8px',
              color: 'var(--Blue-60, #1890FF)',
              fontFamily: 'Pretendard',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '20px',
              whiteSpace: 'pre-wrap',
            }}>
            {'리포트 작성 완료일 기준,\n다음 주 수요일에 정산이 진행됩니다.'}
          </div>
        )}
      </div>
    </div>
  );
};
