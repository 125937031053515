import { Roles } from '@consts/role';
import { ChatMessage } from '@models/chat';
import { useAppSocketStore } from '@stores/appSocketStore';
import { useAuthStore } from '@stores/authStore';
import { getUserDeviceId } from '@utils/string';
import { useEffect } from 'react';
import * as io from 'socket.io-client';
import { ManagerOptions, Socket } from 'socket.io-client';
import { API_URL } from '@variables';
import * as authApi from '@apis/auth';
import { PaymentStatementsQuery } from '@stores/paymentStatementsStore';

export interface AppSocketPayload {
  productId?: string;
  roomId?: string;
  sender: {
    userId: string;
    role: Roles;
  };
}

export interface ChatMessagePayload extends AppSocketPayload {
  data: Partial<ChatMessage>;
}

export interface ReadMessagePayload extends AppSocketPayload {
  messageId: string;
}

export interface AppEventPayload {
  type: 'user-info' | 'user-agent' | 'login' | 'logout';
  data?: any;
}

export interface AppSocketFileData {
  type: string;
  file: File;
}
export interface AppSocketFile extends AppSocketPayload {
  data: AppSocketFileData;
}

export interface ConnectOptions {
  deviceId: string;
  [key: string]: any;
}

type EventFunctionKeys =
  | 'connect'
  | 'disconnect'
  | 'chat_message'
  | 'read_message'
  | 'payment_result'
  | 'sync_naver_commerce_products_status'
  | 'sync_settlement_commerce_payments_status'
  | 'upload_excel_status';
export class AppSocket {
  connecting: boolean = false;
  connected: boolean = false;
  host: string;
  path: string;
  socket?: Socket;
  opts?: Partial<ManagerOptions>;
  eventFunctions: { [key: string]: { [key: string]: any } } = {
    connect: {},
    disconnect: {},
    chat_message: {},
    read_message: {},
    payment_result: {},
    upload_excel_status: {},
    sync_naver_commerce_products_status: {},
    sync_settlement_commerce_payments_status: {},
  };

  constructor(host: string, path?: string, opts?: Partial<ManagerOptions>) {
    this.host = host;
    this.opts = opts;

    if (path) {
      this.path = path;
    } else {
      this.path = '/socket.io';
    }
  }

  connect = (options?: ConnectOptions) => {
    this.connecting = true;
    this.socket = io.connect(this.host, {
      path: this.path,
      query: options,
      reconnection: true,
      ...this.opts,
    });

    this.socket.on('connect', () => {
      this.connected = true;
      Object.values(this.eventFunctions['connect']).forEach((func) => func());
    });

    this.socket.on('disconnect', () => {
      this.connected = false;
      Object.values(this.eventFunctions['disconnect']).forEach((func) => func());
    });

    this.socket.on('chat_message', (response: object) => {
      Object.values(this.eventFunctions['chat_message']).forEach((func) => func(response));
    });

    this.socket.on('read_message', (response: object) => {
      Object.values(this.eventFunctions['read_message']).forEach((func) => func(response));
    });

    this.socket.on('payment_result', (response: object) => {
      Object.values(this.eventFunctions['payment_result']).forEach((func) => func(response));
    });

    this.socket.on('upload_excel_status', (response: object) => {
      Object.values(this.eventFunctions['upload_excel_status']).forEach((func) => func(response));
    });

    this.socket.on('sync_naver_commerce_products_status', (response: object) => {
      Object.values(this.eventFunctions['sync_naver_commerce_products_status']).forEach((func) => func(response));
    });

    this.socket.on('sync_settlement_commerce_payments_status', (response: object) => {
      Object.values(this.eventFunctions['sync_settlement_commerce_payments_status']).forEach((func) => func(response));
    });
  };

  disconnect = () => {
    if (this.socket && this.socket.connected) {
      this.socket.disconnect();
    } else {
      throw 'There is no connection';
    }
  };

  emit = (event: string, data: object) => {
    if (this.socket) {
      this.socket.emit(event, data);
    } else {
      throw 'There is no connection';
    }
  };

  addSocketEventListener = (event: EventFunctionKeys, key: string, callback: (data: any) => void) => {
    this.eventFunctions[event][key] = callback;
  };

  removeSocketEventListener = (event: EventFunctionKeys, key: string) => {
    try {
      delete this.eventFunctions[event][key];
      // eslint-disable-next-line no-empty
    } catch {}
  };
}

const appSocket = new AppSocket(API_URL, undefined, { transports: ['websocket'] });

export const useAppSocket = () => {
  const appSocketStore = useAppSocketStore();
  const authStore = useAuthStore();

  useEffect(() => {
    if (!appSocket.connecting) {
      appSocket.connect({ deviceId: getUserDeviceId(), product: 'admin' });
      appSocketStore.setAppSocket(appSocket);
      appSocket.addSocketEventListener('connect', 'app-connect', () => {
        appSocketStore.setConnected(true);
        authApi
          .getMe()
          .then((response) => {
            authStore.setUser(response.data);
            if (response.data) {
              sendEvent({ type: 'user-info', data: response.data }, appSocket);
            }
          })
          .catch(() => {});

        appSocket?.emit('event', { type: 'user-agent', data: { userAgent: window.navigator.userAgent } });
      });
      appSocket.addSocketEventListener('disconnect', 'app-disconnect', () => {
        appSocketStore.setConnected(false);
      });
      appSocketStore.setInitialized(true);
    }
  }, []);

  const sendMessageRead = (data: ReadMessagePayload) => {
    appSocketStore.appSocket?.emit('read_message', data);
  };

  const sendMessage = (data: ChatMessagePayload) => {
    appSocketStore.appSocket?.emit('chat_message', data);
  };

  const enterRoom = (data: AppSocketPayload) => {
    appSocketStore.appSocket?.emit('chat_enter', data);
  };

  const leaveRoom = (data: AppSocketPayload) => {
    appSocketStore.appSocket?.emit('chat_leave', data);
  };

  const sendEvent = (data: AppEventPayload, socket?: AppSocket) => {
    const eventData = { ...data };
    (socket || appSocketStore.appSocket)?.emit('event', eventData);
  };

  const syncNaverCommerceProducts = (payload?: {
    type?: string;
    blacklist?: string;
    whitelist?: string;
    closingDate?: string;
  }) => {
    appSocketStore.appSocket?.emit('sync_naver_commerce_products', payload || {});
  };

  const cancelSyncNaverCommerceProducts = () => {
    appSocketStore.appSocket?.emit('cancel_sync_naver_commerce_products', {});
  };

  const settlementCommercePayments = (data: { type: 'class' | 'commerce'; query?: PaymentStatementsQuery }) => {
    appSocketStore.appSocket?.emit('settlement_commerce_payments', data);
  };

  const cancelSettlementCommercePayments = () => {
    appSocketStore.appSocket?.emit('cancel_settlement_commerce_payments', {});
  };

  const uploadExcelFile = (data: AppSocketFile) => {
    appSocketStore.appSocket?.emit('upload_excel_file', data);
  };

  return {
    sendMessage,
    sendEvent,
    enterRoom,
    leaveRoom,
    syncNaverCommerceProducts,
    cancelSyncNaverCommerceProducts,
    settlementCommercePayments,
    cancelSettlementCommercePayments,
    sendMessageRead,
    uploadExcelFile,
    socket: appSocket,
    connected: appSocketStore.connected,
  };
};
