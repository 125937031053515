import AppPreviewModal from '@components/class-invoice/AppPreviewModal';
import { usePayment } from '@hooks/payment';
import { ProductSelectedOptionItem } from '@models/commerce';
import { ProductModel } from '@models/product';
import React from 'react';
import CommercePayment from './CommercePayment';
import CommercePaymentComplete from './CommercePaymentComplete';

interface CommercePaymentModalProps {
  open?: boolean;
  product?: ProductModel;
  totalFee: number;
  selectedOptions?: ProductSelectedOptionItem[];

  onClickBack?: () => void;
  onConfirm?: () => void;
}

export const CommercePaymentModal: React.FC<CommercePaymentModalProps> = (props) => {
  const payment = usePayment();
  const { open, product, totalFee, selectedOptions, onConfirm } = props;

  return (
    <AppPreviewModal
      title={'결제하기'}
      open={open}
      height={0}
      width={480}
      hideRightNaviButton
      onLeftNaviButtonClick={() => {
        props.onClickBack && props.onClickBack();
      }}>
      {payment.status === 'done' && payment.paymentStatement && (
        <CommercePaymentComplete paymentStatement={payment.paymentStatement} onConfirm={onConfirm} />
      )}
      {payment.status !== 'done' && (
        <CommercePayment
          product={product}
          totalFee={totalFee}
          selectedOptions={selectedOptions}
          onConfirm={onConfirm}></CommercePayment>
      )}
    </AppPreviewModal>
  );
};
