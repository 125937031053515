import React from 'react';
import { MainFeedCellProps } from '.';
import { Carousel } from '@components/common/Carousel';
import { cdnImageUrl } from '@utils/image';
import { actionStringFromItem } from '@utils/string';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';

interface CommerceWideItemProps {
  className?: string;
  style?: any;
  item?: any;
  width: number;
  height: number;
  onClick?: (event: any) => void;
}
const CommerceWideItem: React.FC<CommerceWideItemProps> = (props) => {
  const item = props.item;

  return (
    <div
      id="commerce-wide-item"
      className={props.className}
      style={{
        width: props.width,
        height: props.height,
        marginRight: '12px',
        borderRadius: '12px',
        border: '1px solid rgba(0, 0, 0, 0.08)',
        overflow: 'hidden',
        cursor: 'pointer',
        ...props.style,
      }}
      onClick={props.onClick}>
      <img
        style={{
          width: props.width,
          height: props.height,
          objectFit: 'cover',
        }}
        src={cdnImageUrl(item.imageUrlTemp || item.imageUrl)}
      />
    </div>
  );
};

export const CommerceCarouselCell: React.FC<MainFeedCellProps> = (props) => {
  const dialog = useOkCancelDialog();
  const data = props.data || [];
  let imageWidth = Math.floor((428 * 0.8) / 4) * 4;
  if (props.size === 'small') {
    imageWidth = Math.floor((428 * 0.67) / 4) * 4;
  }
  return (
    <div
      style={{
        width: '100%',
        backgroundColor: 'white',
      }}>
      <div
        style={{
          marginBottom: '20px',
          backgroundColor: 'white',
          padding: '0 16px',
        }}>
        <div
          style={{
            fontSize: '19px',
            fontWeight: 'bold',
            color: '#242424',
          }}>
          {props.title}
        </div>
      </div>
      <div>
        <Carousel styles={{ containerStyle: { padding: '0 16px' } }} options={{ dragFree: true, align: 'start' }}>
          {data.map((item: any, index: number) => {
            return (
              <CommerceWideItem
                width={imageWidth}
                height={Math.floor((imageWidth * 0.75) / 4) * 4}
                key={`commerce-item-${index}`}
                className={'embla__slide'}
                style={{ flex: `0 0 ${imageWidth}px` }}
                item={item}
                onClick={() => {
                  dialog.open({
                    type: 'ok',
                    confirmColor: '#FF3D8F',
                    title: '이동 정보',
                    content: actionStringFromItem(item),
                  });
                }}></CommerceWideItem>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default CommerceCarouselCell;
