import SearchIcon from '@assets/images/app/icon-search-gray.png';
import React from 'react';
import { MainFeedCellProps } from '.';

export const SearchCell: React.FC<MainFeedCellProps> = (props) => {
  return (
    <div style={{ display: 'flex', padding: '0 16px' }}>
      <button
        id="search-cell"
        className="link-button"
        style={{
          width: '100%',
          display: 'flex',
          borderRadius: '22px',
          border: '1px solid #dadcdf',
          height: '44px',
          paddingLeft: '14px',
          alignItems: 'center',
        }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img style={{ width: '24px', height: '24px', marginRight: '10px' }} src={SearchIcon} />
          <div style={{ fontSize: '15px', color: '#9b9da0' }}>{props.title || '어떤 수업을 찾으세요?'}</div>
        </div>
      </button>
    </div>
  );
};

export default SearchCell;
