import * as api from '@apis/payment-statement';
import {
  getPBPaymentStatementsExcelFile,
  getPlannedCommerceExcelFile,
  getSearchPaymentStatementsExcelFile,
} from '@apis/payment-statement';
import UpdateCompletionDateModal from '@components/payment-statement/UpdateCompletionDateModal';
import ChangeScheduleModal from '@components/payment-statement/ChangeScheduleModal';
import RefundModeModal from '@components/payment-statement/RefundModeModal';
import { useAuthentication } from '@hooks/authentication';
import { usePayment } from '@hooks/payment';
import { ClassInvoiceStatus, LegacyClassInvoiceStatus } from '@models/invoice';
import {
  PaymentStatement,
  PaymentStatementReportStatus,
  PaymentStatementSettlementStatus,
  PaymentStatementWithLogs,
  RefundStatus,
  RefundSubmissionStatus,
  UpdatePaymentStatementEventType,
} from '@models/paymentStatement';
import { RefundLogStatus } from '@models/refundLog';
import useAddAccountTransferModalStore from '@stores/addAccountTransferModalStore';
import useUpdateCompletionDateModalStore from '@stores/updateCompletionDateModalStore';
import useChangeScheduleModalStore from '@stores/changeScheduleModalStore';
import { useClassInvoiceModalStore } from '@stores/classInvoiceModalStore';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { usePaymentStatementModalStore } from '@stores/paymentStatementModalStore';
import { usePaymentStatemetsStore } from '@stores/paymentStatementsStore';
import useRefundModeModalStore from '@stores/refundModeModalStore';
import { useQuery } from '@tanstack/react-query';
import { formatPhoneNumber } from '@utils/dataTransform';
import { localTimeToUtcISOString, utcTimcToLocalISOString } from '@utils/date';
import { cdnImageUrl } from '@utils/image';
import { getPaymentMethodText } from '@utils/payment';
import { WEB_URL } from '@variables';
import { Button, Checkbox, DatePicker, DatePickerProps, Input, Popover, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { PhotoView } from 'react-photo-view';
import { toast } from 'react-toastify';

type ExcelSheet = 'payment' | 'settlement';
export const PaymentStatementsPage: React.FC = () => {
  useAuthentication();
  const store = usePaymentStatemetsStore();
  const payment = usePayment();
  const dialog = useOkCancelDialog();
  const classInvoiceModalStore = useClassInvoiceModalStore();
  const paymentStatementModalStore = usePaymentStatementModalStore();
  const changeScheduleModalStore = useChangeScheduleModalStore();
  const refundModeModalStore = useRefundModeModalStore();
  const addAccountTransferModalStore = useAddAccountTransferModalStore();
  const updateCompletionDateModalStore = useUpdateCompletionDateModalStore();
  const searchInputRef = useRef<any>();
  const [searchKeyword, setSearchKeyword] = useState<string | undefined>('');
  const [selectedItemIndex, setSelectedItemIndex] = useState<number[]>([]);
  const [checkAll, setCheckAll] = useState<boolean>(false);

  useEffect(() => {
    const targets: number[] = [];
    if (checkAll) {
      if (store.searchResultContainer.contents && store.searchResultContainer.contents.length > 0) {
        const { page = 0, size = 0 } = store.searchQuery;
        const maxSize = Math.min((page + 1) * size, store.searchResultContainer.contents.length);

        const currentPageResultList = store.searchResultContainer.contents.slice(page * size, maxSize) || [];
        currentPageResultList.map((paymentStatement, idx) => {
          const commerceTypeCondition =
            paymentStatement.product.saleType === 'commerce' &&
            [RefundStatus.RESERVED, RefundStatus.ACCEPTED, RefundStatus.NONE].includes(
              paymentStatement.statuses.refund || RefundStatus.NONE,
            );
          const classTypeCondition =
            paymentStatement.product.saleType === 'class' && store.searchQuery.saleType === 'class';
          if (commerceTypeCondition || classTypeCondition) {
            targets.push(idx);
          }
        });
        setSelectedItemIndex(targets);
      }
    } else {
      setSelectedItemIndex([]);
    }
  }, [checkAll]);

  const TABLE_COLUMNS: ColumnsType<PaymentStatementWithLogs> = [
    {
      title: () => {
        const isAllType = store.searchQuery.saleType === 'all';
        return (
          <Checkbox
            onChange={() => {
              setCheckAll((value) => {
                return !value;
              });
            }}
            checked={checkAll}
            disabled={isAllType}></Checkbox>
        );
      },
      render: (data: PaymentStatementWithLogs, _, index) => {
        const checked = selectedItemIndex.includes(index);
        return (
          <Checkbox
            disabled={
              data.product.saleType === 'commerce' &&
              ![RefundStatus.RESERVED, RefundStatus.ACCEPTED, RefundStatus.NONE].includes(
                data?.statuses?.refund || RefundStatus.NONE,
              )
            }
            checked={checked}
            onChange={() => {
              setSelectedItemIndex((prev) => {
                const newValue = [...prev];
                const isInclude = newValue.includes(index);
                if (isInclude) {
                  const idx = newValue.indexOf(index);
                  newValue.splice(idx, 1);
                } else if (data?._id) {
                  newValue.push(index);
                }
                return newValue;
              });
            }}></Checkbox>
        );
      },
      fixed: 'left',
    },
    {
      title: 'ID',
      dataIndex: '_id',
      render: (_id, record) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(_id);
                toast.dark('결제내역 ID를 클립보드에 복사했습니다', {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  pauseOnFocusLoss: false,
                  draggable: true,
                  progress: undefined,
                  bodyStyle: {
                    color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                    textAlign: 'center',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                  },
                  theme: 'dark',
                });
              }}>
              ID 복사
            </Button>
            <Button
              onClick={() => {
                window.open(`${WEB_URL}/product/${record.product.id}`);
              }}>
              상품 바로가기
            </Button>
          </div>
        );
      },
      fixed: 'left',
    },
    {
      title: '구분',
      dataIndex: 'product',
      render: (product) => `${product?.saleType}`,
      fixed: 'left',
    },
    {
      title: '신규 환불',
      render: (data: PaymentStatementWithLogs) => (
        <Button onClick={() => refundModeModalStore.open(data)}>
          {data ? (data.statuses.refund !== RefundStatus.ALL ? '환불 하기' : '내역 보기') : '-'}
        </Button>
      ),
      fixed: 'left',
      align: 'center',
    },
    {
      title: '환불상태',
      width: 100,
      dataIndex: 'statuses',
      render: (statuses) => {
        const refundStatus = statuses?.refund;
        let status = '-';
        if (refundStatus === RefundStatus.ALL) {
          status = '전체환불';
        } else if (refundStatus === RefundStatus.PARTIAL) {
          status = '부분환불';
        } else if (refundStatus === RefundStatus.RESERVED) {
          status = '환불예약';
        } else if (refundStatus === RefundStatus.REQUESTED) {
          status = '환불요청';
        } else if (refundStatus === RefundStatus.ACCEPTED) {
          status = '환불승인';
        } else if (refundStatus === RefundStatus.DENIED) {
          status = '환불반려';
        }
        return status;
      },
      fixed: 'left',
    },
    {
      title: '환불접수',
      align: 'center',
      render: (data: PaymentStatementWithLogs) => {
        const isRefundAll = data?.statuses.refund === RefundStatus.ALL;
        if (isRefundAll) {
          return '-';
        }

        return (
          <div>
            <Button
              onClick={() => {
                if (confirm('발송 하시겠습니까?')) {
                  payment.updateRefundStatus(data._id, UpdatePaymentStatementEventType.SUBMISSION);
                }
              }}>
              발송
            </Button>
            {data.refund?.data?.[0]?.status === RefundLogStatus.ADMIN_SUBMISSON ||
            data.refund?.submissionStatus === RefundSubmissionStatus.NOTIFIED ? (
              <Button
                style={{ marginTop: '5px' }}
                onClick={() => {
                  if (confirm('취소 하시겠습니까?')) {
                    payment.updateRefundStatus(data._id, UpdatePaymentStatementEventType.CANCEL_SUBMISSION);
                  }
                }}>
                접수 취소
              </Button>
            ) : null}
          </div>
        );
      },
      fixed: 'left',
    },
    {
      title: '환불접수 상태',
      render: (data: PaymentStatementWithLogs) => {
        let status = '-';
        if (data?.refund) {
          const refundData = data.refund.data;
          if (refundData?.length && 'submissionStatus' in refundData[0] && refundData[0].submissionStatus) {
            status = '환불 접수';
          } else if (refundData?.length && refundData[0]?.status === RefundLogStatus.ADMIN_SUBMISSON) {
            status = '환불 접수';
          } else if (data.refund.submissionStatus === RefundSubmissionStatus.NOTIFIED) {
            status = '환불 접수';
          }
        }
        return status;
      },
      width: 112,
      fixed: 'left',
    },
    {
      title: '구매자',
      dataIndex: 'buyerDetail',
      align: 'center',
      render: (buyer) => {
        if (buyer) {
          return (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <div>{`${buyer[0]?.profile?.name}`}</div>
              <div>{`(${formatPhoneNumber(buyer[0]?.profile?.phone)})`}</div>
              <div>
                {buyer[0]?.membership?.name && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      borderRadius: '8px',
                      background: 'var(--Blue-10, #EBF9FF)',
                      color: 'var(--Blue-60, #1890FF)',
                      fontFamily: 'Pretendard',
                      fontSize: '13px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      padding: '4px',
                    }}>
                    {buyer[0]?.membership?.name}
                  </div>
                )}
              </div>
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(buyer[0]._id);
                  toast.dark(`구매자 ID를 클립보드에 복사했습니다(${buyer[0]._id})`, {
                    position: 'top-center',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    pauseOnFocusLoss: false,
                    draggable: true,
                    progress: undefined,
                    bodyStyle: {
                      color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                      textAlign: 'center',
                      fontFamily: 'Pretendard',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '20px',
                    },
                    theme: 'dark',
                  });
                }}>
                ID 복사
              </Button>
            </div>
          );
        }
        return '-';
      },
      fixed: 'left',
    },
    {
      title: '판매자',
      dataIndex: 'sellerDetail',
      align: 'center',
      render: (seller) => {
        if (!seller || seller.length === 0) {
          return '-';
        }
        return (
          <div>
            <div>{`${seller[0].profile?.name}${
              seller[0].profile?.nickname ? `(${seller[0].profile?.nickname})` : ''
            }`}</div>
            <div>
              {seller[0].profile?.contract?.licensePhoto && (
                <PhotoView src={cdnImageUrl(seller[0].profile?.contract?.licensePhoto)}>
                  <Button>신분증</Button>
                </PhotoView>
              )}
            </div>
            <div>
              {seller[0].profile?.contract?.bankAccountPhoto && (
                <PhotoView src={cdnImageUrl(seller[0].profile?.contract?.bankAccountPhoto)}>
                  <Button>통장</Button>
                </PhotoView>
              )}
            </div>
          </div>
        );
      },
    },
    {
      title: '일정변경',
      render: (record: PaymentStatement) => (
        <Button
          onClick={() =>
            changeScheduleModalStore.open({
              type: record.product.saleType as 'class' | 'commerce',
              paymentStatementId: record._id,
              productName: record.product.name,
              buyerName: record.buyer.name || '',
              purchases: record.purchases,
              paidAmount: record.payment.amount,
              paidAt: record.payment.paidAt || new Date(),
              productId: record.product.id,
              memo: record.memo,
              classInvoiceId: record.product.classInvoiceId,
            })
          }>
          일정변경
        </Button>
      ),
      align: 'center',
    },
    {
      title: '계좌',
      dataIndex: 'seller',
      render: (seller) => {
        if (!seller || seller.length === 0) {
          return '-';
        }

        return (
          <div style={{ minWidth: '40px' }}>
            <div style={{ fontWeight: 'bold' }}>{seller[0]?.profile?.contract?.bankName}</div>
            <div>{seller[0]?.profile?.contract?.bankAccountNumber}</div>
          </div>
        );
      },
    },
    {
      title: '상품명',
      dataIndex: 'productDetail',
      render: (product) => {
        if (!product || product.length === 0) {
          return '-';
        }
        return (
          <Popover content={product[0].title || product[0].name}>
            <div style={{ width: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {product[0].title || product[0].name}
            </div>
          </Popover>
        );
      },
    },
    {
      title: '구매옵션',
      dataIndex: 'purchases',
      render: (purchases) => {
        return (
          <div>{`${(purchases || [])
            .map((purchase: any) => {
              let optionName = '';
              if (purchase.option && purchase.option.length > 0) {
                optionName = `${purchase.option.join(' ')}`;
              }
              if (Array.isArray(purchase.name)) {
                return `${purchase.name.join('/')}(${purchase.count})`;
              }
              return `${optionName}${purchase.name}(${purchase.count})`;
            })
            .join('\n')}`}</div>
        );
      },
    },
    {
      title: '수강증',
      dataIndex: 'classInvoice',
      render: (classInvoice) => {
        if (!classInvoice || classInvoice.length === 0 || classInvoice[0]._id === null) {
          return '-';
        }
        return (
          <Button
            onClick={() => {
              classInvoiceModalStore.open({
                classId: classInvoice[0].programId,
                classInvoiceId: classInvoice[0]._id,
                mode: 'view',
              });
            }}>
            수강증 보기
          </Button>
        );
      },
      width: 130,
    },
    {
      title: '수강상태',
      dataIndex: 'classInvoice',
      render: (classInvoice) => {
        if (classInvoice && classInvoice.length > 0) {
          const classInvoiceStatus = classInvoice && classInvoice[0]?.status;
          let status = '-';
          if (
            classInvoiceStatus === LegacyClassInvoiceStatus.CLASS_COMPLETED ||
            classInvoiceStatus === ClassInvoiceStatus.CLASS_COMPLETED
          ) {
            status = '수강 완료';
          } else if (
            classInvoiceStatus === LegacyClassInvoiceStatus.PAYMENT_COMPLETED ||
            classInvoiceStatus === ClassInvoiceStatus.PAYMENT_COMPLETED
          ) {
            status = '진행중';
          } else if (
            classInvoiceStatus === LegacyClassInvoiceStatus.WAITING_FOR_PAYMENT ||
            classInvoiceStatus === ClassInvoiceStatus.WAITING_FOR_PAYMENT
          ) {
            status = '결제 요청';
          } else if (
            classInvoiceStatus === LegacyClassInvoiceStatus.CREATED ||
            classInvoiceStatus === ClassInvoiceStatus.CREATED
          ) {
            status = '채팅 신정';
          } else {
            status = classInvoiceStatus;
          }
          return status;
        } else {
          return '-';
        }
      },
      width: 84,
    },
    {
      title: '청구금액',
      dataIndex: 'payment',
      render: (payment) => {
        return (
          <div>
            <div>{`${payment?.amount?.toLocaleString()}원`}</div>
            {payment?.membershipDiscountAmount && (
              <div
                style={{
                  fontSize: '12px',
                }}>{`${payment?.membershipDiscountAmount.toLocaleString()}원 멤버십 할인`}</div>
            )}
          </div>
        );
      },
    },
    {
      title: '결제금액',
      dataIndex: 'payment',
      render: (payment) => {
        return (
          <div>
            <div>
              {payment?.amount?.toLocaleString()}원{payment?.point ? `(${payment?.point?.toLocaleString()}P)` : ''}
            </div>
            {payment?.bankAccountTransferAmount && (
              <div
                style={{
                  color: '#424242',
                }}>{`(계좌이체: ${payment?.bankAccountTransferAmount?.toLocaleString()}원)`}</div>
            )}
          </div>
        );
      },
    },
    {
      title: '결제 적립',
      width: 120,
      render: (record) => {
        const paybackPoint = record?.payback?.amount || 0;
        if (paybackPoint) return `${paybackPoint.toLocaleString()}P`;
        return '-';
      },
    },
    {
      title: '결제수단',
      width: 120,
      render: (record) => getPaymentMethodText(record.payment),
    },
    {
      title: '시간',
      align: 'center',
      width: 220,
      render: (record) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <div style={{ display: 'flex', gap: '4px' }}>
              <div style={{ fontWeight: 'bold' }}>생성</div>
              <div>{moment(record.createdAt).format('YYYY.MM.DD HH:mm')}</div>
            </div>
            <div style={{ display: 'flex', gap: '4px' }}>
              <div style={{ fontWeight: 'bold' }}>결제</div>
              <div>{record?.payment?.paidAt ? moment(record.payment.paidAt).format('YYYY.MM.DD HH:mm') : '-'}</div>
            </div>
            <div style={{ display: 'flex', gap: '4px' }}>
              <div style={{ fontWeight: 'bold' }}>업데이트</div>
              <div>{moment(record.updatedAt).format('YYYY.MM.DD HH:mm')}</div>
            </div>
            <div style={{ display: 'flex', gap: '4px' }}>
              <div style={{ fontWeight: 'bold' }}>결제완성</div>
              <div>{record?.completionDate ? moment(record.completionDate).format('YYYY.MM.DD HH:mm') : '-'}</div>
            </div>
          </div>
        );
      },
    },
    {
      title: '결제상태',
      dataIndex: 'statuses',
      render: (statuses) => {
        const payStatus = (statuses?.payment || '').toLowerCase();
        let status = '-';
        if (payStatus === 'done') {
          status = '결제완료';
        } else if (payStatus === 'ready') {
          status = '결제대기';
        } else if (payStatus === 'none') {
          status = '-';
        } else {
          status = payStatus;
        }
        return status;
      },
    },
    {
      title: '배송상태',
      dataIndex: 'statuses',
      render: (statuses) => {
        const status = `${statuses?.shipping}`.toLowerCase();
        if (status === 'none' || !statuses?.shipping) {
          return '-';
        } else {
          return status;
        }
      },
      width: 84,
    },
    {
      title: '리포트 상태',
      render: (data: PaymentStatementWithLogs) => {
        if (!data) {
          return '-';
        }

        const saleType = data.product.saleType;
        const reportStatus = data.statuses?.report || '';
        let status = '-';
        if (saleType === 'class') {
          if (reportStatus === PaymentStatementReportStatus.PUBLISHED) {
            const publishedAt = moment(data.report?.publishedAt).format('YYYY-MM-DD') || '-';
            status = `발송완료\n(${publishedAt})`;
          } else if (reportStatus === PaymentStatementReportStatus.EDITTING) {
            const createdAt = moment(data.report?.createdAt).format('YYYY-MM-DD') || '-';
            status = `임시저장\n(${createdAt})`;
          } else {
            status = '미작성';
          }
        }
        return status;
      },
      width: 100,
    },
    {
      title: '정산상태',
      render: (data: PaymentStatementWithLogs) => {
        if (!data) {
          return '-';
        }
        const settlementStatus = (data.statuses?.settlement || '').toLowerCase();
        let status = '-';
        if (settlementStatus === 'done' && data?.settlement?.transferAt) {
          const transperAt = moment(data?.settlement?.transferAt).format('YYYY-MM-DD HH:mm');
          status = `정산완료\n(${transperAt})`;
        }
        return status;
      },
      width: 84,
    },
    {
      title: '기존 환불',
      render: (data: PaymentStatementWithLogs) => renderRefundButton(data),
      align: 'center',
    },
    {
      title: '계좌이체 금액 추가',
      render: (data: PaymentStatementWithLogs) => {
        return (
          <Button
            onClick={() => {
              addAccountTransferModalStore.open({
                _id: data._id,
                memo: data?.memo,
                payment: data?.payment,
                buyer: data?.buyer,
                product: data.product,
              });
            }}>
            추가
          </Button>
        );
      },
      align: 'center',
    },
    {
      title: '결제완성일 설정',
      render: (data: PaymentStatementWithLogs) => {
        return (
          <Button
            onClick={() => {
              updateCompletionDateModalStore.open({
                _id: data._id,
                memo: data?.memo?.settlement,
                completionDate: data?.completionDate,
              });
            }}>
            설정
          </Button>
        );
      },
      align: 'center',
    },
  ];

  const renderRefundButton = (data: PaymentStatementWithLogs) => {
    if (!data) {
      return '-';
    }
    const isRefundAll = data.statuses.refund === RefundStatus.ALL;
    return (
      <Button
        onClick={() => {
          paymentStatementModalStore.open(data);
        }}>
        {isRefundAll ? '내역 보기' : '환불 하기'}
      </Button>
    );
  };

  const paymentStatementSearchQuery = useQuery({
    queryKey: [
      `admin-search-payment-statement`,
      JSON.stringify(store.searchQuery),
      store.searchKeyword,
      paymentStatementModalStore.show,
      dialog.show,
    ],
    queryFn: () => api.searchPaymentStatements(store.searchQuery),
  });

  useEffect(() => {
    const searchResult = paymentStatementSearchQuery.data?.data;
    if (searchResult) {
      store.setPaymentStatemetsContainer(searchResult);
    }
    setSelectedItemIndex([]);
  }, [paymentStatementSearchQuery.data?.data]);

  const onChangeStartDate: DatePickerProps['onChange'] = (date) => {
    store.setStartDate(localTimeToUtcISOString(date ? new Date(date.toISOString()) : new Date(), { timeToZero: true }));
  };

  const onChangeEndDate: DatePickerProps['onChange'] = (date) => {
    store.setEndDate(localTimeToUtcISOString(date ? new Date(date.toISOString()) : new Date(), { timeToZero: true }));
  };

  const bulkRefundExecute = () => {
    dialog.open({
      title: '환불',
      content: `${selectedItemIndex.length}건을 일괄환불 하시겠습니까?`,
      type: 'ok_cancel',
      onConfirm: async () => {
        const { page = 0, size = 0 } = store.searchQuery;
        const maxSize = Math.min((page + 1) * size, store.searchResultContainer.contents.length);
        const currentPageResultList = store.searchResultContainer.contents.slice(page * size, maxSize) || [];
        const paymentStatementIds: string[] = [];
        selectedItemIndex.forEach((index) => {
          const paymentStatement = currentPageResultList[index];
          if (paymentStatement) {
            paymentStatementIds.push(paymentStatement._id);
          }
        });
        payment.requestRefunds(paymentStatementIds);
      },
    });
  };

  const bulkRefundReserve = () => {
    dialog.open({
      title: '환불 예약',
      content: `${selectedItemIndex.length}건을 환불예약 하시겠습니까?`,
      type: 'ok_cancel',
      onConfirm: async () => {
        const { page = 0, size = 0 } = store.searchQuery;
        const maxSize = Math.min((page + 1) * size, store.searchResultContainer.contents.length);
        const currentPageResultList = store.searchResultContainer.contents.slice(page * size, maxSize) || [];
        const paymentStatementIds: string[] = [];
        selectedItemIndex.forEach((index) => {
          const paymentStatement = currentPageResultList[index];
          if (paymentStatement) {
            paymentStatementIds.push(paymentStatement._id);
          }
        });
        payment.registerRefunds(paymentStatementIds);
      },
    });
  };

  const getExcelFile = async (type: ExcelSheet) => {
    const fileName = `igogo-${type}-${moment().format('YYYYMMDD-HHmmss')}.xlsx`;
    const blob = await getSearchPaymentStatementsExcelFile(store.searchQuery, type);
    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
      // IE variant
      (window.navigator as any).msSaveOrOpenBlob(new Blob(blob.data), fileName);
    } else {
      const url = window.URL.createObjectURL(blob.data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode && link.parentNode.removeChild(link);
    }
  };

  const getExcelFilePB = async () => {
    const startDate = store.searchQuery.startDate;
    const endDate = store.searchQuery.endDate;
    const fileName = `챌린저스-${moment(startDate).format('YYYYMMDD-HHmmss')}~${moment(endDate).format(
      'YYYYMMDD-HHmmss',
    )}.xlsx`;
    const blob = await getPBPaymentStatementsExcelFile(store.searchQuery);
    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
      // IE variant
      (window.navigator as any).msSaveOrOpenBlob(new Blob(blob.data), fileName);
    } else {
      const url = window.URL.createObjectURL(blob.data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode && link.parentNode.removeChild(link);
    }
  };

  const getExcelFileCommercePlanned = async () => {
    const startDate = store.searchQuery.startDate;
    const endDate = store.searchQuery.endDate;
    const fileName = `커머스-${moment(startDate).format('YYYYMMDD-HHmmss')}~${moment(endDate).format(
      'YYYYMMDD-HHmmss',
    )}.xlsx`;
    const blob = await getPlannedCommerceExcelFile(store.searchQuery);
    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
      // IE variant
      (window.navigator as any).msSaveOrOpenBlob(new Blob(blob.data), fileName);
    } else {
      const url = window.URL.createObjectURL(blob.data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode && link.parentNode.removeChild(link);
    }
  };

  const checkCanBulkRefundCondition = () => {
    const { page = 0, size = 0 } = store.searchQuery;
    const maxSize = Math.min((page + 1) * size, store.searchResultContainer.contents.length);
    const currentPageResultList = store.searchResultContainer.contents.slice(page * size, maxSize) || [];
    const canBulkRefund = (selectedItemIndex || []).every((index) => {
      const paymentStatement = currentPageResultList[index];
      const recentRefundLog = paymentStatement?.refund?.data?.[0];
      if (
        recentRefundLog?.status === RefundLogStatus.ACCEPTED ||
        recentRefundLog?.status === RefundLogStatus.RESERVED
      ) {
        return true;
      }
      return false;
    });
    return canBulkRefund;
  };

  const checkCanBulkRefundRegisterCondition = () => {
    const { page = 0, size = 0 } = store.searchQuery;
    const maxSize = Math.min((page + 1) * size, store.searchResultContainer.contents.length);
    const currentPageResultList = store.searchResultContainer.contents.slice(page * size, maxSize) || [];
    const canBulkRefundRegister = (selectedItemIndex || []).every((index) => {
      const paymentStatement = currentPageResultList[index];
      if (paymentStatement?.statuses.refund === RefundStatus.NONE) {
        return true;
      }
      return false;
    });
    return canBulkRefundRegister;
  };

  return (
    <div className="dashboard">
      {updateCompletionDateModalStore.show && <UpdateCompletionDateModal />}
      <div className="page-header">
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div>결제내역</div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <div>
            <Button
              style={{ borderColor: store.searchQuery.dateType === 'payment.paidAt' ? 'blue' : '#d9d9d9' }}
              onClick={() => {
                store.updateSearchQuery({ dateType: 'payment.paidAt' });
              }}>
              결제일
            </Button>
          </div>
          <div>
            <Button
              style={{ borderColor: store.searchQuery.dateType === 'createdAt' ? 'blue' : '#d9d9d9' }}
              onClick={() => {
                store.updateSearchQuery({ dateType: 'createdAt' });
              }}>
              생성일
            </Button>
          </div>
          <div>
            <Button
              style={{ borderColor: store.searchQuery.dateType === 'updatedAt' ? 'blue' : '#d9d9d9' }}
              onClick={() => {
                store.updateSearchQuery({ dateType: 'updatedAt' });
              }}>
              수정일
            </Button>
          </div>
          <div>
            <Button
              style={{ borderColor: store.searchQuery.dateType === 'plannedDates' ? 'blue' : '#d9d9d9' }}
              onClick={() => {
                store.updateSearchQuery({ dateType: 'plannedDates' });
              }}>
              수업일
            </Button>
          </div>
          <div style={{ marginLeft: '10px' }}>
            <DatePicker
              onChange={onChangeStartDate}
              value={dayjs(utcTimcToLocalISOString(store.searchQuery.startDate as any))}
            />
          </div>
          <div style={{ marginLeft: '8px' }}>
            <DatePicker
              onChange={onChangeEndDate}
              value={dayjs(utcTimcToLocalISOString(store.searchQuery.endDate as any))}
            />
          </div>
        </div>
      </div>
      <div className="page-content">
        <div style={{ display: 'flex', marginBottom: '10px', justifyContent: 'space-between', flexWrap: 'wrap' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', flexWrap: 'wrap' }}>
            <div
              style={{
                lineHeight: '2rem',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '10px',
                flexWrap: 'wrap',
              }}>
              <Select
                value={store.searchQuery.saleType || 'all'}
                style={{ width: '120px' }}
                onChange={(value) => {
                  store.updateSearchQuery({ saleType: value });
                }}
                options={[
                  { value: 'all', label: '전체' },
                  { value: 'commerce', label: '커머스' },
                  { value: 'class', label: '클래스' },
                ]}
              />
              <Select
                defaultValue={store.searchQuery.refundType || ''}
                style={{ width: '120px' }}
                onChange={(value) => {
                  store.updateSearchQuery({ refundType: value });
                }}
                options={[
                  { value: '', label: '전체' },
                  { value: RefundStatus.ALL, label: '전체환불' },
                  { value: RefundStatus.PARTIAL, label: '부분환불' },
                  { value: RefundStatus.ACCEPTED, label: '환불승인' },
                  { value: RefundStatus.DENIED, label: '환불반려' },
                  { value: RefundStatus.REQUESTED, label: '환불요청' },
                  { value: RefundStatus.RESERVED, label: '환불예약' },
                  { value: RefundStatus.REQUEST_CANCELLED, label: '환불철회' },
                ]}
              />
              <Select
                defaultValue={store.searchQuery.settlementStatus || ''}
                style={{ width: '120px' }}
                onChange={(value) => {
                  store.updateSearchQuery({ settlementStatus: value });
                }}
                options={[
                  { value: '', label: '전체' },
                  { value: PaymentStatementSettlementStatus.DONE, label: '정산완료' },
                  { value: 'payment_completion', label: '결제완성' },
                  { value: 'payment_not_completion', label: '결제완성X' },
                ]}
              />
              <Select
                style={{ width: '240px' }}
                value={store.searchQuery.type || 'all'}
                onChange={(value) => {
                  store.updateSearchQuery({ type: value, dateType: 'payment.paidAt' });
                }}
                options={[
                  { value: 'all', label: '전체' },
                  { value: 'no_planned_dates_settlement_target', label: '비일정옵션 정산대상(전체환불 제외)' },
                  { value: 'purchase_payback_done', label: '결제 적립된 내역' },
                ]}
              />
              <Input
                ref={searchInputRef}
                style={{ width: '240px' }}
                value={searchKeyword}
                placeholder={'검색어 입력 후 엔터'}
                onChange={(e) => {
                  setSearchKeyword(e.target.value);
                }}
                onKeyUp={(e) => {
                  if (e.code === 'Enter' && searchKeyword) {
                    store.updateSearchQuery({ keyword: searchKeyword });
                  }
                }}
              />
              <Button
                onClick={() => {
                  setSearchKeyword(undefined);
                  store.resetSearchQuery();
                }}>
                검색 초기화
              </Button>
              <div style={{ fontWeight: 'bold' }}>검색결과: {store.searchResultContainer.total}</div>
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', minHeight: '36px', gap: '10px' }}>
              <Popover content={<div>커머스 일괄 환불예약은 "결제완료" 건만 가능합니다.</div>}>
                <Button
                  disabled={!selectedItemIndex.length || !checkCanBulkRefundRegisterCondition()}
                  onClick={bulkRefundReserve}>
                  환불 예약
                </Button>
              </Popover>
              <Popover content={<div>커머스 일괄환불은 "예약", "승인" 된 건에 대해서만 환불처리합니다.</div>}>
                <Button
                  disabled={
                    selectedItemIndex.length === 0 ||
                    store.searchQuery.saleType === 'class' ||
                    !checkCanBulkRefundCondition()
                  }
                  onClick={bulkRefundExecute}>
                  일괄 환불
                </Button>
              </Popover>
            </div>
          </div>
          <div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', flexWrap: 'wrap' }}>
              <div style={{ marginTop: '6px', marginRight: '10px' }}>검색결과 엑셀 파일 다운로드</div>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <Button
                  onClick={() => {
                    getExcelFile('payment');
                  }}>
                  기본
                </Button>
                <Button
                  onClick={() => {
                    getExcelFilePB();
                  }}>
                  챌린저스
                </Button>
                <Button
                  onClick={() => {
                    getExcelFileCommercePlanned();
                  }}>
                  판매자별
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Table
          columns={TABLE_COLUMNS}
          scroll={{ x: 'max-content', y: 'calc(100dvh - 330px)' }}
          style={{ whiteSpace: 'pre-wrap' }}
          rowKey={(record, index) => record?._id || `${index}`}
          dataSource={store.searchResultContainer.contents}
          pagination={{
            current: (store.searchResultContainer.page || 0) + 1,
            pageSize: store.searchResultContainer.size || 20,
            total: store.searchResultContainer.total,
          }}
          loading={paymentStatementSearchQuery.isLoading}
          onChange={(e) => {
            store.updateSearchQuery({ page: (e.current || 2) - 1, size: e.pageSize || 20 });
          }}
        />
        <RefundModeModal />
        <ChangeScheduleModal />
      </div>
    </div>
  );
};

export default PaymentStatementsPage;
