import * as classApi from '@apis/app-classes';
import * as chatApi from '@apis/chats';
import ChatLeftTailImage from '@assets/images/app/chat-left-tail.png';
import ChatPaidIcon from '@assets/images/app/chat-paid.png';
import ChatPaymentIcon from '@assets/images/app/chat-payment.png';
import ChatExtendIcon from '@assets/images/app/chat-prolong.png';
import ChatReportIcon from '@assets/images/app/chat-report.png';
import ChatRequestIcon from '@assets/images/app/chat-request.png';
import ChatRightTailImage from '@assets/images/app/chat-right-tail.png';
import { Linkify } from '@components/common/Linkify';
import { ChatMessage, MessageType } from '@models/chat';
import { ClassInvoiceStatus, LegacyClassInvoiceStatus } from '@models/invoice';
import { useAuthStore } from '@stores/authStore';
import { useClassInvoiceModalStore } from '@stores/classInvoiceModalStore';
import { useMutation, useQuery } from '@tanstack/react-query';
import { cdnImageUrl } from '@utils/image';
import { Button } from 'antd';
import moment from 'moment';
import 'moment/locale/ko';
import React from 'react';
import { toast } from 'react-toastify';

const ChatAvatarContainer: React.FC<{ avatarUrl?: string; name?: string; children?: any }> = (props) => {
  const { avatarUrl, name } = props;

  return (
    <div
      id="chat-avatar-container"
      style={{ display: 'flex', alignItems: 'flex-start', maxWidth: '480px', width: '100%' }}>
      <div>
        <div
          style={{
            width: '36px',
            height: '36px',
            borderRadius: '36px',
            border: '1px solid rgba(0,0,0,0.04)',
            overflow: 'hidden',
            marginRight: '6px',
          }}>
          <img
            style={{ width: '36px', height: '36px', borderRadius: '36px', objectFit: 'cover' }}
            src={cdnImageUrl(avatarUrl)}
          />
        </div>
      </div>
      <div>
        <div style={{ fontSize: '13px', marginBottom: '4px' }}>{name}</div>
        <div>{props.children}</div>
      </div>
    </div>
  );
};

const CardMessage: React.FC<{
  message: ChatMessage;
  title?: string;
  buttonLabel?: string;
  headerIcon?: any;
  children?: any;
  buttonStyle?: any;
  onClick?: (message: ChatMessage) => void;
}> = (props) => {
  return (
    <div
      id="bubble-card"
      style={{
        width: '280px',
        alignItems: 'flex-end',
        marginRight: '16px',
      }}>
      <div
        style={{
          width: '100%',
          padding: '12px',
          backgroundColor: '#4a9fff',
          display: 'flex',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          overflow: 'hidden',
        }}>
        <div
          style={{
            textAlign: 'left',
            fontSize: '16px',
            fontWeight: 'bold',
            color: 'white',
            lineHeight: '22px',
          }}>
          {props.title}
        </div>
        <div>{props.headerIcon && <img style={{ width: '24px', height: '24px' }} src={props.headerIcon} />}</div>
      </div>
      <div
        style={{
          width: '100%',
          padding: '10px',
          backgroundColor: 'white',

          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px',
          overflow: 'hidden',
        }}>
        <div
          style={{
            textAlign: 'left',
            fontSize: '15px',
            color: '#242424',
          }}>
          {props.children}
        </div>
      </div>
    </div>
  );
};

const TimeLabel: React.FC<{ message: ChatMessage }> = (props) => {
  const message = props.message;
  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'flex-end', flex: 1, flexDirection: 'column' }}>
        <div
          style={{
            fontSize: '9px',
            color: '#808387',
            marginRight: '4px',
            marginBottom: '2px',
          }}>
          {message.readAt && '읽음'}
        </div>
        <div
          style={{
            fontSize: '9px',
            color: '#808387',
            marginRight: '4px',
            marginBottom: '2px',
          }}>
          {moment(message.createdAt).format('a hh:mm')}
        </div>
      </div>
    </div>
  );
};

const ImageMessage: React.FC<{ message: ChatMessage }> = (props) => {
  const message = props.message;
  return (
    <div id="bubble-image" style={{ width: '280px', marginRight: '16px' }}>
      {(message.images || []).map((item: any, index: number) => {
        if (item.imageUrl) {
          return (
            <div key={`bubble-image-${index}`}>
              <img
                style={{
                  borderRadius: '15px',
                  width: '280px',
                }}
                src={cdnImageUrl(item.imageUrl)}
              />
            </div>
          );
        }
      })}
    </div>
  );
};

const WelcomeWarningMessage: React.FC<{
  warningMent?: string;
}> = (props) => {
  return (
    <div
      style={{
        paddingTop: '20px',
        paddingBottom: '20px',
        paddingLeft: '20px',
        paddingRight: '20px',
        margin: '8px',
        width: '100%',
        backgroundColor: '#f9f4df',
        borderRadius: '12px',
      }}>
      <div>
        <div style={{ fontSize: '15px', color: '#424242', fontWeight: 'bold' }}>
          매너있는 채팅을 부탁드립니다:){'\n'}
        </div>
        <div style={{ fontSize: '13px', color: '#424242', whiteSpace: 'pre-wrap' }}>{props.warningMent}</div>
      </div>
    </div>
  );
};

const DateLine: React.FC<{ message: ChatMessage }> = (props) => {
  const message = props.message;
  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        height: '30px',
        justifyContent: 'center',
        marginLeft: '10px',
        marginRight: '10px',
        marginTop: '20px',
        marginBottom: '20px',
        position: 'relative',
      }}>
      <div
        style={{
          position: 'absolute',
          top: '8px',
          height: '1px',
          width: '100%',
          backgroundColor: '#dadcdf',
          zIndex: 0,
        }}
      />
      <div style={{ backgroundColor: '#e8eaed', paddingLeft: '4px', paddingRight: '4px', zIndex: 1 }}>
        <div
          style={{
            backgroundColor: '#e8eaed',
            color: '#808387',
            fontSize: '11px',
          }}>
          {moment(message.createdAt).format('YYYY년 MM월 DD일')}
        </div>
      </div>
    </div>
  );
};

const messageTypeFromMessage = (message: ChatMessage) => {
  if (message.type) {
    return message.type;
  }

  if (message.classId === message.text) {
    return MessageType.WELCOME_WARNING;
  } else if (message.image) {
    return MessageType.IMAGE;
  } else if (message.text.indexOf('[클래스 문의가 들어왔어요]') >= 0) {
    return MessageType.CLASS_INQUIRY;
  } else if (message.leave) {
    return MessageType.CHAT_END;
  } else if (message.makecard) {
    return MessageType.PAYMENT_REQUEST;
  } else if (message.payment) {
    return MessageType.PAYMENT_COMPLETE;
  } else if (message.writereport) {
    return MessageType.CLASS_REPORT;
  } else if (message.prolong || message.text.indexOf('[연장 신청이 들어왔어요]') >= 0) {
    return MessageType.CLASS_PERIOD_EXTEND;
  }
};

interface ChatBubbleProps {
  classProductName: string;
  message: ChatMessage;
  addDateLine: boolean;
  isMine: boolean;
  avatarUrl?: string;
  warningMent?: string;
  avatarName?: string;
  isLast?: boolean;
  style?: any;
  onClickPayment?: () => void;
}

const ChatBubbleContainer: React.FC<{
  message: ChatMessage;
  isSystemMessage?: boolean;
  isRight?: boolean;
  isLast?: boolean;
  children?: any;
  addDateLine?: boolean;
  avatarUrl?: string;
  avatarName?: string;
  style?: any;
}> = (props) => {
  const { message, addDateLine, isSystemMessage, isRight, isLast, children, avatarUrl, avatarName } = props;

  if (isSystemMessage) {
    return (
      <div
        id="chat-bubble-container-system"
        style={{
          width: '100%',
          marginTop: '8px',
          ...props.style,
        }}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '6px',
            flexDirection: 'row',
          }}>
          {children}
        </div>
      </div>
    );
  }

  if (isRight) {
    return (
      <div
        id="chat-bubble-container-right"
        style={{
          width: '100%',
          marginTop: '8px',
          paddingBottom: isLast ? '16px' : '8px',
          ...props.style,
        }}>
        {addDateLine && <DateLine message={message} />}
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            marginBottom: '6px',
            flexDirection: 'row',
          }}>
          <div>
            <TimeLabel message={message} />
          </div>
          {children}
        </div>
      </div>
    );
  }

  return (
    <div
      id="chat-bubble-container-left"
      style={{
        width: '100%',
        flex: 1,
        alignItems: 'flex-start',
        alignContent: 'flex-start',
        justifyContent: 'flex-start',
        marginTop: '8px',
        paddingLeft: '4px',
        flexDirection: 'row',
        paddingBottom: isLast ? '24px' : '8px',
        ...props.style,
      }}>
      {addDateLine && <DateLine message={message} />}
      <ChatAvatarContainer avatarUrl={avatarUrl} name={avatarName}>
        {children}
      </ChatAvatarContainer>
    </div>
  );
};

export const ChatBubble: React.FC<ChatBubbleProps> = (props) => {
  const authStore = useAuthStore();
  const { message, addDateLine, isMine, isLast, classProductName, warningMent, avatarUrl, avatarName } = props;
  const classInvoiceModalStore = useClassInvoiceModalStore();
  const messageType = messageTypeFromMessage(message);
  const classInvoiceId = message.classCardId;
  const classId = message.classId;
  const classInvoiceQuery = useQuery({
    queryKey: [`class-invoice-${classInvoiceId}-done`],
    queryFn: () => classApi.getClassInvoice(classId!, classInvoiceId!),
    enabled: !!classInvoiceId && !!classId,
  });

  const updateChatMessageStatusMutation = useMutation({
    mutationFn: chatApi.updateChatMessageStatus,
    onSuccess: async () => {
      toast.dark('메시지 상태가 변경되었습니다', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: false,
        draggable: true,
        progress: undefined,
        bodyStyle: {
          color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
          textAlign: 'center',
          fontFamily: 'Pretendard',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '20px',
        },
        theme: 'dark',
      });
    },
  });

  const classInvoice = classInvoiceQuery.data?.data;
  const isTutor = (authStore.user?.id && classInvoice?.tutorId === authStore.user?.id) || false;
  const mode =
    classInvoice?.status === ClassInvoiceStatus.WAITING_FOR_PAYMENT ||
    classInvoice?.status === LegacyClassInvoiceStatus.WAITING_FOR_PAYMENT
      ? 'payment'
      : 'view';

  let bubbleContent = <div></div>;
  let isSystemMessage = false;

  switch (messageType) {
    case MessageType.WELCOME_WARNING:
      isSystemMessage = true;
      bubbleContent = <WelcomeWarningMessage warningMent={warningMent} />;
      break;
    case MessageType.PAYMENT_REQUEST:
      bubbleContent = (
        <CardMessage
          message={message}
          buttonStyle={{ backgroundColor: '#4a9fff' }}
          title={'수강증을\n결제해주세요'}
          headerIcon={ChatPaymentIcon}>
          <div
            key={Math.random()}
            style={{
              textAlign: 'left',
              fontSize: '15px',
              color: '#242424',
              whiteSpace: 'pre-wrap',
            }}>
            [{classProductName}]{'\n'}
            {'\n'}
            수강증이 발행되었습니다.{'\n'}
            {'\n'}
            스케줄, 아이 정보 등 수강증 내용을{'\n'}
            꼼꼼하게 확인하신 후 결제해 주세요.{'\n'}
            {'\n'}- 수강증 결제가 완료되어야만{'\n'}
            요청하신 스케줄이 최종 확정됩니다.
          </div>
          <div
            style={{
              marginTop: '20px',
            }}>
            <button
              className="link-button"
              style={{
                width: '100%',
                display: 'flex',
                height: '40px',
                padding: '4px',
                marginTop: '16px',
                backgroundColor: '#4a9fff',
                alignItems: 'center',
                borderRadius: '6px',
              }}
              onClick={() => {
                if (isTutor) {
                  classInvoiceModalStore.open({
                    classId: message.classId,
                    classInvoiceId: message.classCardId,
                    mode: 'view',
                  });
                  return;
                }

                props.onClickPayment && props.onClickPayment();

                classInvoiceModalStore.open({
                  classId: message.classId,
                  classInvoiceId: message.classCardId,
                  mode: mode,
                });
              }}>
              <div
                style={{
                  width: '100%',
                  textAlign: 'center',
                  fontSize: '13px',
                  color: 'white',
                }}>
                {mode === 'payment' ? (isTutor ? '수강일정 수정하기' : '결제하기') : '수강증 확인'}
              </div>
            </button>
          </div>
        </CardMessage>
      );
      break;
    case MessageType.CLASS_REPORT:
      bubbleContent = (
        <CardMessage
          message={message}
          buttonStyle={{ backgroundColor: '#4a9fff' }}
          title={'리포트가\n도착했어요'}
          headerIcon={ChatReportIcon}>
          <div
            key={Math.random()}
            style={{
              textAlign: 'left',
              fontSize: '15px',
              color: '#242424',
            }}>
            [{classProductName}]{'\n'}
            {'\n'}
            {message.parentChild ? '대상 어린이 : ' + message.parentChild + '\n\n' : ''}
            튜터님께서 작성해주신{'\n'}
            우리아이 리포트를 전달드려요.{' '}
          </div>
          <div
            style={{
              marginTop: '20px',
            }}>
            <button
              className="link-button"
              style={{
                width: '100%',
                display: 'flex',
                height: '40px',
                padding: '4px',
                marginTop: '16px',
                backgroundColor: '#eff1f4',
                alignItems: 'center',
                borderRadius: '6px',
              }}
              onClick={() => {}}>
              <div style={{ width: '100%', textAlign: 'center', fontSize: '13px' }}>리포트 열람하기</div>
            </button>

            <button
              disabled={isTutor}
              className="link-button"
              style={{
                width: '100%',
                display: 'flex',
                height: '40px',
                padding: '4px',
                marginTop: '4px',
                backgroundColor: isTutor ? '#eff1f4' : '#4a9fff',
                alignItems: 'center',
                borderRadius: '6px',
              }}
              onClick={() => {}}>
              <div
                style={{
                  width: '100%',
                  textAlign: 'center',
                  color: 'white',
                  fontSize: '13px',
                }}>
                후기 남기고 포인트 받기
              </div>
            </button>
          </div>
        </CardMessage>
      );
      break;

    case MessageType.PAYMENT_COMPLETE:
      bubbleContent = (
        <CardMessage
          message={message}
          buttonStyle={{ backgroundColor: '#4a9fff' }}
          title={'결제가\n완료되었어요'}
          headerIcon={ChatPaidIcon}>
          <div
            style={{
              textAlign: 'left',
              fontSize: '15px',
              color: '#242424',
              whiteSpace: 'pre-wrap',
            }}>
            [{classProductName}]{'\n\n'}
            스케줄 예약이 확정 되었습니다.
            {'\n\n'}
            {message.parentChild ? '대상 어린이 : ' + message.parentChild + '\n' : ''}
            {message.address ? '수업 장소 : ' + message.address + '' : ''}
          </div>
          <div
            style={{
              marginTop: '20px',
            }}>
            <button
              className="link-button"
              style={{
                width: '100%',
                display: 'flex',
                height: '40px',
                padding: '4px',
                marginTop: '16px',
                backgroundColor: '#eff1f4',
                alignItems: 'center',
                borderRadius: '6px',
              }}
              onClick={() => {
                classInvoiceModalStore.open({
                  classId: message.classId,
                  classInvoiceId: message.classCardId,
                  mode: 'view',
                });
              }}>
              <div
                style={{
                  width: '100%',
                  textAlign: 'center',
                  fontSize: '13px',
                  color: '#242424',
                }}>
                수강증 확인
              </div>
            </button>
          </div>
        </CardMessage>
      );
      break;

    case MessageType.CLASS_PERIOD_EXTEND:
      bubbleContent = (
        <CardMessage
          message={message}
          buttonStyle={{ backgroundColor: '#4a9fff' }}
          title={'수업을\n계속하고 싶어요'}
          headerIcon={ChatExtendIcon}>
          <div>
            <div
              style={{
                textAlign: 'left',
                fontSize: '15px',
                color: '#242424',
              }}>
              [{classProductName}]{'\n\n'}
              {message.parentChild ? '대상 어린이 : ' + message.parentChild + '\n' : ''}
              {message.address ? '수업 장소 : ' + message.address : ''}
            </div>
            {/* <div
              style={{
                marginTop: '20px',
              }}>
              <button
                className="link-button"
                style={{
                  width: '100%',
                  display: 'flex',
                  height: '40px',
                  padding: '4px',
                  marginTop: '16px',
                  backgroundColor: '#4a9fff',
                  alignItems: 'center',
                  borderRadius: '6px',
                }}
                onClick={() => {}}>
                <div
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    fontSize: '13px',
                    color: 'white',
                  }}>
                  {isTutor ? '수강증 생성' : '수강증 관리'}
                </div>
              </button>
            </div> */}
          </div>
        </CardMessage>
      );
      break;

    case MessageType.IMAGE:
      bubbleContent = (
        <div>
          <ImageMessage message={message}></ImageMessage>
          <Button
            style={{ marginTop: '12px' }}
            onClick={() => {
              updateChatMessageStatusMutation.mutate({
                messageId: message._id,
                status: message.status === 'block' ? 'default' : 'block',
              });
            }}>
            {message.status === 'block' ? '가리기 해제' : '가리기'}
          </Button>
          <Button
            style={{ marginTop: '12px' }}
            onClick={() => {
              updateChatMessageStatusMutation.mutate({
                messageId: message._id,
                status: message.status === 'delete' ? 'default' : 'delete',
              });
            }}>
            {message.status === 'delete' ? '복구' : '삭제'}
          </Button>
        </div>
      );
      break;

    case MessageType.CLASS_INQUIRY:
      bubbleContent = (
        <div
          style={{
            width: '100%',
            alignItems: 'flex-end',
            paddingRight: '10px',
            maxWidth: '280px',
          }}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              padding: '12px',
              backgroundColor: '#4a9fff',
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              overflow: 'hidden',
            }}>
            <div
              key={Math.random()}
              style={{
                textAlign: 'left',
                fontSize: '16px',
                fontWeight: 'bold',
                color: 'white',
                lineHeight: '22px',
                whiteSpace: 'pre-wrap',
              }}>
              안녕하세요, {'\n'}튜터님
            </div>
            <div>
              <img style={{ width: '24px', height: '24px' }} src={ChatRequestIcon} />
            </div>
          </div>
          <div
            style={{
              width: '100%',
              padding: '10px',
              backgroundColor: 'white',
              borderBottomRightRadius: '10px',
              borderBottomLeftRadius: '10px',
              overflow: 'hidden',
            }}>
            <div
              style={{
                textAlign: 'left',
                fontSize: '15px',
                color: '#242424',
                lineHeight: '22px',
                whiteSpace: 'pre-wrap',
              }}>
              {message.text
                .replace('[클래스 문의가 들어왔어요] \n\n안녕하세요. 튜터님! \n튜터님의 ', '')
                .replace('클래스에 대해 이야기 나누고 싶어요 :)\n', '\n클래스를 수강하고 싶어요!')
                .replace('수강 고객', '\n대상 어린이')}
            </div>
          </div>
        </div>
      );
      break;
    case MessageType.CHAT_END:
      bubbleContent = (
        <div
          style={{
            flexDirection: 'row',
            alignItems: 'flex-end',
            width: '100%',
            marginBottom: '10px',
            maxWidth: '280px',
          }}>
          <TimeLabel message={message} />
          <div
            style={{
              width: '100%',
              alignItems: 'flex-end',
              alignContent: 'flex-start',
              justifyContent: 'flex-end',
              paddingRight: '10px',
              flexDirection: 'column',
            }}>
            <div
              style={{
                width: '100%',
                paddingLeft: '16px',
                paddingRight: '16px',
                paddingTop: '2px',
                paddingBottom: '2px',
                backgroundColor: '#66686b',
                borderTopLeftRadius: '12px',
                borderTopRightRadius: '12px',
                overflow: 'hidden',
              }}>
              <div style={{ textAlign: 'left' }}>
                <div
                  style={{
                    lineHeight: '40px',
                    fontSize: '14px',
                    color: 'white',
                    fontWeight: 'bold',
                  }}>
                  아이고고 알림
                </div>
              </div>
            </div>
            <div
              style={{
                width: '100%',
                paddingLeft: '12px',
                paddingRight: '12px',
                paddingBottom: '11px',
                paddingTop: '11px',
                backgroundColor: '#ffffff',
                borderBottomLeftRadius: '12px',
                borderBottomRightRadius: '12px',
                overflow: 'hidden',
              }}>
              <div
                style={{
                  textAlign: 'left',
                  color: '#242424',
                  fontSize: '15px',
                  lineHeight: '22px',
                  whiteSpace: 'pre-wrap',
                }}>
                {'채팅 상담이 종료되었습니다\n감사합니다 :)'}
              </div>
            </div>
          </div>
        </div>
      );
      break;
    default:
      bubbleContent = (
        <div id="bubble-text" style={{ display: 'flex', position: 'relative' }}>
          {!isMine && (
            <div style={{ width: '0px' }}>
              <img
                style={{ width: '18px', height: '16px', position: 'absolute', left: '-4px' }}
                src={ChatLeftTailImage}></img>
            </div>
          )}
          <div
            style={{
              textAlign: 'left',
              fontSize: '15px',
              color: '#242424',
              lineHeight: '22px',
              padding: '12px',
              borderRadius: '12px',
              backgroundColor: isMine ? '#c5e0ff' : 'white',
              maxWidth: '280px',
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-word',
            }}>
            <Linkify>{message.text}</Linkify>
            <Button
              style={{ marginTop: '12px' }}
              onClick={() => {
                updateChatMessageStatusMutation.mutate({
                  messageId: message._id,
                  status: message.status === 'block' ? 'default' : 'block',
                });
              }}>
              {message.status === 'block' ? '가리기 해제' : '가리기'}
            </Button>
            <Button
              style={{ marginTop: '12px' }}
              onClick={() => {
                updateChatMessageStatusMutation.mutate({
                  messageId: message._id,
                  status: message.status === 'delete' ? 'default' : 'delete',
                });
              }}>
              {message.status === 'delete' ? '복구' : '삭제'}
            </Button>
          </div>
          {isMine && (
            <div style={{ width: '18px' }}>
              <img
                style={{ width: '18px', height: '16px', position: 'absolute', right: '15px', top: 0 }}
                src={ChatRightTailImage}></img>
            </div>
          )}
        </div>
      );
      break;
  }

  return (
    <ChatBubbleContainer
      style={props.style}
      message={message}
      isSystemMessage={isSystemMessage}
      addDateLine={addDateLine}
      isLast={isLast}
      isRight={isMine}
      avatarUrl={avatarUrl}
      avatarName={avatarName}>
      {bubbleContent}
    </ChatBubbleContainer>
  );
};
