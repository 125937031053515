import { DeleteOutlined } from '@ant-design/icons';
import FileUploadButton from '@components/common/FileUploadButton';
import { closestCenter, DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import {
  arrayMove,
  // verticalListSortingStrategy,
  horizontalListSortingStrategy,
  SortableContext,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { cdnImageUrl, resizeImage } from '@utils/image';
import { extractFileName } from '@utils/string';
import { Button, Input } from 'antd';
import { RcFile } from 'antd/es/upload';
import { useEffect, useRef, useState } from 'react';

interface SortableImageProps {
  onDeleteImage?: (event: any) => void;
  value?: any;
  key: any;
  id: any;
  imageUrl: string;
}

export const SortableImage: React.FC<SortableImageProps> = (props) => {
  const [hover, setHover] = useState<boolean>();
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const { onDeleteImage, imageUrl } = props;
  const onClickRemove = (event: any) => {
    event.preventDefault();
    onDeleteImage && onDeleteImage(event);
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          width: '184px',
        }}>
        <div style={{ width: '184px', height: '184px', position: 'relative', borderRadius: '8px', overflow: 'hidden' }}>
          <img src={cdnImageUrl(imageUrl)} style={{ width: '184px', height: '184px', objectFit: 'cover' }}></img>
          {hover && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
                backgroundColor: 'rgba(0,0,0,0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <button className="link-button" style={{ width: '48px', height: '48px' }} onClick={onClickRemove}>
                <div style={{ color: 'white' }}>
                  <DeleteOutlined style={{ color: 'white' }} size={48} />
                  <div>삭제하기</div>
                </div>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export interface ClassDetailEditData {
  images?: string[];
  imagesTemp?: string[];
  files?: File[];
  title?: string;
  description?: string;
}
export interface ClassDetailEditItemProps {
  editImage?: boolean;
  data?: ClassDetailEditData;
  onChange?: (value: ClassDetailEditData) => void;
}

export const ClassDetailEditItem: React.FC<ClassDetailEditItemProps> = (props) => {
  const [files, setFiles] = useState<File[]>([]);
  const [changedData, setChangedData] = useState<ClassDetailEditData>({});

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 10 },
    }),
  );

  useEffect(() => {
    if (changedData && Object.keys(changedData).length > 0) {
      props.onChange && props.onChange({ ...changedData, files });
    }
  }, [changedData, files]);

  const onChangeProperty = (key: string, value: any) => {
    setChangedData((prev: any) => {
      const newProperties = { ...prev };
      newProperties[key] = value;
      return { ...props.data, ...newProperties };
    });
  };

  const onChangeImageFiles = async (files: FileList) => {
    const imageFiles: File[] = [];
    const newFiles = await Promise.all(
      Array.from(files).map(async (file) => {
        const result = await resizeImage(file, { maxWidth: 856 });
        const { fileName } = extractFileName(file.name);
        const contentTypes = result.type.split('/');
        const imageFile = new File([result.image], `${fileName}.${contentTypes[1]}`) as RcFile;
        const tempUrl = URL.createObjectURL(result.image);

        imageFiles.push(imageFile);

        return { result, fileName: `${fileName}.${contentTypes[1]}`, imageFile, tempUrl };
      }),
    );

    setFiles((value) => {
      const newValue = [...value, ...imageFiles];
      return newValue;
    });

    const tempUrls: string[] = [];
    const fileNames: string[] = [];

    newFiles.forEach((newFile) => {
      tempUrls.push(newFile.tempUrl);
      fileNames.push(newFile.fileName);
    });

    onChangeProperty('imagesTemp', [...(props.data?.imagesTemp || props.data?.images || []), ...tempUrls].slice(0, 5));
    onChangeProperty('images', [...(props.data?.images || []), ...fileNames].slice(0, 5));
  };

  const onImagesSortEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const images = props.data?.images || [];
      const imagesTemp = props.data?.imagesTemp || props.data?.images || [];
      const oldIndex = images.findIndex((item) => item === active.id);
      const newIndex = images.findIndex((item) => item === over?.id);
      const newImages = arrayMove(images, oldIndex, newIndex);
      const newImagesTemp = arrayMove(imagesTemp, oldIndex, newIndex);

      onChangeProperty('images', newImages);
      onChangeProperty('imagesTemp', newImagesTemp);
    }
  };

  const onDeleteImage = (index: number) => {
    const newImages = [...(props.data?.images || [])];
    const newImagesTemp = [...(props.data?.imagesTemp || props.data?.images || [])];

    newImages.splice(index, 1);
    newImagesTemp.splice(index, 1);
    onChangeProperty('imagesTemp', newImagesTemp);
    onChangeProperty('images', newImages);
  };

  return (
    <div>
      {props.editImage && (
        <div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
            <div style={{ fontSize: '14px', fontWeight: 'bold' }}>소개 이미지(최대 5장)</div>
            <FileUploadButton
              multiple
              accept={['jpg', 'jpeg', 'png', 'tif', 'tiff', 'webp', 'svg']
                .map((ext) => {
                  return '.' + ext;
                })
                .join(',')}
              className="link-button"
              style={{
                fontSize: '14px',
                height: '32px',
                padding: '4px 15px',
                borderRadius: '6px',
                backgroundColor: '#ffffff',
                border: '1px solid #d9d9d9',
                color: 'rgba(0, 0, 0, 0.88)',
                boxShadow: '0 2px 0 rgba(0, 0, 0, 0.02)',
                cursor: 'pointer',
                marginLeft: '12px',
              }}
              onChange={(e) => {
                if (e.target.files) {
                  const files = e.target.files;
                  onChangeImageFiles(files);
                }
              }}>
              이미지 추가
            </FileUploadButton>
          </div>
          <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={onImagesSortEnd}>
            <SortableContext
              items={(props.data?.imagesTemp || props.data?.images || []).map((item) => item)}
              strategy={horizontalListSortingStrategy}>
              <div style={{ display: 'flex', gap: '4px', overflow: 'auto' }}>
                {(props.data?.imagesTemp || props.data?.images || []).map((imageUrl: string, index: number) => {
                  return (
                    <SortableImage
                      key={`image-${imageUrl}-${index}`}
                      id={imageUrl}
                      onDeleteImage={() => {
                        onDeleteImage(index);
                      }}
                      imageUrl={imageUrl}
                    />
                  );
                })}
              </div>
            </SortableContext>
          </DndContext>
        </div>
      )}
      <div style={{ alignItems: 'center', marginBottom: '8px' }}>
        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>제목</div>
        <Input
          style={{ maxWidth: '428px' }}
          value={changedData.title || props.data?.title}
          onChange={(e) => {
            onChangeProperty('title', e.target.value);
          }}></Input>
      </div>
      <div style={{ alignItems: 'center', marginBottom: '8px' }}>
        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>내용</div>
        <Input.TextArea
          rows={4}
          style={{ maxWidth: '428px' }}
          value={changedData.description || props.data?.description}
          onChange={(e) => {
            onChangeProperty('description', e.target.value);
          }}></Input.TextArea>
      </div>
    </div>
  );
};

export interface ClassDetailEditProps {
  editImage?: boolean;
  items: ClassDetailEditData[];
  onChange?: (items: ClassDetailEditData[]) => void;
}

export const ClassDetailEdit: React.FC<ClassDetailEditProps> = (props) => {
  const [changedItems, setChangedItems] = useState<ClassDetailEditData[]>([]);
  const timerRef = useRef<any>();

  useEffect(() => {
    if (changedItems.length > 0) {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => {
        props.onChange && props.onChange(changedItems);
      }, 300);
    }
  }, [changedItems]);

  const items = props.items || [];

  return (
    <div style={{ width: '428px' }}>
      {items.map((item, index) => {
        return (
          <div key={`class-detail-${index}`} style={{ marginTop: '12px' }}>
            <div style={{ display: 'flex', gap: '8px', justifyContent: 'flex-end' }}>
              <Button
                onClick={() => {
                  setChangedItems(() => {
                    const newValue = [...items];
                    newValue.splice(index, 1);
                    return newValue;
                  });
                }}>
                삭제
              </Button>
              <Button
                onClick={() => {
                  if (index > 0) {
                    setChangedItems(() => {
                      const newValue = [...items];
                      const prevItem = items[index - 1];
                      newValue[index - 1] = newValue[index];
                      newValue[index] = prevItem;
                      return newValue;
                    });
                  }
                }}>
                ↑
              </Button>
              <Button
                onClick={() => {
                  if (index < changedItems.length - 1) {
                    setChangedItems(() => {
                      const newValue = [...items];
                      const prevItem = items[index + 1];
                      newValue[index + 1] = newValue[index];
                      newValue[index] = prevItem;
                      return newValue;
                    });
                  }
                }}>
                ↓
              </Button>
            </div>
            <div>
              <ClassDetailEditItem
                data={item}
                editImage={props.editImage}
                onChange={(data) => {
                  setChangedItems(() => {
                    const newValue = [...items];
                    newValue[index] = data;
                    return newValue;
                  });
                }}></ClassDetailEditItem>
            </div>
          </div>
        );
      })}
      {props.items.length < 10 && (
        <div>
          <Button
            style={{ width: '428px' }}
            onClick={() => {
              setChangedItems(() => {
                const newValue = [...items, {}];
                return newValue;
              });
            }}>
            ➕ 추가
          </Button>
        </div>
      )}
    </div>
  );
};
