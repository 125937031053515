import { User } from './auth';
import { ProductModel } from './product';

type ScheduleType = 'oneday' | 'regular' | 'chat';
export type ChildInfoKeyType = 'name' | 'birthday' | 'gender';
export class ChildInfo {
  name?: string;
  birthday?: string;
  gender?: string;

  constructor(data: { name?: string; birthday?: string; gender?: string }) {
    this.name = data.name;
    this.birthday = data.birthday;
    this.gender = data.gender;
  }

  toString() {
    return `${this.name}-${this.birthday}-${this.gender}`;
  }
}
export interface ClassInquiryInfo {
  address?: any;
  classTitle?: string;
  children?: ChildInfo[];
  days?: string[];
  scheduleType?: ScheduleType;
  scheduleWithChat?: boolean;
}

export enum MessageType {
  WELCOME_WARNING = 'welcome_warning',
  IMAGE = 'image',
  CLASS_INQUIRY = 'class_inquiry',
  CHAT_END = 'chat_end',
  PAYMENT_REQUEST = 'payment_request',
  PAYMENT_COMPLETE = 'payment_complete',
  CLASS_REPORT = 'class_report',
  CLASS_PERIOD_EXTEND = 'class_period_extend',
  TEXT = 'text',
}

export interface ChatMessage {
  _id: string;
  type?: MessageType;
  status?: string;
  text: string;
  tutorId: string;
  parentId: string;
  classId: string;
  createdAt: string;
  chatId: string;
  chatRoomId: string;
  classCardId: string;
  makecard: string;
  payment: string;
  writereport: string;
  prolong: string;
  leave: string;
  address: string;
  parentChild: string;
  image: boolean;
  images: ChatImage[];
  targetAppToken?: string;
  readAt: string;
  updatedAt?: string;
}

export interface ExtendedChatMessage extends ChatMessage {
  class: ProductModel;
  tutor: User;
  parent: User;
}

export interface ChatImage {
  height: number;
  width: number;
  imageUrl: string;
}

export interface ChatRoom {
  _id: string;
  classId: string;
  parentId: string;
  tutorId: string;
  createdAt: string;
  updatedAt: string;
  chattext: string;
  unreadParentCount: number;
  unreadTutorCount: number;
  commentTutorCount: number;
  tutorLastMessageAt: string;
  unreadParentPushCount: number;
  parentLastMessageAt: string;
  unreadTutorPushCount: number;
  tutorPin: boolean;
  pin: boolean;
  inquiryInfo?: ClassInquiryInfo;
}

export interface ExtendedChatRoom extends ChatRoom {
  class: ProductModel;
  tutor: User;
  parent: User;
}

export type RemindChatType = 'tutor' | 'parent';
