import { Membership } from '@models/membership';
import { PaymentStatement } from '@models/paymentStatement';
import { SalesChannelOrder } from '@models/sales-channels';
import { create } from 'zustand';

type PaymentModel = Omit<PaymentStatement, 'payment' | 'memo' | 'buyer' | 'product'> &
  Omit<SalesChannelOrder, 'payment' | 'memo' | 'buyer' | 'product'> & {
    payment: {
      method?: string;
      gateway?: string;
      amount: number;
      point?: number;
      billingAmount: number;
      membershipDiscountAmount?: number;
      paidAt?: Date | string;
      result?: any;
      bankAccountTransferAmount?: number;
      expectedAmount?: number;
    };
    memo: {
      payment?: string;
      refund?: string;
      refundUser?: string;
      refundPartner?: string;
      settlement?: string;
      shipping?: string;
      bankAccountTransfer?: string;
    };
    buyer: {
      id?: string;
      name?: string;
      phone?: string;
      nid?: string;
      membership?: Partial<Membership>;
    };
    product: {
      saleType?: string;
      id?: string;
      name?: string;
      classInvoiceId?: string;
      applyGroupDiscount?: boolean;
    };
  };

interface AddAccountTransferModalProps {
  show: boolean;
  data: Partial<PaymentModel>;
  open: (data: Partial<PaymentModel>) => void;
  close: () => void;
}

const useAddAccountTransferModalStore = create<AddAccountTransferModalProps>((set) => ({
  show: false,
  data: {},
  open: (data) => {
    set({ data: data, show: true });
  },
  close: () => {
    set({ show: false });
  },
}));

export default useAddAccountTransferModalStore;
