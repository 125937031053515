import * as api from '@apis/customer-requests';
import { useAuthentication } from '@hooks/authentication';
import { CustomerRequest } from '@models/common';
import { useCustomerRequestsStore } from '@stores/customerRequestsStore';
import { useQuery } from '@tanstack/react-query';
import { Button, Input, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

export const CustomerRequestsPage: React.FC = () => {
  const [searchKeyword, setSearchKeyword] = useState<string>();
  const searchInputRef = useRef<any>();

  const store = useCustomerRequestsStore();

  useAuthentication();

  const customerRequestsQuery = useQuery({
    queryKey: [`customer-requests`, JSON.stringify(store.searchQuery)],
    queryFn: () => api.searchCustomerRequests(store.searchQuery),
  });

  const customerRequests = customerRequestsQuery.data?.data;

  useEffect(() => {
    if (customerRequests) {
      store.setCustomerRequestsContainer(customerRequests);
    }
  }, [customerRequests]);

  const TABLE_COLUMNS: ColumnsType<CustomerRequest> = [
    {
      title: 'ID',
      dataIndex: '_id',
      render: (_id) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(_id);
                toast.dark('ID를 클립보드에 복사했습니다', {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  pauseOnFocusLoss: false,
                  draggable: true,
                  progress: undefined,
                  bodyStyle: {
                    color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                    textAlign: 'center',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                  },
                  theme: 'dark',
                });
              }}>
              ID
            </Button>
          </div>
        );
      },
    },
    {
      title: '상품',
      width: 200,
      render: (record: CustomerRequest) => {
        return record.data.productName;
      },
    },
    {
      title: '메모',
      width: 200,
      render: (record: CustomerRequest) => {
        return record.data.memo;
      },
    },
    {
      title: '구분',
      width: 200,
      render: (record: CustomerRequest) => {
        return record.type;
      },
    },
    {
      title: '작성자',
      width: 200,
      render: (record: CustomerRequest) => {
        return record.user?.profile?.name;
      },
    },
    {
      title: '생성',
      dataIndex: 'createdAt',
      render: (createdAt) => {
        if (!createdAt) {
          return '-';
        }
        return moment(createdAt).format('YYYY.MM.DD HH:mm');
      },
    },
    {
      title: '수정',
      dataIndex: 'updatedAt',
      render: (updatedAt) => {
        if (!updatedAt) {
          return '-';
        }
        return moment(updatedAt).format('YYYY.MM.DD HH:mm');
      },
    },
  ];
  return (
    <div className="dashboard" style={{ position: 'relative' }}>
      <div className="page-header">
        <div>고객 요청 관리</div>
      </div>
      <div style={{ display: 'flex' }}>
        <div className="page-content" style={{ width: '100%', height: 'calc(100vh - 80px)', overflow: 'auto' }}>
          <div style={{ display: 'flex', gap: '12px', width: '100%', height: '52px', alignItems: 'center' }}>
            {/* <Select
              defaultValue="all"
              style={{ width: 160 }}
              onChange={(value) => {
                store.updateSearchQuery({ type: value });
              }}
              options={[
                { value: 'all', label: '전체' },
                { value: 'violation', label: '정책 위반 메시지' },
              ]}
            /> */}
            <Input
              ref={searchInputRef}
              style={{ width: '240px' }}
              value={searchKeyword}
              placeholder={'검색어 입력 후 엔터'}
              onChange={(e) => {
                setSearchKeyword(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.code === 'Enter' && searchKeyword) {
                  store.updateSearchQuery({ keyword: searchKeyword });
                }
              }}></Input>

            <div style={{ display: 'flex', marginLeft: 'auto', gap: '12px', alignItems: 'center' }}>
              <Button
                onClick={() => {
                  setSearchKeyword(undefined);
                  store.resetSearchQuery();
                }}>
                검색 초기화
              </Button>
            </div>
          </div>
          <Table
            columns={TABLE_COLUMNS}
            scroll={{ x: 'max-content', y: 'calc(100dvh - 280px)' }}
            style={{ whiteSpace: 'pre-wrap' }}
            rowKey={(record) => record?._id}
            dataSource={store.searchResultContainer.contents.filter((product) => product?._id)}
            pagination={{
              current: (store.searchResultContainer.page || 0) + 1,
              pageSize: store.searchResultContainer.size || 20,
              total: store.searchResultContainer.total,
            }}
            loading={customerRequestsQuery.isLoading}
            onChange={(e) => {
              store.updateSearchQuery({ page: (e.current || 2) - 1, size: e.pageSize || 20 });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomerRequestsPage;
