import * as api from '@apis/admin-user';
import * as membershipApi from '@apis/memberships';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { UpdateUserBody } from '@models/auth';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { useMutation, useQuery } from '@tanstack/react-query';
import { removeTempProperties } from '@utils/dataTransform';
import { Button, Checkbox, Modal, Select } from 'antd';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React, { useEffect, useState } from 'react';

export interface UserMembershipEditModalProps {
  open?: boolean;
  userId?: string;
  onClose?: () => void;
  needRefetch?: () => void;
}

interface SortableItemProps {
  onDeleteItem?: (event: any) => void;
  id: any;
  item: { key: string; label: string };
  canDelete?: boolean;
}

export const SortableItem: React.FC<SortableItemProps> = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const { onDeleteItem, item } = props;
  return (
    <div ref={setNodeRef} style={{ ...style }} {...attributes} {...listeners}>
      <div
        style={{
          display: 'flex',
          width: '80px',
          borderRadius: '8px',
          border: '1px solid #aaaaaa',
          justifyContent: 'space-between',
          alignItems: 'center',
          fontSize: '12px',
          padding: '4px',
        }}>
        <div style={{ flex: 1, textAlign: 'center' }}>{item.label}</div>
        <div style={{ flexShrink: 0 }}>
          {props.canDelete && (
            <button
              style={{ width: '20px' }}
              className="link-button"
              onClick={(e) => {
                onDeleteItem && onDeleteItem(e);
              }}>
              X
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export const UserMembershipEditModal: React.FC<UserMembershipEditModalProps> = (props) => {
  const [updateUserData, setUpdateUserData] = useState<UpdateUserBody>({});
  const dialog = useOkCancelDialog();
  const userQuery = useQuery({
    queryKey: [`user-item-${props.userId}`],
    queryFn: () => api.getUser(props.userId!),
    enabled: !!props.userId,
  });

  const membershipsQuery = useQuery({
    queryKey: [`memberships-edit`],
    queryFn: () => membershipApi.searchMemberships({ page: 0, size: 100 }),
  });

  useEffect(() => {
    setUpdateUserData({});
  }, [props.userId]);

  const memberships = membershipsQuery.data?.data.contents || [];
  const data = userQuery.data?.data;

  const updateUserMutation = useMutation({
    mutationFn: api.updateUser,
    onSuccess: async () => {
      props.onClose && props.onClose();
      props.needRefetch && props.needRefetch();
      dialog.open({
        content: '수정되었습니다.',
        type: 'ok',
      });
    },
    onError: async (e: any) => {
      props.onClose && props.onClose();
      props.needRefetch && props.needRefetch();
      dialog.open({
        title: '오류',
        content: e?.response?.data?.message,
        type: 'ok',
      });
    },
  });

  return (
    <Modal
      className="app-modal"
      open={props.open}
      styles={{ footer: { height: '44px' } }}
      footer={
        <div style={{ width: '100%' }}>
          <Button
            style={{
              width: 120,
            }}
            onClick={props.onClose}>
            취소
          </Button>
          <Button
            style={{
              width: 120,
              marginLeft: '8px',
              color: 'red',
            }}
            onClick={() => {
              dialog.open({
                title: '멤버십 삭제 확인',
                content: '멤버십을 삭제합니다',
                type: 'ok_cancel',
                onConfirm: () => {
                  const formData: FormData = new FormData();
                  const json = removeTempProperties({ membership: null });
                  formData.append('userId', props.userId!);
                  formData.append('json', JSON.stringify(json));

                  updateUserMutation.mutate(formData);
                },
              });
            }}>
            멤버십 삭제
          </Button>
          <Button
            style={{
              width: 120,
              marginLeft: '8px',
            }}
            onClick={() => {
              const formData: FormData = new FormData();
              const json = removeTempProperties({ ...updateUserData });
              formData.append('userId', props.userId!);
              formData.append('json', JSON.stringify(json));

              updateUserMutation.mutate(formData);
            }}>
            저장
          </Button>
        </div>
      }
      onCancel={props.onClose}>
      <OverlayScrollbarsComponent
        defer
        options={{ scrollbars: { autoHide: 'scroll' } }}
        style={{
          backgroundColor: '#ffffff',
          marginTop: 0,
          position: 'relative',
          height: '240px',
          overflow: 'auto',
        }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              fontSize: 20,
              fontWeight: 'bold',
              textAlign: 'center',
              marginBottom: 10,
            }}>
            {`${data?.profile?.name} 유저 멤버십 수정`}
          </div>
          <div style={{ textAlign: 'left', padding: 20 }}>
            <div style={{ marginTop: 10 }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '4px', fontWeight: 'bold' }}>
                <Select
                  style={{ width: '400px' }}
                  placeholder={'멤버십 없음'}
                  value={data?.membership?.alias}
                  options={memberships.map((membership) => {
                    return { value: membership.alias, label: membership.name };
                  })}
                  onChange={(value) => {
                    const membership = memberships.find((item) => item.alias === value);
                    const newMembership = {
                      _id: membership?._id,
                      alias: membership?.alias,
                      name: membership?.name,
                      color: membership?.color,
                      qualified: true,
                      joinDate: new Date(),
                    };
                    setUpdateUserData((prev) => {
                      return { ...prev, membership: newMembership } as any;
                    });
                  }}></Select>
              </div>
            </div>
            <div style={{ marginTop: 10 }}>
              <Checkbox
                checked={
                  updateUserData?.membership?.qualified === undefined
                    ? data?.membership?.qualified
                    : updateUserData?.membership?.qualified
                }
                onChange={(e) => {
                  setUpdateUserData((prev) => {
                    return { ...prev, membership: { ...data?.membership, qualified: e.target.checked } } as any;
                  });
                }}>
                인증 여부
              </Checkbox>
            </div>
          </div>
        </div>
      </OverlayScrollbarsComponent>
    </Modal>
  );
};
