import { FeedData } from '@models/feed';
import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { SelectProductCollection } from './SelectProductCollection';

export const CollectionFeedEdit: React.FC<{
  clear?: boolean;
  data: Partial<FeedData>;
  onChange?: (data: Partial<FeedData>) => void;
}> = (props) => {
  const [changedData, setChangedData] = useState<FeedData>();
  const { data } = props;
  const feedData = changedData || data || [];

  useEffect(() => {
    if (props.clear) {
      setChangedData(undefined);
    }
  }, [props.clear]);

  useEffect(() => {
    if (changedData) {
      props.onChange && props.onChange({ ...data, ...changedData });
    }
  }, [changedData]);

  return (
    <div>
      <div style={{ display: 'flex', height: '44px', justifyContent: 'space-between' }}>
        <Select
          style={{ width: '200px' }}
          value={feedData.type}
          options={[
            { value: 'curation-class-general', label: '일반형' },
            { value: 'curation-class-grid', label: '그리드형' },
            { value: 'curation-class-list', label: '리스트형' },
          ]}
          onChange={(value) => {
            setChangedData((prev) => {
              return { ...data, ...prev, type: value };
            });
          }}></Select>
      </div>
      <div>
        <div>기획전 선택</div>
        <SelectProductCollection
          onSelect={(value) => {
            setChangedData((prev) => {
              return { ...data, ...prev, collectionId: value._id, title: value.title } as FeedData;
            });
          }}></SelectProductCollection>
        <div style={{ marginTop: '8px' }}>
          <span>선택: </span>
          {feedData.title}
        </div>
      </div>
    </div>
  );
};

export default CollectionFeedEdit;
