import React from 'react';

import { ClassInvoice } from '@models/invoice';
import { ProductModel } from '@models/product';
import { ClassSchedule } from '@models/schedule';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import ClassAddressView from '../ClassAddressView';
import ClassFeeView from '../ClassFeeView';
import { ClassInvoiceButton } from '../ClassInvoiceButton';
import { ClassInvoiceSummary } from '../ClassInvoiceSummary';
import Divider from '../Divider';
import PaymentResult from '../PaymentResult';
import './ClassInvoice.scss';
import { useQuery } from '@tanstack/react-query';
import * as api from '@apis/app-classes';
import { useClassInvoiceModalStore } from '@stores/classInvoiceModalStore';

interface ClassInvoiceViewProps {
  classProduct?: ProductModel;
  classInvoice: ClassInvoice;
  isTutorLogin?: boolean;
  onCancel?: () => void;
  onConfirm?: (data: any) => void;
  onClickReport?: () => void;
  onChangeSchedule?: (schedule: Partial<ClassSchedule>) => void;
  onClickScheduleEdit?: () => void;
}

export const ClassInvoiceView: React.FC<ClassInvoiceViewProps> = (props) => {
  const { classInvoice, classProduct } = props;
  const selectedChildrenList = classInvoice?.students || [];
  const otherChildrenCount = classInvoice?.additionalParticipantCount || 0;
  const classInvoiceModalStore = useClassInvoiceModalStore();
  const classThumbnail = classInvoice.classThumbnail;

  const paymentStatementQuery = useQuery({
    queryKey: [`class-item-${classInvoice.classId || classInvoice.programId}-${classInvoice._id}`],
    queryFn: () => api.getPaymentStatement(classInvoice.classId || classInvoice.programId, classInvoice._id!),
    enabled: !!classInvoice._id,
  });

  const paymentStatement = classInvoice.paymentStatement || paymentStatementQuery.data?.data;
  const paid =
    (paymentStatement?.statuses?.payment || '').toLowerCase() === 'done' ||
    ['class_completed', 'payment_completed', 'payment_refund', 'ONGOING_STATUS', 'COMPLETE_STATUS'].includes(
      classInvoice.status,
    );

  return (
    <OverlayScrollbarsComponent
      defer
      options={{
        scrollbars: { autoHide: 'scroll' },
        overflow: {
          x: 'hidden',
        },
      }}
      style={{
        backgroundColor: '#ffffff',
        marginTop: 0,
        position: 'relative',
        height: '100%',
        overflow: 'auto',
        width: '100%',
      }}>
      <div style={{ width: '100%' }}>
        <div style={{ padding: '20px 16px', backgroundColor: '#EFF1F4' }}>
          <ClassInvoiceSummary
            paid={paid}
            isTutor={props.isTutorLogin}
            tutorProfileImage={classInvoice.tutorProfileImage}
            tutorName={classInvoice.tutorName}
            classTitle={classInvoice.classTitle}
            classThumbnail={classThumbnail}
            students={selectedChildrenList}
            additionalParticipantCount={otherChildrenCount}
            totalClassTimes={classInvoice.totalClassTimes}
            classDuration={classInvoice.classDuration}
            schedules={classInvoice.schedules}
            report={classInvoice.report}
            onChangeSchedule={props.onChangeSchedule}
            onClickScheduleEdit={props.onClickScheduleEdit}
            onClickShowClassReport={() => {
              props.onCancel && props.onCancel();
            }}
            onClickCreateClassReport={() => {
              props.onCancel && props.onCancel();
            }}
            refund={paymentStatement.statuses.refund === 'all'}
          />
        </div>
        <ClassAddressView address={classInvoice.address} addressDetail={classInvoice.addressDetail} />
        <Divider />
        {props.isTutorLogin && (
          <ClassFeeView
            defaultClassFee={classInvoice.classFeePerTime || (classInvoice.totalAmount || 0) / 4 || classProduct?.fee}
            totalClassTimes={classInvoice.totalClassTimes}
            totalStudentCount={selectedChildrenList.length + otherChildrenCount}
            applyGroupDiscount={classInvoice.applyGroupDiscount}
          />
        )}
        {paid && paymentStatement && !props.isTutorLogin && (
          <PaymentResult
            showClassInfo
            classFee={classInvoice.classFeePerTime || (classInvoice.totalAmount || 0) / 4 || classProduct?.fee}
            totalClassTimes={classInvoice.totalClassTimes}
            totalStudentCount={selectedChildrenList.length + otherChildrenCount}
            applyGroupDiscount={classInvoice.applyGroupDiscount}
            payment={paymentStatement?.payment}
          />
        )}

        {!paid && props.isTutorLogin && (
          <div style={{ marginBottom: '24px' }}>
            <div style={{ textAlign: 'center', fontWeight: 'bold', color: '#FF3D8F' }}>
              아직 결제가 진행되지 않았습니다.
            </div>
          </div>
        )}
        <div style={{ padding: '24px 20px', backgroundColor: '#E8EAED' }}>
          {!paid && !props.isTutorLogin && (
            <div>
              <div style={{ textAlign: 'center', fontWeight: 'bold' }}>아래 버튼을 눌러 결제를 완료해주세요!</div>
              <ClassInvoiceButton
                theme="primary"
                onClick={() => {
                  classInvoiceModalStore.open({
                    classId: classInvoice.classId || classInvoice.programId,
                    classInvoiceId: classInvoice._id,
                    mode: 'payment',
                    isTutor: false,
                  });
                }}>
                결제하러 가기
              </ClassInvoiceButton>
            </div>
          )}
          <ClassInvoiceButton
            theme="secondary"
            onClick={() => {
              props.onCancel && props.onCancel();
            }}>
            채팅하기
          </ClassInvoiceButton>
        </div>
      </div>
    </OverlayScrollbarsComponent>
  );
};
