import * as api from '@apis/app-chats';
import * as productsApi from '@apis/app-products';
import * as userApi from '@apis/app-user';
import CameraOnIcon from '@assets/images/app/btn-camera-on.png';
import SendOffIcon from '@assets/images/app/btn-send-off.png';
import SendOnIcon from '@assets/images/app/btn-send-on.png';
import ChatTopBottomIcon from '@assets/images/app/chat-bottom-right.png';
import ChatTopRightIcon from '@assets/images/app/chat-top-right.png';
import LoaderImage from '@assets/images/app/loading_img.gif';
import ParentDefaultAvatar from '@assets/images/app/parentintro.png';
import TutorDefaultAvatar from '@assets/images/app/tutorintro.png';
import FileUploadButton from '@components/common/FileUploadButton';
import { useAppSocket } from '@hooks/appSocket';
import { usePayment } from '@hooks/payment';
import { ChatMessage } from '@models/chat';
import { useClassInvoiceModalStore } from '@stores/classInvoiceModalStore';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { Virtuoso } from 'react-virtuoso';
import { ChatBubble } from './ChatBubble';

export const ChatPreview: React.FC<{ productId: string; parentId: string; tutorId: string }> = (props) => {
  const virtuoso = useRef<any>();
  const shouldScrollToBottom = useRef<boolean>(true);
  const userScrollChanged = useRef<boolean>(false);
  const [chatMessage, setChatMessage] = useState<string>('');
  const [messageContainer, setMessageContainer] = useState({
    messages: [] as ChatMessage[],
    page: 0,
    size: 20,
    hasNext: false,
  });
  const appSocket = useAppSocket();
  const introWarningMessage = {
    parent:
      '💌 매너있는 채팅을 부탁드립니다.\n- 튜터 대상의 영업은 이용약관 위반으로 제재될 수 있습니다.\n- 상호간에 불쾌한 메시지 전달을 금지합니다.\n(아이고고 이용약관 보기)\n\n✅ 아이고고 클래스는 정부의 방역지침을 준수하며,\n1:1 또는 소규모 수업으로 항상 안전하게 진행됩니다.\n(아이고고 방역지침 보기)',
    tutor:
      '✅ 클래스 중 마스크 착용, 손 소독 및 교구 소독 등\n아이고고 방역지침을 준수해주세요.\n(아이고고 방역지침 보기)\n\n⚠️ 개인거래 유도, 수업 외 영업, 불쾌한 메시지 전송은\n아이고고 이용약관 위반으로 제재될 수 있습니다.\n(아이고고 이용약관 보기)\n\n💌 접종완료 인증이나 음성 검사결과를 상담에 적극 활용해보세요.',
  };
  const productId = props.productId;
  const parentId = props.parentId;
  const tutorId = props.tutorId;

  const classItemQuery = useQuery({
    queryKey: [`class-item-${productId}`],
    queryFn: () => productsApi.getProduct(productId!),
    enabled: !!productId,
  });

  const classProduct = classItemQuery.data?.data;
  const sellerName = classProduct?.sellerName || '';
  const tutorProfileImageUrl = classProduct?.sellerProfileImage;
  const classProductName = classProduct?.title;
  const isTutorLogin = false;
  const chatMessagesQuery = useQuery({
    queryKey: [
      `chat-messages-${productId}-${tutorId}-${parentId}`,
      { page: messageContainer.page },
      { updatedAt: messageContainer.messages.length === 0 },
    ],
    queryFn: () => api.getChatMessages(roomId, messageContainer.page, messageContainer.size),
    enabled: !!(productId && tutorId && parentId),
  });

  const parentUserQuery = useQuery({
    queryKey: [`user-${parentId}`],
    queryFn: () => userApi.getUser(parentId),
    enabled: !!(productId && tutorId && parentId),
  });

  const chatMessagesQueryData = chatMessagesQuery.data?.data;
  const roomId = `${productId}_${tutorId}_${parentId}`;
  const classInvoiceModalStore = useClassInvoiceModalStore();
  const payment = usePayment();

  useEffect(() => {
    if (payment.status === 'done') {
      if (payment.paymentStatement?.product?.id) {
        if (payment.paymentStatement?.product.classInvoiceId) {
          classInvoiceModalStore.open({
            classId: payment.paymentStatement?.product.id,
            classInvoiceId: payment.paymentStatement?.product.classInvoiceId,
            mode: 'payment_completed',
          });
        }
      }
    }
  }, [payment.status]);

  useEffect(() => {
    if (!classInvoiceModalStore.show) {
      chatMessagesQuery.refetch().then(() => {
        scrollToBottomDelay(false, 300);
      });
    }
  }, [classInvoiceModalStore.show]);

  const receiveChatMessage = (data: ChatMessage) => {
    if (roomId !== `${data.classId}_${data.tutorId}_${data.parentId}`) {
      return;
    }

    setMessageContainer((container) => {
      const newContainer = { ...container };
      const existIds = newContainer.messages.map((message) => {
        return message._id;
      });

      let newMessages = newContainer.messages;

      if (!existIds.includes(data._id)) {
        newMessages.push(data);
      }

      newMessages = newMessages.sort((lv: any, rv: any) => {
        return moment(lv.createdAt).diff(rv.createdAt);
      });

      newContainer.messages = newMessages;

      if (!userScrollChanged.current) {
        shouldScrollToBottom.current = true;
      }

      return newContainer;
    });
    scrollToBottomDelay(true);
  };

  useEffect(() => {
    appSocket.socket.addSocketEventListener('chat_message', 'chat_receive_message', receiveChatMessage);
    return () => {
      appSocket.socket.removeSocketEventListener('chat_message', 'chat_receive_message');
    };
  }, []);

  useEffect(() => {
    if (chatMessagesQueryData) {
      const newContainer = { ...messageContainer };
      const existIds = newContainer.messages.map((message) => {
        return message._id;
      });

      const newMessages = newContainer.messages;

      (chatMessagesQueryData?.contents || []).forEach((message: any) => {
        if (!existIds.includes(message._id)) {
          const warningFound = newMessages.find((message) => message.classId === message.text);
          if (message.classId === message.text) {
            if (!warningFound) {
              newMessages.push({ ...message, createdAt: new Date(0, 0, 0).toISOString() });
            }
          } else {
            newMessages.push({ ...message });
          }
        }
      });

      newContainer.messages = newMessages.sort((lv: any, rv: any) => {
        return moment(lv.createdAt).diff(rv.createdAt);
      });

      newContainer.page = chatMessagesQueryData.page;
      newContainer.size = chatMessagesQueryData.size;
      newContainer.hasNext = chatMessagesQueryData.hasNext;

      setMessageContainer(newContainer);
      if (chatMessagesQueryData.page > 0 && !shouldScrollToBottom.current) {
        scrollToIndex(chatMessagesQueryData.contents.length - 1);
      }

      return () => {
        userScrollChanged.current = false;
      };
    }
  }, [chatMessagesQueryData?.contents]);

  const messages = messageContainer.messages;
  const parent = parentUserQuery.data?.data;

  const scrollToBottom = (smooth: boolean = true) => {
    if (virtuoso.current && messages.length > 0) {
      virtuoso.current.scrollTo({ top: Number.MAX_SAFE_INTEGER, behavior: smooth ? 'smooth' : undefined });

      shouldScrollToBottom.current = false;
    }
  };

  const scrollToBottomDelay = (smooth: boolean = true, delay: number = 200) => {
    setTimeout(() => {
      scrollToBottom(smooth);
    }, delay);
  };

  const scrollToIndex = (index: number, smooth: boolean = false) => {
    if (virtuoso.current && messages.length > 0) {
      virtuoso.current.autoscrollToBottom();
      virtuoso.current.scrollToIndex({
        index: index,
        align: 'start',
        behavior: smooth ? 'smooth' : undefined,
      });
      shouldScrollToBottom.current = false;
    }
  };

  const title = `${sellerName} 튜터 / ${parent?.profile?.name} 부모님`;

  return (
    <div>
      <div
        style={{
          position: 'sticky',
          zIndex: 1,
          top: 0,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '56px',
          backgroundColor: '#ffffff',
          borderBottom: '1px solid #eff1f4',
          fontSize: '17px',
          fontWeight: 'bold',
          color: '#242424',
        }}>
        {title}
      </div>
      <div style={{ height: 'calc(100dvh - 100px)', overflowX: 'hidden', overflowY: 'auto', position: 'relative' }}>
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <div
            style={{
              display: 'flex',
              height: '38px',
              backgroundColor: '#FFF',
              alignItems: 'center',
              flexDirection: 'row',
            }}>
            <div
              style={{
                display: 'flex',
                height: '38px',
                width: '100%',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={() => {}}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  height: '38px',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  borderTop: '1px solid #f5f7fb',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  alignContent: 'center',
                }}>
                <div>
                  <div style={{ fontSize: '12px', color: '#424242' }}>{classProductName}</div>
                </div>
                <div>
                  <img style={{ width: '12px', height: '12px' }} src={ChatTopRightIcon} />
                </div>
              </div>
            </div>
          </div>
          {chatMessagesQuery.isLoading && (
            <div
              style={{ position: 'absolute', width: '100%', top: '40px', display: 'flex', justifyContent: 'center' }}>
              <img style={{ width: '80px', height: '40px' }} src={LoaderImage} />
            </div>
          )}
          <div id="chat-container" style={{ height: '100%', backgroundColor: '#e8eaed' }}>
            <Virtuoso
              ref={virtuoso}
              atTopStateChange={(atTop: boolean) => {
                if (atTop && messageContainer.hasNext) {
                  setMessageContainer((value) => {
                    const newValue = { ...value };
                    newValue.page = Number(value.page) + 1;
                    return newValue;
                  });
                }
              }}
              atBottomStateChange={(atBottom: boolean) => {
                if (shouldScrollToBottom.current && !atBottom) {
                  scrollToBottomDelay(false, 200);
                  scrollToBottomDelay(false, 400);
                }

                userScrollChanged.current = !atBottom;
              }}
              style={{ height: '100%', overflowX: 'hidden' }}
              totalCount={messages?.length || 0}
              itemContent={(index) => {
                const message = messages[index];
                const addDateLine =
                  index > 0 &&
                  moment(messages[index - 1].createdAt).format('YYYY-MM-DD') !==
                    moment(messages[index].createdAt).format('YYYY-MM-DD');

                const isMine = message.chatId === message.parentId;
                const isTutorMessage = message.chatId === message.tutorId;
                return (
                  <ChatBubble
                    key={`chat-bubble-${index}`}
                    message={message}
                    classProductName={classProductName!}
                    addDateLine={addDateLine}
                    isMine={isMine}
                    warningMent={isTutorLogin ? introWarningMessage.tutor : introWarningMessage.parent}
                    avatarUrl={
                      isTutorMessage
                        ? tutorProfileImageUrl || TutorDefaultAvatar
                        : parent?.profile?.imageUrl || ParentDefaultAvatar
                    }
                    avatarName={isTutorMessage ? sellerName : parent?.profile?.name}
                    isLast={index + 1 === messages.length}
                    onClickPayment={() => {
                      payment.clear();
                    }}></ChatBubble>
                );
              }}
            />
          </div>
          <div id="chat-input-conatiner" style={{ flexShrink: 0, width: '100%' }}>
            <div style={{ backgroundColor: '#e8eaed', width: '100%', padding: '0 12px 12px 12px' }}>
              <div
                style={{
                  width: '100%',
                  height: '44px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  marginRight: '8px',
                  borderRadius: '8px',
                  overflow: 'hidden',
                }}>
                <button
                  className={'link-button'}
                  style={{
                    width: '100%',
                    display: 'flex',
                    backgroundColor: '#4a9fff',
                    height: '44px',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={() => {}}>
                  <div style={{ fontSize: '14px', color: 'white' }}>
                    {isTutorLogin ? '나의 스케줄' : '튜터 프로필 보기'}
                  </div>
                  <img style={{ marginLeft: '4px', width: '14px', height: '14px' }} src={ChatTopBottomIcon} />
                </button>
                <div style={{ width: '1px', backgroundColor: '#4a9fff', height: '44px' }}>
                  <div
                    style={{
                      width: '1px',
                      backgroundColor: 'white',
                      height: '44px',
                      opacity: 0.2,
                    }}
                  />
                </div>
                <button
                  className={'link-button'}
                  style={{
                    width: '100%',
                    backgroundColor: '#4a9fff',
                    height: '44px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderTopRightRadius: '8px',
                    borderBottomRightRadius: '8px',
                  }}
                  onClick={() => {}}>
                  <div
                    style={{
                      backgroundColor: '#4a9fff',
                      height: '44px',
                      flex: 1,
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderTopRightRadius: '8px',
                      borderBottomRightRadius: '8px',
                      display: 'flex',
                      flexDirection: 'row',
                    }}>
                    <div style={{ fontSize: '14px', color: 'white' }}>
                      {isTutorLogin ? '수강증 생성' : '수강증 관리'}
                    </div>
                    <img style={{ marginLeft: '4px', width: '14px', height: '14px' }} src={ChatTopBottomIcon} />
                  </div>
                </button>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                maxHeight: '110px',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: 'white',
                bottom: 0,
                flexDirection: 'row',
              }}>
              <FileUploadButton
                multiple
                accept={['jpg', 'jpeg', 'png', 'tif', 'tiff', 'webp', 'svg']
                  .map((ext) => {
                    return '.' + ext;
                  })
                  .join(',')}
                className="link-button"
                style={{
                  display: 'flex',
                  alignSelf: 'center',
                  width: '40px',
                  height: '24px',
                  justifyContent: 'center',
                }}>
                <img style={{ width: '24px', height: '24px' }} src={CameraOnIcon} />
              </FileUploadButton>
              <div
                style={{
                  borderRadius: '22px',
                  border: '1px solid #e8eaed',
                  paddingTop: '8px',
                  paddingBottom: '8px',
                  paddingLeft: '16px',
                  marginTop: '8px',
                  marginBottom: '8px',
                  width: 'calc(100% - 80px)',
                }}>
                <TextareaAutosize
                  value={chatMessage}
                  minRows={2}
                  maxRows={4}
                  style={{
                    width: 'calc(100% - 16px)',
                    fontSize: '14px',
                    color: '#000000',
                    resize: 'none',
                    border: 'none',
                    outline: 'none',
                  }}
                  placeholder="메세지를 입력해주세요."
                  onChange={(e) => {
                    setChatMessage(e.target.value);
                  }}
                />
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '40px',
                  marginBottom: '2px',
                }}
                onClick={() => {}}>
                <div
                  style={{
                    width: '24px',
                    height: '24px',
                  }}>
                  <img
                    style={{ width: '24px', height: '24px' }}
                    src={chatMessage.length > 0 ? SendOnIcon : SendOffIcon}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatPreview;
