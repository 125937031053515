import { PagenatedResponse } from '@models/common';
import { ProductOrderViewModel } from '@models/productOrderView';
import { PaymentStatementsQuery } from '@stores/paymentStatementsStore';
import { apiClient } from '.';

export const searchProductOrders = async (query?: PaymentStatementsQuery) => {
  return apiClient.get<PagenatedResponse<ProductOrderViewModel>>(`/admin/v1/product-orders`, {
    params: query,
  });
};
