import { PagenatedResponse } from '@models/common';
import { Feed } from '@models/feed';
import { ProductModel, ProductSearchQuery } from '@models/product';
import { apiClient } from '.';

export const searchFeedLogs = async (query?: ProductSearchQuery) => {
  return apiClient.get<PagenatedResponse<ProductModel>>(`/admin/v1/feed/logs`, {
    params: query,
  });
};

export const getFeed = async (type: 'main-feed' | 'md-pick') => {
  return apiClient.get<Feed>(`/admin/v1/feed/${type}`);
};

export const refreshMainFeed = async () => {
  return apiClient.post(`/admin/v1/schedule/refresh-mainfeed`);
};

export const updateMainFeed = async (formData: FormData) => {
  return apiClient.put(`/admin/v1/feed/main-feed`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const updateMdPick = async (formData: FormData) => {
  return apiClient.put(`/admin/v1/feed/md-pick`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const deployFeed = async (type: 'main-feed' | 'md-pick') => {
  return apiClient.post<Feed>(`/admin/v1/feed/${type}/deploy`);
};

export const getBanners = async () => {
  return apiClient.get<Feed>(`/admin/v1/feed/banners`);
};

export const updateBanners = async (formData: FormData) => {
  return apiClient.put(`/admin/v1/feed/banners`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const getFeedElements = async () => {
  return apiClient.get<{
    searchPlaceholder?: Feed;
    popularKeywords?: Feed;
    recommendKeywords?: Feed;
    chatIntroWarnings?: Feed;
    loungeCurationCategories?: Feed;
  }>(`/admin/v1/feed/elements`);
};

export const updateFeedElements = async (body: {
  searchPlaceholder?: Feed;
  popularKeywords?: Feed;
  recommendKeywords?: Feed;
  chatIntroWarnings?: Feed;
  loungeCurationCategories?: Feed;
}) => {
  return apiClient.put(`/admin/v1/feed/elements`, body);
};
