import { RcFile } from 'antd/es/upload';
import { UserProfile } from './auth';
import { PagenatedResponse } from './common';
import { PaymentStatement } from './paymentStatement';
import { ProductModel } from './product';

export enum UserContentStatus {
  OPEN = 'open', // 게시
  PENDING = 'pending', // 대기
  SUGGESTION = 'suggestion', // 제안
  HIDDEN = 'hidden', // 숨김
  RESERVED = 'reserved', // 예약
  END = 'end', // 예약 종료
  DELIST = 'delist', // 삭제대기
}

export interface UserContent {
  _id: string;
  board: string; // review, curation, suggestion
  category: string; // lounge curation categories
  type: string; // post, instagram, twitter, ad
  publisherId: string;
  editorId?: string;
  rootId?: string;
  paymentStatementId?: string;
  product?: Partial<ProductModel>;
  text: string;
  originText: string;
  images?: string[];
  rates?: UserContentRate;
  status: UserContentStatus;
  publisher?: {
    name?: string;
    nickname?: string;
    message?: string;
    imageUrl?: string;
    age?: string;
    location?: string;
    profile?: UserProfile;
  };
  editor?: { name?: string };
  paymentStatement?: PaymentStatement;
  bestDate?: Date;
  bestPointGrantedAt?: Date;
  publishedAt?: Date;
  createdAt: Date;
  updatedAt: Date;
}

export interface UserContentRate {
  rate1: number;
  rate2: number;
  rate3: number;
  rate4: number;
}

export interface UserContentEditData {
  rate1?: number;
  rate2?: number;
  rate3?: number;
  rate4?: number;
  text: string;
  originText: string;
  images?: string[];
  imagesTemp?: string[];
  files?: { [key: string]: RcFile };
  type: string;
  category: string;
  board: string;
  title?: string;
  description?: string;
}

export interface CreateUserContentBody extends UserContentEditData {
  productId?: string;
  paymentStatementId?: string;
}

export enum UserContentRateType {
  SOCIABILITY = '친화력',
  PROFESSIONALISM = '전문성',
  READINESS = '준비성',
  PUNCTUALITY = '시간준수',
}

export interface Children {
  name: string;
  birthday: string;
  gender: string;
}

export interface UserContentContainer extends PagenatedResponse<UserContent> {
  rateSummary?: { avg: number; rate1: number; rate2: number; rate3: number; rate4: number };
}

export interface UrlPreviewData {
  success: boolean;
  ogTitle: string;
  ogUrl: string;
  ogDescription: string;
  twitterCard: string;
  twitterUrl: string;
  twitterDescription: string;
  ogImage: OgImage[];
  twitterImage: TwitterImage[];
  ogLocale: string;
  favicon: string;
  charset: string;
}

export interface OgImage {
  url: string;
  type: string;
}

export interface TwitterImage {
  url: string;
}
