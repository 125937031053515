import { PagenatedQuery, PagenatedResponse } from '@models/common';
import {
  KakaoNotificationLog,
  KakaoReservedNotification,
  PushNotification,
  PushNotificationBody,
} from '@models/notifications';
import { apiClient } from '.';

export const getPushNotifications = async (query: PagenatedQuery) => {
  return apiClient.get<PagenatedResponse<PushNotification>>(`/admin/v1/notifications/push`, {
    params: query,
  });
};

export const createPushNotification = async (data: { type: string; notificationData: PushNotificationBody }) => {
  return apiClient.post(`/admin/v1/notifications/push`, data);
};

export const updatePushNotification = async (data: {
  notificationId: string;
  notificationData: PushNotificationBody;
}) => {
  return apiClient.put(`/admin/v1/notifications/push/${data.notificationId}`, data.notificationData);
};

export const deletePushNotification = async (notificationId: string) => {
  return apiClient.delete(`/admin/v1/notifications/push/${notificationId}`);
};

export const getKakaoReservedNotifications = async (query: PagenatedQuery) => {
  return apiClient.get<PagenatedResponse<KakaoReservedNotification>>(`/admin/v1/notifications/kakao/reserved`, {
    params: query,
  });
};

export const getKakaoNotificationLogs = async (query: PagenatedQuery) => {
  return apiClient.get<PagenatedResponse<KakaoNotificationLog>>(`/admin/v1/notifications/kakao`, {
    params: query,
  });
};

export const retryKakaoNotificationLog = async (notificationId: string) => {
  return apiClient.post(`/admin/v1/notifications/kakao/${notificationId}`);
};
