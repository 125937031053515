import { ExtendedChatMessage, ExtendedChatRoom, RemindChatType } from '@models/chat';
import { PagenatedResponse, SearchPagenateQuery } from '@models/common';
import { apiClient } from '.';

export const searchChatRooms = async (query?: SearchPagenateQuery) => {
  return apiClient.get<PagenatedResponse<ExtendedChatRoom>>(`/admin/v1/chats`, {
    params: query,
  });
};

export const searchViolationMessages = async (query?: SearchPagenateQuery) => {
  return apiClient.get<PagenatedResponse<ExtendedChatMessage>>(`/admin/v1/chats/violation-detected-messages`, {
    params: query,
  });
};

export const searchChatMessages = async (query?: SearchPagenateQuery) => {
  return apiClient.get<PagenatedResponse<ExtendedChatMessage>>(`/admin/v1/chats/messages`, {
    params: query,
  });
};

export const updateChatMessageStatus = async (data: { messageId: string; status: string }) => {
  return apiClient.put<PagenatedResponse<ExtendedChatMessage>>(`/admin/v1/chats/messages/${data.messageId}`, {
    status: data.status,
  });
};

export const remindChat = async (data: {
  type: RemindChatType;
  receiverPhoneNumber: string;
  receiverName: string;
  className: string;
  userName?: string;
}) => {
  const { type, receiverPhoneNumber, receiverName, className, userName } = data;
  await apiClient.post('/admin/v1/chats/reminder', {
    type,
    phone: receiverPhoneNumber,
    name: receiverName,
    className,
    userName,
  });
};
