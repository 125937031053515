import { PaymentStatementWithLogs } from '@models/paymentStatement';
import { create } from 'zustand';
import { PaymentStatementModalStore } from './paymentStatementModalStore';

const refundModeModalStore = create<PaymentStatementModalStore>((set, get) => ({
  show: false,
  data: undefined,
  close: () => {
    const isCloseExecute = !!get().show;
    set({ show: false });
    return isCloseExecute;
  },
  open: (data?: PaymentStatementWithLogs) => {
    set({ data: data, show: true });
  },
}));

export default refundModeModalStore;
