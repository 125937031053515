import useChangeScheduleModalStore from '@stores/changeScheduleModalStore';
import { Button, DatePicker, Descriptions, Input, InputRef, Modal, Select, Table, TimePicker } from 'antd';
import { DescriptionsItemProps } from 'antd/es/descriptions/Item';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import moment from 'moment';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import './ChangeScheduleModal.scss';
import { ClassSchedule } from '@models/schedule';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as tutorClassAPI from '@apis/tutor-classes';
import * as paymentStatementAPI from '@apis/payment-statement';
import * as productAPI from '@apis/product';
import {
  ClassChangePurchaseItem,
  CommerceChangePurchasesItem,
  PaymentStatementPurchase,
} from '@models/paymentStatement';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { getPlannedDatesFromPaymentStatement } from '@utils/payment';
import { AxiosError } from 'axios';

interface ChangeClassScheduleTableProps {
  classInvoiceId: string;
  result?: ClassChangePurchaseItem[];
  onChange: (data: ClassChangePurchaseItem[]) => void;
}
interface ChangeCommerceOptionTableProps {
  productId: string;
  purchases: PaymentStatementPurchase[];
  paidAt: Date;
  result?: CommerceChangePurchasesItem[];
  onChange: (data: CommerceChangePurchasesItem[]) => void;
}

interface CommerceSelectMenuProps {
  productOptionStepList: string[];
  result?: CommerceChangePurchasesItem[];
  currentName: string;
  currentIndex: number;
  handleSelectChange: (changeProductOption: string, currentProductOption: string) => void;
}

const CommerceSelectMenu: React.FC<CommerceSelectMenuProps> = (props) => {
  const { productOptionStepList, result, currentName, currentIndex, handleSelectChange } = props;
  const [items, setItems] = useState<string[]>([]);
  const customInputRef = useRef<InputRef>(null);

  useEffect(() => {
    setItems(productOptionStepList.filter((item) => item !== currentName));
  }, [productOptionStepList]);

  const handleAddButton = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    e.preventDefault();
    setItems((prev) => {
      const inputValue = customInputRef.current?.input?.value;
      if (inputValue) {
        if (prev.includes(inputValue)) {
          return prev;
        }
        return [inputValue, ...prev];
      }
      return prev;
    });
  };

  return (
    <Select
      value={result?.[currentIndex]?.newItem || ''}
      options={items.map((item) => ({ label: item, value: item }))}
      popupMatchSelectWidth={false}
      style={{ minWidth: 250 }}
      onChange={(e) => handleSelectChange(e, currentName)}
      dropdownRender={(menu) => (
        <Fragment>
          {menu}
          <div style={{ display: 'flex', gap: '4px' }}>
            <Input placeholder="커스텀 상품 등록" ref={customInputRef} onKeyDown={(e) => e.stopPropagation()} />
            <Button type="text" onClick={handleAddButton}>
              추가
            </Button>
          </div>
        </Fragment>
      )}
    />
  );
};

const ChangeClassScheduleTable: React.FC<ChangeClassScheduleTableProps> = (props) => {
  const { classInvoiceId, result, onChange } = props;

  const scheduleQuery = useQuery({
    queryKey: [`schedule-${classInvoiceId}`],
    queryFn: () => tutorClassAPI.getClassSchedulesByClassInvoiceId(classInvoiceId),
  });

  useEffect(() => {
    const data = scheduleQuery.data?.data;

    if (data && data.length !== 0) {
      onChange(Array.from({ length: data.length }, (_, index) => ({ currentItem: data[index], newItem: '' })));
    }
  }, [scheduleQuery.data?.data]);

  const handleTimeChange = (time: string, index: number, type: 'date' | 'time', dateRegex: RegExp) => {
    if (!time || !result) return;

    if (dateRegex.test(result[index].newItem)) {
      const newResult = [...result];
      newResult[index].newItem = newResult[index].newItem.replace(dateRegex, time);

      onChange(newResult);
    } else {
      const newResult = [...result];

      if (newResult[index].newItem) {
        if (type === 'date') {
          newResult[index].newItem = `${time} ${newResult[index].newItem}`;
        } else {
          newResult[index].newItem = `${newResult[index].newItem} ${time}`;
        }
      } else {
        newResult[index].newItem = moment(newResult[index].currentItem.startDate).format('YYYY-MM-DD HH:mm');
        newResult[index].newItem = newResult[index].newItem.replace(dateRegex, time);
      }

      onChange(newResult);
    }
  };

  const CLASS_SCHEDULE_COLUMNS: ColumnsType<ClassSchedule> = [
    {
      width: 300,
      title: '수업 일시',
      align: 'center',
      dataIndex: 'startDate',
      render: (startDate) => moment(startDate).format('YYYY. MM. DD. HH:mm'),
    },
    {
      title: '변경 수업 일시',
      align: 'center',
      children: [
        {
          width: 200,
          title: '날짜',
          align: 'center',
          dataIndex: 'startDate',
          render: (startDate: string, _, index) => {
            const regex = /\d{4}-\d{1,2}-\d{1,2}/;
            const date = result?.[index]?.newItem.match(regex) || moment(startDate).format().match(regex) || [];

            return (
              <DatePicker
                value={dayjs(date[0])}
                onChange={(e) => handleTimeChange(e?.format('YYYY-MM-DD') || '', index, 'date', regex)}
              />
            );
          },
        },
        {
          width: 150,
          title: '시간',
          align: 'center',
          dataIndex: 'startDate',
          render: (startDate: string, _, index) => {
            const regex = /T?\d{1,2}:\d{1,2}/;
            const date = result?.[index]?.newItem.match(regex) || moment(startDate).format().match(regex) || [];

            return (
              <TimePicker
                value={dayjs(date[0]?.replace('T', '') || '00:00', 'HH:mm')}
                format="HH:mm"
                minuteStep={5}
                onChange={(e) => handleTimeChange(e?.format('HH:mm') || '', index, 'time', regex)}
              />
            );
          },
        },
      ],
    },
  ];

  return (
    <Table
      title={() => '수업 일정 목록'}
      pagination={false}
      bordered
      columns={CLASS_SCHEDULE_COLUMNS}
      dataSource={scheduleQuery.data?.data}
    />
  );
};

const ChangeCommerceOptionTable: React.FC<ChangeCommerceOptionTableProps> = (props) => {
  const { productId, purchases, paidAt, result, onChange } = props;

  const productOptionQuery = useQuery({
    queryKey: [`product-options-${productId}`],
    queryFn: () => productAPI.getProductOptions(productId),
  });

  const productOptionStepList = useMemo(
    () => productOptionQuery.data?.data?.map((item) => item.step.join(' / ')) || [],
    [productOptionQuery.data],
  );
  const includeDatePurchases = useMemo(
    () => purchases.filter((item) => getPlannedDatesFromPaymentStatement('commerce', [item], paidAt).length !== 0),
    [purchases],
  );

  useEffect(() => {
    onChange(
      Array.from({ length: includeDatePurchases.length }, (_, index) => ({
        currentItem: purchases[index],
        newItem: '',
      })),
    );
  }, [purchases]);

  const handleSelectChange = (changeProductOption: string, currentProductOption: string) => {
    if (!result) return;
    const newResult = [...result];

    result.forEach((item, index) => {
      if (item.currentItem.name === currentProductOption) {
        newResult[index].newItem = changeProductOption;
      }
    });

    onChange(newResult);
  };

  const COMMERCE_SCHEDULE_COLUMS: ColumnsType<PaymentStatementPurchase> = [
    {
      width: 300,
      title: '수업 일시',
      align: 'center',
      render: (record) => record.name,
    },
    {
      title: '변경된 수업 일시',
      align: 'center',
      render: (record, _record, index) => (
        <CommerceSelectMenu
          productOptionStepList={productOptionStepList}
          result={result}
          currentName={record.name}
          currentIndex={index}
          handleSelectChange={handleSelectChange}
        />
      ),
    },
  ];

  return (
    <Table
      title={() => '수업 일정 목록'}
      pagination={false}
      bordered
      columns={COMMERCE_SCHEDULE_COLUMS}
      dataSource={includeDatePurchases}
    />
  );
};

const ChangeScheduleModal: React.FC = () => {
  const { data, result, show, close, onChange } = useChangeScheduleModalStore();
  const dialog = useOkCancelDialog();
  const queryClient = useQueryClient();
  const paymentMemoRef = useRef<HTMLTextAreaElement>(null);
  const shippingMemoRef = useRef<HTMLTextAreaElement>(null);

  const updateClassScheduleMutation = useMutation({
    mutationFn: paymentStatementAPI.changeSchedule,
    onSuccess: async () => {
      close();
      dialog.open({
        type: 'ok',
        title: '일정 변경',
        content: '일정 변경에 성공했습니다.',
      });
      await queryClient.invalidateQueries({
        queryKey: [`schedule-${data?.classInvoiceId}`],
      });
      await queryClient.invalidateQueries({
        queryKey: ['admin-search-payment-statement'],
      });
    },
    onError: (error: AxiosError<{ error: string }>) => {
      let errorContent = '일정 변경에 실패했습니다.';

      if (error.response?.data?.error === 'NOT_ENOUGH_INVENTORY') {
        errorContent = '바꾸고자 하는 상품의 재고가 부족합니다.';
      }

      dialog.open({
        type: 'ok',
        confirmColor: '#FF3D8F',
        title: '일정 변경',
        content: errorContent,
      });
    },
  });

  const handleConfirmClick = () => {
    if (data) {
      const requestBody: paymentStatementAPI.ChangeScheduleBody = {
        paymentStatementId: data.paymentStatementId,
        body: {
          saleType: data.type === 'class' ? 'class' : 'commerce',
          changePurchaseItems: [],
          paidAt: data.paidAt,
          memo: {
            payment: data?.memo?.payment === paymentMemoRef.current?.value ? undefined : paymentMemoRef.current?.value,
            shipping:
              data?.memo?.shipping === shippingMemoRef.current?.value ? undefined : shippingMemoRef.current?.value,
          },
        },
      };

      if (result && result.length) {
        if (data!.type === 'class') {
          requestBody.body.changePurchaseItems = (result as ClassChangePurchaseItem[]).filter((item) => item.newItem);
        } else {
          requestBody.body.changePurchaseItems = (result as CommerceChangePurchasesItem[]).filter(
            (item) => item.newItem,
          );
        }
      }

      updateClassScheduleMutation.mutate(requestBody);
    } else {
      dialog.open({
        type: 'ok',
        confirmColor: '#FF3D8F',
        title: '일정 변경',
        content: '일정 변경에 실패했습니다\n새로고침 후 다시 시도해주세요.',
      });
    }
  };

  const PURCHASES_INFO_ITEMS: DescriptionsItemProps[] = [
    { span: 3, label: '상품명', children: data?.productName || '-' },
    { span: 3, label: '구매자', children: data?.buyerName || '-' },
    {
      span: 3,
      label: '옵션',
      children: data?.purchases?.map((item) => <div>{`${item.name} (${item.count})`}</div>) || '-',
    },
    {
      span: 3,
      label: '입력옵션',
      children: <div style={{ whiteSpace: 'pre-wrap' }}>{data?.memo?.qna || '-'}</div>,
    },
    { span: 3, label: '결제금액', children: `${data?.paidAmount.toLocaleString() || 0}원` },
  ];

  return (
    <Modal
      className="schedule-modal"
      open={show}
      width={800}
      onCancel={close}
      okText="저장하기"
      cancelText="닫기"
      onOk={() => handleConfirmClick()}>
      <Descriptions title="구매 정보" bordered items={PURCHASES_INFO_ITEMS} />
      {data && data.classInvoiceId && data.type === 'class' && (
        <ChangeClassScheduleTable
          key={JSON.stringify(data)}
          classInvoiceId={data.classInvoiceId}
          result={result as ClassChangePurchaseItem[]}
          onChange={onChange}
        />
      )}
      {data && data.type === 'commerce' && (
        <ChangeCommerceOptionTable
          key={JSON.stringify(data)}
          productId={data.productId}
          purchases={data.purchases}
          paidAt={data.paidAt}
          result={result as CommerceChangePurchasesItem[]}
          onChange={onChange}
        />
      )}
      <section className="schedule-modal__memo">
        <section className="schedule-modal__memo-item">
          <label htmlFor="payment" className="schedule-modal__label">
            결제 메모 수정
          </label>
          <textarea
            key={data?.memo?.payment}
            id="payment"
            className="schedule-modal__textarea"
            ref={paymentMemoRef}
            defaultValue={data?.memo?.payment}
            rows={2}
            placeholder="(선택) 결제 메모를 수정해 주세요!"
          />
        </section>
        <section className="schedule-modal__memo-item">
          <label htmlFor="shipping" className="schedule-modal__label">
            배송 메모 수정
          </label>
          <textarea
            key={data?.memo?.shipping}
            id="shipping"
            className="schedule-modal__textarea"
            ref={shippingMemoRef}
            defaultValue={data?.memo?.shipping}
            rows={2}
            placeholder="(선택) 배송 메모를 입력해 주세요!"
          />
        </section>
      </section>
    </Modal>
  );
};

export default ChangeScheduleModal;
