import * as api from '@apis/sales-channels';
import { useAuthentication } from '@hooks/authentication';
import { NaverCommerceOrder } from '@models/sales-channels';
import { useNaverCommerceOrdersStore } from '@stores/naverCommerceOrdersStore';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { useMutation, useQuery } from '@tanstack/react-query';
import { localTimeToUtcISOString, utcTimcToLocalISOString } from '@utils/date';
import { Button, Checkbox, DatePicker, DatePickerProps, Input, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

export const NAVER_COMMERCE_REASON_LABELS: { [key: string]: string } = {
  INTENT_CHANGED: '구매 의사 취소',
  COLOR_AND_SIZE: '색상 및 사이즈 변경',
  WRONG_ORDER: '다른 상품 잘못 주문',
  PRODUCT_UNSATISFIED: '서비스 불만족',
  DELAYED_DELIVERY: '배송 지연',
  SOLD_OUT: '상품 품절',
  DROPPED_DELIVERY: '배송 누락',
  NOT_YET_DELIVERY: '미배송',
  BROKEN: '상품 파손',
  INCORRECT_INFO: '상품 정보 상이',
  WRONG_DELIVERY: '오배송',
  WRONG_OPTION: '색상 등 다른 상품 잘못 배송',
  SIMPLE_INTENT_CHANGED: '단순 변심',
  MISTAKE_ORDER: '주문 실수',
  ETC: '기타',
  DELAYED_DELIVERY_BY_PURCHASER: '배송 지연',
  INCORRECT_INFO_BY_PURCHASER: '상품 정보 상이',
  PRODUCT_UNSATISFIED_BY_PURCHASER: '서비스 불만족',
  NOT_YET_DISCUSSION: '상호 협의가 완료되지 않은 주문 건',
  OUT_OF_STOCK: '재고 부족으로 인한 판매 불가',
  SALE_INTENT_CHANGED: '판매 의사 변심으로 인한 거부',
  NOT_YET_PAYMENT: '구매자의 미결제로 인한 거부',
  NOT_YET_RECEIVE: '상품 미수취',
  WRONG_DELAYED_DELIVERY: '오배송 및 지연',
  BROKEN_AND_BAD: '파손 및 불량',
  RECEIVING_DUE_DATE_OVER: '수락 기한 만료',
  RECEIVER_MISMATCHED: '수신인 불일치',
  GIFT_INTENT_CHANGED: '보내기 취소',
  GIFT_REFUSAL: '선물 거절',
  MINOR_RESTRICTED: '상품 수신 불가',
  RECEIVING_BLOCKED: '상품 수신 불가',
  UNDER_QUANTITY: '주문 수량 미달',
  ASYNC_FAIL_PAYMENT: '결제 승인 실패',
  ASYNC_LONG_WAIT_PAYMENT: '결제 승인 실패',
};

export const NaverCommerceOrdersPage: React.FC = () => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [searchKeyword, setSearchKeyword] = useState<string>();
  const searchInputRef = useRef<any>();
  const store = useNaverCommerceOrdersStore();
  const dialog = useOkCancelDialog();
  const selectedIdsCount = (selectedIds || []).filter((id) => !!id).length;

  useAuthentication();

  useEffect(() => {
    if (!searchKeyword && store.searchQuery.keyword) {
      setSearchKeyword(store.searchQuery.keyword);
    }
  }, [store.searchQuery.keyword]);

  const naverCommerceOrdersQuery = useQuery({
    queryKey: [`naver-commerce-orders`, JSON.stringify(store.searchQuery)],
    queryFn: () => api.searchNaverCommerceOrders(store.searchQuery),
  });

  const convertMutation = useMutation({
    mutationFn: api.convertNaverComerceOrdersToNaverOrders,
    onSuccess: async () => {
      const content = `이동했습니다`;
      dialog.open({
        content: content,
        type: 'ok',
      });
    },
    onError: async (e: any) => {
      dialog.open({
        title: '오류',
        content: e?.response?.data?.message,
        type: 'ok',
      });
    },
  });

  const naverCommerceOrders = naverCommerceOrdersQuery.data?.data;

  useEffect(() => {
    if (naverCommerceOrders) {
      store.setNaverCommerceOrdersContainer(naverCommerceOrders);
    }
  }, [naverCommerceOrders]);

  const onChangeStartDate: DatePickerProps['onChange'] = (date) => {
    store.setStartDate(localTimeToUtcISOString(date ? new Date(date.toISOString()) : new Date(), { timeToZero: true }));
  };

  const onChangeEndDate: DatePickerProps['onChange'] = (date) => {
    store.setEndDate(localTimeToUtcISOString(date ? new Date(date.toISOString()) : new Date(), { timeToZero: true }));
  };

  const TABLE_COLUMNS: ColumnsType<NaverCommerceOrder> = [
    {
      fixed: 'left',
      title: () => {
        return (
          <Checkbox
            checked={
              JSON.stringify(selectedIds.sort()) ===
              JSON.stringify(
                (store.searchResultContainer.contents || [])
                  .filter((order) => !!order)
                  .map((order) => order._id)
                  .sort(),
              )
            }
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedIds(
                  (store.searchResultContainer.contents || []).filter((order) => !!order).map((order) => order._id),
                );
              } else {
                setSelectedIds([]);
              }
            }}></Checkbox>
        );
      },
      dataIndex: '_id',
      width: 60,
      render: (_id) => {
        return (
          <Checkbox
            onChange={(e) => {
              setSelectedIds((values) => {
                const checked = e.target.checked;
                const newValues = [...values];
                if (checked) {
                  if (!newValues.includes(_id)) {
                    newValues.push(_id);
                  }
                  return newValues;
                } else {
                  return newValues.filter((value) => value !== _id);
                }
              });
            }}
            checked={selectedIds.includes(_id)}></Checkbox>
        );
      },
    },
    {
      fixed: 'left',
      title: 'ID',
      render: (record: NaverCommerceOrder) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(record?._id);
                toast.dark('ID를 클립보드에 복사했습니다', {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  pauseOnFocusLoss: false,
                  draggable: true,
                  progress: undefined,
                  bodyStyle: {
                    color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                    textAlign: 'center',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                  },
                  theme: 'dark',
                });
              }}>
              ID
            </Button>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(record?.productOrderId);
                toast.dark('상품주문번호를 클립보드에 복사했습니다', {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  pauseOnFocusLoss: false,
                  draggable: true,
                  progress: undefined,
                  bodyStyle: {
                    color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                    textAlign: 'center',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                  },
                  theme: 'dark',
                });
              }}>
              상품주문번호
            </Button>
          </div>
        );
      },
    },
    {
      fixed: 'left',
      title: '구매자',
      render: (record: NaverCommerceOrder) => {
        return `${record?.content?.order.ordererName}\n(${record?.content?.order.ordererTel})`;
      },
    },
    {
      fixed: 'left',
      title: '상태',
      render: (record: NaverCommerceOrder) => {
        return (
          <div>
            <div>{record?.content?.productOrder.productOrderStatus}</div>
            <div>{record?.content?.currentClaim?.return?.claimStatus}</div>
            <div>{record?.content?.currentClaim?.cancel?.claimStatus}</div>
          </div>
        );
      },
    },
    {
      fixed: 'left',
      title: '취소/환불 요청 사유',
      width: 200,
      render: (record: NaverCommerceOrder) => {
        const cancelData = record?.content?.currentClaim?.cancel;
        const returnData = record?.content?.currentClaim?.return;

        let reason = NAVER_COMMERCE_REASON_LABELS[cancelData?.cancelReason || returnData?.returnReason || ''];
        const detailReason = cancelData?.cancelDetailedReason || returnData?.returnDetailedReason;

        if (detailReason) {
          reason = reason + '\n' + detailReason;
        }
        return reason;
      },
    },
    {
      title: '상품명',
      width: 320,
      render: (record: NaverCommerceOrder) => {
        return record?.content?.productOrder.productName;
      },
    },
    {
      title: '옵션',
      width: 320,
      render: (record: NaverCommerceOrder) => {
        return record?.content?.productOrder.productOption;
      },
    },
    {
      title: '수량',
      width: 80,
      render: (record: NaverCommerceOrder) => {
        return record?.content?.productOrder.quantity;
      },
    },
    {
      title: '실결제금액',
      width: 100,
      render: (record: NaverCommerceOrder) => {
        return `${record?.content?.productOrder.totalPaymentAmount.toLocaleString()}원`;
      },
    },
    {
      title: '결제일',
      width: 112,
      render: (record: NaverCommerceOrder) => {
        return moment(record?.content?.order.paymentDate).format('YYYY.MM.DD HH:mm');
      },
    },
    {
      title: '생성',
      dataIndex: 'createdAt',
      render: (createdAt) => {
        return createdAt ? moment(createdAt).format('YYYY.MM.DD HH:mm') : '-';
      },
    },
    {
      title: '업데이트',
      dataIndex: 'updatedAt',
      render: (updatedAt) => {
        return updatedAt ? moment(updatedAt).format('YYYY.MM.DD HH:mm') : '-';
      },
    },
  ];

  return (
    <div className="dashboard" style={{ position: 'relative' }}>
      <div className="page-header">
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <div>네이버 커머스 연동 판매 내역</div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <div>
            <Button
              style={{ borderColor: store.searchQuery.dateType === 'payment.paidAt' ? 'blue' : '#d9d9d9' }}
              onClick={() => {
                store.updateSearchQuery({ dateType: 'payment.paidAt' });
              }}>
              결제일
            </Button>
          </div>
          <div>
            <Button
              style={{ borderColor: store.searchQuery.dateType === 'createdAt' ? 'blue' : '#d9d9d9' }}
              onClick={() => {
                store.updateSearchQuery({ dateType: 'createdAt' });
              }}>
              생성일
            </Button>
          </div>
          <div>
            <Button
              style={{ borderColor: store.searchQuery.dateType === 'updatedAt' ? 'blue' : '#d9d9d9' }}
              onClick={() => {
                store.updateSearchQuery({ dateType: 'updatedAt' });
              }}>
              수정일
            </Button>
          </div>
          <div>
            <Button
              style={{ borderColor: store.searchQuery.dateType === 'plannedDate' ? 'blue' : '#d9d9d9' }}
              onClick={() => {
                store.updateSearchQuery({ dateType: 'plannedDate' });
              }}>
              수업일
            </Button>
          </div>
          <div style={{ marginLeft: '10px' }}>
            <DatePicker
              onChange={onChangeStartDate}
              value={dayjs(utcTimcToLocalISOString(store.searchQuery.startDate as any))}
            />
          </div>
          <div style={{ marginLeft: '8px' }}>
            <DatePicker
              onChange={onChangeEndDate}
              value={dayjs(utcTimcToLocalISOString(store.searchQuery.endDate as any))}
            />
          </div>
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <div className="page-content" style={{ width: '100%', height: 'calc(100vh - 80px)', overflow: 'auto' }}>
          <div style={{ display: 'flex', gap: '12px', width: '100%', height: '52px', alignItems: 'center' }}>
            <Select
              style={{ width: '120px' }}
              value={store.searchQuery.status || 'all_result'}
              options={[
                { value: 'all_result', label: '모든 상태' },
                { value: 'PAYED', label: '결제됨' },
                { value: 'REFUND_REQUESTED', label: '환불요청' },
                { value: 'CANCELED', label: '취소' },
                { value: 'RETURNED', label: '환불' },
                { value: 'DELIVERED', label: '배송 완료' },
                { value: 'PURCHASE_DECIDED', label: '구매 확정' },
              ]}
              onChange={(value) => {
                store.updateSearchQuery({ status: value });
              }}></Select>
            <Select
              placeholder={'검색 타입'}
              style={{ width: '140px' }}
              value={store.searchQuery.keywordType || 'all'}
              options={[
                { value: 'all', label: '전체' },
                { value: 'content.order.ordererTel', label: '구매자 전화번호' },
                { value: 'content.order.ordererName', label: '구매자 이름' },
                { value: 'content.productOrder.productName', label: '상품 이름' },
                { value: 'content.productOrder.productOption', label: '옵션' },
              ]}
              onChange={(value) => {
                store.updateSearchQuery({ keywordType: value });
              }}></Select>
            <Input
              ref={searchInputRef}
              style={{ width: '240px' }}
              value={searchKeyword}
              placeholder={'검색어 입력 후 엔터'}
              onChange={(e) => {
                setSearchKeyword(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.code === 'Enter' && searchKeyword) {
                  store.updateSearchQuery({ keyword: searchKeyword });
                }
              }}></Input>
            <Button
              onClick={() => {
                setSelectedIds([]);
                setSearchKeyword(undefined);
                store.resetSearchQuery();
              }}>
              검색 초기화
            </Button>
            <div>{`결과: ${store.searchResultContainer?.total || 0}개`}</div>
          </div>
          {selectedIdsCount > 0 && (
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center', marginBottom: '12px' }}>
              <div>{`${selectedIdsCount}개 선택`}</div>
              <Button
                disabled={convertMutation.isPending}
                onClick={() => {
                  dialog.open({
                    title: '이동 확인',
                    content: (
                      <div style={{}}>{`선택한 항목 ${selectedIdsCount}개를 네이버 판매내역으로 이동합니다`}</div>
                    ),
                    type: 'ok_cancel',
                    onConfirm: () => {
                      convertMutation.mutate(selectedIds);
                    },
                  });
                }}>
                네이버 판매 내역으로 이동하기
              </Button>
              <Button
                onClick={() => {
                  setSelectedIds([]);
                }}>
                선택 해제
              </Button>
            </div>
          )}
          <Table
            columns={TABLE_COLUMNS}
            scroll={{ x: 'max-content', y: 'calc(100dvh - 320px)' }}
            style={{ whiteSpace: 'pre-wrap' }}
            rowKey={(record) => record?._id}
            dataSource={store.searchResultContainer.contents.filter((product) => product?._id)}
            pagination={{
              current: (store.searchResultContainer.page || 0) + 1,
              pageSize: store.searchResultContainer.size || 20,
              total: store.searchResultContainer.total,
            }}
            loading={naverCommerceOrdersQuery.isLoading}
            onChange={(e) => {
              store.updateSearchQuery({ page: (e.current || 2) - 1, size: e.pageSize || 20 });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default NaverCommerceOrdersPage;
