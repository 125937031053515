import { getUserDeviceId } from '@utils/string';
import axios from 'axios';
import { API_URL } from '../variables';

export const apiClient = axios.create({
  baseURL: `${API_URL}`,
  withCredentials: true,
  timeout: 600000,
});

apiClient.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem('token');
    config.headers.Authorization = `Bearer ${token}`;
    config.headers['x-device-id'] = getUserDeviceId();
    return config;
  },
  (error) => {
    window.history.pushState({}, '', '/login');
    throw error;
  },
);

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      (error?.response?.status === 401 || error?.response?.status === 403) &&
      !window.location.href.includes('/login')
    ) {
      window.location.href = '/login';
    }
    return Promise.reject(error);
  },
);
