import { CustomerRequest, PagenatedResponse, SearchPagenateQuery } from '@models/common';
import { create } from 'zustand';

const PAGE_SIZE = 20;

interface CustomerRequestsContainer {
  contents: CustomerRequest[];
  page: number;
  size: number;
  total: number;
  hasNext: boolean;
}

interface CustomerRequestsStoreProps {
  searchResultContainer: CustomerRequestsContainer;
  searchQuery: SearchPagenateQuery;
  searchKeyword?: string;
  setSearchKeyword: (keyword: string) => void;
  updateSearchQuery: (query: { [key: string]: any }) => void;
  resetSearchQuery: () => void;
  setCustomerRequestsContainer: (result: PagenatedResponse<CustomerRequest>) => void;
}

export const useCustomerRequestsStore = create<CustomerRequestsStoreProps>((set) => ({
  searchResultContainer: {
    contents: [] as CustomerRequest[],
    total: 0,
    page: 0,
    size: 0,
    hasNext: false,
  },
  searchQuery: {
    page: 0,
    size: PAGE_SIZE,
  },
  searchKeyword: undefined,
  setCustomerRequestsContainer: (result) => {
    set((state) => {
      const contents = new Array(result.total || PAGE_SIZE);
      contents.splice(result.page * result.size, result.size, ...(result?.contents || []));
      const newValue = { ...state.searchResultContainer, ...result, contents: contents };
      return { ...state, searchResultContainer: newValue };
    });
  },
  setSearchKeyword: (keyword: string) => {
    set((state) => {
      return {
        ...state,
        searchKeyword: keyword,
        searchResultContainer: { ...state.searchResultContainer, size: 0 },
      };
    });
  },
  updateSearchQuery: (query: { [key: string]: any }) => {
    set((state) => {
      return {
        ...state,
        searchQuery: { ...state.searchQuery, ...query },
        searchResultContainer: { ...state.searchResultContainer, size: 0 },
      };
    });
  },
  resetSearchQuery: () => {
    set({
      searchQuery: {
        page: 0,
        size: PAGE_SIZE,
        type: 'all',
      },
    });
  },
}));
