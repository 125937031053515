import moment from 'moment-timezone';

export interface ConvertDateTimeOptions {
  timeToZero?: boolean;
}

export const localTimeToUtcISOString = (datetime: Date | string | null, options?: ConvertDateTimeOptions) => {
  if (!datetime) {
    return null;
  }

  if (options?.timeToZero) {
    return moment(datetime).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).utc().toISOString();
  }

  return moment(datetime).utc().toISOString();
};

export const utcTimcToLocalISOString = (datetime: Date | string | null, options?: ConvertDateTimeOptions) => {
  if (!datetime) {
    return null;
  }

  if (options?.timeToZero) {
    return moment.utc(datetime).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).local().toISOString();
  }

  return moment.utc(datetime).local().toISOString();
};

export const dateToUtcDate = (date: Date) => {
  return moment.tz(moment(date).format('YYYY-MM-DD HH:mm:ss'), 'Asia/Seoul').utc().toDate();
};

export const isValidDateString = (value: string) => {
  try {
    const date = new Date(value);
    if (!date.getTime()) {
      return false;
    }
    return true;
  } catch {
    /* empty */
  }
  return false;
};
