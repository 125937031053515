import * as api from '@apis/admin-user-contents';
import * as feedApi from '@apis/app-feed';
import { ReactComponent as BookmarkIcon } from '@assets/images/app/bookmark.svg';
import { ReactComponent as DefaultAvatar } from '@assets/images/app/default-avatar-gray.svg';
import { ReactComponent as HeartOffGrayIcon } from '@assets/images/app/heart-gray.svg';
import { ReactComponent as ShareIcon } from '@assets/images/app/share.svg';
import DateTimeButtonPicker from '@components/class-invoice/DateTimeButtonPicker';
import { DividerBold } from '@components/common/Divider';
import { Linkify } from '@components/common/Linkify';
import { SelectUser } from '@components/product/SelectUser';
import UserContentSummary from '@components/user-contents/UserContentSummary';
import { useAuthentication } from '@hooks/authentication';
import { RefundStatus } from '@models/paymentStatement';
import { PRODUCT_ACTIVITY_TYPE_TEXT } from '@models/product';
import { UserContent, UserContentStatus } from '@models/user-contents';
import { useLoungeEditorModalStore } from '@stores/loungeEditorModalStore';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { useUserContentsStore } from '@stores/userContentsStore';
import { useMutation, useQuery } from '@tanstack/react-query';
import { localTimeToUtcISOString, utcTimcToLocalISOString } from '@utils/date';
import { cdnImageUrl } from '@utils/image';
import { WEB_URL } from '@variables';
import { Button, DatePicker, DatePickerProps, Input, Modal, Select, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import moment from 'moment';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

const USER_CONTENTS_STATUS_LABELS: { [key: string]: string } = {
  [UserContentStatus.OPEN]: '게시중',
  [UserContentStatus.PENDING]: '대기',
  [UserContentStatus.SUGGESTION]: '제안',
  [UserContentStatus.HIDDEN]: '숨김',
  [UserContentStatus.RESERVED]: '예약',
  [UserContentStatus.END]: '예약 종료',
  [UserContentStatus.DELIST]: '삭제대기',
};

export const UserContentsPage: React.FC = () => {
  const loungeEditorModal = useLoungeEditorModalStore();
  const [reserveDate, setReserveDate] = useState<{ startDate?: Date; endDate?: Date }>({});
  const [changeReserveContentId, setChangeReserveContentId] = useState<string>();
  const [changePublisherContentId, setChangePublisherContentId] = useState<string>();
  const [selectedUserId, setSelectedUserId] = useState<string>();
  const [searchKeyword, setSearchKeyword] = useState<string>();
  const [showContent, setShowContent] = useState<any>();
  const searchInputRef = useRef<any>();
  const dialog = useOkCancelDialog();

  const store = useUserContentsStore();

  useAuthentication();

  const onChangeStartDate: DatePickerProps['onChange'] = (date) => {
    store.setStartDate(localTimeToUtcISOString(date ? new Date(date.toISOString()) : new Date(), { timeToZero: true }));
  };

  const onChangeEndDate: DatePickerProps['onChange'] = (date) => {
    store.setEndDate(localTimeToUtcISOString(date ? new Date(date.toISOString()) : new Date(), { timeToZero: true }));
  };

  const userContentsQuery = useQuery({
    queryKey: [`userContents`, JSON.stringify(store.searchQuery)],
    queryFn: () => api.searchUserContents(store.searchQuery),
  });

  const loungeCurationCategoriesQuery = useQuery({
    queryKey: [`lounge-curation-categories`],
    queryFn: () => feedApi.getLoungeCurationCategories(),
  });
  const loungeCurationCategories = loungeCurationCategoriesQuery.data?.data?.data || [];
  const loungeCurationCategoriesDict: { [key: string]: string | undefined } = {};
  loungeCurationCategories.forEach((item) => {
    loungeCurationCategoriesDict[item.type] = item.title;
  });

  const updateUserContentStatusMutation = useMutation({
    mutationFn: api.updateUserContentStatus,
    onSuccess: async () => {
      toast.dark('콘텐츠 상태를 변경했습니다', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: false,
        draggable: true,
        progress: undefined,
        bodyStyle: {
          color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
          textAlign: 'center',
          fontFamily: 'Pretendard',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '20px',
        },
        theme: 'dark',
      });
      userContentsQuery.refetch();
    },
  });

  const updateUserContentMutation = useMutation({
    mutationFn: api.updateUserContent,
    onSuccess: async () => {
      setChangePublisherContentId(undefined);
      setSelectedUserId(undefined);
      setChangeReserveContentId(undefined);
      setReserveDate({});
      toast.dark('콘텐츠를 수정했습니다', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: false,
        draggable: true,
        progress: undefined,
        bodyStyle: {
          color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
          textAlign: 'center',
          fontFamily: 'Pretendard',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '20px',
        },
        theme: 'dark',
      });
      userContentsQuery.refetch();
    },
  });

  const userContents = userContentsQuery.data?.data;

  useEffect(() => {
    if (userContents) {
      store.setUserContentsContainer(userContents);
    }
  }, [userContents]);

  useEffect(() => {
    userContentsQuery.refetch();
  }, [loungeEditorModal.show]);

  const TABLE_COLUMNS: ColumnsType<UserContent> = [
    {
      title: 'ID',
      align: 'center',
      render: (record: UserContent) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(record._id);
                toast.dark('ID를 클립보드에 복사했습니다', {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  pauseOnFocusLoss: false,
                  draggable: true,
                  progress: undefined,
                  bodyStyle: {
                    color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                    textAlign: 'center',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                  },
                  theme: 'dark',
                });
              }}>
              콘텐츠 ID
            </Button>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(record.paymentStatementId || '');
                toast.dark('결제내역 ID를 클립보드에 복사했습니다', {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  pauseOnFocusLoss: false,
                  draggable: true,
                  progress: undefined,
                  bodyStyle: {
                    color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                    textAlign: 'center',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                  },
                  theme: 'dark',
                });
              }}>
              결제 ID
            </Button>
          </div>
        );
      },
    },
    {
      title: '구분',
      align: 'center',
      render: (record) => {
        let board = record.board;
        switch (record.board) {
          case 'review':
            board = '후기';
            break;
          case 'curation':
            board = '콘텐츠발견';
            break;
        }

        return (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div>{board}</div>
            {record.bestDate && (
              <div
                style={{
                  width: '52px',
                  height: '24px',
                  lineHeight: '24px',
                  borderRadius: '4px',
                  fontSize: '12px',
                  color: '#ffffff',
                  textAlign: 'center',
                  background: 'orange',
                }}>
                베스트
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: '상태',
      align: 'center',
      render: (record) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', alignItems: 'center' }}>
            <Select
              style={{ width: '100px' }}
              value={record.status}
              options={Object.keys(USER_CONTENTS_STATUS_LABELS).map((key) => {
                return { value: key, label: USER_CONTENTS_STATUS_LABELS[key] };
              })}
              onChange={(value) => {
                updateUserContentStatusMutation.mutate({
                  userContentId: record._id,
                  status: value,
                });
              }}></Select>
            {record.status === UserContentStatus.RESERVED && (
              <button
                className="link-button"
                style={{ border: '1px solid #d9d9d9', borderRadius: '6px', padding: '8px 12px' }}
                onClick={() => {
                  setChangeReserveContentId(record._id);
                }}>
                <div>
                  {record.startDate && <div>{moment(record.startDate).format('YYYY-MM-DD HH:mm')}</div>}
                  <div>~</div>
                  {record.endDate && <div>{moment(record.endDate).format('YYYY-MM-DD HH:mm')}</div>}
                </div>
              </button>
            )}
            {record.status === UserContentStatus.OPEN && record.endDate && (
              <button
                className="link-button"
                style={{ border: '1px solid #d9d9d9', borderRadius: '6px', padding: '8px 12px' }}
                onClick={() => {
                  setChangeReserveContentId(record._id);
                }}>
                <div>
                  <div>~</div>
                  {record.endDate && <div>{moment(record.endDate).format('YYYY-MM-DD HH:mm')}</div>}
                </div>
              </button>
            )}
          </div>
        );
      },
    },
    {
      title: '작성자',
      align: 'center',
      render: (record) => {
        const publisher = record.publisher;
        const editor = record.editor;
        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', alignItems: 'center' }}>
            <div>{`${publisher?.profile?.name}\n(${publisher?.profile?.phone?.substring(
              publisher?.profile?.phone?.length - 4,
            )})`}</div>
            {editor?.profile?.name && (
              <Tooltip
                title={`${editor?.profile?.name}\n(${editor?.profile?.phone?.substring(
                  editor?.profile?.phone?.length - 4,
                )})`}></Tooltip>
            )}
            <Button
              onClick={() => {
                setChangePublisherContentId(record._id);
              }}>
              변경
            </Button>
          </div>
        );
      },
    },
    {
      title: '내용',
      render: (record) => {
        return (
          <div style={{ width: '480px' }}>
            <UserContentSummary
              item={record}
              loungeCurationCategoriesDict={loungeCurationCategoriesDict}></UserContentSummary>
          </div>
        );
      },
    },
    {
      title: '동작',
      align: 'center',
      render: (record: UserContent) => {
        const userContent = record;
        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Button
              onClick={() => {
                setShowContent(
                  <div
                    className="ProseMirror"
                    style={{ display: 'flex', flexDirection: 'column', gap: '16px', padding: '16px 0' }}>
                    <div
                      style={{
                        padding: '16px 20px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '12px',
                        borderTop: '1px solid #EFF1F4',
                        borderBottom: '1px solid #EFF1F4',
                      }}>
                      <div style={{ flexShrink: 0 }}>
                        {userContent?.publisher?.imageUrl ? (
                          <img
                            src={cdnImageUrl(userContent?.publisher?.imageUrl)}
                            alt="avatar"
                            style={{ objectFit: 'cover', width: '40px', height: '40px', borderRadius: '20px' }}
                          />
                        ) : (
                          <DefaultAvatar />
                        )}
                      </div>
                      <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <div
                          style={{
                            color: 'var(--Neutral-90, #424242)',
                            fontFamily: 'Pretendard',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            lineHeight: '20px',
                          }}>
                          {userContent?.publisher?.nickname || '익명'}
                        </div>
                        <div
                          style={{
                            color: 'var(--Neutral-60, #9B9DA0)',
                            fontFamily: 'Pretendard',
                            fontSize: '13px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            lineHeight: '18px',
                          }}>
                          <div>
                            {userContent?.publisher?.message ||
                              `${userContent?.publisher?.location} · ${userContent?.publisher?.age}`}
                          </div>
                          <div>{moment(userContent?.updatedAt || userContent?.createdAt).fromNow()}</div>
                        </div>
                      </div>
                    </div>
                    <div style={{ padding: '0 20px' }}>
                      <Linkify>{userContent?.text}</Linkify>
                    </div>
                    {userContent?.product && (
                      <button
                        className="link-button"
                        style={{
                          height: '52px',
                          display: 'flex',
                          gap: '12px',
                          margin: '0 20px',
                          border: '1px solid #EFF1F4',
                          textAlign: 'left',
                          alignItems: 'center',
                        }}
                        onClick={() => {
                          window.open(`${WEB_URL}/product/${userContent?.product?._id}`, '_blank');
                        }}>
                        <img
                          src={cdnImageUrl(userContent?.product?.thumbnailImage)}
                          alt=""
                          style={{
                            height: '52px',
                            width: '52px',
                            objectFit: 'cover',
                            display: 'bolck',
                          }}
                        />
                        <div
                          style={{
                            width: 'calc(100% - 108px)',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                          }}>
                          <div
                            style={{
                              wordBreak: 'break-word',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              color: 'var(--Neutral-90, #424242)',
                              fontFamily: 'Pretendard',
                              fontSize: '13px',
                              fontStyle: 'normal',
                              fontWeight: 400,
                            }}>
                            {userContent?.product?.title}
                          </div>
                          {(userContent?.product?.activityType || userContent?.product?.favoritedCount) && (
                            <div
                              style={{
                                display: 'flex',
                                gap: '6px',
                                color: 'var(--Neutral-60, #9B9DA0)',
                                textAlign: 'right',
                                fontFamily: 'Pretendard',
                                fontSize: '12px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                marginTop: '4px',
                              }}>
                              {userContent?.product?.activityType && (
                                <div>{PRODUCT_ACTIVITY_TYPE_TEXT[userContent?.product?.activityType]?.title}</div>
                              )}
                              {userContent?.product?.activityType && (
                                <div style={{ width: '1px', height: '100%' }}></div>
                              )}
                              {userContent?.product?.favoritedCount && userContent?.product?.favoritedCount > 0 && (
                                <div>{`찜 ${userContent?.product?.favoritedCount}개`}</div>
                              )}
                            </div>
                          )}
                        </div>
                      </button>
                    )}
                    <div
                      style={{ padding: '0 20px', display: 'flex', flexDirection: 'column', gap: '4px', minWidth: 0 }}>
                      {userContent?.images &&
                        userContent?.images.length > 0 &&
                        userContent?.images.map((item, index) => {
                          return (
                            <img
                              key={`image-${index}`}
                              src={cdnImageUrl(item)}
                              alt={`upload-${index}`}
                              style={{ display: 'block' }}
                            />
                          );
                        })}
                    </div>
                    <div style={{ display: 'flex', gap: '16px', alignItems: 'center', padding: '0 20px' }}>
                      <HeartOffGrayIcon />
                      <ShareIcon />
                      <BookmarkIcon />
                    </div>
                    <DividerBold />
                  </div>,
                );
              }}>
              보기
            </Button>
            <Button
              onClick={() => {
                loungeEditorModal.open({
                  userContentId: record._id,
                  paymentStatement: record?.paymentStatement,
                  board: record.board,
                });
              }}>
              편집
            </Button>
          </div>
        );
      },
    },
    {
      title: '리뷰 정보',
      width: 240,
      render: (record: UserContent) => {
        if (record.board !== 'review') {
          return '-';
        }

        const paymentStatement = record?.paymentStatement;
        const total =
          (record.rates?.rate1 || 0) +
          (record.rates?.rate2 || 0) +
          (record.rates?.rate3 || 0) +
          (record.rates?.rate4 || 0);
        const latestReviewAvg = Number((total / 4).toFixed(2));

        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Tooltip
              overlay={
                <table>
                  <tr>
                    <td>친화력</td>
                    <td>전문성</td>
                    <td>준비성</td>
                    <td>시간준수</td>
                  </tr>
                  <tr>
                    <td>{`${record.rates?.rate1}`}</td>
                    <td>{`${record.rates?.rate2}`}</td>
                    <td>{`${record.rates?.rate3}`}</td>
                    <td>{`${record.rates?.rate4}`}</td>
                  </tr>
                </table>
              }>
              <div>
                <span>별점: </span>
                <span>{latestReviewAvg}</span>
              </div>
            </Tooltip>
            {paymentStatement && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', gap: '4px' }}>
                  <div>결제일시:</div>
                  <div>
                    {paymentStatement.createdAt && moment(paymentStatement.createdAt).format('YYYY.MM.DD HH:mm')}
                  </div>
                </div>
                {(paymentStatement.plannedDates || []).length > 0 && (
                  <div style={{ display: 'flex', gap: '4px' }}>
                    <div>수업일시:</div>
                    <div>
                      {(paymentStatement.plannedDates || []).map((date) => {
                        return <div>{moment(date).format('YYYY.MM.DD HH:mm')}</div>;
                      })}
                    </div>
                  </div>
                )}
                <div style={{ display: 'flex', gap: '4px' }}>
                  <div>상태:</div>
                  <div>
                    {(() => {
                      const payStatus = (paymentStatement.statuses.payment || '').toLowerCase();
                      let status = '';
                      if (payStatus === 'done') {
                        status = '결제완료';
                      } else if (payStatus === 'ready') {
                        status = '결제대기';
                      } else if (payStatus === 'none') {
                        status = '';
                      } else {
                        status = payStatus;
                      }
                      return status;
                    })()}
                  </div>
                  <div style={{ fontWeight: 'bold' }}>
                    {(() => {
                      const refundStatus = paymentStatement.statuses?.refund;
                      let status = '';
                      if (refundStatus === RefundStatus.ALL) {
                        status = '전체환불';
                      } else if (refundStatus === RefundStatus.PARTIAL) {
                        status = '부분환불';
                      } else if (
                        refundStatus === RefundStatus.REFUNDREGISTER ||
                        refundStatus === RefundStatus.ACCEPTED
                      ) {
                        status = '환불접수';
                      } else if (refundStatus === RefundStatus.RESERVED) {
                        status = '환불예약';
                      }
                      return status;
                    })()}
                  </div>
                </div>
              </div>
            )}
            <Button
              style={{ color: record.bestDate ? 'red' : 'rgba(0, 0, 0, 0.88)' }}
              onClick={() => {
                if (record.bestDate) {
                  dialog.open({
                    title: '베스트 해제 확인',
                    content: `${record.publisher?.profile?.name}님의 리뷰를 베스트에서 제외합니다.\n베스트에서 제외되더라도 포인트가 회수되지 않습니다.`,
                    type: 'ok_cancel',
                    onConfirm: () => {
                      const formData: FormData = new FormData();
                      formData.append('json', JSON.stringify({ bestDate: record.bestDate ? null : new Date() }));
                      formData.append('userContentId', record._id);
                      updateUserContentMutation.mutate(formData);
                    },
                  });
                } else {
                  if (record.bestPointGrantedAt) {
                    dialog.open({
                      title: '베스트 재선정 확인',
                      content: `${record.publisher?.profile?.name}님의 리뷰를 베스트로 다시 선정합니다.\n포인트 지급 이력이 있어 포인트가 다시 지급되지는 않습니다.`,
                      type: 'ok_cancel',
                      onConfirm: () => {
                        const formData: FormData = new FormData();
                        formData.append('json', JSON.stringify({ bestDate: record.bestDate ? null : new Date() }));
                        formData.append('userContentId', record._id);
                        updateUserContentMutation.mutate(formData);
                      },
                    });
                  } else {
                    dialog.open({
                      title: '베스트 선정 확인',
                      content: `${record.publisher?.profile?.name}님의 리뷰를 베스트로 선정합니다.\n${Math.floor(
                        (paymentStatement?.payment.amount || 0) * 0.05,
                      ).toLocaleString()} 포인트가 지급됩니다.`,
                      type: 'ok_cancel',
                      onConfirm: () => {
                        const formData: FormData = new FormData();
                        formData.append('json', JSON.stringify({ bestDate: record.bestDate ? null : new Date() }));
                        formData.append('userContentId', record._id);
                        updateUserContentMutation.mutate(formData);
                      },
                    });
                  }
                }
              }}>
              {record.bestDate ? '베스트 해제' : '베스트 선정'}
            </Button>
          </div>
        );
      },
    },
    {
      title: '시간',
      width: 180,
      align: 'center',
      render: (record) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ display: 'flex', gap: '4px' }}>
              <div>생성</div>
              <div>{moment(record.createdAt).format('YYYY.MM.DD HH:mm')}</div>
            </div>
            <div style={{ display: 'flex', gap: '4px' }}>
              <div>수정</div>
              <div>{moment(record.updatedAt).format('YYYY.MM.DD HH:mm')}</div>
            </div>
            <div style={{ display: 'flex', gap: '4px' }}>
              <div>게시</div>
              <div>{record.publishedAt ? moment(record.publishedAt).format('YYYY.MM.DD HH:mm') : '-'}</div>
            </div>
            {record.bestDate && (
              <div style={{ display: 'flex', gap: '4px' }}>
                <div>B선정</div>
                <div>{record.bestDate ? moment(record.bestDate).format('YYYY.MM.DD HH:mm') : '-'}</div>
              </div>
            )}
            {record.bestPointGrantedAt && (
              <div style={{ display: 'flex', gap: '4px' }}>
                <div>B지급</div>
                <div>
                  {record.bestPointGrantedAt ? moment(record.bestPointGrantedAt).format('YYYY.MM.DD HH:mm') : '-'}
                </div>
              </div>
            )}
          </div>
        );
      },
    },
  ];
  return (
    <div className="dashboard" style={{ position: 'relative' }}>
      <Modal
        width={600}
        open={!!changePublisherContentId}
        onCancel={() => {
          setChangePublisherContentId(undefined);
        }}
        footer={
          <div>
            <Button
              onClick={() => {
                setChangePublisherContentId(undefined);
              }}>
              취소
            </Button>
            <Button
              style={{ color: 'red' }}
              onClick={() => {
                if (!selectedUserId) {
                  dialog.open({ type: 'ok', title: '알림', content: '유저를 선택해주세요' });
                  return;
                }

                const formData: FormData = new FormData();
                formData.append('json', JSON.stringify({ publisherId: selectedUserId }));
                formData.append('userContentId', changePublisherContentId!);
                updateUserContentMutation.mutate(formData);
              }}>
              변경하기
            </Button>
          </div>
        }>
        <div>
          <div style={{ height: '44px' }}>게시자 변경</div>
          <SelectUser
            style={{ width: '528px' }}
            selectedUserId={selectedUserId}
            onSelect={(user) => {
              setSelectedUserId(user._id);
            }}></SelectUser>
        </div>
      </Modal>
      <Modal
        open={!!changeReserveContentId}
        onCancel={() => {
          setChangeReserveContentId(undefined);
        }}
        footer={
          <div>
            <Button
              onClick={() => {
                setChangeReserveContentId(undefined);
              }}>
              취소
            </Button>
            <Button
              style={{ color: 'red' }}
              onClick={() => {
                const formData: FormData = new FormData();
                formData.append('json', JSON.stringify(reserveDate));
                if (changeReserveContentId) {
                  formData.append('userContentId', changeReserveContentId);
                }

                updateUserContentMutation.mutate(formData);
              }}>
              변경하기
            </Button>
          </div>
        }>
        <div>
          <div style={{ height: '44px' }}>콘텐츠 예약 변경</div>
          <div>
            <div>시작</div>
            <DateTimeButtonPicker
              minDate={moment().format('YYYY-MM-DD')}
              value={reserveDate.startDate}
              onChange={(value) => {
                setReserveDate((prev) => {
                  return { ...prev, startDate: value };
                });
              }}
            />
          </div>
          <div>
            <div style={{ marginTop: '4px' }}>종료</div>
            <DateTimeButtonPicker
              minDate={moment().format('YYYY-MM-DD')}
              value={reserveDate.endDate}
              onChange={(value) => {
                setReserveDate((prev) => {
                  return { ...prev, endDate: value };
                });
              }}
            />
          </div>
        </div>
      </Modal>
      <Modal
        open={!!showContent}
        onOk={() => {
          setShowContent(undefined);
        }}
        onCancel={() => {
          setShowContent(undefined);
        }}>
        <div style={{ height: '36px' }}></div>
        <OverlayScrollbarsComponent
          defer
          options={{
            scrollbars: { autoHide: 'scroll' },
            overflow: {
              x: 'hidden',
            },
          }}
          style={{
            backgroundColor: '#ffffff',
            marginTop: 0,
            position: 'relative',
            overflow: 'auto',
            width: '100%',
            height: 'calc(100dvh - 280px)',
          }}>
          {showContent}
        </OverlayScrollbarsComponent>
      </Modal>
      <div className="page-header">
        <div>라운지 콘텐츠 관리</div>
        <div style={{ display: 'flex', gap: '8px' }}>
          <div>
            <DatePicker
              onChange={onChangeStartDate}
              value={dayjs(utcTimcToLocalISOString(store.searchQuery.startDate as any))}
            />
          </div>
          <div>
            <DatePicker
              onChange={onChangeEndDate}
              value={dayjs(utcTimcToLocalISOString(store.searchQuery.endDate as any))}
            />
          </div>
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <div className="page-content" style={{ width: '100%', height: 'calc(100vh - 80px)', overflow: 'auto' }}>
          <div
            style={{
              display: 'flex',
              gap: '12px',
              width: '100%',
              height: '52px',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
              <Select
                value={store.searchQuery.board || 'all'}
                style={{ width: 180 }}
                onChange={(value) => {
                  store.updateSearchQuery({ board: value });
                }}
                options={[
                  { value: 'all', label: '구분 전체' },
                  { value: 'curation', label: '콘텐츠' },
                  { value: 'review', label: '리뷰' },
                  { value: 'review_best', label: '베스트 리뷰' },
                ]}
              />
              <Select
                value={store.searchQuery.sort || 'createdAt'}
                style={{ width: 180 }}
                onChange={(value) => {
                  store.updateSearchQuery({ sort: value });
                }}
                options={[
                  { value: 'createdAt', label: '최근 작성 순' },
                  { value: 'publishedAt', label: '최근 게시 순' },
                  { value: 'bestDate', label: '베스트 선정 순' },
                ]}
              />
              <Input
                ref={searchInputRef}
                style={{ width: '240px' }}
                value={searchKeyword}
                placeholder={'검색어 입력 후 엔터'}
                onChange={(e) => {
                  setSearchKeyword(e.target.value);
                }}
                onKeyUp={(e) => {
                  if (e.code === 'Enter' && searchKeyword) {
                    store.updateSearchQuery({ keyword: searchKeyword });
                  }
                }}></Input>
              <Button
                onClick={() => {
                  setSearchKeyword(undefined);
                  store.resetSearchQuery();
                }}>
                검색 초기화
              </Button>
            </div>

            <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
              <Button
                style={{
                  width: 120,
                  marginLeft: '8px',
                }}
                onClick={() => {
                  loungeEditorModal.open(undefined);
                }}>
                {'새글 작성하기'}
              </Button>
              <Button
                onClick={async () => {
                  const fileName = `igogo-user-contents-${moment().format('YYYYMMDD-HHmmss')}.xlsx`;
                  const blob = await api.getUserContentsExcelFile(store.searchQuery);
                  if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
                    // IE variant
                    (window.navigator as any).msSaveOrOpenBlob(new Blob(blob.data), fileName);
                  } else {
                    const url = window.URL.createObjectURL(blob.data);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode && link.parentNode.removeChild(link);
                  }
                }}>
                리뷰 엑셀 파일 다운로드
              </Button>
            </div>
          </div>
          <Table
            columns={TABLE_COLUMNS}
            scroll={{ x: 'max-content', y: 'calc(100dvh - 280px)' }}
            style={{ whiteSpace: 'pre-wrap' }}
            rowKey={(record) => record?._id}
            dataSource={store.searchResultContainer.contents.filter((userContent) => userContent?._id)}
            pagination={{
              current: (store.searchResultContainer.page || 0) + 1,
              pageSize: store.searchResultContainer.size || 20,
              total: store.searchResultContainer.total,
            }}
            loading={userContentsQuery.isLoading}
            onChange={(e) => {
              store.updateSearchQuery({ page: (e.current || 2) - 1, size: e.pageSize || 20 });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default UserContentsPage;
