import HeartGrayIcon from '@assets/images/app/btn-heart-gray.png';
import HeartRedIcon from '@assets/images/app/btn-heart-red.png';
import LiveIcon from '@assets/images/app/icon-live-16@3x.png';
import LocationIcon from '@assets/images/app/icon-location-16@3x.png';
import { ProductActivityType, ProductModel, ProductStatus } from '@models/product';
import { getAddressText, getBadges } from '@utils/dataTransform';
import moment from 'moment';
import React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';

interface ProductItemProps {
  item?: ProductModel;
  isFavorited?: boolean;
  style?: any;
  width?: number;
  onClick?: (event: any) => void;
}

export const ProductItem: React.FC<ProductItemProps> = (props) => {
  const _renderItemImage = (imageUrl: string) => {
    const isFavorited = props.isFavorited;

    let fixImageUrl = imageUrl;
    if (fixImageUrl != null && fixImageUrl != '') {
      const str1 = fixImageUrl.substr(0, fixImageUrl.indexOf('/upload/') + 7);
      const str2 = fixImageUrl.substr(fixImageUrl.indexOf('/upload/') + 7, fixImageUrl.length);
      fixImageUrl = str1 + '/w_500' + str2;
    }

    const addressText = getAddressText({
      activityType: props.item?.activityType,
      availableArea: props.item?.location,
    });

    return (
      <div style={{ width: '100%', borderRadius: '8px', overflow: 'hidden' }}>
        <img
          style={{
            width: '100%',
            borderRadius: '8px',
            objectFit: 'cover',
            aspectRatio: 1,
          }}
          src={fixImageUrl}></img>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0,0,0,0.6)',
            paddingLeft: '4px',
            paddingRight: '6px',
            height: '20px',
            borderRadius: '4px',
            position: 'absolute',
            top: '8px',
            left: '8px',
          }}>
          <img
            src={props.item?.activityType === ProductActivityType.ONLINE ? LiveIcon : LocationIcon}
            style={{ alignSelf: 'center', width: '14px', height: '14px', marginRight: '2px' }}></img>
          <div
            style={{
              alignSelf: 'center',
              fontSize: '11px',
              color: '#ffffff',
              fontWeight: 500,
              lineHeight: '10px',
            }}>
            {addressText == '전체' ? '전체' : addressText}
          </div>
        </div>
        {isFavorited && (
          <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
            <img
              style={{
                width: '24px',
                height: '24px',
                border: '1px solid #d8d8d8',
                borderRadius: '50%',
              }}
              src={isFavorited ? HeartRedIcon : HeartGrayIcon}
            />
          </div>
        )}
      </div>
    );
  };

  const item = props.item;
  const mainImageUrl = item?.thumbnailImage;
  const badges = props && item && item?.badges && item?.badges.length > 0 ? item?.badges : [];

  let isNew = false;
  const firstApprovedAt = props && item && item?.approvedAt ? item?.approvedAt : new Date(1990, 0, 1);
  const monthCheck = moment(new Date()).diff(moment(firstApprovedAt), 'day');
  if (monthCheck <= 30) {
    isNew = true;
  }

  const activeBadgeList = getBadges({
    badges: badges,
    isNew,
    isClosed: item?.status === ProductStatus.CLOSE,
    applyGroupDiscount: item?.applyGroupDiscount,
  });

  return (
    <div id="class-item" style={{ ...props.style, position: 'relative' }} onClick={props.onClick}>
      {mainImageUrl && _renderItemImage(mainImageUrl as string)}
      <div style={{ marginTop: '10px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {props.item?.activityType && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}>
              <div
                style={{
                  fontSize: '12px',
                  textAlign: 'center',
                  color: '#808387',
                  fontWeight: 'bold',
                  marginRight: '4px',
                  lineHeight: '16px',
                }}>
                {props.item.activityType === ProductActivityType.ONLINE
                  ? '비대면'
                  : props.item.activityType === ProductActivityType.STUDIO
                    ? '스튜디오'
                    : '가정방문'}
              </div>
              <div style={{ width: '1px', height: '8px', backgroundColor: '#d8d8d8', marginRight: 4 }}></div>
              <div
                style={{
                  fontSize: '12px',
                  textAlign: 'left',
                  color: '#808387',
                  fontWeight: 'normal',
                  lineHeight: '16px',
                }}>
                {props.item.sellerName}
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          letterSpacing: -0.2,
          marginTop: '6px',
          flex: 1,
          fontSize: '14px',
          color: '#424242',
          lineHeight: '18px',
          maxHeight: '36px',
          overflow: 'hidden',
        }}>
        <LinesEllipsis text={props.item?.title} maxLine={2} ellipsis="..." trimRight></LinesEllipsis>
      </div>
      <div style={{ alignItems: 'flex-start', alignContent: 'flex-start', flexDirection: 'column', marginTop: 6 + 2 }}>
        {
          <div
            style={{
              fontWeight: 'bold',
              fontSize: '14px',
              color: '#242424',
              lineHeight: '18px',
            }}>
            {(props.item?.fee || 0).toLocaleString()}원{' '}
            {props.item && props.item.regularTime && props.item.regularTime > 0 ? ` / ${props.item.regularTime}회` : ''}
          </div>
        }
      </div>

      {activeBadgeList.length > 0 && (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '8px' }}>
          {activeBadgeList.map((item, index) => {
            return (
              <div
                key={`badge-${index}`}
                style={{
                  display: 'flex',
                  marginRight: '4px',
                  paddingLeft: '4px',
                  paddingRight: '4px',
                  height: '18px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '4px',
                  backgroundColor: item.backgroundColor,
                }}>
                <div style={{ fontSize: '11px', color: item.color, fontWeight: 500 }}>{item.value}</div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ProductItem;
