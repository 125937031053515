import { PagenatedResponse } from '@models/common';
import { ClassInvoiceCountByState, GetClassInvoiceQuery, LegacyClassInvoice } from '@models/invoice';
import { CommercePurchaseModel, PaymentStatement } from '@models/paymentStatement';
import { ProductModel } from '@models/product';
import { apiClient } from '.';
import { User } from '../models/auth';

export const getUser = async (userId: string) => {
  return apiClient.get<User>(`/app/v1/user/${userId}`);
};

export const getFavorite = async () => {
  return apiClient.get<ProductModel[]>(`/app/v1/user/me/favorite`);
};

export const getPurchases = async (page: number, size: number) => {
  return apiClient.get<PagenatedResponse<PaymentStatement>>(`/app/v1/user/me/purchases`, { params: { page, size } });
};

export const getCommerceProductPurchases = async () => {
  return apiClient.get<CommercePurchaseModel[]>(`/app/v1/user/me/purchases/commerce-product`);
};

export const getMyClassInvoices = async (query: GetClassInvoiceQuery) => {
  return apiClient.get<{
    result: LegacyClassInvoice[];
    count: { [key: string]: ClassInvoiceCountByState };
  }>(`/app/v1/user/me/class-invoices`, {
    params: query,
  });
};

export const useCoupon = async (couponCode: string) => {
  return apiClient.post<{ point: number; eventName: string }>(`/app/v1/user/me/coupon`, { couponCode });
};

export const createCustomerRequest = async (body: any) => {
  return apiClient.post(`/app/v1/customer-request`, body);
};
