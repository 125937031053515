import * as api from '@apis/chats';
import ChatPreview from '@components/chat/ChatPreview';
import { useAuthentication } from '@hooks/authentication';
import { ExtendedChatMessage } from '@models/chat';
import { useChatMessagesStore } from '@stores/chatMessagesStore';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Input, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

export const ChatMessagesPage: React.FC = () => {
  const [selectedMessage, setSelectedMessage] = useState<ExtendedChatMessage>();
  const [searchKeyword, setSearchKeyword] = useState<string>();
  const searchInputRef = useRef<any>();

  const store = useChatMessagesStore();

  useAuthentication();

  const chatMessagesQuery = useQuery({
    queryKey: [`feed-chat-messages`, JSON.stringify(store.searchQuery)],
    queryFn: () => api.searchChatMessages(store.searchQuery),
  });

  const updateChatMessageStatusMutation = useMutation({
    mutationFn: api.updateChatMessageStatus,
    onSuccess: async () => {
      toast.dark('메시지 상태가 변경되었습니다', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: false,
        draggable: true,
        progress: undefined,
        bodyStyle: {
          color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
          textAlign: 'center',
          fontFamily: 'Pretendard',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '20px',
        },
        theme: 'dark',
      });
      chatMessagesQuery.refetch();
    },
  });
  const chatMessages = chatMessagesQuery.data?.data;

  useEffect(() => {
    if (chatMessages) {
      store.setChatMessagesContainer(chatMessages);
    }
  }, [chatMessages]);

  const TABLE_COLUMNS: ColumnsType<ExtendedChatMessage> = [
    {
      title: 'ID',
      dataIndex: '_id',
      render: (_id, record) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(_id);
                toast.dark('메시지 ID를 클립보드에 복사했습니다', {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  pauseOnFocusLoss: false,
                  draggable: true,
                  progress: undefined,
                  bodyStyle: {
                    color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                    textAlign: 'center',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                  },
                  theme: 'dark',
                });
              }}>
              메시지 ID
            </Button>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(record.classId);
                toast.dark('클래스 ID를 클립보드에 복사했습니다', {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  pauseOnFocusLoss: false,
                  draggable: true,
                  progress: undefined,
                  bodyStyle: {
                    color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                    textAlign: 'center',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                  },
                  theme: 'dark',
                });
              }}>
              클래스 ID
            </Button>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(record.tutorId);
                toast.dark('튜터 ID를 클립보드에 복사했습니다', {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  pauseOnFocusLoss: false,
                  draggable: true,
                  progress: undefined,
                  bodyStyle: {
                    color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                    textAlign: 'center',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                  },
                  theme: 'dark',
                });
              }}>
              튜터 ID
            </Button>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(record.parentId);
                toast.dark('부모 ID를 클립보드에 복사했습니다', {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  pauseOnFocusLoss: false,
                  draggable: true,
                  progress: undefined,
                  bodyStyle: {
                    color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                    textAlign: 'center',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                  },
                  theme: 'dark',
                });
              }}>
              부모 ID
            </Button>
          </div>
        );
      },
    },
    {
      title: '클래스',
      width: 200,
      render: (record: ExtendedChatMessage) => {
        return `[${record.tutor?.profile?.nickname}] ${record.class.title}`;
      },
    },
    {
      title: '보낸사람',
      render: (record: ExtendedChatMessage) => {
        let sender = `${record.tutor?.profile?.nickname}|튜터`;
        if (record.chatId === record.parentId) {
          sender = `${record.parent?.profile?.name}(${record.parent?.profile?.phone?.substring(
            record.parent?.profile?.phone?.length - 4,
          )})|부모\n(${(record.parent?.profile?.children || [])
            .map((item) => {
              return item.name;
            })
            .join(', ')})`;
        }

        return sender;
      },
    },
    {
      title: '텍스트',
      width: 428,
      dataIndex: 'text',
      render: (text) => {
        return text;
      },
    },
    {
      title: '상태',
      width: 100,
      render: (record) => {
        return (
          <Select
            placeholder="기본"
            style={{ width: '80px' }}
            value={record.status}
            options={[
              {
                value: 'default',
                label: '기본',
              },
              {
                value: 'block',
                label: '가림',
              },
              {
                value: 'delete',
                label: '삭제',
              },
            ]}
            onChange={(value) => {
              updateChatMessageStatusMutation.mutate({ messageId: record._id, status: value });
            }}></Select>
        );
      },
    },
    {
      title: '생성',
      dataIndex: 'createdAt',
      render: (createdAt) => {
        if (!createdAt) {
          return '-';
        }
        return moment(createdAt).format('YYYY.MM.DD HH:mm');
      },
    },
    {
      title: '채팅 보기',
      render: (record) => {
        return (
          <Button
            onClick={() => {
              setSelectedMessage(record);
            }}>
            보기
          </Button>
        );
      },
    },
  ];
  return (
    <div className="dashboard" style={{ position: 'relative' }}>
      <div className="page-header">
        <div>채팅 메시지 모니터링</div>
      </div>
      <div style={{ display: 'flex' }}>
        <div className="page-content" style={{ width: '100%', height: 'calc(100vh - 80px)', overflow: 'auto' }}>
          <div style={{ display: 'flex', gap: '12px', width: '100%', height: '52px', alignItems: 'center' }}>
            <Select
              defaultValue="all"
              style={{ width: 160 }}
              onChange={(value) => {
                store.updateSearchQuery({ type: value });
              }}
              options={[
                { value: 'all', label: '전체' },
                { value: 'violation', label: '정책 위반 메시지' },
              ]}
            />
            <Input
              ref={searchInputRef}
              style={{ width: '240px' }}
              value={searchKeyword}
              placeholder={'검색어 입력 후 엔터'}
              onChange={(e) => {
                setSearchKeyword(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.code === 'Enter' && searchKeyword) {
                  store.updateSearchQuery({ keyword: searchKeyword });
                }
              }}></Input>

            <div style={{ display: 'flex', marginLeft: 'auto', gap: '12px', alignItems: 'center' }}>
              <Button
                onClick={() => {
                  setSearchKeyword(undefined);
                  store.resetSearchQuery();
                }}>
                검색 초기화
              </Button>
            </div>
          </div>
          <Table
            columns={TABLE_COLUMNS}
            scroll={{ x: 'max-content', y: 'calc(100dvh - 280px)' }}
            style={{ whiteSpace: 'pre-wrap' }}
            rowKey={(record) => record?._id}
            dataSource={store.searchResultContainer.contents.filter((product) => product?._id)}
            pagination={{
              current: (store.searchResultContainer.page || 0) + 1,
              pageSize: store.searchResultContainer.size || 20,
              total: store.searchResultContainer.total,
            }}
            loading={chatMessagesQuery.isLoading}
            onChange={(e) => {
              store.updateSearchQuery({ page: (e.current || 2) - 1, size: e.pageSize || 20 });
            }}
          />
        </div>
      </div>
      {selectedMessage && (
        <div
          style={{
            width: '428px',
            height: '100vh',
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'white',
            zIndex: 900,
            borderLeft: '1px solid #eaeaea',
          }}>
          <div style={{ height: '44px', backgroundColor: '#eaeaea', display: 'flex', alignItems: 'center' }}>
            <Button
              onClick={() => {
                setSelectedMessage(undefined);
              }}>
              닫기
            </Button>
          </div>
          <ChatPreview
            productId={selectedMessage.classId}
            tutorId={selectedMessage.tutorId}
            parentId={selectedMessage.parentId}></ChatPreview>
        </div>
      )}
    </div>
  );
};

export default ChatMessagesPage;
