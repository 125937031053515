import FileUploadButton from '@components/common/FileUploadButton';
import { closestCenter, DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DeepLinkActions } from '@models/common';
import { FeedData, FeedDataItem } from '@models/feed';
import { cdnImageUrl, resizeImage } from '@utils/image';
import { extractFileName } from '@utils/string';
import { Button, Input } from 'antd';
import { RcFile } from 'antd/es/upload';
import React, { useEffect, useState } from 'react';
import { SelectProductCollection } from './SelectProductCollection';

interface SortableThemeCellItemProps {
  onDeleteItem?: (event: any) => void;
  onChange?: (item: FeedDataItem) => void;
  onChangeImage?: (files: FileList) => void;
  id: any;
  item: FeedDataItem;
}

export const SortableThemeCellItem: React.FC<SortableThemeCellItemProps> = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const { onDeleteItem, onChange, item } = props;

  return (
    <div ref={setNodeRef} style={{ ...style }} {...attributes} {...listeners}>
      <div style={{ display: 'flex', marginBottom: '8px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '182px', marginRight: '8px' }}>
          <div
            id="theme-cell-item"
            style={{
              width: '182px',
              height: '80px',
              marginBottom: '8px',
            }}>
            <div
              style={{
                position: 'relative',
                cursor: 'pointer',
                overflow: 'hidden',
                borderRadius: '10px',
                height: '80px',
              }}>
              <img
                src={cdnImageUrl(item.mainImageUrlTemp || item.mainImageUrl)}
                style={{
                  width: '100%',
                  height: '80px',
                  objectFit: 'cover',
                  borderRadius: '10px',
                }}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  height: '80px',
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  backgroundColor: 'rgba(0,0,0,0.2)',
                  borderRadius: '10px',
                }}>
                <div
                  style={{
                    color: 'white',
                    fontSize: '14px',
                    lineHeight: '20px',
                    fontWeight: '700',
                    alignSelf: 'center',
                    whiteSpace: 'pre-wrap',
                    textAlign: 'center',
                  }}>
                  {item.mainText}
                </div>
              </div>
            </div>
          </div>
          <FileUploadButton
            accept={['jpg', 'jpeg', 'png', 'tif', 'tiff', 'webp', 'svg']
              .map((ext) => {
                return '.' + ext;
              })
              .join(',')}
            className="link-button"
            style={{
              fontSize: '14px',
              height: '32px',
              padding: '4px 15px',
              borderRadius: '6px',
              backgroundColor: '#ffffff',
              border: '1px solid #d9d9d9',
              color: 'rgba(0, 0, 0, 0.88)',
              boxShadow: '0 2px 0 rgba(0, 0, 0, 0.02)',
              cursor: 'pointer',
            }}
            onChange={(e) => {
              if (e.target.files) {
                const files = e.target.files;
                props.onChangeImage && props.onChangeImage(files);
              }
            }}>
            이미지 변경
          </FileUploadButton>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', marginRight: '8px' }}>
          <Input.TextArea
            value={item.mainText}
            onChange={(e) => {
              onChange && onChange({ ...item, mainText: e.target.value });
            }}></Input.TextArea>
          <div>
            <div>기획전 선택</div>
            <SelectProductCollection
              onSelect={(value) => {
                onChange &&
                  onChange({
                    ...item,
                    action: DeepLinkActions.GO_TO_THEME_COLLECTION,
                    payload: { collectionId: value._id, title: value.title },
                  });
              }}></SelectProductCollection>
            <div style={{ marginTop: '8px' }}>
              <span>선택: </span>
              {item.payload?.title}
            </div>
          </div>
        </div>
        <div>
          <Button
            onClick={(e) => {
              onDeleteItem && onDeleteItem(e);
            }}>
            삭제
          </Button>
        </div>
      </div>
    </div>
  );
};

export const ThemeCellEdit: React.FC<{
  clear?: boolean;
  data: Partial<FeedData>;
  onChange?: (data: Partial<FeedData>) => void;
  onChangeImageFile?: (files: { [key: string]: File }) => void;
}> = (props) => {
  const [changedContents, setChangedContents] = useState<FeedDataItem[]>();
  const [imageFiles, setImageFiles] = useState<{ [key: string]: File }>({});
  const [changedTitle, setChangedTitle] = useState<string>();
  const { data } = props;
  const contents = changedContents || data.data || [];
  const sortableSensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 10 },
    }),
  );

  useEffect(() => {
    if (props.clear) {
      setChangedContents(undefined);
      setImageFiles({});
      setChangedTitle(undefined);
    }
  }, [props.clear]);

  useEffect(() => {
    props.onChangeImageFile && props.onChangeImageFile(imageFiles);
  }, [imageFiles]);

  useEffect(() => {
    props.onChange &&
      props.onChange({
        ...data,
        data: changedContents || data.data,
        title: changedTitle || data.title,
      });
  }, [changedContents, changedTitle]);

  const onChangeImage = async (files: FileList, index: number) => {
    const { fileName } = extractFileName(files[0].name);
    const newFiles = await Promise.all(
      Array.from(files)
        .slice(0, 10)
        .map(async (file) => {
          const result = await resizeImage(file, { maxWidth: 368 });

          return result;
        }),
    );
    const contentTypes = newFiles[0].type.split('/');
    const uploadFileName = `${fileName}.${contentTypes[1]}`;
    const newImageFile = new File([newFiles[0].image], `${fileName}.${contentTypes[1]}`) as RcFile;
    const tempUrl = URL.createObjectURL(newFiles[0].image);
    setChangedContents((prev) => {
      const newValues = [...(prev || contents)];
      newValues[index] = { ...newValues[index], mainImageUrl: uploadFileName, mainImageUrlTemp: tempUrl };
      return newValues;
    });

    setImageFiles((value) => {
      const newValue = { ...value };
      newValue[`${data.type}.${index}`] = newImageFile;
      return newValue;
    });
  };

  const onSortEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      if (contents) {
        const oldIndex = contents.findIndex((item) => item.mainText === active.id);
        const newIndex = contents.findIndex((item) => item.mainText === over?.id);
        const newData = arrayMove(contents, oldIndex, newIndex);
        setChangedContents(newData);
      }
    }
  };

  return (
    <div>
      <div style={{ display: 'flex', height: '44px', justifyContent: 'space-between' }}>
        <Button
          onClick={() => {
            setChangedContents((prev) => {
              const newValues = [{}, ...(prev || contents)];
              return newValues;
            });
          }}>
          빈 항목 추가
        </Button>
      </div>
      <div style={{ marginBottom: '12px' }}>
        <div style={{ fontWeight: 'bold' }}>타이틀</div>
        <Input
          value={data.title}
          placeholder={'테마 클래스 추천'}
          onChange={(e) => {
            setChangedTitle(e.target.value);
          }}></Input>
      </div>

      <DndContext sensors={sortableSensors} collisionDetection={closestCenter} onDragEnd={onSortEnd}>
        <SortableContext
          items={contents.map((item) => {
            return { ...item, id: item.mainText || '' };
          })}
          strategy={verticalListSortingStrategy}>
          <div style={{ maxWidth: '600px' }}>
            {contents.map((item, index) => {
              return (
                <SortableThemeCellItem
                  item={item}
                  id={item.mainText}
                  onDeleteItem={() => {
                    setChangedContents((prev) => {
                      const newValues = [...(prev || contents)];
                      newValues.splice(index, 1);
                      return newValues;
                    });
                  }}
                  onChangeImage={(files: FileList) => {
                    onChangeImage(files, index);
                  }}
                  onChange={(item) => {
                    setChangedContents((prev) => {
                      const newValues = [...(prev || contents)];
                      newValues[index] = item;
                      return newValues;
                    });
                  }}></SortableThemeCellItem>
              );
            })}
          </div>
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default ThemeCellEdit;
