import * as paymentStatementApi from '@apis/payment-statement';
import useUpdateCompletionDateModalStore from '@stores/updateCompletionDateModalStore';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { useMutation } from '@tanstack/react-query';
import { localTimeToUtcISOString, utcTimcToLocalISOString } from '@utils/date';
import { Button, DatePicker, Input, Modal } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

export const UpdateCompletionDateModal: React.FC = () => {
  const { show, close, data } = useUpdateCompletionDateModalStore();
  const [date, setDate] = useState<string | undefined | null>();
  const [memo, setMemo] = useState<string>();
  const dialog = useOkCancelDialog();

  const updateCompletionDateMutation = useMutation({
    mutationFn: paymentStatementApi.updateCompletionDate,
    onSuccess: async () => {
      clear();
      close();
      dialog.open({
        type: 'ok',
        content: '반영 되었습니다.',
      });
    },
    onError: () => {},
  });

  useEffect(() => {
    if (data.memo) {
      setMemo(data.memo);
    }
  }, [data?.memo]);

  const clear = () => {
    setDate(undefined);
    setMemo(undefined);
  };

  const renderDates = () => {
    if (data.completionDate) {
      return <div>{moment(data.completionDate).format('YYYY. MM. DD')}</div>;
    }
    return '-';
  };

  const validate = () => {
    if (!memo || !date) return false;
    return true;
  };

  const updatePayment = () => {
    if (validate()) {
      const updateData: any = { id: data._id!, body: { date: date, memo: memo } };
      updateCompletionDateMutation.mutate(updateData);
    } else {
      dialog.open({
        type: 'cancel',
        content: '날짜 혹은 메모를 확인해주세요.',
      });
    }
  };

  if (!show) {
    return <></>;
  }
  return (
    <Modal
      centered
      open={show}
      width={480}
      closable={false}
      footer={
        <div>
          <Button
            onClick={() => {
              clear();
              updatePayment();
            }}>
            {data?.completionDate ? '수정하기' : '추가하기'}
          </Button>
          <Button
            onClick={() => {
              clear();
              close();
            }}>
            취소
          </Button>
        </div>
      }
      onCancel={() => {
        clear();
        close();
      }}>
      <div>
        <div style={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'center' }}>결제 완성일 설정</div>
        <div
          style={{
            padding: '12px 0',
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
            alignItems: 'flex-start',
          }}>
          <div style={{ fontWeight: 'bold' }}>기존 등록된 결제 완성일: {renderDates()}</div>
          <div>
            <div style={{ fontWeight: 'bold' }}>결제 완성일을 선택해주세요.</div>
            <DatePicker
              value={date ? dayjs(utcTimcToLocalISOString(date as any)) : dayjs(new Date())}
              onChange={(date) => {
                setDate(
                  localTimeToUtcISOString(date ? new Date(date.toISOString()) : new Date(), { timeToZero: true }),
                );
              }}
            />
          </div>
          <div style={{ width: '100%' }}>
            <div style={{ fontWeight: 'bold' }}>정산 메모(필수입력)</div>
            <Input.TextArea
              value={memo}
              onChange={(e) => {
                setMemo(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateCompletionDateModal;
