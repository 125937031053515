import { PagenatedResponse, SearchPagenateQuery } from '@models/common';
import { Membership } from '@models/membership';
import { apiClient } from '.';

export const searchMemberships = async (query?: SearchPagenateQuery) => {
  return apiClient.get<PagenatedResponse<Membership>>(`/admin/v1/memberships`, {
    params: query,
  });
};

export const updateMembership = async (formData: FormData) => {
  const membershipId = formData.get('membershipId');
  return apiClient.put<Membership>(`/admin/v1/memberships/${membershipId}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const createMembership = async (formData: FormData) => {
  return apiClient.post<Membership>(`/admin/v1/memberships`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
