import React, { useEffect, useState } from 'react';
import * as api from '@apis/admin-credit-points';
import { AdminCreditPointOnetimeCoupon } from '@models/credit-points';
import { useQuery } from '@tanstack/react-query';
import { Button, Modal, Table } from 'antd';
import { PagenatedResponse } from '@models/common';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';

const PAGE_SIZE = 20;
interface OnetimePointCouponsModalProps {
  couponId: string;
  open?: boolean;
  onClose?: () => void;
}

export const OnetimePointCouponsModal: React.FC<OnetimePointCouponsModalProps> = (props) => {
  const [searchQuery, setSearchQuery] = useState({
    page: 0,
    size: PAGE_SIZE,
  });
  const [couponsContainer, setCouponsContainer] = useState<PagenatedResponse<AdminCreditPointOnetimeCoupon>>({
    page: -1,
    total: 0,
    size: PAGE_SIZE,
    hasNext: false,
    contents: [],
  });
  const { couponId } = props;
  const couponsQuery = useQuery({
    queryKey: [`onetime-point-coupons-${couponId}`, JSON.stringify(searchQuery)],
    queryFn: () => api.searchOnetimeCoupons(couponId, searchQuery),
  });

  const coupons = couponsQuery.data?.data;

  useEffect(() => {
    if (coupons) {
      setCouponsContainer((prev) => {
        const contents = new Array(coupons.total || PAGE_SIZE);
        contents.splice(coupons.page * coupons.size, coupons.size, ...(coupons?.contents || []));
        return { ...prev, ...coupons, contents: contents };
      });
    }
  }, [coupons]);

  const TABLE_COLUMNS: ColumnsType<AdminCreditPointOnetimeCoupon> = [
    {
      title: '쿠폰 코드',
      render: (record: AdminCreditPointOnetimeCoupon) => {
        return record?.couponCode;
      },
    },
    {
      title: '사용여부',
      render: (record: AdminCreditPointOnetimeCoupon) => {
        if (!record.useYn) {
          return '미사용';
        }
        return `${record.useInfo.user.profile?.name}\n${moment(record.useInfo.log.createdAt).format('YYYY-MM-DD')}`;
      },
    },
  ];

  return (
    <Modal
      styles={{ footer: { height: '44px' } }}
      open={props.open}
      footer={
        <div style={{ width: '100%' }}>
          <Button
            style={{
              width: 120,
            }}
            onClick={props.onClose}>
            닫기
          </Button>
        </div>
      }
      onCancel={props.onClose}>
      <div>
        <div>1회용 쿠폰 목록</div>
        <Table
          columns={TABLE_COLUMNS}
          scroll={{ x: 'max-content', y: 'calc(100dvh - 280px)' }}
          style={{ whiteSpace: 'pre-wrap' }}
          rowKey={(record) => record?._id}
          dataSource={couponsContainer.contents.filter((product) => product?._id)}
          pagination={{
            current: (couponsContainer.page || 0) + 1,
            pageSize: couponsContainer.size || 20,
            total: couponsContainer.total,
          }}
          loading={couponsQuery.isLoading}
          onChange={(e) => {
            setSearchQuery({ page: (e.current || 2) - 1, size: e.pageSize || 20 });
          }}
        />
      </div>
    </Modal>
  );
};

export default OnetimePointCouponsModal;
