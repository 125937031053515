import { DeleteOutlined } from '@ant-design/icons';
import * as api from '@apis/feeds';
import { EditFeedDataView, FEED_TYPE_LABELS } from '@components/feed/EditFeedDataView';
import MainFeedView from '@components/feed/MainFeedView';
import MdPickView from '@components/feed/MdPickView';
import { closestCenter, DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useAuthentication } from '@hooks/authentication';
import { Feed, FeedData } from '@models/feed';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { useMutation, useQuery } from '@tanstack/react-query';
import { removeTempProperties } from '@utils/dataTransform';
import { Button, Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react';

interface SortableFeedItemProps {
  onDeleteItem?: (event: any) => void;
  onClickItem?: (item: FeedData) => void;
  id: any;
  item: FeedData;
}

export const SortableFeedItem: React.FC<SortableFeedItemProps> = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const { onDeleteItem, onClickItem, item } = props;

  let title = `[${(item.type && FEED_TYPE_LABELS[item.type].label) || item.type}]`;
  if (item?.title) {
    title = `${title} ${item?.title}`;
  } else if (item?.brandTitle) {
    title = `${title} ${item?.brandTitle}`;
  }

  return (
    <div ref={setNodeRef} style={{ ...style }} {...attributes} {...listeners}>
      <div
        className="feed-line"
        style={{
          display: 'flex',
          gap: '8px',
          borderRadius: '8px',
          border: `1px solid ${FEED_TYPE_LABELS[item.type].color}`,
          backgroundColor: 'white',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '8px',
          marginTop: '4px',
        }}
        onClick={() => {
          onClickItem && onClickItem(item);
        }}>
        <div>{title}</div>
        <div className="feed-item">
          <Button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onDeleteItem && onDeleteItem(e);
            }}>
            <DeleteOutlined style={{ color: 'red' }} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export const MainFeedPage: React.FC = () => {
  useAuthentication();

  const [editMode, setEditMode] = useState<'main-feed' | 'md-pick'>('main-feed');
  const [modifiedFeedMap, setModifiedFeedMap] = useState<{ 'main-feed'?: Feed; 'md-pick'?: Feed }>({});
  const [addType, setAddType] = useState<string>();
  const [selectedItemIndex, setSelectedItemIndex] = useState<number>(-1);
  const [modalMode, setModalMode] = useState<string>();
  const [editedFeedData, setEditedFeedData] = useState<Partial<FeedData>>();
  const [modifiedFiles, setModifiedFiles] = useState<{ [key: string]: File }>({});
  const dialog = useOkCancelDialog();

  const feedQuery = useQuery({
    queryKey: [`feed-${editMode}`],
    queryFn: () => api.getFeed(editMode),
  });

  const refreshMainFeedMutation = useMutation({
    mutationFn: api.refreshMainFeed,
    onSuccess: async () => {
      dialog.open({ type: 'ok', title: '알림', content: '메인피드를 갱신했습니다' });
    },
    onError: async (e) => {
      dialog.open({ type: 'ok', title: '오류', content: JSON.stringify(e) });
    },
  });

  const updateMainFeedMutation = useMutation({
    mutationFn: api.updateMainFeed,
    onSuccess: async () => {
      dialog.open({ type: 'ok', title: '알림', content: '메인피드를 수정했습니다' });
    },
    onError: async (e) => {
      dialog.open({ type: 'ok', title: '오류', content: JSON.stringify(e) });
    },
  });

  const updateMdPickMutation = useMutation({
    mutationFn: api.updateMdPick,
    onSuccess: async () => {
      dialog.open({ type: 'ok', title: '알림', content: 'MD PICK 피드를 수정했습니다' });
    },
    onError: async (e) => {
      dialog.open({ type: 'ok', title: '오류', content: JSON.stringify(e) });
    },
  });

  const deployFeedMutation = useMutation({
    mutationFn: api.deployFeed,
    onSuccess: async () => {
      dialog.open({
        type: 'ok',
        title: '알림',
        content: `${editMode === 'main-feed' ? '메인피드를 운영에' : 'MD PICK을 운영에'} 배포했습니다.`,
      });
    },
    onError: async (e) => {
      dialog.open({ type: 'ok', title: '오류', content: JSON.stringify(e) });
    },
  });

  const feedData = feedQuery.data?.data;
  const feedContent = (modifiedFeedMap[editMode]?.data || feedData?.data || []).filter((item) => item);

  const sortableSensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 10 },
    }),
  );

  const onSortEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const data = modifiedFeedMap[editMode]?.data;
      if (data) {
        const oldIndex = data.findIndex((item, index) => `${item._id}_${index}` === active.id);
        const newIndex = data.findIndex((item, index) => `${item._id}_${index}` === over?.id);
        const newData = arrayMove(data, oldIndex, newIndex);
        setModifiedFeedMap((value) => {
          return { ...value, [editMode]: { ...value[editMode], data: newData } };
        });
      }
    }
  };

  useEffect(() => {
    setModifiedFeedMap((map) => {
      return { ...map, [editMode]: feedData };
    });
  }, [feedData]);

  return (
    <div className="dashboard" style={{ position: 'relative' }}>
      <Modal
        width={800}
        open={!!modalMode}
        onCancel={() => {
          setModalMode(undefined);
          setEditedFeedData(undefined);
        }}
        footer={
          <div>
            <Button
              onClick={() => {
                if (modalMode === 'edit') {
                  setModifiedFeedMap((value) => {
                    const newData = [...feedContent];
                    newData[selectedItemIndex] = editedFeedData as FeedData;
                    return { ...value, [editMode]: { ...value[editMode], data: newData } };
                  });
                } else {
                  setModifiedFeedMap((value) => {
                    const newData = [...feedContent];
                    newData.push(editedFeedData as FeedData);
                    return { ...value, [editMode]: { ...value[editMode], data: newData } };
                  });
                }

                setModalMode(undefined);
                setEditedFeedData(undefined);
              }}>
              {modalMode === 'new' ? '추가' : '수정'}
            </Button>
            <Button
              onClick={() => {
                setModalMode(undefined);
              }}>
              취소
            </Button>
          </div>
        }>
        {modalMode && (
          <EditFeedDataView
            mode={modalMode}
            data={editedFeedData || (selectedItemIndex >= 0 && feedContent[selectedItemIndex]) || { type: addType }}
            onChangeImageFile={(files) => {
              setModifiedFiles((prev) => {
                return { ...prev, ...files };
              });
            }}
            onChange={(data) => {
              setEditedFeedData(data);
            }}></EditFeedDataView>
        )}
      </Modal>
      <div className="page-header">
        <div>메인피드 관리(홈 / MD PICK)</div>
        <div style={{ display: 'flex', gap: '8px', marginRight: '428px' }}>
          <Button
            disabled={!modifiedFeedMap}
            onClick={() => {
              const formData: FormData = new FormData();
              const json = { ...modifiedFeedMap[editMode] };
              json.data = (json.data || []).map((item) => {
                if (['curation-class-general', 'curation-class-list', 'curation-class-grid'].includes(item.type)) {
                  return { ...item, data: undefined };
                } else {
                  return item;
                }
              }) as FeedData[];

              json.data = removeTempProperties(json.data);

              formData.append('json', JSON.stringify(json));
              Object.values(modifiedFiles).forEach((file) => {
                formData.append('files', file);
              });
              if (editMode === 'main-feed') {
                updateMainFeedMutation.mutate(formData);
              } else if (editMode === 'md-pick') {
                updateMdPickMutation.mutate(formData);
              }
            }}>
            저장
          </Button>
          <Button
            onClick={() => {
              deployFeedMutation.mutate(editMode);
            }}>
            배포하기
          </Button>
          <Button
            onClick={() => {
              refreshMainFeedMutation.mutate();
            }}>
            메인피드 새로고침
          </Button>
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <div className="page-content" style={{ width: '100%', height: 'calc(100vh - 80px)', overflow: 'auto' }}>
          <div
            style={{
              display: 'flex',
              gap: '12px',
              width: 'calc(100% - 428px)',
              height: '44px',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            <Select
              value={editMode}
              style={{ width: 120 }}
              onChange={(value) => {
                setEditMode(value as 'main-feed' | 'md-pick');
              }}
              options={[
                { value: 'main-feed', label: '홈' },
                { value: 'md-pick', label: 'MD PICK' },
              ]}
            />
            <div
              style={{
                display: 'flex',
                gap: '4px',
              }}>
              {editMode === 'main-feed' && (
                <Select
                  value={addType}
                  style={{ width: 240 }}
                  onChange={(value) => {
                    setAddType(value);
                  }}
                  options={Object.keys(FEED_TYPE_LABELS).map((key) => {
                    return { value: key, label: FEED_TYPE_LABELS[key].label };
                  })}
                />
              )}

              <Button
                onClick={() => {
                  if (editMode === 'md-pick') {
                    setAddType('brand');
                  }
                  setSelectedItemIndex(-1);
                  setModalMode('new');
                }}>
                추가
              </Button>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1, width: 'calc(100% - 428px)', display: 'flex' }}>
              <div style={{ flex: 1, height: 'calc(100vh - 160px)', overflow: 'auto' }}>
                <DndContext sensors={sortableSensors} collisionDetection={closestCenter} onDragEnd={onSortEnd}>
                  <SortableContext
                    items={feedContent.map((item, index) => {
                      return { ...item, id: `${item._id}_${index}` };
                    })}
                    strategy={verticalListSortingStrategy}>
                    <div style={{ maxWidth: '600px' }}>
                      {feedContent.map((item: FeedData, index: number) => {
                        return (
                          <SortableFeedItem
                            id={`${item._id}_${index}`}
                            key={`${item._id}_${index}`}
                            onDeleteItem={() => {
                              const newData = [...feedContent];
                              newData.splice(index, 1);
                              setModifiedFeedMap((value) => {
                                return { ...value, [editMode]: { ...value[editMode], data: newData } };
                              });
                            }}
                            onClickItem={() => {
                              setSelectedItemIndex(index);
                              setModalMode('edit');
                            }}
                            item={item}
                          />
                        );
                      })}
                    </div>
                  </SortableContext>
                </DndContext>
              </div>
              <div style={{ flex: 1 }}></div>
            </div>
            <div style={{ width: '428px', flexShrink: 0 }}></div>
            <div
              style={{
                width: '428px',
                height: '100vh',
                position: 'fixed',
                top: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'white',
                zIndex: 900,
                borderLeft: '1px solid #eaeaea',
              }}>
              <div
                style={{
                  height: '44px',
                  backgroundColor: '#eaeaea',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                미리보기
              </div>
              {editMode === 'main-feed' && <MainFeedView data={feedContent as any}></MainFeedView>}
              {editMode === 'md-pick' && <MdPickView data={feedContent as any}></MdPickView>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainFeedPage;
