const CITY_ALL = '전체';

const DISTRICTS = {
  전체: ['전체'],
  서울특별시: [
    '전체',
    '강남구',
    '강동구',
    '강북구',
    '강서구',
    '관악구',
    '광진구',
    '구로구',
    '금천구',
    '노원구',
    '도봉구',
    '동대문구',
    '동작구',
    '마포구',
    '서대문구',
    '서초구',
    '성동구',
    '성북구',
    '송파구',
    '양천구',
    '영등포구',
    '용산구',
    '은평구',
    '종로구',
    '중구',
    '중랑구',
  ],
  경기도: [
    '전체',
    '고양시',
    '과천시',
    '광명시',
    '광주시',
    '구리시',
    '군포시',
    '김포시',
    '남양주시',
    '부천시',
    '성남시',
    '수원시',
    '시흥시',
    '안산시',
    '안양시',
    '오산시',
    '용인시',
    '의왕시',
    '의정부시',
    '파주시',
    '하남시',
    '화성시',
  ],
  '인천 광역시': ['전체', '계양구', '남동구', '동구', '미추홀구', '부평구', '서구', '연수구', '중구'],
  '대구 광역시': ['전체', '경산', '남구', '달서구', '달성군', '동구', '북구', '서구', '수성구', '중구'],
  '대전 광역시': ['전체', '대덕구', '동구', '서구', '유성구', '중구'],
  '부산 광역시': [
    '전체',
    '강서구',
    '금정구',
    '기장군',
    '남구',
    '동구',
    '동래구',
    '부산진구',
    '북구',
    '사상구',
    '사하구',
    '서구',
    '수영구',
    '연제구',
    '영도구',
    '중구',
    '해운대구',
  ],
  강원도: ['전체', '춘천시'],
  제주도: ['전체', '서귀포시', '제주시'],
};

export { CITY_ALL, DISTRICTS };
