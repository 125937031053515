import { PagenatedResponse, SearchPagenateQuery } from '@models/common';
import {
  NaverCommerceOrder,
  NaverCommerceProduct,
  SalesChannelOrder,
  SalesChannelProduct,
} from '@models/sales-channels';
import { NaverOrdersQuery } from '@stores/naverOrdersStore';
import { apiClient } from '.';
import {
  RequestCancelReservation,
  KakaoReservationsQuery,
  ChangeReservationStatus,
} from '@pages/SalesChannelKakaoOrdersPage';

export const createSalesChannelProducts = async (body: {
  items: { sellerName?: string; title?: string; price?: number }[];
}) => {
  return apiClient.post(`/admin/v1/sales-channels/products`, body);
};

export const deleteSalesChannelProduct = async (productId: string) => {
  return apiClient.delete(`/admin/v1/sales-channels/products/${productId}`);
};

export const searchSalesChannelProducts = async (query?: SearchPagenateQuery) => {
  return apiClient.get<PagenatedResponse<SalesChannelProduct>>(`/admin/v1/sales-channels/products`, {
    params: query,
  });
};

export const updateSalesChannelProducts = async (body: { targetIds: string[]; data: Partial<SalesChannelProduct> }) => {
  return apiClient.put(`/admin/v1/sales-channels/products`, body);
};

export const searchNaverOrders = async (query?: NaverOrdersQuery) => {
  return apiClient.get<PagenatedResponse<SalesChannelOrder>>(`/admin/v1/sales-channels/naver/orders`, {
    params: query,
  });
};

export const searchKakaoReservations = async (query: KakaoReservationsQuery) => {
  return apiClient.get<PagenatedResponse<SalesChannelOrder>>('/admin/v1/sales-channels/kakao/orders', {
    params: query,
  });
};

export const changeKakaoReservationStatus = async (props: {
  type: 'complete' | 'retract-complete' | 'cancel';
  bookingId: string;
  body: ChangeReservationStatus | RequestCancelReservation;
}) => {
  const { type, bookingId, body } = props;

  return apiClient.put(`/admin/v1/sales-channels/kakao/orders/${bookingId}/status/${type}`, body);
};

export const searchInboundOrders = async (query?: NaverOrdersQuery) => {
  return apiClient.get<PagenatedResponse<SalesChannelOrder>>(`/admin/v1/sales-channels/inbound/orders`, {
    params: query,
  });
};

export const searchNaverCommerceProducts = async (query?: NaverOrdersQuery) => {
  return apiClient.get<PagenatedResponse<NaverCommerceProduct>>(`/admin/v1/sales-channels/naver-commerce/products`, {
    params: query,
  });
};

export const deleteNaverOrders = async (ids: string) => {
  return apiClient.delete(`/admin/v1/sales-channels/naver/orders`, {
    params: { ids },
  });
};

export const updateSalesChannelOrders = async (body: {
  channel: 'naver' | 'inbound';
  targetIds: string[];
  data: Partial<SalesChannelOrder>;
}) => {
  return apiClient.put(`/admin/v1/sales-channels/${body.channel}/orders`, body);
};

export const sendKakaoNotification = async (body: { targetIds: string[]; templateId: string }) => {
  return apiClient.post(`/admin/v1/sales-channels/naver/orders/kakao-alimtalk`, body);
};

export const getSalesChannelExcelFile = async (query?: NaverOrdersQuery) => {
  return apiClient.get(`/admin/v1/sales-channels/naver/orders/export`, {
    params: query,
    responseType: 'blob',
    headers: { 'Cache-Control': 'no-cache' },
  });
};

export const getSalesChannelExcelFileForSettlement = async (query?: NaverOrdersQuery) => {
  return apiClient.get(`/admin/v1/sales-channels/naver/orders/export/settlement`, {
    params: query,
    responseType: 'blob',
    headers: { 'Cache-Control': 'no-cache' },
  });
};

export const sendNaverOrdersSummaryKakaoNotification = async () => {
  return apiClient.post(`/admin/v1/schedule/notification/naver-orders`);
};

export const updateSalesChannelOrderPaymentAmount = async () => {
  return apiClient.patch(`/admin/v1/sales-channels/naver/orders/payment-amount`);
};

export const sendRefundsKakaoNotification = async () => {
  return apiClient.post(`/admin/v1/schedule/notification/naver-refunds`);
};

export const searchNaverCommerceOrders = async (query?: NaverOrdersQuery) => {
  return apiClient.get<PagenatedResponse<NaverCommerceOrder>>(`/admin/v1/sales-channels/naver-commerce/orders`, {
    params: query,
  });
};

export const convertNaverComerceOrdersToNaverOrders = async (ids: string[]) => {
  return apiClient.post(`/admin/v1/sales-channels/naver-commerce/orders/naver-orders`, { ids });
};

export const convertNaverComerceOrdersToNaverOrdersDispatched = async (ids: string[]) => {
  return apiClient.post(`/admin/v1/sales-channels/naver-commerce/orders/naver-orders`, {
    ids,
    rangeType: 'DISPATCHED_DATETIME',
  });
};

export const addSalesChannelOrderAccountTransferAmount = async (data: {
  id: string;
  channel: string;
  body: { amount: number; memo: string; channel: string };
}) => {
  const salesOrderId = data.id;
  const channel = data.channel;
  return apiClient.patch(`/admin/v1/sales-channels/${channel}/${salesOrderId}/account-transfer-amount`, data.body);
};

export const createBankAccountTransferOrder = async (data: Partial<SalesChannelOrder>) => {
  return apiClient.post<SalesChannelOrder>(`/admin/v1/sales-channels/inbound-order`, data);
};
