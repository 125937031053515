import { PagenatedResponse, SearchPagenateQuery } from '@models/common';
import { ProductSearchQuery } from '@models/product';
import { PaymentStatementReview, PaymentStatementReviewStatus } from '@models/review';
import { apiClient } from '.';

export const searchReviews = async (query?: SearchPagenateQuery) => {
  return apiClient.get<PagenatedResponse<PaymentStatementReview>>(`/admin/v1/reviews`, {
    params: query,
  });
};

export const updateReview = async (data: { reviewId: string; body: { status: PaymentStatementReviewStatus } }) => {
  return apiClient.put<PagenatedResponse<PaymentStatementReview>>(`/admin/v1/reviews/${data.reviewId}`, data.body);
};

export const getReviewsExcelFile = async (query?: ProductSearchQuery) => {
  return apiClient.get(`/admin/v1/reviews/export`, {
    params: query,
    responseType: 'blob',
    headers: { 'Cache-Control': 'no-cache' },
  });
};
