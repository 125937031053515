import * as commonApi from '@apis/common';
import * as api from '@apis/product';
import { getProductsExcelFile } from '@apis/product';
import ProductDetailView from '@components/product/ProductDetailView';
import ProductEditView from '@components/product/ProductEditView';
import { SelectUser } from '@components/product/SelectUser';
import { useAppSocket } from '@hooks/appSocket';
import { useAuthentication } from '@hooks/authentication';
import {
  ProductModel,
  ProductStatus,
  PRODUCT_ACTIVITY_TYPE_TEXT,
  PRODUCT_STATUS_TEXT,
  ProductApproveStatus,
  PRODUCT_APPROVE_STATUS_TEXT,
  ProductActivityType,
} from '@models/product';
import { useContentLoaderStore } from '@stores/contentLoaderStore';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { useProductsStore } from '@stores/proudctsStore';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getAddressText, getBadges } from '@utils/dataTransform';
import { cdnImageUrl } from '@utils/image';
import { PRODUCT_SHARE_BASE_URL } from '@variables';
import { Button, Checkbox, Input, Modal, Popover, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { clearEmptyKeysFromObject } from '@utils/dataTransform';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { toast } from 'react-toastify';
import './ProductsPage.scss';

export const ProductsPage: React.FC = () => {
  const [shareLinkProductId, setShareLinkProductId] = useState<string>();
  const [showCreateProduct, setShowCreateProduct] = useState<boolean>(false);
  const [newProduct, setNewProduct] = useState<Partial<ProductModel>>({
    status: ProductStatus.CLOSE,
    approveStatus: ProductApproveStatus.REQUESTED,
  });
  const [searchKeyword, setSearchKeyword] = useState<string>();
  const [lastEditProductId, setLastEditProductId] = useState<string>();
  const [previewProduct, setPreviewProduct] = useState<ProductModel>();
  const searchInputRef = useRef<any>();
  const appSocket = useAppSocket();
  const store = useProductsStore();
  const loader = useContentLoaderStore();
  const dialog = useOkCancelDialog();
  useAuthentication();

  const productSearchQuery = useQuery({
    queryKey: [`admin-search-products`, JSON.stringify(store.searchQuery)],
    queryFn: () => api.searchProducts(store.searchQuery),
  });

  const mutationDeleteProduct = useMutation({
    mutationFn: api.deleteProduct,
    onSuccess: () => {
      productSearchQuery.refetch();
    },
  });

  const createProductMutation = useMutation({
    mutationFn: api.createProduct,
    onSuccess: (response) => {
      productSearchQuery.refetch();
      setShowCreateProduct(false);
      setNewProduct({ status: ProductStatus.CLOSE, approveStatus: ProductApproveStatus.REQUESTED });
      setLastEditProductId(response.data._id);
      dialog.open({
        content: `상품이 추가되었습니다`,
      });
    },
  });

  const generateOneShareLinkMutate = useMutation({
    mutationFn: commonApi.generateProductShareLink,
  });

  useEffect(() => {
    const searchResult = productSearchQuery.data?.data;
    if (searchResult) {
      store.setProductsContainer(searchResult);
    }
  }, [productSearchQuery.data?.data]);

  useEffect(() => {
    appSocket.socket.addSocketEventListener('upload_excel_status', 'upload_excel_status_listner', (data) => {
      if (data.type === 'products') {
        if (data.status.toLowerCase() === 'done') {
          productSearchQuery.refetch();
        }

        if (data.status.toLowerCase() === 'done' || data.status.toLowerCase() === 'error') {
          loader.off();
        }

        if (data.status) {
          toast.error(data.message, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            pauseOnFocusLoss: false,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        }
      }
    });
  }, []);

  useEffect(() => {
    if (!searchKeyword && store.searchQuery.keyword) {
      setSearchKeyword(store.searchQuery.keyword);
    }
  }, [store.searchQuery.keyword]);

  window.onbeforeunload = () => {
    appSocket.socket.removeSocketEventListener('upload_excel_status', 'upload_excel_status_listner');
    appSocket.socket.removeSocketEventListener('upload_excel_status', 'upload_excel_status_listner_in_edit');
  };

  const TABLE_COLUMNS: ColumnsType<ProductModel> = [
    {
      title: '썸네일',
      dataIndex: 'thumbnailImage',
      render: (thumbnailImage) => (
        <img
          src={cdnImageUrl(thumbnailImage)}
          style={{ width: '40px', height: '40px', objectFit: 'cover', borderRadius: '4px' }}
        />
      ),
      fixed: 'left',
    },
    {
      title: '판매방식',
      width: 112,
      align: 'center',
      render: (record) => {
        const saleType = record.saleType;
        const naverStore = record.linkedSalesChannel?.naverStore;
        return (
          <div>
            <div>{`${saleType}`}</div>
            {naverStore && (
              <div
                style={{
                  backgroundColor: '#03c75a',
                  color: 'white',
                  fontSize: '12px',
                  padding: '4px 8px',
                  borderRadius: '4px',
                }}>
                네이버스토어
              </div>
            )}
          </div>
        );
      },
      fixed: 'left',
    },
    {
      title: '구분',
      dataIndex: 'activityType',
      render: (activityType: ProductActivityType) => {
        if (!activityType) {
          return '-';
        }
        return `${PRODUCT_ACTIVITY_TYPE_TEXT[activityType]?.title || activityType}`;
      },
      fixed: 'left',
    },
    {
      title: '지역',
      dataIndex: 'location',
      render: (location) => {
        let content = '';
        if (location?.specificAddress) {
          content = `${location?.specificAddress?.address} ${location?.specificAddress?.address2}`;
        }
        return (
          <Popover content={content}>
            <div style={{ color: content ? 'green' : 'black' }}>{getAddressText({ availableArea: location })}</div>
          </Popover>
        );
      },
      fixed: 'left',
    },
    {
      title: '판매자',
      dataIndex: 'sellerName',
      render: (sellerName) => {
        return `${sellerName}`;
      },
      fixed: 'left',
    },
    {
      title: '상품명',
      render: (record) => {
        return (
          <div>
            {record?.appOnly && (
              <div
                style={{
                  padding: '4px',
                  backgroundColor: '#FF3D8F',
                  color: 'white',
                  borderRadius: '20px',
                  width: '48px',
                  fontSize: '12px',
                  textAlign: 'center',
                }}>
                앱 전용
              </div>
            )}
            <div style={{ width: '280px' }}>{record?.title}</div>
          </div>
        );
      },
      fixed: 'left',
    },
    {
      title: '금액',
      dataIndex: 'fee',
      render: (fee) => `${fee?.toLocaleString()}원`,
    },
    {
      title: '뱃지',
      dataIndex: 'badges',
      render: (badges, record) => {
        const badgeList = badges || [];

        let isNew = false;
        const firstApprovedAt = record?.approvedAt ? record.approvedAt : new Date(1990, 0, 1);
        const monthCheck = moment(new Date()).diff(moment(firstApprovedAt), 'day');
        if (monthCheck <= 30) {
          isNew = true;
        }

        const activeBadgeList = getBadges({
          badges: badgeList,
          isNew,
          isClosed: record?.status === ProductStatus.CLOSE,
          applyGroupDiscount: record?.applyGroupDiscount,
        });

        return activeBadgeList.map((item, index) => {
          return (
            <div
              key={`badge-${index}`}
              style={{
                display: 'flex',
                marginRight: '4px',
                paddingLeft: '4px',
                paddingRight: '4px',
                height: '18px',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '4px',
                backgroundColor: item.backgroundColor,
                marginBottom: '4px',
                width: '48px',
              }}>
              <div style={{ fontSize: '11px', color: item.color, fontWeight: 500 }}>{item.value}</div>
            </div>
          );
        });
      },
    },
    {
      title: '상태',
      render: (record: ProductModel) => {
        return (
          <div>
            <p
              style={{
                width: '60px',
                height: '30px',
                borderRadius: '8px',
                color: PRODUCT_STATUS_TEXT[record.status as ProductStatus]?.textColor || '#242424',
                backgroundColor: PRODUCT_STATUS_TEXT[record.status as ProductStatus]?.backgroundColor || 'white',
                textAlign: 'center',
                alignContent: 'center',
              }}>
              {PRODUCT_STATUS_TEXT[record.status as ProductStatus]?.text || '-'}
            </p>
            <p
              style={{
                borderRadius: '8px',
                width: '60px',
                height: '30px',
                color:
                  PRODUCT_APPROVE_STATUS_TEXT[record.approveStatus as ProductApproveStatus]?.textColor || '#242424',
                backgroundColor:
                  PRODUCT_APPROVE_STATUS_TEXT[record.approveStatus as ProductApproveStatus]?.backgroundColor || 'white',
                textAlign: 'center',
                alignContent: 'center',
              }}>
              {PRODUCT_APPROVE_STATUS_TEXT[record.approveStatus as ProductApproveStatus]?.text || '-'}
            </p>
          </div>
        );
      },
    },
    {
      title: '노출',
      render: (record: ProductModel) => {
        // TODO: 마이그레이션 이후 exposed 변수 삭제
        const exposed =
          record.display && (record.status === ProductStatus.OPEN || record.status === ProductStatus.CLOSE);
        const soldout = record.soldout;
        const closed = record.status === ProductStatus.CLOSE;
        return (
          <div>
            <div>
              <span>노출여부:</span>
              <span>{exposed ? '✅' : '❌'}</span>
            </div>
            <div>
              <span>판매상태:</span>
              <span>{soldout ? 'SOLD OUT' : closed ? '판매 종료' : '정상'}</span>
            </div>
            <div>
              <span>재고:</span>
              <span>{record.totalInventory === undefined ? '-' : record.totalInventory}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: '수정',
      dataIndex: '_id',
      render: (_id: string, record) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Button
              onClick={() => {
                api.getProduct(_id).then((response) => {
                  const duplicatedProduct = {
                    ...response.data,
                    _id: undefined,
                    updatedAt: undefined,
                    createdAt: undefined,
                    viewCount: 0,
                    status: ProductStatus.CLOSE,
                    approveStatus: ProductApproveStatus.REQUESTED,
                    soldout: false,
                    meta: undefined,
                    firstApprovedAt: undefined,
                    approvedAt: undefined,
                    display: false,
                    title: `[복제됨] ${response.data.title}`,
                  };
                  createProductMutation.mutate(clearEmptyKeysFromObject(duplicatedProduct));
                });
              }}>
              복제본 생성
            </Button>
            <Button
              onClick={() => {
                store.setEditProductId(_id);
                setLastEditProductId(_id);
              }}>
              수정
            </Button>
            <Button
              style={{ color: 'red' }}
              onClick={() => {
                dialog.open({
                  content: `'${record.title}' 상품을 삭제하시겠습니까?`,
                  onConfirm: () => {
                    mutationDeleteProduct.mutate(_id);
                  },
                });
              }}>
              삭제하기
            </Button>
          </div>
        );
      },
    },
    {
      title: '복사',
      dataIndex: '_id',
      render: (_id: string) => {
        const url = `${PRODUCT_SHARE_BASE_URL}/${_id}`;
        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(_id);
                toast.dark('상품 ID를 클립보드에 복사했습니다', {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  pauseOnFocusLoss: false,
                  draggable: true,
                  progress: undefined,
                  bodyStyle: {
                    color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                    textAlign: 'center',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                  },
                  theme: 'dark',
                });
              }}>
              ID 복사
            </Button>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(url);
                toast.dark('주소를 클립보드에 복사했습니다', {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  pauseOnFocusLoss: false,
                  draggable: true,
                  progress: undefined,
                  bodyStyle: {
                    color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                    textAlign: 'center',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                  },
                  theme: 'dark',
                });
              }}>
              공유링크 복사
            </Button>
          </div>
        );
      },
    },
    {
      title: '미리보기',
      dataIndex: '_id',
      render: (_id: string) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Button
              onClick={() => {
                api.getProduct(_id).then((response) => {
                  setPreviewProduct(response.data);
                });
              }}>
              미리보기
            </Button>
          </div>
        );
      },
    },
    {
      title: '생성 / 수정',
      dataIndex: 'createdAt',
      render: (createdAt, record) => {
        return (
          <div style={{ width: '120px' }}>
            <div style={{ fontSize: '13px' }}>{moment(createdAt).format('YYYY.MM.DD HH:mm')}</div>
            <div style={{ fontSize: '13px', fontWeight: 'bold' }}>
              {moment(record.updatedAt).format('YYYY.MM.DD HH:mm')}
            </div>
          </div>
        );
      },
      fixed: 'right',
    },
  ];

  return (
    <div className="dashboard" style={{ position: 'relative' }}>
      <div className="page-header">
        <div>상품 관리</div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Input
            placeholder="상품 ID"
            onChange={(e) => {
              setShareLinkProductId(e.target.value);
            }}></Input>
          <button
            disabled={generateOneShareLinkMutate.isPending}
            className="link-button"
            style={{
              color: 'white',
              fontWeight: 'bold',
              width: '240px',
              display: 'flex',
              justifyContent: 'center',
              height: '40px',
              padding: '4px',
              backgroundColor: '#2259a1',
              alignItems: 'center',
              borderRadius: '6px',
            }}
            onClick={() => {
              if (shareLinkProductId) {
                generateOneShareLinkMutate.mutate(shareLinkProductId);
              }
            }}>
            {generateOneShareLinkMutate.isPending && <ClipLoader size={'20px'} color={'#ffffff'}></ClipLoader>}
            {!generateOneShareLinkMutate.isPending && '신규상품 공유링크 생성'}
          </button>
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <Modal
          open={showCreateProduct}
          onCancel={() => {
            setShowCreateProduct(false);
          }}
          footer={
            <div>
              <Button
                onClick={() => {
                  createProductMutation.mutate(newProduct);
                }}>
                만들기
              </Button>
              <Button
                onClick={() => {
                  setShowCreateProduct(false);
                }}>
                취소
              </Button>
            </div>
          }>
          <div>
            <div>
              <div style={{ fontWeight: 'bold' }}>판매방식 선택</div>
              <Select
                value={newProduct.saleType || 'commerce'}
                style={{ width: 120 }}
                onChange={(value) => {
                  setNewProduct((prevValue) => {
                    return { ...prevValue, saleType: value };
                  });
                }}
                options={[
                  { value: 'commerce', label: '커머스' },
                  { value: 'class', label: '클래스' },
                ]}
              />
            </div>
            <div style={{ marginTop: '12px' }}>
              <div style={{ fontWeight: 'bold' }}>파트너 선택</div>
              <SelectUser
                onSelect={(partner) => {
                  setNewProduct((prevValue) => {
                    return { ...prevValue, sellerId: partner._id, sellerName: partner.profile?.nickname };
                  });
                }}></SelectUser>
            </div>
            <div style={{ marginTop: '12px' }}>
              <div style={{ fontWeight: 'bold' }}>상품 제목</div>
              <Input
                onChange={(e) => {
                  setNewProduct((prevValue) => {
                    return { ...prevValue, title: e.target.value };
                  });
                }}></Input>
            </div>
          </div>
        </Modal>
        <div className="page-content" style={{ width: '100%', height: 'calc(100vh - 80px)', overflow: 'auto' }}>
          <div style={{ display: 'flex', gap: '12px', width: '100%', height: '52px', alignItems: 'center' }}>
            <Select
              value={store.searchQuery.saleType || 'all'}
              style={{ width: 120 }}
              onChange={(value) => {
                store.updateSearchQuery({ saleType: value });
              }}
              options={[
                { value: 'all', label: '상품타입' },
                { value: 'commerce', label: '커머스' },
                { value: 'class', label: '클래스' },
                { value: 'commerce-naver', label: '커머스-네이버' },
              ]}
            />
            <Select
              value={store.searchQuery.status || ''}
              style={{ width: 120 }}
              onChange={(value) => {
                store.updateSearchQuery({ status: value });
              }}
              options={[
                { value: '', label: '판매 상태' },
                { value: ProductStatus.OPEN, label: '판매중' },
                { value: ProductStatus.CLOSE, label: '판매중지' },
                { value: ProductStatus.PRIVATE, label: '프라이빗' },
              ]}
            />
            <Select
              value={store.searchQuery.approveStatus || ''}
              style={{ width: 120 }}
              onChange={(value) => {
                store.updateSearchQuery({ approveStatus: value });
              }}
              options={[
                { value: '', label: '승인 상태' },
                { value: ProductApproveStatus.ACCEPTED, label: '승인 완료' },
                { value: ProductApproveStatus.REQUESTED, label: '승인 요청' },
                { value: ProductApproveStatus.DENIED, label: '승인 반려' },
                { value: ProductApproveStatus.NOT_READY, label: '수정중' },
              ]}
            />
            <Select
              value={store.searchQuery.stockStatus || 'all'}
              style={{ width: 120 }}
              onChange={(value) => {
                store.updateSearchQuery({ stockStatus: value });
              }}
              options={[
                { value: 'all', label: '재고상태' },
                { value: 'in_stock', label: '재고 있음' },
                { value: 'soldout', label: '재고 없음' },
              ]}
            />
            <Select
              value={store.searchQuery.displayStatus || 'all'}
              style={{ width: 120 }}
              onChange={(value) => {
                store.updateSearchQuery({ displayStatus: value });
              }}
              options={[
                { value: 'all', label: '노출상태' },
                { value: 'open', label: '노출' },
                { value: 'close', label: '노출X' },
              ]}
            />
            <Select
              value={store.searchQuery.sortType || 'none'}
              style={{ width: 140 }}
              onChange={(value) => {
                store.updateSearchQuery({ sortType: value });
              }}
              options={[
                { value: 'none', label: '기본 정렬' },
                { value: 'createdAt_desc', label: '생성 내림차순' },
                { value: 'createdAt_asc', label: '생성 오름차순' },
                { value: 'updatedAt_desc', label: '업데이트 내림차순' },
                { value: 'updatedAt_asc', label: '업데이트 오름차순' },
                { value: 'title_desc', label: '이름 내림차순' },
                { value: 'title_asc', label: '이름 오름차순' },
              ]}
            />
            <Input
              ref={searchInputRef}
              style={{ width: '240px' }}
              value={searchKeyword}
              placeholder={'검색어 입력 후 엔터'}
              onChange={(e) => {
                setSearchKeyword(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.code === 'Enter' && searchKeyword) {
                  store.updateSearchQuery({ keyword: searchKeyword });
                }
              }}></Input>
            <Button
              onClick={() => {
                setSearchKeyword(undefined);
                store.resetSearchQuery();
              }}>
              검색 초기화
            </Button>
            <Checkbox
              checked={store.searchQuery?.appOnly}
              onChange={() => {
                const appOnly = store.searchQuery?.appOnly ? undefined : true;
                store.updateSearchQuery({ appOnly });
              }}>
              앱 전용 상품
            </Checkbox>

            <div style={{ display: 'flex', marginLeft: 'auto', gap: '12px', alignItems: 'center' }}>
              <Button
                onClick={() => {
                  let saleType = store.searchQuery.saleType || 'class';
                  if (saleType === 'all') {
                    saleType = 'class';
                  }
                  setShowCreateProduct(true);
                  setNewProduct({
                    status: ProductStatus.CLOSE,
                    approveStatus: ProductApproveStatus.REQUESTED,
                    saleType: saleType,
                  });
                }}>
                신규 상품 추가
              </Button>
              <Button
                onClick={async () => {
                  const fileName = `igogo-products-${moment().format('YYYYMMDD-HHmmss')}.xlsx`;
                  const blob = await getProductsExcelFile(store.searchQuery);
                  if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
                    // IE variant
                    (window.navigator as any).msSaveOrOpenBlob(new Blob(blob.data), fileName);
                  } else {
                    const url = window.URL.createObjectURL(blob.data);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode && link.parentNode.removeChild(link);
                  }
                }}>
                결과 엑셀 파일 다운로드
              </Button>
            </div>
          </div>
          <Table
            columns={TABLE_COLUMNS}
            rowClassName={(record) => {
              return record._id === lastEditProductId ? 'selected-row' : '';
            }}
            scroll={{ x: 'max-content', y: 'calc(100dvh - 280px)' }}
            style={{ whiteSpace: 'pre-wrap', backgroundColor: 'transparent' }}
            rowKey={(record) => record?._id}
            dataSource={store.searchResultContainer.contents.filter((product) => product?._id)}
            pagination={{
              current: (store.searchResultContainer.page || 0) + 1,
              pageSize: store.searchResultContainer.size || 20,
              total: store.searchResultContainer.total,
            }}
            loading={productSearchQuery.isLoading}
            onChange={(e) => {
              store.updateSearchQuery({ page: (e.current || 2) - 1, size: e.pageSize || 20 });
            }}
          />
        </div>
        {store.editProductId && (
          <ProductEditView
            productId={store.editProductId}
            onClose={() => {
              store.setEditProductId(undefined);
            }}
            needRefetch={() => {
              productSearchQuery.refetch();
            }}
          />
        )}
        {previewProduct && (
          <div
            style={{
              width: '428px',
              height: '100vh',
              position: 'fixed',
              top: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'white',
              zIndex: 900,
              borderLeft: '1px solid #eaeaea',
            }}>
            <div style={{ height: '44px', backgroundColor: '#eaeaea', display: 'flex', alignItems: 'center' }}>
              <Button
                onClick={() => {
                  setPreviewProduct(undefined);
                }}>
                닫기
              </Button>
            </div>
            <ProductDetailView product={previewProduct}></ProductDetailView>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductsPage;
