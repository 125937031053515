import TutorDefaultAvatar from '@assets/images/app/tutorintro.png';
import { Carousel } from '@components/common/Carousel';
import { cdnImageUrl } from '@utils/image';
import React from 'react';
import { MainFeedCellProps } from '.';

const TutorItem: React.FC<{ item: any }> = (props) => {
  const item = props.item;
  const tutorCategory =
    item.profile.categorySubList &&
    item.profile.categorySubList
      .map((item: any, index: number) => (
        <div
          key={`tutor-category-${index}`}
          style={{
            fontSize: '12px',
            lineHeight: '16px',
            color: 'white',
            fontWeight: 'bold',
          }}>
          #{item}
        </div>
      ))
      .slice(0, 2);

  return (
    <div
      id="tutor-on-cell"
      style={{ backgroundColor: '#242424', marginRight: 0, marginLeft: '12px', cursor: 'pointer' }}>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ display: 'flex', width: '64px', height: '64px', justifyContent: 'center', position: 'relative' }}>
          <img
            src={item.profile.imageUrl ? cdnImageUrl(item.profile.imageUrl) : TutorDefaultAvatar}
            style={{ width: '64px', height: '64px', borderRadius: '50%', objectFit: 'cover' }}
          />
          <div
            style={{
              display: 'flex',
              width: '16px',
              height: '16px',
              backgroundColor: '#242424',
              borderRadius: '32px',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              bottom: 0,
              right: 0,
            }}>
            <div
              style={{
                width: '10px',
                height: '10px',
                backgroundColor: '#00e8b1',
                borderRadius: '26px',
              }}>
              <div
                style={{
                  width: '10px',
                  height: '10px',
                  borderRadius: '32px',
                  backgroundColor: item.profile && item.profile.active ? '' : 'rgba(36, 36 ,36, 0.4)',
                }}
              />
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div
            style={{
              fontSize: '12px',
              color: 'rgba(225,225,225, .8)',
              marginTop: '10px',
              marginBottom: '6px',
              width: '64px',
              wordBreak: 'break-word',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textAlign: 'center',
            }}>
            {item.profile.nickname}
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
            }}>
            {tutorCategory && tutorCategory}
          </div>
        </div>
      </div>
    </div>
  );
};

export const TutorOnCell: React.FC<MainFeedCellProps> = (props) => {
  const data = props.data || [];
  return (
    <div>
      <div
        style={{
          height: '267px',
          width: '100%',
          paddingTop: '32px',
          paddingBottom: '32px',
          backgroundColor: '#242424',
        }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            paddingBottom: '8px',
            marginLeft: '12px',
          }}>
          <div
            style={{
              color: 'white',
              lineHeight: '24px',
              fontSize: '19px',
              fontWeight: 'bold',
              marginRight: '4px',
            }}>
            튜터 ON
          </div>
          <div
            style={{
              width: '8px',
              height: '8px',
              backgroundColor: '#ff5e5e',
              borderRadius: '16px',
            }}
          />
        </div>
        <div
          style={{
            color: 'rgba(255,255,255, 0.8)',
            paddingBottom: '24px',
            lineHeight: '16px',
            fontSize: '13px',
            marginLeft: '12px',
          }}>
          최근 활발히 접속중인 튜터님 바로보기
        </div>
        <div
          style={{
            backgroundColor: '#242424',
          }}>
          <Carousel
            options={{
              dragFree: true,
              align: 'start',
            }}>
            {data.map((item: any, index: number) => {
              return (
                <div key={`tutor-on-item-${index}`} className={'embla__slide'} style={{ flex: `0 0 80px` }}>
                  <TutorItem item={item}></TutorItem>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default TutorOnCell;
