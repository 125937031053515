import { useAuthentication } from '@hooks/authentication';
import { SalesChannelOrder } from '@models/sales-channels';
import { useNaverOrdersStore } from '@stores/naverOrdersStore';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Checkbox, DatePicker, DatePickerProps, Input, Modal, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import * as api from '@apis/sales-channels';
import FileUploadButton from '@components/common/FileUploadButton';
import { useAuthStore } from '@stores/authStore';
import { Roles } from '@consts/role';
import * as XLSX from 'xlsx';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { useContentLoaderStore } from '@stores/contentLoaderStore';
import { useAppSocket } from '@hooks/appSocket';
import { SelectUser } from '@components/product/SelectUser';
import { AdminUser } from '@models/auth';
import { localTimeToUtcISOString, utcTimcToLocalISOString } from '@utils/date';
import dayjs from 'dayjs';
import { formatPhoneNumber } from '@utils/dataTransform';
import useAddAccountTransferModalStore from '@stores/addAccountTransferModalStore';
import { PaymentStatementSettlementStatus } from '@models/paymentStatement';

export const REFUND_STATUSES: { [key: string]: any } = {
  all: (
    <div className="badge" style={{ backgroundColor: '#bd334a' }}>
      전체 환불
    </div>
  ),
  partial: (
    <div className="badge" style={{ backgroundColor: '#d944db' }}>
      부분 환불
    </div>
  ),
  requested: (
    <div className="badge" style={{ backgroundColor: '#db9737' }}>
      환불 요청
    </div>
  ),
  accepted: (
    <div className="badge" style={{ backgroundColor: '#2782b3' }}>
      환불 접수
    </div>
  ),
  denied: (
    <div className="badge" style={{ backgroundColor: '#6e6e6e' }}>
      환불 반려
    </div>
  ),
  none: '-',
};

export const NaverOrdersPage: React.FC = () => {
  const [editItem, setEditItem] = useState<SalesChannelOrder>();
  const [showChangeStatus, setShowChangeStatus] = useState<boolean>(false);
  const [showSelectUser, setShowSelectUser] = useState<boolean>(false);
  const [selectedPartner, setSelectedPartner] = useState<AdminUser>();
  const [selectedChangeStatus, setSelectedChangeStatus] = useState<string>('none');
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [searchKeyword, setSearchKeyword] = useState<string>();
  const searchInputRef = useRef<any>();
  const authStore = useAuthStore();
  const dialog = useOkCancelDialog();
  const store = useNaverOrdersStore();
  const loader = useContentLoaderStore();
  const appSocket = useAppSocket();
  const addAccountTransferModalStore = useAddAccountTransferModalStore();

  const selectedIdsCount = (selectedIds || []).filter((id) => !!id).length;

  useAuthentication();

  useEffect(() => {
    if (!searchKeyword && store.searchQuery.keyword) {
      setSearchKeyword(store.searchQuery.keyword);
    }
  }, [store.searchQuery.keyword]);

  const naverOrdersQuery = useQuery({
    queryKey: [`naver-orders`, JSON.stringify(store.searchQuery), addAccountTransferModalStore.show],
    queryFn: () => api.searchNaverOrders(store.searchQuery),
  });

  const deleteOrdersMutation = useMutation({
    mutationFn: api.deleteNaverOrders,
    onSuccess: async () => {
      setShowSelectUser(false);
      setSelectedPartner(undefined);
      const content = `삭제했습니다`;
      dialog.open({
        content: content,
        type: 'ok',
      });

      naverOrdersQuery.refetch();
    },
    onError: async (e: any) => {
      dialog.open({
        title: '오류',
        content: e?.response?.data?.message,
        type: 'ok',
      });
    },
  });

  const sendKakaoNotificationMutation = useMutation({
    mutationFn: api.sendKakaoNotification,
    onSuccess: async (response) => {
      setShowSelectUser(false);
      setSelectedPartner(undefined);
      const content = `알림톡을 발송했습니다.\n전체: ${response.data.total}\n요청: ${response.data.request}`;
      dialog.open({
        content: content,
        type: 'ok',
      });

      naverOrdersQuery.refetch();
    },
    onError: async (e: any) => {
      dialog.open({
        title: '오류',
        content: e?.response?.data?.message,
        type: 'ok',
      });
    },
  });

  const sendNaverOrdersSummaryKakaoNotificationMutation = useMutation({
    mutationFn: api.sendNaverOrdersSummaryKakaoNotification,
    onSuccess: async (response) => {
      const result: { targets: any[]; success: number; failed: number; successIds: string[]; failedIds: string[] } =
        response.data;
      dialog.open({
        title: '발송 완료',
        content: `${result.targets.length}개 대상\n${result.success} 성공\n${result.failed} 실패`,
        type: 'ok',
      });

      naverOrdersQuery.refetch();
    },
    onError: async (e: any) => {
      dialog.open({
        title: '오류',
        content: e?.response?.data?.message,
        type: 'ok',
      });
    },
  });

  const updatePaymentAmountMutation = useMutation({
    mutationFn: api.updateSalesChannelOrderPaymentAmount,
    onSuccess: async (response) => {
      dialog.open({
        title: '업데이트 완료',
        content: `${response.data.total}개 대상\n${response.data.updated} 업데이트`,
        type: 'ok',
      });

      naverOrdersQuery.refetch();
    },
    onError: async (e: any) => {
      dialog.open({
        title: '오류',
        content: e?.response?.data?.message,
        type: 'ok',
      });
    },
  });

  const sendRefundsKakaoNotificationMutation = useMutation({
    mutationFn: api.sendRefundsKakaoNotification,
    onSuccess: async (response) => {
      const result: { targets: any[]; success: number; failed: number } = response.data;
      dialog.open({
        title: '발송 완료',
        content: `${result.targets.length}개 대상\n${result.success} 성공\n${result.failed} 실패`,
        type: 'ok',
      });

      naverOrdersQuery.refetch();
    },
    onError: async (e: any) => {
      dialog.open({
        title: '오류',
        content: e?.response?.data?.message,
        type: 'ok',
      });
    },
  });

  const updateNaverOrderMutation = useMutation({
    mutationFn: api.updateSalesChannelOrders,
    onSuccess: async () => {
      setEditItem(undefined);
      setShowSelectUser(false);
      setSelectedPartner(undefined);
      setShowChangeStatus(false);
      dialog.open({
        content: '수정했습니다.',
        type: 'ok',
      });

      naverOrdersQuery.refetch();
    },
    onError: async (e: any) => {
      dialog.open({
        title: '오류',
        content: e?.response?.data?.message,
        type: 'ok',
      });
    },
  });

  const naverOrders = naverOrdersQuery.data?.data;

  useEffect(() => {
    if (naverOrders) {
      store.setNaverOrdersContainer(naverOrders);
    }
  }, [naverOrders]);

  useEffect(() => {
    appSocket.socket.addSocketEventListener('upload_excel_status', 'upload_excel_status', (data) => {
      const status = (data.status || '').toLowerCase();
      if (status === 'done') {
        naverOrdersQuery.refetch();
      }
      if (status === 'done' || status === 'error') {
        loader.off();
      }

      if (data.status) {
        toast.error(data.message, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    });
  }, []);

  const onChangeStartDate: DatePickerProps['onChange'] = (date) => {
    store.setStartDate(localTimeToUtcISOString(date ? new Date(date.toISOString()) : new Date(), { timeToZero: true }));
  };

  const onChangeEndDate: DatePickerProps['onChange'] = (date) => {
    store.setEndDate(localTimeToUtcISOString(date ? new Date(date.toISOString()) : new Date(), { timeToZero: true }));
  };

  const TABLE_COLUMNS: ColumnsType<SalesChannelOrder> = [
    {
      fixed: 'left',
      title: () => {
        return (
          <Checkbox
            checked={
              JSON.stringify(selectedIds.sort()) ===
              JSON.stringify(
                (store.searchResultContainer.contents || [])
                  .filter((order) => !!order)
                  .map((order) => order._id)
                  .sort(),
              )
            }
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedIds(
                  (store.searchResultContainer.contents || []).filter((order) => !!order).map((order) => order._id),
                );
              } else {
                setSelectedIds([]);
              }
            }}></Checkbox>
        );
      },
      dataIndex: '_id',
      width: 60,
      render: (_id) => {
        return (
          <Checkbox
            onChange={(e) => {
              setSelectedIds((values) => {
                const checked = e.target.checked;
                const newValues = [...values];
                if (checked) {
                  if (!newValues.includes(_id)) {
                    newValues.push(_id);
                  }
                  return newValues;
                } else {
                  return newValues.filter((value) => value !== _id);
                }
              });
            }}
            checked={selectedIds.includes(_id)}></Checkbox>
        );
      },
    },
    {
      fixed: 'left',
      title: 'ID',
      render: (record: SalesChannelOrder) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(record?._id);
                toast.dark('ID를 클립보드에 복사했습니다', {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  pauseOnFocusLoss: false,
                  draggable: true,
                  progress: undefined,
                  bodyStyle: {
                    color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                    textAlign: 'center',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                  },
                  theme: 'dark',
                });
              }}>
              ID
            </Button>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(record?.uid);
                toast.dark('상품주문번호를 클립보드에 복사했습니다', {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  pauseOnFocusLoss: false,
                  draggable: true,
                  progress: undefined,
                  bodyStyle: {
                    color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                    textAlign: 'center',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                  },
                  theme: 'dark',
                });
              }}>
              상품주문번호
            </Button>
          </div>
        );
      },
    },
    {
      fixed: 'left',
      align: 'center',
      title: '환불',
      width: 120,
      render: (record: SalesChannelOrder) => {
        return (
          <div>
            <div>{REFUND_STATUSES[record?.statuses?.refund] || '-'}</div>
            <div>{record?.memo?.refund}</div>
          </div>
        );
      },
    },
    {
      fixed: 'left',
      title: '구매자(수취인)',
      render: (record: SalesChannelOrder) => {
        let name = record?.buyer?.name;
        let phoneNumber = formatPhoneNumber(record?.buyer?.phone);
        if (record?.buyer?.name !== record?.receiver?.name) {
          name = `${record?.buyer?.name}(${record?.receiver?.name})`;
        }

        if (record?.buyer?.phone?.replace(/-/g, '') !== record?.receiver?.phone?.replace(/-/g, '')) {
          phoneNumber = `${formatPhoneNumber(record?.buyer?.phone)}(${formatPhoneNumber(record?.receiver?.phone)})`;
        }
        return `${name}\n${phoneNumber}`;
      },
    },
    {
      fixed: 'left',
      title: '정산일',
      render: (record: SalesChannelOrder) => {
        const settlementStatus = record?.statuses?.settlement;
        if (settlementStatus === 'done') {
          const transferAt = record?.settlement?.transferAt;
          return `${moment(transferAt).format('YYYY.MM.DD HH:mm')}`;
        }
        return '-';
      },
    },
    {
      title: '판매자',
      render: (record: SalesChannelOrder) => {
        return <div>{`${record?.seller?.name} ${record?.seller?.id ? '✅' : ''} (${record?.sellerUser?.role})`}</div>;
      },
    },
    {
      title: '상품명',
      width: 400,
      render: (record: SalesChannelOrder) => {
        return record?.product?.name;
      },
    },
    {
      title: '수업일',
      width: 112,
      render: (record: SalesChannelOrder) => {
        if (!record?.plannedDate) {
          return '-';
        }
        return moment(record?.plannedDate).format('YYYY.MM.DD HH:mm');
      },
    },
    {
      title: '수정',
      render: (record: SalesChannelOrder) => {
        return (
          <Button
            onClick={() => {
              setEditItem(record);
            }}>
            수정
          </Button>
        );
      },
    },
    {
      title: '옵션',
      render: (record: SalesChannelOrder) => {
        return record?.purchase?.name;
      },
    },
    {
      title: '메모',
      width: 240,
      render: (record: SalesChannelOrder) => {
        return record?.memo?.payment || record?.memo?.bankAccountTransfer;
      },
    },
    {
      title: '고객 요청사항',
      width: 240,
      render: (record: SalesChannelOrder) => {
        return record?.memo?.shipping;
      },
    },
    {
      title: '수량',
      width: 80,
      render: (record: SalesChannelOrder) => {
        return record?.purchase?.count;
      },
    },
    {
      title: '실결제금액',
      width: 100,
      render: (record: SalesChannelOrder) => {
        return `${record?.payment?.billingAmount.toLocaleString()}원`;
      },
    },
    {
      title: '정산대상금액',
      width: 108,
      render: (record: SalesChannelOrder) => {
        return (
          <div>
            <div>{`${record?.payment?.amount.toLocaleString()}원`}</div>
            {record?.refund?.amount && record?.refund.amount > 0 && (
              <div style={{ color: 'red' }}>{`-${record?.refund?.amount.toLocaleString()}원`}</div>
            )}
            {record?.payment?.bankAccountTransferAmount && (
              <div
                style={{
                  color: '#424242',
                }}>{`(계좌이체: ${record?.payment?.bankAccountTransferAmount?.toLocaleString()}원)`}</div>
            )}
            {/* {record?.payment?.expectedAmount && (
              <div style={{ color: '#aaaaaa' }}>{`${record?.payment?.expectedAmount?.toLocaleString()}원`}</div>
            )} */}
          </div>
        );
      },
    },
    {
      title: '알림톡 발송',
      width: 280,
      render: (record: SalesChannelOrder) => {
        let confirmPurchaseNotificationDate = '-';
        let purchaseNotificationDate = '-';
        let refundNotificationDate = '-';
        if (record?.confirmPurchaseNotificationDate) {
          confirmPurchaseNotificationDate = moment(record?.confirmPurchaseNotificationDate).format('YYYY.MM.DD HH:mm');
        }

        if (record?.purchaseNotificationDate) {
          purchaseNotificationDate = moment(record?.purchaseNotificationDate).format('YYYY.MM.DD HH:mm');
        }

        if (record?.refundNotificationDate) {
          refundNotificationDate = moment(record?.refundNotificationDate).format('YYYY.MM.DD HH:mm');
        }

        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <div style={{ display: 'flex', gap: '8px' }}>
              <div>구매 확인(구매자)</div>
              <div>{confirmPurchaseNotificationDate}</div>
            </div>
            <div style={{ display: 'flex', gap: '8px' }}>
              <div>구매 알림(판매자)</div>
              <div>{purchaseNotificationDate}</div>
            </div>
            <div style={{ display: 'flex', gap: '8px' }}>
              <div>환불 알림(판매자)</div>
              <div>{refundNotificationDate}</div>
            </div>
          </div>
        );
      },
    },
    {
      title: '계좌이체 금액 추가',
      render: (record: SalesChannelOrder) => {
        return (
          <Button
            onClick={() => {
              addAccountTransferModalStore.open({
                _id: record._id,
                memo: record?.memo,
                payment: record?.payment,
                buyer: record?.buyer,
                product: record.product,
                channel: record.channel,
              });
            }}>
            추가
          </Button>
        );
      },
    },
    {
      title: '정산상태',
      render: (data: SalesChannelOrder) => {
        if (!data) {
          return '-';
        }
        const settlementStatus = (data.statuses?.settlement || '').toLowerCase();
        let status = '-';
        if (settlementStatus === 'done' && data?.settlement?.transferAt) {
          const transperAt = moment(data?.settlement?.transferAt).format('YYYY-MM-DD HH:mm');
          status = `정산완료\n(${transperAt})`;
        }
        return status;
      },
      width: 84,
    },
    {
      title: '결제일',
      width: 112,
      render: (record: SalesChannelOrder) => {
        if (!record?.payment?.paidAt) {
          return '-';
        }
        return moment(record?.payment?.paidAt).format('YYYY.MM.DD HH:mm');
      },
    },
    {
      title: '생성',
      dataIndex: 'createdAt',
      render: (createdAt) => {
        return createdAt ? moment(createdAt).format('YYYY.MM.DD HH:mm') : '-';
      },
    },
    {
      title: '업데이트',
      dataIndex: 'updatedAt',
      render: (updatedAt) => {
        return updatedAt ? moment(updatedAt).format('YYYY.MM.DD HH:mm') : '-';
      },
    },
  ];

  const getExcelFile = async (type: 'default' | 'settlement' = 'default') => {
    const fileName = `igogo-naver-${type}-${moment().format('YYYYMMDD-HHmmss')}.xlsx`;
    let blob = undefined;

    if (type === 'settlement') {
      blob = await api.getSalesChannelExcelFileForSettlement(store.searchQuery);
    } else {
      blob = await api.getSalesChannelExcelFile(store.searchQuery);
    }

    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
      (window.navigator as any).msSaveOrOpenBlob(new Blob(blob.data), fileName);
    } else {
      const url = window.URL.createObjectURL(blob.data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode && link.parentNode.removeChild(link);
    }
  };

  const loadExcelFile = async (file: File, type: 'naver_store_orders' | 'naver_store_refunds') => {
    const fileReader = await new FileReader();
    fileReader.readAsArrayBuffer(file);

    fileReader.onload = (e: any) => {
      const bufferArray = e?.target.result;
      try {
        XLSX.read(bufferArray);
        if (authStore.user && authStore.user?.role === Roles.ADMIN) {
          appSocket.uploadExcelFile({
            sender: {
              userId: authStore.user?.id,
              role: authStore.user?.role,
            },
            data: { type: type, file: file },
          });
        }
      } catch (e: any) {
        if (e.message === 'File is password-protected') {
          dialog.open({ type: 'ok', title: '오류', content: '엑셀 파일의 암호를 해제 후 업로드해주세요' });
        } else {
          dialog.open({ type: 'ok', title: '오류', content: e });
        }
        loader.off();
      }
    };
  };

  return (
    <div className="dashboard" style={{ position: 'relative' }}>
      <Modal
        width={600}
        open={!!editItem}
        onCancel={() => {
          setEditItem(undefined);
        }}
        footer={
          <div>
            <Button
              onClick={() => {
                setEditItem(undefined);
              }}>
              취소
            </Button>
            <Button
              style={{ color: 'red' }}
              onClick={() => {
                if (editItem) {
                  updateNaverOrderMutation.mutate({
                    channel: 'naver',
                    targetIds: [editItem._id],
                    data: {
                      memo: editItem.memo,
                      statuses: editItem.statuses,
                      receiver: editItem.receiver,
                      buyer: editItem.buyer,
                      refund: editItem.refund,
                      payment: editItem.payment,
                      purchase: editItem.purchase,
                    },
                  });
                }
              }}>
              저장하기
            </Button>
          </div>
        }>
        <div>
          <div style={{ height: '44px', fontSize: '16px', fontWeight: 'bold' }}>판매 내역 수정</div>
          <div style={{ marginTop: '8px' }}>
            <div style={{ fontWeight: 'bold' }}>구매자</div>
            <div style={{ display: 'flex', gap: '8px' }}>
              <Input
                style={{ flex: 1 }}
                value={editItem?.buyer.name}
                onChange={(e) => {
                  setEditItem((prev) => {
                    return { ...prev, buyer: { ...prev?.buyer, name: e.target.value } } as SalesChannelOrder;
                  });
                }}></Input>
              <Input
                style={{ flex: 2 }}
                value={editItem?.buyer.phone}
                onChange={(e) => {
                  setEditItem((prev) => {
                    return { ...prev, buyer: { ...prev?.buyer, phone: e.target.value } } as SalesChannelOrder;
                  });
                }}></Input>
            </div>
          </div>
          <div style={{ marginTop: '8px' }}>
            <div style={{ fontWeight: 'bold' }}>수취인</div>
            <div style={{ display: 'flex', gap: '8px' }}>
              <Input
                style={{ flex: 1 }}
                value={editItem?.receiver.name}
                onChange={(e) => {
                  setEditItem((prev) => {
                    return { ...prev, receiver: { ...prev?.receiver, name: e.target.value } } as SalesChannelOrder;
                  });
                }}></Input>
              <Input
                style={{ flex: 2 }}
                value={editItem?.receiver.phone}
                onChange={(e) => {
                  setEditItem((prev) => {
                    return { ...prev, receiver: { ...prev?.receiver, phone: e.target.value } } as SalesChannelOrder;
                  });
                }}></Input>
            </div>
          </div>
          <div style={{ marginTop: '8px' }}>
            <div style={{ fontWeight: 'bold' }}>메모</div>
            <Input.TextArea
              value={editItem?.memo.payment}
              onChange={(e) => {
                setEditItem((prev) => {
                  return { ...prev, memo: { ...prev?.memo, payment: e.target.value } } as SalesChannelOrder;
                });
              }}></Input.TextArea>
          </div>
          <div style={{ marginTop: '8px' }}>
            <div style={{ fontWeight: 'bold' }}>고객 요청사항</div>
            <Input.TextArea
              value={editItem?.memo.shipping}
              onChange={(e) => {
                setEditItem((prev) => {
                  return { ...prev, memo: { ...prev?.memo, shipping: e.target.value } } as SalesChannelOrder;
                });
              }}></Input.TextArea>
          </div>
          <div style={{ marginTop: '8px' }}>
            <div style={{ fontWeight: 'bold' }}>정산대상금액</div>
            <Input
              value={editItem?.payment.amount}
              onChange={(e) => {
                setEditItem((prev) => {
                  return {
                    ...prev,
                    payment: { ...prev?.payment, amount: Number(e.target.value) || 0 },
                  } as SalesChannelOrder;
                });
              }}></Input>
          </div>
          <div style={{ marginTop: '8px' }}>
            <div style={{ fontWeight: 'bold' }}>옵션</div>
            <div style={{ display: 'flex', gap: '8px' }}>
              <Input
                style={{ flex: 9 }}
                value={editItem?.purchase.name}
                onChange={(e) => {
                  setEditItem((prev) => {
                    return {
                      ...prev,
                      purchase: { ...prev?.purchase, name: e.target.value },
                    } as SalesChannelOrder;
                  });
                }}></Input>
              <Input
                style={{ flex: 1 }}
                value={editItem?.purchase.count}
                onChange={(e) => {
                  setEditItem((prev) => {
                    return {
                      ...prev,
                      purchase: { ...prev?.purchase, count: Number(e.target.value) || 0 },
                    } as SalesChannelOrder;
                  });
                }}></Input>
            </div>
          </div>
          <div style={{ marginTop: '8px' }}>
            <div style={{ fontWeight: 'bold' }}>환불</div>
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
              <div style={{ width: '40px', flexShrink: 0 }}>상태</div>
              <Select
                style={{ width: '120px' }}
                value={editItem?.statuses.refund}
                options={[
                  { value: 'none', label: '없음' },
                  { value: 'all', label: '전체 환불' },
                  { value: 'partial', label: '부분 환불' },
                  { value: 'requested', label: '환불 요청' },
                  { value: 'accepted', label: '환불 접수' },
                  { value: 'denied', label: '환불 반려' },
                ]}
                onChange={(value) => {
                  setEditItem((prev) => {
                    return { ...prev, statuses: { ...prev?.statuses, refund: value } } as SalesChannelOrder;
                  });
                }}></Select>
            </div>
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center', marginTop: '8px' }}>
              <div style={{ width: '40px', flexShrink: 0 }}>금액</div>
              <Input
                value={editItem?.refund?.amount}
                onChange={(e) => {
                  setEditItem((prev) => {
                    return {
                      ...prev,
                      refund: { ...prev?.refund, amount: Number(e.target.value) || 0 },
                    } as SalesChannelOrder;
                  });
                }}></Input>
            </div>
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center', marginTop: '8px' }}>
              <div style={{ width: '40px', flexShrink: 0 }}>메모</div>
              <Input.TextArea
                value={editItem?.memo.refund}
                onChange={(e) => {
                  setEditItem((prev) => {
                    return { ...prev, memo: { ...prev?.memo, refund: e.target.value } } as SalesChannelOrder;
                  });
                }}></Input.TextArea>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        width={600}
        open={showChangeStatus}
        onCancel={() => {
          setShowChangeStatus(false);
        }}
        footer={
          <div>
            <Button
              onClick={() => {
                setShowChangeStatus(false);
              }}>
              취소
            </Button>
            <Button
              style={{ color: 'red' }}
              onClick={() => {
                updateNaverOrderMutation.mutate({
                  channel: 'naver',
                  targetIds: selectedIds,
                  data: {
                    statuses: { payment: 'done', refund: selectedChangeStatus },
                  },
                });
              }}>
              변경하기
            </Button>
          </div>
        }>
        <div>
          <div style={{ height: '44px' }}>환불 상태 일괄 변경</div>
          <Select
            style={{ width: '120px' }}
            value={selectedChangeStatus || 'none'}
            options={[
              { value: 'none', label: '없음' },
              { value: 'all', label: '전체 환불' },
              { value: 'partial', label: '부분 환불' },
              { value: 'requested', label: '환불 요청' },
              { value: 'accepted', label: '환불 접수' },
              { value: 'denied', label: '환불 반려' },
            ]}
            onChange={(value) => {
              setSelectedChangeStatus(value);
            }}></Select>
        </div>
      </Modal>
      <Modal
        width={600}
        open={showSelectUser}
        onCancel={() => {
          setShowSelectUser(false);
        }}
        footer={
          <div>
            <Button
              onClick={() => {
                setShowSelectUser(false);
              }}>
              취소
            </Button>
            <Button
              style={{ color: 'red' }}
              onClick={() => {
                if (!selectedPartner) {
                  dialog.open({ type: 'ok', title: '알림', content: '판매자를 선택해주세요' });
                  return;
                }

                updateNaverOrderMutation.mutate({
                  channel: 'naver',
                  targetIds: selectedIds,
                  data: {
                    seller: {
                      id: selectedPartner?._id,
                      phone: selectedPartner?.profile?.phone,
                      name: selectedPartner?.profile?.nickname,
                    },
                  },
                });
              }}>
              변경하기
            </Button>
          </div>
        }>
        <div>
          <div style={{ height: '44px' }}>판매자 선택 및 변경</div>
          <SelectUser
            style={{ width: '528px' }}
            selectedUserId={selectedPartner?._id}
            onSelect={(partner) => {
              setSelectedPartner(partner);
            }}></SelectUser>
        </div>
      </Modal>
      <div className="page-header">
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <div>네이버 스토어 판매 관리</div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <div>
            <Button
              style={{ borderColor: store.searchQuery.dateType === 'payment.paidAt' ? 'blue' : '#d9d9d9' }}
              onClick={() => {
                store.updateSearchQuery({ dateType: 'payment.paidAt' });
              }}>
              결제일
            </Button>
          </div>
          <div>
            <Button
              style={{ borderColor: store.searchQuery.dateType === 'createdAt' ? 'blue' : '#d9d9d9' }}
              onClick={() => {
                store.updateSearchQuery({ dateType: 'createdAt' });
              }}>
              생성일
            </Button>
          </div>
          <div>
            <Button
              style={{ borderColor: store.searchQuery.dateType === 'updatedAt' ? 'blue' : '#d9d9d9' }}
              onClick={() => {
                store.updateSearchQuery({ dateType: 'updatedAt' });
              }}>
              수정일
            </Button>
          </div>
          <div>
            <Button
              style={{ borderColor: store.searchQuery.dateType === 'plannedDate' ? 'blue' : '#d9d9d9' }}
              onClick={() => {
                store.updateSearchQuery({ dateType: 'plannedDate' });
              }}>
              수업일
            </Button>
          </div>
          <div style={{ marginLeft: '10px' }}>
            <DatePicker
              onChange={onChangeStartDate}
              value={dayjs(utcTimcToLocalISOString(store.searchQuery.startDate as any))}
            />
          </div>
          <div style={{ marginLeft: '8px' }}>
            <DatePicker
              onChange={onChangeEndDate}
              value={dayjs(utcTimcToLocalISOString(store.searchQuery.endDate as any))}
            />
          </div>
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <div className="page-content" style={{ width: '100%', height: 'calc(100vh - 80px)', overflow: 'auto' }}>
          <div style={{ display: 'flex', gap: '12px', width: '100%', height: '52px', alignItems: 'center' }}>
            <Select
              style={{ width: '120px' }}
              value={store.searchQuery.refundStatus || 'all_result'}
              options={[
                { value: 'all_result', label: '모든 상태' },
                { value: 'none', label: '없음' },
                { value: 'all', label: '전체 환불' },
                { value: 'partial', label: '부분 환불' },
                { value: 'requested', label: '환불 요청' },
                { value: 'accepted', label: '환불 접수' },
                { value: 'denied', label: '환불 반려' },
              ]}
              onChange={(value) => {
                store.updateSearchQuery({ refundStatus: value });
              }}></Select>
            <Select
              defaultValue={store.searchQuery.settlementStatus || ''}
              style={{ width: '120px', marginRight: '10px' }}
              onChange={(value) => {
                store.updateSearchQuery({ settlementStatus: value });
              }}
              options={[
                { value: '', label: '전체' },
                { value: PaymentStatementSettlementStatus.DONE, label: '정산완료' },
              ]}
            />
            <Select
              style={{ width: '240px' }}
              value={store.searchQuery.type || 'all'}
              options={[
                { value: 'all', label: '모두' },
                { value: 'none', label: '발송내역 없음' },
                { value: 'confirm_purchase_send', label: '구매 확인 알림 발송(구매자)' },
                { value: 'confirm_purchase_not_send', label: '구매 확인 알림 발송 X(구매자)' },
                { value: 'purchase_send', label: '구매 알림 발송' },
                { value: 'purchase_not_send', label: '구매 알림 발송 X' },
                { value: 'refund_send', label: '환불 알림 발송' },
                { value: 'refund_not_send', label: '환불 알림 발송 X' },
                { value: 'payment_amount_zero', label: '정산대상 금액 0원' },
                { value: 'payment_diff_500', label: '정산대상 금액 차이 500원 초과' },
                { value: 'seller_id_null', label: '판매자 매칭 안됨' },
                { value: 'invalid_phone_number', label: '전화번호 없음' },
              ]}
              onChange={(value) => {
                store.updateSearchQuery({ type: value });
              }}></Select>
            <Select
              placeholder={'검색 타입'}
              style={{ width: '140px' }}
              value={store.searchQuery.keywordType || 'all'}
              options={[
                { value: 'all', label: '전체' },
                { value: 'buyer.phone', label: '구매자 전화번호' },
                { value: 'buyer.name', label: '구매자 이름' },
                { value: 'receiver.phone', label: '수취인 전화번호' },
                { value: 'receiver.name', label: '수취인 이름' },
                { value: 'product.name', label: '상품 이름' },
                { value: 'purchase.name', label: '옵션 이름' },
                { value: 'seller.name', label: '판매자 이름' },
                { value: 'memo.refund', label: '환불 메모' },
                { value: 'memo.payment', label: '구매 메모' },
                { value: 'memo.shipping', label: '배송 메모' },
                { value: 'payment.amount', label: '정산대상금액' },
              ]}
              onChange={(value) => {
                store.updateSearchQuery({ keywordType: value });
              }}></Select>
            <Input
              ref={searchInputRef}
              style={{ width: '240px' }}
              value={searchKeyword}
              placeholder={'검색어 입력 후 엔터'}
              onChange={(e) => {
                setSearchKeyword(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.code === 'Enter' && searchKeyword) {
                  store.updateSearchQuery({ keyword: searchKeyword });
                }
              }}></Input>
            <Button
              onClick={() => {
                setSelectedIds([]);
                setSearchKeyword(undefined);
                store.resetSearchQuery();
              }}>
              검색 초기화
            </Button>
            <div>{`결과: ${store.searchResultContainer?.total || 0}개`}</div>
            <div style={{ display: 'flex', marginLeft: 'auto', gap: '12px', alignItems: 'center' }}>
              <div style={{ width: '68px', textAlign: 'right', fontSize: '12px', lineHeight: '12px' }}>
                네이버 스토어 엑셀 파일
              </div>
              <FileUploadButton
                className="ant-btn ant-btn-default"
                style={{
                  fontSize: '14px',
                  height: '32px',
                  padding: '4px 15px',
                  borderRadius: '6px',
                  backgroundColor: '#ffffff',
                  border: '1px solid #d9d9d9',
                  color: 'rgba(0, 0, 0, 0.88)',
                  boxShadow: '0 2px 0 rgba(0, 0, 0, 0.02)',
                  cursor: 'pointer',
                }}
                onChange={(e) => {
                  if (e.target.files) {
                    loader.on();
                    const files = e.target.files;
                    loadExcelFile(files[0], 'naver_store_orders');
                  }
                }}>
                {'판매 내역 업로드'}
              </FileUploadButton>
              <FileUploadButton
                className="ant-btn ant-btn-default"
                style={{
                  fontSize: '14px',
                  height: '32px',
                  padding: '4px 15px',
                  borderRadius: '6px',
                  backgroundColor: '#ffffff',
                  border: '1px solid #d9d9d9',
                  color: 'rgba(0, 0, 0, 0.88)',
                  boxShadow: '0 2px 0 rgba(0, 0, 0, 0.02)',
                  cursor: 'pointer',
                }}
                onChange={(e) => {
                  if (e.target.files) {
                    loader.on();
                    const files = e.target.files;
                    loadExcelFile(files[0], 'naver_store_refunds');
                  }
                }}>
                {'환불 내역 업로드'}
              </FileUploadButton>
              <div style={{ display: 'flex', gap: '8px', alignItems: 'center', fontSize: '14px' }}>
                <div style={{ width: '72px', textAlign: 'right', fontSize: '12px', lineHeight: '12px' }}>
                  검색결과 엑셀 파일 다운로드
                </div>
                <Button
                  onClick={() => {
                    getExcelFile();
                  }}>
                  기본
                </Button>
                <Button
                  onClick={() => {
                    getExcelFile('settlement');
                  }}>
                  정산용
                </Button>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', gap: '12px', alignItems: 'center', marginBottom: '12px' }}>
            <Button
              disabled={sendKakaoNotificationMutation.isPending}
              onClick={() => {
                dialog.open({
                  title: '판매내역 알림톡 발송 확인',
                  content: `24시간 이내 판매된 판매내역 요약 알림톡을 전송합니다.`,
                  type: 'ok_cancel',
                  onConfirm: () => {
                    sendNaverOrdersSummaryKakaoNotificationMutation.mutate();
                  },
                });
              }}>
              판매 요약 알림톡 발송
            </Button>
            <Button
              disabled={sendKakaoNotificationMutation.isPending}
              onClick={() => {
                dialog.open({
                  title: '모든 환불내역 알림톡 발송 확인',
                  content: (
                    <div
                      style={{
                        color: 'red',
                      }}>{`환불 상태(전체/부분)인 항목 중 알림톡을 발송하지 않은 항목 모두 환불 알림톡을 발송합니다.`}</div>
                  ),
                  type: 'ok_cancel',
                  onConfirm: () => {
                    sendRefundsKakaoNotificationMutation.mutate();
                  },
                });
              }}>
              환불내역 알림톡 발송
            </Button>
            <Button
              disabled={updatePaymentAmountMutation.isPending}
              onClick={() => {
                updatePaymentAmountMutation.mutate();
              }}>
              정산대상 금액 업데이트
            </Button>
          </div>
          {selectedIdsCount > 0 && (
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center', marginBottom: '12px' }}>
              <div>{`${selectedIdsCount}개 선택에 대해`} </div>
              <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                <Button
                  onClick={() => {
                    setShowChangeStatus(true);
                  }}>
                  상태 변경
                </Button>
                <Button
                  onClick={() => {
                    setShowSelectUser(true);
                  }}>
                  판매자 변경
                </Button>
                <Button
                  disabled={sendKakaoNotificationMutation.isPending}
                  onClick={() => {
                    dialog.open({
                      title: '판매 확인 알림톡 발송 확인',
                      content: `선택한 항목 ${selectedIdsCount}개에 판매 확인 알림톡을 전송합니다.`,
                      type: 'ok_cancel',
                      onConfirm: () => {
                        sendKakaoNotificationMutation.mutate({
                          targetIds: selectedIds,
                          templateId: 'igogo_naver_confirm_naver_purchase',
                        });
                      },
                    });
                  }}>
                  판매 확인 알림톡 발송(구매자)
                </Button>
                <Button
                  disabled={sendKakaoNotificationMutation.isPending}
                  onClick={() => {
                    dialog.open({
                      title: '판매 알림톡 발송 확인',
                      content: `선택한 항목 ${selectedIdsCount}개에 판매 알림톡을 전송합니다.`,
                      type: 'ok_cancel',
                      onConfirm: () => {
                        sendKakaoNotificationMutation.mutate({
                          targetIds: selectedIds,
                          templateId: 'igogopush_purchase_naver_store',
                        });
                      },
                    });
                  }}>
                  판매 알림톡 발송
                </Button>
                <Button
                  disabled={sendKakaoNotificationMutation.isPending}
                  onClick={() => {
                    dialog.open({
                      title: '환불 알림톡 발송 확인',
                      content: (
                        <div
                          style={{
                            color: 'red',
                          }}>{`선택한 항목 ${selectedIdsCount}개에 환불 알림톡을 전송합니다.`}</div>
                      ),
                      type: 'ok_cancel',
                      onConfirm: () => {
                        sendKakaoNotificationMutation.mutate({
                          targetIds: selectedIds,
                          templateId: 'igogopush_refund_naver_store',
                        });
                      },
                    });
                  }}>
                  환불 알림톡 발송
                </Button>
                <Button
                  style={{ color: 'red' }}
                  disabled={deleteOrdersMutation.isPending}
                  onClick={() => {
                    dialog.open({
                      title: '삭제 확인',
                      content: (
                        <div
                          style={{
                            color: 'red',
                          }}>{`선택한 항목 ${selectedIdsCount}개를 삭제합니다.`}</div>
                      ),
                      type: 'ok_cancel',
                      onConfirm: () => {
                        deleteOrdersMutation.mutate(selectedIds.join(','));
                      },
                    });
                  }}>
                  삭제
                </Button>
                <Button
                  onClick={() => {
                    setSelectedIds([]);
                  }}>
                  선택 해제
                </Button>
              </div>
            </div>
          )}
          <Table
            columns={TABLE_COLUMNS}
            scroll={{ x: 'max-content', y: 'calc(100dvh - 380px)' }}
            style={{ whiteSpace: 'pre-wrap' }}
            rowKey={(record) => record?._id}
            dataSource={store.searchResultContainer.contents.filter((product) => product?._id)}
            pagination={{
              current: (store.searchResultContainer.page || 0) + 1,
              pageSize: store.searchResultContainer.size || 20,
              total: store.searchResultContainer.total,
            }}
            loading={naverOrdersQuery.isLoading}
            onChange={(e) => {
              store.updateSearchQuery({ page: (e.current || 2) - 1, size: e.pageSize || 20 });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default NaverOrdersPage;
