import * as api from '@apis/auth';
import { useAuthStore } from '@stores/authStore';
import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSocket } from './appSocket';
import { User } from '@models/auth';
import { Roles } from '@consts/role';

export const useAuthentication = () => {
  useAppSocket();
  const authStore = useAuthStore();
  const navigate = useNavigate();

  const setUser = (user?: User) => {
    authStore.setUser(user);
  };

  const mutationLogin = useMutation({
    mutationFn: api.login,
    onSuccess: async () => {
      const response = await api.getMe();
      setUser(response.data);
    },
  });

  const mutationLogout = useMutation({
    mutationFn: api.logout,
    onSuccess: () => {
      setUser(undefined);
      navigate('/login');
    },
    onError: () => {
      setUser(undefined);
      navigate('/login');
    },
  });

  useEffect(() => {
    if (!authStore.user) {
      api
        .getMe()
        .then((response) => {
          if (response.data.role !== Roles.ADMIN) {
            setUser(undefined);
            mutationLogout.mutate();
            navigate('/login');
          } else {
            setUser(response.data);
          }
        })
        .catch(() => {
          api
            .refresh()
            .then(() => {
              api.getMe().then((response) => {
                setUser(response.data);
              });
            })
            .catch(() => {
              setUser(undefined);
              navigate('/login');
            });
        })
        .catch(() => {
          setUser(undefined);
          navigate('/login');
        });
    } else {
      if (authStore.user.role !== Roles.ADMIN) {
        setUser(undefined);
        navigate('/login');
      }
    }
  }, [authStore.user]);

  const logout = () => {
    // appSocket.sendEvent({ type: 'logout', data: authStore.user });
    mutationLogout.mutate();
  };

  return { authStore, login: mutationLogin.mutate, loginError: mutationLogin.error, logout };
};
