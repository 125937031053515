import { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import './SlideMenu.scss';

interface SlideMenuStyles {
  container: CSSProperties;
  content: CSSProperties;
}

interface SlideMenuProps {
  className?: string;
  label?: ReactNode;
  defaultOn?: boolean;
  styles?: Partial<SlideMenuStyles>;
  children: ReactNode;
}

const SlideMenu: React.FC<SlideMenuProps> = (props) => {
  const { label, defaultOn = false, styles } = props;
  const [open, setOpen] = useState(defaultOn);

  useEffect(() => {
    setOpen(defaultOn);
  }, [label]);

  return (
    <section className={`slide-menu ${props.className}`} style={styles?.container}>
      <div className="slide-menu__box" onClick={() => setOpen(!open)}>
        <div className="slide-menu__label">{label}</div>
        <DownOutlined className={`slide-menu__icon-arrow${open ? '--on' : ''}`} />
      </div>
      {open && (
        <div className="slide-menu__content" style={styles?.content}>
          {props.children}
        </div>
      )}
    </section>
  );
};

export default SlideMenu;
