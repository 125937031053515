import { PagenatedResponse, SearchPagenateQuery } from '@models/common';
import { PaymentStatement, PaymentStatementSettlementStatus, RefundStatus } from '@models/paymentStatement';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const PAGE_SIZE = 20;

interface PaymentStatemetsContainer {
  contents: PaymentStatement[];
  page: number;
  size: number;
  total: number;
  hasNext: boolean;
}

export interface PaymentStatementsQuery extends SearchPagenateQuery {
  buyerId?: string;
  paymentStatus?: string;
  dateType?: string;
  saleType?: string;
  refundType?: RefundStatus;
  isSettlementTarget?: boolean;
  settlementStatus?: PaymentStatementSettlementStatus;
}

interface PaymentStatemetsStoreProps {
  searchResultContainer: PaymentStatemetsContainer;
  searchQuery: PaymentStatementsQuery;
  searchKeyword?: string;
  setStartDate: (date: string | null) => void;
  setEndDate: (date: string | null) => void;
  setSearchKeyword: (keyword: string) => void;
  updateSearchQuery: (query: { [key: string]: any }) => void;
  resetSearchQuery: () => void;
  setPaymentStatemetsContainer: (result: PagenatedResponse<PaymentStatement>) => void;
}

export const usePaymentStatemetsStore = create(
  persist<PaymentStatemetsStoreProps>(
    (set) => ({
      searchResultContainer: {
        contents: [] as PaymentStatement[],
        total: 0,
        page: 0,
        size: 0,
        hasNext: false,
      },
      searchQuery: {
        page: 0,
        size: PAGE_SIZE,
        dateType: 'payment.paidAt',
        startDate: undefined,
        endDate: undefined,
        isSettlementTarget: false,
      },
      setStartDate: (date: string | null) => {
        set((state) => {
          return { ...state, searchQuery: { ...state.searchQuery, startDate: date } };
        });
      },
      setEndDate: (date: string | null) => {
        set((state) => {
          return { ...state, searchQuery: { ...state.searchQuery, endDate: date } };
        });
      },
      searchKeyword: undefined,
      setPaymentStatemetsContainer: (result) => {
        set((state) => {
          const contents = new Array(result.total || 20);
          contents.splice(result.page * result.size, result.size, ...(result?.contents || []));
          const newValue = { ...state.searchResultContainer, ...result, contents: contents };
          return { ...state, searchResultContainer: newValue };
        });
      },
      setSearchKeyword: (keyword: string) => {
        set((state) => {
          return {
            ...state,
            searchKeyword: keyword,
            searchResultContainer: { ...state.searchResultContainer, size: 0 },
          };
        });
      },
      updateSearchQuery: (query: { [key: string]: any }) => {
        set((state) => {
          return {
            ...state,
            searchQuery: { ...state.searchQuery, page: 0, ...query },
            searchResultContainer: { ...state.searchResultContainer, size: 0 },
          };
        });
      },
      resetSearchQuery: () => {
        set({
          searchQuery: {
            page: 0,
            size: PAGE_SIZE,
            dateType: 'payment.paidAt',
            startDate: undefined,
            endDate: undefined,
            isSettlementTarget: false,
            keyword: '',
          },
        });
      },
    }),
    {
      name: 'igogo-admin-payment-statements',
    },
  ),
);
