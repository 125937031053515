import useDebounce from '@hooks/useDebounce';
import useNotificationStore from '@stores/notificationStore';
import { Button, Input, Select } from 'antd';
import { useEffect, useState } from 'react';

interface NoticeContentMenuProps {
  openModal: () => void;
}

const NoticeContentMenu: React.FC<NoticeContentMenuProps> = (props) => {
  const { openModal } = props;
  const [searchKeyword, setSearchKeyword] = useState('');
  const debounceKeyword = useDebounce({ value: searchKeyword });
  const store = useNotificationStore();

  useEffect(() => {
    store.updateSearchQuery({ keyword: debounceKeyword });
  }, [debounceKeyword]);

  return (
    <div style={{ display: 'flex', gap: '12px', width: '100%', height: '52px', alignItems: 'center' }}>
      <Select
        defaultValue=""
        style={{ width: 120 }}
        onChange={(value) => {
          store.updateSearchQuery({ targetType: value });
        }}
        options={[
          { value: '', label: '전체' },
          { value: 'app', label: '앱' },
          { value: 'partner', label: '파트너' },
          { value: 'seller', label: '셀러' },
        ]}
      />
      <Select
        defaultValue=""
        style={{ width: 120 }}
        onChange={(value) => {
          store.updateSearchQuery({ optionType: value });
        }}
        options={[
          { value: '', label: '전체' },
          { value: 'popup', label: '팝업 고정' },
          { value: 'priority', label: '상단 고정' },
        ]}
      />
      <Input
        placeholder={'공지사항 검색'}
        style={{ width: '240px' }}
        value={searchKeyword}
        onChange={(e) => {
          setSearchKeyword(e.target.value);
        }}
      />
      <div style={{ display: 'flex', marginLeft: 'auto', gap: '12px', alignItems: 'center' }}>
        <Button onClick={openModal}>공지사항 추가</Button>
      </div>
    </div>
  );
};

export default NoticeContentMenu;
