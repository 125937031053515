import * as usersApi from '@apis/admin-user';
import { Roles } from '@consts/role';
import { AdminUser } from '@models/auth';
import { useQuery } from '@tanstack/react-query';
import { Button, Input, Select } from 'antd';
import React, { useRef, useState } from 'react';

interface SelectUserProps {
  selectedUserId?: string;
  onSelect?: (value: AdminUser) => void;
  style?: any;
}

export const SelectUser: React.FC<SelectUserProps> = (props) => {
  const [query, setQuery] = useState<{ type?: string; keyword?: string }>({});
  const userSearchInputRef = useRef<any>();

  const userSerchQuery = useQuery({
    queryKey: [`admin-search-users`, JSON.stringify(query)],
    queryFn: () => usersApi.searchUsers({ ...query, page: 0, size: 500 }),
  });

  const searchResult = userSerchQuery.data?.data;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', ...props.style }}>
      <div style={{ display: 'flex', gap: '8px' }}>
        <Select
          value={query.type || 'all'}
          style={{ width: 120 }}
          onChange={(value) => {
            setQuery((prev) => {
              return { ...prev, type: value };
            });
          }}
          options={[
            { value: 'all', label: '전체' },
            { value: Roles.SELLER, label: '셀러' },
            { value: Roles.TUTOR, label: '튜터' },
            { value: Roles.PARENT, label: '부모' },
            { value: Roles.EDITOR, label: '에디터' },
          ]}
        />
        <Input
          ref={userSearchInputRef}
          onKeyUp={(e) => {
            if (e.code === 'Enter') {
              setQuery((prev) => {
                return { ...prev, keyword: userSearchInputRef.current.input.value };
              });
            }
          }}></Input>
        <Button
          onClick={() => {
            setQuery((prev) => {
              return { ...prev, keyword: userSearchInputRef.current.input.value };
            });
          }}>
          검색
        </Button>
      </div>
      <div style={{ display: 'flex', marginTop: '12px', gap: '12px', alignItems: 'center' }}>
        <Select
          style={{ width: '100%' }}
          placeholder={'판매자를 선택해주세요'}
          value={props.selectedUserId}
          onSelect={(_id) => {
            const selectedUser = searchResult?.contents.find((user) => user._id === _id);
            if (selectedUser) {
              props.onSelect && props.onSelect(selectedUser);
            }
          }}>
          {(searchResult?.contents || []).map((item) => {
            if (!item?._id) {
              return undefined;
            }

            let name = `${item.profile?.name}(${item.profile?.phone?.substring(item.profile?.phone?.length - 4)})[${
              item.roles
            }]`;
            if (item.deletedAt) {
              name = `탈퇴한 사용자(${item.profile?.name})`;
            } else if (item.profile?.nickname) {
              name = `${item.profile?.name}(${item.profile?.nickname})[${item.roles}]`;
            }
            return (
              <Select.Option key={item._id} value={item._id}>
                {name}
              </Select.Option>
            );
          })}
        </Select>
        <div style={{ flexShrink: 0 }}>{`${searchResult?.contents.length || 0}명`}</div>
      </div>
    </div>
  );
};
