import { PushNotificationTopic } from '@consts/pushNotification';
import { DeepLinkActions } from './common';

export interface PushNotification {
  _id: string;
  title: string;
  body: string;
  topic?: PushNotificationTopic;
  contentType?: DeepLinkActions;
  reservedAt?: Date;
  status?: PushNotificationStatus;
  sentAt?: Date;
  collectionId?: string;
  content?: { [key: string]: any };
  channelId?: PushNotificationTopic; // > topic
  pushType?: DeepLinkActions; // > contentType
  reserveDate?: Date; // > reservedAt
  sendYn?: boolean; // > status
  sendDate?: Date; // > sentAt
  createdAt: Date;
  updatedAt: Date;
  url?: string;
  keyword?: string;
  planningId?: string;
  classId?: string;
  commerceId?: string;
  noticeId?: string;
  productId?: string;
  clickedCount?: number;
  targetUserIds?: string[];
  result?: { responses: any[]; successCount?: number; failureCount?: number };
}

export enum PushNotificationStatus {
  NONE = 'none',
  READY = 'ready',
  RESERVE = 'reserve',
  RESERVE_CANCEL = 'reserve_cancel',
  PENDING = 'pending',
  SENT = 'sent',
  SENT_FAIL = 'sent_fail',
}

export interface PushNotificationBody {
  _id?: string;
  topic?: PushNotificationTopic;
  targetUserIds?: string[];
  contentType?: DeepLinkActions;
  productId?: string;
  collectionId?: string;
  userContentId?: string;
  tab?: string;
  write?: boolean;
  content?: { [key: string]: any };
  status?: PushNotificationStatus;
  url?: string;
  noticeId?: string;
  title: string;
  body: string;
  reservedAt?: Date;
  keyword?: string;
}

export enum LegacyPushNotificationType {
  COMMERCE = 'commerce',
  PLANNING = 'planning',
  PROGRAM = 'program',
}

export interface KakaoNotificationLog {
  _id: string;
  type: string;
  phone: string;
  name: string;
  request: Request;
  response: Response;
  status: string;
  originId?: string;
  createdAt: string;
  updatedAt: string;
}

export interface KakaoReservedNotification {
  _id: string;
  templateName: string;
  receiverName: string;
  recevierPhone: string;
  content: any;
  ref: any;
  status: string;
  reservedAt: Date;
  createdAt: Date;
}

export interface Request {
  sender: string;
  username: string;
  key: string;
  receiver: string;
  kakao_plus_id: string;
  template_type: number;
  user_template_no: string;
  type: string;
  kakao_faild_type: number;
  message: string;
  reserve_type: string;
}

export interface Response {
  status: string;
  message?: string;
}
