import { create } from 'zustand';
type OkCancelDialogType = 'ok_cancel' | 'ok' | 'cancel';

interface OkCancelDialogData {
  title?: string;
  customTitle?: any;
  content: any;
  confirmColor?: string;
  confirmText?: string;
  cancelText?: string;
  type?: OkCancelDialogType;
  onConfirm?: () => void;
  onCancel?: () => void;
}

interface OkCancelDialog {
  show?: boolean;
  data?: OkCancelDialogData;
  open: (data: OkCancelDialogData) => void;
  close: () => void;
}

export const useOkCancelDialog = create<OkCancelDialog>((set) => ({
  show: false,
  data: {} as OkCancelDialogData,
  open: (data?: OkCancelDialogData) => {
    set({ show: true, data });
  },
  close: () => {
    set({ show: false, data: {} as OkCancelDialogData });
  },
}));
