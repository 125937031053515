import { ClassProduct } from '@models/class';
import { ClassInvoice, CreateClassInvoiceData } from '@models/invoice';
import { SettlementsModel } from '@models/paymentStatement';
import { ClassReport, ClassReportBody } from '@models/report';
import { ClassSchedule } from '@models/schedule';
import { apiClient } from '.';

export const getClassSchedules = async (startDate?: Date, endDate?: Date) => {
  return apiClient.get<ClassSchedule[]>('/tutor/v1/schedules', { params: { startDate, endDate } });
};

export const getClassSchedulesByClassInvoiceId = async (classInvoiceId: string) => {
  return apiClient.get<ClassSchedule[]>(`/admin/v1/class-invoices/${classInvoiceId}/schedules`);
};

export const getTutorClasses = async () => {
  return apiClient.get<ClassProduct[]>('/tutor/v1/classes');
};

export const getClass = async (classId: string) => {
  return apiClient.get<ClassProduct>(`/tutor/v1/classes/${classId}`, { params: { classId } });
};

export const getClassSummary = async (classId: string) => {
  return apiClient.get<{ tutorClass: ClassProduct; summary: any }>(`/tutor/v1/classes/${classId}/summary`, {
    params: { classId },
  });
};

export const updateClassState = async (classId: string) => {
  return apiClient.patch<ClassProduct>(`/tutor/v1/classes/${classId}/state`, { params: { classId } });
};

export const updateClass = async (classFormData: FormData) => {
  const classId = classFormData.get('classId');
  return apiClient.put<{ step: string; class: ClassProduct }>(`/tutor/v1/classes/${classId}`, classFormData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const createClass = async (classFormData: FormData) => {
  return apiClient.post<ClassProduct>(`/tutor/v1/classes`, classFormData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const getSettlements = async () => {
  return apiClient.get<{
    settlements: SettlementsModel[] | [];
    totalForPay: number;
    currentMonthForPay: number;
    readyForPay: number;
  }>('/tutor/v1/settlements');
};

export const getSettlementsSummary = async () => {
  return apiClient.get<{
    totalForPay: number;
    currentMonthForPay: number;
    readyForPay: number;
  }>('/tutor/v1/settlements/summary');
};

export const createReport = async (reportData: ClassReportBody) => {
  const { classProductId, classInvoiceId } = reportData;
  return apiClient.post<ClassReport>(
    `/tutor/v1/classes/${classProductId}/invoices/${classInvoiceId}/report`,
    reportData,
  );
};

export const updateReport = async (reportData: ClassReportBody) => {
  const { classProductId, classInvoiceId } = reportData;
  return apiClient.put<ClassReport>(
    `/tutor/v1/classes/${classProductId}/invoices/${classInvoiceId}/report`,
    reportData,
  );
};

export const createClassInvoice = async (body: CreateClassInvoiceData) => {
  return apiClient.post<ClassInvoice>(`/tutor/v1/classes/${body.classId}/invoice`, body);
};

export const updateClassSchedule = async (data: Partial<ClassSchedule>) => {
  const { classId, requestId, _id } = data;
  return apiClient.put(`/tutor/v1/classes/${classId}/invoices/${requestId}/schedules/${_id}`, data);
};

export const updateClassSchedules = async (data: {
  classId: string;
  requestId: string;
  schedules: Partial<ClassSchedule>[];
}) => {
  const { classId, requestId, schedules } = data;
  return apiClient.put(`/tutor/v1/classes/${classId}/invoices/${requestId}/schedules`, schedules);
};
