import { Fragment } from 'react';
import { Button, Form, FormProps, Input, Modal } from 'antd';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import './NoticeEditModal.scss';
import * as notificationApi from '@apis/notification';
import { NoticeModel } from '@models/notification';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { Content, ExposeDomain, Option, Title } from './NoticeModalContent';
import transNoticeBody from './transNoticeBody';

interface NoticeEditModalProps {
  notificationId: string;
  onClose: () => void;
}
interface ModalFooterProps {
  notificationId: string;
  onClose: () => void;
}
interface ModalContentProps {
  data?: NoticeModel;
}

const ModalFooter: React.FC<ModalFooterProps> = (props) => {
  const { notificationId, onClose } = props;

  const info = [
    { label: '취소', htmlType: 'button', onAction: onClose },
    {
      label: notificationId === 'new' ? '생성' : '수정',
      htmlType: 'submit',
    },
  ];
  return (
    <div style={{ width: '100%' }}>
      {info.map(({ label, htmlType, onAction }) => (
        <Button
          key={label}
          style={{ width: '120px' }}
          htmlType={htmlType as 'button' | 'submit'}
          onClick={onAction}
          form="notice">
          {label}
        </Button>
      ))}
    </div>
  );
};

const ModalContent: React.FC<ModalContentProps> = (props) => {
  const { data } = props;

  const content = [
    { label: '제목', name: 'title', Render: Title, required: true },
    { label: '내용', name: 'content', Render: Content, required: true },
    { label: '노출 도메인', name: 'targets', Render: ExposeDomain, required: false },
    { label: '옵션', name: 'options', Render: Option, required: false },
  ];

  return (
    <Fragment>
      {content.map(({ label, name, Render }) => (
        <Form.Item key={label} name={name} label={label}>
          <Render data={data} />
        </Form.Item>
      ))}
      <Form.Item key={'priority'} name={'priority'} label={'상단고정 우선순위'}>
        <Input defaultValue={data?.priority}></Input>
      </Form.Item>
    </Fragment>
  );
};

const NoticeEditModal: React.FC<NoticeEditModalProps> = (props) => {
  const { notificationId, onClose } = props;
  const dialog = useOkCancelDialog();
  const queryClient = useQueryClient();

  const notificationQuery = useQuery({
    queryKey: [`notification-item-${notificationId}`],
    queryFn: () => notificationApi.getNotification(notificationId!),
    enabled: !!notificationId && notificationId !== 'new',
  });
  const createNotificationMutation = useMutation({
    mutationFn: notificationApi.createNotification,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['notification-all'] });
      await queryClient.invalidateQueries({ queryKey: [`notification-item-${notificationId}`] });

      onClose && onClose();
      dialog.open({
        content: notificationId === 'new' ? '생성되었습니다.' : '수정되었습니다.',
        type: 'ok',
      });
    },
    onError: async (e: any) => {
      onClose && onClose();
      dialog.open({
        title: '오류',
        content: e?.response?.data?.message,
        type: 'ok',
      });
    },
  });

  const updateNotificationMutation = useMutation({
    mutationFn: notificationApi.updateNotification,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['notification-all'] });
      await queryClient.invalidateQueries({ queryKey: [`notification-item-${notificationId}`] });
      onClose && onClose();
      dialog.open({
        content: '수정되었습니다.',
        type: 'ok',
      });
    },
    onError: async (e: any) => {
      onClose && onClose();
      dialog.open({
        title: '오류',
        content: e?.response?.data?.message,
        type: 'ok',
      });
    },
  });

  const data = notificationQuery.data?.data;

  const onSubmit: FormProps['onFinish'] = (e) => {
    const isNew = notificationId === 'new';
    const body = transNoticeBody({ data: data!, rawData: e, isNew: isNew });

    if (isNew) {
      createNotificationMutation.mutate(body);
    } else {
      updateNotificationMutation.mutate({ notificationId: notificationId, body: body });
    }
  };

  return (
    <Modal open={true} onCancel={onClose} footer={<ModalFooter notificationId={notificationId} onClose={onClose} />}>
      <OverlayScrollbarsComponent
        options={{ scrollbars: { autoHide: 'scroll' } }}
        style={{ height: 'calc(100vh - 240px)' }}>
        {(notificationId === 'new' || data) && (
          <Form
            name="notice"
            layout="vertical"
            initialValues={{
              title: data && data.title,
              content: data && data.content,
              targets: data && data.targets,
              priority: data?.priority || '',
              options: [data && data.popup && 'popup'],
            }}
            onFinish={onSubmit}>
            <div className="notification-header">공지사항 작성</div>
            <ModalContent data={data} />
          </Form>
        )}
      </OverlayScrollbarsComponent>
    </Modal>
  );
};

export default NoticeEditModal;
