import * as api from '@apis/admin-notifications';
import * as productApi from '@apis/product';
import * as productCollectionApi from '@apis/product-collection';
import * as noticeApi from '@apis/admin-notice';
import DateTimeButtonPicker from '@components/class-invoice/DateTimeButtonPicker';
import {
  LegacyPushNotificationContentType,
  PushNotificationTopic,
  PUSH_NOTIFICATION_TARGETS,
} from '@consts/pushNotification';
import { useAuthentication } from '@hooks/authentication';
import { Notice } from '@models/notice';
import { ProductModel } from '@models/product';
import { ProductCollection } from '@models/productCollection';
import { PushNotification, PushNotificationBody, PushNotificationStatus } from '@models/notifications';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Checkbox, Input, Modal, Popover, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DeepLinkActions, PagenatedQuery } from '@models/common';
import { DEEPLINK_ACTION_LABELS } from '@components/feed/DeepLinkActionEdit';
import { SelectUser } from '@components/product/SelectUser';

interface ChangedData {
  type: DeepLinkActions;
  productId?: string;
  collectionId?: string;
  noticeId?: string;
  url?: string;
  keyword?: string;
  userContentId?: string;
  partnerId?: string;
  tab?: string;
  write?: boolean;
  content?: { [key: string]: any };
}
interface ContentSetViewProps {
  contentType: DeepLinkActions;
  pushNotificationData?: PushNotification;
  isClose: boolean;
  onChange?: (data: ChangedData) => void;
}

const ContentSetView: React.FC<ContentSetViewProps> = (props) => {
  const contentType = props.contentType;
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [url, setUrl] = useState<string>('');
  const [keyword, setKeyword] = useState<string>('');
  const [query, setQuery] = useState<{ keyword?: string; type?: string }>({ keyword: '' });
  const [selectedValue, setSelectedValue] = useState<string>('');

  const productSearchQuery = useQuery({
    queryKey: [`admin-search-products`, query.keyword],
    queryFn: () => productApi.searchProducts({ ...query, page: 0, size: 50000 }),
    enabled: contentType && contentType === DeepLinkActions.GO_TO_PRODUCT,
  });
  const productCollectionSearchQuery = useQuery({
    queryKey: [`admin-search-product-collections`, query.keyword],
    queryFn: () => productCollectionApi.searchProductCollections({ ...query, page: 0, size: 50000 }),
    enabled:
      contentType &&
      (contentType === DeepLinkActions.GO_TO_COLLECTION || contentType === DeepLinkActions.GO_TO_THEME_COLLECTION),
  });
  const noticeSearchQuery = useQuery({
    queryKey: [`admin-get-notices`],
    queryFn: () => noticeApi.searchNotices({ page: 0, size: 1000 }),
    enabled: contentType && contentType === DeepLinkActions.GO_TO_NOTICE,
  });

  const productSearchResult = query.keyword ? productSearchQuery.data?.data.contents || [] : [];
  const productCollectionSearchResult = query.keyword ? productCollectionSearchQuery.data?.data.contents || [] : [];
  const noticeSearchResult = noticeSearchQuery.data?.data.contents || [];

  const productSearchResultOptions = productSearchResult.map((item: ProductModel) => {
    const saleType = item?.saleType === 'commerce' ? '🔹커머스' : '🔸클래스';
    return {
      value: item._id,
      label: `${saleType}[${item.sellerName}]${item.title}`,
      children: item,
    };
  });

  const productCollectionSearchResultOptions = productCollectionSearchResult.map((item: ProductCollection) => {
    return {
      value: item._id,
      label: `${item.title}`,
      children: item,
    };
  });

  const noticeSearchResultOptions = noticeSearchResult.map((item: Notice) => {
    return {
      value: item._id,
      label: `${item.title}`,
      children: item,
    };
  });

  useEffect(() => {
    if (props?.pushNotificationData) {
      if (props.pushNotificationData.url) {
        setUrl(props.pushNotificationData.url);
      } else {
        setSelectedValue(props.pushNotificationData?.content?.title);
      }
    }
  }, [props.pushNotificationData]);

  useEffect(() => {
    setSearchKeyword('');
    setQuery({ keyword: '' });
  }, [props.contentType]);

  let contentSetView = <div></div>;
  if (
    contentType === DeepLinkActions.GO_TO_PRODUCT ||
    contentType === DeepLinkActions.GO_TO_COLLECTION ||
    contentType === DeepLinkActions.GO_TO_THEME_COLLECTION ||
    contentType === DeepLinkActions.GO_TO_NOTICE
  ) {
    contentSetView = (
      <div>
        {!(contentType === DeepLinkActions.GO_TO_NOTICE) && (
          <div style={{ marginBottom: '5px' }}>
            검색:
            <Input
              value={searchKeyword}
              placeholder={'검색어를 입력해주세요'}
              onChange={(e) => {
                setSearchKeyword(e.target.value);
              }}
              onPressEnter={() => {
                const query: any = { keyword: searchKeyword };
                if (contentType === DeepLinkActions.GO_TO_THEME_COLLECTION) {
                  query['type'] = 'thema';
                }
                setQuery(query);
                setSelectedValue('');
              }}></Input>
          </div>
        )}
        {contentType === DeepLinkActions.GO_TO_PRODUCT &&
          (productSearchResult.length > 0 || props.pushNotificationData?.content) && (
            <Select
              style={{ width: '100%' }}
              value={selectedValue}
              onChange={(value, option: any) => {
                const data: ChangedData = { type: contentType, productId: value, content: option.children };
                setSelectedValue(option?.label);
                props.onChange && props.onChange(data);
              }}
              options={productSearchResultOptions}
            />
          )}
        {(contentType === DeepLinkActions.GO_TO_COLLECTION || contentType === DeepLinkActions.GO_TO_THEME_COLLECTION) &&
          (productCollectionSearchResult.length > 0 || props.pushNotificationData?.content) && (
            <Select
              style={{ width: '100%' }}
              value={selectedValue}
              onChange={(value, option: any) => {
                const newData: Partial<ProductCollection> = {
                  _id: option?.children?._id,
                  title: option?.children?.title || '',
                  type: option?.children?.type || '',
                  coverImageUrl: option?.children?.coverImageUrl || '',
                };
                const data: ChangedData = { type: contentType, collectionId: value, content: newData };
                setSelectedValue(option?.label);
                props.onChange && props.onChange(data);
              }}
              options={productCollectionSearchResultOptions}
            />
          )}
        {contentType === DeepLinkActions.GO_TO_NOTICE &&
          (noticeSearchResult.length > 0 || props.pushNotificationData?.content) && (
            <Select
              style={{ width: '100%' }}
              value={selectedValue}
              onChange={(value, option: any) => {
                const data: ChangedData = { type: contentType, noticeId: value, content: option.children };
                setSelectedValue(option?.label);
                props.onChange && props.onChange(data);
              }}
              options={noticeSearchResultOptions}
            />
          )}
      </div>
    );
  } else if (contentType === DeepLinkActions.OPEN_LINK) {
    contentSetView = (
      <Input
        value={url}
        placeholder={'링크를 입력해주세요.'}
        onChange={(e) => {
          const data: ChangedData = { type: contentType, url: e.target.value };
          setUrl(e.target.value);
          props.onChange && props.onChange(data);
        }}></Input>
    );
  } else if (contentType === DeepLinkActions.SEARCH_KEYWORD) {
    contentSetView = (
      <Input
        value={keyword}
        placeholder={'검색어를 입력해주세요.'}
        onChange={(e) => {
          const data: ChangedData = { type: contentType, keyword: e.target.value };
          setKeyword(e.target.value);
          props.onChange && props.onChange(data);
        }}></Input>
    );
  } else if (contentType === DeepLinkActions.GO_TO_LOUNGE) {
    contentSetView = (
      <div style={{ marginTop: '8px', display: 'flex', gap: '4px', flexDirection: 'column' }}>
        <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
          <Select
            style={{ width: 320 }}
            onChange={(value) => {
              const data: ChangedData = { type: contentType, tab: value };
              props.onChange && props.onChange(data);
            }}
            options={[
              { value: 'all', label: '홈' },
              { value: 'review', label: '후기' },
              { value: 'curation', label: '콘텐츠발견' },
            ]}></Select>
          <Checkbox
            style={{ flexShrink: 0 }}
            onChange={(e) => {
              const data: ChangedData = { type: contentType, write: e.target.checked };
              props.onChange && props.onChange(data);
            }}>
            글작성
          </Checkbox>
        </div>

        <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
          <div style={{ flexShrink: 0 }}>콘텐츠 ID</div>
          <Input
            onChange={(e) => {
              const data: ChangedData = { type: contentType, userContentId: e.target.value };
              props.onChange && props.onChange(data);
            }}></Input>
        </div>
      </div>
    );
  } else if (contentType === DeepLinkActions.GO_TO_FAVORITES) {
    contentSetView = (
      <div style={{ marginTop: '8px', display: 'flex', gap: '4px', flexDirection: 'column' }}>
        <Select
          style={{ width: 320 }}
          onChange={(value) => {
            const data: ChangedData = { type: contentType, tab: value };
            props.onChange && props.onChange(data);
          }}
          options={[
            { value: 'product', label: '상품' },
            { value: 'partner', label: '파트너' },
            { value: 'user-content', label: '라운지' },
          ]}></Select>
      </div>
    );
  } else if (contentType === DeepLinkActions.GO_TO_PARTNER) {
    contentSetView = (
      <div style={{ marginTop: '8px', display: 'flex', gap: '4px', flexDirection: 'column' }}>
        <SelectUser
          style={{ width: '528px' }}
          onSelect={(partner) => {
            const data: ChangedData = { type: contentType, partnerId: partner._id };
            props.onChange && props.onChange(data);
          }}></SelectUser>
      </div>
    );
  }

  return <div>{contentSetView}</div>;
};

interface PushNoticeEditModalProps {
  open?: boolean;
  onClose?: () => void;
  needRefetch?: () => void;
  pushNotificationData?: PushNotification;
}
type CreatePushNotificationType = 'reserve' | 'send';

const PushNoticeEditModal: React.FC<PushNoticeEditModalProps> = (props) => {
  const okCancelDialog = useOkCancelDialog();
  const [pasteValue, setPasteValue] = useState<string>();
  const [isReserve, setIsReserve] = useState<boolean>(false);
  const [notificationData, setNotificationData] = useState({
    topic: PushNotificationTopic.NOTICE,
    contentType: DeepLinkActions.GO_TO_COLLECTION,
    targetUserIds: undefined,
    contentId: '',
    content: {},
    title: '',
    body: '',
  } as PushNotificationBody);

  useEffect(() => {
    if (props.pushNotificationData) {
      const notificationData: PushNotificationBody = {
        ...props.pushNotificationData,
        topic: props.pushNotificationData.topic || PushNotificationTopic.NOTICE,
        contentType: props?.pushNotificationData?.contentType,
        content: props.pushNotificationData?.content || {},
      };
      if (props.pushNotificationData.productId) {
        notificationData['productId'] = props.pushNotificationData.productId;
      } else if (props.pushNotificationData.collectionId) {
        notificationData['collectionId'] = props.pushNotificationData.collectionId;
      }

      if (notificationData.reservedAt || notificationData.status === PushNotificationStatus.RESERVE_CANCEL) {
        setIsReserve(true);
      }
      setNotificationData(notificationData);
    }
  }, [props.pushNotificationData]);

  const mutationCreatePushNotificationQuery = useMutation({
    mutationFn: api.createPushNotification,
    onSuccess: async (res) => {
      if (res.status === 201) {
        props.onClose && props.onClose();
        props.needRefetch && props.needRefetch();
        setNotificationData({
          topic: PushNotificationTopic.NOTICE,
          contentType: DeepLinkActions.GO_TO_COLLECTION,
          productId: '',
          collectionId: '',
          content: {},
          title: '',
          body: '',
        });
        const isReserveType = mutationCreatePushNotificationQuery?.variables?.type === 'reserve';
        okCancelDialog.open({
          title: '성공',
          content: isReserveType ? '저장 되었습니다.' : '발송 되었습니다.',
          type: 'ok',
        });
      }
    },
  });

  const mutationUpdatePushNotificationQuery = useMutation({
    mutationFn: api.updatePushNotification,
    onSuccess: async (res) => {
      if (res.status === 200) {
        props.onClose && props.onClose();
        props.needRefetch && props.needRefetch();
        setNotificationData({
          _id: '',
          topic: PushNotificationTopic.NOTICE,
          contentType: DeepLinkActions.GO_TO_COLLECTION,
          productId: '',
          collectionId: '',
          content: {},
          title: '',
          body: '',
        });
        okCancelDialog.open({
          title: '성공',
          content: '저장되었습니다.',
          type: 'ok',
        });
      }
    },
  });

  const sendPushNotification = () => {
    const newData = {
      type: 'send' as CreatePushNotificationType,
      notificationData,
    };
    mutationCreatePushNotificationQuery.mutate(newData);
  };

  const reservePushNotification = () => {
    const newData = {
      type: 'reserve' as CreatePushNotificationType,
      notificationData,
    };
    mutationCreatePushNotificationQuery.mutate(newData);
  };

  const updatePushNotification = () => {
    const newData = {
      notificationId: notificationData?._id || '',
      notificationData,
    };
    mutationUpdatePushNotificationQuery.mutate(newData);
  };

  const isEdit = !!props.pushNotificationData;

  return (
    <Modal
      okText={undefined}
      width={600}
      open={props.open}
      onCancel={() => {
        setIsReserve(false);
        setNotificationData({
          topic: PushNotificationTopic.NOTICE,
          contentType: DeepLinkActions.GO_TO_COLLECTION,
          productId: '',
          collectionId: '',
          content: {},
          title: '',
          body: '',
        });
        props.onClose && props.onClose();
      }}
      footer={
        isEdit ? (
          <div>
            <Button
              onClick={() => {
                props.onClose && props.onClose();
              }}>
              닫기
            </Button>
            <Button
              onClick={() => {
                if (isEdit) {
                  setNotificationData((prev) => {
                    const newValue = { ...prev };
                    const isReserveCancel = newValue.status === PushNotificationStatus.RESERVE_CANCEL;
                    if (isReserveCancel) {
                      newValue['status'] = PushNotificationStatus.RESERVE;
                    } else {
                      newValue['status'] = PushNotificationStatus.RESERVE_CANCEL;
                    }
                    return newValue;
                  });
                  // updatePushNotification();
                }
              }}>
              {notificationData.status === PushNotificationStatus.RESERVE_CANCEL ? '다시 예약하기' : '예약 취소하기'}
            </Button>
            <Button onClick={updatePushNotification}>저장하기</Button>
          </div>
        ) : (
          <div>
            <Button
              onClick={() => {
                props.onClose && props.onClose();
              }}>
              닫기
            </Button>
            {!isReserve && (
              <Button
                onClick={() => {
                  okCancelDialog.open({
                    title: '푸시 메시 전송 확인',
                    content: '푸시 메시지를 전송할까요?',
                    onConfirm: sendPushNotification,
                  });
                }}>
                발송하기
              </Button>
            )}
            {isReserve && <Button onClick={reservePushNotification}>예약하기</Button>}
          </div>
        )
      }>
      <div>
        <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{isEdit ? '푸시 알림 편집' : '푸시 알림 생성'}</div>
        <div>
          <div style={{ marginTop: '5px' }}>발송 대상</div>
          <Select
            style={{ width: 200 }}
            placeholder="발송 대상 선택"
            value={notificationData.topic || PushNotificationTopic.NOTICE}
            onChange={(value) => {
              setNotificationData((prev) => {
                const newValue = { ...prev };
                newValue['topic'] = value;
                return newValue;
              });
            }}
            options={Object.keys(PUSH_NOTIFICATION_TARGETS).map((key) => {
              return { value: key, label: PUSH_NOTIFICATION_TARGETS[key] };
            })}
          />
          {notificationData.topic === PushNotificationTopic.MANUAL && (
            <div>
              <Input
                style={{ marginTop: '8px' }}
                value={pasteValue}
                placeholder="여기에 대상 유저 아이디를 붙여넣으세요"
                onChange={() => {
                  setPasteValue('');
                }}
                onPaste={(e) => {
                  const text = e.clipboardData.getData('text/plain');
                  const newItems = text.split('\n').map((line) => {
                    return line.trim().replace(/\r/g, '');
                  });

                  setNotificationData((prev) => {
                    const newValue = { ...prev, targetUserIds: newItems };
                    return newValue;
                  });
                }}></Input>
              {notificationData.targetUserIds && notificationData.targetUserIds?.length > 0 && (
                <div style={{ marginTop: '8px' }}>
                  <Popover
                    content={notificationData.targetUserIds.join(
                      ' / ',
                    )}>{`대상: ${notificationData.targetUserIds.length}명`}</Popover>
                </div>
              )}
            </div>
          )}
          <div style={{ marginTop: '5px' }}>클릭시 이동 설정</div>
          <Select
            style={{ width: '100%', marginBottom: '5px' }}
            value={notificationData.contentType}
            onChange={(value) => {
              setNotificationData((prev) => {
                const newValue = { ...prev };
                newValue['contentType'] = value;
                return newValue;
              });
            }}
            options={Object.keys(DEEPLINK_ACTION_LABELS).map((key) => {
              return { value: key, label: DEEPLINK_ACTION_LABELS[key].label };
            })}
          />
          {notificationData.contentType && (
            <ContentSetView
              contentType={notificationData.contentType}
              pushNotificationData={props.pushNotificationData}
              isClose={!!props.pushNotificationData}
              onChange={(data) => {
                // const type = data.type;
                setNotificationData((prev) => {
                  const newValue = { ...prev, ...data };
                  // newValue['content'] = data?.data || {};
                  // if (type === DeepLinkActions.GO_TO_PRODUCT) {
                  //   newValue['productId'] = data.value || '';
                  // } else if (
                  //   type === DeepLinkActions.GO_TO_COLLECTION ||
                  //   type === DeepLinkActions.GO_TO_THEME_COLLECTION
                  // ) {
                  //   newValue['collectionId'] = data.value || '';
                  // } else if (type === DeepLinkActions.GO_TO_NOTICE) {
                  //   newValue['noticeId'] = data.value || '';
                  // } else {
                  //   if (type === DeepLinkActions.OPEN_LINK) {
                  //     newValue['url'] = data.value;
                  //   }
                  //   if (type === DeepLinkActions.SEARCH_KEYWORD) {
                  //     newValue['keyword'] = data.value;
                  //   }
                  //   delete newValue['productId'];
                  //   delete newValue['collectionId'];
                  //   delete newValue['noticeId'];
                  //   delete newValue['content'];
                  // }
                  return newValue;
                });
              }}></ContentSetView>
          )}
          <div>
            <div style={{ marginTop: '5px' }}>
              타이틀:
              <Input
                value={notificationData.title}
                placeholder={'타이틀을 입력해주세요'}
                onChange={(e) => {
                  setNotificationData((prev) => {
                    const newValue = { ...prev };
                    newValue['title'] = e.target.value;
                    return newValue;
                  });
                }}></Input>
            </div>
            <div style={{ marginTop: '5px' }}>
              메시지:
              <Input.TextArea
                value={notificationData.body}
                placeholder={'내용을 입력해주세요'}
                onChange={(e) => {
                  setNotificationData((prev) => {
                    const newValue = { ...prev };
                    newValue['body'] = e.target.value;
                    return newValue;
                  });
                }}></Input.TextArea>
            </div>
            {!props.pushNotificationData && (
              <Checkbox
                style={{ marginTop: '5px' }}
                checked={!!isReserve}
                onChange={() => {
                  setIsReserve((prev) => {
                    const newValue = !prev;
                    return newValue;
                  });
                }}>
                예약하기
              </Checkbox>
            )}
            {isReserve && (
              <DateTimeButtonPicker
                minDate={moment().format('YYYY-MM-DD')}
                value={notificationData?.reservedAt}
                onChange={(value) => {
                  setNotificationData((prev) => {
                    const newValue = { ...prev };
                    newValue['reservedAt'] = value;
                    return newValue;
                  });
                }}></DateTimeButtonPicker>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export const PushNotificationPage: React.FC = () => {
  useAuthentication();
  const okCancelDialog = useOkCancelDialog();
  const [query, setQuery] = useState<PagenatedQuery>({ page: 0, size: 20 });
  const [editModal, setEditModal] = useState<boolean>(false);
  const [editNotificationData, setEditNotificationData] = useState<PushNotification>();

  const pushNotificationsQuery = useQuery({
    queryKey: [`admin-get-push-notifications`, JSON.stringify(query)],
    queryFn: () => api.getPushNotifications(query),
  });

  const mutationDeletePushNotification = useMutation({
    mutationFn: api.deletePushNotification,
    onSuccess: () => {
      pushNotificationsQuery.refetch();
    },
  });

  useEffect(() => {
    if (editNotificationData && editNotificationData._id) {
      setEditModal(true);
    }
  }, [editNotificationData]);

  useEffect(() => {
    if (!editModal) {
      setEditNotificationData(undefined);
    }
  }, [editModal]);

  const TABLE_COLUMNS: ColumnsType<PushNotification> = [
    {
      title: '발송대상',
      render: (record) => `${record.channelId || record.topic}`,
    },
    {
      title: '클릭수',
      align: 'right',
      width: 80,
      dataIndex: 'clickedCount',
      render: (clickedCount) => {
        return clickedCount || 0;
      },
    },
    {
      title: '제목',
      dataIndex: 'title',
      render: (title) => {
        return title;
      },
    },
    {
      title: '내용',
      dataIndex: 'body',
      render: (body) => {
        return body;
      },
    },
    {
      title: '디렉션',
      align: 'center',
      dataIndex: 'contentType',
      render: (contentType, record) => {
        let typeText = '-';
        let legacyId = '-';
        if (record.classId) {
          legacyId = record.classId;
        } else if (record.commerceId) {
          legacyId = record.commerceId;
        } else if (record.noticeId) {
          legacyId = record.noticeId;
        } else if (record.planningId) {
          legacyId = record.planningId;
        }
        const type = contentType ? contentType : record.pushType;
        const contentId = record.productId ? record.productId : record.collectionId ? record.collectionId : legacyId;
        if (type === LegacyPushNotificationContentType.PRODUCT || type === DeepLinkActions.GO_TO_PRODUCT) {
          typeText = `상품상세`;
        } else if (type === LegacyPushNotificationContentType.COLLECTION || type === DeepLinkActions.GO_TO_COLLECTION) {
          typeText = `기획전`;
        } else if (type === LegacyPushNotificationContentType.NOTICE || type === DeepLinkActions.GO_TO_NOTICE) {
          typeText = `공지사항`;
        } else if (type === LegacyPushNotificationContentType.PICK || type === DeepLinkActions.GO_TO_PICK) {
          typeText = `PICK`;
        } else if (type === LegacyPushNotificationContentType.LINK || type === DeepLinkActions.OPEN_LINK) {
          typeText = `링크`;
        } else if (type === DeepLinkActions.SEARCH_KEYWORD) {
          typeText = `검색어`;
        } else if (type === DeepLinkActions.GO_TO_THEME_COLLECTION) {
          typeText = `테마 기획전`;
        } else if (type === DeepLinkActions.GO_TO_CHAT_LIST) {
          typeText = `채팅목록`;
        } else if (type === DeepLinkActions.GO_TO_REWARD) {
          typeText = `리워드`;
        } else if (type === DeepLinkActions.GO_TO_COMMUNITY) {
          typeText = `커뮤니티`;
        } else if (type === LegacyPushNotificationContentType.NORMAL) {
          typeText = `일반`;
        } else {
          typeText = type;
        }
        return `${typeText}\n[${contentId}]`;
      },
    },
    {
      title: '상세경로',
      width: '200px',
      render: (record: PushNotification) => {
        if (record.content && record.content.title) {
          return record.content.title;
        } else if (record?.url) {
          return record.url;
        } else if (record?.keyword) {
          return record.keyword;
        } else {
          return '-';
        }
      },
    },
    {
      title: '예약시간',
      dataIndex: 'reservedAt',
      render: (reservedAt, record) => {
        if (reservedAt) {
          return moment(reservedAt).format('YYYY.MM.DD HH:mm');
        } else if (record.reserveDate) {
          return moment(record.reserveDate).format('YYYY.MM.DD HH:mm');
        } else {
          return '-';
        }
      },
    },
    {
      title: '발송상태',
      width: '140px',
      dataIndex: 'status',
      render: (status, record) => {
        let statusText = '-';
        if (status === PushNotificationStatus.SENT) {
          statusText = '발송 ✅';
        } else if (status === PushNotificationStatus.RESERVE) {
          statusText = '예약 🔵';
        } else if (status === PushNotificationStatus.RESERVE_CANCEL) {
          statusText = '예약취소 ❌';
        } else if (status === PushNotificationStatus.SENT_FAIL) {
          statusText = '발송실패';
        } else if (record.sendYn) {
          statusText = '발송 ✅';
        }

        if (record.topic === PushNotificationTopic.MANUAL) {
          const success = record.result?.successCount || 0;
          const failure = record.result?.failureCount || 0;

          statusText = `대상 유저: ${(record.targetUserIds || []).length}명\n발송: ${
            (record.result?.responses || []).length
          }\n성공: ${success}\n실패: ${failure}`;
        }
        return statusText;
      },
    },
    {
      title: '발송시간',
      dataIndex: 'sentAt',
      render: (sentAt, record) => {
        if (sentAt) {
          return moment(sentAt).format('YYYY.MM.DD HH:mm');
        } else if (record.sendDate) {
          return moment(record.sendDate).format('YYYY.MM.DD HH:mm');
        } else {
          return '-';
        }
      },
    },
    {
      title: '등록/수정일',
      dataIndex: 'createdAt',
      render: (createdAt, record) => {
        if (!createdAt) {
          return '-';
        }
        if (record.updatedAt) {
          return (
            <div style={{ width: '120px' }}>
              <div style={{ fontSize: '13px' }}>{moment(createdAt).format('YYYY.MM.DD HH:mm')}</div>
              <div style={{ fontSize: '13px', fontWeight: 'bold' }}>
                {moment(record.updatedAt).format('YYYY.MM.DD HH:mm')}
              </div>
            </div>
          );
        } else {
          return moment(createdAt).format('YYYY.MM.DD HH:mm');
        }
      },
    },
    {
      title: '수정',
      render: (record: PushNotification) => {
        const status = record.status;
        const isSend = status === PushNotificationStatus.SENT || record.sendYn;
        if (!isSend) {
          return (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <Button
                onClick={() => {
                  setEditNotificationData(record);
                }}>
                수정
              </Button>
              <Button
                style={{ color: 'red' }}
                onClick={() => {
                  okCancelDialog.open({
                    content: `예약된 푸시 알림을 삭제하시겠습니까?`,
                    onConfirm: () => {
                      mutationDeletePushNotification.mutate(record._id);
                    },
                  });
                }}>
                삭제
              </Button>
            </div>
          );
        } else {
          return '-';
        }
      },
    },
  ];

  return (
    <div className="dashboard" style={{ position: 'relative' }}>
      {editModal && (
        <PushNoticeEditModal
          open={!!editModal}
          onClose={() => setEditModal(false)}
          pushNotificationData={editNotificationData}
          needRefetch={() => {
            pushNotificationsQuery.refetch();
          }}></PushNoticeEditModal>
      )}

      <div className="page-header">
        <div>PUSH 메시지 관리</div>
      </div>
      <div style={{ display: 'flex' }}>
        <div className="page-content" style={{ width: '100%', height: 'calc(100vh - 80px)', overflow: 'auto' }}>
          <div style={{ display: 'flex', gap: '12px', width: '100%', height: '52px', alignItems: 'center' }}>
            <div style={{ display: 'flex', marginLeft: 'auto', gap: '12px', alignItems: 'center' }}>
              <Button
                onClick={() => {
                  setEditModal(true);
                }}>
                푸시 생성
              </Button>
            </div>
          </div>
          <Table
            columns={TABLE_COLUMNS}
            scroll={{ x: 'max-content', y: 'calc(100dvh - 360px)' }}
            style={{ whiteSpace: 'pre-wrap' }}
            rowKey={(record) => record?._id}
            dataSource={pushNotificationsQuery.data?.data.contents}
            pagination={{
              current: (query.page || 0) + 1,
              pageSize: query.size || 20,
              total: pushNotificationsQuery.data?.data.total,
            }}
            onChange={(e) => {
              setQuery({ page: (e.current || 2) - 1, size: e.pageSize || 20 });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PushNotificationPage;
