import * as api from '@apis/admin-notifications';
import { useAuthentication } from '@hooks/authentication';
import { KakaoReservedNotification } from '@models/notifications';
import { useKakaoReservedNotificationsStore } from '@stores/kakaoReservedNotificaionsStore';
import { useQuery } from '@tanstack/react-query';
import { localTimeToUtcISOString, utcTimcToLocalISOString } from '@utils/date';
import { Button, DatePicker, DatePickerProps, Input, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

export const KakaoReservedNotificationsPage: React.FC = () => {
  const [searchKeyword, setSearchKeyword] = useState<string>();
  const store = useKakaoReservedNotificationsStore();

  useAuthentication();

  const kakaoReservedNotificationsQuery = useQuery({
    queryKey: [`admin-kakao-reserved-notifications`, JSON.stringify(store.searchQuery)],
    queryFn: () => api.getKakaoReservedNotifications(store.searchQuery),
  });

  const onChangeStartDate: DatePickerProps['onChange'] = (date) => {
    store.setStartDate(localTimeToUtcISOString(date ? new Date(date.toISOString()) : new Date(), { timeToZero: true }));
  };

  const onChangeEndDate: DatePickerProps['onChange'] = (date) => {
    store.setEndDate(localTimeToUtcISOString(date ? new Date(date.toISOString()) : new Date(), { timeToZero: true }));
  };

  useEffect(() => {
    const searchResult = kakaoReservedNotificationsQuery.data?.data;
    if (searchResult) {
      store.setKakaoReservedNotificationsContainer(searchResult);
    }
  }, [kakaoReservedNotificationsQuery.data?.data]);

  const TABLE_COLUMNS: ColumnsType<KakaoReservedNotification> = [
    {
      title: 'ID',
      render: (record: KakaoReservedNotification) => {
        return (
          <Button
            onClick={() => {
              navigator.clipboard.writeText(record._id);
              toast.dark('ID를 클립보드에 복사했습니다', {
                position: 'top-center',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                pauseOnFocusLoss: false,
                draggable: true,
                progress: undefined,
                bodyStyle: {
                  color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                  textAlign: 'center',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '20px',
                },
                theme: 'dark',
              });
            }}>
            ID
          </Button>
        );
      },
    },
    {
      title: '타입',
      render: (record: KakaoReservedNotification) => `${record.templateName}`,
    },
    {
      title: '수신번호',
      width: 80,
      render: (record: KakaoReservedNotification) => {
        return record.recevierPhone;
      },
    },
    {
      title: '수신자',
      render: (record: KakaoReservedNotification) => {
        return record.receiverName;
      },
    },
    {
      title: '내용',
      render: (record: KakaoReservedNotification) => {
        return JSON.stringify(record.content, undefined, 2);
      },
    },
    {
      title: '상태',
      render: (record: KakaoReservedNotification) => {
        return record.status;
      },
    },
    {
      title: '참조',
      width: 100,
      render: (record: KakaoReservedNotification) => {
        if (record.ref.paymentStatementId) {
          return (
            <Button
              onClick={() => {
                navigator.clipboard.writeText(record.ref.paymentStatementId);
                toast.dark('ID를 클립보드에 복사했습니다', {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  pauseOnFocusLoss: false,
                  draggable: true,
                  progress: undefined,
                  bodyStyle: {
                    color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                    textAlign: 'center',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                  },
                  theme: 'dark',
                });
              }}>
              결제내역 ID 복사
            </Button>
          );
        }
        return <></>;
      },
    },
    {
      title: '예약',
      dataIndex: 'reservedAt',
      render: (reservedAt) => {
        if (!reservedAt) {
          return '-';
        }
        return moment(reservedAt).format('YYYY.MM.DD HH:mm');
      },
    },
    {
      title: '생성',
      dataIndex: 'createdAt',
      render: (createdAt) => {
        if (!createdAt) {
          return '-';
        }
        return moment(createdAt).format('YYYY.MM.DD HH:mm');
      },
    },
  ];

  return (
    <div className="dashboard" style={{ position: 'relative' }}>
      <div className="page-header">
        <div>카카오 알림톡 예약 발송 내역</div>
        <div style={{ display: 'flex' }}>
          <div style={{ marginLeft: '10px' }}>
            <DatePicker
              onChange={onChangeStartDate}
              value={dayjs(utcTimcToLocalISOString(store.searchQuery.startDate as any))}
            />
          </div>
          <div style={{ marginLeft: '8px' }}>
            <DatePicker
              onChange={onChangeEndDate}
              value={dayjs(utcTimcToLocalISOString(store.searchQuery.endDate as any))}
            />
          </div>
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <div className="page-content" style={{ width: '100%', height: 'calc(100vh - 80px)', overflow: 'auto' }}>
          <div style={{ display: 'flex', gap: '12px', width: '100%', height: '52px', alignItems: 'center' }}>
            <Select
              value={store.searchQuery.type || 'all'}
              style={{ width: 120 }}
              onChange={(value) => {
                store.updateSearchQuery({ type: value });
              }}
              options={[
                { value: 'all', label: '전체' },
                { value: 'success', label: '성공' },
                { value: 'error', label: '오류' },
              ]}
            />
            <Input
              style={{ width: '240px' }}
              value={searchKeyword}
              placeholder={'검색어 입력 후 엔터'}
              onChange={(e) => {
                setSearchKeyword(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.code === 'Enter' && searchKeyword) {
                  store.updateSearchQuery({ keyword: searchKeyword });
                }
              }}></Input>
            <Button
              onClick={() => {
                setSearchKeyword(undefined);
                store.resetSearchQuery();
              }}>
              검색 초기화
            </Button>
            <div>{`결과: ${store.searchResultContainer?.total || 0}개`}</div>
          </div>
          <Table
            columns={TABLE_COLUMNS}
            scroll={{ x: 'max-content', y: 'calc(100dvh - 340px)' }}
            style={{ whiteSpace: 'pre-wrap' }}
            rowKey={(record) => record?._id}
            dataSource={kakaoReservedNotificationsQuery.data?.data.contents}
            pagination={{
              current: (store.searchResultContainer.page || 0) + 1,
              pageSize: store.searchResultContainer.size || 20,
              total: store.searchResultContainer.total,
            }}
            loading={kakaoReservedNotificationsQuery.isLoading}
            onChange={(e) => {
              store.updateSearchQuery({ page: (e.current || 2) - 1, size: e.pageSize || 20 });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default KakaoReservedNotificationsPage;
