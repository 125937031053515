import { PaymentStatementSettlement } from './paymentStatement';

export interface SalesChannelOrder {
  _id: string;
  uid: string;
  buyer: Buyer;
  channel: string;
  createdAt?: string;
  memo: Memo;
  origin: Origin;
  payment: Payment;
  confirmPurchaseNotificationDate: Date;
  purchaseNotificationDate: Date;
  refundNotificationDate: Date;
  plannedDate: Date;
  product: Product;
  purchase: Purchase;
  purchases: Purchase[];
  refunds: Purchase[];
  receiver: Receiver;
  seller: Seller;
  statuses: Statuses;
  updatedAt?: string;
  sellerUser: SellerUser;
  refund: SalesChannelOrderRefund;
  settlement?: PaymentStatementSettlement;
}

export interface SalesChannelOrderRefund {
  data?: any;
  amount?: number;
  updatedAt?: Date;
  createdAt?: Date;
}

export interface Buyer {
  name: string;
  phone: string;
  nId?: string;
  id?: string;
}

export interface Memo {
  payment?: string;
  shipping?: string;
  refund?: string;
  bankAccountTransfer?: string;
}

export interface Origin {
  orderId: string;
  buyerName: string;
  receiverName: string;
  productName: string;
  option: string;
  quantity: number;
  paidAt: number;
  orderStatus: string;
  device: string;
  buyerId: string;
  shippingMethodRequested: string;
  orderNumber: string;
  productNumber: string;
  shippingMethod: string;
  shippingAgent: any;
  shippingInvoiceNumber: any;
  optionFee: number;
  shippingDate: number;
  orderDetailStatus: string;
  productType: string;
  productPrice: number;
  productDiscountPriceTotal: number;
  productDiscountPrice: number;
  orderPrice: number;
  gift: any;
  orderCheckDate: number;
  shippingDeadlineDate: number;
  shippingProceedDate: any;
  shippingInvoicePrintDate: any;
  shippingFeeFormType: any;
  shippingFeeNumber: any;
  shippingFeeType: any;
  shippingFeeTotal: number;
  shippingFeeAdditional: any;
  shippingFeedDiscount: number;
  sellerProductCode: any;
  sellerCode1: any;
  sellerCode2: any;
  receiverPhone1: string;
  receiverPhone2: any;
  address: any;
  addressBase: any;
  addressDetail: any;
  buyerPhone: string;
  addressPostCode: any;
  deliveryMemo: any;
  shippingLocation: any;
  paymentMethod: string;
  paymentCommissionType: string;
  paymentCommissionBillingType: string;
  naverPayOrderCommission: number;
  naverPayIncomeCommission: number;
  expectedSettlementAmount: number;
  referer: string;
  personalClearanceCode: any;
  orderedAt: number;
  subscribeAppliedCount: number;
  subscribeProcessCount: number;
  shippingReceiptDate: any;
}

export interface Payment {
  method: string;
  amount: number;
  expectedAmount?: number;
  billingAmount: number;
  paidAt: string | Date;
  bankAccountTransferAmount?: number;
}

export interface Product {
  name: string;
  saleType?: string;
}

export interface Purchase {
  name: string;
  fee: number;
  count: number;
  uid?: string;
  status?: string;
}

export interface Receiver {
  name: string;
  phone: string;
  phone2: any;
}

export interface Seller {
  id?: string;
  name?: string;
  phone?: string;
}

export interface Statuses {
  payment?: string;
  refund: string;
  settlement?: string;
}

export interface SellerUser {
  id: string;
  loginType: string;
  role: string;
  profile: Profile;
}

export interface Profile {
  name: string;
  phone: string;
  customerServicePhone: string;
}

export interface SalesChannelProductUser {
  id?: string;
  name: string;
  phone?: string;
}

export interface SalesChannelProduct {
  _id: string;
  type: string; // naver-store
  title: string;
  seller: SalesChannelProductUser;
  price: number;
  status: string;
  createdAt: Date;
  updatedAt: Date;
}

export type NaverCommerceRangeType =
  | 'PAYED_DATETIME'
  | 'DISPATCHED_DATETIME'
  | 'CLAIM_REQUESTED_DATETIME'
  | 'CLAIM_COMPLETED_DATETIME';

export interface Content {
  order: Order;
  productOrder: ProductOrder;
  currentClaim: CurrentClaim;
  delivery: Delivery;
}

export interface Order {
  orderId: string;
  orderDate: string;
  ordererId: string;
  ordererNo: string;
  ordererName: string;
  ordererTel: string;
  isDeliveryMemoParticularInput: string;
  paymentDate: string;
  paymentMeans: string;
  payLocationType: string;
  orderDiscountAmount: number;
  generalPaymentAmount: number;
  naverMileagePaymentAmount: number;
  chargeAmountPaymentAmount: number;
  payLaterPaymentAmount: number;
}

export interface ProductOrder {
  productOrderId: string;
  quantity: number;
  totalProductAmount: number;
  totalPaymentAmount: number;
  productOrderStatus: string;
  productId: string;
  productName: string;
  unitPrice: number;
  productClass: string;
  originalProductId: string;
  merchantChannelId: string;
  deliveryAttributeType: string;
  placeOrderDate: string;
  placeOrderStatus: string;
  shippingDueDate: string;
  expectedDeliveryMethod: string;
  shippingMemo: string;
  packageNumber: string;
  itemNo: string;
  productOption: string;
  optionCode: string;
  optionPrice: number;
  mallId: string;
  inflowPath: string;
  productDiscountAmount: number;
  sellerBurdenDiscountAmount: number;
  productImediateDiscountAmount: number;
  sellerBurdenImediateDiscountAmount: number;
  shippingAddress: ShippingAddress;
  claimId: string;
  claimStatus: string;
  claimType: string;
  commissionRatingType: string;
  commissionPrePayStatus: string;
  paymentCommission: number;
  saleCommission: number;
  knowledgeShoppingSellingInterlockCommission: number;
  channelCommission: number;
  expectedSettlementAmount: number;
}

export interface ShippingAddress {
  name: string;
  tel1: string;
}

export interface CurrentClaim {
  return?: Return;
  cancel?: Cancel;
}

export interface Return {
  claimId: string;
  claimStatus: string;
  returnCompletedDate: string;
  returnDetailedReason: string;
  returnReason: string;
  claimRequestDate: string;
  requestChannel: string;
  collectCompletedDate: string;
  refundStandbyStatus: string;
}

export interface Cancel {
  claimId: string;
  cancelReason: string;
  cancelDetailedReason: string;
  claimRequestDate: string;
  claimStatus: string;
  requestChannel: string;
}

export interface Delivery {
  deliveredDate: string;
  deliveryMethod: string;
  deliveryStatus: string;
  sendDate: string;
  isWrongTrackingNumber: boolean;
}

export interface NaverCommerceOrder {
  _id: string;
  productOrderId: string;
  content: Content;
  createdAt: Date;
  updatedAt: Date;
}

export interface OriginProduct {
  statusType: string;
  saleType: string;
  leafCategoryId: string;
  name: string;
  detailContent: string;
  images: Images;
  salePrice: number;
  stockQuantity: number;
  detailAttribute: DetailAttribute;
  customerBenefit: CustomerBenefit;
}

export interface Images {
  representativeImage: RepresentativeImage;
  optionalImages: OptionalImage[];
}

export interface RepresentativeImage {
  url: string;
}

export interface OptionalImage {
  url: string;
}

export interface DetailAttribute {
  naverShoppingSearchInfo: NaverShoppingSearchInfo;
  afterServiceInfo: AfterServiceInfo;
  originAreaInfo: OriginAreaInfo;
  optionInfo: OptionInfo;
  supplementProductInfo: SupplementProductInfo;
  purchaseReviewInfo: PurchaseReviewInfo;
  taxType: string;
  certificationTargetExcludeContent: CertificationTargetExcludeContent;
  sellerCommentContent: string;
  sellerCommentUsable: boolean;
  minorPurchasable: boolean;
  productInfoProvidedNotice: ProductInfoProvidedNotice;
  productAttributes: ProductAttribute[];
  itselfProductionProductYn: boolean;
  seoInfo: SeoInfo;
  productSize: ProductSize;
}

export interface NaverShoppingSearchInfo {
  manufacturerName: string;
  brandName: string;
  catalogMatchingYn: boolean;
}

export interface AfterServiceInfo {
  afterServiceTelephoneNumber: string;
  afterServiceGuideContent: string;
}

export interface OriginAreaInfo {
  originAreaCode: string;
  content: string;
  plural: boolean;
}

export interface OptionInfo {
  simpleOptionSortType: string;
  optionSimple: any[];
  optionCustom: OptionCustom[];
  optionCombinationSortType: string;
  optionCombinationGroupNames: OptionCombinationGroupNames;
  optionCombinations: OptionCombination[];
  remainOptionCombinations?: OptionCombination[];
  useStockManagement: boolean;
  optionDeliveryAttributes: any[];
}

export interface OptionCustom {
  id: number;
  groupName: string;
  usable: boolean;
}

export interface OptionCombinationGroupNames {
  optionGroupName1: string;
  optionGroupName2: string;
}

export interface OptionCombination {
  id: number;
  optionName1: string;
  optionName2?: string;
  optionName3?: string;
  stockQuantity: number;
  price: number;
  usable: boolean;
  date?: Date;
}

export interface SupplementProductInfo {
  sortType: string;
  supplementProducts: SupplementProduct[];
}

export interface SupplementProduct {
  id: number;
  groupName: string;
  name: string;
  price: number;
  stockQuantity: number;
  usable: boolean;
}

export interface PurchaseReviewInfo {
  purchaseReviewExposure: boolean;
}

export interface CertificationTargetExcludeContent {
  kcCertifiedProductExclusionYn: string;
}

export interface ProductInfoProvidedNotice {
  productInfoProvidedNoticeType: string;
  etc: Etc;
}

export interface Etc {
  returnCostReason: string;
  noRefundReason: string;
  qualityAssuranceStandard: string;
  compensationProcedure: string;
  troubleShootingContents: string;
  itemName: string;
  modelName: string;
  certificateDetails: string;
  manufacturer: string;
  customerServicePhoneNumber: string;
}

export interface ProductAttribute {
  attributeSeq: number;
  attributeValueSeq: number;
}

export interface SeoInfo {
  sellerTags: SellerTag[];
}

export interface SellerTag {
  code?: number;
  text: string;
}

export interface ProductSize {}

export interface CustomerBenefit {
  immediateDiscountPolicy?: {
    discountMethod?: { value?: number; unitType?: string };
    mobileDiscountMethod?: { value?: number; unitType?: string };
  };
}

export interface SmartstoreChannelProduct {
  channelProductName: string;
  storeKeepExclusiveProduct: boolean;
  naverShoppingRegistration: boolean;
  channelProductDisplayStatusType: string;
}

export interface NaverCommerceProduct {
  _id: string;
  channelProductNo: string;
  originProduct: OriginProduct;
  smartstoreChannelProduct: SmartstoreChannelProduct;
  createdAt: Date;
  updatedAt: Date;
}

export enum SalesChannelsType {
  NAVER_STORE = 'naver-store',
  INBOUND_NAVER = 'inbound-naver',
  INBOUND_APP = 'inbound-app',
  KAKAO_RESERVATION = 'kakao-reservation',
}

export const NaverCommerceProductStatusLabel: { [key: string]: string } = {
  SALE: '판매중',
  OUTOFSTOCK: '품절',
  WAIT: '판매 대기',
  SUSPENSION: '판매 중지',
};

export const NaverCommerceProductDisplayStatusLabel: { [key: string]: string } = {
  ON: '전시중',
  WAIT: '전시 대기',
  SUSPENSION: '전시 중지',
};
