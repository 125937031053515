import * as creditPointTemplateApi from '@apis/admin-credit-points';
import * as api from '@apis/admin-user';
import { AdminUser } from '@models/auth';
import { AdminCreditPointLog } from '@models/credit-points';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Button, Input, Modal, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

interface UserPointEditModalProps {
  open?: boolean;
  onClose?: () => void;
  user?: AdminUser;
  needRefetch?: () => void;
}

export const UserPointEditModal: React.FC<UserPointEditModalProps> = (props) => {
  const [editPointAmount, setEditPointAmount] = useState<number>(0);
  const [editPointMemo, setEditPointMemo] = useState<string>('');
  const dialog = useOkCancelDialog();
  const queryClient = useQueryClient();

  const creditPointTemplatesQuery = useQuery({
    queryKey: [`admin-credit-point-templates`],
    queryFn: () => creditPointTemplateApi.getCreditPointTemplates(),
  });

  const updateUserPointMutation = useMutation({
    mutationFn: api.updateUserPoint,
    onSuccess: async () => {
      props.needRefetch && props.needRefetch();
      queryClient.invalidateQueries({ queryKey: [`admin-credit-point-logs-${user?._id}`] });
      dialog.open({
        content: '저장되었습니다.',
        type: 'ok',
      });
    },
  });

  const creditPointTemplates = creditPointTemplatesQuery.data?.data || [];
  const user = props.user;

  const userCreditPointLogsQuery = useQuery({
    queryKey: [`admin-credit-point-logs-${user?._id}`],
    queryFn: () => user && api.getUserCreditPointLogs(user._id),
  });

  const creditPointLogs = userCreditPointLogsQuery?.data?.data || [];

  useEffect(() => {
    setEditPointAmount(0);
    setEditPointMemo('');
  }, [props.open]);

  const TABLE_COLUMNS: ColumnsType<AdminCreditPointLog> = [
    {
      title: '구분',
      width: '80px',
      align: 'center',
      dataIndex: 'flag',
      render: (flag) => {
        return (
          <div
            style={{
              color: 'white',
              borderRadius: 20,
              padding: '2px',
              backgroundColor: flag === '+' ? '#0180ef' : '#d944db',
            }}>
            {flag === '+' ? '적립' : '차감'}
          </div>
        );
      },
    },
    {
      title: '내용',
      dataIndex: 'content',
      align: 'center',
      render: (content) => {
        return content;
      },
    },
    {
      title: '포인트',
      width: '100px',
      align: 'center',
      dataIndex: 'amount',
      render: (amount: number) => {
        return amount?.toLocaleString();
      },
    },
    {
      title: '생성일',
      width: '120px',
      align: 'center',
      dataIndex: 'createdAt',
      render: (createdAt) => {
        return moment(createdAt).format('YYYY. MM. DD');
      },
    },
  ];

  return (
    <Modal
      okText={undefined}
      width={600}
      open={props.open}
      onCancel={() => {
        props.onClose && props.onClose();
      }}
      footer={[<Button onClick={props.onClose}>취소</Button>]}>
      <div>
        <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{`${user?.profile?.name}님 포인트 수정`}</div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}>{`현재 포인트: ${user?.profile?.creditPoints}`}</div>
        <div>선택하기</div>
        <div>
          <Select
            style={{ width: '100%' }}
            placeholder={'미리 정의된 포인트 선택'}
            onChange={(value) => {
              const found = creditPointTemplates.find((template) => template._id === value);
              if (found) {
                setEditPointAmount(found.creditPoint);
                setEditPointMemo(found.creditPointName);
              }
            }}
            options={
              creditPointTemplates
                .map((template) => {
                  if (template.creditPointStatus && template.creditPointStatus) {
                    return { value: template._id, label: template.creditPointName };
                  }
                  return undefined;
                })
                .filter((template) => !!template) as { value: string; label: string }[]
            }
          />
        </div>
        <div style={{ marginTop: '12px' }}>변경 예정 포인트</div>
        <div>
          <Input
            value={editPointAmount}
            onChange={(e) => {
              setEditPointAmount(parseInt(e.target.value) || 0);
            }}></Input>
        </div>
        <div style={{ marginTop: '12px' }}>포인트 변경 사유</div>
        <div>
          <Input
            value={editPointMemo}
            onChange={(e) => {
              setEditPointMemo(e.target.value);
            }}></Input>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '24px' }}>
          <Button
            onClick={() => {
              if (user?._id) {
                updateUserPointMutation.mutate({
                  userId: user?._id,
                  amount: editPointAmount,
                  content: editPointMemo,
                  type: 'add',
                });
              }
            }}>
            포인트 지급
          </Button>
          <Button
            onClick={() => {
              if (user?._id) {
                updateUserPointMutation.mutate({
                  userId: user?._id,
                  amount: editPointAmount,
                  content: editPointMemo,
                  type: 'subtract',
                });
              }
            }}>
            포인트 차감
          </Button>
        </div>
        <div style={{ marginTop: '20px' }}>
          <div>포인트 적립 내역</div>
          <Table columns={TABLE_COLUMNS} dataSource={creditPointLogs} pagination={{ defaultPageSize: 5 }} />
        </div>
      </div>
    </Modal>
  );
};

export default UserPointEditModal;
