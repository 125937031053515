import { useQuery } from '@tanstack/react-query';
import { Button, Input, Select } from 'antd';
import React, { useRef, useState } from 'react';
import * as usersApi from '@apis/admin-user';
import { AdminUser } from '@models/auth';
import { SearchPagenateQuery } from '@models/common';

interface SelectParentProps {
  selectedUserId?: string;
  onSelect?: (value: AdminUser) => void;
  style?: any;
}

const PAGE_SIZE = 20;

export const SelectParent: React.FC<SelectParentProps> = (props) => {
  const userSearchInputRef = useRef<any>();
  const [searchQuery, setSearchQuery] = useState<SearchPagenateQuery>({
    page: 0,
    size: PAGE_SIZE,
    type: 'parent',
    startDate: undefined,
    endDate: undefined,
  });

  const userSerchQuery = useQuery({
    queryKey: [`admin-search-users-parent`, JSON.stringify(searchQuery)],
    queryFn: () => usersApi.searchUsers({ ...searchQuery, page: 0, size: 50000 }),
  });

  const users = userSerchQuery.data?.data?.contents || [];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', ...props.style }}>
      <div style={{ display: 'flex', gap: '8px' }}>
        <Input
          ref={userSearchInputRef}
          onKeyUp={(e) => {
            if (e.code === 'Enter') {
              if (userSearchInputRef.current?.input.value) {
                setSearchQuery((prev) => {
                  return { ...prev, keyword: userSearchInputRef.current.input.value, page: 0, size: 500 };
                });
              } else {
                setSearchQuery({
                  page: 0,
                  size: PAGE_SIZE,
                  type: 'parent',
                  startDate: undefined,
                  endDate: undefined,
                });
              }
            }
          }}></Input>
        <Button
          onClick={() => {
            if (userSearchInputRef.current?.input.value) {
              setSearchQuery((prev) => {
                return { ...prev, keyword: userSearchInputRef.current.input.value, page: 0, size: 500 };
              });
            } else {
              setSearchQuery({
                page: 0,
                size: PAGE_SIZE,
                type: 'parent',
                startDate: undefined,
                endDate: undefined,
              });
            }
          }}>
          검색
        </Button>
      </div>
      <div style={{ display: 'flex', marginTop: '12px', gap: '12px', alignItems: 'center' }}>
        <Select
          style={{ width: '100%' }}
          placeholder={'구매자를 선택해주세요'}
          value={props.selectedUserId}
          onSelect={(_id) => {
            const selectedUser = users.find((user) => user._id === _id);
            if (selectedUser) {
              props.onSelect && props.onSelect(selectedUser);
            }
          }}>
          {users.map((item) => {
            if (!item?._id) {
              return undefined;
            }

            const phoneNum = item.profile?.phone || '';
            let name = `${item.profile?.name}(${phoneNum.substring(phoneNum.length - 4)})[${item._id}]`;
            if (item.deletedAt) {
              name = `탈퇴한 사용자(${item.profile?.name})`;
            } else if (item.profile?.nickname) {
              name = `${item.profile?.name}(${item.profile?.nickname})[${item.roles}]`;
            }
            return (
              <Select.Option key={item._id} value={item._id}>
                {name}
              </Select.Option>
            );
          })}
        </Select>
        <div style={{ flexShrink: 0 }}>{`${users.length || 0}명`}</div>
      </div>
    </div>
  );
};
