import React from 'react';
import { MainFeedCellProps } from '.';
import { Carousel } from '@components/common/Carousel';
import { cdnImageUrl } from '@utils/image';
import { actionStringFromItem } from '@utils/string';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';

export const VerticalBannerCell: React.FC<MainFeedCellProps> = (props) => {
  const data = props.data || [];
  const dialog = useOkCancelDialog();
  return (
    <div id="vertical-banner-cell" style={{ width: '100%', height: '220px', overflow: 'hidden' }}>
      <Carousel
        options={{ dragFree: true, containScroll: 'trimSnaps', align: 'start' }}
        autoplay
        styles={{ containerStyle: { margin: '0 6px 0 16px' } }}>
        {data
          .filter((item: any) => item.imageUrl && item.imageUrl !== '')
          .map((item: any, index: number) => {
            return (
              <div key={`vertical-slide-${index}`} className={'embla__slide'} style={{ flex: '0 0 170px' }}>
                <div
                  style={{
                    width: '160px',
                    marginRight: '12px',
                    borderRadius: '8px',
                    height: '220px',
                    backgroundColor: 'white',
                    position: 'relative',
                    overflow: 'hidden',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    dialog.open({
                      type: 'ok',
                      confirmColor: '#FF3D8F',
                      title: '이동 정보',
                      content: actionStringFromItem(item),
                    });
                  }}>
                  <img
                    style={{ width: '160px', height: '220px', objectFit: 'cover' }}
                    src={cdnImageUrl(item.imageUrlTemp || item.imageUrl)}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      bottom: 0,
                      marginLeft: '12px',
                      marginBottom: '12px',
                    }}>
                    <div
                      style={{
                        color: 'white',
                        fontSize: '15px',
                        fontWeight: 700,
                        lineHeight: 1.4,
                        whiteSpace: 'pre-wrap',
                      }}>
                      {item.mainCopy1}
                      <br />
                      {item.mainCopy2}
                    </div>
                    <div
                      style={{
                        marginTop: '4px',
                        fontSize: '12px',
                        color: 'white',
                        opacity: 0.8,
                        lineHeight: 1.4,
                      }}>
                      {item.subCopy}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </Carousel>
    </div>
  );
};

export default VerticalBannerCell;
