import { TextInput } from '@components/common/TextInput';
import useAddAccountTransferModalStore from '@stores/addAccountTransferModalStore';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { useMutation } from '@tanstack/react-query';
import { Button, Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import * as paymentStatementApi from '@apis/payment-statement';
import * as salesChannelApi from '@apis/sales-channels';

export const AddAccountTransferModal: React.FC = () => {
  const { show, close, data } = useAddAccountTransferModalStore();
  const [memo, setMemo] = useState<string>();
  const [amount, setAmount] = useState<number>();
  const dialog = useOkCancelDialog();
  const accountTransferedAmount = data?.payment?.bankAccountTransferAmount || 0;

  const updatePaymentStatementMutation = useMutation({
    mutationFn: paymentStatementApi.addPaymentStatementAccountTransferAmount,
    onSuccess: async () => {
      onSuccess();
    },
    onError: () => {},
  });

  const updateSalesChannelOrderMutation = useMutation({
    mutationFn: salesChannelApi.addSalesChannelOrderAccountTransferAmount,
    onSuccess: async () => {
      onSuccess();
    },
    onError: () => {},
  });

  const validate = () => {
    return !!(amount !== undefined && amount > 0 && !isNaN(amount));
  };

  const updatePayment = () => {
    if (validate()) {
      const channel = data?.channel;
      const updateData: any = {
        id: data._id!,
        body: { amount: amount || 0, memo: memo || '', channel: data?.channel },
      };

      if (channel) {
        updateData['channel'] = channel === 'naver-store' ? 'naver' : channel;
        updateSalesChannelOrderMutation.mutate(updateData);
      } else {
        delete updateData.body.channel;
        updatePaymentStatementMutation.mutate(updateData);
      }
    } else {
      dialog.open({
        type: 'cancel',
        content: '금액을 확인해주세요.',
      });
    }
  };

  const clear = () => {
    setAmount(undefined);
    setMemo(undefined);
  };

  const onSuccess = () => {
    clear();
    close();
    dialog.open({
      type: 'ok',
      content: '계좌이체 금액 추가 되었습니다.',
    });
  };

  useEffect(() => {
    if (data.memo?.bankAccountTransfer) {
      setMemo(data?.memo?.bankAccountTransfer);
    }
  }, [data.memo]);

  if (!show) {
    return <></>;
  }
  return (
    <Modal
      centered
      open={show}
      width={480}
      closable={false}
      footer={
        <div>
          <Button
            onClick={() => {
              updatePayment();
            }}>
            반영하기
          </Button>
          <Button
            onClick={() => {
              clear();
              close();
            }}>
            취소
          </Button>
        </div>
      }
      onCancel={() => {
        clear();
        close();
      }}>
      <div>
        <div>
          <div style={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'center' }}>계좌이체 금액 추가</div>
          <div style={{ fontWeight: 'bold', marginTop: '10px' }}>
            <div>- 구매자: {`${data?.buyer?.name}(${data?.buyer?.phone})`}</div>
            <div>- 상품: {data?.product?.name}</div>
          </div>
          <div
            style={{
              padding: '12px 0',
              display: 'flex',
              flexDirection: 'column',
              gap: '12px',
              alignItems: 'flex-start',
            }}>
            <div>
              <div style={{ fontWeight: 'bold' }}>누적 추가 금액</div>
              <div>{accountTransferedAmount}원</div>
            </div>
            <div>
              <div style={{ fontWeight: 'bold' }}>추가 금액 입력</div>
              <TextInput
                type="number"
                value={amount || ''}
                placeholder="추가 금액 입력"
                onChangeValue={(value) => {
                  setAmount(Number(value));
                }}
              />
            </div>
            <div style={{ width: '100%' }}>
              <div style={{ fontWeight: 'bold' }}>메모(입금자/금액/일시를 남겨주세요!)</div>
              <Input.TextArea
                value={memo}
                placeholder="메모 입력"
                onChange={(e) => {
                  setMemo(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddAccountTransferModal;
