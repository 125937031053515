import * as commonApi from '@apis/common';
import * as api from '@apis/payment-statement';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { usePaymentStore } from '@stores/paymentStore';
import { useMutation, useQueryClient } from '@tanstack/react-query';
// import { loadPaymentWidget } from '@tosspayments/payment-widget-sdk';
import {
  AdminRefundBody,
  PaymentStatement,
  PaymentStatementPurchase,
  UpdatePaymentStatementEventType,
} from '@models/paymentStatement';
import { PaymentMethodType, RequestPayParams, RequestPayResponse } from '@type/portone';
import { API_URL, IMP_MERCHANT_ID_CLASS, IMP_MERCHANT_ID_COMMERCE, IMP_PAYMENT_HOOK_PATH } from '@variables';
import moment from 'moment';
import { useEffect } from 'react';
import { useAppSocket } from './appSocket';
import { toast } from 'react-toastify';

export const usePayment = () => {
  const paymentStore = usePaymentStore();
  const appSocket = useAppSocket();
  const dialog = useOkCancelDialog();
  const queryClient = useQueryClient();

  const clear = () => {
    paymentStore.setStatus('ready');
    paymentStore.setPaymentStatement(undefined);
  };

  const onPaymentResult = (data: PaymentStatement) => {
    if ((data.statuses.payment || '').toLocaleLowerCase() === 'done') {
      paymentStore.setStatus('done');
    } else {
      paymentStore.setStatus('error');
      dialog.open({
        type: 'ok',
        confirmColor: '#FF3D8F',
        title: '결제에 실패했습니다.',
        content: data.payment.result.fail_reason,
      });
    }
    paymentStore.setPaymentStatement(data);
  };

  useEffect(() => {
    appSocket.socket.addSocketEventListener('payment_result', 'payment_result', onPaymentResult);
    return () => {
      appSocket.socket.removeSocketEventListener('payment_result', 'payment_result');
    };
  }, []);

  const payWithIamport = async (paymentStatement: PaymentStatement) => {
    const iamportRequestData: RequestPayParams = {
      pg: paymentStatement.payment.gateway,
      pay_method: paymentStatement.payment.method as PaymentMethodType,
      name: paymentStatement.product.name,
      amount: paymentStatement.payment.billingAmount,
      buyer_name: paymentStatement.buyer.name, // 구매자 이름
      buyer_tel: paymentStatement.buyer.phone, // 구매자 연락처
      buyer_email: '',
      buyer_addr: '',
      niceMobileV2: true,
      merchant_uid: paymentStatement.uuid,
      buyer_postcode: '06018',
      app_scheme: 'igogopayment',
      m_redirect_url: window.location.href,
      notice_url: `${API_URL}${IMP_PAYMENT_HOOK_PATH}`,
    };

    if (paymentStatement.payment.method === 'naverpay') {
      let naverChainId = 'd2xqSzVzc2xPa3F'; // 비실물 원데이 기본
      if (paymentStatement.purchases.length > 1) {
        naverChainId = 'ODJZd1NrWXV6RTJ';
      }

      if (paymentStatement.shipping) {
        naverChainId = 'MUdsWmQ4S2JjZkQ';
      }

      const categoryType = paymentStatement.shipping ? 'PRODUCT' : 'ETC';
      const categoryId = paymentStatement.shipping ? 'GENERAL' : 'ETC';
      const naverProducts = paymentStatement.purchases.map((item) => {
        return {
          uid: paymentStatement.product.id,
          categoryId,
          categoryType,
          name: item.name,
          count: item.count,
        };
      });

      iamportRequestData['naverChainId'] = naverChainId;
      iamportRequestData['naverProducts'] = naverProducts;
      iamportRequestData['naverUseCfm'] = moment().format('YYYYMMDD');
    } else {
      if (paymentStatement.product.saleType === 'commerce' && IMP_MERCHANT_ID_COMMERCE) {
        iamportRequestData['pg'] = `${paymentStatement.payment.gateway}.${IMP_MERCHANT_ID_COMMERCE}`;
      } else if (paymentStatement.product.saleType === 'class' && IMP_MERCHANT_ID_CLASS) {
        iamportRequestData['pg'] = `${paymentStatement.payment.gateway}.${IMP_MERCHANT_ID_CLASS}`;
      }
    }

    iamportRequestData['custom_data'] = paymentStatement;

    const { IMP } = window;
    paymentStore.setStatus('in_progress');

    if (IMP) {
      IMP.request_pay(iamportRequestData, (response: RequestPayResponse) => {
        const { success, error_msg } = response;

        if (!success) {
          paymentStore.setStatus('cancel');
          dialog.open({ type: 'ok', confirmColor: '#FF3D8F', title: '결제에 실패했습니다.', content: error_msg });
          paymentStatement.statuses.payment = 'cancel';
          const data = {
            data: paymentStatement,
            event: UpdatePaymentStatementEventType.PAYMENT_CANCEL,
          };
          paymentStatementUpdateMutation.mutate(data);
        }
      });
    }
  };

  const paymentStatementMutation = useMutation({
    mutationFn: commonApi.createPaymentStatement,
    onSuccess: async (response) => {
      const paymentStatement = response.data;

      if (paymentStatement.payment.billingAmount > 0) {
        // if (paymentAgency === 'toss') {
        //   payWithTossPayments(paymentStatement);
        // } else {
        payWithIamport(paymentStatement);
        // }
      }
    },
  });

  const paymentStatementUpdateMutation = useMutation({
    mutationFn: commonApi.updatePaymentStatement,
    onSuccess: async (response) => {
      const paymentStatement = response.data;
      paymentStore.setPaymentStatement(paymentStatement);
    },
  });

  const requestRefundWithBodyMutation = useMutation({
    mutationFn: api.requestRefundWithBody,
    onSuccess: (response) => {
      const paymentStatement = response.data;
      paymentStore.setPaymentStatement(paymentStatement);
      queryClient.invalidateQueries({ queryKey: ['admin-search-payment-statement'] });
    },
  });

  const requestRefundWithBody = async (paymentStatementId: string, body: AdminRefundBody) => {
    const data = {
      paymentStatementId,
      data: body,
    };
    return await requestRefundWithBodyMutation.mutateAsync(data);
  };

  const requestRefundsMutation = useMutation({
    mutationFn: api.requestRefunds,
    onSuccess: (response) => {
      const result: {
        targetIds: string[];
        success: number;
        failed: number;
        successIds: string[];
        failedIds: string[];
      } = response.data;
      dialog.open({
        title: '환불 처리 완료',
        content: `${result.targetIds.length}개 대상\n${result.success} 성공\n${result.failed} 실패`,
        type: 'ok',
      });
      queryClient.invalidateQueries({ queryKey: ['admin-search-payment-statement'] });
    },
    onError: async (e: any) => {
      dialog.open({
        title: '오류',
        content: e?.response?.data?.message,
        type: 'ok',
      });
    },
  });

  const requestRefunds = async (paymentStatementIds: string[]) => {
    return await requestRefundsMutation.mutateAsync({ paymentStatementIds });
  };

  const updateRefundStatusMutation = useMutation({
    mutationFn: api.updateRefundStatus,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['admin-search-payment-statement'] });
      toast.dark('환불 상태를 성공적으로 변경했습니다.', {
        pauseOnFocusLoss: false,
        bodyStyle: {
          textAlign: 'center',
        },
      });
    },
    onError: () => {
      toast.dark('환불 상태 변경에 실패했습니다.', {
        pauseOnFocusLoss: false,
        bodyStyle: {
          textAlign: 'center',
        },
      });
    },
  });

  const updateRefundStatus = async (
    id: string,
    event: UpdatePaymentStatementEventType,
    refundItems?: PaymentStatementPurchase[],
    referenceId?: string,
    memo?: string,
    noMoreRefundsAvailable?: boolean,
  ) => {
    const data = {
      paymentStatementId: id,
      body: {
        event,
        refundItems,
        referenceId,
        memo,
        noMoreRefundsAvailable,
      },
    };
    await updateRefundStatusMutation.mutateAsync(data);
  };

  const registerRefundsMutation = useMutation({
    mutationFn: api.registerRefunds,
    onSuccess: async (response) => {
      const result: {
        targetIds: string[];
        success: number;
        failed: number;
        successIds: string[];
        failedIds: string[];
      } = response.data;

      dialog.open({
        title: '환불 예약 완료',
        content: `${result?.targetIds?.length}개 대상\n${result?.success} 성공\n${result?.failed} 실패`,
        type: 'ok',
      });
      await queryClient.invalidateQueries({ queryKey: ['admin-search-payment-statement'] });
    },
    onError: (e: any) => {
      dialog.open({
        title: '오류',
        content: e?.response?.data?.message,
        type: 'ok',
      });
    },
  });

  const registerRefunds = async (paymentStatementIds: string[]) => {
    await registerRefundsMutation.mutateAsync({ paymentStatementIds });
  };

  return {
    requestRefundWithBody,
    requestRefunds,
    updateRefundStatus,
    registerRefunds,
    requestPayment: paymentStatementMutation.mutate,
    status: paymentStore.status,
    paymentStatement: paymentStore.paymentStatement,
    clear: clear,
  };
};
