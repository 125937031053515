import * as productApi from '@apis/app-products';
import * as userApi from '@apis/app-user';
import BottomRoundButton from '@components/common/BottomRoundButton';
import { ClassDates, ClassFee, ClassTimes, CreateClassInvoiceData, StudentInfo } from '@models/invoice';
import { useQuery } from '@tanstack/react-query';
import { cdnImageUrl } from '@utils/image';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React, { useEffect, useState } from 'react';
import ClassAddressView from '../../class-invoice/ClassAddressView';
import ClassDateEdit from '../../class-invoice/ClassDateEdit';
import ClassFeeView from '../../class-invoice/ClassFeeView';
import ClassTimesEdit from '../../class-invoice/ClassTimesEdit';
import Divider from '../../class-invoice/Divider';
import StudentEdit from '../../class-invoice/StudentEdit';
import { ClassInquiryInfo } from '../ClassInquiryModal';
import { ProductActivityType } from '@models/product';

export interface ClassInvoiceCreateProps {
  classId: string;
  parentId: string;
  chatRoomId?: string;
  classInquiryInfo?: ClassInquiryInfo;
  onCancel?: () => void;
  onConfirm?: (data: CreateClassInvoiceData) => void;
}

export const ClassInvoiceCreate: React.FC<ClassInvoiceCreateProps> = (props) => {
  const [classInvoiceCreateData, setClassInvoiceCreateData] = useState<{
    student?: StudentInfo;
    times?: ClassTimes;
    dates?: ClassDates;
    classFee?: ClassFee;
  }>({});
  const { classId, parentId, classInquiryInfo } = props;
  const classItemQuery = useQuery({
    queryKey: [`class-item-${classId}`],
    queryFn: () => productApi.getProduct(classId!),
    enabled: !!classId,
  });

  const parentUserQuery = useQuery({
    queryKey: [`user-${parentId}`],
    queryFn: () => userApi.getUser(parentId),
    enabled: !!parentId,
  });

  const parentUser = parentUserQuery.data?.data;

  const classProduct = classItemQuery.data?.data;
  const classTitle = classProduct?.title;

  const classThumbnail = classProduct?.thumbnailImage;

  const address =
    classProduct?.activityType === ProductActivityType.HOME
      ? parentUser?.profile?.address
      : classProduct?.location.specificAddress;

  const onChangeProperty = (key: any, value: any) => {
    setClassInvoiceCreateData((prev: any) => {
      const newProperties = { ...prev };
      newProperties[key] = value;
      return newProperties;
    });
  };

  const active = !!(
    classInvoiceCreateData.student?.children &&
    classInvoiceCreateData.student?.children.length > 0 &&
    classInvoiceCreateData.dates &&
    classInvoiceCreateData.times &&
    !classInvoiceCreateData.dates.hasEmpty
  );

  useEffect(() => {
    const inquiryChildren = classInquiryInfo?.children || [];
    setClassInvoiceCreateData((prev: any) => {
      const newProperties = { ...prev };
      newProperties['student'] = {
        children: inquiryChildren,
        additionalParticipantCount: 0,
        total: inquiryChildren.length,
      } as StudentInfo;
      return newProperties;
    });
  }, [classInquiryInfo]);

  return (
    <OverlayScrollbarsComponent
      defer
      options={{
        scrollbars: { autoHide: 'scroll' },
        overflow: {
          x: 'hidden',
        },
      }}
      style={{
        backgroundColor: '#ffffff',
        marginTop: 0,
        position: 'relative',
        height: '100%',
        overflow: 'auto',
        width: '100%',
      }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          height: '100px',
          borderBottom: '1px solid #EFF1F4',
          backgroundColor: 'white',
          padding: '0 20px',
        }}>
        <img
          style={{ width: '40px', height: '40px', objectFit: 'cover', borderRadius: '8px' }}
          src={cdnImageUrl(classThumbnail)}></img>
        <div
          style={{
            marginLeft: '16px',
            fontFamily: 'Pretendard',
            fontWeight: 700,
            fontSize: '16px',
            lineHeight: '22px',
          }}>
          {classTitle}
        </div>
      </div>
      <StudentEdit
        student={classInvoiceCreateData.student}
        allChildren={parentUser?.profile?.children || []}
        onChange={(student) => {
          onChangeProperty('student', student);
        }}></StudentEdit>
      <Divider />
      <ClassTimesEdit
        times={classInvoiceCreateData.times}
        onChange={(value) => {
          onChangeProperty('times', value);
        }}
      />
      <Divider />
      <ClassDateEdit
        dates={classInvoiceCreateData.dates}
        times={classInvoiceCreateData.times}
        onChange={(value) => {
          onChangeProperty('dates', value);
        }}
      />
      <Divider />
      <ClassAddressView address={address?.address} />
      <Divider />
      <ClassFeeView
        edit
        defaultClassFee={classProduct?.fee || 0}
        totalClassTimes={classInvoiceCreateData.times?.totalClassTimes}
        totalStudentCount={classInvoiceCreateData.student?.total}
        applyGroupDiscount={classProduct?.applyGroupDiscount}
        onChangeClassFee={(value) => {
          onChangeProperty('classFee', value);
        }}
      />
      <div
        style={{
          width: '100%',
          height: '175px',
          backgroundColor: '#EFF1F4',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}>
        <div
          style={{
            marginTop: '24px',
            color: 'var(--Neutral-70, #808387)',
            textAlign: 'center',
            fontFamily: 'Pretendard',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '20px',
          }}>
          수강증 발행 후에는 일정 변경만 가능합니다.
        </div>
        <div style={{ padding: '40px 20px 0 20px', width: '100%' }}>
          <BottomRoundButton
            disabled={!active}
            onClick={() => {
              const data: CreateClassInvoiceData = {
                classId: classProduct?._id,
                parentId: parentId,
                tutorId: classProduct?.sellerId,
                students: classInvoiceCreateData.student?.children,
                additionalParticipantCount: classInvoiceCreateData.student?.additionalParticipantCount,
                applyGroupDiscount: classProduct?.applyGroupDiscount,
                totalClassTimes: classInvoiceCreateData.times?.totalClassTimes,
                classDuration: classInvoiceCreateData.times?.duration,
                totalAmount: classInvoiceCreateData.classFee?.regular,
                discountedAmount: classInvoiceCreateData.classFee?.total,
                classFeePerTime: classInvoiceCreateData.classFee?.fee,
                classTitle: classProduct?.title,
                classThumbnail: classThumbnail,
                parentName: parentUser?.profile?.name,
                tutorName: classProduct?.sellerName,
                tutorProfileImage: classProduct?.sellerProfileImage,
                chatRoomId: props.chatRoomId,
                dates: classInvoiceCreateData.dates?.dates,
              };
              props.onConfirm && props.onConfirm(data);
            }}>
            수강증 검토
          </BottomRoundButton>
        </div>
      </div>
    </OverlayScrollbarsComponent>
  );
};
